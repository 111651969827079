import React, { useState } from 'react'
import styled from 'styled-components'

import { HeadModul, ModulContainer } from 'components/Main/table/TableStyles'
import { usePortalContext } from 'context/PortalContext'
import { IoMdClose } from 'react-icons/io'
import { useCampaignContext } from 'context/CampaignContext'
import { get, set } from 'lodash'
import { DarkButton } from 'components/style/Global'
import { API } from 'aws-amplify'
import { updateCampaign, updateCampaign_V2 } from 'graphql/mutations'
import { UpdateCampaignMutation, UpdateCampaign_V2Mutation } from 'API'
import { useSearchParams } from 'react-router-dom';
import { useClientCode } from 'customHooks/useParameters'


type Props = {}

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  padding: 50px 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;

  h1 {
    text-align: center;
   font-size: 20px;
   font-weight: normal;
   margin-bottom: 50px;
  }
`

const InputContainer = styled.form`
display: flex;
flex-direction: column;
width: fit-content;
min-width: 600px;
margin-bottom:1.3vw;

input, select, textarea {
font-size: 12px;
font-family: "fontRegular";
padding: 8px;
border-radius: 7px;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
background-color:${({ theme }) => theme.background};
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
}
textarea {
    resize: none;
    min-height: 100px;
}

option {
    font-size: 12px;
  }

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.primaryGray};
  }

  span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none
  }
  input:invalid[focused-name = true] {
    outline: 1px solid red;
  }
  input:invalid[focused-name = true] ~ span {
    display: block;
  }
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.secondaryGray};
    font-family: "fontRegular";
    font-weight: normal;
  }
`

const EditCampaignModal = (props: Props) => {
    const { setEditCampaign } = usePortalContext()
    const { activeCampaign } = useCampaignContext()
    const [activeInput, setActiveInput] = useState<any>()
    const [updateCampaignVariables, setUpdateCampaignVariables] = useState<any>({})
    const [responseMessage, setResponseMessage] = useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();

    const [getID, setID,] = useClientCode();
    const element = Object.entries(activeCampaign || {}).map(([key, value]) => {
        return (
            <div key={key}>
                <label>{key}</label>
                <input type="text" />
            </div>
        )
    });

    const inputs = [
        {
            id: 1,
            name: "Name",
            type: "text",
            placeholder: activeCampaign?.campaign,
            label: "Campaign Name",
        },
        {
            id: 2,
            name: "StartDate",
            type: "date",
            placeholder: activeCampaign?.startDate,
            label: "Start Date",
        },
        {
            id: 3,
            name: "EndDate",
            type: "date",
            placeholder: activeCampaign?.endDate,
            label: "End Date",
        },
        {
            id: 4,
            name: "Budget",
            type: "number",
            placeholder: activeCampaign?.briefed_amount,
            label: "Briefed Budget",
        },
        {
            id: 5,
            name: "Comments",
            type: "textarea",
            placeholder: activeCampaign?.comment,
            label: "Comments",
        },
        {
            id: 6,
            name: "AdditionalInfo",
            type: "textarea",
            placeholder: activeCampaign?.additionalInfo,
            label: "Additional Info 1",
        },
        {
            id: 7,
            name: "AdditionalInfo2",
            type: "textarea",
            placeholder: activeCampaign?.additionalInfo2,
            label: "Additional Info 2",
        },
    ]





    const handleDiscard = (name: any) => {
        delete updateCampaignVariables[name]
        setUpdateCampaignVariables({ ...updateCampaignVariables })
    }

    async function handleUpdateCampaign(updateCampaignVariables: any) {

        try {
            const response = (await API.graphql({
                query: updateCampaign_V2,
                variables: {
                    CampaignId: Number(activeCampaign?.campaignId),
                    // clientCode: activeCampaign?.ClientCode,
                    // clientCountry: activeCampaign?.ClientCountry,
                    ...updateCampaignVariables
                },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: UpdateCampaign_V2Mutation }
            const message = response.data.updateCampaign_V2?.message
            setResponseMessage(message)
            searchParams.delete("campaignID")
            setSearchParams(searchParams)
            setID("none")
            // fetchCampaigns()
            setTimeout(() => {
                setResponseMessage(undefined)
                setEditCampaign(false)
                setID(getID)
            }, 1500);

        } catch (error) {
            console.error("updateCampaign error", error);

        }
    }



    return (
        <Modal>
            <HeadModul>
                <button type='button' className="x" onClick={() => setEditCampaign(false)}> <IoMdClose className='closeIcon' /></button>
                <h1>Edit Campaign</h1>
                {!responseMessage ? <ModulContainer>
                    {inputs.map(input => (
                        <InputContainer key={input.id}>

                            <label>{input.label}</label>
                            {activeInput === input.id ? <>{input.type === "textarea" ?
                                <textarea onChange={(e) => setUpdateCampaignVariables({ ...updateCampaignVariables, [input.name]: e.target.value })} placeholder={input.placeholder ? input.placeholder.toString() : ""} ></textarea> :
                                <input onChange={(e) => setUpdateCampaignVariables({ ...updateCampaignVariables, [input.name]: e.target.value })} type={input.type} placeholder={input.placeholder ? input.placeholder.toString() : ""} />}
                                <div style={{ width: "100%", marginTop: "1vw" }}>
                                    <button type='button' style={{ float: "right" }} onClick={() => { handleDiscard(input.name); setActiveInput(null) }} >Discard</button>
                                </div>
                            </>
                                : <h3 onClick={() => setActiveInput(input.id)}>{updateCampaignVariables[input.name] ? updateCampaignVariables[input.name] : input.placeholder ? input.placeholder : "no data available"}</h3>}

                        </InputContainer>
                    ))}
                    {/*                     <DarkButton style={{}} type="button" onClick={() => handleUpdateCampaign(updateCampaignVariables)}>Submit</DarkButton>
 */}                </ModulContainer> : <ModulContainer>
                    <p>{responseMessage}</p>
                </ModulContainer>}
                <DarkButton style={{}} type="button" onClick={() => handleUpdateCampaign(updateCampaignVariables)}>Submit</DarkButton>

            </HeadModul>
        </Modal >
    )
}

export default EditCampaignModal