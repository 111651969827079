
import styled from 'styled-components';
import { ValueResponse, briefTemplateSectionColumns } from 'API';

const Bulletin = styled.div`

.value {
  font-size: 12px;
  font-family: 'fontRegular';
  padding: 8px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.primaryGray};
  background-color: ${({ theme }) => theme.placeholderBackground};
  margin-bottom: 20px;
  max-width: 900px;

}

  label {
    font-size: 12px;
  }

  .labelContianer {
    display: flex;
    flex-direction: row;
  }
  .subColumns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2vw;
  }



`;

type Props = {
  inputProps: briefTemplateSectionColumns;
};

function BriefBulletin(props: Props) {

  return (
    <Bulletin>
      <div className='labelContianer'>
        <label htmlFor="">{props.inputProps.label}</label>
      </div>
      {props.inputProps.subColumns?.length === 0 ?
        <div style={{ whiteSpace: "pre-wrap", width: props.inputProps.name?.includes("eadline") ? "6vw" : "inherit" }} className='value' >{(props.inputProps.value as ValueResponse)[props.inputProps.valueType as keyof typeof props.inputProps.value]}</div> :

        <div className='subColumns'>{props.inputProps.subColumns?.map((subColumn: any) => (

          <Bulletin key={subColumn.label}>
            <div className='labelContianer'>
              <label htmlFor="">{subColumn.label}</label>
            </div>
            {subColumn.valueType === "BooleanValue" ?
              <div className='value'>
                {(subColumn.value[subColumn.valueType] === false || subColumn.value[subColumn.valueType] === null) ? "No" : "Yes"}
              </div> :
              <div className='value'>

                {isNaN(Number(subColumn.value[subColumn.valueType])) ? subColumn.value[subColumn.valueType] : Number(subColumn.value[subColumn.valueType]).toLocaleString()}
              </div>}

          </Bulletin>


        )
        )}</div>

      }

    </Bulletin>
  );
}

export default BriefBulletin;
