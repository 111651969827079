import { ModulContainer } from "components/Main/table/TableStyles";
import { FileUploader } from "react-drag-drop-files";
import { dragAndDropContainer, fileTypes, smallDragAndDropContainer } from "components/Main/Gallery/ARContainer";
import { QueryInfo } from "../Brief";

interface Props {
  selectedFiles: QueryInfo[],
  addFile: (files: FileList) => void,
}

const DragAndDrop3 = (props: Props) => {

  const { selectedFiles, addFile } = props
  return (
    <> {selectedFiles.length === 0 && (
      <div className="uploadedFilesLeftContianer">
        {/* <label>Choose file</label> */}
        <ModulContainer onClick={e => e.stopPropagation()} className='modal-content-upload'>
          <FileUploader
            classes="drop_area"
            handleChange={(files: FileList) => addFile(files)}
            name="file"
            multiple
            types={fileTypes}
            children={smallDragAndDropContainer}
          />
        </ModulContainer>
      </div>
    )
    }</>
  )
}

export default DragAndDrop3