import { DashboardSection, mediaSource } from 'API';
import { API } from 'aws-amplify';
import { PlaceholderType } from 'components/newSetup/PlaceholderContainer';
import { orderList2 } from 'components/newSetup/SortingPlaceHolderList';
import { useCampaignContext } from 'context/CampaignContext';
import { updateStoredQueriesOrder } from 'graphql/mutations';
import { getStoredQueryOrder } from 'graphql/queries';


interface Props {
  tablePlaceholderList: PlaceholderType[];
}

export const useDragAndDrop = (props: Props) => {
  // const { tablePlaceholderList } = props;

  const { activeCampaign } = useCampaignContext();

  const campaignId = activeCampaign?.campaignId ?? -1;

  const getOrderList = async (
    section: string,
    tablePlaceholderList?: PlaceholderType[],
  ): Promise<orderList2[] | null> => {
    try {

      const response = (await API.graphql({
        query: getStoredQueryOrder,
        variables: {
          CampaignId: campaignId, //activeCampaign?.campaignId,
          DashboardSection: section === 'Table' ? DashboardSection.TABLE : DashboardSection.CHART,
          DashboardType: mediaSource.DIGITAL,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: any };

      const { data } = response;


      const list = data.getStoredQueryOrder as orderList2[];


      const newList = list.map((item) => {
        return { ...item, Saved: true };
      });


      // const newList = list.map((item) => {
      //   const placeholder = tablePlaceholderList.find((placeholder) => placeholder.id === placeholder.query_id);
      //   if (placeholder) {
      //     return { ...item, Saved: false };
      //   } else {
      //     return { ...item, Saved: true };
      //   }
      // });
      return newList;
    } catch (e) {

      console.error(e);
      return null;
    }
  };

  const updateOrderList = async (section: string, orderList: orderList2[]): Promise<orderList2[] | null> => {
    try {
      const response = (await API.graphql({
        query: updateStoredQueriesOrder,
        variables: {
          CampaignId: activeCampaign?.campaignId,
          DashboardSection: section === 'Table' ? DashboardSection.TABLE : DashboardSection.CHART,
          DashboardType: mediaSource.DIGITAL,
          Order: orderList,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: any };

      const { data } = response;
      const result = data.updateStoredQueriesOrder;

      if (result.StatusCode === 200) {
        const response = getOrderList(section);
        return response;
      }

      return null;
    } catch (e) {

      console.error(e);
      return null;
    }
  };

  return { getOrderList, updateOrderList };
};
