import { Environment } from 'API';
import { createContext, FC } from 'react';

const { REACT_APP_ENVIRONMENT } = process.env;

export type ApiContextType = {
  paddingtonEndpoint: string;
  getEnvironment: Environment;
};

interface Props {
  children: React.ReactNode;
}

const APIContext = createContext<ApiContextType>({
  paddingtonEndpoint: '',
  getEnvironment: Environment.DEMO,
});

export const APIContextProvider: FC<Props> = ({ children }) => {
  const paddingtonEndpoint = 'https://azscvggsuvgadphtejlgcfmylm.appsync-api.eu-west-1.amazonaws.com/graphql';

  const getEnvironment = Environment[REACT_APP_ENVIRONMENT as keyof typeof Environment];

  return (
    <APIContext.Provider
      value={{
        paddingtonEndpoint,
        getEnvironment,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;
