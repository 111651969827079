import styled from "styled-components";

export const KPIholder = styled.div`
 width: 25%;
  height: 7.5vw;
  margin: 10px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;
/*   padding: 16px;
 */  font-weight: normal;
  h1 {
    font-weight: normal;
    font-size: 1.2vw;
  }
   h2 {
    font-weight: normal;
    font-size: .8vw;
    font-family: "fontMedium";
   }

   h4 {
    font-size: 2.2vw;
    color: ${({ theme }) => theme.brand};
    font-family: "FontBold";
   }
   .icon {
  color: ${({ theme }) => theme.secondaryGray};
  font-size: 1vw;
  margin: 16px;
  padding: 2px;
  border-radius: 10%;
  /* border: 1px solid ${({ theme }) => theme.border}; */
  box-shadow: 0 0 0 1px  ${({ theme }) => theme.border}; 
  cursor: pointer;
}

.icon:hover {
  box-shadow: 0 0 0 2px  ${({ theme }) => theme.secondaryGray}; 
}



   .valueFlex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
   }
   .plan {
    border-left: 3px solid ${({ theme }) => theme.secondaryGray};
    padding-left: 10px;

   }
   .loader {
    margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }
`

export const QuarterHolder = styled.div`
 width: 25%;
  height: 400px;
  margin: 10px;
  padding: 16px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;

  h1 {
    font-weight: normal;
    font-size: 14px;
  }
   h2 {
    font-weight: normal;
    font-size: 12px;
    font-family: "fontMedium";
   }

`

export const SingleHolder = styled.div`
 width: 100%;
  min-height: 400px;
  height: fit-content;
  margin: 10px;
  padding: 16px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;

  h1 {
    font-weight: normal;
    font-size: 14px;
  }
   h2 {
    font-weight: normal;
    font-size: 12px;
    font-family: "fontMedium";
   }
`

