import React, { useState } from 'react'
import { FormInputContainer } from '../style/BriefInputStyle'
import { Inputs } from '../APICall/BRIEFTEMPLATE'
import { enrichedBriefTemplateSectionSubColumns, enrichedbriefTemplateSectionColumns } from '../CustomHook/useBriefDataFromClient'
import { briefTemplateSectionColumns, briefTemplateSubColumn } from 'API'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
type Props = {
  inputProps: briefTemplateSectionColumns | briefTemplateSubColumn,
  onChange: onChange,
  type: string,
  pattern?: string,
  required?: boolean,
  value?: any,
  min?: string,
  max?: string,
  minlength?: string,
  maxlength?: string,
  size?: string,
  name?: string,



}

type InputProps = {
  required?: boolean;
  name?: string;
  pattern?: string;
  value?: string;
  min?: string;
  max?: string;
  minLength?: string;
  maxLength?: string;
  size?: string;
}

type onChange = (e: any) => void


const Input = (props: Props) => {
  const [focused, setFocused] = useState(false)
  const { inputProps, type, pattern, min, max, minlength, maxlength, size, onChange, name, value } = props
  const { required, id } = inputProps


  const inputDetails: InputProps = {
    pattern,
    min,
    max,
    minLength: minlength,
    maxLength: maxlength,
    size,
  }

  const filteredInputProps = Object.fromEntries(
    Object.entries(inputDetails).filter(([_, value]) => value !== undefined)
  );


  return (
    <FormInputContainer key={inputProps.name}>
      {inputProps.type === "CHECKBOX"
        ? <div>
          <input
            type={type}
            name={name}
            onBlur={() => setFocused(true)}
            focused-name={focused.toString()}
            onChange={(e) => onChange({ e: e.target.checked, id: id })} checked={value} />
        </div>
        : inputProps.type === "DATE" ?
          <DatePicker
            className="datepicker"
            dateFormat="dd-MM-yyyy"
            selected={value}
            onChange={(e: any) => onChange({ e: e.toISOString().slice(0, 10), id: id })}
            calendarStartDay={1}
          /> :

          <div>
            <input type={type}
              {...filteredInputProps} name={name}
              onChange={(e) => onChange({ e: e.target.value, id: id })}
              value={value}
              onBlur={() => setFocused(true)}
              focused-name={focused.toString()}
              className={required && (value === "" || value === undefined) && focused ? "errorMessageInput" : ""}

            />
            <span>{"mandatory"}</span>
          </div>
      }
    </FormInputContainer>
  )
}

export default Input