import { S3Method, GetPreSignedUrlQuery, StoreQueriesMutation, Environment } from 'API';
import { API } from 'aws-amplify';
import { getPreSignedUrl } from 'graphql/queries';
import { v4 as uuidv4 } from 'uuid';
import { useCampaignContext } from 'context/CampaignContext';
import APIContext from 'context/APIContext';
import { useContext, useEffect, useState } from 'react';
import { storeQueries } from 'graphql/mutations';


export const saveFiletoS3 = (selectedImage: File, position: number, campaignId: number, ClientCode: string, ClientCountry: string, env: Environment, tab: string) => {
    // const { activeCampaign } = useCampaignContext();
    // const { getEnvironment } = useContext(APIContext);
    // const [queryIsSaved, setQueryIsSaved] = useState(false)

    // useEffect(() => {
    const fileN = selectedImage && `${uuidv4()}_${selectedImage.name}`;

    async function saveQuery() {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const saveResponse = (await API.graphql({
                query: storeQueries,
                variables: {
                    campaign_id: campaignId.toString(), //activeCampaign?.campaignId?.toString(),
                    position: `${tab}UploadFile${position}`,
                    query_id: fileN,
                    title: "noTitle",
                    metrics: {
                        chartType: selectedImage.type,
                        metric: 'image',
                        axis: 1,
                    },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: StoreQueriesMutation };



            // setQueryIsSaved(true)
        } catch (e) {
            console.log(e);
        }
    }


    async function addToS3(url: any) {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': `${selectedImage.type}`,
                },
                body: selectedImage,
            });
            saveQuery()
        } catch (e) {
            console.log('Error adding to S3,', e);
        }
    }





    const putPresigned = async () => {
        try {
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.put_object,
                    Client: {
                        code: ClientCode,
                        country: ClientCountry,
                    },
                    Environment: env,
                    FileName: fileN,
                    ContentType: selectedImage.type,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            const url = preSignedURL.data.getPreSignedUrl?.url;
            // console.log("This is the item I want to save!", url  )
            addToS3(url);
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }
    };


    // if (selectedImage) {
    void putPresigned()
    // }




    return {
        id: fileN,
        position: `${tab}UploadFile${position}`,
    }



}