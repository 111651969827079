import { Environment, LogActivityMutation } from "API";
import { API } from "aws-amplify";
import APIContext from "context/APIContext";
import { logActivity } from "graphql/mutations";
import { useContext, useState } from "react";








interface LoggingVars {
  Username: string,
  Action: string,
  Environment: Environment,
  Service: string,
  Url?: string | null,
  Ip?: string | null,
}


type LoggingResult = [boolean, (userInfo: LoggingVars) => void];

export const useLoggingActivity = (): LoggingResult => {
  const [status, setStatus] = useState<boolean>(false);
  // const [ioAddress, setIpAddress] = useState<string>("");

  // fetch('https://api.ipify.org?format=json')
  //   .then(response => response.json())
  //   .then(data => {
  //     // console.log(data.ip);
  //     setIpAddress(data.ip);
  //   })
  //   .catch(error => {
  //     console.log('Error:', error);
  //   });


  const logActivityMutation = async (userInfo: LoggingVars) => {
    console.error("mutation")
    try {
      const mutation = (await API.graphql({
        query: logActivity,
        variables: userInfo,
        authMode: 'AWS_IAM',
      })) as { data: LogActivityMutation };

      return true;
    } catch (e) {
      console.error("Error logging activity", e)
    }

  }

  return [status, logActivityMutation];
}