import {
  placeholderDimensions,
  placeholderFullSources,
  placeholderMetrics,
} from 'components/newSetup/PlaceholderContainer';
import {
  Dimensions,
  GetDataSourcesQuery,
  Metrics,
  agencyEnum,
  calculatedMetricsDetail,
  client,
  countryEnum,
  datatype,
  detailedDimensionsDetail,
  filter,
  filterDetail,
  filtertype,
  localQuery,
  metric,
  metricFunction,
  queryInput,
  userDetail,
  view,
} from 'API';
import { getEndDate, getStartDate } from './dateMethods';
import { ACdetails } from 'context/CampaignContext';
import { NullableUser } from 'context/AuthContext';

export const prepQuery = (
  query: placeholderFullSources,
  isGraph: boolean,
  activeCampaign: ACdetails,
  user: NullableUser,
) => {
  // read the query object and based on the items selected, build the query
  const { REACT_APP_AGENCY } = process.env;

  const uniqueViews = Array.from(
    new Set([
      ...(query.dimensions as placeholderDimensions[])
        .filter((e) => e.isSelected)
        .flatMap(
          (d) =>
            d.view?.map((v) =>
              JSON.stringify({
                viewName: v as string,
                database: 'data_lake_denmark',
              }),
            ) || [],
        ),
      ...(query.metrics as placeholderMetrics[])
        .filter((e) => e.isSelected)
        .flatMap(
          (m) =>
            m.view?.map((v) =>
              JSON.stringify({
                viewName: v as string,
                database: 'data_lake_denmark',
              }),
            ) || [],
        ),
    ]),
  ).map((s) => JSON.parse(s as string));

  const allowed = ['isSelected'];
  const orderByIds = [
    ...(query.dimensions?.filter((e) => e.isSelected).map((d) => d.detailedDimension?.id) as string[]),
    ...(query.metrics?.filter((e) => e.isSelected).map((m) => m.detailedMetric?.id) as string[]),
    ...(query.calcMetrics?.filter((e) => e.isSelected).map((m) => m.id) as string[]),
  ];

  const mainQuery: queryInput = {
    query: {
      metrics: query.metrics?.filter((e) => e.isSelected).map((m) => m.detailedMetric) as metric[],
      filters: [
        {
          column: 'campaign_id',
          filter: {
            filtertype: filtertype.EQUAL,
            datatype: datatype.NUMBER,
            value: activeCampaign?.campaignId?.toString(),
          } as filter,
        },
      ] as filterDetail[],
      calculatedMetrics: query.calcMetrics
        .filter((e) => e.isSelected)
        .map((q: any) => {
          const filtered = Object.keys(q)
            .filter((key) => !allowed.includes(key))
            .reduce((obj: any, key) => {
              obj[key] = q[key];
              return obj;
            }, {});
          return filtered;
        }) as calculatedMetricsDetail[],
      detailedDimensions: query.dimensions
        ?.filter((e) => e.isSelected)
        .map((d) => d.detailedDimension) as detailedDimensionsDetail[],
      orderBy: orderByIds,
      preview: 100,
    } as localQuery,
    views: uniqueViews,
    jobnumber: 'ANNA0027',
    clients: [
      {
        country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
        client: activeCampaign?.ClientCode as string,
      },
    ],
    date: {
      from: getStartDate(),
      to: getEndDate(),
    },
    user: {
      name: user?.username,
      agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
      country: countryEnum.DK,
    } as userDetail,
  };
  return mainQuery;
};

export const prepQueryCC = (
  query: placeholderFullSources,
  isGraph: boolean,
  activeCampaign: ACdetails,
  user: NullableUser,
) => {
  const { REACT_APP_AGENCY } = process.env;
  const allowed = ['isSelected'];
  const mainQuery: queryInput = {
    query: {
      // TODO ADD THE OTHER 2 AS WELL
      metrics: [
        {
          column:
            query.name === 'Facebook'
              ? 'custom_conversions_value'
              : query.name === 'Adform'
              ? 'custom_conversions'
              : query.name === 'DV360'
              ? 'total_conversions'
              : 'total_conversions',
          aggregation: metricFunction.SUM,
          name: 'custom_conversion',
          id: 'custom_conversion',
          decimals: 2,
        },
      ] as metric[],

      filters: [
        {
          column: 'campaign_id',
          filter: {
            filtertype: filtertype.EQUAL,
            datatype: datatype.NUMBER,
            value: activeCampaign?.campaignId?.toString(),
          } as filter,
        },
        {
          column:
            query.name === 'Facebook'
              ? 'conversion_name'
              : query.name === 'Adform'
              ? 'filter_name'
              : query.name === 'DV360'
              ? 'floodlight_activity_name'
              : 'activity',
          filter: {
            filtertype: filtertype.IN,
            datatype: datatype.STRING,
            list: query.customConversions?.filter((e) => e.isSelected).map((e) => e.CCname) as string[],
          },
        },
      ] as filterDetail[],
      calculatedMetrics: query.calcMetrics
        .filter((e) => e.isSelected)
        .map((q: any) => {
          const filtered = Object.keys(q)
            .filter((key) => !allowed.includes(key))
            .reduce((obj: any, key) => {
              obj[key] = q[key];
              return obj;
            }, {});
          return filtered;
        }) as calculatedMetricsDetail[],
      detailedDimensions: [
        ...(query.dimensions as placeholderDimensions[])?.filter((e) => e.isSelected).map((d) => d.detailedDimension),
        {
          column:
            query.name === 'Facebook'
              ? 'conversion_name'
              : query.name === 'Adform'
              ? 'filter_name'
              : query.name === 'DV360'
              ? 'floodlight_activity_name'
              : 'activity',
          name: 'CC_name',
          hidden: false,
          id: 'CC_name',
        },
      ] as detailedDimensionsDetail[],
      orderBy: [
        ...(query.dimensions?.filter((e) => e.isSelected).map((d) => d.detailedDimension?.id) as string[]),
        'custom_conversion',
        'CC_name',
      ],
      preview: 100,
    } as localQuery,
    views: [
      {
        viewName:
          query.name === 'Facebook'
            ? 'fozzie_facebook_custom_conversions_'
            : query.name === 'Adform'
            ? 'fozzie_adform_custom_cost_conversions_'
            : query.name === 'DV360'
            ? 'fozzie_google_dv360_conversions_'
            : 'fozzie_campaign_manager_conversions_',
        database: 'data_lake_denmark',
      },
    ],
    jobnumber: 'ANNA0027',
    clients: [
      {
        country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
        client: activeCampaign?.ClientCode as string,
      },
    ],
    date: {
      from: getStartDate(),
      to: getEndDate(),
    },
    user: {
      name: user?.username,
      agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
      country: countryEnum.DK,
    } as userDetail,
  };

  return mainQuery;

  //PUSH ORDER ID
};
