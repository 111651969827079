import { StatementType, GetUserV2Query, getUserV3Response, userAccessType, getUserTypeV2 } from "API";
import { API } from "aws-amplify";
import APIContext from "context/APIContext";
import { IActiveOrganization, useClientContext } from "context/ClientContext";
import { getUserV2 } from "graphql/queries";
import { get } from "lodash";
import { useContext, useEffect, useState } from "react";



export const useGetSingleUserAccess = () => {
  const { getEnvironment } = useContext(APIContext);
  const { activeOrganization } = useClientContext();
  const [userAccess, setUserAccess] = useState<userAccessType[] | null>(null);

  const fetchAccessDataForUser = async (user: getUserV3Response): Promise<userAccessType[]> => {
    try {
      const get = (await API.graphql({
        query: getUserV2,
        variables: {
          Environment: getEnvironment, // Environment.DEMO,
          Organization_id: activeOrganization?.OrganizationId,
          StatementType: StatementType.Access,
          User: user.Username,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUserV2Query };

      const userAccessType = get.data.getUserV2?.Access;

      // Merge READ_ACCESS and READ_PLUS_ACCESS
      const mergedAccess = userAccessType?.reduce((acc, user) => {
        if (user?.Level === "READ_ACCESS" || user?.Level === "READ_PLUS_ACCESS") {
          if (!acc) {
            acc = { __typename: "userAccessType", Level: "READ_ACCESS", Clients: [] };
          }
          acc.Clients = [...(acc.Clients || []), ...(user?.Clients || [])];
        }
        return acc;
      }, null);

      // Remove duplicates from Clients array
      const uniqueClients = mergedAccess?.Clients?.filter((client, index, self) =>
        index === self.findIndex((c) => (
          c?.ClientCode === client?.ClientCode && c?.ClientCountry === client?.ClientCountry
        ))
      );

      // Reconstruct the array
      const updatedUserAccessType = userAccessType
        ? userAccessType
          .filter(user => user?.Level !== "READ_ACCESS" && user?.Level !== "READ_PLUS_ACCESS")
          .concat({ ...mergedAccess, Clients: uniqueClients, __typename: "userAccessType" })
        : [];

      console.log(updatedUserAccessType);

      // userAccessType?.map(user => {
      //   if (user?.Level === "READ_PLUS_ACCESS") {
      //     const readPlusAccess = userAccessType?.find(e => e?.Level === "READ_PLUS_ACCESS");
      //     if (readPlusAccess) {
      //       const clients = [...(user.Clients ?? []), ...(readPlusAccess.Clients ?? [])];
      //       user.Clients = clients;
      //     }
      //   }
      // })
      // console.log(userAccessType)
      setUserAccess(updatedUserAccessType as userAccessType[]);
      return updatedUserAccessType as userAccessType[];
    } catch (e) {
      console.error('ERROR IN FETCHING USER ACCESS', e);
      return [];
    }
  };

  return [fetchAccessDataForUser, userAccess] as const;
};



