
import { HeadModul, ModulContainer } from 'components/Main/table/TableStyles';
import { DarkButton } from 'components/style/Global';
import { IoMdClose } from 'react-icons/io';
import { useState } from 'react';
import styled from 'styled-components';
import { readPlusSave } from './UserAdmin2';
import { Clients } from 'API';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components'

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.4vw;
  }

  .filterTitle {
    margin: 0;
    padding: 0.4vw 0;
  }
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: normal;
  }

  .form {
    margin-top: 2vh;
    border: 2px solid ${({ theme }) => theme.border};
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: 1vw;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 0.5vw 0.9vw;

  }

  .namesForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5vw;
  }
  .selectorsForm {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding: 0 4vw;
  }
  input,
  label,
  h3 {
    display: block;
    margin-bottom: 1vh;
    margin-top: 0.3vh;
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontRegular', sans-serif;
    font-size: 12px;
  }
  input {
    width: 17vw;
    font-size: 12px;
    padding: 8px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color: ${({ theme }) => theme.background};
  }

  .toggler {
    border: 2px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    padding: 0.9vw;
    height: fit-content;
    overflow-y: hidden;
    margin-bottom: 20px;



  }
  .onoff {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5vw;
    height: fit-content;

    label {
      cursor: pointer;
      background: transparent;
      border: 1px solid ${({ theme }) => theme.primaryGray};
      border-radius: 7px;
      width: fit-content;
      margin-bottom: 8px;
      margin-top: 21px;
      font-family: 'FontMedium', sans-serif;
      color: ${({ theme }) => theme.primaryGray};
      display: flex;
      padding: 9px 12px;
    }

    input {
      display: none;
    }
    .checked {
      padding: 9px 12px;
    }

    input:checked ~ .checked {
      border: 1px solid ${({ theme }) => theme.secondaryGray};
      border-radius: 7px;
    }

    label:has(input:checked) {
      background-color:${({ theme }) => theme.border};
    }


    section {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid ${({ theme }) => theme.border};
      width: 100%;
      padding: 5px 15px 0px 15px;
      margin-bottom: 0;
      align-items: center;
      // border-radius: 50px;
      justify-content: space-between;

      legend {
        font-family: 'FontRegular', sans-serif;
        font-weight: 800;
        font-size: 0.7vw;
        margin-right: 1vw;
        color: ${({ theme }) => theme.primaryGray};
        border-bottom: none;
      }
      input[type='radio'] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      input[type='checkbox'] {
        opacity: 0;
        position: fixed;
        width: 0;
      }
      label {
        display: flex;
        align-items: center;
        /* display: inline-block; */
        padding: 6px;
        margin: 0rem !important;
        font-size: 0.6vw;
        color: ${({ theme }) => theme.secondaryGray};

        /*         cursor: pointer; */
      }
      input[type='radio']:checked + label {
        color: #20c933;
      }
      input[type='checkbox']:checked + label {
        color: #20c933;
      }
    }
    .dashboards {
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 5px;
      padding: 0px 15px 0px 15px;
    }
    .midChild {
      position: relative;
      padding-right: 0.6vw;
    }
    .midChild:after {
      content: ' ';
      position: absolute;
      border-left: 1px solid ${({ theme }) => theme.border};
      top: 25%;
      right: 0;
      height: 50%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .confirm {
    border: 2px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    padding: 0.9vw;
  }

  .onoff2 {
    grid-template-columns: repeat(9, auto);
    
    width: fit-content;
    label {
      padding: 5px 10px;
    }
  }

  .loaderDiv {
    position: absolute;
    margin-top: -50px;
    margin-left: -50px;
    top: 50%;
    left: 50%;
    width: 400px ;
    height:400px ;
  }


`;
type Props = {
  setReadPlusModal: (a: boolean) => void;
  readPlusModal: boolean;
  typeOfReadAccess: any[];
  HandleReadPlusModalResponse: (a: readPlusSave) => void;
  readPlusLoader: boolean;
  setReadPlusLoader: (a: boolean) => void;
};
const ReadPlusAdminModal = (props: Props) => {
  const { setReadPlusModal, readPlusModal, typeOfReadAccess, readPlusLoader, setReadPlusLoader } = props;
  const [value, setValue] = useState<string>('');
  const [selectedClients, setSelectedClients] = useState<Clients[]>([]);

  const themes = useTheme()

  const handleClientCodes = (e: any) => {
    const found = typeOfReadAccess.find(el => el.ClientCode === e.target.value)
    delete found.Level
    if (e.target.checked) {
      setSelectedClients([...selectedClients, found])
    } else {
      setSelectedClients(selectedClients.filter(el => el.ClientCode !== e.target.value))
    }
  }

  const handleSave = () => {
    setReadPlusLoader(true)
    props.HandleReadPlusModalResponse({ clientCodes: selectedClients as any, newLevel: value })
    // setReadPlusModal(false)
  }

  const [confirmed, setConfirmed] = useState<boolean>(false)


  return (
    <Modal onClick={() => setReadPlusModal(false)}>
      {!readPlusLoader ? (

        <HeadModul onClick={(e) => e.stopPropagation()}>
          <button type="button" className="x" onClick={() => setReadPlusModal(false)}>
            {' '}
            <IoMdClose className="closeIcon" />
          </button>

          <ModulContainer onClick={(e) => e.stopPropagation()}>
            <h1>
              Manage access to <br /> Campaign Overview
            </h1>
            <div className="toggler" style={{ overflow: 'hidden' }}>
              <h3 style={{ width: '200px' }}>Select the type of access you would like to grant:</h3>
              <div className="onoff">
                <label htmlFor="READ_PLUS_ACCESS">
                  <input
                    type="radio"
                    checked={value === 'READ_PLUS_ACCESS'}
                    name="accessType"
                    id="READ_PLUS_ACCESS"
                    value="READ_PLUS_ACCESS"
                    // checked={sd.isSelected}
                    // name={sd.dimension}
                    // value={sd.dimension}
                    onChange={(e) => { setConfirmed(false); setValue(e.target.value); setSelectedClients([]) }}
                  />
                  <span> Grant Access to Campaign Overview</span>
                </label>

                <label htmlFor="READ_ACCESS">
                  <input
                    type="radio"
                    checked={value === 'READ_ACCESS'}
                    name="accessType"
                    id="READ_ACCESS"
                    value="READ_ACCESS"
                    onChange={(e) => { setConfirmed(false); setValue(e.target.value); setSelectedClients([]) }}
                  />
                  <span>Remove Access to Campaign Overview</span>
                </label>
              </div>
            </div>

            {value !== "" && <div className='toggler'>
              <h3 style={{ width: "200px" }}>Select Client Codes you wish to change access for:</h3>



              <div className='onoff onoff2'>
                {!confirmed ? <div className='confirm'>
                  <h3>This feature is based on a subscribtion. <br /> Please verify that the client's subscription status has been updated</h3>
                  <button onClick={() => setConfirmed(true)} type='button'>CONFIRM</button>
                </div> :
                  <>
                    {typeOfReadAccess.filter(e => e.Level !== value).map(e => (
                      <label key={e.ClientCode}>
                        <input
                          type="checkbox"
                          // checked={c.isSelected}
                          // name={c.CCname}
                          value={e.ClientCode}
                          // onChange={(e) => selectCustomConversion(e)}
                          onChange={(e) => { handleClientCodes(e) }}

                        />
                        <span>{e.ClientCode}</span>
                      </label>
                    ))}
                  </>


                }

                {/* {typeOfReadAccess.filter(e => e.Level !== value).map(e => (
                  <label key={e.ClientCode}>
                    <input
                      type="checkbox"
                      // checked={c.isSelected}
                      // name={c.CCname}
                      value={e.ClientCode}
                      // onChange={(e) => selectCustomConversion(e)}
                      onChange={(e) => { handleClientCodes(e) }}

                    />
                    <span>{e.ClientCode}</span>
                  </label>
                ))} */}

              </div>


            </div>}


          </ModulContainer>

          {selectedClients.length > 0 && value !== "" && <DarkButton
            style={{ alignSelf: 'flex-end' }}
            type="button"
            onClick={() => handleSave()
            }
          >
            SAVE
          </DarkButton>}
        </HeadModul>)
        : (

          <div className="loaderDiv">
            <ReactLoading type="spinningBubbles" color={themes.publishButton}></ReactLoading>
          </div>

        )}
    </Modal>
  );
};

export default ReadPlusAdminModal;
