import { MetricsResponse, Placeholder, UserQuery } from 'API';
import { StyledTableContainer } from 'components/Main/table/TableStyles'
import { fullSources } from 'customHooks/useDataSources';
import React, { useState } from 'react'
import SavedContainer from '../SavedContainer';
import { useDataQuery } from 'customHooks/useDataQuery';
import { useJoinData } from 'customHooks/useJoinData';

type Props = {
    isGraph: boolean;
    componentID: string;
    publishedQueriesDetails?: Placeholder;
}




const ClientPlaceholderContainer = (props: Props) => {
    const { isGraph, componentID, publishedQueriesDetails } = props
    const fromTitle = publishedQueriesDetails?.title ?? "Add title here"
    const [chartMetrics, setChartMetrics] = useState<Array<MetricsResponse>>(publishedQueriesDetails?.metrics as MetricsResponse[] ?? []);



    const [mainData, mainQueryStatus, mainError, mainQueryID] = useDataQuery({ query: undefined, querySource: 'QUERY', publishedOutputLocation: publishedQueriesDetails?.outputlocation, publishedQueriesDetails: publishedQueriesDetails });
    const [mainTotalData, mainTotalQueryStatus, , ,] = useDataQuery({ query: undefined, querySource: 'QUERY TOTAL', publishedOutputLocation: publishedQueriesDetails?.outputlocationTotal, publishedQueriesDetails: publishedQueriesDetails });
    const [ccData, ccQueryStatus, , ccQueryID] = useDataQuery({ query: undefined, querySource: 'QUERY', publishedOutputLocation: publishedQueriesDetails?.outputlocation_cc, publishedQueriesDetails: publishedQueriesDetails });
    const [ccTotalData, ccTotalQueryStatus, , ,] = useDataQuery({ query: undefined, querySource: 'QUERY TOTAL', publishedOutputLocation: publishedQueriesDetails?.outputlocationTotal_cc, publishedQueriesDetails: publishedQueriesDetails });

    const [joinedData] = useJoinData(
        mainData as any[] | undefined,
        mainTotalData as any[] | undefined,
        ccData as any[] | undefined,
        ccTotalData as any[] | undefined,
        isGraph,
        mainQueryStatus,
        mainTotalQueryStatus,
        ccQueryStatus,
        ccTotalQueryStatus,
        chartMetrics,
        setChartMetrics,
        componentID,
    );

    return (
        <StyledTableContainer expand={0}>
            <SavedContainer
                joinedData={joinedData && joinedData.every((e: any) => e !== undefined) ? joinedData : undefined}
                isGraph={isGraph} // COMES FROM PARENT
                title={fromTitle} // COMES FROM PARENT
                page={7}
                chartMetrics={chartMetrics} // COMES FROM PARENT
                componentID={componentID} // COMES FROM PARENT
            ></SavedContainer>
        </StyledTableContainer>
    )
}

export default ClientPlaceholderContainer