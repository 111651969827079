import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent as SVG_DIGITAL } from '../../assets/newAssets/Property 1=digital.svg';
import { ReactComponent as SVG_OOH_KAMPAGNE } from '../../assets/newAssets/Property 1=ooh.svg';
import { ReactComponent as SVG_PRINT } from '../../assets/newAssets/Property 1=print.svg';
import { ReactComponent as SVG_RADIO } from '../../assets/newAssets/Property 1=radio.svg';
import { ReactComponent as SVG_TV } from '../../assets/newAssets/Property 1=video.svg';
import { ReactComponent as SVG_CINEMA } from '../../assets/SVG/cinema.svg';
import FlowChart from '../../assets/introImg/FLOWCHART/FlowChart-OMD.png';
import FlowChartPHD from '../../assets/introImg/FLOWCHART/FlowChart-PHD.png';
import FlowChartHS from '../../assets/introImg/FLOWCHART/FlowChart-H&S.png';
import { DashboardTypes } from 'API';
import { useClientCode } from 'customHooks/useParameters';
import { Header, HomePage2Container, Main } from './HP2Style';
import { Link, NavLink, useLocation } from 'react-router-dom';
import AuthContext from 'context/AuthContext';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { CampaignSelectComponent } from 'components/Header/CampaignSelectComponent';
import { ReactComponent as SVG_SETTINGS } from '../../assets/SVG/settings.svg';
import InputContext from 'context/InputContext';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

type PageType = 'welcome' | 'dashboardSelector';

type Props = {
  dashType: DashboardTypes;
};

const HomePage2 = (props: Props) => {
  const navigate = useNavigate();
  const [selectedClient] = useClientCode();
  const creatorIcon = iconList.find((icon) => icon === 'screen_share');
  const { user } = useContext(AuthContext);
  const { dashType } = props;
  const [currentPage, setCurrentPage] = useState('welcome' as PageType);
  const { clientFilterList, campaignList, setDashType } = useCampaignContext();
  const { isOnlyDanish, organizationList, userAccess, anyPublish, checkPaddington, isThereAnyReadPlus } =
    useClientContext();
  const { REACT_APP_AGENCY } = process.env;

  const [, setOrg] = useClientCode();
  const { activeOrganization, setActiveOrganization, userCanDoInsource } = useClientContext();

  useEffect(() => {
    if (organizationList.length === 1) {
      setOrg(organizationList[0]?.OrganizationId as string);
      setActiveOrganization(organizationList[0]);
    }
  }, [organizationList]);

  const defaultImg = REACT_APP_AGENCY === 'omd' ? FlowChart : REACT_APP_AGENCY === 'phd' ? FlowChartPHD : FlowChartHS;

  const agencyNameCapitalized =
    REACT_APP_AGENCY === 'omd'
      ? 'OMD'
      : REACT_APP_AGENCY === 'phd'
      ? 'PHD'
      : REACT_APP_AGENCY === 'hs'
      ? 'Hearts & Science'
      : 'Agency';

  const dashboards = [
    {
      title: 'Video Reports',
      img: SVG_TV,
      link: '/reporting/TVPortal',
      /*    description: 'Budgets, costs or reach? Find all the results, across all stations in one place.', */
      description: 'Click here to find all video evaluations',
      id: 'TV',
    },
    {
      title: 'Digital Reports',
      img: SVG_DIGITAL,
      link: '/reporting/onlinePortal',
      /*    description: 'Find digital campaign reports here. Compare results and adjust your ongoing campaigns.', */
      description: 'Click here to find all digital evaluations',
      id: 'Digital',
    },
    {
      title: 'Radio Reports',
      img: SVG_RADIO,
      link: '/reporting/radioPortal',
      /*    description: 'Radio campaigns are a great way to reach your audience. Find  all the results in one place', */
      description: 'Click here to find all radio evaluations',
      id: 'Radio',
    },
    {
      title: 'Print Reports',
      img: SVG_PRINT,
      link: '/reporting/printPortal',
      /*  description: 'Get an overview and dates of your print ads. Splitted in formats and medias.', */
      description: 'Click here to find all print evaluations',
      id: 'Print',
    },
    {
      title: 'OOH Reports',
      img: SVG_OOH_KAMPAGNE,
      link: '/reporting/OOHPortal',
      /*    description: 'Bus stops, billboards or digital screens? Find all the results in one place', */
      description: 'Click here to find all OOH evaluations',
      id: 'OOH',
    },
    {
      title: 'Cinema Reports',
      img: SVG_CINEMA,
      link: '/reporting/cinemaPortal',
      description: 'Click here to find all cinema evaluations',
      id: 'Cinema',
    },

    {
      title: 'Insource Data',
      img: SVG_DIGITAL,
      link: '/reporting/insourceDataPortal',
      description: 'Click here to create digital reports',
      id: 'Insource',
    },
  ];
  // if (isTryg) {
  //   dashboards.splice(1, 1)
  // }

  const campaignListHasDigital = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'DIGITAL'));
  const campaignListHasTV = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'TV'));
  const campaignListHasRadio = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'RADIO'));
  const campaignListHasPrint = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'PRINT'));
  const campaignListHasOOH = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'OOH'));
  const campaignListHasCinema = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'CINEMA'));
  const campaignListHasInsource = campaignList?.some((e) => e?.dashboardType.some((e) => e === 'INSOURCE_DATA'));
  const campaignListHasOverview = true;

  const checkCampaignListHas = (description: string) => {
    switch (description) {
      case 'Digital':
        return campaignListHasDigital;
      case 'TV':
        return campaignListHasTV;
      case 'Radio':
        return campaignListHasRadio;
      case 'Print':
        return campaignListHasPrint;
      case 'OOH':
        return campaignListHasOOH;
      case 'Overview':
        return campaignListHasOverview;
      case 'Coming soon':
        return campaignListHasOverview;
      case 'Cinema':
        return campaignListHasCinema;
      case 'Insource':
        return campaignListHasInsource;
      default:
        return false;
    }
  };

  const redirectToPage = (link: string) => {
    const search = createSearchParams({ client: selectedClient }).toString();
    if (link === '/reporting/onlinePortal') {
      setDashType(DashboardTypes.DIGITAL);
    } else if (link === '/reporting/TVPortal') {
      setDashType(DashboardTypes.TV);
    } else if (link === '/reporting/radioPortal') {
      setDashType(DashboardTypes.RADIO);
    } else if (link === '/reporting/printPortal') {
      setDashType(DashboardTypes.PRINT);
    } else if (link === '/reporting/OOHPortal') {
      setDashType(DashboardTypes.OOH);
    } else if (link === '/') {
      setDashType(DashboardTypes.HOME);
    } else if (link === '/reporting/portal') {
      setDashType(DashboardTypes.FLOWCHART);
    } else if (link === '/reporting/cinemaPortal') {
      setDashType(DashboardTypes.CINEMA);
    } else if (link === '/reporting/insourceDataPortal') {
      setDashType(DashboardTypes.INSOURCE_DATA);
    } else if (link === '/reporting/dataOverwrite') {
      setDashType(DashboardTypes.OVERWRITE);
    }
    // navigate({
    //   pathname: link,
    //   search: search,
    // });

    navigate(link + '?' + search, { state: { client: selectedClient } });
  };

  const { REACT_APP_LOGO } = process.env;

  const LogoImage = ({ theme }: { theme: string }) => (
    <img
      style={{ width: '70px', padding: '0 20%' }}
      className="bar__clientLogo"
      src={require(`../../assets/${theme}${REACT_APP_LOGO}`)}
      alt="Logo"
    />
  );

  const redirectHome = () => {
    setDashType(DashboardTypes.HOME);
    setActiveOrganization(undefined);
    setOrg('none');
  };

  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  const jumptoSection = (section: string) => {
    const search = createSearchParams({ client: selectedClient }).toString();
    // console.log(search);

    return `/reporting${search !== 'client=none' ? '?' + search : ''}${section}`;
  };
  return (
    <HomePage2Container>
      <Header orgLength={organizationList.length}>
        <div style={{ cursor: 'pointer' }} onClick={() => redirectHome()}>
          <LogoImage theme="light" />
        </div>

        <div className="links">
          <Link to={jumptoSection('#overview')}>Overview</Link>
          <Link to={jumptoSection('#reports')}>Reports</Link>
        </div>
        {/* <div className='links_dropdown'>
           {organizationList.length > 1 && <CampaignSelectComponent
            clientFilterList={clientFilterList}
            dashType={dashType}
            setCurrentPage={setCurrentPage}
          ></CampaignSelectComponent>}
         
        </div> */}
        <div style={{ display: 'flex', flexDirection: 'row', height: '38px' }}>
          {activeOrganization && checkPaddington && (
            <NavLink to="/reporting/userAdmin" onClick={() => setDashType(DashboardTypes.HOME)} className="useradmin">
              <SVG_SETTINGS className="svg" />
              {/* <h4 className="navigationTitle">User Admin</h4> */}
            </NavLink>
          )}
          <section className="user">
            <p>
              {user?.signInUserSession.idToken?.payload?.name?.at(0) ?? ''}
              {user?.signInUserSession.idToken?.payload?.family_name?.at(0) ?? ''}
            </p>
          </section>
        </div>
      </Header>
      {activeOrganization && userAccess ? (
        <Main>
          <section className="overview" id="overview">
            <h1>
              Welcome to <span>{agencyNameCapitalized} portal</span>
              <br />
              Our all-in-one platform
            </h1>
            <div className="organizationButtons">
              {
                /* isOnlyDanish && */ !isThereAnyReadPlus && !anyPublish ? (
                  <button disabled>Coming Soon</button>
                ) : (
                  <>
                    <button onClick={() => redirectToPage('/reporting/portal')}>Go to campaign portal</button>
                    {organizationList.length > 1 && (
                      <h2 style={{ fontFamily: 'fontRegular', fontWeight: 'normal', fontSize: '0.9vw' }}> - or - </h2>
                    )}
                    {organizationList.length > 1 && (
                      <button onClick={() => redirectHome()} style={{ fontSize: 'inherit', boxShadow: 'none' }}>
                        Change organization
                      </button>
                    )}
                  </>
                )
              }
            </div>
            <div className="img">
              <img src={defaultImg} alt="" />
            </div>
          </section>
          {activeOrganization && (
            <section className="reports" id="reports">
              <h1>Reporting Dashboards</h1>
              <div className="dashboardsGrid">
                {dashboards
                  .filter((e) => checkCampaignListHas(e.id))
                  .filter((el) => (!userCanDoInsource ? el.title !== 'Insource Data' : el.title))
                  .map((dashboard) => (
                    <div onClick={() => redirectToPage(dashboard.link)} key={dashboard.id} className="box">
                      <h1>{dashboard.title}</h1>
                      <p>{dashboard.description}</p>
                      <div className="iconContainer">
                        {dashboard.id === 'TV' ? (
                          <SVG_TV></SVG_TV>
                        ) : dashboard.id === 'Digital' ? (
                          <SVG_DIGITAL></SVG_DIGITAL>
                        ) : dashboard.id === 'OOH' ? (
                          <SVG_OOH_KAMPAGNE></SVG_OOH_KAMPAGNE>
                        ) : dashboard.id === 'Radio' ? (
                          <SVG_RADIO></SVG_RADIO>
                        ) : dashboard.id === 'Print' ? (
                          <SVG_PRINT></SVG_PRINT>
                        ) : dashboard.id === 'Cinema' ? (
                          <SVG_CINEMA></SVG_CINEMA>
                        ) : dashboard.id === 'Insource' ? (
                          <div className="material-symbols-outlined andSVG">{creatorIcon}</div>
                        ) : (
                          <img src="" alt="" />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </section>
          )}
        </Main>
      ) : (
        <Main>
          <section className="overview" id="overview">
            <h1>
              Welcome to <span>{agencyNameCapitalized} portal</span>
              <br />
              Our all-in-one platform
            </h1>
            <div className="organizationButtons">
              <h3>Select a client to get started</h3>
              <div>
                {organizationList.length > 1 && (
                  <CampaignSelectComponent
                    clientFilterList={clientFilterList}
                    dashType={dashType}
                    setCurrentPage={setCurrentPage}
                  ></CampaignSelectComponent>
                )}
              </div>
            </div>
            <div className="img">
              <img src={defaultImg} alt="" />
            </div>
          </section>
        </Main>
      )}
    </HomePage2Container>
  );
};

export default HomePage2;
