
const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


export function Radio12(data: any, theme: any) {
    const theData = [] as any;
    const categ = [] as any;


    categ.push("1+", "2+", "3+", "4+", "5+", "6+", "7+", "8+", "9+", "10+")
    delete data[0].subcampaign_id


    const mappedData = Object.keys(data[0]).map(key => data[0][key])



    theData.push({
        name: "Reach",
        type: "column",
        color: colorArray[0],
        yAxis: 0,
        data: mappedData
    })


    return {
        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            type: "column",
            style: {
                fontFamily: "fontRegular"
            }
        },
        title: {
            text: '1+ Reach',
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: 'All Stations',
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },

        yAxis: {
            visible: false
        },
        xAxis: {
            categories: categ,
            crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },

        },
        tooltip: {
            useHTML: true,
            followPointer: true,
            shared: false,
            // Highcharts.TooltipFormatterContextObject
            formatter: function (this: any) {
                let cat = categ;
                const x = this.point.x;
                /*                 const a = this.y;
                                const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0) */
                return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "%"
            },
        },

        plotOptions: {
            column: {
                pointPadding: 0.1,
                distance: -60,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y) + "%"
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent'
                    }
                }
            },
        },
        series: theData,
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {

                return this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },

    }






}

