import { GetStoredQueryQuery, S3Method, GetPreSignedUrlQuery, Environment, UserQuery, MetricsResponse } from "API";
import { API } from "aws-amplify";
import { getPreSignedUrl } from "graphql/queries";


export async function getFilesFromS3(storedResponse: UserQuery, clientCode: string, clientCountry: string, env: Environment) {




    async function getFromS3(url: any) {
        try {
            const response = fetch(url, {
                method: 'GET',
            });
            /*             setIsImage(true); */
            const test = createFile((await response).url);

            return test
        } catch (err) {
            console.log({ err });
        }
    }

    async function createFile(url: any) {
        if (typeof window === 'undefined') return; // make sure we are in the browser
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = {
            type: (storedResponse.metrics as MetricsResponse[])[0].chartType || 'image/png',
        };
        return new File([data], storedResponse.query_id as string, metadata);
    }

    const getPresigned = async () => {
        try {
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.get_object,
                    Client: {
                        code: clientCode,
                        country: clientCountry,
                    },
                    Environment: env,
                    FileName: storedResponse.query_id,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            const url = preSignedURL.data.getPreSignedUrl?.url;
            const test = await getFromS3(url);
            return test
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }
    };

    const a = await getPresigned()
    return a
}