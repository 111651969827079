import {
  BottomRow,
  Breadcrumbs,
  Dimensions,
  Line,
  MidRow,
  NavigationButton,
  RightPane,
  Selections,
  TopRow,
} from 'components/Main/table/TableStyles';
import { placeholderFullSources } from './PlaceholderContainer';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { ChangeEvent, useState } from 'react';

interface Props {
  isGraph: Boolean;
  placeHolderDataSource: placeholderFullSources[];
  selectDimension: (e: ChangeEvent<HTMLInputElement>) => void;
  setPage(page: any): any;
  nextPage(): any;
  previousPage(): any;
  startNewQuery(): any;
}
const DimensionsContainer = (props: Props) => {
  const [serchTerm, setSearchTerm] = useState<string>('');
  const { isGraph, placeHolderDataSource, setPage, nextPage, previousPage, selectDimension, startNewQuery } = props;
  const selectedPlatform = placeHolderDataSource.find((e) => e.isSelected);

  return (
    <Dimensions displayDimensions={2}>
      <TopRow isGraph={isGraph}>
        <Breadcrumbs>
          <label style={{ cursor: 'pointer' }} onClick={() => setPage(1)}>
            {selectedPlatform?.name}
          </label>
          <Line>|</Line>
          <label>Dimensions:</label>
          {selectedPlatform?.dimensions
            ?.filter((e) => e.isSelected)
            .map((dimension: any, key: number) => (
              <span key={key}>{dimension.dimension}</span>
            ))}
        </Breadcrumbs>
      </TopRow>

      <MidRow>
        <h1>Dimensions</h1>
        <br />
        <h2>
          Chosen Dimensions :
          <span>
            <div style={{ width: isGraph === true ? '40vw' : 'auto', display: 'flex', flexWrap: 'wrap' }}>
              {selectedPlatform
                ?.dimensions?.filter((d) => d.isSelected)
                .map((sd: any) => (
                  <Selections chosen={true} key={sd.dimension as string}>
                    <input
                      type="checkbox"
                      checked={sd.isSelected}
                      name={sd.dimension}
                      value={sd.dimension}
                      onChange={(e) => selectDimension(e)}
                    />
                    <span> {sd.dimension}</span>
                  </Selections>
                ))}
            </div>
          </span>
        </h2>

        <RightPane>
          <input
            className="searchBar"
            type="text"
            placeholder="Search.."
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          ></input>
          <div className="listContainer">
            {/* <h1>{placeHolderDataSource?.filter(el => el.isSelected)[0].dimensions?.length}</h1> */}
            {selectedPlatform?.dimensions
              ?.filter((val) => val.dimension?.toLowerCase().includes(serchTerm.toLowerCase()))
              .map((dimension) => (
                <Selections key={dimension.dimension as string}>
                  <input
                    type="checkbox"
                    checked={dimension.isSelected}
                    name={dimension.dimension}
                    value={dimension.dimension}
                    onChange={(e) => selectDimension(e)}
                  />
                  <span>{dimension.dimension as string}</span>
                </Selections>
              ),
              )}
          </div>
        </RightPane>
      </MidRow>

      <BottomRow isGraph={props.isGraph}>
        <NavigationButton type="button" style={{ backgroundColor: 'transparent' }} onClick={startNewQuery}>
          <GoChevronLeft /> Back
        </NavigationButton>
        {selectedPlatform?.dimensions?.some((e) => e.isSelected) ? (
          <NavigationButton style={{ backgroundColor: 'transparent' }} type="button" onClick={nextPage}>
            Continue <GoChevronRight />
          </NavigationButton>
        ) : (
          <NavigationButton type="button" style={{ cursor: 'not-allowed' }}>
            Continue <GoChevronRight />
          </NavigationButton>
        )}
      </BottomRow>
    </Dimensions>
  );
};

export default DimensionsContainer;
