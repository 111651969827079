import React, { useState } from 'react'
import { FormInputContainer } from '../style/BriefInputStyle'
import { Inputs, valuesSavedFromClient } from '../APICall/BRIEFTEMPLATE'
import { enrichedBriefTemplateSectionSubColumns, enrichedbriefTemplateSectionColumns } from '../CustomHook/useBriefDataFromClient'
import { briefTemplateSectionColumns, briefTemplateSubColumn } from 'API'



interface Props {
  inputProps: briefTemplateSectionColumns | briefTemplateSubColumn,
  onChange: (e: any) => void,
  name?: string,
  value: any
}

const TextArea = (props: Props) => {
  const { onChange, name, inputProps, value } = props
  const { required, id } = inputProps
  const [focused, setFocused] = useState(false)


  return (
    <FormInputContainer >
      <>
        <textarea
          onChange={(e) => onChange({ e: e.target.value, id: id })}
          name={name}
          focused-name={focused.toString()}
          onBlur={() => setFocused(true)}
          value={value}

          className={required && (value === "" || value === undefined) && focused ? "errorMessageInput" : ""}
        />

      </>


    </FormInputContainer>
  )
}

export default TextArea