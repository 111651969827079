import SummaryChartsContainer from 'components/Main/graf/SummaryChartsContainer';
import { useCampaignContext } from 'context/CampaignContext';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SVG_NOTPUBLISHED } from '../../../../assets/newAssets/exclamationCircle.svg';
import { useGetSummaryStatus } from 'customHooks/useGettSummaryStatus';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import SummaryPerMediaStacked from 'components/Main/graf/SummryPerMediaStacked';

type Props = {};

const KPIChartsStyled = styled.div`
  display: flex;
  gap: 0.6vw;
  padding: 1vw 0 1vw 0.3vw;
`;

const KPIChartContainer = styled.div`
  width: 50%;
  height: 200px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  position: relative;
  font-weight: normal;
  display: flex;
  align-items: center;

  .stampNOTPublished {
    /*         position: absolute;
        top: 0;
        right: 0; */
    fill: ${({ theme }) => theme.c400};
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    /*     text-align: center; */
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;
    /* Position the tooltip */
    position: absolute;
    right: 10px;
    top: 30px;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

const KPICharts = (props: Props) => {
  const { costPerMedia, costPerWeek, activeCampaign } = useCampaignContext();
  // const [, pd] = useGetSummaryStatus()

  // const [
  //     storedQueries,
  //     ,
  //     ,
  //     ,
  //     ,
  //     ,
  //     ,
  // ] = useGetStoredQueriesDetails(activeCampaign, undefined, [], pd, () => { });

  // console.log("storedQueries", storedQueries)

  function getWeek(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  const leftOver: any[] = [];
  // in costPerWeek array, if year_week falls outside of activeCampaign start and end date, remove it from array
  const filteredCostPerWeek = costPerWeek?.filter((item) => {
    const yearWeek = item.year_week;
    const year = parseInt(yearWeek.substring(0, 4));
    const week = parseInt(yearWeek.substring(5, 7));
    const startDate = new Date(activeCampaign?.startDate || '1970-01-01');
    const endDate = new Date(activeCampaign?.endDate || '1970-01-01');
    const startYear = startDate.getFullYear();
    const startWeek = getWeek(startDate);
    const endYear = endDate.getFullYear();
    const endWeek = getWeek(endDate);
    if (year < endYear && week < 53) {
      return item;
    } else if (year > startYear && year === endYear && week > 0 && week < endWeek) {
      return item;
    } else if (year > startYear && year < endYear && week > 0 && endWeek && week < 53) {
      return item;
    } else if (year === startYear && year === endYear && week >= startWeek && week <= endWeek) {
      return item;
    } else {
      leftOver.push(item);
    }
  });

  const outsideCost = leftOver?.reduce((acc, item) => {
    return acc + item.actual_cost;
  }, 0);

  return (
    <KPIChartsStyled>
      <KPIChartContainer>
        {costPerMedia && costPerMedia.length > 0 && (
          <SummaryPerMediaStacked data={costPerMedia} title={'Cost per media (media net)'}></SummaryPerMediaStacked>
        )}
      </KPIChartContainer>
      <KPIChartContainer>
        {activeCampaign?.authorized && outsideCost && outsideCost > 0 ? (
          <span className="tooltip">
            <SVG_NOTPUBLISHED className="stampNOTPublished tooltip"></SVG_NOTPUBLISHED>
            <span className="tooltiptext">
              We've detected cost values(of <b> {Math.round(outsideCost * 100) / 100}</b> in total) in weeks that fall
              outside the campaign's start and end dates, and these are currently not displayed in the chart. If you'd
              like to view these values, please adjust the campaign dates accordingly.
            </span>
          </span>
        ) : null}
        {filteredCostPerWeek && filteredCostPerWeek.length > 0 && (
          <SummaryChartsContainer data={filteredCostPerWeek} title={'Cost per week'}></SummaryChartsContainer>
        )}
      </KPIChartContainer>
    </KPIChartsStyled>
  );
};

export default KPICharts;
