const TaboolaLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      x="0px"
      y="0px"
      viewBox="141 -98.9 392.6 306.9"
      width="25"
      height="25"
      style={{ marginRight: "5px", alignContent: "center", justifySelf: "center" }}
    >
      <style type="text/css">
        {`.st0{fill:#054164;}`}
      </style>
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,306.90667)">
        <g id="g12" transform="scale(0.1)">
          <path
            id="path14"
            className="st0"
            d="M1841.1,1526.1c-543,3.1-786,363.8-783.6,764.8c2.3,401,249.4,755.9,792.4,752.7    c543-3,786-360.8,783.6-761.9C2631.1,1880.8,2384.1,1522.9,1841.1,1526.1 M1847.6,2642.6c-208.9,1.3-238-201.9-238.9-355    c-0.8-153.1,25.8-359.4,234.6-360.7c208.8-1.1,237.9,204.7,238.9,358C2083.1,2438.1,2056.5,2641.5,1847.6,2642.6"
          ></path>
          <path
            id="path16"
            className="st0"
            d="M3209.3,1526c-542.9,3.3-785.9,364-783.5,764.9c2.2,400.9,249.4,755.9,792.3,752.7    c543-3.1,786-360.9,783.7-761.9C3999.5,1880.7,3752.3,1523,3209.3,1526 M3215.9,2642.7c-208.8,1.3-237.8-201.9-238.7-355.1    c-1-153.1,25.6-359.3,234.6-360.6c208.6-1.3,237.9,204.7,238.7,357.8C3451.3,2438.1,3424.6,2641.6,3215.9,2642.7"
          ></path>
          <path
            id="path18"
            className="st0"
            d="M1264.3,1526c391.5-174.9,797.7-252.5,1240.3-255.1c465-2.7,817.6,85.1,1264,255.1l-2.6-481.7    c-399.5-198.1-832.8-305-1264.5-302.5c-470.5,2.8-809.6,99.4-1240.1,302.5L1264.3,1526"
          ></path>
        </g>
      </g>

    </svg>

  );
};

export default TaboolaLogo;