import { heIL } from "@mui/x-date-pickers";

const colorArray = ["#698296", "#465663", "#576C7D", "#437BA8", "#5BA6E3"]


export function PRINT11(data: any, theme: any, value: number[], dateArray: any) {
    const theData = [] as any;
    const theAxis = [] as any;



    // this is returning 4 days before the first date in the data
    function getPreviousDays(date: any, subtractor: number) {
        const sevenDaysAgo: Date = new Date(Date.parse(date) - subtractor * 24 * 60 * 60 * 1000)
        return `${sevenDaysAgo.getFullYear()}-${sevenDaysAgo.getMonth() > 10 ? (sevenDaysAgo.getMonth() + 1) : "0" + (sevenDaysAgo.getMonth() + 1)}-${sevenDaysAgo.getDate() > 10 ? sevenDaysAgo.getDate() : "0" + sevenDaysAgo.getDate()}`;
    }
    // this is returning 4 days after the last date in the data
    function getUpcomingDays(date: any, additor: number) {
        const sevenDaysAgo: Date = new Date(Date.parse(date) + additor * 24 * 60 * 60 * 1000)
        return `${sevenDaysAgo.getFullYear()}-${sevenDaysAgo.getMonth() > 10 ? (sevenDaysAgo.getMonth() + 1) : "0" + (sevenDaysAgo.getMonth() + 1)}-${sevenDaysAgo.getDate() > 10 ? sevenDaysAgo.getDate() : "0" + sevenDaysAgo.getDate()}`;
    }


    const startDate: Date = new Date(Date.parse(dateArray[value[0]]))
    const endDate: Date = new Date(Date.parse(dateArray[value[1]]))
    // add two days to the end date
    endDate.setDate(endDate.getDate() + 2)
    // reduce the start date by two days
    startDate.setDate(startDate.getDate() - 2)


    const dateAdjustedData = data.filter((a: any) => new Date(a.date) >= startDate && new Date(a.date) <= endDate);

    const dataPerPublisher = dateAdjustedData.reduce(
        (result: any, item: any) => ({
            ...result,
            [item["publisher"]]: [
                ...(result[item["publisher"]] || []),
                item,
            ]
        }),
        {}
    )
    //sort dataPerPublisher alphabetically
    const sortedDataPerPublisher: any = {};
    Object.keys(dataPerPublisher).sort((a, b) => b.localeCompare(a)).forEach(function (key) {
        sortedDataPerPublisher[key] = dataPerPublisher[key];
    });



    function getDayOfWeek2(date: any) {
        const dayOfWeek = new Date(date).getDay();

        const startDate = new Date(date).getFullYear();
        const startdate2: any = new Date(startDate, 0, 1)
        const datedate: any = new Date(date)
        const days = Math.floor((datedate - startdate2) /
            (24 * 60 * 60 * 1000));
        var weekNumber = Math.ceil(days / 7);
        return {
            day: isNaN(dayOfWeek) ? null :
                ['S', 'M', 'T', 'W', 'T', 'F', 'S'][dayOfWeek],
            week: weekNumber
        }


    }




    const dataDatesArray = dateAdjustedData.map((e: any) => e.date as String)
    let uniqueData: any = [...new Set(dataDatesArray)].filter((a: any) => new Date(a) >= startDate && new Date(a) <= endDate);

    const start = getPreviousDays(uniqueData[0], 4)
    const end = getUpcomingDays(uniqueData[uniqueData.length - 1], 4)

    var getDaysArray = function (s: any, e: any) { for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) { a.push(new Date(d)); } return a; };
    var daylist = getDaysArray(new Date(start), new Date(end));
    const extendedDatePeriod = daylist.map(v => v.toISOString().split("T")[0])
    extendedDatePeriod.filter((a: any) => new Date(a) >= startDate && new Date(a) <= endDate);



    function compareArrays(shortArray: any, longArray: any) {
        let result = Array(longArray.length).fill(null);
        for (let i = 0; i < shortArray.length; i++) {
            const index = longArray.indexOf(shortArray[i]);
            if (index !== -1) {
                result[index] = shortArray[i];
            }
        }
        return result;
    }

    ;
    if (theData.length === 0)
        for (const [index, [key, value]] of Object.entries(sortedDataPerPublisher).entries()) {
            const mainDatePeriod = compareArrays((value as any[]).map((e: any) => e.date), extendedDatePeriod);
            const ser = [{
                name: null,
                showInLegend: false,
                color: theme.secondaryGray,
                data: extendedDatePeriod.map((e: any) => index),
                type: "scatter",
                marker: {
                    symbol: 'circle'
                }
            },
            {
                color: colorArray[index],
                name: (value as any[])[0].format,
                data: mainDatePeriod.map((e: any) => {
                    return {
                        y: e === null ? null : index,
                        name: e === null ? null : e.slice(-2),
                        date: e === null ? null : e,
                        publisher: key,
                        format: (value as any[])[0].format
                    }
                }),
                // data: mainDatePeriod.map((e: any) => e === null ? null : index),
                markerData: mainDatePeriod.map((e: any) => e === null ? null : e.slice(-2)),
                type: "scatter",
                marker: {
                    symbol: 'circle',

                }
            }];
            theData.push(...ser);
            theAxis.push(key);

        }




    const dataForCategories = extendedDatePeriod.map((e: any) => getDayOfWeek2(e))
    const transformedData = Object.values(dataForCategories.reduce((acc: any, obj: any) => {
        const name = obj.week;
        const categories = obj.day;

        if (!acc[name]) {
            acc[name] = {
                name: `Week ${name}`,
                categories: [],
            };
        }

        acc[name].categories.push(categories);
        return acc;
    }, {}));



    return {
        chart: {
            backgroundColor: 'transparent',
            type: "scatter",
            zoomType: 'xy',
            height: theAxis.length * 40 + 200,
            style: {
                fontFamily: "fontRegular"
            },

        },
        title: {
            text: 'Overview',
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: "All Media",
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },

        yAxis: {
            categories: theAxis,
            lineColor: 'transparent',
            gridLineWidth: 0,
            title: {
                style: {
                    color: "#698296",
                },
                enabled: false,
                rotation: 0,
            },
        },
        xAxis: {
            opposite: true,

            lineColor: 'transparent',
            categories: transformedData,
            tickWidth: 0,
            //  crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                },

            },


        },
        tooltip: {
            //followPointer: true,
            formatter: function (this: any) {
                if (this.point.publisher)
                    return '<b>' + this.point.publisher + ", " + this.point.format + '<b/> <br/>' + this.point.date
                else
                    return false
            }

        },

        plotOptions: {
            scatter: {
                pointPadding: -0.3,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: any) {
                        return this.point.name
                    },
                    style: {
                        textOutline: false,
                        fontSize: extendedDatePeriod.length < 30 ? "16px" : `${(600 / extendedDatePeriod.length)}px`,
                        color: theme.secondaryGray
                    },
                    y: 12
                },
                marker: {
                    radius: extendedDatePeriod.length < 30 ? 20 : (600 / extendedDatePeriod.length),
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: theme.primaryGray
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },

            },

        },
        series: theData,
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: 0,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {

                return this.name === null ? "" : this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },

    }
}