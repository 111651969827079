
import styled, { useTheme } from 'styled-components';
import { MainContainer, Graphs, Tables, AudienceReport } from 'components/Main/Main.styles';
import { useCampaignContext } from 'context/CampaignContext';
import { TxtContainer2, Add } from 'components/Main/table/TableStyles';
import { StyledGraphContainer } from 'components/Main/graf/GraphStyles';
import PlaceholderContainer from '../PlaceholderContainer';
import { useTemplateContext } from 'context/TemplateContext';
import { useDataSources } from 'customHooks/useDataSources';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import RadioKPIs from 'components/newSetup/RADIO/radioKPIs';
// import PreviewTV from 'assets/Preview/PreviewTV';
import ReactLoading from 'react-loading';
import { PublishContext } from 'context/PublishContext';
import { useContext, useEffect, useState } from 'react';
import ClientPlaceholderContainer from '../CLIENTCOMPONENTS/ClientPlaceholderContainer';
import NoCampaignSelected from '../NoCampaignSelected';
import InputContext from 'context/InputContext';
import { useClientContext } from 'context/ClientContext';
import { IImageList, useImageList } from 'customHooks/useImageList';
import ARContainer from 'components/Main/Gallery/ARContainer';

export const Alert = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  text-align: center;
  .text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
  }
`;



const RadioMain = () => {
    const { activeSubCampaign, activeCampaign } = useCampaignContext()
    const { fetchedPlaceholders } = useTemplateContext()
    const newPlaceholder = undefined
    const theme = useTheme()
    const [dataSources] = useDataSources(activeCampaign)
    const { publishedData } = useContext(PublishContext)
    const { setCapture } = useContext(InputContext)
    const [storedQueries, , , , , , publishedQueries] = useGetStoredQueriesDetails(activeCampaign, newPlaceholder, fetchedPlaceholders, publishedData)
    const { anyCreate } = useClientContext();
    const isAgency = anyCreate;


    const componentIDs = [
        ['Radio4.1', 'Radio4.2', 'Radio4.3', 'Radio4.4', 'Radio4.5'],
        ['Radio5.1', 'Radio5.2', 'Radio5.3', 'Radio5.4', 'Radio5.5'],
        ['Radio6.1', 'Radio6.2', 'Radio6.3', 'Radio6.4', 'Radio6.5'],
    ];

    const [uploadComponents, setUploadComponents] = useState<IImageList[][] | undefined>(undefined)
    const [list] = useImageList({ storedQueries: storedQueries, source: "Radio" })
    useEffect(() => {
        if (dataSources.length > 0) {
            setUploadComponents(list)
        } else {
            setUploadComponents(undefined)
        }
    }, [dataSources, list])


    return (

        <>
            {activeCampaign?.authorized && <button className='PDFbutton' onClick={() => setCapture(true)}>Download PPT</button>}

            {activeCampaign ?
                <>
                    {activeCampaign.authorized ?
                        <MainContainer key={activeSubCampaign?.subcampaignId}>
                            {activeSubCampaign?.subcampaignId !== -1 && storedQueries.length > 0 && dataSources.length > 0 ?
                                <>
                                    <RadioKPIs></RadioKPIs>
                                    <Graphs
                                        dashboard={"Radio"}
                                        columnsNumber={4}
                                    >
                                        {storedQueries.filter(el => el?.position?.startsWith("Radio1"))
                                            .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                            .map(e => (
                                                <PlaceholderContainer
                                                    componentID={e.position as string}
                                                    placeholderId={e.position as string}
                                                    dataSources={dataSources}
                                                    storedQueryDetails={e}
                                                    isGraph={true}
                                                ></PlaceholderContainer>
                                            ))}

                                    </Graphs>

                                    <Graphs dashboard={"TV"}
                                        columnsNumber={2}>
                                        {storedQueries.filter(el => el?.position?.startsWith("Radio2"))
                                            .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                            .map(e => (
                                                <PlaceholderContainer
                                                    componentID={e.position as string}
                                                    placeholderId={e.position as string}
                                                    dataSources={dataSources}
                                                    storedQueryDetails={e}
                                                    isGraph={true}
                                                ></PlaceholderContainer>
                                            ))}
                                    </Graphs>

                                    <Tables>
                                        {storedQueries.filter(el => el?.position?.startsWith("Radio3"))
                                            .map(e => (
                                                <PlaceholderContainer
                                                    componentID={e.position as string}
                                                    placeholderId={e.position as string}
                                                    dataSources={dataSources}
                                                    storedQueryDetails={e}
                                                    isGraph={false}
                                                ></PlaceholderContainer>
                                            ))}
                                    </Tables>
                                </> :
                                <StyledGraphContainer expand={0}>

                                    <TxtContainer2 isGraph={false} displayTxt={0}>
                                        <Add isGraph={true} >
                                            <div style={{ paddingTop: "50%" }} className='text'>
                                                <ReactLoading className="loaderCentered" type="spinningBubbles" color={theme.publishButton} />
                                            </div>
                                        </Add>
                                    </TxtContainer2>
                                </StyledGraphContainer>
                            }


                            {uploadComponents?.map((ids, index) => (
                                <AudienceReport key={index}>
                                    {ids.map((id: any) => (
                                        <ARContainer
                                            key={id.id}
                                            componentID={id.id}
                                            oldComponentID={
                                                null}
                                            visible={id.visible}
                                            setUploadComponents={setUploadComponents}
                                            uploadComponents={uploadComponents}
                                        />
                                    ))}
                                </AudienceReport>
                            ))}



                        </MainContainer>
                        :
                        <MainContainer>
                            <RadioKPIs></RadioKPIs>
                            <Graphs
                                dashboard={"Radio"}
                                columnsNumber={4}
                            >
                                {publishedQueries.filter(el => el?.position?.startsWith("Radio1"))
                                    .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                    .map(e => (
                                        <ClientPlaceholderContainer
                                            key={e.position}
                                            publishedQueriesDetails={e}
                                            componentID={e.position as string}
                                            isGraph={true}
                                        ></ClientPlaceholderContainer>
                                    ))}

                            </Graphs>

                            <Graphs dashboard={"TV"}
                                columnsNumber={2}>
                                {publishedQueries.filter(el => el?.position?.startsWith("Radio2"))
                                    .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                    .map(e => (
                                        <ClientPlaceholderContainer
                                            key={e.position}
                                            publishedQueriesDetails={e}
                                            componentID={e.position as string}
                                            isGraph={true}
                                        ></ClientPlaceholderContainer>
                                    ))}
                            </Graphs>

                            <Tables>
                                {publishedQueries.filter(el => el?.position?.startsWith("Radio3"))
                                    .map(e => (
                                        <ClientPlaceholderContainer
                                            key={e.position}
                                            publishedQueriesDetails={e}
                                            componentID={e.position as string}
                                            isGraph={false}
                                        ></ClientPlaceholderContainer>
                                    ))}
                            </Tables>

                            {componentIDs.map((ids, index) => (
                                <AudienceReport key={index}>
                                    {ids.map((id) => (
                                        <ARContainer
                                            key={id}
                                            componentID={id}
                                            oldComponentID={
                                                null}
                                        />
                                    ))}
                                </AudienceReport>
                            ))}


                        </MainContainer>
                    }




                </>
                :
                <NoCampaignSelected page={"Radio"}></NoCampaignSelected>
            }
        </>
    );
};

export default RadioMain;
