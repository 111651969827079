

import React, { useEffect, useState } from 'react'
import { KPIholder } from './KPIContainer'
import { TxtContainer } from 'components/Main/Main.styles'
import { mediaSource, GetCustomValueHistoryQuery, SaveCustomValueMutation } from 'API'
import { API } from 'aws-amplify'
import { getCustomValueHistory } from 'graphql/queries'
import { useCampaignContext } from 'context/CampaignContext'
import { saveCustomValue } from 'graphql/mutations'
import { useTheme } from 'styled-components'
import { KPItoS3 } from 'components/KPItoS3'

type Props = {
    componentID: string
}



const CustomKPI = (props: Props) => {


    return (
        <KPIholder className='KPIBox'>
            <TxtContainer >
                {/*   <h1 style={{ fontSize: "0.6vw" }}>Custom KPI</h1> */}
                <KPItoS3 location={"summary"} componentID={props.componentID}></KPItoS3>
            </TxtContainer>

        </KPIholder>
    )
}

export default CustomKPI