import { useContext, useState } from 'react';
import { UploadFileContainer } from '../style/BriefInputStyle';
import DragAndDrop3 from './3.1.DrangAndDrop';
import SelectedFilesList3 from './3.2.SelectedFilesList';
import { saveFiletoS3 } from './saveFiletoS3';
import { useCampaignContext } from 'context/CampaignContext';
import { DeleteStoredQueryMutation, Environment } from 'API';
import APIContext from 'context/APIContext';
import { env } from 'process';
import { QueryInfo } from '../Brief';
import { API } from 'aws-amplify';
import { deleteStoredQuery } from 'graphql/mutations';
import { useTab } from 'customHooks/useParameters';


type Props = {
  uploadNewFile(id: string, position: string, file: File): void;
  uploadedFiles: QueryInfo[];
  openFile: (queryInfo: QueryInfo) => void;
  editBrief: boolean;
  setUploadedFiles: (files: QueryInfo[]) => void;
  position: string
};

export const colorsForIcon: { [key: string]: string } = {
  PDF: 'rgb(255, 204, 123)',
  PNG: 'rgb(152, 205, 255)',
  JPG: '#b3fbf4',
  DOCX: '#babffe',
  TXT: '#e6fcac',
  ZIP: '#ffb0a2',
};

const UploadFile3 = (props: Props) => {
  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const { activeCampaign } = useCampaignContext();
  const { getEnvironment } = useContext(APIContext);
  const selectedFiles = props.uploadedFiles;

  const [tab] = useTab();


  const addFile = async (files: FileList) => {
    if (files && files.length > 0) {
      const fileList = Array.from(files) as File[];
      //  setSelectedFiles([...selectedFiles, ...fileList]);
      // const position = "Brief"



      if (activeCampaign) {
        const initialIndex = selectedFiles.length;
        fileList.forEach((f, index) => {
          const saved = saveFiletoS3(
            f,
            initialIndex + index + 1,
            activeCampaign.campaignId,
            activeCampaign.ClientCode,
            activeCampaign.ClientCountry,
            getEnvironment,
            props.position
          );

          props.uploadNewFile(saved.id, saved.position, f);
        });
      }
    }
  };

  async function removeFromS3(position: string) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const deleteStoredQ = (await API.graphql({
        query: deleteStoredQuery,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          Environment: getEnvironment,
          placeholder: position,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: DeleteStoredQueryMutation };
    } catch (e) {
      console.log('ERROR  DELETING QUERY', e);
    }
  }

  const removeFile = (queryInfo: QueryInfo) => {
    removeFromS3(queryInfo.position);
    props.setUploadedFiles(selectedFiles.filter((f) => f.id !== queryInfo.id));
  };

  const removeAllFiles = () => {
    // setSelectedFiles([]);
  };

  const tabPlanNoAuthorized = tab === "plan" && !activeCampaign?.authorized;

  return (
    <div>
      <UploadFileContainer>
        {!tabPlanNoAuthorized && <DragAndDrop3 selectedFiles={selectedFiles} addFile={addFile} />}
        {selectedFiles.length > 0 && (
          <SelectedFilesList3
            selectedFiles={selectedFiles}
            editBrief={props.editBrief}
            removeAllFiles={removeAllFiles}
            colorsForIcon={colorsForIcon}
            openFile={props.openFile}
            removeFile={removeFile}
            addFile={addFile}
          />
        )}
      </UploadFileContainer>
    </div>
  );
};

export default UploadFile3;
