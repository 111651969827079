import { agencyEnum, countryEnum, datatype, filtertype, queryInput } from 'API';
import { API, Storage } from 'aws-amplify';
import AuthContext from 'context/AuthContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useTemplateContext } from 'context/TemplateContext';
import { getStartDate, getEndDate } from 'methods/dateMethods';
import { useContext, useEffect, useState } from 'react';
const { REACT_APP_USER_FILES_S3_BUCKET, REACT_APP_AGENCY } = process.env;

export const useGetStoredQuery = (queryID: string): readonly [queryInput | undefined] => {
  const { user } = useContext(AuthContext);
  const { activeCampaign, activeSubCampaign } = useCampaignContext();
  const [query, setQuery] = useState<queryInput>();
  const { fetchedPlaceholders } = useTemplateContext();
  const arrayOfViewsUsedinDefaultTemplate = [
    {
      viewName: 'fozzie_facebook_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_custom_conversions_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_conversions_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_frequency_campaign_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_frequency_fb_campaign_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_frequency_adset_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_facebook_frequency_ad_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_google_dv360_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_google_dv360_conversions_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_tiktok_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_tiktok_frequency_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_snapchat_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_snapchat_conversions_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_snapchat_frequency_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_campaign_manager_bvod_excluded_',
      database: 'data_lake_denmark',
    },
    {
      viewName: 'fozzie_linkedin_',
      database: 'data_lake_denmark',
    },
  ];

  const createMainTotal = (mainQuery: queryInput) => {
    if (mainQuery) {
      const totalQuery = structuredClone(mainQuery);
      totalQuery.query.detailedDimensions?.forEach((element: any) => {
        const filteredOrder = totalQuery.query.orderBy?.filter((remove: any) => remove !== element.id);
        totalQuery.query.orderBy = filteredOrder;
      });
      totalQuery.query.detailedDimensions = [
        {
          column: 'campaign_id',
          name: 'campaign_id',
          hidden: false,
          id: 'pero',
        },
      ];
      if (totalQuery.query.detailedDimensions[0]?.id) {
        totalQuery.query.orderBy?.unshift(totalQuery.query.detailedDimensions[0].id);
      } //TODO: remove this
      // totalQuery.query.metrics = totalQuery.query.metrics?.map((element: any) => (element.column.endsWith("reach") && props.checkedPlatform === "Facebook") ? { ...element, column: "campaign_mapping_reach" } : element)
      // if (mainQuery.query.metrics?.some((el: any) => el.column.endsWith("reach") && props.checkedPlatform === "Facebook")) {
      //     if (mainQuery.query.filters?.some((el: any) => el.column !== "campaign_id")) {
      //         totalQuery.query.nullColumns = ["campaign_mapping_reach"]
      //     }
      //     if (!totalQuery.views.some((el: any) => el.viewName === `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`)) {
      //         totalQuery.views.push({
      //             viewName: `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`, database: activeCampaign?.ClientCountry === "DK" ? "data_lake_denmark" : activeCampaign?.ClientCountry === "SE" ? "data_lake_sweden" :
      //                 activeCampaign?.ClientCountry === "FI" ? "data_lake_finland" : "data_lake_norway"
      //         })
      //     }
      // }

      totalQuery.query.calculatedMetrics = totalQuery.query.calculatedMetrics?.map((element: any) =>
        element.query.includes('/MAX')
          ? {
              ...element,
              query: element.query.slice(0, element.query.indexOf('/')).concat('/MAX(#campaign_mapping_reach)'),
            }
          : element,
      );
      if (mainQuery.query.calculatedMetrics?.some((el: any) => el.query.includes('/MAX'))) {
        if (mainQuery.query.filters?.some((el: any) => el.column !== 'campaign_id')) {
          totalQuery.query.nullColumns = ['campaign_mapping_reach'];
        }
        if (
          !totalQuery.views.some((el: any) => el.viewName === `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`)
        ) {
          totalQuery.views.push({
            viewName: `fozzie_facebook_frequency_campaign_${REACT_APP_AGENCY}`,
            database:
              activeCampaign?.ClientCountry === 'DK'
                ? 'data_lake_denmark'
                : activeCampaign?.ClientCountry === 'SE'
                ? 'data_lake_sweden'
                : activeCampaign?.ClientCountry === 'FI'
                ? 'data_lake_finland'
                : 'data_lake_norway',
          });
        }
      }

      return totalQuery;
    }
  };

  useEffect(() => {
    const fetchStoredQuery = async () => {
      const link = await Storage.get(`${queryID}.json`, {
        level: 'protected',
        identityId: 'queries',
        region: 'eu-west-1',
        bucket: REACT_APP_USER_FILES_S3_BUCKET, //THE BUCKET
      });

      try {
        const response = await fetch(`${link}`);
        const data = (await response.json()) as queryInput & { queryid?: string };

        // CHANGE CAMPAIGN ID
        const findCampaignFilter = data.query.filters?.findIndex((el: any) => el.column === 'campaign_id');
        if (findCampaignFilter !== -1 && data.query.filters) {
          const filterObject = data.query.filters[findCampaignFilter as number];
          if (filterObject) {
            const filter = filterObject.filter;
            const campaignId = activeCampaign?.campaignId;
            const subCampaignId = activeSubCampaign?.subcampaignId;
            if (filter && campaignId && subCampaignId === -1) {
              filter.value = campaignId.toString();
            } else if (filter && campaignId && subCampaignId && subCampaignId !== -1) {
              data.query.filters = [
                {
                  column: 'subcampaign_id',
                  filter: {
                    filtertype: filtertype.EQUAL,
                    datatype: datatype.NUMBER,
                    value: subCampaignId.toString(),
                  },
                },
              ];
            }
          }
        }
        //CHANGE CLIENTS
        data.clients = [
          {
            country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
            client: activeCampaign?.ClientCode as string,
          },
        ];

        // CHANGE CC_NAME
        data.query.detailedDimensions?.forEach((el: any) => {
          if (
            el.column === 'conversion_name' ||
            el.column === 'filter_name' ||
            el.column === 'activity' ||
            el.column === 'floodlight_activity_name'
          ) {
            el.name = 'CC_name';
          }
        });
        data.query.metrics?.forEach(
          (el: any) => {
            if (
              el.column === 'custom_conversions_value' ||
              el.column === 'custom_conversions' ||
              el.column === 'total_conversions'
            ) {
              el.name = 'custom_conversion';
            }
          },
          // TODO ADD THE OTHER 2 AS WELL
          // added the 2 more for that
        );

        //REMOVE FILTERS IF FETCHED PLACEHOLDERS
        if (fetchedPlaceholders.length > 0) {
          data.query.filters = data.query.filters?.filter((el: any) => {
            return (
              el.column === 'campaign_id' ||
              el.column === 'subcampaign_id' ||
              el.column === 'conversion_name' ||
              el.column === 'filter_name' ||
              el.column === 'activity' ||
              el.column === 'floodlight_activity_name'
            );
          });
        }

        // CHANGE USER
        const newUser = {
          name: user?.username,
          agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
          country: countryEnum.DK,
        };
        data.user = newUser;
        //DELETE QUERY ID FROM RESUULT
        delete data.queryid;
        //CHANGE DATE
        data.date.from = getStartDate();
        data.date.to = getEndDate();
        setQuery(data);

        // MAYBE CHANGE VIEWS
        const deepEqual = (arr1: any[], arr2: any[]): boolean => {
          if (arr1.length !== arr2.length) return false;
          return arr1.every((obj, index) => {
            return Object.keys(obj).every((key) => obj[key] === arr2[index][key]);
          });
        };

        if (deepEqual(data.views, arrayOfViewsUsedinDefaultTemplate)) {
          console.log('dataforkita', data.views);

          const getDatabase = (country: string | undefined) => {
            switch (country) {
              case 'DK':
                return 'data_lake_denmark';
              case 'SE':
                return 'data_lake_sweden';
              case 'FI':
                return 'data_lake_finland';
              default:
                return 'data_lake_norway';
            }
          };
          const platforms = [
            'google_dv360',
            'facebook',
            'adform_bvod_excluded',
            'campaign_manager_bvod_excluded',
            'google_ads_video',
            'google_dv360_youtube',
            'tiktok',
            'snapchat',
            'linkedin',
            'readpeak',
            'taboola',
            'pinterest',
            'marathon_digital_tracking',
            'campaign_manager_olv',
            'adform_olv',
          ];

          const views = platforms.map((platform) => ({
            viewName: `fozzie_${platform}_`,
            database: getDatabase(activeCampaign?.ClientCountry),
          }));
          data.views = views;
        }
        console.log('QUERY ON STORED FOR', queryID, data);
      } catch (e) {
        console.error('error fetching storedQuery', e);
      }
    };

    if (queryID) {
      fetchStoredQuery();
    } else {
      setQuery(undefined);
    }
  }, [activeCampaign, activeSubCampaign?.subcampaignId, fetchedPlaceholders, queryID, user?.username]);

  return [query];
};
