import { BottomRow, Line, NavigationButton, Overview, TopRow, Breadcrumbs, MidRow } from 'components/Main/table/TableStyles'
import React, { useEffect } from 'react'
import { placeholderFullSources } from './PlaceholderContainer';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { DarkButton } from 'components/style/Global';
import { useTheme } from 'styled-components'
type Props = {
    isGraph: Boolean;
    placeHolderDataSource: placeholderFullSources[];
    setPage(page: any): any;
    nextPage(): any;
    previousPage(): any;
    page: number;
    prepUserQuery(): any;
    toggleHiddenDimension(dimensionColumn: string): any;
}

const OverviewContainer = (props: Props) => {
    const { isGraph, placeHolderDataSource, setPage, nextPage, previousPage, page, prepUserQuery, toggleHiddenDimension } = props
    // const selectedPlatform = placeHolderDataSource.find(e => e.isSelected)
    const [selectedPlatform, setSelectedPlatform] = React.useState<placeholderFullSources>()

    useEffect(() => {
        setSelectedPlatform(placeHolderDataSource.find(e => e.isSelected))
    }, [placeHolderDataSource])
    const theme = useTheme()

    return (
        <Overview displayOverview={5}>
            <TopRow isGraph={isGraph}>
                <Breadcrumbs>
                    <label style={{ cursor: "pointer" }} onClick={() => setPage(1)}>{selectedPlatform?.name}</label>
                    <Line>|</Line>
                    <label style={{ cursor: "pointer" }} onClick={() => setPage(2)} >Dimensions:</label>
                    {selectedPlatform?.dimensions?.filter(e => e.isSelected).map((dimension: any, key: number) => (
                        <span key={key}>{dimension.dimension}</span>
                    )
                    )}
                    <Line>|</Line>
                    <label style={{ cursor: "pointer" }} onClick={() => setPage(3)} >Metrics:</label>
                    {selectedPlatform?.metrics?.filter(e => e.isSelected).map((metric: any, key: number) => (
                        <span key={key}>{metric.metric}</span>
                    )
                    )}
                    {selectedPlatform?.calcMetrics?.filter(e => e.isSelected).map((metric: any, key: number) => (
                        <span key={key}>{metric.name}</span>
                    )
                    )}
                </Breadcrumbs>

            </TopRow>

            <MidRow>
                <h1 style={{ marginBottom: '30px' }}>Overview</h1>
                <h2 style={{ marginBottom: '10px' }}>
                    Platform :{' '}
                    <span>
                        <button type="button">{selectedPlatform?.name}</button>
                    </span>
                </h2>
                <h2 style={{ marginBottom: '10px' }}>
                    Dimensions :{' '}
                    <span>
                        {selectedPlatform?.dimensions?.filter(e => e.isSelected)?.map(d => (
                            <React.Fragment key={d.dimension}>
                                <button style={{ color: d.detailedDimension?.hidden ? "red" : theme.primaryGray }} onClick={() => {
                                    if (d.detailedDimension?.hidden) {
                                        toggleHiddenDimension(d.detailedDimension.column);
                                    }
                                }} key={d.dimension} type="button" name={d.dimension}>
                                    {d.dimension}
                                    {d.detailedDimension?.hidden && (
                                        <span className="questionMark tooltip">
                                            ?<span className="tooltiptext">"This dimension is hidden, click on it if you wish to unhide it.</span>
                                        </span>
                                    )}
                                </button>
                            </React.Fragment>

                        ))}
                    </span>
                </h2>
                <h2 style={{ marginBottom: '10px' }}>
                    Metrics :{' '}
                    <span>
                        {' '}
                        {selectedPlatform?.metrics?.filter(q => q.isSelected).map(m => (
                            <button key={m.metric} type="button" name={m.metric} value={m.metric}>
                                {m.metric}
                            </button>
                        ))}
                        {selectedPlatform?.calcMetrics
                            .filter(q => q.isSelected)
                            .map(cm => (
                                <button key={cm.name} type="button" name={cm.name}>
                                    {cm.name}
                                </button>
                            ))}
                    </span>
                </h2>
                <h2 style={{ marginBottom: '10px' }}>
                    Conversions :{' '}
                    <span>
                        {' '}
                        {selectedPlatform?.customConversions?.filter(q => q.isSelected).map(c => (
                            <button key={c.CCname} type="button" name={c.CCname} value={c.CCname}>
                                {c.CCname}
                            </button>
                        ))}
                    </span>
                </h2>
            </MidRow>







            <BottomRow isGraph={isGraph}>
                <NavigationButton type="button" style={{ backgroundColor: 'transparent' }} onClick={previousPage}>
                    <GoChevronLeft /> Back
                </NavigationButton>
                <DarkButton type="button" onClick={() => prepUserQuery()} className="scaleHover" style={{ width: '150px' }}>
                    FETCH
                </DarkButton>
            </BottomRow>
     


        </Overview>
    )
}

export default OverviewContainer