import { DefaultTheme } from 'styled-components';
const { REACT_APP_AGENCY } = process.env;

export const darkTheme: DefaultTheme = getDarkTheme(REACT_APP_AGENCY);
export const lightTheme: DefaultTheme = getLightTheme(REACT_APP_AGENCY);

function getLightTheme(prop: any) {
  if (prop === 'hs') {
    return {
      scrollThumb: 'rgba(0, 0, 0, 0.1)',
      background: '#F7F8FA',
      placeholderBackground: '#FFFFFF',
      border: '#E8EAEF',
      primaryGray: '#A1AEBE',
      secondaryGray: '#B5BEC9',
      brand: '#2CB891',
      publishButton: '#FF0198',
      c50: '#FFF0FA',
      c100: '#FFE3F8',
      c200: '#FFC6F2',
      c300: '#FF98E5',
      c400: '#FF58D1',
      c500: '#FF27BB',
      c600: '#FF0099',
    };
  } else if (prop === 'phd') {
    return {
      scrollThumb: 'rgba(0, 0, 0, 0.1)',
      background: '#F7F8FA',
      placeholderBackground: '#FFFFFF',
      border: '#E8EAEF',
      primaryGray: '#A1AEBE',
      secondaryGray: '#B5BEC9',
      publishButton: '#38164f',
      brand: '#38164f',
      c50: '#F9F6FE',
      c100: '#F2EBFC',
      c200: '#E6DAFA',
      c300: '#D3BDF5',
      c400: '#B993ED',
      c500: '#9E69E3',
      c600: '#8749D4',
    };
  } else {
    return {
      scrollThumb: 'rgba(0, 0, 0, 0.1)',
      background: '#F7F8FA',
      placeholderBackground: '#FFFFFF',
      border: '#E8EAEF',
      primaryGray: '#807F83',
      secondaryGray: '#C9CACC',
      brand: '#EE3123',
      publishButton: '#EE3123',
      c50: '#FFF2F1',
      c100: '#FFE2E0',
      c200: '#FFCAC6',
      c300: '#FFA69F',
      c400: '#FF7367',
      c500: '#FB4738',
      c600: '#E42616',
    };
  }
}

function getDarkTheme(prop: any) {
  if (prop === 'hs') {
    return {
      scrollThumb: '#7e7e7e',
      background: '#1A1B1E',
      placeholderBackground: '#27292A',
      border: '#353738',
      primaryGray: '#B5BEC9',
      secondaryGray: '#807F83',
      brand: '#2CB891',
      publishButton: '#FF0198',
      c50: '#FFF0FA',
      c100: '#FFE3F8',
      c200: '#FFC6F2',
      c300: '#FF98E5',
      c400: '#FF58D1',
      c500: '#FF27BB',
      c600: '#FF0099',
    };
  } else if (prop === 'phd') {
    return {
      scrollThumb: '#7e7e7e',
      background: '#1A1B1E',
      placeholderBackground: '#27292A',
      border: '#353738',
      primaryGray: '#B5BEC9',
      secondaryGray: '#807F83',
      brand: '#00d1b3',
      publishButton: '#00d1b3',
      c50: '#F9F6FE',
      c100: '#F2EBFC',
      c200: '#E6DAFA',
      c300: '#D3BDF5',
      c400: '#B993ED',
      c500: '#9E69E3',
      c600: '#8749D4',
    };
  } else {
    return {
      scrollThumb: '#7e7e7e',
      background: '#1A1B1E',
      placeholderBackground: '#27292A',
      border: '#353738',
      primaryGray: '#C9CACC',
      secondaryGray: '#807F83',
      brand: '#EE3123',
      publishButton: '#EE3123',
      c50: '#FFF2F1',
      c100: '#FFE2E0',
      c200: '#FFCAC6',
      c300: '#FFA69F',
      c400: '#FF7367',
      c500: '#FB4738',
      c600: '#E42616',
    };
  }
}
