import styled from 'styled-components';

export const HomePage2Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  width: 80vw;
  /*   height: 400vh; */
  padding-bottom: 5vw;
`;

interface IHeader {
  orgLength: number;
}

export const Header = styled.div<IHeader>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 70px;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;

  .links_dropdown {
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.orgLength > 1 ? 'space-between' : 'center')};
    width: 100%;
    padding: 0 40px;
    /* justify-content: center; */
    > * {
      .react-select__control {
        width: 250px;
        > * {
          /* &:first-child {
            width: 100%;
          } */
        }
      }
    }
  }

  .links {
    display: flex;
    gap: 30px;
  }
  a {
    font-size: 16px;
  }
  .user {
    background-color: ${({ theme }) => theme.c400};
    display: inline-block;
    font-size: 16px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    p {
      color: ${({ theme }) => theme.c50};
    }
  }
  .useradmin {
    /* background-color: ${({ theme }) => theme.c400}; */
    display: flex;
    font-size: 16px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    margin: auto;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    p {
      color: ${({ theme }) => theme.c50};
    }
    .svg {
      /* width: px; */
      height: 1vw;
      /* line-height: 18px; */
      fill: ${({ theme }) => theme.primaryGray};
      stroke-width: 5px;
      width: 100%;

      path {
        stroke: ${({ theme }) => theme.primaryGray};
        stroke-width: 0.1;
      }
    }
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    height: 100%;
    padding-top: 4.6vw;
    /* border: 1px solid ${({ theme }) => theme.border}; */
  }

  .overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .organizationButtons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      width: 100%;
      /* border: 1px solid red; */
      align-content: center;
      align-items: center;
      * {
        margin: 0;
        justify-content: center;
        max-width: 600px;
      }
      h3 {
        font-size: 20px;
        color: ${({ theme }) => theme.secondaryGray};
        font-family: 'FontRegular', sans-serif;
        font-weight: normal;
      }
      .react-select__control {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
        border-color: ${({ theme }) => theme.secondaryGray};
        width: 300px;
      }
      button {
        text-wrap: nowrap;
      }
    }

    h1 {
      font-size: 4.2vw;
      margin-bottom: 20px;
      /* padding-top: 100px; */
      span {
        color: ${({ theme }) => theme.brand};
      }
    }
    button {
      margin-top: 20px;
      font-size: 18px;
    }

    .img {
      padding-top: 6.25vw;
      width: 100%;
    }
    img {
      border-radius: 40px;
      width: 100%;
      object-fit: contain;
    }
  }

  .reports {
    .dashboardsGrid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
      gap: 50px;
      padding: 60px;
      justify-items: center;

      .box {
        z-index: 0;
        position: relative;
        cursor: pointer;
        width: 300px;
        height: 350px;
        border-radius: 10px;
        padding: 35px;
        background-image: ${({ theme }) => theme.brand};
        background-image: linear-gradient(37.33deg, #fff 0%, ${({ theme }) => theme.c50} 100%);
        -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

        h1 {
          font-size: 28px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          margin-bottom: 10px;
        }
        .iconContainer {
          position: absolute;
          bottom: 0;
          /* right: 0; */
          /* border: 1px solid red; */
          width: 230px;
          height: 220px;
          overflow: hidden;

          svg {
            /* position: absolute; */
            /* right: 0; */
            display: flex;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            fill: ${({ theme }) => theme.c200};
            padding: 15% 5% 25% 10%;
          }

          .andSVG {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 130px; /* Adjust the font size as needed */
            color: ${({ theme }) => theme.c200};
            text-align: center;
            padding: 15% 5% 25% 10%;
            /* font-family: 'FontRegular', sans-serif; */

            line-height: 1; /* Ensure the line height does not affect the size */
          }
          img {
            border-radius: 40px;
            width: 100%;
            object-fit: contain;
            height: 100%;
            padding: 15% 5% 5% 10%;
          }

          .material-symbols-outlined {
            font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 48;
          }
        }
      }
    }
  }
`;
