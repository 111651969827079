import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { usePortalContext } from 'context/PortalContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as SVG_DIGITAL } from '../../../../assets/newAssets/Property 1=digital.svg';
import { ReactComponent as SVG_OOH_KAMPAGNE } from '../../../../assets/newAssets/Property 1=ooh.svg';
import { ReactComponent as SVG_PRINT } from '../../../../assets/newAssets/Property 1=print.svg';
import { ReactComponent as SVG_RADIO } from '../../../../assets/newAssets/Property 1=radio.svg';
import { ReactComponent as SVG_TV } from '../../../../assets/newAssets/Property 1=video.svg';
import { ReactComponent as SVG_CINEMA } from '../../../../assets/SVG/cinema.svg';
import { ReactComponent as SVG_PUBLISHED } from '../../../../assets/newAssets/checkmarkCircle.svg';
import { DashboardTypes, FlowchartCampaignStatus, GetQueriesQuery } from 'API';
import { QueryInfo } from '../Brief/Brief';
import { API } from 'aws-amplify';
import { getQueries } from 'graphql/queries';
import { useUpdateCampaignStatus } from '../Brief/CustomHook/useUpdateCampaignStatus';
import ClientStatementsContext from 'context/ClientStatementsContext';
import DocumentUploader from './DocumentUploader';
import { useTab } from 'customHooks/useParameters';
import { useClientContext } from 'context/ClientContext';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

type Props = {
  document: QueryInfo[];
  setDocument: React.Dispatch<React.SetStateAction<QueryInfo[]>>;
  uploadedFiles: QueryInfo[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<QueryInfo[]>>;
  isClient: boolean;
  openSelectedDocumentInNewTab: () => void;
  fullAccess: boolean;
  readAccess: boolean;
  noAccess: boolean;
  isUserAuthorized: boolean | undefined;
  onFileOpen: (file: QueryInfo) => void;
};
interface ContainerProps {
  active?: string;
  theme: any;
}

const ReportsContainer = styled.div<ContainerProps>`
  display: flex;
  gap: 0.6vw;
  padding: 1vw 0 0vw 0.3vw;

  .IsDisabled {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    pointer-events: none;
    cursor: not-allowed;
  }
`;
export const IconContainer = styled.div<ContainerProps>`
  position: relative;
  padding: 1vw;
  border: 1px solid ${(props) => (props.active === 'true' ? props.theme.brand : props.theme.border)};
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  aspect-ratio: 1 / 1;
  width: 5vw;
  height: 5vw;
  cursor: ${(props) => (props.active === 'true' ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.active === 'false' ? 'none' : 'default')};

  .green {
    color: #e8eaef !important;
  }

  h2 {
    font-size: 0.7vw;
    font-family: 'fontRegular';
    font-weight: normal;
    text-align: center;
    color: ${(props) => (props.active === 'true' ? props.theme.brand : props.theme.border)};
  }

  .svg {
    height: 2vw;
    fill: ${(props) => (props.active === 'true' ? props.theme.brand : props.theme.border)};
    // stroke-width: 5px;
    width: 1.5vw;

    path {
      stroke: ${(props) => (props.active === 'true' ? props.theme.brand : props.theme.border)};
      stroke-width: 0.1;
    }
  }
  .stampPublished {
    display: ${(props) => (props.active === 'true' ? 'block' : 'none')};
    position: absolute;
    bottom: -10px;
    right: -10px;
    fill: green;
    background-color: ${({ theme }) => theme.placeholderBackground};
  }
  .stampNOTPublished {
    display: ${(props) => (props.active === 'true' ? 'block' : 'none')};
    position: absolute;
    bottom: -10px;
    right: -10px;
    fill: ${({ theme }) => theme.c400};
    background-color: ${({ theme }) => theme.placeholderBackground};
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 48;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    height: 100%; */
    font-size: 1.5vw; /* Adjust the font size as needed */
    color: ${({ theme }) => theme.brand};
    text-align: center;
    line-height: 1; /* Ensure the line height does not affect the size */
  }
`;

export const checkIfPublished = (type: string, activeCampaign: any) => {
  // console.log('activeCampaign', activeCampaign, type);
  // make type all uppercase
  type = type === 'Video' ? 'TV' : type === 'Audio' ? 'RADIO' : type;
  type = type.toUpperCase();
  const arr: any = [];
  if (activeCampaign?.subcampaign) {
    for (const [key, value] of Object.entries(activeCampaign.subcampaign)) {
      const publishedobj = (value as any).published;
      arr.push(publishedobj[type]);
    }
  }
  return arr.some((e: boolean) => e === true);
};

const ReportsLinks = (props: Props) => {
  const {
    setDocument,
    document,
    uploadedFiles,
    setUploadedFiles,
    isClient,
    openSelectedDocumentInNewTab,
    noAccess,
    isUserAuthorized,
    onFileOpen,
  } = props;
  const themee = useTheme();
  const { activeCampaign, setDashType } = useCampaignContext();
  const { userCanDoInsource } = useClientContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setDrawerIsOpen } = usePortalContext();
  const navigate = useNavigate();
  const { statements } = useContext(ClientStatementsContext);
  const [tab] = useTab();

  const handleClick = (link: string) => {
    if (link === '/reporting/onlinePortal') {
      setDashType(DashboardTypes.DIGITAL);
    } else if (link === '/reporting/TVPortal') {
      setDashType(DashboardTypes.TV);
    } else if (link === '/reporting/radioPortal') {
      setDashType(DashboardTypes.RADIO);
    } else if (link === '/reporting/printPortal') {
      setDashType(DashboardTypes.PRINT);
    } else if (link === '/reporting/OOHPortal') {
      setDashType(DashboardTypes.OOH);
    } else if (link === '/') {
      setDashType(DashboardTypes.HOME);
    } else if (link === '/reporting/portal') {
      setDashType(DashboardTypes.FLOWCHART);
    } else if (link === '/reporting/cinemaPortal') {
      setDashType(DashboardTypes.CINEMA);
    }
    searchParams.delete('tab');
    setSearchParams(searchParams);
    setDrawerIsOpen(false);
    const search = searchParams.toString();
    const url = `${link}?${search}`;
    //  const search = createSearchParams({ client: selectedClient  }).toString()
    // navigate({
    //   pathname: link,
    //   search: search,
    // });

    navigate(url, {
      state: { tab: tab },
    });
  };

  const reportIconList = [
    {
      name: 'Digital',
      icon: SVG_DIGITAL,
      link: '/reporting/onlinePortal',
    },
    {
      name: 'Video',
      icon: SVG_TV,
      link: '/reporting/TVPortal',
    },
    {
      name: 'Audio',
      icon: SVG_RADIO,
      link: '/reporting/radioPortal',
    },
    {
      name: 'Print',
      icon: SVG_PRINT,
      link: '/reporting/printPortal',
    },
    {
      name: 'OOH',
      icon: SVG_OOH_KAMPAGNE,
      link: '/reporting/OOHPortal',
    },
    {
      name: 'Cinema',
      icon: SVG_CINEMA,
      link: '/reporting/cinemaPortal',
    },
  ];
  // userCanDoInsource &&
  //   reportIconList.push({
  //     name: 'Inource Data',
  //     icon: SVG_DIGITAL,
  //     link: '/reporting/insourceDataPortal',
  //   });

  const [UpdateCampaign] = useUpdateCampaignStatus();
  const { campaignList, setPermanentCampaignList } = useCampaignContext();

  // const checkPublishedReports = () => {
  //   reportIconList.forEach((item) => {
  //     if (checkIfPublished(item.name, activeCampaign)) {

  //       if (activeCampaign?.status === 'PLANNING_DONE') {
  //         const result = UpdateCampaign(activeCampaign, FlowchartCampaignStatus.CAMPAIGN_PARTLY_PUBLISHED);

  //         const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
  //         const newCampaign = {
  //           ...campaign,
  //           status: 'CAMPAIGN_PARTLY_PUBLISHED',
  //         } as ACdetails;

  //         const newCampaignList = campaignList?.map((campaign: ACdetails) => {
  //           if (campaign?.campaignId === activeCampaign?.campaignId) {
  //             return newCampaign;
  //           } else {
  //             return campaign;
  //           }
  //         }) as ACdetails[];

  //         setPermanentCampaignList(newCampaignList);
  //       }
  //     }
  //   });
  // };

  // Call the method

  // checkPublishedReports();

  const uploadNewFile = (id: string, position: string, file: File) => {
    setUploadedFiles((prev) => [...prev, { id: id, position: position, name: id.split('_')[1], file: file }]);
  };

  const postDocument = (file: File | undefined, previewFile?: any) => {
    if (previewFile !== undefined) {
      setDocument(previewFile);
    } else {
      if (document.length > 0) {
        const test = {
          id: '1',
          position: '1',
          name: file?.name,
        } as unknown as QueryInfo;
        // setDocument((prevDocuments) => [...prevDocuments, test]);
        setDocument([test]);
      } else {
        if (file !== undefined) {
          const test = {
            id: '1',
            position: '1',
            name: file.name,
            file: file,
          } as unknown as QueryInfo;
          // setDocument((prevDocuments) => [...prevDocuments, test]);
          setDocument([test]);
        }
      }
    }
  };

  async function fetchUploadedFiles(): Promise<QueryInfo[]> {
    try {
      const response = (await API.graphql({
        query: getQueries,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(10),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetQueriesQuery };

      // console.log('This is the item I want to save!', response);

      if (response.data.getQueries) {
        const res = response.data.getQueries;
        const queries = res.filter((query) => query?.position?.includes('SummaryUploadFile'));
        return queries.map((q) => {
          return { id: q?.query_id ?? '', position: q?.position ?? '', name: q?.query_id?.split('_')[1] ?? '' };
        });
      }
      return [] as QueryInfo[];
    } catch (e) {
      console.error('ERROR FETCHING QUARIES', e);
      return [] as QueryInfo[];
    }
  }

  const addToFileList = async () => {
    const queryInfos = await fetchUploadedFiles();
    // console.log('This is the item I want to save!', queryInfos);
    postDocument(undefined, queryInfos);
    setUploadedFiles(queryInfos);
  };

  useEffect(() => {
    if (activeCampaign && searchParams.get('tab') === 'summary') {
      addToFileList();
    }
  }, [activeCampaign, searchParams]);

  const [bubleVisible, setBubleVisible] = useState(false);
  const handleBuble = () => {
    setBubleVisible(!bubleVisible);
    setTimeout(() => {
      setBubleVisible(false);
    }, 3000);
  };
  const creatorIcon = iconList.find((icon) => icon === 'screen_share');

  const InsourceElement = (
    <>
      <IconContainer onClick={() => handleClick('/reporting/insourceDataPortal')} active={'true'} theme={themee}>
        <div className="svg material-symbols-outlined  ">{creatorIcon}</div>
        <h2>Insource Data</h2>
      </IconContainer>
    </>
  );

  return (
    <ReportsContainer>
      {reportIconList.filter((rep) => (!isUserAuthorized ? checkIfPublished(rep.name, activeCampaign) : rep)).length ===
      0 ? (
        <div className="error">
          <h4>No dashboards are available at the moment.</h4>
          {userCanDoInsource && activeCampaign?.dashboardType.some((e) => e === 'INSOURCE_DATA') && (
            <>
              <h4>Dashboards available with your own data:</h4>
              {InsourceElement}
            </>
          )}
        </div>
      ) : (
        <>
          {reportIconList
            .filter((rep) => (!isUserAuthorized ? checkIfPublished(rep.name, activeCampaign) : rep))
            .map((item) => {
              return (
                <React.Fragment key={item.name}>
                  <IconContainer
                    onClick={() =>
                      !isUserAuthorized && !checkIfPublished(item.name, activeCampaign)
                        ? handleBuble()
                        : handleClick(item.link)
                    }
                    active={activeCampaign?.dashboardType
                      .some((e) => (e === 'TV' ? 'VIDEO' : e === 'RADIO' ? 'AUDIO' : e) === item.name.toUpperCase())
                      .toString()}
                    theme={themee}
                  >
                    <item.icon className="svg"></item.icon>
                    {checkIfPublished(item.name, activeCampaign) && (
                      <SVG_PUBLISHED className="stampPublished"></SVG_PUBLISHED>
                    )}
                    <h2>{item.name}</h2>
                  </IconContainer>
                  <span className="buble">
                    <span className="bubletext" style={{ visibility: bubleVisible ? 'visible' : 'hidden' }}>
                      "This campaign has yet to be evaluated."
                    </span>
                  </span>
                </React.Fragment>
              );
            })}

          {userCanDoInsource && activeCampaign?.dashboardType.some((e) => e === 'INSOURCE_DATA') && InsourceElement}
        </>
      )}

      <>
        <>
          <DocumentUploader
            document={document}
            setDocument={setDocument}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            isClient={isClient}
            openSelectedDocumentInNewTab={openSelectedDocumentInNewTab}
            fullAccess={statements?.Queries?.CREATE}
            readAccess={statements?.Queries?.READ}
            noAccess={noAccess}
            isUserAuthorized={isUserAuthorized}
            postDocument={postDocument}
            onFileOpen={onFileOpen}
          ></DocumentUploader>
        </>
      </>
    </ReportsContainer>
  );
};

export default ReportsLinks;
