/**
 * Adds a specified number of months to a date.
 *
 * @param monthsToAdd - The number of months to add to the date.
 * @param dateString - The date to add months to, as a string in the "yyyy-mm-dd" format. Defaults to today's date if not provided.
 * @returns The new date, as a string in the "yyyy-mm-dd" format.
 *
 * @example
 * // Add 2 months to January 1, 2022
 * addMonthsToDate(2, "2022-01-01");
 *
 * // Add 2 months to today's date
 * addMonthsToDate(2);
 */
export function addMonthsToDate(monthsToAdd: number, dateString?: string): string {
    // Create a new Date object from the date string, or from today's date if dateString is not provided
    let date = dateString ? new Date(dateString) : new Date();
    // Add the specified number of months to the date
    date.setMonth(date.getMonth() + monthsToAdd);
    // Format the date as a string in the "yyyy-mm-dd" format
    return date.toISOString().split('T')[0];
}



// GetStartDate should be a method, that always returns the formatted date 18 months ago
/**
 *
 * @returns The date 18 months ago, as a string in the "yyyy-mm-dd" format.
 *
 */

export function getStartDate(): string {
    return addMonthsToDate(-18);
}

// GetEndDate should be a method, that always returns the formatted date 2 months from now
/**
 *
 * @returns The date 2 months from now, as a string in the "yyyy-mm-dd" format.
 *
 */

export function getEndDate(): string {
    return addMonthsToDate(2);
}

export const europinzedDate = (date: string) => {
    const dateArray = date ? date.split('-') :"";
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
  }