import styled from 'styled-components';

interface CommentsContainerProps {
  header: Boolean;
}

export const CommentsContainer = styled.div<CommentsContainerProps>`
  border: 2px solid ${({ theme }) => theme.border};
  position: absolute;
  flex-direction: column;
  width: 500px;
  right: ${(props) => (props.header === true ? '100px' : '70px')};
  top: ${(props) => (props.header === true ? '70px' : '20px')};
  padding: 7px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  z-index: 100;
`;
export const Bulletin = styled.div`
  height: auto;
  width: 100%;
  margin: 10px auto;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  display: grid;
  gap: 10px;
  padding: 8px;
`;

export const Head = styled.h4`
 color: ${({ theme }) => theme.secondaryGray};  
 font-size: 10px;
`;

export const Action = styled.div`
  display: flex;
  height: auto;
  justify-self: end;
  

  button {
    margin-left: 5px;
  }
`;

export const Input = styled.div`
  display: grid;
  gap: 1vw;
  width: 100%;
  margin: auto;
  margin-top: 1vw;
  textarea  {
    min-height: 200px;
    border-radius: 7px;
    resize: none;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color: ${({ theme }) => theme.background};
    font-size: 16px;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
}

.editComment {
  height: 400px;
}
`;
