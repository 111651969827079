import React, { useEffect, useRef, useState, useContext } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { queryInput } from 'API';

import styled, { useTheme } from 'styled-components';
import { useCampaignContext } from 'context/CampaignContext';

import InputContext from 'context/InputContext';
import { TV11 } from 'components/Main/graf/TVPlaceholders/TV1.1';
import { TV12 } from 'components/Main/graf/TVPlaceholders/TV1.2';
import { TV13 } from 'components/Main/graf/TVPlaceholders/TV1.3';
import { TV14 } from 'components/Main/graf/TVPlaceholders/TV1.4.';
import { TV21 } from 'components/Main/graf/TVPlaceholders/TV2.1';
import { TV22 } from 'components/Main/graf/TVPlaceholders/TV2.2';
import PreviewModal from 'components/Main/table/PreviewModal';
import { useHTML2Canvas } from 'customHooks/useHTML2Canvas';


Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x: any, y: any, w: any, h: any) {
    return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
};

highcharts3d(Highcharts);
type Props = {
    data: any;
    page: any;
    title: any
    setTitle(title: any): any
    componentID: any,
    currentQuery: any;
    currentConversionsQuery: any;
    handleChanges(secondQuery: any, currentConversionsQueryCOPY: any): any;
    conversionsData: any;
    chartMetrics: any;
    setChartMetrics(chartMetric: any): any;


};

// const colorArray = ["#7D98AB", "#DBBC8E", "#A9C8C0", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]




const GraphsContainer = styled.div`
  input {
font-size: 12px;
padding: 8px;
border-radius: 7px;
width: 20vw;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};

  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.primaryGray};
}

select {
    border-radius: 7px;
    padding: 3px;
        font-family: 'FontRegular', sans-serif;
}
option {
    font-size: 14px;
    padding: 3px;
    font-family: 'FontRegular', sans-serif;
}
label {
    color: ${({ theme }) => theme.primaryGray};
}
h1 {
    font-family: "FontRegular";
    font-size: 1.4vw;
}
`

function TVChartsContainer(props: Props) {

    const theme = useTheme()
    const tempDataCopy = JSON.parse(JSON.stringify(props.data))
    const mostkeys = tempDataCopy.sort(
        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
    )[0];
    const [TV11dropdown, setTV11dropdown] = useState("TRP")
    const { TVStationSelector } = useCampaignContext();
    const [options, setOptions] = useState<any>()
    const [primSum, setPrimeSum] = useState<any>()
    const { setNetworkList, capture, updatePPT } = useContext(InputContext);


    const metricsCount = [];
    const dimensionCount = [];
    for (const [key, value] of Object.entries(mostkeys)) {
        if (typeof value === "number" || key === "Start date" || key === "End date" || value === "Infinity" || value === "NaN" || value === null) {
            metricsCount.push(value)
        } else if (typeof value === 'string' && value !== "Infinity" && key !== "Start date" && key !== "End date" && value !== "NaN") {
            dimensionCount.push(value)
        }/*  */
    }







    useEffect(() => {
        if (props.componentID === "TV1.1") {
            const networks = props.data.map((e: any) => e.network)
            setNetworkList(networks);
            setOptions(TV11(props.data, TV11dropdown, theme))
        } else if (props.componentID === "TV1.2") {

            setOptions(TV12(props.data, theme))
        } else if (props.componentID === "TV1.3") {
            setOptions(TV13(props.data, TVStationSelector, theme))
        } else if (props.componentID === "TV1.4") {
            const sortingArray = ["First", "Second", "Middle", "Second Last", "Last"]
            const sortedData = props.data.sort((a: any, b: any) => sortingArray.indexOf(a.pip_rel) - sortingArray.indexOf(b.pip_rel))
            setOptions(TV14(sortedData, TVStationSelector, theme))
        } else if (props.componentID === "TV2.1") {
            setOptions(TV21(props.data, TVStationSelector, theme))
        } else if (props.componentID === "TV2.2") {

            setOptions(TV22(props.data, TVStationSelector, theme))
            const trpSum = props.data.filter((stat: any) => TVStationSelector === "All stations" ? stat.network !== "All Stations" : stat.network === TVStationSelector).map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
            const dataArraySUM = props.data.filter((stat: any) => TVStationSelector === "All stations" ? stat.network !== "All Stations" : stat.network === TVStationSelector).map((e: any) => {
                return { ...e, trp_primary_target: ((e.trp_primary_target / trpSum) * 100) }
            })
            const prim = dataArraySUM.filter((slot: any) => slot.timeslot.includes("PT")).map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
            setPrimeSum(Math.round(prim))
        }
    }, [props.chartMetrics, TV11dropdown, TVStationSelector, props.componentID, props.data])





    const [edit, setEdit] = useState(false)

    const handleSave = () => {
        setEdit(!edit)
    }


    // CHANGE ORDER
    const [currentQueryCOPY, setCurrentQueryCOPY] = useState<any>(undefined)
    const [mainReference, setMainReference] = useState<queryInput | undefined>(undefined)
    const [currentConversionsQueryCOPY, setCurrentConversionsQueryCOPY] = useState<queryInput | undefined>(undefined)
    const [hidden, setHidden] = useState(false)
    const [clickedHeader, setClickedHeader] = useState()
    const [activeObject, setActiveObject] = useState<any>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [testObject, setTestObject] = useState<any>(() => {
        if (props.currentQuery !== undefined) {
            const testingStuff = JSON.parse(JSON.stringify(props.currentQuery))?.query.metrics
            testingStuff?.map((metric: any) => Object.assign(metric, { active: true }))
            return testingStuff
        }
    })


    useEffect(() => {
        if (props.currentQuery) {
            setCurrentQueryCOPY(JSON.parse(JSON.stringify(props.currentQuery)))
            setMainReference(JSON.parse(JSON.stringify(props.currentQuery)))
        }
    }, [props.currentQuery])


    // CREATE CONVERSIONS QUERY COPY
    useEffect(() => {
        if (props.currentConversionsQuery) {
            setCurrentConversionsQueryCOPY(JSON.parse(JSON.stringify(props.currentConversionsQuery)))
        }
    }, [props.currentConversionsQuery])


    const [items, setItems] = useState<any>();

    useEffect(() => {
        if (currentQueryCOPY !== null && currentQueryCOPY !== undefined) {
            setItems(Object.keys(mostkeys))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQueryCOPY])


    const openClose = (value: any) => {
        setActiveObject({})

        let QcalcMetrics = currentQueryCOPY?.query.calculatedMetrics
        let QMetrics = testObject
        let QDimensions = currentQueryCOPY?.query.detailedDimensions


        if (QcalcMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QcalcMetrics.find((x: any) => x.name === value))
        } else if (QMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.name === value))
        } else if (QMetrics?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.column === value))
        } else if (QDimensions?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.name === value))
        } else if (QDimensions?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.column === value))
        }
        setHidden(!hidden)
        setClickedHeader(value)
    }

    // COMPARE CURRENTQUERY WITH COPY
    useEffect(() => {
        if (props.currentConversionsQuery === undefined && currentQueryCOPY !== undefined) {
            if (JSON.stringify(props.currentQuery) !== JSON.stringify(currentQueryCOPY)) {
                props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        } else if (currentConversionsQueryCOPY !== undefined && currentQueryCOPY !== undefined) {
            if ((JSON.stringify(props.currentQuery) !== JSON.stringify(currentQueryCOPY)) || (JSON.stringify(props.currentConversionsQuery) !== JSON.stringify(currentConversionsQueryCOPY))) {

                props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConversionsQueryCOPY, currentQueryCOPY, hidden])



    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const chartRef: any = useRef();
    const returnObject = useHTML2Canvas(capture, chartRef.current, props.componentID as string, props.title, "")
    useEffect(() => {
        if (returnObject) {
            // updatePDFelements(returnObject);

            const tobeReturned = {
                ...returnObject,
                theData: options.series,
                categories: options.xAxis.categories,

            }

            updatePPT(tobeReturned);
        }
    }, [returnObject])
    return (
        <GraphsContainer className='graphsContainer'>

            {/*      <div className='chartsContainerTitle' style={{ marginBottom: "2vw", textAlign: "center", width: "100%", padding: "0" }}>
                {!edit && <h1 style={{ width: "100%" }} onClick={() => props.page === 6 ? setEdit(!edit) : null} >{props.title === "Add title here" && props.page === 7 ? "" : props.title} </h1>}
                {edit && <h1><input style={{}} type="text" onChange={(e) => props.setTitle(e.target.value)} /> <button style={{ width: "fit-content" }} type='button' onClick={handleSave}>SAVE</button></h1>}
            </div> */}

            {props.page === 6 && items !== undefined &&
                <div style={{ display: "flex", flexDirection: "row", marginBottom: "2vw", textAlign: "center", width: "100%" }}>
                    {items.map((headerName: any) => (
                        <button style={{ fontSize: "0.8rem", width: "fit-content", marginRight: ".3vw", padding: ".6vw" }} key={headerName} onClick={(event) => openClose(headerName)} name={headerName} value={headerName} type='button'>{headerName}</button>
                    ))
                    }
                </div>}



            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1vw", position: "relative" }}>
                {props.componentID === "TV1.1" &&
                    <div style={{ alignSelf: "end", position: "absolute", zIndex: "10" }} >
                        <label></label>
                        <select onChange={(e) => setTV11dropdown(e.target.value)}>
                            <option value="TRPs">TRP</option>
                            <option value="price_per_TRP">Price per TRP</option>
                            <option value="budgets">Budget</option>
                        </select>
                    </div>}

                {props.componentID === "TV2.2" && <div style={{ right: "1.5vw", position: "absolute", display: "inline-block", zIndex: "0" }}>
                    <button style={{ cursor: "initial", padding: "0.2vw", boxShadow: "initial" }} disabled><b>{primSum}%</b> (17:00-23:30)</button>
                </div>}
                <div ref={chartRef} >
                    <HighchartsReact style={{ zIndex: "0", pointerEvents: "none" }}
                        highcharts={Highcharts}
                        options={options} ref={chartComponentRef} {...props} allowChartUpdate={true} />
                </div>







            </div>

            {
                hidden &&
                <PreviewModal
                    hidden={hidden}
                    openClose={openClose}
                    activeObject={activeObject}
                    data={props.data}
                    clickedHeader={clickedHeader}
                    currentQueryCOPY={currentQueryCOPY}
                    currentQuery={props.currentQuery}
                    setCurrentQueryCOPY={(data: any) => setCurrentQueryCOPY(data)}
                    testObject={testObject}
                    mainReference={mainReference}
                    currentConversionsQueryCOPY={currentConversionsQueryCOPY}
                    currentConversionsQuery={props.currentConversionsQuery}
                    conversionsData={props.conversionsData}
                    setChartMetrics={props.setChartMetrics}
                    chartMetrics={props.chartMetrics}
                ></PreviewModal>
            }


        </GraphsContainer >
    );
}

export default TVChartsContainer;