import { Saved, SavedMidRow, TopRow } from 'components/Main/table/TableStyles';
import { useCampaignContext } from 'context/CampaignContext';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from './DataTable';
import Comments from 'components/Comments/Comments';
import { AiOutlineDelete } from 'react-icons/ai';
import { VscComment, VscEdit } from 'react-icons/vsc';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { CommentsContext } from 'context/CommentsContext';
import { deleteStoredQuery } from 'graphql/mutations';
import { API } from 'aws-amplify';
import { DashboardTypes, DeleteStoredQueryMutation } from 'API';
import { PublishContext } from 'context/PublishContext';
import APIContext from 'context/APIContext';
import { IconButton } from 'components/style/Global';
import ChartsContainer from './ChartsContainer';

import TVChartsContainer from './TV/TVChartsContainer';
import RadioChartsContainer from './RADIO/RadioChartsContainer';
import OOHChartsContainer2 from './OOH&PRINT/OOHContainer';

import { ErrorBoundary } from 'react-error-boundary';
import DeleteConfirmationModal from 'components/DeleteConfirmationModall';
import { PlaceholderType } from './PlaceholderContainer';
import { useClientContext } from 'context/ClientContext';

type Props = {
  isGraph?: boolean;
  joinedData?: any[] | undefined;
  title?: string;
  setTitle?(title: string): void;
  page?: number;
  ccData?: any;
  userMainQuery?: any;
  userCCQuery?: any;
  chartMetrics?: any;
  setChartMetrics?(chartMetric: any): any;
  componentID: string;
  previousPage?(): any;
  handleDuplicate?: (componentID: string, mainQueryID: string) => Promise<boolean>;
  mainQueryID?: string;
  ccQueryID?: string;
  removeItemFromList?: (id: string) => void;
  element?: PlaceholderType;
  defaultSelected?: boolean;
};

//componentID: any,
// handleChanges(secondQuery: any, currentConversionsQueryCOPY: any): any;

const SavedContainer = (props: Props) => {
  const {
    isGraph,
    joinedData,
    title,
    setTitle,
    page,
    ccData,
    userCCQuery,
    userMainQuery,
    componentID,
    previousPage,
    handleDuplicate,
    mainQueryID,
    ccQueryID,
    element,
    defaultSelected,
  } = props;
  const { dashType, activeCampaign, activeSubCampaign, canDoInsourceAndOnInsourcePortal } = useCampaignContext();
  const { statements } = useContext(ClientStatementsContext);
  const { commentsList } = useContext(CommentsContext);
  const { getEnvironment } = useContext(APIContext);
  const { unpublishCampaign } = useContext(PublishContext);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { loggedInUserAccessType } = useClientContext();

  const [toggleComment, setToggleComment] = useState(false);
  const handleChanges = (secondQuery: any) => {
    // console.log(secondQuery);
  };
  const handleEdit = () => {
    // props.setStoredQueryData(undefined)
    // props.setStoredConversionsQueryData(undefined)
    previousPage && previousPage();
  };
  const fullAccessOnThisClientCode =
    loggedInUserAccessType?.Access?.find((lev) => lev?.Level === 'FULL_ACCESS')?.Clients?.some(
      (client) => client?.ClientCode === activeCampaign?.ClientCode,
    ) || false;

  async function handleDelete() {
    try {
      const deleteStoredQ = (await API.graphql({
        query: deleteStoredQuery,
        variables: {
          campaign_id: activeSubCampaign?.name
            ? activeSubCampaign.subcampaignId?.toString()
            : activeCampaign?.campaignId?.toString(),
          Environment: getEnvironment,
          //   placeholder: props.componentID,
          placeholder: props.element?.id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: DeleteStoredQueryMutation };

      // TODO: UNPUBLISH CAMPAIGN SHOULD BE CHANGED WHEN NEW PUBLISH IS THERE
      unpublishCampaign();

      props.removeItemFromList !== undefined && element !== undefined && props.removeItemFromList(element.id);
      // window.location.reload();
    } catch (e) {
      console.log('ERROR  DELETING QUERY', e);
      // props.removeItemFromList !== undefined && element !== undefined && props.removeItemFromList(element.id)
    }
  }

  const [deleteLinkModal, setDeleteLinkModal] = useState(false);
  const handleDeleteConfirmationModal = (a: boolean) => {
    if (a) {
      // call the API
      handleDelete();
      setDeleteLinkModal(false);
    } else {
      setDeleteLinkModal(a);
    }
  };

  const handleDuplicateClick = async () => {
    if (isDuplicating) return;

    if (handleDuplicate === undefined) return;

    setIsDuplicating(true);
    const result = await handleDuplicate(componentID, '');
    setIsDuplicating(!result);
  };

  useEffect(() => {
    console.log('QUERY DATA FOR PLACEHOLDER', props.componentID, joinedData);
  }, [joinedData]);

  return (
    <Saved displaySaved={7}>
      <TopRow style={{ justifyContent: 'flex-end' }} className="SavedTopRow" isGraph={isGraph}>
        {((fullAccessOnThisClientCode && dashType === DashboardTypes.DIGITAL) || canDoInsourceAndOnInsourcePortal) && (
          <IconButton type="button" disabled={isDuplicating} onClick={isDuplicating ? () => {} : handleDuplicateClick}>
            Duplicate Placeholder
          </IconButton>
        )}
        {(dashType === DashboardTypes.DIGITAL || dashType === DashboardTypes.INSOURCE_DATA) &&
          (statements?.Comment?.WRITE ||
            (statements?.Comment?.READ && commentsList?.some((el) => el.placeholder === props.componentID))) && (
            <>
              {props.page === 7 && (
                <Comments
                  header={false}
                  toggleComment={toggleComment}
                  componentID={props.componentID}
                  setToggleComment={(toggleComment: boolean) => setToggleComment(toggleComment)}
                ></Comments>
              )}
              <IconButton type="button" onClick={() => setToggleComment(!toggleComment)}>
                <VscComment />
                Comments
              </IconButton>
            </>
          )}
        {((fullAccessOnThisClientCode && dashType === 'DIGITAL') || canDoInsourceAndOnInsourcePortal) && (
          <IconButton type="button" onClick={handleEdit}>
            <VscEdit />
            Edit
          </IconButton>
        )}
        {((fullAccessOnThisClientCode && dashType === 'DIGITAL') || canDoInsourceAndOnInsourcePortal) && (
          <IconButton
            type="button"
            onClick={() => {
              setDeleteLinkModal(true);
            }}
          >
            <AiOutlineDelete />
            Delete Stored
          </IconButton>
        )}
      </TopRow>
      <SavedMidRow>
        {joinedData && !isGraph && (
          <DataTable
            data={joinedData}
            title={title}
            setTitle={setTitle || (() => {})}
            page={page}
            userMainQuery={userMainQuery}
            userCCQuery={userCCQuery}
            conversionsData={ccData}
            componentID={componentID}
          ></DataTable>
        )}

        {joinedData && isGraph && (dashType === 'INSOURCE_DATA' || dashType === 'DIGITAL') && (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <ChartsContainer
              data={joinedData}
              title={title}
              setTitle={setTitle || (() => {})}
              page={page}
              componentID={componentID}
              currentQuery={userMainQuery}
              currentConversionsQuery={userCCQuery}
              conversionsData={ccData}
              chartMetrics={props.chartMetrics}
              setChartMetrics={props.setChartMetrics || (() => {})}
              handleChanges={handleChanges}
            ></ChartsContainer>
          </ErrorBoundary>
        )}

        {joinedData && isGraph && dashType === 'TV' && (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <TVChartsContainer
              data={joinedData}
              componentID={componentID}
              page={page}
              title={title}
              setTitle={setTitle || (() => {})}
              chartMetrics={props.chartMetrics}
              setChartMetrics={props.setChartMetrics || (() => {})}
              currentQuery={userMainQuery}
              currentConversionsQuery={userCCQuery}
              conversionsData={ccData}
              handleChanges={handleChanges}
            ></TVChartsContainer>
          </ErrorBoundary>
        )}

        {joinedData && isGraph && dashType === 'RADIO' && (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <RadioChartsContainer
              data={joinedData}
              componentID={componentID}
              page={page}
              title={title}
              setTitle={setTitle || (() => {})}
              chartMetrics={props.chartMetrics}
              setChartMetrics={props.setChartMetrics || (() => {})}
              currentQuery={userMainQuery}
              currentConversionsQuery={userCCQuery}
              conversionsData={ccData}
              handleChanges={handleChanges}
            ></RadioChartsContainer>
          </ErrorBoundary>
        )}

        {joinedData &&
          joinedData?.length > 0 &&
          isGraph &&
          (dashType === 'OOH' || dashType === 'PRINT' || dashType === 'CINEMA') && (
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
              <OOHChartsContainer2
                data={joinedData}
                componentID={props.componentID}
                page={props.page}
                title={props.title}
                setTitle={setTitle || (() => {})}
                chartMetrics={props.chartMetrics}
                setChartMetrics={props.setChartMetrics || (() => {})}
                currentQuery={userMainQuery}
                currentConversionsQuery={userCCQuery}
                conversionsData={ccData}
                // handleChanges={(secondQuery: any) => handleChanges(secondQuery)}
              ></OOHChartsContainer2>
            </ErrorBoundary>
          )}

        {deleteLinkModal && (
          <DeleteConfirmationModal
            handleDeleteConfirmationModal={handleDeleteConfirmationModal}
            modalObject={{
              title: 'Delete Stored Query',
              message: 'Are you sure you want to delete this query?',
              mainButton: 'Delete',
              secondaryButton: 'Cancel',
            }}
          ></DeleteConfirmationModal>
        )}
      </SavedMidRow>
    </Saved>
  );
};

export default SavedContainer;
