import { MRT_ColumnDef } from 'material-react-table';
import styled from 'styled-components';
import _, { Dictionary } from 'lodash';
import { useEffect, useState } from 'react';
import React from 'react';
import Row from './NewRow';
import CreateUser from './PlanComponents/CreateUser';
import CreateRow from './NewCreateRow';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { useCheckRollbackStatus } from 'customHooks/useCheckRollbackStatus';
import { useUpdateCampaignStatus } from '../Brief/CustomHook/useUpdateCampaignStatus';
import { Environment, FlowchartCampaignStatus } from 'API';
import { usePortalContext } from 'context/PortalContext';
import { useCreateRow, useDeleteRow, useUpdateOrderRow, useUpdateRow } from './useFetchPlanData';
import { IActiveOrganization } from 'context/ClientContext';
import SaveButton from './PlanComponents/SaveButton';
import { CommunicationTaskSelector } from './CommunicationTaskSelector';
import ErrorModal from './Modals/ErrorModal';
import Info3 from '../Brief/3.Info';

interface Props {
  newColumns: MRT_ColumnDef<any>[];
  newRow: any[] | undefined;
  columnInputTypes: {
    [x: string]: string;
  }[];
  columnTypes: {
    [x: string]: string;
  }[];
  activeCampaign: ACdetails;
  getEnvironment: Environment;
  activeOrganization: IActiveOrganization;
}

export interface ColumnData {
  accessorKey: string;
  enableResizing: boolean;
  header: string;
  maxSize: number;
  minSize: number;
  size: number;
  type: string;
}

interface TableHeaderProps {
  size: number;
  minSize: number;
}

interface RowProps {
  row: any;
  columns: ColumnData[];
  groupKey: string;
  moveRow: (rowId: string, groupKey: string, direction: 'up' | 'down') => void;
  index: number;
  groupLength: number;
}

export interface EditedList {
  [key: string]: {
    [key: string]: string;
  };
}

export interface CreateRow {
  Section: any;
  Rows: {
    Columns: {
      ColumnId: string;
      Value:
      | {
        StringValue: any;
      }
      | {
        StringValue?: undefined;
      };
    }[];
  }[];
}

const PlanContainer = styled.div`
  table {
    overflow: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-spacing: 0;
  }

  thead {
    tr {
      th {
        position: sticky;
        top: 0;
        background: #fff;
        vertical-align: top;
        border: 1px solid #eeeeee;
        padding: 0.25rem;
        padding-top: 0.5rem;
      }
    }
  }

  tbody {
    white-space: nowrap;
  }

  td {
    border: 1px solid #eeeeee;
    padding: 5px 10px;
    border-top-width: 0;
    border-left-width: 0;
  }
`;

const TableHeaderElement = styled.div<TableHeaderProps>`
  background-color: transparent;
  width: ${({ size }) => size + 'px'};
  min-width: ${({ minSize }) => minSize + 'px'};
  word-wrap: break-word;
  white-space: normal;
`;

export const headerInfoMap = {
  'Communication Task': 'What task should the communication fulfill?',
  Touchpoint: 'Which media group are we using?',
  Channel: 'Examples of channels that can be included in the final plan.',
  'Reasons for Touchpoint':
    'Why is this particular media group included in the plan – and what specific task should it fulfill?',
  'Target Group': 'Which primary target group are we targeting?',
  'Campaign KPI': 'What objective do we want to achieve using this channel?',
  KPI: 'What objective do we want to achieve using this channel?',
  'Media Key Figures': 'Media metrics',
  'Key Figures': 'Media metrics',
  Format: 'What formats do we expect to use?',
  'Start Date': 'What date do we expect this media group to start from?',
  'End Date': 'What date do we expect to finish the execution of this media group?',
  'Recommended budget CTC': 'What Cost-to-Client budget is allocated (in rounded figures) for this media group?',
  Comments: 'Are there any deadlines, restrictions, or other aspects that the client should be aware of?',
};

const NewPlanItem = (props: Props) => {
  const { newColumns, newRow, columnInputTypes, columnTypes, activeCampaign, getEnvironment, activeOrganization
    // data, setData, groupedRows, setGroupedRows 
  } = props;

  // console.log("This is a text",  newRow2 ) 

  const CommunicationTaskKey = '1eda874c-86e5-41f3-8c78-d5125b51e1c8';
  const RecommendedBudgetCTC = 'bdb786ee-1b4b-41da-ba45-0e30daf7f988';
  const ApprovedBudgetMediaNet = '09b438ee-226c-43b4-a90c-49e62a0541e1-jfsd';

  const columns = newColumns as unknown as ColumnData[];



  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
  const [editedUsers, setEditedUsers] = useState<Record<string, any>>({});
  const [openGroups, setOpenGroups] = useState<string[]>([]);
  const [allGroupsOpen, setAllGroupsOpen] = useState<boolean>(true);
  const [openToEdit, setOpenToEdit] = useState(false);
  const [editedList, setEditedList] = useState({} as EditedList);
  const [modifiedRows, setModifiedRows] = useState<any[]>([]);
  const [isRowAdded, setIsRowAdded] = useState(false);


  const [data, setData] = useState(newRow as any[]);
  const [groupedRows, setGroupedRows] = useState(_.groupBy(newRow, (row) => row[CommunicationTaskKey]));



  const [rollbackStatus] = useCheckRollbackStatus(activeCampaign);
  const [UpdateCampaign] = useUpdateCampaignStatus();
  const { campaignList, setPermanentCampaignList, permanentCampaignList } = useCampaignContext();
  const { budgetCTC, budgetApproved, setBudgetCTC, setBudgetApproved } = usePortalContext();
  // const [pendingBudgetCTC, setPendingBudget] = useState(false);
  // const [pendingBudgetApproved, setPendingBudgetApproved] = useState(false);
  // const [localValues, setLocalValues] = useState<Record<string, string>>({});
  // const [localGroupKey, setLocalGroupKey] = useState('' as string);

  const [callDeleteApi] = useDeleteRow(getEnvironment, activeCampaign, activeOrganization);
  const [callApi] = useCreateRow(getEnvironment, activeCampaign, activeOrganization);
  const [callUpdateApi] = useUpdateRow(getEnvironment, activeCampaign, activeOrganization);
  const [callUpdateOrderRowsApi] = useUpdateOrderRow(getEnvironment, activeCampaign, activeOrganization);

  const [error, setError] = useState(null as null | string);



  useEffect(() => {
    if (groupedRows) {
      setOpenGroups(Object.keys(groupedRows));
    }
  }, [groupedRows]);

  const toggleGroup = (groupKey: string) => {
    setOpenGroups((prevOpenGroups: string[]) => {
      const newOpenGroups = prevOpenGroups.includes(groupKey)
        ? prevOpenGroups.filter((key: string) => key !== groupKey)
        : [...prevOpenGroups, groupKey];

      const allOpen = Object.keys(groupedRows).every((key) => newOpenGroups.includes(key));

      setAllGroupsOpen(allOpen);

      return newOpenGroups;
    });
  };

  const toggleAllGroups = () => {
    setAllGroupsOpen((prevAllGroupsOpen) => !prevAllGroupsOpen);
    setOpenGroups(allGroupsOpen ? [] : Object.keys(groupedRows));
  };

  const moveRow = async (rowId: string, groupKey: string, direction: 'up' | 'down') => {
    // Find the group
    const groupRows = groupedRows[groupKey];

    // Find the index of the row in the group
    const index = groupRows.findIndex((row: any) => row.rowId === rowId);

    if ((direction === 'up' && index === 0) || (direction === 'down' && index === groupRows.length - 1)) {
      return;
    }
    // Find the index of the row to swap with
    const swapIndex = direction === 'up' ? index - 1 : index + 1;

    // Swap the rows
    const temp = groupRows[index];
    groupRows[index] = groupRows[swapIndex];
    groupRows[swapIndex] = temp;

    // Update the state
    setGroupedRows({
      ...groupedRows,
      [groupKey]: groupRows,
    });

    const flattenedGroupedRows = Object.values(groupedRows)
      .flat()
      .map((item) => {
        return { rowId: item.rowId };
      });

    const updateResult = await callUpdateOrderRowsApi(flattenedGroupedRows);

    if (updateResult === null) {
      setError('This is an error on moving Row');
    } else if (updateResult === 'updated') {
      // setError(null);
      // setEditButton(!EditButton);
    } else {
      // setError(updateResult);
    }
  };

  const deleteRow = async (rowId: string, groupKey: string) => {
    if (window.confirm('Are you sure you want to delete this row?')) {
      // const rowId = row.rowId;
      // const selectedRow = row.original;

      const response = await callDeleteApi(rowId);

      if (response === null) {
        setError('There has been a problem with deleting one of the rows, please try again');
        return;
      } else if (response?.StatusCode === 200) {
        const newData = data.filter((item) => item.rowId !== rowId);

        setData(newData);

        if (newData.length === 0) {
          if (activeCampaign?.status === 'PLANNING_DONE' || activeCampaign?.status === 'PLANNING_STARTED') {
            const rollbackTo = rollbackStatus?.hasBrief ? 'BRIEF_COMPLETED' : 'CREATED';

            UpdateCampaign(activeCampaign, FlowchartCampaignStatus[rollbackTo]);

            const campaign = permanentCampaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
            const newCampaign = {
              ...campaign,
              recommended_budget_ctc: Number(budgetCTC?.budget),
              approved_budget: Number(budgetApproved?.budget),
              status: FlowchartCampaignStatus[rollbackTo],
            } as ACdetails;

            const newCampaignList = permanentCampaignList?.map((campaign: ACdetails) => {
              if (campaign?.campaignId === activeCampaign?.campaignId) {
                return newCampaign;
              } else {
                return campaign;
              }
            }) as ACdetails[];
            setPermanentCampaignList(newCampaignList);
          }
        }
      } else {
        alert('Something went wrong');
      }

      setGroupedRows((prevGroupedRows) => {
        const newGroupRows = prevGroupedRows[groupKey].filter((row: any) => row.rowId !== rowId);

        if (newGroupRows.length === 0) {
          const { [groupKey]: value, ...remainingGroups } = prevGroupedRows;
          return remainingGroups;
        }

        return {
          ...prevGroupedRows,
          [groupKey]: newGroupRows,
        };
      });
    }
  };

  const createNewCategory = async (selectedOption: any, row: any) => {
    const changeItem = data.map((item) => {
      if (item.rowId === row.rowId) {
        item[CommunicationTaskKey] = selectedOption;
      }
      return item;
    });

    const updatingObject = changeItem.map((item) => {
      const modifiedItems = {
        RowId: item.rowId,
        ColumnId: CommunicationTaskKey,
        Value: {
          StringValue: item[CommunicationTaskKey],
        },
      };

      return modifiedItems;
    });

    const updateResult = await callUpdateApi(updatingObject);
    if (updateResult === null) {
      setError('There has been a problem with adding one pf the rows, please try again');
    } else if (updateResult === 'updated') {
      // setError(null);
      // setEditButton(!EditButton);
    } else {
      // setError(updateResult);
    }

    setData(changeItem);
    setIsRowAdded(true);
    setOpenGroups((prevOpenGroups) => [...prevOpenGroups, selectedOption]);
  };

  const handleSelectChangeAndUpdateGlobal = async (
    selectedOption: any,
    accessorKey: string,
    setValues: any,
    values: any,
    row: any,
  ) => {
    const value = selectedOption ? (selectedOption.label ? selectedOption.label : selectedOption) : '';

    if (value === '') {
      return;
    }

    const rowTest = row[CommunicationTaskKey] === value;

    if (rowTest) {
      return;
    }

    setValues({
      ...values,
      [accessorKey]: value,
    });

    const changeItemOriginal = data.map((item) => {
      if (item.rowId === row.rowId) {
        item[CommunicationTaskKey] = value;
      }
      return item;
    });

    const changeItem2 = () => {
      let itemToUpdate: typeof data[0] | undefined;

      const itemsByKey = data.reduce((acc, item) => {
        const key = item[CommunicationTaskKey];

        // Find the item to update
        if (item.rowId === row.rowId) {
          itemToUpdate = { ...item, [CommunicationTaskKey]: value };
          return acc;
        }

        // Add the item to its key's array
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);

        return acc;
      }, {} as { [key: string]: typeof data });

      // If the item to update was found, add it to its new key's array
      if (itemToUpdate) {
        if (!itemsByKey[value]) {
          itemsByKey[value] = [];
        }
        itemsByKey[value].push(itemToUpdate);
      }

      // Flatten the arrays into a single array
      const flattenedData = Object.values(itemsByKey).flat() as any[];
      return flattenedData;
    };

    const updatedData = changeItem2();

    const updatingObject = updatedData.map((item) => {
      const modifiedItems = {
        RowId: item.rowId,
        ColumnId: CommunicationTaskKey,
        Value: {
          StringValue: item[CommunicationTaskKey],
        },
      };

      return modifiedItems;
    });

    const updateResult = await callUpdateApi(updatingObject);
    if (updateResult === null) {
      setError('There has been an error on the update, please try again');
    } else if (updateResult === 'updated') {
      // setError(null);
      // setEditButton(!EditButton);
    } else {
      // setError(updateResult);
    }

    setData(updatedData);
    setIsRowAdded(true);
  };

  const handleCreateRow = async ({ values }: { values: any }) => {
    const newValues = Object.keys(values).map((key) => {
      return columnTypes
        .map((item) => {
          const columnType = Object.keys(item)[0];
          const columnValueType = item[columnType];

          if (key === columnType) {
            return {
              ColumnId: key,
              Value:
                values[key] !== undefined
                  ? {
                    [columnValueType]: values[key],
                  }
                  : { StringValue: '' },
            };
          }
        })
        .filter((item) => item !== undefined)[0];
    });

    const newValues2 = {
      Columns: newValues,
    };

    const newValues3 = {
      Section: values.section !== undefined ? values.section : 'Communication Task',
      Rows: [newValues2],
    } as CreateRow;

    const response = await callApi(newValues3);

    if (response === null) {
      setError('There has been an error on creating a new row, please try again');
      return;
    } else {
      // setError(updateResult);
    }

    // Assuming newRow is an array of objects with a rowId property
    // const newRowIds = newRow.map((item) => item.rowId);

    if (activeCampaign?.status === 'BRIEF_COMPLETED' || activeCampaign?.status === 'CREATED') {
      const result = await UpdateCampaign(activeCampaign, FlowchartCampaignStatus.PLANNING_STARTED);
      const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
      const newCampaign = {
        ...campaign,
        recommended_budget_ctc: Number(budgetCTC?.budget),
        approved_budget: Number(budgetApproved?.budget),
        status:
          activeCampaign?.status === 'BRIEF_COMPLETED' || activeCampaign?.status === 'CREATED'
            ? 'PLANNING_STARTED'
            : activeCampaign?.status,
      } as ACdetails;

      const newCampaignList = campaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];
      setPermanentCampaignList(newCampaignList);
    }

    let responseIds = [] as string[];
    if (response && response[0] && response[0].values) {
      responseIds = response[0].values.map((item: any) => item.rowId);
    }
    // Find the rowId that exists in newRowIds but not in responseIds

    const dataIds = data.map((item) => item.rowId) as string[];

    const unmatchedRowId = responseIds.filter((id) => !dataIds.includes(id))[0];
    const updatedValues = { ...values, rowId: unmatchedRowId };

    setData([...data, updatedValues]);
    setIsRowAdded(true);
    const newList = _.groupBy([...data, updatedValues], (row) => row[CommunicationTaskKey]);
    setOpenGroups(Object.keys(newList));
  };

  useEffect(() => {
    if (isRowAdded) {
      const newList = _.groupBy(data, (row) => row[CommunicationTaskKey]);
      setGroupedRows(newList);
      setIsRowAdded(false);
    }
  }, [isRowAdded, data]);

  const updateData = (rowId: string, editedColumn: string, newValue: string) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.rowId === rowId) {
          return {
            ...item,
            [editedColumn]: newValue,
          };
        }
        return item;
      });
    });
  };

  const changeTheGroupKey = (groupKey: string, groupRows: any[]) => {
    const changeItem = groupRows.map((item) => {
      return (item[CommunicationTaskKey] = groupKey);
    });

    const haha = data.map((item) => {
      changeItem.map((changed: any) => {
        if (item.rowId === changed.rowId) {
          item[CommunicationTaskKey] = changed[CommunicationTaskKey];
        }
        return item;
      });

      return item;
    });
    setData(haha);
    setIsRowAdded(false);
    // setLocalValues((prev) => ({
    //   ...prev,
    //   [groupKey]: prev[groupKey],
    // }));
  };

  const formatter = new Intl.NumberFormat('da-DK', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 0,
  });


  return (
    <>
      {newRow !== undefined ?
        <PlanContainer>
          {/* <div>This is a table made in html</div> */}
          <CreateRow
            openToEdit={openToEdit}
            newRow={newRow}
            newColumns={newColumns}
            data={data}
            handleCreateUser={handleCreateRow}
            columnTypes={columnTypes}
            columnInputTypes={columnInputTypes}
            CommunicationTaskKey={CommunicationTaskKey}
          />

          <table>
            <thead>
              <tr>
                {/* <th style={{ position: 'sticky', left: 0, zIndex: 20, border: '1px solid  #eeeeee' }}> */}
                <th>
                  <div onClick={toggleAllGroups}>{allGroupsOpen ? '▼' : '►'}</div>
                </th>
                {columns.map((column) => (
                  <th
                    key={column.accessorKey}
                    scope="col"
                    style={
                      column.accessorKey === CommunicationTaskKey
                        ? // ? { position: 'sticky', left: 23, zIndex: 20, border: '1px solid  #eeeeee' }
                        { backgroundColor: 'transparent' }
                        : { backgroundColor: 'transparent' }
                    }
                  >
                    <TableHeaderElement size={column.size} minSize={column.minSize}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {column.header}
                        <div style={{ zIndex: 2000000 }}>
                          {headerInfoMap[column.header as keyof typeof headerInfoMap] && (
                            <Info3 info={headerInfoMap[column.header as keyof typeof headerInfoMap]} />
                          )}
                        </div>
                      </div>
                    </TableHeaderElement>
                  </th>
                ))}
                {openToEdit && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr style={{ height: '130px' }}>
                  <td colSpan={columns.length + 1}>No records to display</td>
                </tr>
              ) : (
                <>
                  {Object.entries(groupedRows).map(([groupKey, groupRows], index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>
                            {/* <td style={{ position: 'sticky', left: 0, padding: '0.25rem', zIndex: 20 }}> */}
                            <div onClick={() => toggleGroup(groupKey)}>{openGroups.includes(groupKey) ? '▼' : '►'}</div>
                          </td>
                          <>
                            {openToEdit ? (
                              // <td colSpan={columns.length + 1} style={{ position: 'sticky', left: 23, zIndex: 20 }}>
                              <td colSpan={columns.length + 1}>
                                {/* <textarea
                             // value={localValues[groupKey] || groupKey}
                             value={groupKey}
                             style={{
                               border: 'none',
                               fontFamily: 'FontRegular',
                               resize: 'none',
                               backgroundColor: 'transparent',
                               color: 'black',
                               marginLeft: '5px',
                               width: '',
                             }}
                             onInput={(event: any) => {
                               const newValue = event.target.value;
                               setIsRowAdded(true)
                               setLocalGroupKey(newValue)
                               changeTheGroupKey(localGroupKey, groupRows);
                             }}
                             onFocus={() => setLocalGroupKey(groupKey)}
                             onBlur={(event: any) => {
                               const newValue = event.target.value;
                             }}
                           /> */}
                                {groupKey} ({groupRows.length})
                              </td>
                            ) : (
                              <td colSpan={columns.length + 1}>
                                {/* <td style={{ position: 'sticky', left: 23, zIndex: 20 }}> */}
                                {groupKey} ({groupRows.length})
                              </td>
                            )}
                          </>
                        </tr>
                        {openGroups.includes(groupKey) &&
                          groupRows.map((row: any, index: number) => (
                            <Row
                              row={row}
                              columns={columns}
                              groupKey={groupKey}
                              moveRow={moveRow}
                              index={index}
                              groupLength={groupRows.length}
                              deleteRow={deleteRow}
                              openToEdit={openToEdit}
                              setEditedList={setEditedList}
                              columnInputTypes={columnInputTypes}
                              modifiedRows={modifiedRows}
                              setModifiedRows={setModifiedRows}
                              data={data}
                              key={row.rowId}
                              CommunicationTaskKey={CommunicationTaskKey}
                              createNewCategory={createNewCategory}
                              handleSelectChangeAndUpdateGlobal={handleSelectChangeAndUpdateGlobal}
                              getEnvironment={getEnvironment}
                              activeCampaign={activeCampaign}
                              activeOrganization={activeOrganization}
                              setIsRowAdded={setIsRowAdded}
                              setData={setData}
                              updateData={updateData}
                              setError={setError}
                              error={error}
                            />
                          ))}
                      </React.Fragment>
                    );
                  })}
                </>
              )}
            </tbody>
            <tfoot>
              <>
                <tr>
                  <td></td>
                  {columns.map((column) => {
                    if (column.accessorKey === RecommendedBudgetCTC || column.accessorKey === ApprovedBudgetMediaNet) {
                      const total = Object.values(groupedRows)
                        .flat()
                        .reduce((sum, row) => sum + (Number(row[column.accessorKey]) || 0), 0);
                      return <td key={column.accessorKey}>{formatter.format(Number(total))}</td>;
                    } else {
                      return <td key={column.accessorKey}></td>;
                    }
                  })}
                  {openToEdit && <td></td>}
                </tr>
              </>
            </tfoot>
          </table>
          <>
            {/* <button onClick={() => openEditFunction()}>Click here to see the input</button> */}
            <SaveButton
              EditButton={openToEdit}
              validationErrors={validationErrors}
              setEditButton={setOpenToEdit}
              editedUsers={editedUsers}
              getEnvironment={getEnvironment}
              activeCampaign={activeCampaign}
              activeOrganization={activeOrganization}
              editedList={editedList}
              setIsRowAdded={setIsRowAdded}
              data={data}
              setData={setData}
            />
          </>
          {error !== null && <ErrorModal error={error} setError={setError} type={'ERROR'} />}
        </PlanContainer>
        :
        <>Loading</>}
    </>

  );
};

export default NewPlanItem;
