import styled from 'styled-components';
import { KPIContainerStyled as OriginalKPIContainerStyled } from './KPIContainer';

const KPIContainerStyled = styled(OriginalKPIContainerStyled)`
  max-height: 160px;
`;

export const Skeleton = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 2.5s infinite;

  &.KPIBox {
    height: 90%;
    border-radius: 7px;
    margin-bottom: 10px;
  }

  &.header {
    width: 50%;
    height: 20px;
    margin-bottom: 10px;
  }

  &.content {
    height: 50px;
    border-radius: 7px;
    margin-bottom: 5px;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const KPIContainerSkeleton = () => {
  return (
    <KPIContainerStyled>
      <>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={index} className="KPIBox">
              {/* <Skeleton className="header" /> */}
              <Skeleton className="content" />
            </Skeleton>
          ))}
      </>
    </KPIContainerStyled>
  );
};

export default KPIContainerSkeleton;
