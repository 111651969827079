import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';

interface Props {
  error: string | null;
  setError: (value: string | null) => void;
  type: string;
}

const ErrorModal = (props: Props) => {
  const { error, setError, type } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setError(null);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const openError = () => {
    setOpen(!open);
  };



  return (
    <Dialog
      open={error !== null ? true : false}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    > 
      <DialogTitle id="alert-dialog-title" style={{ color: 'red', fontFamily: 'FontRegular' }}>
      {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ fontFamily: 'FontRegular' }}>
          {/* <span> There has been a problem when we {type} the row. </span> */}
          <span> We are sorry for the inconvenience.</span>
          <span> Please refresh the page and try again.</span>

          <div className="moreInfo" style={{ marginTop: '20px' }}>
            <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => openError()}>
              Find more information about the error
            </div>
            {open && <div>{error}</div>}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose}>Continue</button>
        <button style={{ backgroundColor: 'red', color: 'white' }} onClick={refreshPage} autoFocus>
          Refresh Page
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
