import { placeholderFullSources } from "components/newSetup/PlaceholderContainer";


export const updateCustomSource = (placeholderFullSources: placeholderFullSources[]) => {

    const response = placeholderFullSources.filter((el) => el.isSelected && el.source_name !== 'DATASOURCE#CUSTOMSOURCE');
    let customSource = placeholderFullSources.find(source => source.source_name === 'DATASOURCE#CUSTOMSOURCE');

    if (response && response.length > 1 && customSource) {
        let commonMetrics = response[0]?.metrics;
        let commonDimensions = response[0]?.dimensions;

        response.slice(1).forEach((source) => {
            commonMetrics = commonMetrics?.filter((commonMetric) =>
                source.metrics?.some((m) => m.metric === commonMetric.metric)
            );

            commonDimensions = commonDimensions?.filter((commonDimension) =>
                source.dimensions?.some((d) => d.dimension === commonDimension.dimension)
            );
        });

        commonMetrics = commonMetrics?.map((commonMetric) => {
            const views = response
                .filter((source) => source.metrics?.some((m) => m.metric === commonMetric.metric))
                .flatMap((source) => source.metrics?.find((m) => m.metric === commonMetric.metric)?.view ?? []);
            return { ...commonMetric, view: Array.from(new Set(views)) };
        });

        commonDimensions = commonDimensions?.map((commonDimension) => {
            const views = response
                .filter((source) => source.dimensions?.some((d) => d.dimension === commonDimension.dimension))
                .flatMap((source) => source.dimensions?.find((d) => d.dimension === commonDimension.dimension)?.view ?? []);
            return { ...commonDimension, view: Array.from(new Set(views)) };
        });

        customSource.metrics = commonMetrics;
        customSource.dimensions = commonDimensions;
    }

    const placeholderFullSourcesUpdated = placeholderFullSources.map(source => {
        if (source.source_name === 'DATASOURCE#CUSTOMSOURCE') {
            return {
                ...customSource,
                // isSelected: true,
            };
        } else {
            return {
                ...source,
                // isSelected: false,
            };
        }
    });

    return placeholderFullSourcesUpdated;
}