import React, { useCallback, useState } from 'react';
import { ColumnData, EditedList } from './NewPlanItem';
import InputComponent from './CellInputComponents/InputContainer';
import TextAreaComponent from './CellInputComponents/TextAreaContainer';
import { CommunicationTaskSelector } from './CommunicationTaskSelector';
import { Environment } from 'API';
import { ACdetails } from 'context/CampaignContext';
import { IActiveOrganization } from 'context/ClientContext';
import PreviewContainer from './CellInputComponents/PreviewContainer';
import UpArrow from './../../../../assets/SVG/upArrow.svg';
import DownArrow from './../../../../assets/SVG/downArrow.svg';
import DeleteIcon from './../../../../assets/SVG/deleteIcon.svg';

interface RowProps {
  row: any;
  columns: ColumnData[];
  groupKey: string;
  moveRow: (rowId: string, groupKey: string, direction: 'up' | 'down') => void;
  index: number;
  groupLength: number;
  deleteRow: (rowId: string, groupKey: string) => void;
  openToEdit: boolean;
  setEditedList: React.Dispatch<React.SetStateAction<EditedList>>;
  columnInputTypes: {
    [x: string]: string;
  }[];
  modifiedRows: any[];
  setModifiedRows: React.Dispatch<React.SetStateAction<any[]>>;
  data: any[];
  CommunicationTaskKey: string;
  createNewCategory: (selectedOption: any, row: any) => void;
  handleSelectChangeAndUpdateGlobal: (
    selectedOption: any,
    accessorKey: string,
    setValues: any,
    values: any,
    row: any,
  ) => Promise<void>;
  getEnvironment: Environment;
  activeCampaign: ACdetails;
  activeOrganization: IActiveOrganization;
  setIsRowAdded: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  updateData: (rowId: string, editedColumn: string, newValue: string) => void;
  setError?: React.Dispatch<React.SetStateAction<string | null>>;
  error?: string | null;
}

const Row = React.memo((props: RowProps) => {
  const {
    row,
    columns,
    groupKey,
    moveRow,
    index,
    groupLength,
    deleteRow,
    openToEdit,
    setEditedList,
    columnInputTypes,
    modifiedRows,
    setModifiedRows,
    data,
    CommunicationTaskKey,
    createNewCategory,
    handleSelectChangeAndUpdateGlobal,
    getEnvironment,
    activeCampaign,
    activeOrganization,
    setIsRowAdded,
    setData,
    updateData,
    setError,
    error,
  } = props;

  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null);

  const getInitialValue = useCallback(
    (column: { accessorKey: string; header: any }) => {
      if (column === undefined || column === null) {
        return;
      }

      const columnLine = column.accessorKey as string;
      const initialValue = row[columnLine] as string;

      if (initialValue === undefined || initialValue === null || initialValue === '') {
        return column.header;
      }

      const formattedValue = initialValue === '' ? column.header : initialValue;
      column.header === 'Start Date' && console.log('KAKANJE', row);
      return formattedValue;
    },
    [row],
  );

  const [value, setValue] = useState(getInitialValue);

  type ColumnType = 'DATE' | 'TEXTAREA' | 'NUMBER' | 'BOOLEAN' | 'SELECTOR' | 'default';

  const originalInput = (originalText: any, key: any, rowId: string, original: any): any[] => {
    let updatedRows = [...modifiedRows]; // Create a copy of modifiedRows

    if (updatedRows.length === 0) {
      // If modifiedRows is empty, populate it with original
      updatedRows = original.map((row: any) => {
        if (row.rowId === rowId) {
          // For the object with the matching rowId, replace the value at key with originalText
          return {
            ...row,
            [key]: originalText,
          };
        }
        return row;
      });
    } else {
      // Find the index of the object with the same rowId
      const rowIndex = updatedRows.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        // If an object with the same rowId exists, update it
        updatedRows[rowIndex] = {
          ...updatedRows[rowIndex],
          [key]: originalText,
        };
      } else {
        // If no object with the same rowId exists, add a new one
        const updatedRow = {
          ...modifiedRows,
          [key]: originalText,
          rowId: row.original.rowId,
          section: row.original.section,
          valueType: row.original.valueType,
        };
        updatedRows.push(updatedRow);
      }
    }

    setModifiedRows(updatedRows);
    return updatedRows;
  };

  const componentMap: { [K in ColumnType]: (column: ColumnData) => JSX.Element } = {
    DATE: (column) => (
      <InputComponent
        value={value !== undefined ? value : getInitialValue(column)}
        column={column}
        setValue={setValue}
        setEditedList={setEditedList}
        data={data}
        row={row}
        type={'date'}
        setIsRowAdded={setIsRowAdded}
        setData={setData}
        updateData={updateData}
        setError={setError}
        error={error}
      />
    ),
    TEXTAREA: (column) => (
      <TextAreaComponent
        column={column}
        key={getInitialValue(column)}
        {...{
          value: getInitialValue(column),
          setValue,
          setEditedList,
          row,
          getEnvironment,
          activeCampaign,
          activeOrganization,
          setIsRowAdded,
          setData,
          setError,
          error,
          textAreaRef,
          setTextAreaRef,
        }}
      />
    ),
    NUMBER: (column) => (
      <InputComponent
        value={value !== undefined ? value : getInitialValue(column)}
        column={column}
        setValue={setValue}
        setEditedList={setEditedList}
        data={data}
        originalInput={originalInput}
        row={row}
        getEnvironment={getEnvironment}
        activeCampaign={activeCampaign}
        activeOrganization={activeOrganization}
        type={'number'}
        setIsRowAdded={setIsRowAdded}
        setData={setData}
        updateData={updateData}
        setError={setError}
        error={error}
      />
    ),
    BOOLEAN: () => <div></div>,
    SELECTOR: (column) => (
      <CommunicationTaskSelector
        EditButton={openToEdit}
        data={data}
        newColumns={columns}
        CommunicationTaskKey={CommunicationTaskKey}
        row={row}
        createNewCategory={createNewCategory}
        handleSelectChangeAndUpdateGlobal={handleSelectChangeAndUpdateGlobal}
      />
    ),
    default: (column) => <div>{row.original[column.accessorKey]}</div>,
  };

  return (
    <tr key={groupKey + index}>
      <td></td>
      {columns.map((column, columnIndex) => {
        const typeColumn = Object.values(columnInputTypes.filter((item) => item[column.accessorKey as string])[0])[0];
        return (
          <td key={column.accessorKey}>
            {openToEdit ? (
              <div key={column.accessorKey + 'edit' + columnIndex}>
                {column.accessorKey === CommunicationTaskKey
                  ? componentMap['SELECTOR'](column)
                  : componentMap[typeColumn as ColumnType](column) || componentMap['default'](column)}
              </div>
            ) : (
              <>
                <PreviewContainer
                  {...{ value: getInitialValue(column), column, typeColumn, row, CommunicationTaskKey }}
                />
              </>
            )}
          </td>
        );
      })}
      {openToEdit && (
        <td>
          {index !== 0 ? (
            <button style={{ marginLeft: '10px' }} onClick={() => moveRow(row.rowId, groupKey, 'up')}>
              <img src={UpArrow} alt="up arrow" width="15" height="15"></img>
            </button>
          ) : (
            <span style={{ marginRight: '50px', width: '15px', height: '15px' }}></span>
          )}
          {index !== groupLength - 1 ? (
            <button style={{ marginLeft: '10px' }} onClick={() => moveRow(row.rowId, groupKey, 'down')}>
              <img src={DownArrow} alt="up arrow" width="15" height="15"></img>
            </button>
          ) : (
            <span style={{ marginLeft: '50px', width: '15px', height: '15px' }}></span>
          )}
          <button style={{ marginLeft: '10px', marginRight: '10px' }} onClick={() => deleteRow(row.rowId, groupKey)}>
            <img src={DeleteIcon} alt="up arrow" width="15" height="15"></img>
          </button>
        </td>
      )}
    </tr>
  );
});

export default Row;
