import styled from 'styled-components';

const StyledSVG = styled.svg`
  .cls-1 {
    fill: #8c4c42;
  }
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15,
  .cls-16,
  .cls-17,
  .cls-18,
  .cls-19,
  .cls-20,
  .cls-21,
  .cls-22,
  .cls-23,
  .cls-24,
  .cls-25,
  .cls-26,
  .cls-27,
  .cls-28,
  .cls-29,
  .cls-30,
  .cls-31,
  .cls-32,
  .cls-33,
  .cls-34,
  .cls-35,
  .cls-36,
  .cls-37,
  .cls-38,
  .cls-39,
  .cls-40 {
    stroke-width: 0px;
  }
  .cls-2 {
    fill: url(#linear-gradient-12);
  }
  .cls-3 {
    fill: url(#linear-gradient-17);
  }
  .cls-4 {
    fill: url(#linear-gradient-19);
  }
  .cls-5 {
    fill: url(#linear-gradient);
  }
  .cls-6 {
    fill: #9d756f;
  }
  .cls-7 {
    fill: #000;
  }
  .cls-8 {
    fill: #d77463;
  }
  .cls-9 {
    fill: #c86d5e;
  }
  .cls-10 {
    fill: #7f443b;
  }
  .cls-11 {
    fill: url(#linear-gradient-21);
  }
  .cls-12 {
    fill: url(#linear-gradient-6);
  }
  .cls-13 {
    fill: #965145;
  }
  .cls-41 {
    clip-path: url(#clippath-2);
  }
  .cls-42 {
    isolation: isolate;
  }
  .cls-14 {
    fill: #2e4e91;
  }
  .cls-15 {
    fill: url(#linear-gradient-15);
  }
  .cls-16 {
    fill: url(#linear-gradient-11);
  }
  .cls-17 {
    fill: #f2f9fb;
  }
  .cls-18 {
    fill: url(#linear-gradient-5);
  }
  .cls-19 {
    fill: none;
  }
  .cls-43 {
    clip-path: url(#clippath-1);
  }
  .cls-44 {
    clip-path: url(#clippath-4);
  }
  .cls-20 {
    fill: url(#linear-gradient-13);
  }
  .cls-21 {
    fill: url(#linear-gradient-16);
  }
  .cls-22 {
    fill: #b56154;
  }
  .cls-23 {
    fill: url(#linear-gradient-20);
  }
  .cls-24 {
    fill: url(#linear-gradient-14);
  }
  .cls-25 {
    fill: url(#linear-gradient-2);
  }
  .cls-26 {
    fill: #343333;
  }
  .cls-27 {
    fill: #e1a79f;
  }
  .cls-28 {
    fill: #8fa1a2;
  }
  .cls-29 {
    fill: url(#linear-gradient-7);
  }
  .cls-30 {
    fill: url(#linear-gradient-3);
  }
  .cls-31 {
    fill: #e2e5e6;
    mix-blend-mode: multiply;
  }
  .cls-45 {
    clip-path: url(#clippath);
  }
  .cls-32 {
    fill: url(#linear-gradient-9);
  }
  .cls-33 {
    fill: url(#linear-gradient-8);
  }
  .cls-34 {
    fill: url(#linear-gradient-18);
  }
  .cls-35 {
    fill: #fff;
  }
  .cls-36 {
    fill: #9e9e9e;
  }
  .cls-37 {
    fill: #313131;
  }
  .cls-38 {
    fill: url(#linear-gradient-10);
  }
  .cls-39 {
    fill: url(#linear-gradient-4);
  }
  .cls-40 {
    fill: #514287;
  }
`;

const OOH_OMD_3D = () => {
  return (
    <StyledSVG xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 733.51">
      <defs>
        <clipPath id="clippath">
          <polygon
            className="cls-19"
            points="366.51 -671.57 366.51 -567.11 407.42 -557.44 407.42 -426.86 241.32 -331.01 222.91 -320.38 143.36 -274.46 66.28 -229.96 66.28 -498.26 173.8 -560.34 173.8 -530.44 189.81 -526.38 217.83 -499.02 217.82 -317.44 262.85 -343.52 262.79 -604.12 266.36 -609.36 269.08 -615.34 366.51 -671.57"
          />
        </clipPath>
        <clipPath id="clippath-1">
          <polygon
            className="cls-19"
            points="895.75 -666.87 60.43 -184.6 60.42 -506.12 895.75 -988.38 895.75 -666.87"
          />
        </clipPath>
        <linearGradient
          id="linear-gradient"
          x1="231.64"
          y1="596.34"
          x2="260.15"
          y2="596.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#7f443b" />
          <stop offset=".28" stopColor="#7f443b" />
          <stop offset=".39" stopColor="#83463d" />
          <stop offset="1" stopColor="#9b534a" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" y1="505.06" y2="505.06" xlinkHref="#linear-gradient" />
        <linearGradient
          id="linear-gradient-3"
          x1="231.64"
          y1="416.89"
          x2="260.15"
          y2="416.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#4f5051" />
          <stop offset=".02" stopColor="#4d4e4f" />
          <stop offset=".3" stopColor="#3e3f42" />
          <stop offset="1" stopColor="#505153" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="231.76"
          y1="413.85"
          x2="260"
          y2="413.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#454546" />
          <stop offset="1" stopColor="#5b5b5c" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="232.67"
          y1="413.83"
          x2="259.08"
          y2="413.83"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5b5b5c" />
          <stop offset="1" stopColor="#454546" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="99.67"
          y1="176.78"
          x2="105.64"
          y2="176.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e2e3e2" />
          <stop offset="1" stopColor="#f0f0f0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="190.29"
          y1="125.06"
          x2="196.26"
          y2="125.06"
          xlinkHref="#linear-gradient-6"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="280.91"
          y1="73.34"
          x2="286.88"
          y2="73.34"
          xlinkHref="#linear-gradient-6"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="340.14"
          y1="92.41"
          x2="183.19"
          y2="461.89"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".16" stopColor="#f2f2f2" />
          <stop offset="1" stopColor="#ebebeb" />
        </linearGradient>
        <clipPath id="clippath-2">
          <polygon
            className="cls-19"
            points="366.51 84.49 366.51 188.95 407.42 198.62 407.42 329.19 241.32 425.05 222.91 435.68 143.36 481.6 66.28 526.09 66.28 257.79 173.8 195.72 173.8 225.62 189.81 229.68 217.83 257.04 217.82 438.62 262.85 412.54 262.79 151.94 266.36 146.69 269.08 140.71 366.51 84.49"
          />
        </clipPath>
        <clipPath id="clippath-4">
          <polygon className="cls-19" points="895.75 89.19 60.43 571.46 60.42 249.94 895.75 -232.32 895.75 89.19" />
        </clipPath>
        <linearGradient
          id="linear-gradient-10"
          x1="440.19"
          y1="331.13"
          x2="440.19"
          y2="491.92"
          gradientTransform="translate(96.89 58.51) rotate(-30) scale(1 .87) skewX(-26.57)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9d756f" />
          <stop offset="1" stopColor="#7f443b" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-11"
          x1="394.8"
          y1="216.16"
          x2="392.17"
          y2="193.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset=".33" stopColor="#fafafa" />
          <stop offset=".69" stopColor="#ededed" />
          <stop offset="1" stopColor="#dcdcdc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-12"
          x1="268.72"
          y1="148.3"
          x2="266.01"
          y2="146.38"
          xlinkHref="#linear-gradient-11"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="-568.58"
          y1="153.54"
          x2="-564.26"
          y2="161.07"
          gradientTransform="translate(744.88 501.36) rotate(39.77)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#dbdbdb" />
          <stop offset="1" stopColor="#bababa" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-14"
          x1="372.79"
          y1="2468.41"
          x2="379.75"
          y2="2468.41"
          gradientTransform="translate(-1605.39 1988.74) rotate(-125.23)"
          xlinkHref="#linear-gradient-11"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="202.08"
          y1="252.51"
          x2="199.47"
          y2="229.99"
          xlinkHref="#linear-gradient-11"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="179.48"
          y1="224.67"
          x2="185.46"
          y2="224.67"
          xlinkHref="#linear-gradient-6"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="264.22"
          y1="534.06"
          x2="268.14"
          y2="534.06"
          gradientTransform="translate(-47.13 -175.17) rotate(-30) scale(1 .87) skewX(-26.57)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5a5b63" />
          <stop offset="1" stopColor="#6e6f74" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-18"
          x1="270.24"
          y1="172.78"
          x2="276.22"
          y2="172.78"
          xlinkHref="#linear-gradient-6"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="369.01"
          y1="534.58"
          x2="372.95"
          y2="534.58"
          xlinkHref="#linear-gradient-17"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="361"
          y1="120.89"
          x2="366.97"
          y2="120.89"
          xlinkHref="#linear-gradient-6"
        />
        <linearGradient
          id="linear-gradient-21"
          x1="473.81"
          y1="535.08"
          x2="477.75"
          y2="535.08"
          xlinkHref="#linear-gradient-17"
        />
      </defs>
      <g className="cls-42">
        <g id="Newspaper">
          <g className="cls-45">
            <g className="cls-43">
              <polygon
                className="cls-40"
                points="902.71 -525.01 -19.02 7.14 -19.02 -151.02 902.71 -683.17 902.71 -525.01"
              />
            </g>
          </g>
          <polygon className="cls-26" points="274.71 604.75 217.16 604.74 217.17 598.85 274.7 598.85 274.71 604.75" />
          <path
            className="cls-10"
            d="M225.54,592.52c11.29-6.52,29.48-6.52,40.72,0,11.21,6.47,11.22,17.01,0,23.47-11.24,6.48-29.43,6.48-40.71,0-11.24-6.46-11.23-17,0-23.46Z"
          />
          <path
            className="cls-6"
            d="M225.55,586.34c11.28-6.44,29.47-6.43,40.71.02,11.21,6.52,11.2,16.97,0,23.54-11.24,6.49-29.43,6.49-40.72-.01-11.22-6.56-11.21-17.02,0-23.55Z"
          />
          <path
            className="cls-13"
            d="M228.01,587.78c9.91-5.7,25.91-5.7,35.81.01,9.86,5.75,9.86,14.95,0,20.66-9.9,5.69-25.89,5.69-35.82.02-9.85-5.73-9.83-14.93.01-20.69Z"
          />
          <path
            className="cls-5"
            d="M245.91,605.01c-3.71,0-7.27-.83-9.88-2.34-2.78-1.69-4.39-3.96-4.39-6.36s1.6-4.66,4.39-6.3c2.62-1.51,6.17-2.34,9.88-2.34s7.19.84,9.85,2.34c2.81,1.64,4.4,3.87,4.4,6.3s-1.58,4.67-4.4,6.36c-2.66,1.51-6.14,2.34-9.85,2.34Z"
          />
          <rect className="cls-25" x="231.64" y="413.81" width="28.52" height="182.5" />
          <path
            className="cls-30"
            d="M259.25,413.81c-.71-1.19-1.92-2.33-3.5-3.23-2.66-1.57-6.14-2.38-9.85-2.38s-7.27.81-9.88,2.38c-1.37.78-2.43,1.78-3.22,2.83l-1.16.23v3.22c0,2.4,1.6,4.66,4.39,6.34,2.62,1.53,6.17,2.38,9.88,2.38s7.19-.85,9.85-2.38c2.81-1.68,4.4-3.94,4.4-6.34v-3.05h-.9Z"
          />
          <path
            className="cls-36"
            d="M245.91,422.5c-3.71,0-7.27-.82-9.88-2.36-2.78-1.61-4.39-3.87-4.39-6.33s1.6-4.68,4.39-6.28c2.62-1.51,6.17-2.41,9.88-2.41s7.19.9,9.85,2.41c2.81,1.61,4.4,3.83,4.4,6.28s-1.58,4.71-4.4,6.33c-2.66,1.54-6.14,2.36-9.85,2.36Z"
          />
          <path
            className="cls-39"
            d="M245.91,422.35c-3.71,0-7.14-.83-9.8-2.37-2.79-1.58-4.34-3.75-4.34-6.16s1.55-4.54,4.34-6.16c2.66-1.52,6.09-2.31,9.8-2.31s7.11.79,9.73,2.31c2.82,1.62,4.36,3.79,4.36,6.16s-1.54,4.59-4.36,6.16c-2.62,1.54-6.06,2.37-9.73,2.37Z"
          />
          <path
            className="cls-18"
            d="M255.25,408.46c5.11,2.96,5.11,7.77,0,10.78-5.19,2.93-13.58,2.93-18.68,0-5.19-3.01-5.19-7.83,0-10.78,5.11-3,13.49-3,18.68,0Z"
          />
          <path
            className="cls-7"
            d="M102.12,174.38h.8v-4.96l27.55,16.16-.4-1.32c-.79-.46-1.59-.91-2.38-1.37-1.98-1.14-3.96-2.29-5.94-3.44-2.46-1.43-4.93-2.84-7.39-4.27-2.3-1.33-9.19-5.19-11.42-6.62-.19-.12-.81-.52-.81,0v5.81Z"
          />
          <path
            className="cls-7"
            d="M105.16,174.58c0,1.91-1.34,4.23-2.99,5.18-1.66.95-2.99.2-2.99-1.73s1.33-4.22,2.99-5.17c1.65-.96,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-12"
            d="M105.64,175.05c0,1.91-1.34,4.22-2.99,5.17-1.65.96-2.98.19-2.98-1.73s1.33-4.21,2.98-5.16c1.66-.96,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-35"
            d="M104.79,176.46c0,1.08-.76,2.41-1.7,2.95-.95.55-1.7.11-1.71-.98,0-1.09.76-2.41,1.71-2.95.94-.54,1.7-.11,1.7.99Z"
          />
          <path
            className="cls-7"
            d="M192.74,122.66h.8v-4.96l27.55,16.16-.4-1.32c-.8-.46-1.58-.92-2.38-1.38-1.98-1.14-3.96-2.29-5.94-3.43-2.46-1.42-4.92-2.84-7.39-4.27-2.3-1.32-9.19-5.21-11.42-6.62-.19-.12-.82-.52-.82,0v5.81Z"
          />
          <path
            className="cls-7"
            d="M195.78,122.86c0,1.91-1.34,4.23-2.99,5.18-1.66.95-2.99.2-2.99-1.72s1.33-4.22,2.99-5.17c1.65-.96,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-29"
            d="M196.26,123.33c0,1.92-1.34,4.23-2.99,5.17-1.65.96-2.99.19-2.99-1.72s1.33-4.21,2.99-5.16c1.66-.96,2.99-.18,2.99,1.71Z"
          />
          <path
            className="cls-35"
            d="M195.41,124.73c0,1.09-.76,2.42-1.7,2.95-.95.55-1.71.11-1.71-.98,0-1.09.76-2.41,1.71-2.95.94-.55,1.7-.11,1.7.97Z"
          />
          <path
            className="cls-7"
            d="M283.35,70.94h.81v-4.95l27.55,16.15-.4-1.33c-.79-.45-1.58-.91-2.38-1.37-1.98-1.14-3.96-2.29-5.94-3.43-2.46-1.42-4.93-2.84-7.39-4.27-2.3-1.33-9.19-5.21-11.43-6.62-.19-.12-.82-.52-.82,0v5.81Z"
          />
          <path
            className="cls-7"
            d="M286.4,71.14c0,1.91-1.34,4.22-2.99,5.18-1.66.94-2.99.19-2.99-1.72s1.33-4.22,2.99-5.18c1.65-.95,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-33"
            d="M286.88,71.62c0,1.91-1.34,4.21-2.99,5.16-1.65.96-2.99.19-2.99-1.72s1.33-4.21,2.99-5.16c1.65-.95,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-35"
            d="M286.03,73.02c0,1.09-.76,2.42-1.69,2.95-.94.54-1.7.11-1.71-.99,0-1.08.76-2.4,1.71-2.94.94-.55,1.69-.1,1.69.98Z"
          />
          <polygon
            className="cls-10"
            points="41.05 529.82 35.12 379.9 41.05 243.24 445.28 9.86 445.28 296.44 41.05 529.82"
          />
          <polygon className="cls-27" points="433.42 3.1 29.19 236.48 41.05 243.33 445.27 9.94 433.42 3.1" />
          <polygon className="cls-8" points="29.19 522.98 41.05 529.82 41.05 243.24 29.19 236.4 29.19 522.98" />
          <polygon className="cls-9" points="58.39 539.83 58.39 253.26 462.63 19.87 462.63 306.44 58.39 539.83" />
          <polygon
            className="cls-32"
            points="454.73 33.55 454.73 301.89 423.49 319.92 241.35 425.08 222.93 435.71 143.39 481.61 66.3 526.12 66.3 257.81 454.73 33.55"
          />
          <polygon className="cls-27" points="450.76 13.1 46.54 246.49 58.4 253.34 462.63 19.95 450.76 13.1" />
          <polygon className="cls-8" points="46.54 532.98 58.4 539.83 58.4 253.26 46.54 246.41 46.54 532.98" />
          <g className="cls-41">
            <g className="cls-44">
              <polygon className="cls-27" points="895.75 89.19 60.43 571.46 60.42 249.94 895.75 -232.32 895.75 89.19" />
              <polygon className="cls-38" points="895.75 89.19 60.43 571.46 60.43 410.7 895.75 -71.56 895.75 89.19" />
              <path
                className="cls-22"
                d="M1024.6-67.72L478.86,247.36c-6.15,3.55-11.14.67-11.14-6.43s4.99-15.74,11.14-19.29L1024.6-93.44c6.15-3.55,11.14-.67,11.14,6.43,0,7.1-4.99,15.74-11.14,19.29Z"
              />
              <path
                className="cls-22"
                d="M143.9,500.77c-6.14,3.55-11.14.69-11.14-6.43,0-1.26.17-2.58.46-3.91.15-.69.33-1.37.56-2.03.3-1.33.46-2.67.46-3.94,0-7.1-4.99-9.98-11.14-6.43l-88.67,51.2v73.41s118.82-68.6,118.82-68.6v-38.67l-9.36,5.4Z"
              />
              <polygon className="cls-22" points="565.9 415.3 12.54 734.77 12.54 576.61 565.9 257.14 565.9 415.3" />
              <polygon className="cls-1" points="902.71 231.05 -19.02 763.2 -19.02 605.04 902.71 72.89 902.71 231.05" />
              <path
                className="cls-9"
                d="M92.32,529.15l-118.8,68.59c-6.15,3.55-11.14.67-11.14-6.43,0-7.1,4.99-15.74,11.14-19.29l118.8-68.59c6.15-3.55,11.14-.67,11.14,6.43s-4.99,15.74-11.14,19.29Z"
              />
              <path
                className="cls-17"
                d="M183.1,243.08c-1.43-2.87-4.54-3.18-7.42-1.03-2.79-4.51-8.6-4.13-13.4,1.01-3.62-3.34-9.36-2.53-14.4,2.35-3.84-3.62-10.14-2.51-15.41,3.26-4.52,4.94-7.04,11.93-6.93,17.93-2.12.31-4.41,1.58-6.51,3.77-2.97-2.85-7.86-2-11.96,2.48-2.69,2.93-4.46,6.81-5.1,10.55-1.43.36-2.92,1.28-4.3,2.78-2.73,2.99-4.14,7.33-3.77,10.83l8.97-5.18,5.21-3.01,5.39-3.11,7.82-4.52,6.53-3.77,1.83-1.06,3.62-2.09,7.73-4.46,8.07-4.66,4.34-2.51,3.53-2.04,8.59-4.96,5.11-2.95,6.55-3.78,6.48-3.74c-.12-.75-.29-1.47-.6-2.09Z"
              />
              <path
                className="cls-17"
                d="M428.33,93.39c-2.9-5.83-9.36-6.23-15.16-1.39-4.52-8.85-15.08-8.46-23.65.92-4.71,5.14-7.82,11.92-8.98,18.49-2.78.47-5.76,2.2-8.47,5.16-2.77,3.04-4.79,6.85-5.89,10.72-2.11-.65-4.78.29-7.11,2.84-3.6,3.94-5.01,10.18-3.13,13.94.03.05.06.08.09.14l9.77-5.64,3.72-2.14,13.86-8,12.12-7,9.36-5.4,8.99-5.19,15.86-9.16c.21-3.05-.22-5.94-1.38-8.27Z"
              />
              <path
                className="cls-10"
                d="M85.95,511.74c1.55-4.53,2.57-10.22,2.69-16,.02-.39.02-.78.02-1.18,0-1.83-.09-3.56-.27-5.17-.19-1.96-.54-3.7-.98-5.2-.43-1.61-.99-2.93-1.65-3.96-1.45-2.42-3.37-3.28-5.48-2.06-4.63,2.68-8.38,14.35-8.38,26.06,0,6.19,1.05,11.14,2.71,14.06l11.34-6.55Z"
              />
              <path
                className="cls-10"
                d="M100.28,503.47c2.07-6.03,3.42-13.6,3.58-21.29.02-.52.02-1.04.02-1.57,0-2.44-.12-4.74-.36-6.88-.26-2.6-.72-4.92-1.3-6.92-.58-2.14-1.33-3.91-2.19-5.27-1.93-3.22-4.49-4.36-7.3-2.73-6.16,3.55-11.14,19.08-11.14,34.67,0,8.24,1.39,14.82,3.61,18.71l15.08-8.71Z"
              />
              <path
                className="cls-1"
                d="M92.47,507.97v-48.99c-6.04,3.85-10.89,19.13-10.89,34.51,0,8.23,1.4,14.81,3.62,18.69l7.27-4.2Z"
              />
              <path
                className="cls-27"
                d="M102.22,466.8l-3.76,6.5c-1.08,1.88-2.84,2.89-3.93,2.27-.52-.33-.82-.97-.82-1.78s.29-1.84.82-2.75l5.49-9.52c.86,1.37,1.61,3.13,2.19,5.27Z"
              />
              <path
                className="cls-27"
                d="M103.88,480.6c0,.53,0,1.06-.02,1.57l-4.09,7.08c-1.08,1.88-2.84,2.89-3.92,2.26-.53-.31-.82-.97-.82-1.81s.28-1.78.82-2.72l7.68-13.27c.24,2.14.36,4.43.36,6.88Z"
              />
              <path
                className="cls-13"
                d="M80.07,515.13v-36.83c-4.54,2.9-8.18,14.38-8.18,25.94,0,6.19,1.05,11.13,2.72,14.04l5.46-3.15Z"
              />
              <path
                className="cls-27"
                d="M87.41,484.18l-2.82,4.89c-.81,1.41-2.14,2.17-2.95,1.7-.4-.25-.62-.73-.62-1.34,0-.62.22-1.38.62-2.07l4.13-7.15c.65,1.03,1.21,2.35,1.65,3.96Z"
              />
              <path
                className="cls-27"
                d="M88.65,494.55c0,.4,0,.8-.02,1.18l-3.08,5.33c-.81,1.41-2.14,2.17-2.95,1.7-.4-.23-.62-.73-.62-1.36s.21-1.34.62-2.04l5.77-9.98c.18,1.61.27,3.33.27,5.17Z"
              />
            </g>
          </g>
          <path
            className="cls-13"
            d="M197.49,581.78c-.56,0-1.1-.29-1.39-.8-.44-.77-.18-1.75.59-2.2l33.33-19.25c.77-.45,1.75-.18,2.2.59.44.77.18,1.75-.59,2.2l-33.33,19.25c-.25.15-.53.22-.8.22Z"
          />
          <path
            className="cls-10"
            d="M189.07,678.75s-.09,0-.14,0c-.88-.08-1.54-.85-1.46-1.74l16.85-196.54c.08-.88.84-1.54,1.74-1.46.88.08,1.54.85,1.46,1.74l-16.85,196.54c-.07.84-.77,1.47-1.6,1.47Z"
          />
          <path
            className="cls-10"
            d="M222.78,658.84s-.09,0-.14,0c-.88-.08-1.54-.85-1.46-1.74l16.85-196.54c.08-.88.83-1.54,1.74-1.46.88.08,1.54.85,1.46,1.74l-16.85,196.54c-.07.84-.77,1.47-1.6,1.47Z"
          />
          <path
            className="cls-6"
            d="M279.77,622.44c-.27,0-.54-.07-.79-.21l-50.72-28.73c-.77-.44-1.04-1.42-.6-2.19.44-.77,1.42-1.04,2.19-.6l50.72,28.73c.77.44,1.04,1.42.6,2.19-.29.52-.84.81-1.4.81Z"
          />
          <path
            className="cls-10"
            d="M265.75,720.34c-.72,0-1.37-.49-1.56-1.21l-68.12-269.81c-2.45-11.94,7.25-25.47,18.02-31.7,4.04-2.33,7.63-2.92,10.68-1.75,3.76,1.44,6.6,5.45,8.93,12.62l68.15,269.91c.21.86-.31,1.74-1.17,1.95-.85.21-1.73-.3-1.95-1.17l-68.12-269.81c-1.97-6.07-4.21-9.44-6.99-10.51-2.12-.82-4.71-.31-7.91,1.54-9.79,5.65-18.64,17.75-16.5,28.2l68.11,269.74c.22.86-.31,1.74-1.17,1.95-.13.03-.26.05-.4.05Z"
          />
          <polygon
            className="cls-10"
            points="256.1 470.83 220.94 491.13 195.68 476.55 195.68 474.65 230.85 456.25 256.1 468.93 256.1 470.83"
          />
          <polygon className="cls-6" points="256.1 468.93 220.94 489.24 195.68 474.65 230.85 454.35 256.1 468.93" />
          <polygon
            className="cls-10"
            points="258.04 506.23 222.88 526.54 211.9 520.19 211.9 518.3 247.07 499.89 258.04 504.34 258.04 506.23"
          />
          <polygon className="cls-6" points="258.04 504.34 222.88 524.64 211.9 518.3 247.07 498 258.04 504.34" />
          <polygon
            className="cls-10"
            points="265.77 536.47 230.61 556.77 219.63 550.43 219.63 548.53 254.8 530.13 265.77 534.57 265.77 536.47"
          />
          <polygon className="cls-6" points="265.77 534.57 230.61 554.87 219.63 548.53 254.8 528.23 265.77 534.57" />
          <polygon
            className="cls-10"
            points="273.5 566.7 238.34 587 227.36 580.66 227.36 578.77 262.53 560.36 273.5 564.81 273.5 566.7"
          />
          <polygon className="cls-6" points="273.5 564.81 238.34 585.11 227.36 578.77 262.53 558.46 273.5 564.81" />
          <polygon
            className="cls-10"
            points="281.23 596.94 246.07 617.24 235.09 610.9 235.09 609 270.26 590.6 281.23 595.04 281.23 596.94"
          />
          <polygon className="cls-6" points="281.23 595.04 246.07 615.34 235.09 609 270.26 588.7 281.23 595.04" />
          <polygon
            className="cls-10"
            points="288.96 627.17 253.8 647.47 242.82 641.13 242.82 639.23 277.99 620.83 288.96 625.27 288.96 627.17"
          />
          <polygon className="cls-6" points="288.96 625.27 253.8 645.58 242.82 639.23 277.99 618.93 288.96 625.27" />
          <polygon
            className="cls-10"
            points="296.69 657.41 261.53 677.71 250.55 671.37 250.55 669.47 285.72 651.07 296.69 655.51 296.69 657.41"
          />
          <polygon className="cls-6" points="296.69 655.51 261.53 675.81 250.55 669.47 285.72 649.17 296.69 655.51" />
          <path
            className="cls-6"
            d="M246.4,644.49c-.27,0-.54-.07-.79-.21l-50.72-28.73c-.77-.44-1.04-1.42-.6-2.19.44-.77,1.41-1.04,2.19-.6l50.72,28.73c.77.44,1.04,1.42.6,2.19-.3.52-.84.81-1.4.81Z"
          />
          <path
            className="cls-1"
            d="M202.6,462.06c-1.05.26-2.16.32-3.27.18l64.86,256.88c.18.73.84,1.21,1.56,1.21.13,0,.26-.01.4-.05.86-.22,1.38-1.09,1.17-1.95l-64.71-256.27Z"
          />
          <path
            className="cls-31"
            d="M173.8,225s17.27,4.41,11.3,29.45c0,0,22.77,9.43,29.75,34.63,12.39-21.36,2.35-48.45-.07-54.1-7.04.52-40.97-9.98-40.97-9.98Z"
          />
          <path
            className="cls-31"
            d="M366.51,188.95s17.28,4.41,11.3,29.45c0,0,22.77,9.43,29.75,34.63,12.39-21.36,2.35-48.45-.07-54.1-7.04.52-40.97-9.98-40.97-9.98Z"
          />
          <path
            className="cls-16"
            d="M366.51,188.95c6.11.96,18.58,10.37,15.2,26.79,10.13-.87,35.1,10.8,36.33,26.98,10.29-30.48-10.62-44.28-10.62-44.28,0,0-39.23-9.41-40.91-9.49Z"
          />
          <path
            className="cls-31"
            d="M267.15,146.33c-.61,3.36-.44,5.61-.44,5.61,0,0-.77-.89-3.92,0,2.35-3.12,4.36-5.61,4.36-5.61Z"
          />
          <path className="cls-2" d="M269.08,140.71l-6.29,11.23s2.35-2.08,4.79-1.9c0-2.27-.33-6.42,1.5-9.33Z" />
          <path
            className="cls-8"
            d="M208.14,361.18c2.68,3.33,4.81,5.57,6.92,7.15,2.24-1.81,4.88-1.73,6.3-3.51-3.14-1.76-8.09-5.06-10.5-6.46l-2.72,2.82Z"
          />
          <path
            className="cls-13"
            d="M204.92,351.91c-.9-.39-2.94-.64-4-.76-.37-.04-4.92.06-4.72,1.16.06.32.46.6,1,.85,0,.01,0,.01,0,.02.02.18-.08.35-.09.52-.02.37.16.66.41.93.34.37.97.62,1.43.81.69.28,1.56.25,2.3.25.52,0,1.03-.04,1.53-.1.23.25.35.52.5.85,0,.02.02.05.04.07-.66,1.34-3.93-1.65-4.32.65-.11.68,4.24,2.16,4.24,2.16.13.04,2.77,2.07,3.83,2.2,2.73.32,4.17-2.03,3.64-3.34-.52-1.32-5.09-5.99-5.78-6.29Z"
          />
          <path
            className="cls-14"
            d="M214.66,360.71c.15.95-1.19,3.92-3.85,3.82,2.15,3.32,8.95,10.6,12.47,11.99,5.33,2.1,12.46-.74,17.64-5.99,3.02-3.06,6.84-9.54,1.87-12.47-5.18-3.05-12.5,8.31-17.37,9.41-2.85-2.43-9.65-7.04-10.76-6.76Z"
          />
          <path
            className="cls-13"
            d="M238.74,459.81c-2.01-.15.99,3.11-2.61,2.22-1.19-.3-3.32-.64-4.35.46-1.6,1.72-.03,4.07,1.01,5.75.39.63.86,1.19,1.49,1.57.76.46,1.67.55,2.41,1.05,1.38.92,2.42,2.18,4.13,2.57,1.18.27,2.89.28,4.04-.15.42-.16.96-.62.95-1.14-.01-1.8-1.94-5.62-1.2-7.19.46-.94-4.28-4.93-5.88-5.14Z"
          />
          <path
            className="cls-13"
            d="M229.89,477.19c-.32-1.78-2.63-4.04-1.91-6,.38-1.06-4.73-1.31-7.54-.77-.22.42-.46.83-.76,1.2-.26.31-.55.66-.9.88-.17.11-.55.28-.65.36-.18.47-.78.96-2.68,1.5-1.48.42-4.82.6-5.79,1.93-.19.26-.27.52-.29.79-.44,1.37,2.07,2.76,4.9,3.5,1.08.28,1.63.33,3.03.43,2.03.13,2.58-.18,4.14-.29,1.56-.11,4.3-.06,5.6-.15,1.67-.1,3.21-1.37,2.84-3.38Z"
          />
          <path
            className="cls-8"
            d="M236.44,357.55l-2.15-8.85c-.06-1.28-.44-2.74-1.82-2.86-1.54-.13-5.52,3.5-5.67,4.95l.87,8.95c-.01.08.17.51.18.59,2.34-.7,6.21-2.49,8.59-2.79Z"
          />
          <path
            className="cls-8"
            d="M242.14,343.85c-.73-1.6-1.55-3.15-2.51-4.61-.63-.81-.44-.89-1.14-1.64-7.06-1.17-14.82,2.88-14.46,12.14.05,1.11-.59,6.03,1.52,6.77,6.14,2.16,3.58-2.45,4.27-1.66,1.17,1.33,4.6,1.33,5.95-.45,1.06-.19,1.93-.52,2.72-.91,1.11-3.26,2.15-6.54,3.64-9.63Z"
          />
          <path
            className="cls-27"
            d="M225.06,342.58c-.17,2.41-.09,3.71.86,5.8,1.15.85,4.8,2.46,4.8,2.46,2.34-1.16,3.58-4.3,4.68-6.8.92-2.09.75-3.77-.07-5.17l-10.28,3.71Z"
          />
          <path
            className="cls-27"
            d="M233.82,336.2c8.29.7,14.02,9.51,5.46,16.91-3.14,2.72-3.3,3.22-5.39,3.06-2.25-.31-4.42-1.17-5.52-3.18-2.95-5.4,8.22-8.98,4.04-13.71l1.41-3.08Z"
          />
          <path
            className="cls-27"
            d="M226.01,343.17c5.49,3.02,12.41,3.38,17-.59-.13-4.66-6.16-8.05-12.82-5.92-4.44,1.42-6.43,4.77-4.19,6.5Z"
          />
          <path
            className="cls-8"
            d="M226.51,347.97c.8-2.72,2.7-1.54,2.9-.49.24,1.4-1.2,3.6-2.87,3.72-.02-1.69-.26-2.46-.03-3.23Z"
          />
          <path
            className="cls-10"
            d="M235.4,403.79c-.09-.17-7.27,1.95-6,7.01.69,2.75-.48,12.88,3.6,28.68,1.88,7.27,2.05,12.69,2.44,20.18.11,2.34-1.3,5.64.13,7.88,2.81,4.27,7.23,5.66,10.31,3.81.15-2.02,3.01-19.02-2.37-38.05-.1-11.02-.04-24.51-.35-33.98,0,0-2.26,5.28-7.76,4.47Z"
          />
          <path
            className="cls-22"
            d="M218.36,396.77c-1.34,12.81-1.78,20.87,1.15,37.97,1.55,9.06.18,24.13.32,31.64.05,2.34-1.08,4.39-1.85,6.67,1.62,1.49,9.37,4.05,12.5,2.29.22-2,4.39-21.24,1.15-36.35.26-11.02,1.87-32.88,5.17-43.34,0,0-12.96,2.11-18.43,1.12Z"
          />
          <path
            className="cls-22"
            d="M219.05,387.28s-.01.07-.02.11h.01c.21.11.39.26.55.44,8.35-.2,16.57-3.01,23.52-7.65.21-1.39.47-2.89.78-4.59.28-1.51.69-3.05,1.09-4.6.07-.21.12-.43.17-.63,1.26-5.08,2.12-10.04-2.7-13.46-.25-.18-.55-.3-.9-.38-.77-.18-1.78-.15-2.87,0h0c-3.88.54-8.93,2.68-9.27,2.83-1.13.5-2.25,1.09-3.3,1.72-1.3.76-2.5,1.59-3.55,2.4-1.24.95-2.44.93-3.16,1.65-3.11,3.19.39,4.3-.53,5.93-1.86,3.32-.28,8.16.2,16.23Z"
          />
          <path
            className="cls-22"
            d="M241.7,386.91c-.22.3-.12,1.81-.08,2.43.08,2.96,1.17,8.18,1.45,9.53,0,.01,0,.02,0,.03.04.18.06.28.06.29.04.33.03.67,0,1.02h0c-.36,3.51-4.25,7.71-8.99,10.21-5.8,3.07-12.88,3.59-16.33-2.89.19-1.29.79-17.47.85-17.84.15-.82.27-1.58.36-2.3,0-.04.01-.07.01-.11,1.24-.21,2.43-.81,3.4-1.6,4.46-3.58,4.25-10.64,3.96-15.83-.14-2.4-.8-6.36-3.87-6.39,1.05-.81,2.25-1.64,3.55-2.4,1.14.46,2.19,1.21,2.92,2.53,2.83,5.18,1.47,14.91,1.05,18.82,5.71-1.48,8.81-2.89,11.77-5.79,1.16-5.62,3.44-16.6-3.15-20.12,1.1-.15,2.11-.18,2.87,0,4.6,3.22,4.27,9.9,3.43,14.48-.41,2.27-1.26,13.15-3.28,15.93Z"
          />
          <path
            className="cls-8"
            d="M227.79,360.11c1.05,1.64,6.08-.52,7.38-1.29,2.29-1.36,1.03-2.92-1.19-2.44-1.4.3-2.88,1.35-4.27,1.7l-1.92,2.03Z"
          />
          <path
            className="cls-13"
            d="M241.69,390.53s-7.98,7.51-15.32,6.97c-7.9-.58-7.89-3.46-7.89-3.46l.11-2.71s2.76,3.23,7.78,3.38c7.57.23,15.33-7.8,15.33-7.8v3.62Z"
          />
          <path
            className="cls-20"
            d="M222.36,257.98h0l-27.89-4.8-1.45,8.4,27.89,4.8h0l.1.02h0c.59.05,1.24-.26,1.82-.95,1.23-1.48,1.69-4.14,1.02-5.94-.31-.85-.82-1.35-1.4-1.5h0s-.1-.03-.1-.03Z"
          />
          <path
            className="cls-24"
            d="M192.54,254.11c1.23-1.48,2.77-1.21,3.43.59.67,1.8.21,4.46-1.02,5.94-1.23,1.48-2.77,1.21-3.43-.59s-.21-4.46,1.02-5.94Z"
          />
          <path
            className="cls-28"
            d="M193.29,256.15c.46-.55,1.04-.46,1.29.22.25.68.08,1.68-.39,2.24-.46.55-1.04.46-1.29-.22-.25-.68-.08-1.68.38-2.24Z"
          />
          <path
            className="cls-37"
            d="M199.85,392.9h-.05c-.41-.03-.73-.39-.7-.8l8.47-120.91-18.71-7.33c-.32-.13-.51-.45-.47-.79l.82-6.42c.03-.2.13-.39.3-.51.16-.12.37-.17.57-.14l3.78.65c.41.07.68.46.61.87-.07.41-.45.7-.87.61l-3.01-.52-.65,5.07,18.68,7.32c.3.12.5.42.47.75l-8.51,121.46c-.03.4-.36.7-.75.7Z"
          />
          <path
            className="cls-13"
            d="M206.4,342.8c1.01-1-1.42-4.12-1.55-4.39-.41-1.06-.72-2.31-1.08-3.04-.64-1.3.22-.8-1.51-.27-1.38.42-3.55,1.43-4.04,2.79-1.12,3.13-1.15,3.15.99,4.92-.09-.23.21.83.74,1.72.8,1.33,2.68,1.46,3.69.8.27-.18.53-.4.77-.63.91-.03,1.39-1.31,1.99-1.9Z"
          />
          <path className="cls-8" d="M205.03,347.6c0-2.13.34-5.99-2.85-5.7-2.25.2-2.08,2.72-2.64,4.32l5.49,1.38Z" />
          <path
            className="cls-22"
            d="M224.37,377.75c2.69-.09-.67-4.34-.03-5.82,1.14-2.57,1.03-7.7-1.05-9.03-3.32-2.12-12.91-2.77-16.17-4.64-1.05-4.93,1.88-11.52-3.16-13.9-2.59-1.22-4.58.27-5.08,1.91-1.56,5.13-2.09,6.99-2,9.87.04,1.1.26,4.74.63,5.47.43.81,1.18,1.49,2.1,2.1,1.52,1.82,5.02,5.15,6.35,6.05,5.62,3.8,14.3,8.12,18.4,7.98Z"
          />
          <path
            className="cls-15"
            d="M173.8,225.62c6.11.96,18.58,9.75,15.2,26.17,10.13-.87,35.1,10.8,36.33,26.98,10.3-30.48-10.79-44.11-10.79-44.11,0,0-39.05-8.95-40.74-9.04Z"
          />
          <path
            className="cls-21"
            d="M185.46,222.95c0,1.9-1.34,4.22-2.98,5.18-1.66.94-3,.19-3-1.73s1.34-4.22,3-5.17c1.65-.96,2.98-.18,2.98,1.72Z"
          />
          <path
            className="cls-7"
            d="M185.94,223.42c0,1.91-1.34,4.21-2.98,5.17-1.65.95-2.99.19-2.99-1.73s1.34-4.21,2.99-5.17c1.65-.95,2.98-.18,2.98,1.72Z"
          />
          <path
            className="cls-3"
            d="M185.08,224.81c0,1.08-.76,2.42-1.69,2.95-.94.55-1.71.11-1.71-.98s.76-2.41,1.71-2.95c.94-.55,1.69-.1,1.69.98Z"
          />
          <path
            className="cls-7"
            d="M157.65,200.03c0,1.3-.91,2.87-2.03,3.52-1.12.66-2.03.14-2.04-1.18.01-1.29.92-2.87,2.04-3.51,1.12-.65,2.03-.12,2.03,1.17Z"
          />
          <path className="cls-7" d="M183.71,222.95h-.8v-5.57l-27.41-15.82.4-.7,27.61,15.94c.13.07.2.2.2.35v5.8Z" />
          <path
            className="cls-34"
            d="M276.22,171.05c0,1.91-1.34,4.22-2.99,5.18-1.66.95-2.99.19-2.99-1.73s1.33-4.22,2.99-5.18c1.65-.95,2.99-.18,2.99,1.73Z"
          />
          <path
            className="cls-7"
            d="M276.69,171.53c0,1.92-1.34,4.21-2.99,5.17-1.65.96-2.98.19-2.98-1.73s1.34-4.22,2.98-5.17c1.66-.95,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-4"
            d="M275.84,172.93c0,1.09-.76,2.42-1.69,2.95-.95.54-1.71.11-1.71-.99,0-1.08.76-2.4,1.71-2.95.94-.55,1.69-.11,1.69.98Z"
          />
          <path
            className="cls-7"
            d="M248.4,148.14c0,1.3-.91,2.88-2.03,3.52-1.12.66-2.03.14-2.04-1.16,0-1.3.91-2.88,2.04-3.52,1.12-.65,2.03-.13,2.03,1.16Z"
          />
          <path className="cls-7" d="M274.48,171.06h-.81v-5.57l-27.41-15.82.4-.7,27.61,15.94c.13.08.2.21.2.35v5.8Z" />
          <path
            className="cls-23"
            d="M366.97,119.17c0,1.91-1.34,4.22-2.99,5.18-1.66.95-2.99.19-2.99-1.72s1.33-4.23,2.99-5.18c1.65-.96,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-7"
            d="M367.45,119.64c0,1.91-1.34,4.22-2.99,5.17-1.65.95-2.98.19-2.98-1.73s1.34-4.22,2.98-5.17c1.66-.95,2.99-.18,2.99,1.72Z"
          />
          <path
            className="cls-11"
            d="M366.6,121.04c0,1.08-.76,2.41-1.69,2.94-.96.55-1.71.11-1.72-.98,0-1.09.76-2.41,1.72-2.96.93-.53,1.69-.1,1.69,1Z"
          />
          <path
            className="cls-7"
            d="M339.16,96.25c0,1.3-.91,2.87-2.03,3.52-1.12.65-2.02.13-2.04-1.17.01-1.29.91-2.87,2.04-3.52,1.12-.64,2.03-.12,2.03,1.17Z"
          />
          <path className="cls-7" d="M365.23,119.18h-.8v-5.57l-27.41-15.82.4-.7,27.61,15.94c.13.07.2.21.2.34v5.82Z" />
        </g>
      </g>
    </StyledSVG>
  );
};

export default OOH_OMD_3D;
