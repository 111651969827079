import TextArea from './InputTypes/TextArea'
import Input from './InputTypes/Input'
import { FormInputContainer } from './style/BriefInputStyle'
import SelectInput from './InputTypes/Select'
import Info3 from './3.Info'
import OptionInputs3 from './InputTypes/OptionInputs'
import { BriefValue, briefTemplateSectionColumns, briefTemplateSubColumn } from 'API'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"


type Props = {
  inputProps: briefTemplateSectionColumns | briefTemplateSubColumn,
  onChange: (e: { e: any, id: string }) => void,
  savedValue: any,
  briefValues: BriefValue & { Id: string }[]
}

enum InputType {
  Textarea = "textarea",
  Number = "number",
  Select = "select",
  Radio = "radio",
  Checkbox = "checkbox",
  Date = "date",
  Time = "time",
  Url = "url",
  Text = "text"
}

type InputTypeConfig = {
  [InputType.Textarea]?: JSX.Element;
  [InputType.Number]?: JSX.Element;
  [InputType.Select]?: JSX.Element;
  // [InputType.Text]?: JSX.Element;
  [InputType.Date]?: JSX.Element;
  [InputType.Time]?: JSX.Element;
  [InputType.Url]?: JSX.Element;
  [InputType.Radio]?: JSX.Element;
  [InputType.Checkbox]?: JSX.Element;
  [InputType.Text]?: JSX.Element;
};

const BriefInput2 = (props: Props) => {
  const { inputProps, onChange, savedValue, briefValues } = props
  const { name, type, label, description } = inputProps


  const inputType: InputTypeConfig = {
    [InputType.Textarea]: <TextArea value={savedValue} inputProps={inputProps} onChange={onChange} name={name as string} />,
    [InputType.Number]: <Input value={savedValue} inputProps={inputProps} type={"number"} onChange={onChange} name={name as string} />,
    [InputType.Select]: <SelectInput value={savedValue} inputProps={inputProps} onChange={onChange} name={name as string} />,
    //  [InputType.Text]: <Input inputProps={inputProps} type={"text"} minlength="4" maxlength="8" size="10" onChange={onChange} name={name as string} level={level} originalInput={originalInput} />,
    [InputType.Date]: <Input value={savedValue} inputProps={inputProps} type={"date"} onChange={onChange} name={name as string} />,
    // [InputType.Date]: <DatePicker calendarStartDay={1} dateFormat="dd-MM-yyyy" selected={savedValue} onChange={onChange} />,
    [InputType.Time]: <Input value={savedValue} inputProps={inputProps} type={"time"} min="09:00" max="18:00" onChange={onChange} name={name as string} />,
    [InputType.Url]: <Input value={savedValue} inputProps={inputProps} type={"url"} pattern='https://.*' onChange={onChange} name={name as string} />,
    [InputType.Radio]: <Input value={savedValue} inputProps={inputProps} type={"radio"} onChange={onChange} name={name as string} />,
    [InputType.Checkbox]: <Input value={savedValue} inputProps={inputProps} type={"checkbox"} onChange={onChange} name={name as string} />,
    [InputType.Text]: <OptionInputs3 briefValues={briefValues} value={savedValue} optionInputs={(inputProps as briefTemplateSectionColumns).subColumns as briefTemplateSubColumn[]}
      type={"inputs"} onChange={onChange} name={name as string} />
  };

  const inputComponent = inputType[type?.toLowerCase() as string as keyof typeof inputType];


  return (
    <FormInputContainer key={inputProps.name} >
      <div>
        <div className='labelContianer'>
          {/*        <>{required && "*"}</> */}
          <label htmlFor="">{label}</label>

          <>{description && <Info3 info={description}></Info3>}</>
        </div>
        {inputComponent}

        <div>
          {/*  {required &&
            <>
              {savedValue === "" || savedValue === undefined
                ? <div className='errorMessage'>This field is required</div>
                : <></>
              }
            </>} */}
        </div>

      </div>
    </FormInputContainer>
  )
}

export default BriefInput2