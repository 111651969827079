import styled from 'styled-components';

interface ContainerProps {
  expand: any;
}
export const StyledTableContainer = styled.form<ContainerProps>`
  width: 99%;
  height: ${(props) => (props.expand === 0 ? 'auto' : 'auto')};
  padding-bottom: ${(props) => (props.expand === 0 ? '0%' : '2.5%')};
  margin: 2px 10px 10px 10px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  padding: 14px 20px;
  position: relative;
  


  .upAndDownContainer{
    display: flex;
    flex-direction: row;
    gap: 7px;
    font-size: 12px;
    font-family: 'FontBold',sans-serif;
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    
    div {
      border: 1px solid ${({ theme }) => theme.primaryGray};
      border-radius: 7px;
      padding: 7px 10px;
    
    &:hover {
      background-color: ${({ theme }) => theme.secondaryGray};
      color: white;
      cursor: pointer;
    }

    }
  }


`;

interface TxtProps {
  displayTxt: any;
  isGraph: Boolean;
}
export const TxtContainer2 = styled.div<TxtProps>`
  margin: 0;
  position: ${(props) => (props.isGraph ? 'absolute' : 'relative')};;
  top: 50%;
  -ms-transform: ${(props) => (props.isGraph ? 'translate(-50%)' : '')};
  transform: ${(props) => (props.isGraph ? 'translate(-50%)' : '')};
  display: ${(props) => (props.displayTxt === 0 ? 'flex' : 'none')};
  top: ${(props) => (props.isGraph ? '30%' : '')};;
  left:${(props) => (props.isGraph ? '50%' : '')};;

`;

interface AddProps {
  isGraph: Boolean;
}
export const Add = styled.div<AddProps>`
  display: flex;
  flex-direction: ${(props) => (props.isGraph ? 'column' : 'row')};
  width: 100%;
  height: 100%;
  padding: 0;
  justify-content: space-between;
  text-align: ${(props) => (props.isGraph ? 'center' : 'left')};
  gap: ${(props) => (props.isGraph ? '.8vw' : '0')};
  align-content: center;
  button {
    align-self: center;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.isGraph ? '10px' : '0')};
  }
  h1 {
    font-size: 20px;
    font-weight: inherit;
  }
  h2 {
    font-size: 14px;
    font-weight: inherit;
  }
  .actionButton {
    width: fit-content;
    margin: ${(props) => (props.isGraph ? 'auto' : 'inherit')};
  }

`;

interface PlatformProps {
  displayPlatform?: any;
  isGraph?: Boolean;
}
export const Platform = styled.div<PlatformProps>`
  width: 100%;
  height:  ${(props) => (props.isGraph ? '40vh' : '100%')};;
  display: ${(props) => (props.displayPlatform === 1 ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.isGraph ? '20px' : '0')};
 
`;

interface DimensionsProps {
  displayDimensions: any;
}
export const Dimensions = styled.div<DimensionsProps>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.displayDimensions === 2 ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
`;

interface MetricsProps {
  displayMetrics: any;
}
export const Metrics = styled.div<MetricsProps>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.displayMetrics === 3 ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
`;

interface ConversionsProps {
  displayConversions: any;
}

export const Conversions = styled.div<ConversionsProps>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.displayConversions === 4 ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
`;

interface OverviewProps {
  displayOverview: any;
}
export const Overview = styled.div<OverviewProps>`
  width: 100%;
  height: 35vh;
  display: ${(props) => (props.displayOverview === 5 ? 'flex' : 'none')};
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  .questionMark {
    position: relative;
    font-size: 10px;
    color: ${({ theme }) => theme.secondaryGray};
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.secondaryGray};
    border-radius: 50%;
    font-weight: normal;
    padding: 0 3px;
    top: -7px;
    right: -7px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

interface PreviewProps {
  displayPreview: any;
}
export const Preview = styled.div<PreviewProps>`
  display: ${(props) => (props.displayPreview === 6 ? 'flex' : 'none')};
  flex-direction: column;
`;

interface SavedProps {
  displaySaved: any;
}
export const Saved = styled.div<SavedProps>`
  display: ${(props) => (props.displaySaved === 7 ? 'flex' : 'none')};
  flex-direction: column;
`;

interface TopRowProps {
  isGraph?: Boolean;
}
export const TopRow = styled.div<TopRowProps>`
  display: flex;
  justify-content: space-between;
`;

interface PlatformMidRowProps {
  isGraph: Boolean;
}

export const PlatformMidRow = styled.div<PlatformMidRowProps>`
  display: flex;
  flex-direction: column;
  padding: 18px 45px;

  h2 {
    font-size: 14px;
    font-weight: inherit;
  }
  h1 {
    font-size: 20px;
    font-weight: inherit;
  }

  /*   margin-top: ${(props) => (props.isGraph ? '2vh' : '2rem')};
  margin-bottom: ${(props) => (props.isGraph ? '12vh' : '3em')}; */

  /* > div {
  margin-bottom: ${(props) => (props.isGraph ? '1vh' : '0')};
} */
  .platformsArray {
    display: flex;
    gap: 10px;
    /*   justify-content: center;
    align-items: center; */
  }

  label {
    cursor: pointer;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    width: fit-content;
    margin-bottom: 8px;
    margin-top: 21px;
    font-family: 'FontMedium', sans-serif;
    color: ${({ theme }) => theme.primaryGray};
    display: flex;
  }

  input {
    display: none;
  }
  .checked {
    padding: 9px 12px;
  }

  input:checked ~ .checked {
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
  }
`;

export const MidRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 45px;
  font-family: 'FontMedium', sans-serif;
  justify-self: center;
  button {
    padding: 3px 9px;
    border: 1px solid ${({ theme }) => theme.border};
  }

  h2 {
    font-size: 14px;
    font-weight: inherit;
    margin-bottom: 30px;
  }
  h1 {
    font-size: 20px;
    font-weight: inherit;
  }
  input {
    display: none;
  }
`;

export const PreviewMidRow = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 2rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.placeholderBackground};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.scrollThumb};
  }

  table {
    border-collapse: collapse;
    min-width: 92vw;
    .number {
      text-align: right;
    }
    .string {
      text-align: left;
    }
  }

  tr {
    padding: 5px;
    font-size: 1rem;
    font-family: 'FontRegular', sans-serif;
    font-weight: 500;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
  }

  thead th {
    padding: 10px;
    border-radius: 7px;
    cursor: pointer;
    color: ${({ theme }) => theme.primaryGray};
    background: ${({ theme }) => theme.secondaryGray};
  }
  thead th:hover {
    transition-duration: 300ms;
    transform: translateY(-5px);
  }
  td {
    border: 1px solid ${({ theme }) => theme.border};
  }
`;

export const RightPane = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  padding: 17px 9px 32px 9px;

  & {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: left;
  }

  .listContainer {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0.5rem;
    max-height: 10vw;
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  label {
    padding: 0 !important;
    font-size: 1vw;
  }
  label:hover {
    background-color: black;
    color: white;
  }

  label:has(input:checked) {
    background-color: ${({ theme }) => theme.primaryGray};
    color: white;
  }

  .checked {
    padding: 10px;
    padding: 10px 20px;
    display: block;
    border-radius: 50px;
  }

  .questionMark {
    position: absolute;
    font-size: 10px;
    color: ${({ theme }) => theme.secondaryGray};
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.secondaryGray};
    border-radius: 50%;
    font-weight: normal;
    padding: 0 3px;
    top: -7px;
    right: -7px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
interface SelectionsProps {
  chosen?: Boolean;
}
export const Selections = styled.label<SelectionsProps>`
  cursor: pointer;
  // background: transparent;
  border:  1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  width: fit-content;
  margin-bottom: 3px;
  font-family: 'FontMedium', sans-serif;
  color: ${({ theme }) => theme.secondaryGray};
  /* padding: 3px 8px; */
  padding: 3px 0px;
  height: fit-content;
  margin-right: 6px;
  position: relative;


  span {
    font-size: 12px;
    padding: 3px 8px;
    font-weight: normal;
  }
    .span {
    font-size: 12px;
    padding: 3px 8px;
    font-weight: normal;
  }
`;

export const Breadcrumbs = styled.div`
  display: flex;
  display: flex;
  flex-wrap: wrap;

  label,
  span {
    font-size: 0.75rem;
    font-weight: initial;
    color: ${({ theme }) => theme.secondaryGray};
    padding-left: 5px;
    padding-right: 5px;
  }
  label {
    font-weight: 800;
    color: ${({ theme }) => theme.primaryGray};
  }
`;

export const SavedMidRow = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.placeholderBackground};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollThumb};
  }

  table {
    border-collapse: collapse;
    min-width: 92vw;

    .number {
      text-align: right;
    }
    .string {
      text-align: left;
    }
  }

  tr {
    font-family: 'FontRegular', sans-serif;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryGray};
    border-bottom: 1px solid ${({ theme }) => theme.border};
  }

  tbody > tr > td {
    padding: 12px;
    font-size: 14px;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontRegular', sans-serif;
  }
  th,
  tbody > tr:nth-last-child(1) > td {
    color: ${({ theme }) => theme.primaryGray};
    font-size: 14px;
    font-family: 'FontBold', sans-serif;
  }
  th {
    padding: 12px;
    font-size: 18px;
  }
  tr:hover > td {
    background-color: ${({ theme }) => theme.background};
  }
`;

interface BottomRowProps {
  isGraph?: Boolean;
}

export const BottomRow = styled.div<BottomRowProps>`
  display: flex;
  justify-content: flex-end;
  gap: 9px;
  height: fit-content;
  .notAllowed:hover {
    color: white;
  }
`;

export const NavigationButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.secondaryGray};
`;

export const Line = styled.span`
  font-weight: 300 !important;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primaryGray};
`;

export const FilterDrop = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 1vw;
`;

export const HeadModul = styled.div`
  z-index: 100;
  background-color: ${({ theme }) => theme.placeholderBackground};
  height: 'fit-content';
  overflow-y: scroll;
  width: fit-content;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  align-self: center;



  &::-webkit-scrollbar {
    display: none;
  }
  width: fit-content;
  /*   left: 35%;
  top: 15%; */
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  /*   position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  .x {
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    align-self: flex-end;
    padding: 5px 6px 3px 6px;
    size: 4px;
  }
  .closeIcon {
    /*      stroke: black; */
    stroke-width: 55;
  }
`;

export const ModulContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  //margin-top: 50px;

  label {
    margin-bottom: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .drop_area {
    border: 1px dashed ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
  }



  h1 {
    font-weight: normal;
    font-family: 'FontMedium', sans-serif;
    font-size: 18px;
    margin-bottom: 4px;
    margin-bottom: 40px;
  }

  .editDataForm {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;

  h1 {
    font-size:10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  input, select {
    width: 200px
  }
  }
`;

export const Container = styled.div`
  display: grid;

  button {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .fBTN {
    margin: 0 8px 0 0;
    width: auto;
    max-width: 200px;
    margin-bottom: 10px;
  }

  .removelabel {
    cursor: pointer;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    width: fit-content;
    margin-bottom: 8px;
    font-family: 'FontMedium', sans-serif;
    color: ${({ theme }) => theme.primaryGray};
    padding: 9px 12px;
    height: fit-content;
    padding: 3px 9px;

    path,
    svg {
      pointer-events: none;
    }
  }
  select {
    width: 100%;
  }

`;
