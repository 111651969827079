import { DashboardTypes, GetQueriesQuery, Placeholder, TemplatePlaceholder, UserQuery } from 'API';
import { API } from 'aws-amplify';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { getQueries } from 'graphql/queries';

import { useContext, useEffect, useState } from 'react';
import { useDragAndDrop } from './useDragAndDrop';
import { orderList2 } from 'components/newSetup/SortingPlaceHolderList';
import InputContext from 'context/InputContext';
import { PlaceholderType } from 'components/newSetup/PlaceholderContainer';
import { useTemplateContext } from 'context/TemplateContext';
import { table } from 'console';
import { set } from 'lodash';
import { useActiveSubCampaign } from './useSubcampaignList';
import { useSearchParams } from 'react-router-dom';
// import { useSubCampaignId } from './useParameters';

// type PlaceholderType = {
//   type: 'Chart' | 'Table';
//   minor: number;
//   id: string;
//   query_id?: string;
// };

export const useGetStoredQueriesDetails = (
  activeCampaign: ACdetails | undefined,
  newPlaceholder: PlaceholderType | undefined,
  fetchedPlaceholders: TemplatePlaceholder[],
  publishedData?: Placeholder[],
  setAddingNewTable?: React.Dispatch<React.SetStateAction<boolean>>,
): readonly [
  UserQuery[],
  React.Dispatch<React.SetStateAction<UserQuery[]>>,
  PlaceholderType[],
  (value: React.SetStateAction<PlaceholderType[]>) => void,
  (value: React.SetStateAction<PlaceholderType[]>) => void,
  PlaceholderType[],
  Placeholder[],
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = "subcampaignID";
  const getSubCampaignId = searchParams.get(searchParam) ?? "none";
  // const [getSubCampaignId] = useSubCampaignId()
  const [storedQueries, setStoredQueries] = useState<Array<UserQuery>>([]);
  const [publishedQueries, setPublishedQueries] = useState<Array<Placeholder>>([]);
  const { setOrder2Table, setOrder2Chart } = useContext(InputContext);
  // const {setFetchedPlaceholders} = useTemplateContext();
  const { dashType, canDoInsourceAndOnInsourcePortal } = useCampaignContext();
  const [activeSubCampaign, subcampaignList] = useActiveSubCampaign(dashType);
  const [tablePlaceholderList, setTablePlaceholderList] = useState<Array<PlaceholderType>>([
    { type: 'Table', minor: 1, id: '101', query_id: '101' },
  ]);
  const [graphPlaceholderList, setGraphPlaceholderList] = useState<Array<PlaceholderType>>([
    { type: 'Chart', minor: 1, id: '201', query_id: '201' },
  ]);

  const { getOrderList, updateOrderList } = useDragAndDrop({ tablePlaceholderList });

  const newOrderForBackend = (newOrder: any[]) => {
    const newOrderedList = newOrder.map((item, index) => ({
      Order: index + 1,
      QueryId: item.position,
    })) as orderList2[];

    return newOrderedList;
  };

  const makeNewOrder = (placeholderObjects: PlaceholderType[]) => {
    const newOrder = placeholderObjects.map((item, index) => {
      return {
        Order: index + 1,
        QueryId: item.id,
      };
    }) as orderList2[];

    return newOrder;
  };

  useEffect(() => {
    const sortPlaceholders = async (storedQueries: UserQuery[]) => {

      async function fetchImmages() {
        try {
          const response = (await API.graphql({
            query: getQueries,
            variables: {
              campaign_id: activeCampaign?.campaignId?.toString(),
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })) as { data: GetQueriesQuery };

          const storedQueries = response.data.getQueries;

          // FILTER THE ONE THAT START WITH OOH3, OOH4, OOH5, Print3, Print4, Print5, TV4, TV5, TV6, Radio4, Radio5, Radio6, Cinema3, Cinema4, Cinema5
          const filteredStoredQueries = storedQueries?.filter(
            (el) =>
              el?.position?.startsWith('OOH3') ||
              el?.position?.startsWith('OOH4') ||
              el?.position?.startsWith('OOH5') ||
              el?.position?.startsWith('Print3') ||
              el?.position?.startsWith('Print4') ||
              el?.position?.startsWith('Print5') ||
              el?.position?.startsWith('TV4') ||
              el?.position?.startsWith('TV5') ||
              el?.position?.startsWith('TV6') ||
              el?.position?.startsWith('Radio4') ||
              el?.position?.startsWith('Radio5') ||
              el?.position?.startsWith('Radio6') ||
              el?.position?.startsWith('Cinema3') ||
              el?.position?.startsWith('Cinema4') ||
              el?.position?.startsWith('Cinema5'),
          );
          return filteredStoredQueries;
        } catch (e) {
          console.log('ERROR FETCHING QUARIES', e);
        }
      }
      if (storedQueries && storedQueries !== null) {
        /**
         * This block of code handles the processing of stored queries and their respective orders.
         * First, it filters the stored queries into `tablePlaceholders` and `graphPlaceholders` based on their `position` property.
         * Then, it fetches the existing order lists for tables and charts from the backend.
         *
         *
         * If the fetched order list is empty, it means this is not a template. In this case, it performs the following operations:
         * 1. If the fetched order list for tables (`fetchedTableOrder`) has elements and its length is equal to the length of `tablePlaceholders`,
         *    it sets `order2Table` to `fetchedTableOrder`.
         *
         * 2. If the fetched order list for tables has elements but its length is not equal to the length of `tablePlaceholders`,
         *    it generates a new order list for tables (`newOrderTable`), updates the backend with this new order list, and sets `order2Table` to `newOrderTable`.
         *
         * 3. If the fetched order list for tables is empty, it generates a new order list for tables (`newOrderTable`), updates the backend with this new order list,
         *    and sets `order2Table` to `newOrderTable`.
         *
         * The same operations are performed for charts using `fetchedChartOrder` and `graphPlaceholders`.
         *
         * If the fetched order list is not empty, it means this is a template.
         * In this case, it generates new order lists for tables and charts using the `newOrderForBackend` function,
         * and sets `order2Table` and `order2Chart` to these new order lists.
         *
         * If the fetched order list is not empty, it does nothing.
         */

        // if OOH PRINT TV OR RADIO, CINEMA, FETCH SfilteredStoredQueries
        if (
          dashType === DashboardTypes.OOH ||
          dashType === DashboardTypes.PRINT ||
          dashType === DashboardTypes.TV ||
          dashType === DashboardTypes.RADIO ||
          dashType === DashboardTypes.CINEMA
        ) {

          setTimeout(async () => {

            const images = await fetchImmages();
            const concatenatedStoredQueries = storedQueries.concat(images as UserQuery[]);
            console.log("ARE THERE ANY STORED QUERIES", concatenatedStoredQueries)
            setStoredQueries(concatenatedStoredQueries);
          }, 4000);


        } else {
          setStoredQueries(storedQueries as Array<UserQuery>);
          console.log("ARE THERE ANY STORED QUERIES", storedQueries)
        }

        const tablePlaceholders = storedQueries.filter((el) => el?.position?.startsWith('1'));
        const graphPlaceholders = storedQueries.filter((el) => el?.position?.startsWith('2'));

        const fetchedTableOrder = await getOrderList('Table');
        const fetchedChartOrder = await getOrderList('Chart');

        if (fetchedPlaceholders.length === 0) {
          // IF IT IS NOT A TEMPLATE
          if (fetchedTableOrder && fetchedTableOrder?.length > 0) {
            if (fetchedTableOrder.length === tablePlaceholders.length) {
              setOrder2Table(fetchedTableOrder);

              // console.log("THis is the newOrderTable", fetchedTableOrder)
            } else {
              const newOrderTable = newOrderForBackend(tablePlaceholders);
              updateOrderList('Table', newOrderTable);
              setOrder2Table(newOrderTable);
            }
          } else {
            const newOrderTable = newOrderForBackend(tablePlaceholders);
            updateOrderList('Table', newOrderTable);
            setOrder2Table(newOrderTable);
          }

          if (fetchedChartOrder && fetchedChartOrder?.length > 0) {
            if (fetchedChartOrder.length === graphPlaceholders.length) {
              setOrder2Chart(fetchedChartOrder);

              // console.log("THis is the newOrderTable", fetchedChartOrder)
            } else {
              const newOrderChart = newOrderForBackend(graphPlaceholders);
              updateOrderList('Chart', newOrderChart);
              setOrder2Chart(newOrderChart);
            }
          } else {
            const newOrderChart = newOrderForBackend(graphPlaceholders);
            updateOrderList('Chart', newOrderChart);
            setOrder2Chart(newOrderChart);
          }
        } else {
          // IF IT IS A TEMPLATE

          //SOLUTION WITH ORDER PROPERTY
          const tableOrder = fetchedPlaceholders.filter((el) => el?.Placeholder?.startsWith('1'));
          tableOrder.sort((a, b) => Number(a.Placeholder) - Number(b.Placeholder));
          tableOrder.sort((a, b) => Number(a.OrderIndex) - Number(b.OrderIndex));
          const adjustedTableOrder = tableOrder.map((el) => {
            return {
              campaign_id: activeCampaign?.campaignId?.toString(),
              metrics: el.Metrics,
              position: el.Placeholder,
              query_id: el.S3Location,
              query_id_cc: el.S3Location_cc,
              title: el.Placeholder_titel,
            };
          });
          const graphOrder = fetchedPlaceholders.filter((el) => el?.Placeholder?.startsWith('2'));
          graphOrder.sort((a, b) => Number(a.Placeholder) - Number(b.Placeholder));
          graphOrder.sort((a, b) => Number(a.OrderIndex) - Number(b.OrderIndex));
          const adjustedGraphOrder = graphOrder.map((el) => {
            return {
              campaign_id: activeCampaign?.campaignId?.toString(),
              metrics: el.Metrics,
              position: el.Placeholder,
              query_id: el.S3Location,
              query_id_cc: el.S3Location_cc,
              title: el.Placeholder_titel,
            } as UserQuery;
          });
          // console.log("OVOSUMOJIDRUGOVI", adjustedTableOrder, tableOrder)

          // ORIGINAL SOLUTION
          const newOrderTable = newOrderForBackend(adjustedTableOrder);
          setOrder2Table(newOrderTable);
          const newOrderChart = newOrderForBackend(adjustedGraphOrder);
          setOrder2Chart(newOrderChart);

          // console.log("THis is the newOrderTable",storedQueries, tablePlaceholders, newOrderTable, graphPlaceholders, newOrderChart)
        }

        /**
         *  1. If `tablePlaceholders` or `graphPlaceholders` has at least one element, it maps each element to an object of type `PlaceholderType`.
         *     `type` is set to 'Table' or 'Chart' respectively.
         *    - `minor` is calculated based on the `position` of the placeholder. If position is a number greater than 99, getMinor is the last two digits of that number.
         *       If position is a number not greater than 99, getMinor is the number after the decimal point.
         *    - `id` and `original_id` are set to the `position` of the placeholder.
         *    - `query_id` is set to the `query_id` of the placeholder.
         *    - After mapping, it sorts the resulting array of `PlaceholderType` objects by `minor` and
         *      updates the respective state variable (`tablePlaceholderList` or `graphPlaceholderList`).
         *
         * 2. If `tablePlaceholders` or `graphPlaceholders` is an empty array, it creates a new `PlaceholderType` object with predefined values,
         *    generates a new order using this object, and updates the state variables `orderList` and `order2Table`.
         *
         * If `tablePlaceholders` or `graphPlaceholders` is undefined, it does nothing.
         */
        if (tablePlaceholders !== undefined) {
          if (tablePlaceholders.length > 0) {
            const placeholderObjects: Array<PlaceholderType> = tablePlaceholders.map((e) => {
              const numberE = Number(e?.position);
              const getMinor = numberE > 99 ? numberE % 100 : Number(e?.position?.split('.')[1]);

              return {
                type: 'Table',
                minor: getMinor,
                id: e?.position as string,
                query_id: e?.query_id as string,
                original_id: e?.position as string,
              };
            });
            //sort by minor
            placeholderObjects.sort((a, b) => a.minor - b.minor);
            setTablePlaceholderList(placeholderObjects);
          } else {
            const newQuery = {
              type: 'Table',
              minor: 1,
              id: '101',
              query_id: '101',
              original_id: '101',
            } as PlaceholderType;

            const order = makeNewOrder([newQuery]);
            updateOrderList('Table', order);
            setOrder2Table(order);
          }
        }

        if (graphPlaceholders !== undefined) {
          if (graphPlaceholders.length > 0) {
            const placeholderObjects2: Array<PlaceholderType> = graphPlaceholders.map((e) => {
              const numberE = Number(e?.position);
              const getMinor = numberE > 99 ? numberE % 100 : Number(e?.position?.split('.')[1]);

              return {
                type: 'Chart',
                minor: getMinor,
                id: e?.position as string,
                query_id: e?.query_id as string,
                original_id: e?.position as string,
              };
            });

            //sort by minor
            placeholderObjects2.sort((a, b) => a.minor - b.minor);
            setGraphPlaceholderList(placeholderObjects2);
          } else {
            const newQuery = {
              type: 'Chart',
              minor: 1,
              id: '201',
              query_id: '201',
              original_id: '201',
            } as PlaceholderType;

            const order = makeNewOrder([newQuery]);
            updateOrderList('Chart', order);
            setOrder2Chart(order);
          }
        }
      }
    };

    async function fetchStoredQueries() {
      setTablePlaceholderList([{ type: 'Table', minor: 1, id: '101', query_id: '101', original_id: '101' }]);
      setGraphPlaceholderList([{ type: 'Chart', minor: 1, id: '201', query_id: '201', original_id: '201' }]);
      try {
        const response = (await API.graphql({
          query: getQueries,
          variables: {
            campaign_id: activeCampaign?.campaignId?.toString(),
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetQueriesQuery };

        const storedQueries = response.data.getQueries;

        sortPlaceholders(storedQueries as UserQuery[]);
      } catch (e) {
        console.log('ERROR FETCHING QUARIES', e);
      }
    }

    if (activeCampaign?.authorized || (!activeCampaign?.authorized && canDoInsourceAndOnInsourcePortal)) {
      if (activeCampaign && fetchedPlaceholders.length === 0 && (dashType === DashboardTypes.DIGITAL || dashType === DashboardTypes.INSOURCE_DATA)) {
        fetchStoredQueries();
      } else if (activeCampaign && fetchedPlaceholders.length > 0) {
        setTablePlaceholderList([{ type: 'Table', minor: 1, id: '101' }]);
        setGraphPlaceholderList([{ type: 'Chart', minor: 1, id: '201' }]);
        setStoredQueries([]);
        const storedQueries = fetchedPlaceholders.map((el) => {
          return {
            campaign_id: activeCampaign?.campaignId?.toString(),
            position: el.Placeholder,
            metrics: el.Metrics,
            query_id: el.S3Location,
            query_id_cc: el.S3Location_cc,
            title: el.Placeholder_titel,
          };
        }) as UserQuery[];
        // setFetchedPlaceholders([]);
        sortPlaceholders(storedQueries);
      }
    } else if (activeCampaign?.authorized === false && publishedData) {
      setTablePlaceholderList([{ type: 'Table', minor: 1, id: '101' }]);
      setGraphPlaceholderList([{ type: 'Chart', minor: 1, id: '201' }]);
      setStoredQueries([]);
      const storedQueries = publishedData.map((el) => {
        return {
          campaign_id: activeCampaign?.campaignId?.toString(),
          position: el.position,
          metrics: el.metrics,
          query_id: el.query_id,
          query_id_cc: el.query_id_cc,
          title: el.title,
        };
      }) as UserQuery[] & { outputlocation: string };
      sortPlaceholders(storedQueries);
      setPublishedQueries(publishedData);
    }
  }, [activeCampaign, dashType, fetchedPlaceholders, publishedData, getSubCampaignId]);

  useEffect(() => {
    if (newPlaceholder) {
      if (newPlaceholder.type === 'Chart') {
        setGraphPlaceholderList((ps) => [...ps, newPlaceholder]);
        setAddingNewTable !== undefined && setAddingNewTable(true);
      } else if (newPlaceholder.type === 'Table') {
        setTablePlaceholderList((ps) => [...ps, newPlaceholder]);
        setAddingNewTable !== undefined && setAddingNewTable(true);
      }
    }
  }, [newPlaceholder]);

  return [
    storedQueries,
    setStoredQueries,
    tablePlaceholderList,
    setTablePlaceholderList,
    setGraphPlaceholderList,
    graphPlaceholderList,
    publishedQueries,
  ] as const;
};

export const useFetchStoredQueries = (activeCampaign: ACdetails | undefined) => {
  const [storedQueries, setStoredQueries] = useState<Array<UserQuery>>([]);
  const [tablePlaceholderList, setTablePlaceholderList] = useState<Array<PlaceholderType>>([
    { type: 'Table', minor: 1, id: '101', query_id: '101', original_id: '101' },
  ]);
  const [graphPlaceholderList, setGraphPlaceholderList] = useState<Array<PlaceholderType>>([
    { type: 'Chart', minor: 1, id: '201', query_id: '201', original_id: '201' },
  ]);

  const fetchStoredQueries = async () => {
    setTablePlaceholderList([{ type: 'Table', minor: 1, id: '101', query_id: '101', original_id: '101' }]);
    setGraphPlaceholderList([{ type: 'Chart', minor: 1, id: '201', query_id: '201', original_id: '201' }]);
    try {
      const response = (await API.graphql({
        query: getQueries,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetQueriesQuery };
      const storedQueries = response.data.getQueries;
      sortPlaceholders(storedQueries as UserQuery[]);
    } catch (e) {
      console.log('ERROR FETCHING QUARIES', e);
    }
  };

  const sortPlaceholders = (storedQueries: UserQuery[]) => {
    if (storedQueries && storedQueries !== null) {
      setStoredQueries(storedQueries as Array<UserQuery>);
      const tablePlaceholders = storedQueries.filter((el) => el?.position?.startsWith('1'));
      const graphPlaceholders = storedQueries.filter((el) => el?.position?.startsWith('2'));

      if (tablePlaceholders !== undefined) {
        if (tablePlaceholders.length > 0) {
          const placeholderObjects: Array<PlaceholderType> = tablePlaceholders.map((e) => {
            const numberE = Number(e?.position);
            const getMinor = numberE > 99 ? numberE % 100 : Number(e?.position?.split('.')[1]);

            return {
              type: 'Table',
              minor: getMinor,
              id: e?.position as string,
              query_id: e?.query_id as string,
              original_id: e?.position as string,
            };
          });
          //sort by minor
          placeholderObjects.sort((a, b) => a.minor - b.minor);
          setTablePlaceholderList(placeholderObjects);
        }
      }

      if (graphPlaceholders !== undefined) {
        if (graphPlaceholders.length > 0) {
          const placeholderObjects2: Array<PlaceholderType> = graphPlaceholders.map((e) => {
            const numberE = Number(e?.position);
            const getMinor = numberE > 99 ? numberE % 100 : Number(e?.position?.split('.')[1]);

            return {
              type: 'Chart',
              minor: getMinor,
              id: e?.position as string,
              query_id: e?.query_id as string,
              original_id: e?.position as string,
            };
          });
          //sort by minor
          placeholderObjects2.sort((a, b) => a.minor - b.minor);
          setGraphPlaceholderList(placeholderObjects2);
        }
      }
    }
  };

  return { storedQueries, fetchStoredQueries };
};
