import { TxtContainer } from 'components/Main/Main.styles';
import { KPIholder } from './TV/TV.styles';
import ReactLoading from 'react-loading';
import { KPItoS3 } from 'components/KPItoS3';
import { DefaultTheme } from 'styled-components';
import { useState } from 'react';

interface KPIComponentProps {
  queryStatus: string;
  title: string;
  subtitle: string;
  location?: string;
  componentID?: string;
  ctc?: number;
  theme?: DefaultTheme
}

const KPIComponent: React.FC<KPIComponentProps> = (props: KPIComponentProps) => {
  const { queryStatus, title, subtitle, location, componentID, ctc, theme } = props;

  const [titleFromAPI, setTitleFromAPI] = useState<string>();
  const [subtitleFromAPI, setSubtitleFromAPI] = useState<string>();
  const [formatFromAPI, setFormatFromAPI] = useState<string>();

  const getTitleFromAPI = (title: string, subtitle: string, format: string) => {
    title !== "" && setTitleFromAPI(title);
    subtitle !== "" && setSubtitleFromAPI(subtitle);
    format !== "" && setFormatFromAPI(format);
  }

  const [isKPIeditable, setIsKPIeditable] = useState(false);


  return (
    <KPIholder>
      {queryStatus === 'RUNNING' ? (
        <TxtContainer className="loader">
          {theme !== undefined && <ReactLoading type="spinningBubbles" color={theme.publishButton} />}
        </TxtContainer>
      ) : (
        <TxtContainer>
          {!isKPIeditable && <h1>{titleFromAPI ? titleFromAPI : title}</h1>}
          {!isKPIeditable && <h2>{subtitleFromAPI ? subtitleFromAPI : subtitle}</h2>}
          {/* <h2>{subtitle}</h2> */}
          {ctc ? (
            <div className="valueFlex">
              <h4 className="tooltip">
                {Intl.NumberFormat('en-DK').format(ctc)}
                <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(ctc)}</span>
              </h4>
            </div>
          ) : (
            <>
              {location !== undefined && componentID !== undefined &&
                <KPItoS3 setIsKPIeditable={setIsKPIeditable} getTitleFromAPI={getTitleFromAPI} location={location} componentID={componentID}></KPItoS3>
              }
            </>
          )}
        </TxtContainer>
      )}
    </KPIholder>
  );
};

export default KPIComponent;
