import {
  CreateDetailedPlanningMutation,
  CreateDetailedPlanningOrderMutation,
  DeleteDetailedPlanningMutation,
  DetailedPlanningOrderResponse,
  Environment,
  GetDetailedPlanningQuery,
  UpdateDetailedPlanningMutation,
  detailedPlanningResponse,
  sectionOrderResponse,
  tableOrderResponse,
} from 'API';
import { API } from 'aws-amplify';
import {
  createDetailedPlanning,
  createDetailedPlanningOrder,
  deleteDetailedPlanning,
  updateDetailedPlanning,
} from 'graphql/mutations';
import { getDetailedPlanning } from 'graphql/queries';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';
import { CreateRow } from './PlanItem';
import { ACdetails } from 'context/CampaignContext';
import { IActiveOrganization } from 'context/ClientContext';
import { useTab } from 'customHooks/useParameters';
import { useEffect, useState } from 'react';

type Response =
  | {
    __typename: 'responseStatus';
    Message?: string | null | undefined;
    StatusCode?: number | null | undefined;
  }
  | null
  | undefined;

export type columnType = {
  id: string;
  name: string;
  valueType: string;
};

export const useFetchPlanData = (
  getEnvironment: Environment | undefined,
  activeCampaign: ACdetails | undefined,
  activeOrganization: IActiveOrganization | undefined,
  tab: string,
) => {
  const callApi = async () => {

    const response = (await API.graphql({
      query: getDetailedPlanning,
      variables: {
        // Environment: getEnvironment ,
        Environment: getEnvironment,
        CampaignId: activeCampaign?.campaignId,
        OrganizationId: activeOrganization?.OrganizationId,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as { data: GetDetailedPlanningQuery };
    // console.log('This is the response', response)
    const { data: responseData } = response;
    const { getDetailedPlanning: actualList } = responseData;


    if (actualList === null || actualList === undefined) {
      return null;
    } else {
      return actualList as detailedPlanningResponse;
    }
  };

  const { data: actualList, isLoading,  isError, refetch } = useQuery<detailedPlanningResponse | null, Error>({
    queryKey: ['detailed-planning'],
    queryFn: callApi,
    keepPreviousData: false,
  });

  // const detailed-planning = async () => {
  //   const aLot = await callApi()
  // }

  const [tabIsLoading, setTabIsLoading] = useState(false);

  let newColumns: MRT_ColumnDef<any>[] =
    actualList && actualList.columns
      ? actualList.columns.map((column) => {
        return {
          accessorKey: column?.id as string,
          header: column?.name,
          minSize: 50, //min size enforced during resizing
          maxSize: 400, //max size enforced during resizing
          size:
            column?.name === 'Comments' || column?.name === 'Reasons for Touchpoint'
              ? 300
              : column?.name === 'Communication Task'
                ? 170
                : 115,
          // size: 95,
          type: column?.valueType,
          enableResizing: true,
        };
      })
      : ({} as any);

  let columnInputTypes = actualList?.columns?.map((column) => {
    const columnType = column?.id as string;
    const columnValueType = column?.type;

    return {
      [columnType]: columnValueType,
    };
  }) as { [x: string]: string }[];

  let newRow =
    (actualList?.rows?.length ?? 0) > 0
      ? actualList?.rows?.map((row) => {
        const section = row?.section;
        const values =
          row?.values !== null &&
          row?.values !== undefined &&
          row?.values.map((value) => {
            const columns = value?.columns;
            const rowId = value?.rowId;

            const newColumns = columns?.flatMap((column) => {
              const columnId = column?.columnId !== null && column?.columnId !== undefined ? column?.columnId : '';

              const valueType = column?.valueType;

              const StringValue = column?.value?.StringValue ? String(column?.value?.StringValue) : '';
              const IntValue =
                column?.value?.IntValue !== null && column?.value?.IntValue !== undefined
                  ? String(column?.value?.IntValue)
                  : '';
              const BooleanValue =
                column?.value?.BooleanValue !== null && column?.value?.BooleanValue !== undefined
                  ? String(column?.value?.BooleanValue)
                  : '';
              const FloatValue =
                column?.value?.FloatValue !== null && column?.value?.FloatValue !== undefined
                  ? String(column?.value?.FloatValue)
                  : '';

              const value = StringValue || IntValue || BooleanValue || FloatValue || '';

              return {
                [columnId]: value,
                section: section,
                rowId: rowId,
                valueType,
              };
            }) as { [x: string]: string; valueType: string; section: string; rowId: string }[];

            // instead of having an array of objects, I want to have one single object where the properties are the other objects

            const newObject = newColumns.reduce((acc, curr) => {
              return { ...acc, ...curr };
            }, {});

            return newObject;
          });

        return values;
      })
      : ({} as any);

  //   now I want to put all the newRos in one single array instead of having an array of arrays
  let newRow2 = [] as any[] | undefined;

  let columnTypes = actualList?.columns?.map((column) => {
    const columnType = column?.id as string;
    const columnValueType = column?.valueType;

    return {
      [columnType]: columnValueType,
    };
  }) as { [x: string]: string }[];

  if (Object.keys(newRow).length > 0) {
    newRow2 = newRow.reduce((acc: any, curr: any) => {
      return [...acc, ...curr];
    }, []) as any[];

    // console.log("This is the newRow", newRow)
  } else {
    newRow2 = [] as any[];
  }

  
  useEffect(() => {
    
    const fetchData = async () => {
      if (tab === 'plan') {
        setTabIsLoading(true);
        const test = await refetch();
        if (test.status === 'success') {
          setTabIsLoading(false);
        }
      } else {
        newColumns = [];
        newRow2 = undefined;
        columnTypes = [];
        columnInputTypes = [];
        setTabIsLoading(true);
      }
    };

    fetchData();
  }, [tab]);

  return { newColumns, newRow2, isLoading, isError, refetch, columnTypes, columnInputTypes, tabIsLoading };
};

export const useCreateRow = (
  getEnvironment: Environment,
  activeCampaign: ACdetails,
  activeOrganization: IActiveOrganization,
): readonly [(row: CreateRow) => Promise<sectionOrderResponse[] | null>] => {
  const callApi = async (row: CreateRow) => {
    try {
      const response = (await API.graphql({
        query: createDetailedPlanning,
        variables: {
          Environment: 'PRODUCTION',
          CampaignId: activeCampaign?.campaignId,
          TemplateId: 'OMD#DK#1',
          Values: row,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: CreateDetailedPlanningMutation };

      const { data: responseData } = response;
      const { createDetailedPlanning: actualList } = responseData;
      const { tableOrder } = actualList as DetailedPlanningOrderResponse;
      const { rows } = tableOrder as tableOrderResponse;

      if (actualList === null || actualList === undefined) {
        return null;
      } else {
        return rows as sectionOrderResponse[];
      }
    } catch (error) {
      console.error('Error occurred while creating row:', error);
      return null;
    }
  };

  return [callApi] as const;
};

export const useDeleteRow = (
  getEnvironment: Environment,
  activeCampaign: ACdetails,
  activeOrganization: IActiveOrganization,
): readonly [(rowID: string) => Promise<Response | null>] => {
  const callDeleteApi = async (rowID: string) => {
    try {
      const response = (await API.graphql({
        query: deleteDetailedPlanning,
        variables: {
          Environment: getEnvironment,
          CampaignId: activeCampaign?.campaignId,
          RowIds: rowID,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: DeleteDetailedPlanningMutation };

      const { data: responseData } = response;
      const { deleteDetailedPlanning: actualList } = responseData;

      if (actualList !== null && actualList !== undefined) {
        if (actualList.StatusCode === 200) {
          return actualList as Response;
        } else {
          return null;
        }
      } else {
        return null;
      }

    } catch (error: any) {
      const errors = error?.errors[0];
      const message = errors?.message;

      console.error('Error updating row:', errors);
      return null;
    }
  };

  return [callDeleteApi] as const;
};

export const useUpdateRow = (
  getEnvironment: Environment,
  activeCampaign: ACdetails,
  activeOrganization: IActiveOrganization,
): readonly [(rowID: any) => Promise<string | null>] => {
  const callUpdateApi = async (rowID: any): Promise<string | null> => {
    try {
      const response = (await API.graphql({
        query: updateDetailedPlanning,
        variables: {
          Environment: 'PRODUCTION',
          CampaignId: activeCampaign?.campaignId,
          Columns: rowID,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UpdateDetailedPlanningMutation };

      const { data: responseData } = response;
      const { updateDetailedPlanning: actualList } = responseData;

      if (actualList !== null && actualList !== undefined) {
        if (actualList.StatusCode === 200) {
          return 'updated';
        } else {
          return null;
        }
      } else {
        return null;
      }

      // return "updated";
    } catch (error: any) {
      const errors = error?.errors[0];
      const message = errors?.message;

      console.error('Error updating row:', errors);
      return null;
    }
  };

  return [callUpdateApi] as const;
};

export const useUpdateOrderRow = (
  getEnvironment: Environment,
  activeCampaign: ACdetails,
  activeOrganization: IActiveOrganization,
): readonly [(rowArray: { rowId: string }[]) => Promise<string | null>] => {
  const callUpdateOrderRowsApi = async (rowArray: { rowId: string }[]): Promise<string | null> => {
    try {
      const response = (await API.graphql({
        query: createDetailedPlanningOrder,
        variables: {
          Environment: 'PRODUCTION',
          CampaignId: activeCampaign?.campaignId,
          tableOrder: {
            rows: {
              section: 'Communication Task',
              values: rowArray,
            },
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: CreateDetailedPlanningOrderMutation };

      let isUpdated = null;

      const { data: responseData } = response;
      const { createDetailedPlanningOrder: actualList } = responseData;
      const StatusCode = actualList?.StatusCode;

      if (StatusCode === 200) {
        isUpdated = 'updated';
      }

      return isUpdated;
    } catch (error: any) {
      const errors = error?.errors[0];
      const message = errors?.message;

      console.error('Error updating row:', errors);
      return message;
    }
  };

  return [callUpdateOrderRowsApi] as const;
};
