import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  //padding: 50px 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
`;

export const HeadModul = styled.div`
  box-shadow: 0 0 0 8000px rgba(0, 0, 0, 0.7);
  padding: 40px 60px;
  z-index: 100;
  height: 100%; /* 100% Full-height */
  min-width: 1100px; /* 0 width - change this with JavaScript */
  display: flex;
  flex-direction: column;
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: ${({ theme }) => theme.placeholderBackground};
  overflow-x: hidden; /* Disable horizontal scroll */
  // padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
`;

export const ModulHeader = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  & > * {
    height: 'fit-content';
  }

  .links {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2vh;

    h1 {
      font-size: 24px;
      font-family: 'FontRegular', sans-serif;
      color: ${({ theme }) => theme.primaryGray};
      font-weight: normal;
    }
  }

  .second {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    justify-self: end;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  .closeCampaign {
    cursor: pointer;
  }

  .secondClose {
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    padding: 5px 6px 3px 6px;
    size: 4px;
    justify-self: end;
    margin-left: auto;
    .closeIcon {
      stroke-width: 55;
    }
  }
  h2 {
    font-family: 'FontRegular', sans-serif;
    color: ${({ theme }) => theme.primaryGray};
    font-size: 12px;
    font-weight: normal;
  }

  .third {
    padding-top: 1vh;
    .links > button {
      font-family: 'fontBold';
    }
  }

  .fourth {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    justify-self: end;
    h2 {
      float: right;
      display: flex;
      justify-content: end;
    }
    label:hover {
      cursor: default;
    }

    .tagsContainer {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-self: flex-end;
      label {
        font-size: 10px;
        margin-right: 0px;
        margin-left: 6px;
      }
    }
  }

.campaignID {
    font-size: 24px;
    color: ${({ theme }) => theme.secondaryGray};
    margin-left: 5px;
    align-self: end;

}

`;

export const IconButton = styled.button`
  font-size: 12px;
  font-family: 'FontMedium', sans-serif;
  display: flex;
  gap: 2px;
  white-space: nowrap;
  margin: 0;
  color: ${({ theme }) => theme.primaryGray};
  border: none;
  box-shadow: none;
  margin-bottom: 3px;
  padding: 0;
  & > * {
    font-size: 14px;
  }
  &:hover {
    box-shadow: none;
  }
`;
