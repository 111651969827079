/* eslint-disable @typescript-eslint/no-unused-expressions */
import { datatype, filtertype, metricFunction } from 'API';
import { DarkButton } from 'components/style/Global';
import React, { useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import { Container, FilterDrop, HeadModul, ModulContainer } from './TableStyles';

import { AiOutlineDelete } from 'react-icons/ai';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';

import Select from 'react-select';

export const Modal = styled.div`
  position: fixed; /* Stay in place */
  /* z-index: 1; Sit on top */
  z-index: 9999999;
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.4vw;
  }

  .errorInputs {
    color: red;
  }

  .containerModal {
    margin-bottom: 3rem;
  }
`;

export interface Props {
  hidden: any;
  openClose(value: any): any;
  activeObject: any;
  clickedHeader: any;
  data: any;
  currentQuery: any;
  currentQueryCOPY: any;
  setCurrentQueryCOPY(data: any): any;
  testObject: any;
  mainReference: any;
  currentConversionsQueryCOPY: any;
  currentConversionsQuery: any;
  conversionsData: any;
  setChartMetrics?(chartMetric: any): any;
  chartMetrics?: any;
}

const PreviewModal = (props: Props) => {
  // console.log(props.conversionsData, "conversionsData")
  const filterOptions = props.data.map((element: any) => {
    return {
      label: element[props.clickedHeader],
      value: element[props.clickedHeader],
    };
  });

  const [checked, setChecked] = useState(props.activeObject.hidden);
  const [checked2, setChecked2] = useState<boolean>(
    props.currentQuery.query.calculatedMetrics.some(
      (calMet: any) => calMet.query === `SUM(#cost)/SUM(#${props.activeObject.column})`,
    ),
  );
  const [checked3, setChecked3] = useState(props.activeObject.active);
  const [checked4, setChecked4] = useState<boolean>(
    props.currentQueryCOPY.query.calculatedMetrics.some((element: any) => element.name === props.activeObject.name),
  );
  const [checked5, setChecked5] = useState(
    props.currentConversionsQueryCOPY?.query.calculatedMetrics.some(
      (element: any) => element.query === props.currentConversionsQueryCOPY.views[0].viewName.includes("facebook") ?
        'SUM(#conversions_cost)/SUM(#custom_conversions_value)' : props.currentConversionsQueryCOPY.views[0].viewName.includes("adform") ?
          'SUM(#conversions_cost)/SUM(#custom_conversions)' : props.currentConversionsQueryCOPY.views[0].viewName.includes("dv360") ?
            'SUM(#conversions_cost)/SUM(#total_conversions)' : 'SUM(#conversions_cost)/SUM(#total_conversions)',
    ),
  );



  const [filterToggle, setisFilterToggle] = useState(true);
  const [filterValue, setFilterValue] = useState<string | null>();
  const [filterList, setFilterList] = useState<Array<any> | null>();
  const [filterType, setFilterType] = useState<any>("IN");
  const [filterStatus, setFilterStatus] = useState<boolean>();
  const [oldName, setOldName] = useState<string>('');
  const [newName, setNewName] = useState<string>('');

  const foundIndex = props.currentQueryCOPY.query.detailedDimensions.findIndex(
    (dim: any) => dim.name === props.clickedHeader || dim.column === props.clickedHeader,
  );
  const foundIndexCC =
    props.currentConversionsQueryCOPY === undefined
      ? undefined
      : props.currentConversionsQueryCOPY.query.detailedDimensions.findIndex(
        (dim: any) => dim.name === props.clickedHeader,
      );
  const renderCostPerCC =
    props.currentConversionsQuery === undefined ? undefined : props.currentConversionsQuery.query.calculatedMetrics;
  const renderCostPer = props.currentQuery.query.calculatedMetrics.filter(
    (calcMetric: any) => calcMetric.name === props.clickedHeader,
  );

  const closeModal = () => {
    props.setCurrentQueryCOPY(props.currentQuery);
    props.openClose(props.clickedHeader);
  };

  const setapply = () => {
    props.openClose(props.clickedHeader);
  };

  const displayChars = (char: any) => {
    let sign = '';
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    char === 'EQUAL'
      ? (sign = '=')
      : char === 'LT'
        ? (sign = '>')
        : char === 'LTE'
          ? (sign = '>=')
          : char === 'GT'
            ? (sign = '<')
            : char === 'GTE'
              ? (sign = '<=')
              : char === 'IN'
                ? (sign = 'in')
                : char === 'NOTIN'
                  ? (sign = 'out')
                  : null;
    return sign;
  };

  const handleSwitch = () => {
    setChecked(!checked);
    const index = props.currentQueryCOPY.query.detailedDimensions.findIndex(
      (dimension: any) => dimension.column === props.activeObject.column,
    );
    props.currentQueryCOPY.query.detailedDimensions[index].hidden = !checked

    if (props.currentConversionsQueryCOPY !== undefined) {
      const indexConversion = props.currentConversionsQueryCOPY.query.detailedDimensions.findIndex(
        (dimension: any) => dimension.column === props.activeObject.column,
      );
      props.currentConversionsQueryCOPY.query.detailedDimensions[indexConversion].hidden = !checked;
    }

  };

  const dimensionNameChange = (event: any) => {
    const index = props.currentQueryCOPY.query.detailedDimensions.findIndex(
      (dimension: any) => dimension.column === props.activeObject.column,
    );
    props.currentQueryCOPY.query.detailedDimensions[index].name = event.target.value;
    if (props.currentConversionsQueryCOPY !== undefined) {
      const indexConversion = props.currentConversionsQueryCOPY.query.detailedDimensions.findIndex(
        (dimension: any) => dimension.column === props.activeObject.column,
      );
      props.currentConversionsQueryCOPY.query.detailedDimensions[indexConversion].name = event.target.value;
    }

  };

  const handleList = (value: any, actionMeta: any) => {

    if (actionMeta.action === 'remove-value') {
      if (filterList !== null) {
        setFilterList((ps: any) => [...ps.filter((elementName: any) => elementName !== actionMeta.removedValue.label)]);
      }
    }

    const newArray = value.map((element: any) => {
      const found = props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename?.find(
        (el: any) => el.after === element.label,
      );
      if (found !== undefined) {
        return found.before;
      } else {
        return element.label;
      }
    });
    setFilterList(newArray);
  };
  const handleFilterValue = (value: any) => {

    const found = props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename?.find(
      (el: any) => el.after === value.label,
    );
    if (found !== undefined) {
      setFilterValue(found.before);
    } else {
      setFilterValue(value.label);
    }
  };



  const filterRemove = (e: any) => {

    props.currentQueryCOPY.query.filters = props.currentQueryCOPY.query.filters.filter(
      (remove: any) => remove.column !== e,
    );
    if (props.currentConversionsQueryCOPY) {
      props.currentConversionsQueryCOPY.query.filters = props.currentConversionsQueryCOPY.query.filters.filter(
        (remove: any) => remove.column !== e,
      );
    }

    //  setisFilterToggle(!filterToggle)
    setFilterStatus(!filterStatus);
  };

  const handleFilterSave = () => {

    const checkChangedDimensionName = (headerName: string) => {
      const foundDim = props.currentQuery.query.detailedDimensions.find((dim: any) => dim.name === headerName);
      if (foundDim) {
        if (foundDim.column === "yw" || foundDim.column === "id"
          || foundDim.column === "month"
          || foundDim.column === "year") {
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    }

    const found = props.currentQueryCOPY.query.filters.find((element: any) => element.column === props.activeObject.column);
    const index = props.currentQueryCOPY.query.filters.indexOf(found);

    if (index === -1) {
      props.currentQueryCOPY.query.filters.push({
        column: props.activeObject.column,
        filter: {
          filtertype: filterType,
          datatype: props.clickedHeader === "yw" || props.clickedHeader.endsWith("id")
            || props.clickedHeader.endsWith("month")
            || props.clickedHeader.endsWith("year")
            || checkChangedDimensionName(props.clickedHeader) ? datatype.NUMBER : props.activeObject.column === "date" ? datatype.DATE : datatype.STRING,
          ...(filterType === 'IN' || filterType === 'NOTIN' ? { list: filterList } : { value: filterValue }),
        },
      });
    } else {
      props.currentQueryCOPY.query.filters[index] = {
        column: props.activeObject.column,
        filter: {
          filtertype: filterType,
          datatype: datatype.STRING,
          ...(filterType === 'IN' || filterType === 'NOTIN' ? { list: filterList } : { value: filterValue }),
        },
      };
    }


    if (props.currentConversionsQueryCOPY !== undefined) {
      const foundConversion = props.currentConversionsQueryCOPY.query.filters.find((element: any) => element.column === props.activeObject.column);
      const indexConversion = props.currentConversionsQueryCOPY.query.filters.indexOf(foundConversion);

      if (indexConversion === -1) {
        props.currentConversionsQueryCOPY.query.filters.push({
          column: props.activeObject.column,
          filter: {
            filtertype: filterType,
            datatype: props.clickedHeader === "yw" || props.clickedHeader.endsWith("id")
              || props.clickedHeader.endsWith("month")
              || props.clickedHeader.endsWith("year") ? datatype.NUMBER : datatype.STRING,
            ...(filterType === 'IN' || filterType === 'NOTIN' ? { list: filterList } : { value: filterValue }),
          },
        });
      } else {
        props.currentConversionsQueryCOPY.query.filters[index] = {
          column: props.activeObject.column,
          filter: {
            filtertype: filterType,
            datatype: datatype.STRING,
            ...(filterType === 'IN' || filterType === 'NOTIN' ? { list: filterList } : { value: filterValue }),
          },
        };
      }

    }



    setFilterStatus(!filterStatus);
    setisFilterToggle(!filterToggle);
    setFilterValue(null);
    setFilterType(filtertype.EQUAL);
  };

  const metricNameChange = (event: any) => {
    const index = props.currentQueryCOPY.query.metrics.findIndex(
      (metric: any) => metric.column === props.activeObject.column && metric.aggregation === props.activeObject.aggregation,
    );
    props.currentQueryCOPY.query.metrics[index].name = event.target.value;
    const found = props.testObject.find((oneObject: any) => oneObject.name === props.activeObject.name);
    found.name = event.target.value;
  };

  const metricDecimals = (e: any) => {
    const index = props.currentQueryCOPY.query.metrics.findIndex(
      (metric: any) => metric.column === props.activeObject.column,
    );

    props.currentQueryCOPY.query.metrics[index].decimals = Number(e.target.value);
    const found = props.testObject.find((oneObject: any) => (oneObject.name = props.activeObject.name));
    found.decimals = Number(e.target.value);
  };

  const handleMetricremove = () => {
    if (checked3 === true) {
      //REMOVE ID FROM ORDERBY
      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.filter(
        (id: any) => id !== props.activeObject.id,
      );
      //REMOVE FROM COPY ARRAY
      props.currentQueryCOPY.query.metrics = props.currentQueryCOPY.query.metrics.filter(
        (metric: any) => metric.name !== props.activeObject.name,
      );
      // CHANGE ACTIVE TO FALSE
      props.activeObject.active = !checked3;
      const found = props.testObject.find((oneObject: any) => (oneObject.name = props.activeObject.name));
      found.active = !checked3;
    } else {
      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.concat([props.activeObject.id]);
      props.currentQueryCOPY.query.metrics = props.currentQueryCOPY.query.metrics.concat([
        {
          aggregation: props.activeObject.aggregation,
          column: props.activeObject.column,
          decimals: props.activeObject.decimals,
          id: props.activeObject.id,
          name: props.activeObject.name,
        },
      ]);
      props.activeObject.active = !checked3;
      const found = props.testObject.find((oneObject: any) => (oneObject.name = props.activeObject.name));
      found.active = !checked3;
    }
    setChecked3(!checked3);
  };

  const handleCostPer = () => {

    if (checked2 === false) {
      const id = Math.floor(Math.random() * Date.now()).toString();


      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.concat([id]);
      props.currentQueryCOPY.query.calculatedMetrics = props.currentQueryCOPY.query.calculatedMetrics.concat([
        {
          name: `Cost Per ${props.activeObject.name}`,
          query: `SUM(#cost)/SUM(#${props.activeObject.column})`,
          decimals: 2,
          id: id,
        },
      ]);
    } else {
      //  const findNewCostPerName = props.currentQuery.query.calculatedMetrics.some((calMet: any) => calMet.query === `SUM(#cost)/SUM(#${props.clickedHeader})`)
      const element = props.currentQueryCOPY.query.calculatedMetrics.find(
        (calMet: any) => calMet.query === `SUM(#cost)/SUM(#${props.activeObject.column})`,
      );
      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.filter(
        (id: any) => id !== element.id,
      );


      props.currentQueryCOPY.query.calculatedMetrics = props.currentQueryCOPY.query.calculatedMetrics.filter(
        (metric: any) => metric.query !== `SUM(#cost)/SUM(#${props.activeObject.column})`,
      );
    }
    setChecked2(!checked2);
  };

  const handleCostPerRemove = () => {
    if (checked4 === true) {
      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.filter(
        (id: any) => id !== props.activeObject.id,
      );
      props.currentQueryCOPY.query.calculatedMetrics = props.currentQueryCOPY.query.calculatedMetrics.filter(
        (metric: any) => metric.name !== props.activeObject.name,
      );
    } else {
      const id = Math.floor(Math.random() * Date.now()).toString();
      props.currentQueryCOPY.query.orderBy = props.currentQueryCOPY.query.orderBy.concat([id]);
      props.currentQueryCOPY.query.calculatedMetrics =
        props.currentQueryCOPY.query.calculatedMetrics.concat(renderCostPer);
    }
    setChecked4(!checked4);
  };

  const handleAggregation = (event: any) => {
    const index = props.currentQueryCOPY.query.metrics.findIndex(
      (metric: any) => metric.column === props.activeObject.column,
    );
    props.currentQueryCOPY.query.metrics[index].aggregation = event.target.value;
    const found = props.testObject.find((oneObject: any) => (oneObject.name = props.activeObject.name));
    found.aggregation = event.target.value;
  };

  const calcMetricNameChange = (event: any) => {
    const index = props.currentQueryCOPY.query.calculatedMetrics.findIndex(
      (metric: any) => metric.query === props.activeObject.query,
    );
    const findOriginalName = props.currentQuery.query.calculatedMetrics.find((cm: any) => cm.query === props.activeObject.query).name
    props.currentQueryCOPY.query.calculatedMetrics[index].name = event.target.value;
    props.mainReference.query.calculatedMetrics[index].name = event.target.value;
  };

  const calcMetricDecimals = (e: any) => {
    const index = props.currentQueryCOPY.query.calculatedMetrics.findIndex(
      (metric: any) => metric.query === props.activeObject.query,
    );
    props.currentQueryCOPY.query.calculatedMetrics[index].decimals = Number(e.target.value);
    props.mainReference.query.calculatedMetrics[index].decimals = Number(e.target.value);
  };

  const handleAddCostPerCustomConversion = () => {


    if (checked5 === true) {
      const arr = props.currentConversionsQueryCOPY.query.calculatedMetrics.map((e: any) => e.id)
      props.currentConversionsQueryCOPY.query.orderBy = props.currentConversionsQueryCOPY.query.orderBy.filter(
        (id: any) => {
          return !arr.includes(id)
        },
      );
      props.currentConversionsQueryCOPY.query.calculatedMetrics = [];
    } else {
      const ccID = Math.floor(Math.random() * Date.now()).toString();

      props.currentConversionsQueryCOPY.query.calculatedMetrics.push({
        name: `Cost per`,
        query: props.currentConversionsQueryCOPY.views[0].viewName.includes("facebook") ?
          'SUM(#conversions_cost)/SUM(#custom_conversions_value)' : props.currentConversionsQueryCOPY.views[0].viewName.includes("adform") ?
            'SUM(#conversions_cost)/SUM(#custom_conversions)' : props.currentConversionsQueryCOPY.views[0].viewName.includes("dv360") ?
              'SUM(#conversions_cost)/SUM(#total_conversions)' : 'SUM(#conversions_cost)/SUM(#total_conversions)',
        decimals: 2,
        id: ccID,
      });
      props.currentConversionsQueryCOPY.query.orderBy.push(ccID);
    }
    setChecked5(!checked5);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lengthCounter, setLengthCounter] = useState<any>();
  const handleItemNameChange = () => {
    // FIRST WE CHECK IF RENAME HAS BEEN DONE ON SELECTED ITEM
    let preSelected = '';
    if (props.currentQueryCOPY.query.detailedDimensions[foundIndex]?.rename) {
      if (props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename?.some((el: any) => el.after === oldName)) {
        const selected = props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename?.filter(
          (el: any) => el.after === oldName,
        );
        preSelected = selected[0].before;
      }

      // WE CHECK IF RENAME PROP EXISTS AND IF YES DOES IT CONTAIN SELECTED ITEM(FIRST REMOVE AND THEN ADD NEW) ELSE JUST ADD
      if (
        props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename?.some(
          (el: any) => el.before === (preSelected.length > 0 ? preSelected : oldName),
        )
      ) {
        props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename =
          props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.filter(
            (el: any) => el.before !== (preSelected.length > 0 ? preSelected : oldName),
          );
        props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.push({
          before: preSelected.length > 0 ? preSelected : oldName,
          after: newName,
        });
        // CHANGE ON CONVERSION QUERY
        if (props.currentConversionsQueryCOPY !== undefined) {
          props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename =
            props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.filter(
              (el: any) => el.before !== (preSelected.length > 0 ? preSelected : oldName),
            );
          props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename.push({
            before: preSelected.length > 0 ? preSelected : oldName,
            after: newName,
          });
        }
      } else {
        props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.push({ before: oldName, after: newName });
        if (props.currentConversionsQueryCOPY !== undefined) {
          props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename.push({
            before: oldName,
            after: newName,
          });
        }
      }
    } else {
      props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename = [{ before: oldName, after: newName }];
      if (props.currentConversionsQueryCOPY !== undefined) {
        props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename = [
          { before: oldName, after: newName },
        ];
      }
    }
    setOldName('');
    setNewName('');
    setLengthCounter(props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.length);
  };
  const handleRemoveNameChange = (val: any) => {
    setOldName(val);
    if (props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.some((el: any) => el.before === val)) {

      const newArray = props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.filter(
        (el: any) => el.before !== val,
      );
      props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename = newArray;
      if (props.currentConversionsQueryCOPY !== undefined) {
        const newArrayCC = props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename.filter(
          (el: any) => el.before !== val,
        );
        props.currentConversionsQueryCOPY.query.detailedDimensions[foundIndexCC].rename = newArrayCC;
      }
    }
    setOldName('');
    setNewName('');
    setLengthCounter(props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.length);
  };

  const [ccNewName, setCCNewName] = useState<any>('');

  const handleCustomConversionNameChange = () => {
    const indexFound = props.currentConversionsQueryCOPY.query.detailedDimensions.findIndex(
      (dim: any) => dim.column === 'filter_name' || dim.column === 'conversion_name' || dim.column === 'CC_name',
    );
    //
    let preSelected = '';
    if (props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename) {
      if (
        props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename?.some(
          (el: any) => el.after === props.clickedHeader,
        )
      ) {
        const selected = props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename?.filter(
          (el: any) => el.after === props.clickedHeader,
        );
        preSelected = selected[0].before;
      }


      // WE CHECK IF RENAME PROP EXISTS AND IF YES DOES IT CONTAIN SELECTED ITEM(FIRST REMOVE AND THEN ADD NEW) ELSE JUST ADD
      if (
        props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename?.some(
          (el: any) => el.before === (preSelected.length > 0 ? preSelected : props.clickedHeader),
        )
      ) {

        props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename =
          props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename.filter(
            (el: any) => el.before !== (preSelected.length > 0 ? preSelected : props.clickedHeader),
          );
        props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename.push({
          before: preSelected.length > 0 ? preSelected : props.clickedHeader,
          after: ccNewName,
        });
      } else {
        props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename.push({
          before: props.clickedHeader,
          after: ccNewName,
        });
      }
    } else {

      props.currentConversionsQueryCOPY.query.detailedDimensions[indexFound].rename = [
        { before: props.clickedHeader, after: ccNewName },
      ];
    }
    setCCNewName('');
  };

  const CPconversionsCalcMetricDecimals = (e: any) => {
    props.currentConversionsQueryCOPY.query.calculatedMetrics[0].decimals = Number(e.target.value);
  };

  const CPcustomConversionNameChange = (event: any) => {
    props.currentConversionsQueryCOPY.query.calculatedMetrics[0].name = event.target.value;
  };


  return (
    <Modal>
      {props.hidden && (
        <HeadModul>
          <button type="button" className="x" onClick={() => closeModal()}>
            {' '}
            <IoMdClose className="closeIcon" />
          </button>
          <ModulContainer>
            {/*------------------------------------------------------------- METRIC VARIANT-------------------------------------------*/}
            {props.activeObject.hasOwnProperty('aggregation') && (
              <div className="grid">
                {/* DECIMAL NUMBER*/}
                <Container className="containerBox">
                  <label>Number of Decimals</label>
                  <select
                    defaultValue={props.activeObject.decimals}
                    onChange={(e) => metricDecimals(e)}
                  //  onChange={(e) => (props.activeObject.decimals = Number(e.target.value))}
                  >
                    <option disabled value="">
                      {' '}
                      -- select an option --{' '}
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </Container>

                {/* DISPLAY NAME */}
                <Container className="containerBox">
                  <label>Change display name:</label>
                  <input
                    type="text"
                    placeholder={props.activeObject.name}
                    onChange={(event) => metricNameChange(event)}
                  />
                </Container>

                {/* AGGREGATION */}
                <Container className="containerBox">
                  <label>Aggregation</label>
                  <select defaultValue={props.activeObject.aggregation} onChange={(e) => handleAggregation(e)}>
                    {(Object.keys(metricFunction) as Array<keyof typeof metricFunction>).map((aggregation) => (
                      <option key={aggregation} value={aggregation}>
                        {aggregation}
                      </option>
                    ))}
                  </select>
                </Container>

                {/* TOGGLE COST PER */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Container className="containerBox" >
                    <label>Cost per {props.activeObject.column} </label>
                    <Switch onChange={handleCostPer} checked={checked2} className="react-switch" />
                  </Container>

                  {checked2 === true && (
                    <Container className="containerBox" >
                      <label>Remove {props.activeObject.name} </label>
                      <Switch onChange={handleMetricremove} checked={!checked3} className="react-switch" />
                    </Container>
                  )}
                </div>
              </div>
            )}
            {/*--------------------------------------------------------------------- DIMENSIONS VARIANT-------------------------------------------*/}
            {props.activeObject.hasOwnProperty('hidden') && (
              <div className="grid">
                {/* DISPLAY NAME */}
                <Container className="containerBox">
                  <h1>Change display name:</h1>
                  <input
                    type="text"
                    placeholder={props.activeObject.name}
                    onChange={(event) => dimensionNameChange(event)}
                  />
                </Container>

                {/* HIDE DIMENSION */}
                <Container className="containerBox">
                  <h1>Hide Dimension</h1>
                  <Switch onChange={handleSwitch} checked={checked} className="react-switch" />
                </Container>

                {/* FILTER */}
                <Container className="containerBox">
                  <h1>Filters</h1>
                  {filterToggle ? (
                    <>
                      {props.currentQueryCOPY.query.filters.find((x: any) => x.column === props.clickedHeader) !==
                        undefined ? (
                        <p></p>
                      ) : (
                        <button className="fBTN" type="button" onClick={() => setisFilterToggle(!filterToggle)}>
                          ADD FILTER
                        </button>
                      )}

                      {props.currentQueryCOPY.query.filters.slice(1).map((filter: any, index: any) => (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={index}>
                          <label className="removelabel">
                            {filter.column} {displayChars(filter.filter.filtertype)}{' '}
                            {filter.filter.value ? filter.filter.value : filter.filter.list}
                          </label>

                          {/*    {(filter.column === props.clickedHeader || props.currentQueryCOPY.query.detailedDimensions?.some((e: any) => e.name === props.clickedHeader)) && ( */}
                          <div>
                            {/*  <VscEdit onClick={() => setisFilterToggle(!filterToggle)} style={{ cursor: "pointer" }} /> */}
                            <AiOutlineDelete
                              onClick={() => filterRemove(filter.column)}
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                          {/*    )} */}
                        </div>
                      ))}
                    </>
                  ) : (
                    <FilterDrop>
                      <div className="wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="filterTitle">{props.clickedHeader}</div>
                        <select
                          name=""
                          id=""
                          defaultValue={filterType}
                          onChange={(e) => setFilterType(filtertype[e.target.value as keyof typeof filtertype])}
                        >
                          <option value=""></option>
                          <option value="IN">{'IN'}</option>
                          <option value="NOTIN">{'OUT'}</option>
                          <option value="EQUAL">=</option>
                          <option value="GT">{'>'}</option>
                          <option value="GTE">{'>='}</option>
                          <option value="LT">{'<'}</option>
                          <option value="LTE">{'<='}</option>
                        </select>

                        {filterType === 'IN' || filterType === 'NOTIN' ? (
                          <Select
                            defaultValue={filterList}
                            onChange={handleList}
                            options={filterOptions}
                            isMulti
                            className="basic-multi-select"
                          />
                        ) : (
                          <Select
                            defaultValue={filterList}
                            onChange={handleFilterValue}
                            // onChange={(selectedOption) => setFilterValue(selectedOption.label)}
                            options={filterOptions}
                          />
                        )}
                      </div>
                      <div className="btnz">
                        <button className="fBTN" type="button" onClick={() => setisFilterToggle(!filterToggle)}>
                          CANCEL
                        </button>
                        {(filterList || filterValue) && filterType && (
                          <DarkButton className="fBTN" type="button" onClick={handleFilterSave}>
                            SAVE
                          </DarkButton>
                        )}
                      </div>
                    </FilterDrop>
                  )}
                </Container>

                <Container className="containerBox">
                  <h1>Change item name </h1>
                  from:
                  <select value={oldName} name="" id="" onChange={(e) => setOldName(e.target.value)}>
                    <option value=""> -- select an option -- </option>
                    {props.data.map((element: any, index: any) => (
                      <option key={index} value={element[props.clickedHeader]}>
                        {element[props.clickedHeader]}
                      </option>
                    ))}
                  </select>
                  to:
                  <input
                    type="text"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                  {newName.length > 0 && oldName.length > 0 && (
                    <DarkButton
                      className="fBTN"
                      style={{ width: 'fit-content', marginTop: '0.5vw' }}
                      type="button"
                      onClick={handleItemNameChange}
                    >
                      SAVE
                    </DarkButton>
                  )}
                  {props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename && (
                    <label>
                      Selected:
                      {props.currentQueryCOPY.query.detailedDimensions[foundIndex].rename.map((el: any, index: any) => (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={index}>
                          <label className="removelabel">
                            {el.before} <HiArrowNarrowRight /> {el.after}
                          </label>
                          <AiOutlineDelete
                            name={el.before}
                            onClick={(e) => {
                              setNewName(el.after);
                              setOldName(el.before);
                              handleRemoveNameChange(el.before);
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      ))}
                    </label>
                  )}
                </Container>
              </div>
            )}

            {/* ----------------------------------------------CALC METRICS VARIANT--------------------------------------------*/}
            {renderCostPer[0]?.hasOwnProperty('query') && (
              <div className="grid">
                {/* DECIMAL NUMBER*/}
                <Container className="containerBox">
                  <h1>Number of Decimals</h1>
                  <select
                    defaultValue={props.activeObject.decimals}
                    onChange={(e) => calcMetricDecimals(e)}
                  //  onChange={(e) => (props.activeObject.decimals = Number(e.target.value))}
                  >
                    <option disabled value="">
                      {' '}
                      -- select an option --{' '}
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </Container>

                {/* DISPLAY NAME */}
                <Container className="containerBox">
                  <h1>Change display name:</h1>
                  <input
                    type="text"
                    placeholder={props.activeObject.name}
                    onChange={(event) => calcMetricNameChange(event)}
                  />
                </Container>

                <Container className="containerBox" style={{ width: '50%' }}>
                  <h1>Remove {props.activeObject.name} </h1>
                  <Switch onChange={handleCostPerRemove} checked={!checked4} className="react-switch" />
                </Container>
              </div>
            )}

            {/*---------------------------------------------------------- CUSTOM CONVERSIONS VARIANT-------------------------------------------*/}
            {props.conversionsData?.some(
              (element: any) =>
                element.filter_name === props.clickedHeader || element.conversion_name === props.clickedHeader || element.CC_name === props.clickedHeader,
            ) && (
                <div className="grid">
                  {/* DISPLAY NAME */}
                  <Container className="containerBox">
                    <h1>Change display name:</h1>
                    <input
                      type="text"
                      value={ccNewName}
                      // placeholder={props.activeObject.name}
                      onChange={(e) => setCCNewName(e.target.value)}
                    />
                    <button
                      style={{ marginTop: '20px' }}
                      type="button"
                      onClick={() => handleCustomConversionNameChange()}
                    >
                      CONFIRM
                    </button>
                  </Container>

                  <Container className="containerBox" style={{ width: '50%' }}>
                    <label>Add Cost per {props.clickedHeader} </label>
                    <Switch onChange={handleAddCostPerCustomConversion} checked={checked5} className="react-switch" />
                  </Container>
                </div>
              )}

            {/* ---------------------------------------------------------CONVERSION COST PER VARIANT-----------------------------------------------------------*/}
            {renderCostPerCC &&
              renderCostPerCC[0]?.hasOwnProperty('query') &&
              !props.activeObject.hasOwnProperty('hidden') &&
              !props.conversionsData?.some(
                (element: any) =>
                  element.filter_name === props.clickedHeader || element.conversion_name === props.clickedHeader || element.CC_name === props.clickedHeader,
              ) &&
              !props.activeObject.hasOwnProperty('aggregation') &&
              !renderCostPer[0]?.hasOwnProperty('query') && (
                <div className="grid">
                  {/* DECIMAL NUMBER*/}
                  <Container className="containerBox">
                    <h1>Number of Decimals</h1>
                    <select
                      defaultValue={props.activeObject.decimals}
                      onChange={(e) => CPconversionsCalcMetricDecimals(e)}
                    //  onChange={(e) => (props.activeObject.decimals = Number(e.target.value))}
                    >
                      <option disabled value="">
                        {' '}
                        -- select an option --{' '}
                      </option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </Container>

                  {/* DISPLAY NAME */}
                  <Container className="containerBox">
                    <h1>Change display name prefix:</h1>
                    <input
                      type="text"
                      placeholder={props.activeObject.name}
                      onChange={(event) => CPcustomConversionNameChange(event)}
                    />
                  </Container>

                  <Container className="containerBox" style={{ width: '50%' }}>
                    <h1>Remove {props.activeObject.name} </h1>
                    <Switch onChange={handleAddCostPerCustomConversion} checked={checked5} className="react-switch" />
                  </Container>
                </div>
              )}
          </ModulContainer>

          <DarkButton style={{ alignSelf: 'flex-end' }} type="button" onClick={setapply}>
            OK
          </DarkButton>
        </HeadModul>
      )}
    </Modal>
  );
};

export default PreviewModal;
