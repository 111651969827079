import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  /*   top: 100px;
  left: 0; */
  width: 100%;
  min-height: calc(100vh - 100px);
  padding-bottom: 1vh;
  padding: 0;
  margin: 0;
  /* background-color: ${({ theme }) => theme.background}; */
`;

export const Kpis = styled.section`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
`;
export const KPIContainer = styled.div`
  width: 20%;
  height: 100px;
  padding-bottom: 7%;
  margin: 10px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;

  h4 {
    font-size: 2.5vw;
    font-family: 'FontBold';
    color: ${({ theme }) => theme.brand};
    /* background: rgb(238,48,36);
    background: linear-gradient(172deg, rgba(238,48,36,1) 12%, rgba(135,2,123,1) 100%);
background-clip: text;
-webkit-text-fill-color: transparent; */

    margin-bottom: 9px;
  }

  h3 {
    font-size: 1.2vw;
    font-family: 'FontMedium';
    color: ${({ theme }) => theme.primaryGray};
    font-weight: normal;
  }

  .loader {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export const TxtContainer = styled.div`
  padding: 16px;


   white-space: nowrap;
  span {
    font-size: 14px;
    font-weight: normal;
    font-family: 'FontRegular';
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .headerEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Tables = styled.section`
  height: auto;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;


  .addMoreButton {
    width: 99%;
    height: 'auto';
    padding-bottom: 0%0%;
    margin: 2px 10px 10px 10px;
    background-color: none;
    border-radius: 7px;
    padding: 14px 20px;
    position: relative;
    outline: 1px dashed ${({ theme }) => theme.primaryGray};
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    text-align: center;
  }
  .addMoreButton:hover {
    background-color: ${({ theme }) => theme.placeholderBackground};
    outline: none;
  }
`;

interface GraphsProps {
  dashboard: string;
  columnsNumber: number;
}

export const Graphs = styled.section<GraphsProps>`
  height: auto;
  width: 100%;
  display: ${(props) => (props.dashboard === 'online' ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr;
  /* flex-wrap: wrap; */
  /* grid-template-columns: repeat(auto-fill, minmax(40%, 1fr)); */
  /* gap: 10px; */
  grid-template-rows: auto;
  padding-right: 10px;

   .item {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 20px;
    margin: 10px;
    border: 1px solid black; */
    cursor: pointer;
  }
  .over {
    border: 3px dotted #666;
  }

  .addMoreButton {
    width: 99%;
    height: auto;
    min-height: 40vh;
    padding: 14px 20px;
    outline: 1px dashed ${({ theme }) => theme.primaryGray};
    margin: 2px 10px 10px 10px;
    border-radius: 7px;
    position: relative;
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    text-align: center;
    padding: 200px 0;
  }
  
  .addMoreButton:hover {
    background-color: ${({ theme }) => theme.placeholderBackground};
    outline: none;
  }

  .graphsContainer {
    width: ${(props) => (props.columnsNumber === 2 ? '40vw' : '20vw')};
  }
`;

export const AudienceReport = styled.section`
  height: auto;
  width: 100%;
  display: flex;
  // justify-content: space-around;
  justify-content: flex-start;
`;
