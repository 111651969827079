/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Add, HeadModul, ModulContainer, TxtContainer2 } from '../table/TableStyles';
import { AudienceReportContainer } from './GalleryStyles';
import { DarkButton } from 'components/style/Global';
import { API, Amplify } from 'aws-amplify';
import { IoMdClose } from 'react-icons/io';
import { getPreSignedUrl, getStoredQuery } from 'graphql/queries';
import {
    DeleteStoredQueryMutation,
    GetPreSignedUrlQuery,
    GetStoredQueryQuery,
    Placeholder,
    S3Method,
    StoreQueriesMutation,
} from 'API';
import { useCampaignContext } from 'context/CampaignContext';
import APIContext from 'context/APIContext';
import { PublishContext } from 'context/PublishContext';
import { v4 as uuidv4 } from 'uuid';
import { deleteStoredQuery, storeQueries } from 'graphql/mutations';
import ClientStatementsContext from 'context/ClientStatementsContext';
import UploadIcon from 'assets/uploadIcon';
import { FileUploader } from 'react-drag-drop-files';
const {
    REACT_APP_IDENTITY_POOL_ID,
    REACT_APP_AWS_REGION,
    REACT_APP_USER_POOL_ID,
    REACT_APP_USER_FILES_S3_BUCKET,
    REACT_APP_USER_WEB_CLIENT_ID,
} = process.env;

type Props = {
    componentID: string;
    visible?: boolean;
    setUploadComponents?: any;
    uploadComponents?: any;
    oldComponentID?: string | null;
};

Amplify.configure({
    Auth: {
        identityPoolId: REACT_APP_IDENTITY_POOL_ID,
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    },
    Storage: {
        AWSS3: {
            bucket: REACT_APP_USER_FILES_S3_BUCKET,
            region: REACT_APP_AWS_REGION,
        },
    },
});




const ARContainer = (props: Props) => {
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [caption, setCaption] = useState<any>();
    const { statements } = useContext(ClientStatementsContext);
    const { activeCampaign, dashType, activeSubCampaign, canDoInsourceAndOnInsourcePortal } = useCampaignContext();
    const { getEnvironment } = useContext(APIContext);
    const { updateOLArray, publishedData } = useContext(PublishContext);
    const [fileName, setFileName] = useState<any>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [contentType, setContentType] = useState<any>();
    const [isImage, setIsImage] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);
    const [uploadStat, setUploadStat] = useState<any>();

    useEffect(() => {
        props.visible && setVisible(props.visible);
    }, [props.visible]);




    useEffect(() => {
        if (publishedData.length > 0 && activeCampaign?.authorized === false && !canDoInsourceAndOnInsourcePortal) {
            publishedData.forEach((element: Placeholder) => {
                if (props.oldComponentID && props.oldComponentID !== null && element.position === props.oldComponentID && element.metrics) {

                    getPresigned(element.outputlocation, element.metrics[0]?.chartType);
                }
                else if (element.position === props.componentID && element.metrics) {
                    getPresigned(element.outputlocation, element.metrics[0]?.chartType);
                }
            });
        }
    }, [publishedData]);

    useEffect(() => {
        if (publishedData.length > 0 && activeCampaign?.authorized === false && !canDoInsourceAndOnInsourcePortal) {
            const found = props.oldComponentID && props.oldComponentID !== null ? publishedData.find((element: any) => element.position === props.oldComponentID) : publishedData.find((element: any) => element.position === props.componentID);
            found !== undefined ? setVisible(true) : setVisible(false);
        }
    }, [publishedData]);

    useEffect(() => {
        if ((activeCampaign?.authorized === true || (!activeCampaign?.authorized && canDoInsourceAndOnInsourcePortal)) && statements?.Queries?.READ) {
            /* setTimeout(() => {
                fetchStoredQueriesID();
            }, 4000); */
            if (props.visible) {

                fetchStoredQueriesID();
            }
        }
    }, [activeCampaign, statements]);

    async function fetchStoredQueriesID() {

        try {
            const response = (await API.graphql({
                query: getStoredQuery,
                variables: {
                    campaign_id: activeCampaign?.campaignId?.toString(),
                    position: activeSubCampaign?.name
                        ? props.oldComponentID && props.oldComponentID !== null ? `${props.oldComponentID}_${activeSubCampaign.subcampaignId}` : `${props.componentID}_${activeSubCampaign.subcampaignId}`
                        : props.oldComponentID && props.oldComponentID !== null ? props.oldComponentID : props.componentID,
                    // position: props.oldComponentID && props.oldComponentID !== null ? props.oldComponentID : props.componentID,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetStoredQueryQuery };
            const responseData = response.data.getStoredQuery;

            if (responseData) {
                setIsSaved(true);
                const capt = responseData?.title;
                setCaption(capt);
                const name = responseData?.query_id;
                setFileName(name);

                const cType = responseData?.metrics ? responseData?.metrics[0]?.chartType : '';
                setContentType(cType);
                getPresigned(name, cType);
                // setVisible(true);

                const placeholdersObject = {
                    position: activeSubCampaign?.name
                        ? `${props.componentID}_${activeSubCampaign.subcampaignId}`
                        : props.componentID,
                    outputlocation: name,
                    outputlocationTotal: 'noTotal',
                    metrics: {
                        chartType: cType,
                        metric: 'image',
                        axis: 1,
                    },
                    title: capt,
                };

                updateOLArray(placeholdersObject);
            }
        } catch (e) {
            console.log(`ERROR WAS THIS: ${e}`, e);
        }
    }

    const putPresigned = async () => {
        try {
            const fileN = `${uuidv4()}_${selectedImage.name}`;
            setFileName(fileN);
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.put_object,
                    Client: {
                        code: activeCampaign?.ClientCode,
                        country: activeCampaign?.ClientCountry,
                    },
                    Environment: getEnvironment,
                    FileName: fileN,
                    ContentType: selectedImage.type,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            const url = preSignedURL.data.getPreSignedUrl?.url;
            addToS3(url);
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }
        setShowUploadModal(false);
    };

    async function addToS3(url: any) {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': `${selectedImage.type}`,
                },
                body: selectedImage,
            });
        } catch (e) {
            console.log('Error adding to S3,', e);
        }
    }

    const getPresigned = async (name: any, cType: any) => {
        try {
            const preSignedURL = (await API.graphql({
                query: getPreSignedUrl,
                variables: {
                    ClientMethod: S3Method.get_object,
                    Client: {
                        code: activeCampaign?.ClientCode,
                        country: activeCampaign?.ClientCountry,
                    },
                    Environment: getEnvironment,
                    FileName: name,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetPreSignedUrlQuery };
            const url = preSignedURL.data.getPreSignedUrl?.url;
            getFromS3(url, name, cType);
        } catch (e) {
            console.log('Error getting presigned URL', e);
        }
        setShowUploadModal(false);
    };

    async function getFromS3(url: any, name: string, cType: string) {
        try {
            const response = fetch(url, {
                method: 'GET',
            });
            setIsImage(true);
            const test = createFile((await response).url, name, cType);
            test.then((data) => {

                setSelectedImage(data);
            });
        } catch (err) {
            console.log({ err });
        }
    }

    async function createFile(url: any, name: string, cType: string) {
        if (typeof window === 'undefined') return; // make sure we are in the browser
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = {
            type: cType || 'image/png',
        };
        return new File([data], name, metadata);
    }

    async function removeFromS3() {
        setSelectedImage(null);
        setIsImage(false);

        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const deleteStoredQ = (await API.graphql({
                query: deleteStoredQuery,
                variables: {
                    campaign_id: activeSubCampaign?.name
                        ? activeSubCampaign.subcampaignId?.toString()
                        : activeCampaign?.campaignId?.toString(),
                    Environment: getEnvironment,
                    placeholder: activeSubCampaign?.name
                        ? `${props.componentID}_${activeSubCampaign.subcampaignId}`
                        : props.componentID,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: DeleteStoredQueryMutation };
            setIsSaved(false);
            /*             unpublishCampaign()
                              window.location.reload(); */
        } catch (e) {
            console.log('ERROR  DELETING QUERY', e);
        }
    }

    const savePlaceholder = () => {
        saveQuery();
    };

    async function saveQuery() {
        const placeholdersObject = {
            position: props.componentID,
            outputlocation: fileName,
            outputlocationTotal: 'noTotal',
            metrics: [{
                chartType: selectedImage.type,
                metric: 'image',
                axis: 1,
            }],
            title: caption,
        };

        updateOLArray(placeholdersObject);

        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const saveResponse = (await API.graphql({
                query: storeQueries,
                variables: {
                    campaign_id: activeCampaign?.campaignId?.toString(),
                    position: activeSubCampaign?.name
                        ? `${props.componentID}_${activeSubCampaign.subcampaignId}`
                        : props.componentID,
                    query_id: fileName,
                    title: caption,
                    metrics: {
                        chartType: selectedImage.type,
                        metric: 'image',
                        axis: 1,
                    },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: StoreQueriesMutation };

            setIsSaved(true);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const close = (e: any) => {
            if (e.key === 'Escape') {
                setShowModal(false);
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);


    const handleUploadImageList = (isImage: boolean) => {
        if (props.setUploadComponents) {
            if (isImage) {
                const newUploadComponents = props.uploadComponents.map((elementArray: any[], index: number) => {
                    return elementArray.map((element) => {
                        if (!props.componentID.endsWith(".5")) {
                            const findIndex = elementArray.findIndex((el) => el.id === props.componentID);
                            if (findIndex !== -1 && elementArray[findIndex + 1]) {
                                elementArray[findIndex + 1].visible = true;
                            }
                        } else {
                            const nextElementArray = props.uploadComponents[index + 1];
                            if (nextElementArray && nextElementArray[0]) {
                                nextElementArray[0].visible = true;
                            }
                        }
                        return element;
                    });
                });
                props.setUploadComponents(newUploadComponents);
            } else {
                const newUploadComponents = props.uploadComponents.map((elementArray: any[], index: number) => {
                    return elementArray.map((element) => {
                        if (!props.componentID.endsWith(".5")) {
                            const findIndex = elementArray.findIndex((el) => el.id === props.componentID);
                            if (findIndex !== -1 && elementArray[findIndex + 1]) {
                                elementArray[findIndex + 1].visible = false;
                            }
                        } else {
                            const nextElementArray = props.uploadComponents[index + 1];
                            if (nextElementArray && nextElementArray[0]) {
                                nextElementArray[0].visible = false;
                            }
                        }
                        return element;
                    });
                });
                props.setUploadComponents(newUploadComponents);

            }
        }
    }
    return (
        <AudienceReportContainer
            style={{ display: visible ? 'block' : 'none' }}
            showModal={showModal}
            showUploadModal={showUploadModal}
        >
            {/* MAIN VIEW*/}
            <div className="wrapper">
                {!isImage && (
                    <TxtContainer2 isGraph={true} displayTxt={0}>
                        <Add isGraph={true}>
                            <div className="text">
                                <h1>File Upload</h1>
                                {/* <h2>Press the button to begin</h2> */}
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    setUploadStat('home');
                                    setShowUploadModal(true);
                                }}
                            >
                                Add new
                            </button>
                        </Add>
                    </TxtContainer2>
                )}

                {isImage && (
                    <>
                        {selectedImage && (
                            selectedImage.type.includes('video') ? <video
                                className="imageThumbnail"
                                controls
                                width="250px"
                                src={URL.createObjectURL(selectedImage)}
                                onClick={() => setShowModal(true)}
                            /> : selectedImage.type.includes('image') ?
                                <img
                                    className="imageThumbnail"
                                    alt="not found"
                                    width={'250px'}
                                    src={URL.createObjectURL(selectedImage)}
                                    onClick={() => setShowModal(true)}
                                /> :
                                <div className="imageThumbnail">
                                    <iframe style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }}
                                        src={URL.createObjectURL(selectedImage)} title={URL.createObjectURL(selectedImage)} width={"250px"}>
                                    </iframe>
                                    <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0 }} onClick={() => setShowModal(true)}></div>
                                </div>
                        )}
                        {statements?.Queries?.CREATE && (
                            <div className="buttonsContainer">
                                <button
                                    className="btn editBtn"
                                    style={{
                                        width: 'fit-content',
                                        fontSize: '.7vw',
                                        padding: '5px 20px',
                                        height: 'fit-content',
                                        alignSelf: 'center',
                                    }}
                                    onClick={() => setShowUploadModal(true)}
                                >
                                    Edit
                                </button>
                                {!isSaved && (
                                    <DarkButton
                                        className="btn"
                                        style={{
                                            width: 'fit-content',
                                            fontSize: '.7vw',
                                            padding: '5px 20px',
                                            height: 'fit-content',
                                            alignSelf: 'center',
                                        }}
                                        onClick={() => savePlaceholder()}
                                    >
                                        Save
                                    </DarkButton>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>

            {/* MODAL FOR UPLOADING IMAGE*/}
            <div onClick={() => setShowUploadModal(false)} className="modalUpload myModal">
                {!selectedImage ? (
                    <HeadModul onClick={(e) => e.stopPropagation()} className="modalOverride">
                        <button
                            type="button"
                            className="x"
                            onClick={() => {
                                if (uploadStat === 'home') {
                                    setSelectedImage(null);
                                    setIsImage(false);
                                    handleUploadImageList(false);
                                }
                                setUploadStat(null);
                                setShowUploadModal(false);
                            }}
                        >
                            <IoMdClose className="closeIcon" />
                        </button>
                        <ModulContainer onClick={(e) => e.stopPropagation()} className="modal-content-upload">
                            <FileUploader
                                className="drop_area"
                                handleChange={(file: any) => {
                                    setSelectedImage(file);
                                    setIsImage(true);
                                    handleUploadImageList(true);
                                }}
                                name="file"
                                types={fileTypes}
                                children={dragAndDropContainer}
                            />
                        </ModulContainer>
                    </HeadModul>
                ) : (
                    <HeadModul onClick={(e) => e.stopPropagation()} className="modalOverride">
                        <ModulContainer onClick={(e) => e.stopPropagation()} className="modalPreview">
                            <div className="imageUploader">
                                {selectedImage && (
                                    selectedImage.type.includes('video') ? <video
                                        controls
                                        width="250"
                                        src={URL.createObjectURL(selectedImage)}
                                        onClick={() => setShowModal(true)}
                                    /> : selectedImage.type.includes('image') ?
                                        <img
                                            alt="no_image_found"
                                            src={URL.createObjectURL(selectedImage)}
                                            onClick={() => setShowModal(true)}
                                        />
                                        : <iframe src={URL.createObjectURL(selectedImage)} title={URL.createObjectURL(selectedImage)} width={"400px"}></iframe>
                                )}
                            </div>
                            <textarea
                                className="inputField"
                                onInput={(e) => setCaption((e.target as HTMLInputElement).value)}
                                placeholder={caption ? caption : 'Add caption...'}
                            ></textarea>
                        </ModulContainer>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <button type="button" onClick={() => removeFromS3()}>
                                Remove
                            </button>
                            <DarkButton
                                type="button"
                                onClick={() => {
                                    putPresigned();
                                    setIsSaved(false);
                                }}
                            >
                                Confirm
                            </DarkButton>
                        </div>
                    </HeadModul>
                )}
            </div>

            {/* MODAL FOR PREVIEWING IMAGE*/}
            <div onClick={() => setShowModal(false)} className="modal myModal">
                <span className="close" onClick={() => setShowModal(false)}>
                    x
                </span>
                <div onClick={(e) => e.stopPropagation()} className="headModal">
                    {selectedImage && (
                        selectedImage.type.includes('video') ? <video
                            className="modal-content"
                            controls
                            width="250"
                            src={URL.createObjectURL(selectedImage)}
                            onClick={() => setShowModal(true)}
                        /> : selectedImage.type.includes('image') ?
                            <img src={URL.createObjectURL(selectedImage)} className="modal-content" id="img01" alt="" /> :
                            <iframe src={URL.createObjectURL(selectedImage)} title={URL.createObjectURL(selectedImage)} className="modal-content" width="1000px" height="800px"></iframe>
                    )}
                    {/*   {link1 && <img src={link1} className="modal-content" id="img01" alt='' />} */}
                    <div className="caption">{caption}</div>
                </div>
            </div>
        </AudienceReportContainer >
    );
};

export const fileTypes = ['JPG', 'PNG', 'SVG', 'PDF', 'DOCX', 'TXT', 'ZIP', "MP4", "MOV", "AVI", "WMV", "FLV", "MPG", "MPEG", "M4V", "WEBM", "MKV", "OGG", "XLSX", "DOCX", "PPTX"];

export const dragAndDropContainer = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
            < UploadIcon />
        </div>
        <h1 style={{ textAlign: 'center' }}>
            Drop your audio, video, MS office file <br />
            or
        </h1>
        <br />
        <button type="button">Choose file</button>
    </div>
);

export const smallDragAndDropContainer = (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '13px', padding: "1vw 1vw 1vw 1vw" }}>
        <h1 style={{ marginBottom: "1vw" }}>Drop files here or click to upload</h1>
        <button type="button">Choose file</button>
    </div>
);

export default ARContainer;
