import { BottomRow, Breadcrumbs, NavigationButton, Platform, PlatformMidRow, TopRow } from "components/Main/table/TableStyles";
import { placeholderFullSources } from "./PlaceholderContainer";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useCampaignContext } from "context/CampaignContext";
import { ChangeEvent } from "react";

interface Props {
    isGraph: Boolean
    placeHolderDataSource: placeholderFullSources[],
    selectSource: (e: ChangeEvent<HTMLInputElement>) => void;
    setPage(page: any): any;
    nextPage(): any;
    previousPage(): any;
    handlePlatformsContinue(): any;
}


const PlatformContainer = (props: Props) => {
    const { placeHolderDataSource, isGraph, selectSource, setPage, nextPage, previousPage, handlePlatformsContinue } = props
    const selectedPlatform = placeHolderDataSource.find(e => e.isSelected)
    const { dashType } = useCampaignContext()

    console.log("placeHolderDataSource", placeHolderDataSource)
    return (
        <Platform
            isGraph={isGraph}
            displayPlatform={1}
        >

            <TopRow isGraph={props.isGraph}>
                <Breadcrumbs>
                    <label >{selectedPlatform?.name}</label>
                </Breadcrumbs>
            </TopRow>

            <PlatformMidRow isGraph={isGraph}>
                <h1>Platform</h1>
                <br />
                <h2>Choose a platform for your query </h2>

                <div className="platformsArray">
                    {placeHolderDataSource.filter(el => (dashType === "DIGITAL" || dashType === "INSOURCE_DATA") ? el.name !== "TV" && el.name !== "RADIO" && el.name !== "CINEMA" && el.name !== "OOH" && el.name !== "PRINT" && el.name !== "Custom" : el.name === dashType)
                        .map((platform: any) => (
                            <label key={platform.name}>
                                <input
                                    // ref={platformButton}
                                    type="checkbox"
                                    name="platforms"
                                    value={platform.name}
                                    onChange={(event) => selectSource(event)}
                                    // onChange={(event) => handlePlatformChange(event.target.value)}
                                    id={platform.name}
                                />
                                <div className="checked">
                                    <span style={{ fontSize: '1.5rem', marginBottom: '-5px' }}>{platform.icon}</span>
                                    <span>{platform.name}</span>
                                </div>
                            </label>
                        ))}
                </div>
            </PlatformMidRow>

            <BottomRow isGraph={props.isGraph}>
                <NavigationButton type='button' style={{ backgroundColor: "transparent" }} onClick={previousPage}>
                    <GoChevronLeft /> Back
                </NavigationButton>
                {selectedPlatform ? <NavigationButton style={{ backgroundColor: "transparent" }} type='button' onClick={() => handlePlatformsContinue()}>
                    Continue <GoChevronRight />
                </NavigationButton > : <NavigationButton type='button' style={{ cursor: "not-allowed" }}>
                    Continue <GoChevronRight />
                </NavigationButton>}
            </BottomRow>


        </Platform >
    )



}

export default PlatformContainer;