import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { oneInput } from './PlanComponents/PlanModalInput';
import { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';

interface Props {
  table?: MRT_TableInstance<any>;
  EditButton: boolean;
  data: any[];
  newColumns: MRT_ColumnDef<any>[];
  CommunicationTaskKey: string;
  row: any;
  createNewCategory: (selectedOption: any,  row:any,) => void;
  handleSelectChangeAndUpdateGlobal:(selectedOption: any, accessorKey: string,setValues: any,values: any, row:any ) => void;

}

export const CommunicationTaskSelector = (props: Props) => {
  const { table, EditButton, data, newColumns, CommunicationTaskKey, row, createNewCategory, handleSelectChangeAndUpdateGlobal } = props;

  let grouping = [""];

  if (table) {
    const state = table.getState();
    grouping = state.grouping;
  }

  // console.log("This is something I for sure Need", row !== undefined ? row[CommunicationTaskKey] : "")

  const initialValue = table === undefined ?   row[CommunicationTaskKey] :  row.getValue(grouping[0]) as string;
  const [localValue, setLocalValue] = useState(initialValue);
  const firstRowId = data[0].rowId;

  const CommunicationTaskKey2 = newColumns.find((item) => item.accessorKey === CommunicationTaskKey);

  const column = CommunicationTaskKey2 as any;

  const [selectOption, setSelectOption] = useState([] as any);
  const [values, setValues] = useState({label: initialValue, value: initialValue} as oneInput);


  useEffect(() => {
    const newRow2 = data.reduce((acc: any[], item: any) => {
      const value = item[CommunicationTaskKey];
      const existingItem = acc.find((x) => x.label === value);

      if (!existingItem) {
        acc.push({ label: value, value: CommunicationTaskKey });
      }
      return acc;
    }, []);

    setSelectOption(newRow2);
  }, [data]);

  const widthColumn = column?.name === 'Comments'  ? 300 : 160



  const lines = localValue !== undefined ? ((localValue.match(/\n/g) || []).length + 1) : 0;
  const minHeight = 50;
  const lineHeight = 20;
  const selectedMinHeight =  minHeight + "px"

  const calculatedHeight = `${Math.max(lines * lineHeight, parseInt(selectedMinHeight))}px`;


  return EditButton ? (
    <div>
      <div
        style={{
          border: 'none',
          fontFamily: 'FontRegular',
          resize: 'none',
          backgroundColor: 'transparent',
          color: localValue !== initialValue ? 'black' : 'lightgrey',
          marginLeft: '5px',
          width: widthColumn,
          height: calculatedHeight,
          zIndex: 99999
        }}
      >
        <CreatableSelect
          isClearable
          onChange={(selectedOption) => handleSelectChangeAndUpdateGlobal(selectedOption, column.accessorKey, setValues, values, row)}
          onCreateOption={(selectedOption) => createNewCategory(selectedOption, row,)}
          options={selectOption}
          value={
            values[column.accessorKey] ? { label: values[column.accessorKey], value: values[column.accessorKey] } : {label: initialValue, value: initialValue}
          }
          menuPortalTarget={document.body}
          styles={{
            control: (provided) => ({
              ...provided,
              position: 'relative',
              backgroundColor: "#f8f8fa",
              border: "1px solid #eeeeee",
              zIndex: 9999,
              minHeight: 20,
              margin: 0,
              padding: 0,
            }),
            menu: (provided) => ({
              ...provided,
              position: 'absolute',
              zIndex: 9999
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
      <div>
        {localValue !== initialValue ? (
          <></>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div></div>
  );
};
