import FileIcon from 'assets/newAssets/fileIcon';
import { v4 as uuidv4 } from 'uuid';
import { QueryInfo } from '../Brief';

const FileList = ({
  files,
  onFileOpen,
  onFileRemove,
  colorsForIcon,
}: {
  files: QueryInfo[];
  onFileOpen: (file: QueryInfo) => void;
  onFileRemove: (queryInfo: QueryInfo) => void;
  colorsForIcon: {
    [key: string]: string;
  };
}) => (
  <ul>
    {files.map((file) => {
      const extension = file.name.split('.').pop()?.toUpperCase();
      const color = colorsForIcon[extension || ''] || 'lightgrey';
      const fileWithId = { ...file, id: uuidv4() };

      return (
        <li key={fileWithId.id}>
          <div className="fileAndTitleContainer">
            <FileIcon color={color} type={extension}></FileIcon>
            <a style={{ fontSize: '.8vw' }} href="#" onClick={() => onFileOpen(file)}>
              {file.name}
            </a>
          </div>

          <div className="buttonRemove" onClick={() => onFileRemove(file)}>
            X
          </div>
        </li>
      );
    })}
  </ul>
);

export default FileList;
