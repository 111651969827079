import styled from 'styled-components';

interface ContainerProps {
  expand: any;
}

export const StyledGraphContainer = styled.form<ContainerProps>`
  width: 98%;
  height: auto;
  min-height: 40vh;
  padding: 14px 20px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  margin: 2px 10px 10px 10px;
  border-radius: 7px;
  position: relative;


  input, select {
padding: 8px;
  }
`;


interface PreviewProps {
  displayPreview: any;
}
export const Preview = styled.div<PreviewProps>`
  display: ${(props) => (props.displayPreview === 1 ? 'flex' : 'none')};
  flex-direction: column;
  span {
    font-family: 'FontRegular', sans-serif;
    font-weight: 800;
    color: ${({ theme }) => theme.primaryGray};
  }
`;

export const PreviewMidRow = styled.div``;

export const HightChartsReact = styled.div`
  width: 500px;
`;

export const Line = styled.span`
  font-weight: 300 !important;
`;
