import type React from 'react'

function FileIcon(props: any): JSX.Element {
  const { color, type } = props
  return (

    // <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" {...props}>
    //   <path d="M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/>
    // </svg>

    // <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" {...props}>
    //   <path id="note_FILL0_wght400_GRAD0_opsz24" d="M240-80q-33,0-56.5-23.5T160-160V-880H560L800-640v480q0,33-23.5,56.5T720-80Z" transform="translate(572 1000)" fill="#cecdcd"/>
    //   <path id="note_FILL0_wght400_GRAD0_opsz24-2" data-name="note_FILL0_wght400_GRAD0_opsz24" d="M240-80q-33,0-56.5-23.5T160-160V-800q0-33,23.5-56.5T240-880H560L800-640v480q0,33-23.5,56.5T720-80ZM520-600V-800H240v640H720V-600ZM240-800v0Z" transform="translate(480 908)"/>
    //   <text id="PDF" transform="translate(744 703)" font-size="107" font-family="Montserrat-Bold, Montserrat" font-weight="700"><tspan x="0" y="0">PDF</tspan></text>
    // </svg>

    <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 722 919.208">
      <g transform="translate(1598 923)">
        <g transform="translate(-2238 -951)">
          <path d="M240-51.792q-33,0-56.5-24.329T160-134.613V-880H560L800-631.538v496.925q0,34.164-23.5,58.492T720-51.792Z" transform="translate(562 999)" fill={color} />
          <path d="M240-22.657q-33,0-56.5-25.184T160-108.391V-794.266q0-35.365,23.5-60.55T240-880H560L800-622.8v514.406q0,35.365-23.5,60.55T720-22.657ZM550.958-641.709V-824.043H212.021v748H749.229V-641.709ZM240-579.93,200.891-108.391Z" transform="translate(480 908)" />
          <text transform="translate(726 739)" fontSize="158" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700">
            <tspan x="0" y="0">{type}</tspan>
          </text>
        </g>
      </g>
    </svg>

  )
}

export default FileIcon


