import { useEffect, useState } from 'react';
import { Column, ExistingColumn } from '../Modals/PlanModal';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useCampaignContext } from 'context/CampaignContext';
import Info3 from '../../Brief/3.Info';
import { headerInfoMap } from '../NewPlanItem';

interface Props {
  column: Column | ExistingColumn;
  handleGlobalChange: (localObject: any) => void;
  newSetOfColumns: Column[];
  newRow: any[];
  data: any[];
  columnTypes: {
    [x: string]: string;
  }[];
  columnInputTypes: {
    [x: string]: string;
  }[];
  errorMessage: string | null;
  setErrorMessage: (arg: string | null) => void;
  CommunicationTaskKey: string;
}

export interface oneInput {
  [key: string]: string;
}

const PlanModalInput = (props: Props) => {
  const {
    column,
    handleGlobalChange,
    newSetOfColumns,
    newRow,
    data,
    columnTypes,
    columnInputTypes,
    errorMessage,
    setErrorMessage,
    CommunicationTaskKey,
  } = props;
  const { activeCampaign } = useCampaignContext();
  const [values, setValues] = useState({} as oneInput);
  const [options, setOptions] = useState([] as any);

  const initialValue = 'initialValue' in column && column.initialValue ? column.initialValue : '';

  const handleSelectChangeAndUpdateGlobal = (selectedOption: any, accessorKey: string) => {
    const value = selectedOption ? (selectedOption.label ? selectedOption.label : selectedOption) : '';

    setValues({
      ...values,
      [accessorKey]: value,
    });

    handleGlobalChange({
      ...values,
      [accessorKey]: value,
    } as oneInput);

    setErrorMessage(null);
  };

  const handleInputChangeAndUpdateGlobal = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

    handleGlobalChange({
      ...values,
      [event.target.name]: event.target.value,
    } as oneInput);
  };

  const typeColumn = columnTypes.filter((item) => item[column.accessorKey])[0];
  const columnTypeValue = Object.values(typeColumn)[0];

  const type = Object.values(columnInputTypes.filter((item) => item[column.accessorKey as string])[0])[0];
  if (type === 'DATE' && column.header === 'Start Date' && Object.keys(values).length === 0) {

    handleSelectChangeAndUpdateGlobal(activeCampaign?.startDate as string, column.accessorKey);
  }
  if (type === 'DATE' && column.header === 'End Date' && Object.keys(values).length === 0) {
    handleSelectChangeAndUpdateGlobal(activeCampaign?.endDate as string, column.accessorKey);
  }

  useEffect(() => {
    const newRow2 = data.reduce((acc: any[], item: any) => {
      const value = item[CommunicationTaskKey];
      const existingItem = acc.find((x) => x.label === value);

      if (!existingItem) {
        acc.push({ label: value, value: CommunicationTaskKey });
      }
      return acc;
    }, []);



    setOptions(newRow2);
  }, [data]);





  return (
    <div key={column.accessorKey} style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <label>{column.header}</label>
        {headerInfoMap[column.header as keyof typeof headerInfoMap] && <Info3 info={headerInfoMap[column.header as keyof typeof headerInfoMap]} />}
      </div>
      {column.accessorKey === CommunicationTaskKey ? (
        <>
          <CreatableSelect
            isClearable
            onChange={(selectedOption) => handleSelectChangeAndUpdateGlobal(selectedOption, column.accessorKey)}
            onCreateOption={(selectedOption) => handleSelectChangeAndUpdateGlobal(selectedOption, column.accessorKey)}
            options={options}
            value={
              values[column.accessorKey]
                ? { label: values[column.accessorKey], value: values[column.accessorKey] }
                : { label: initialValue, value: initialValue }
            }
          />
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </>
      ) : type === 'TEXTAREA' ? (
        <textarea
          name={column.accessorKey}
          style={{
            minHeight: '60px',
            fontFamily: 'FontRegular',
            resize: 'none',
            color: '#807F83',
            backgroundColor: '#F7F8FA',
            border: '1px solid #E8EAEF',
            borderRadius: '7px',
          }}
          value={Object.keys(values).length === 0 ? initialValue : values[column.accessorKey]}
          onChange={handleInputChangeAndUpdateGlobal}
        />
      ) : type === 'DATE' && column.header === 'Start Date' ? (
        <DatePicker
          className="datepicker"
          dateFormat="dd-MM-yyyy"
          selected={
            Object.keys(values).length === 0
              ? new Date(activeCampaign?.startDate as string)
              : values[column.accessorKey]
          }
          onChange={(selectedOption: any) =>
            handleSelectChangeAndUpdateGlobal(selectedOption.toISOString().slice(0, 10), column.accessorKey)
          }
          calendarStartDay={1}
          minDate={new Date(activeCampaign?.startDate as string)}
          maxDate={new Date(activeCampaign?.endDate as string)}
        />
      ) : type === 'DATE' && column.header === 'End Date' ? (
        <DatePicker
          className="datepicker"
          dateFormat="dd-MM-yyyy"
          selected={
            Object.keys(values).length === 0 ? new Date(activeCampaign?.endDate as string) : values[column.accessorKey]
          }
          onChange={(selectedOption: any) =>
            handleSelectChangeAndUpdateGlobal(selectedOption.toISOString().slice(0, 10), column.accessorKey)
          }
          calendarStartDay={1}
          minDate={new Date(activeCampaign?.startDate as string)}
          maxDate={new Date(activeCampaign?.endDate as string)}
        />
      ) : type === 'NUMBER' ? (
        <input
          name={column.accessorKey}
          type={'number'}
          value={Object.keys(values).length === 0 ? initialValue : values[column.accessorKey]}
          onChange={handleInputChangeAndUpdateGlobal}
        />
      ) : (
        <input
          name={column.accessorKey}
          type={columnTypeValue === 'IntValue' ? 'number' : 'text'}
          value={Object.keys(values).length === 0 ? '' : values[column.accessorKey]}
          onChange={handleInputChangeAndUpdateGlobal}
        />
      )}
    </div>
  );
};

export default PlanModalInput;
