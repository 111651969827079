import { ACdetails, useCampaignContext } from "context/CampaignContext";
import { IActiveOrganization } from "context/ClientContext";
import { useEffect, useState } from "react";
import { useCampaignId, useClientCode } from "./useParameters";
import { UserQuery } from "API";

interface ACProps {
    storedQueries: UserQuery[];
    source: string;

}

export interface IImageList {
    id: string;
    visible: boolean;
}

export const useImageList = (props: ACProps): readonly [IImageList[][] | undefined] => {
    const { activeSubCampaign } = useCampaignContext()
    const [list, setList] = useState<IImageList[][] | undefined>(undefined)
    const { storedQueries, source } = props

    const cutTVandRADIO = (id: string) => {
        const firstPart = id.split("_")[0]
        const secondPart = id.split("_")[1]
        if (secondPart === activeSubCampaign?.subcampaignId?.toString()) {
            return firstPart
        } else {
            return ""
        }
    }

    useEffect(() => {
        if (props.storedQueries) {
            setList(undefined)

            setTimeout(() => {
                if (source === "TV" || source === "Radio") {
                    setList([
                        [{
                            id: `${source}4.1`,
                            visible: true
                        }, {
                            id: `${source}4.2`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.2`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.1`) ? true : false
                        }, {
                            id: `${source}4.3`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.3`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.2`) ? true : false
                        }, {
                            id: `${source}4.4`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.4`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.3`) ? true : false
                        }, {
                            id: `${source}4.5`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.5`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.4`) ? true : false
                        }],
                        [{
                            id: `${source}5.1`,
                            visible: (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.1`) ||
                                storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.6`)
                            ) ?
                                true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.5`) ? true : false
                        }, {
                            id: `${source}5.2`,
                            visible: (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.2`)
                                || storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.7`)
                            ) ? true : (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.1`) ||
                                storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.6`)) ? true : false
                        }, {
                            id: `${source}5.3`,
                            visible: (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.3`) ||
                                storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.8`)) ? true : (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.2`) ||
                                    storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.7`)) ? true : false
                        }, {
                            id: `${source}5.4`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.4`) ? true : (storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.3`) ||
                                storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}4.8`)) ? true : false
                        }, {
                            id: `${source}5.5`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.5`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.4`) ? true : false
                        }],
                        [{
                            id: `${source}6.1`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.1`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}5.5`) ? true : false
                        }, {
                            id: `${source}6.2`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.2`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.1`) ? true : false
                        }, {
                            id: `${source}6.3`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.3`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.2`) ? true : false
                        }, {
                            id: `${source}6.4`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.4`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.3`) ? true : false
                        }, {
                            id: `${source}6.5`,
                            visible: storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.5`) ? true : storedQueries.some((el) => cutTVandRADIO(el?.position as string) === `${source}6.4`) ? true : false
                        }]
                    ]);




                    // const determineVisibility = (id: string, dependencies: string[], storedQueries: any[]): boolean => {
                    //     return dependencies.some(dep => storedQueries.some(el => cutTVandRADIO(el?.position as string) === dep));
                    // };

                    // const createList = (source: string, storedQueries: any[]) => {
                    //     return [
                    //         [
                    //             { id: `${source}4.1`, visible: true },
                    //             { id: `${source}4.2`, visible: determineVisibility(`${source}4.2`, [`${source}4.1`], storedQueries) },
                    //             { id: `${source}4.3`, visible: determineVisibility(`${source}4.3`, [`${source}4.2`, `${source}4.1`], storedQueries) },
                    //             { id: `${source}4.4`, visible: determineVisibility(`${source}4.4`, [`${source}4.3`, `${source}4.2`], storedQueries) },
                    //             { id: `${source}4.5`, visible: determineVisibility(`${source}4.5`, [`${source}4.4`, `${source}4.3`], storedQueries) },
                    //         ],
                    //         [
                    //             { id: `${source}5.1`, visible: determineVisibility(`${source}5.1`, [`${source}4.6`, `${source}4.5`], storedQueries) },
                    //             { id: `${source}5.2`, visible: determineVisibility(`${source}5.2`, [`${source}5.1`, `${source}4.7`, `${source}4.6`], storedQueries) },
                    //             { id: `${source}5.3`, visible: determineVisibility(`${source}5.3`, [`${source}5.2`, `${source}4.8`, `${source}4.7`], storedQueries) },
                    //             { id: `${source}5.4`, visible: determineVisibility(`${source}5.4`, [`${source}5.3`, `${source}4.8`], storedQueries) },
                    //             { id: `${source}5.5`, visible: determineVisibility(`${source}5.5`, [`${source}5.4`], storedQueries) },
                    //         ],
                    //         [
                    //             { id: `${source}6.1`, visible: determineVisibility(`${source}6.1`, [`${source}5.5`], storedQueries) },
                    //             { id: `${source}6.2`, visible: determineVisibility(`${source}6.2`, [`${source}6.1`], storedQueries) },
                    //             { id: `${source}6.3`, visible: determineVisibility(`${source}6.3`, [`${source}6.2`], storedQueries) },
                    //             { id: `${source}6.4`, visible: determineVisibility(`${source}6.4`, [`${source}6.3`], storedQueries) },
                    //             { id: `${source}6.5`, visible: determineVisibility(`${source}6.5`, [`${source}6.4`], storedQueries) },
                    //         ],
                    //     ];
                    // };

                    // setList(createList(source, storedQueries));






















                } else {


                    setList([
                        [{
                            id: `${source}3.1`,
                            visible: true
                        }, {
                            id: `${source}3.2`,
                            visible: storedQueries.some((el) => el?.position === `${source}3.2`) ? true : storedQueries.some((el) => el?.position === `${source}3.1`) ? true : false
                        }, {
                            id: `${source}3.3`,
                            visible: storedQueries.some((el) => el?.position === `${source}3.3`) ? true : storedQueries.some((el) => el?.position === `${source}3.2`) ? true : false
                        }, {
                            id: `${source}3.4`,
                            visible: storedQueries.some((el) => el?.position === `${source}3.4`) ? true : storedQueries.some((el) => el?.position === `${source}3.3`) ? true : false
                        }, {
                            id: `${source}3.5`,
                            visible: storedQueries.some((el) => el?.position === `${source}3.5`) ? true : storedQueries.some((el) => el?.position === `${source}3.4`) ? true : false
                        }],
                        [{
                            id: `${source}4.1`,
                            visible: (storedQueries.some((el) => el?.position === `${source}4.1`) ||
                                storedQueries.some((el) => el?.position === `${source}3.6`)
                            ) ?
                                true : storedQueries.some((el) => el?.position === `${source}3.5`) ? true : false
                        }, {
                            id: `${source}4.2`,
                            visible: (storedQueries.some((el) => el?.position === `${source}4.2`)
                                || storedQueries.some((el) => el?.position === `${source}3.7`)
                            ) ? true : (storedQueries.some((el) => el?.position === `${source}4.1`) ||
                                storedQueries.some((el) => el?.position === `${source}3.6`)) ? true : false
                        }, {
                            id: `${source}4.3`,
                            visible: (storedQueries.some((el) => el?.position === `${source}4.3`) ||
                                storedQueries.some((el) => el?.position === `${source}3.8`)) ? true : (storedQueries.some((el) => el?.position === `${source}4.2`) ||
                                    storedQueries.some((el) => el?.position === `${source}3.7`)) ? true : false
                        }, {
                            id: `${source}4.4`,
                            visible: storedQueries.some((el) => el?.position === `${source}4.4`) ? true : (storedQueries.some((el) => el?.position === `${source}4.3`) ||
                                storedQueries.some((el) => el?.position === `${source}3.8`)) ? true : false
                        }, {
                            id: `${source}4.5`,
                            visible: storedQueries.some((el) => el?.position === `${source}4.5`) ? true : storedQueries.some((el) => el?.position === `${source}4.4`) ? true : false
                        }],
                        [{
                            id: `${source}5.1`,
                            visible: storedQueries.some((el) => el?.position === `${source}5.1`) ? true : storedQueries.some((el) => el?.position === `${source}4.5`) ? true : false
                        }, {
                            id: `${source}5.2`,
                            visible: storedQueries.some((el) => el?.position === `${source}5.2`) ? true : storedQueries.some((el) => el?.position === `${source}5.1`) ? true : false
                        }, {
                            id: `${source}5.3`,
                            visible: storedQueries.some((el) => el?.position === `${source}5.3`) ? true : storedQueries.some((el) => el?.position === `${source}5.2`) ? true : false
                        }, {
                            id: `${source}5.4`,
                            visible: storedQueries.some((el) => el?.position === `${source}5.4`) ? true : storedQueries.some((el) => el?.position === `${source}5.3`) ? true : false
                        }, {
                            id: `${source}5.5`,
                            visible: storedQueries.some((el) => el?.position === `${source}5.5`) ? true : storedQueries.some((el) => el?.position === `${source}5.4`) ? true : false
                        }]
                    ]);
                }
            }, 2000);

        } else {
            setList(undefined)
        }
    }, [props.storedQueries, source, storedQueries])
    return [list]

}









