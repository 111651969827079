import styled from 'styled-components';

export const StyledHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 7.5vw);
  height: auto;
  padding: 0 6px;
  margin: 0;
  justify-content: center;

  .expanded {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }


  .buble .bubletext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    right: 5vw;
    top: 1vw;
    z-index: 1;
  }

  .buble:hover .bubletext {
    visibility: visible;
  }
  .react-datepicker{

  font-family: "fontRegular";
       button {
    box-shadow: none;
   }
   .react-datepicker__day, .react-datepicker__day-name {
      width: 1.3rem;
    line-height: 1.4rem;
    }
  }
`;

interface AlertProps {
  error: string;
}
export const Alert = styled.div<AlertProps>`
  background-color: ${(props) =>
    props.error === 'error' ? '#FCE9E5' : props.error === 'warning' ? '#FFF8E6' : 'blue'};
  margin: auto;
  height: fit-content;
  width: 60%;
  border: 1px solid ${(props) => (props.error === 'error' ? '#E12A02' : props.error === 'warning' ? '#EFA333' : 'blue')};
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  gap: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  p {
    // color: red;
  }

  .iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    height: 30px;
    width: 30px;
    line-height: 30px;
    vertical-align: middle;
    font-size: 12px;
    padding: 2px;
    margin: 5px;
    border-radius: 50%;
    color: ${(props) => (props.error === 'error' ? '#E12A02' : props.error === 'warning' ? '#EFA333' : 'blue')};
  }
  .close {
    align-self: flex-end;
    padding-right: 10px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    vertical-align: middle;
    color: ${(props) => (props.error === 'error' ? '#E12A02' : props.error === 'warning' ? '#EFA333' : 'blue')};
  }
`;

interface ClientsContainerProps {
  expanded: boolean;
}

export const ClientsContainer = styled.div`
  width: 30%;
  padding: 8px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  margin-left: 4px;
  margin-bottom: 4px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap; /* Use flex-wrap to wrap items onto multiple lines */

  .icon {
    padding: 0;
    align-self: end;
  }

  input {
    display: none;
  }

  label {
    padding: 0 !important;
    font-size: 1vw;
    margin-bottom: 2px;
  }
  label:hover {
    background-color: black;
    color: white;
  }

  label:has(input:checked) {
    background-color: ${({ theme }) => theme.primaryGray};
    color: white;
  }

  .checked {
    padding: 10px;
    padding: 10px 20px;
    display: block;
    border-radius: 50px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    left: 3vw;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;



export const Content = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryGray};
  justify-content: space-between;
  align-items: center;
  h1 {
    font-family: 'FontMedium', sans-serif;
    font-weight: inherit;
    font-size: 20px;
    padding: 4px;
  }
  h2 {
    font-family: 'FontMedium', sans-serif;
    font-weight: normal;
    font-size: 16px;
    padding: 4px;
  }
  .toggleMode {
    display: flex;
    flex-direction: row;
    background: transparent;
    width: fit-content;
    margin-bottom: 8px;
    font-family: 'FontMedium', sans-serif;
    color: ${({ theme }) => theme.primaryGray};
    height: fit-content;
    margin-right: 0.5vw;
    .icon {
      padding: 5px 10px;
      svg {
        font-size: 20px;
      }
    }
    .prev {
      background-color: ${({ theme }) => theme.primaryGray};
      //  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
      color: ${({ theme }) => theme.c300};
      border-radius: 7px 0 0 7px;
    }
    .dev {
      background-color: ${({ theme }) => theme.secondaryGray};
      color: white;
      border-radius: 0 7px 7px 0;
    }
  }
`;
interface Props {
  clicked: string;
  theme: any;
}

export const Filtering = styled.div`
  padding: 4px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 5px;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primaryGray};
  }

  h1 {
    font-size: 0.9rem;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
  }

  svg {
    height: 0.9rem;
    fill: ${({ theme }) => theme.primaryGray};
    stroke-width: 5px;
    align-self: center;
  }
`;
export const FilterContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.border};
  position: fixed;
/*   top:100px; */
  left: 100px;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  margin: 35px 0;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  z-index: 100;
  height: 500px;
  display: flex;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .x {
    color: ${({ theme }) => theme.primaryGray};
    cursor: pointer;
    align-self: flex-end;
    padding: 5px 6px 3px 6px;
    size: 4px;
    margin-bottom: 0;
  }
  input[type='date'] {
    background-color: transparent;
  }

  .title {
    color: ${({ theme }) => theme.primaryGray};
    margin-bottom: 0.1vw;
    font-size: 0.9rem;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
  }
  .sectionContent {
    border-radius: 7px;
    padding: 10px;
    color: ${({ theme }) => theme.primaryGray};
    height: fit-content;
    border: 1px solid ${({ theme }) => theme.border};
    margin-bottom: 1vw;
    display: flex;
  }
  h5 {
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
  }
`;
export const ToggleMode = styled.div<Props>`
  display: flex;
  flex-direction: row;
  background: transparent;
  width: fit-content;
  margin-bottom: 8px;
  font-family: 'FontMedium', sans-serif;
  color: ${({ theme }) => theme.primaryGray};
  height: fit-content;
  margin-right: 0.5vw;
  .icon {
    padding: 6px 10px;
    cursor: pointer;
    .svg {
      width: 100%;
    }
  }  height: 1vw;
    
  .prev {
    background-color: ${(props) => (props.clicked === 'preview' ? props.theme.c100 : props.theme.border)};
    border-radius: 7px 0 0 7px;
    .svg {
      fill: ${(props) => (props.clicked === 'preview' ? props.theme.c500 : props.theme.primaryGray)};
    }
  }
  .dev {
    background-color: ${(props) => (props.clicked === 'create' ? props.theme.c100 : props.theme.border)};
    border-radius: 0 7px 7px 0;
    .svg {
      fill: ${(props) => (props.clicked === 'create' ? props.theme.c500 : props.theme.primaryGray)};
    }
  }
`;

type ContainerProps = {
  dashType?: string
}

export const Container = styled.div<ContainerProps>`
  width: ${({ dashType }) => (dashType !== '' ? '50%' : '100%')};
  padding: 4px;

  /*   margin: 2px 10px 0 10px; */ 
  display: flex;

  #sel1 {
    border-radius:  ${({ dashType }) => (dashType !== '' ? '' : '20px')};
    /* width: ${({ dashType }) => (dashType !== '' ? '130px' : '65%')}; */
  }

  #sel2 {
    width: 300px;
  }
  #sel3 {
    width: 300px;
  }

  #published{
    width: 213px;
  }

  .buttonsListClients {
    button {
      margin-right: 5px;
    }
  }

  .previewPublished{
    position: relative;
    display: block;
    width: 100%  ;
    margin-bottom: 4px;
    margin-right: 4px;
    font-size: 0.9rem;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray} !important;
    /* background: ${({ theme }) => theme.placeholderBackground} !important; */
    border-radius: 10px;
    margin-left: 1rem;
    margin-right: 2rem;
  }
`;

export const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
