import { useCampaignContext } from "context/CampaignContext";
import { DetailsPane, OverwriteMainContainer, SingleColumn, StyledLabel } from "./OverwriteMain.Styles";
import { useDataSources } from "customHooks/useDataSources";
import { metric, detailedDimensionsDetail, calculatedMetricsDetail, metricFunction, queryInput } from "API";
import { CCData, useDataQuery } from "customHooks/useDataQuery";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { platformLogoSelector } from "methods/platformLogoSelector";
import { set } from "lodash";
import { DarkButton } from "components/style/Global";
import { prepQuery } from "methods/prepQuery";
import AuthContext from "context/AuthContext";
import ResultPage from "./OvervwriteResultPage";


export type placeholderMetrics = {
    metric?: string;
    description?: string | null | undefined;
    view?: (string | null)[] | null | undefined;
    isSelected?: boolean;
    detailedMetric?: metric;
};

export type placeholderDimensions = {
    dimension?: string;
    description?: string | null | undefined;
    view?: (string | null)[] | null | undefined;
    isSelected?: boolean;
    detailedDimension?: detailedDimensionsDetail;
};

export type placeholderFullSources = {
    isSelected?: boolean;
    source_name?: string;
    metrics?: placeholderMetrics[];
    dimensions?: placeholderDimensions[];
    name: string;
    icon?: JSX.Element;
    metaName: string;
    calcMetrics: Array<calculatedMetricsDetail & { isSelected?: boolean }>;
    customConversions?: Array<CCData>;
}





const OverwriteMain = () => {
    const { activeCampaign, campaignList } = useCampaignContext();
    const [dataSources] = useDataSources(activeCampaign)
    const [selectedDataSource, setSelectedDataSource] = useState<placeholderFullSources>();
    const { user } = useContext(AuthContext);

    const [userMainQuery, setUserMainQuery] = useState<queryInput>();

    const [mainData, mainQueryStatus, mainError, mainQueryID, mainOL] = useDataQuery({
        query: userMainQuery,
        querySource: 'QUERY',
    });


    useEffect(() => {
        setSelectedDataSource(undefined)
    }, [dataSources])

    // SELECT SOURCE
    const selectSource = (event: ChangeEvent<HTMLInputElement>) => {
        const clonedDataSource = dataSources.map((el) => structuredClone(el)) as placeholderFullSources[];
        const selected = clonedDataSource.find((el) => el.name === event.target.value);
        setSelectedDataSource(undefined);
        setTimeout(() => {
            if (selected) {
                const selectedWithFilteredDims = {
                    ...selected,
                    metrics: selected.metrics?.filter((met) => met.metric === "impressions" || met.metric === "cost"),
                    dimensions: selected.dimensions?.filter((dim) => dim.dimension === "lineitemid" || dim.dimension === "lineitem").map((dd) => ({
                        ...dd, isSelected: true, detailedDimension: {
                            column: dd.dimension as string,
                            hidden: false,
                            id: Math.floor(Math.random() * Date.now()).toString(),
                            name: dd.dimension as string,
                        }
                    })) || [],

                }

                setSelectedDataSource(selectedWithFilteredDims);
            }
        }, 500);
    };

    // SELECT DIMENSION
    const selectDimension = (event: ChangeEvent<HTMLInputElement>) => {
        const addDimension = () => {
            const detailedDimension = {
                column: event.target.name,
                hidden: false,
                id: Math.floor(Math.random() * Date.now()).toString(),
                name: event.target.name,
            };



            const newDimensions = selectedDataSource?.dimensions?.map((el) => {
                if (el.dimension === event.target.value) {
                    return {
                        ...el,
                        isSelected: true,
                        detailedDimension: detailedDimension,
                    };
                } else {
                    return el;
                }
            });
            selectedDataSource && setSelectedDataSource({
                ...selectedDataSource,
                dimensions: newDimensions,
            });


        };

        const removeDimension = () => {
            const newDimensions = selectedDataSource?.dimensions?.map((el) => {
                if (el.dimension === event.target.value) {
                    delete el.detailedDimension;
                    return {
                        ...el,
                        isSelected: false,
                    };
                } else {
                    return el;
                }
            });
            selectedDataSource && setSelectedDataSource({
                ...selectedDataSource,
                dimensions: newDimensions,
            });

        };

        event.target.checked ? addDimension() : removeDimension();
    };
    // SELECT METRIC
    const selectMetric = (event: ChangeEvent<HTMLInputElement>) => {
        const addMetric = () => {
            const detailedMetric: metric = {
                column: event.target.name === 'End date' || event.target.name === 'Start date' ? 'date' : event.target.name,
                name: event.target.name,
                aggregation:
                    event.target.name.endsWith('_reach') ||
                        event.target.name.startsWith('sub_rf_') ||
                        event.target.name === 'effective_frequency' ||
                        event.target.name === 'reach' ||
                        event.target.name === 'frequency' ||
                        event.target.name === 'date' ||
                        event.target.name === 'End date'
                        ? metricFunction.MAX
                        : event.target.name === 'Start date'
                            ? metricFunction.MIN
                            : metricFunction.SUM,
                id: Math.floor(Math.random() * Date.now()).toString(),
                decimals: event.target.name === 'Start date' || event.target.name === 'End date' ? -1 : 2,
            };


            const newMetrics = selectedDataSource?.metrics?.map((el) => {
                if (el.metric === event.target.value) {
                    return {
                        ...el,
                        isSelected: true,
                        detailedMetric: detailedMetric,
                    };
                } else {
                    return el;
                }
            });
            selectedDataSource && setSelectedDataSource({
                ...selectedDataSource,
                metrics: newMetrics,
            });

        };
        const removeMetric = () => {
            const newMetrics = selectedDataSource?.metrics?.map((el) => {
                if (el.metric === event.target.value) {
                    delete el.detailedMetric;
                    return {
                        ...el,
                        isSelected: false,

                    };
                } else {
                    return el;
                }
            });
            selectedDataSource && setSelectedDataSource({
                ...selectedDataSource,
                metrics: newMetrics,
            });


        };
        event.target.checked ? addMetric() : removeMetric();
    };

    //PREP USER QUERY ON FETCH
    const prepUserQuery = () => {
        const selectedPlatform = selectedDataSource
        delete selectedPlatform?.icon;
        const q = prepQuery(selectedPlatform as placeholderFullSources, false, activeCampaign, user);
        //SET MAIN QUERY
        setUserMainQuery(q);


    };

    if (!activeCampaign) return <div>NO CAMPAIGN</div>;
    if (!dataSources || dataSources.length === 0) return <div>NO DATA SOURCE</div>;
    if (dataSources.length > 0 && mainQueryStatus === "RUNNING") return <div>LOADING</div>
    if (mainData && mainQueryStatus === "DONE") {
        return (<ResultPage
            setUserMainQuery={setUserMainQuery}
            mainData={mainData}
            selectedDataSource={selectedDataSource}
        ></ResultPage>)
    }



    return (

        <OverwriteMainContainer>
            <SingleColumn>
                <h1 className="header">Plaform</h1>
                {dataSources.map(source => (
                    <StyledLabel key={source.name}>
                        <input
                            type="radio"
                            name="dataSource"
                            value={source.name}
                            onChange={(event) => selectSource(event)}
                            id={source.name}
                        />
                        <div className="checked">
                            {/* <span className="icon">{source.name}</span> */}
                            <span>{source.name}</span>
                        </div>
                    </StyledLabel>
                ))}
            </SingleColumn>
            <SingleColumn >
                <h1 className="header">Dimensions</h1>
                {selectedDataSource && (
                    <div className="elementsContainer" >
                        {selectedDataSource.dimensions?.map((dim) => (
                            <StyledLabel key={dim.dimension}>
                                <input
                                    type="checkbox"
                                    name={dim.dimension}
                                    value={dim.dimension}
                                    id={dim.dimension}
                                    checked={dim.isSelected}
                                    readOnly
                                // onChange={(event) => selectDimension(event)}
                                />
                                <div className="checked">
                                    <span>{dim.dimension}</span>
                                </div>
                            </StyledLabel>
                        ))}
                    </div>
                )}

            </SingleColumn>
            <SingleColumn>
                <h1 className="header">Metrics</h1>
                {selectedDataSource && (
                    <div className="elementsContainer">
                        {selectedDataSource.metrics?.map((metric) => (
                            <StyledLabel key={metric.metric}>
                                <input
                                    type="checkbox"
                                    name={metric.metric}
                                    value={metric.metric}
                                    id={metric.metric}
                                    onChange={(event) => selectMetric(event)}
                                />
                                <div className="checked">
                                    <span>{metric.metric}</span>
                                </div>
                            </StyledLabel>
                        ))}
                    </div>
                )}
            </SingleColumn>


            <DetailsPane>
                <div className="iconHolder">
                    {platformLogoSelector(selectedDataSource?.name || "")}
                </div>
                <h1 className="header">Query Overview</h1>
                <div className="details">
                    <div className="p"> <h1>Platform:</h1> {selectedDataSource?.name}</div>
                    <div className="p"> <h1>Dimensions:</h1> {selectedDataSource?.dimensions?.filter(d => d.isSelected)
                        .map(d => (d.dimension + ", "))}</div>
                    <div className="p"> <h1>Metrics:</h1>{selectedDataSource?.metrics?.filter(m => m.isSelected)
                        .map(m => m.metric + ", ")} </div>



                </div>
                <DarkButton onClick={prepUserQuery}>FETCH</DarkButton>


            </DetailsPane>


        </OverwriteMainContainer>
    )
}
export default OverwriteMain;