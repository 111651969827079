import { MetricsResponse } from "API";
import { placeholderFullSources } from "components/newSetup/PlaceholderContainer";
import { set } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useJoinData = (
    mainData: Array<any> | undefined,
    mainTotalData: Array<any> | undefined,
    ccData: Array<any> | undefined,
    ccTotalData: Array<any> | undefined,
    isGraph: boolean,
    mainQueryStatus: "DONE" | "RUNNING" | "FAILED" | "NOT STARTED",
    mainTotalQueryStatus: "DONE" | "RUNNING" | "FAILED" | "NOT STARTED",
    ccQueryStatus: "DONE" | "RUNNING" | "FAILED" | "NOT STARTED",
    ccTotalQueryStatus: "DONE" | "RUNNING" | "FAILED" | "NOT STARTED",
    chartMetrics: Array<MetricsResponse>,
    setChartMetrics: Dispatch<SetStateAction<Array<MetricsResponse>>>,
    componentID: string,
    // costPerCC: string,
    newQuery?: "OLD" | "NEW" | "BACK" | "APPLY",
    defaultSelected?: boolean | undefined

): readonly [any] => {
    // NOTE: used to be without useEffect and only returns data, but it was not working properly when edit from page7
    const [data, setData] = useState<any>(undefined);

    // TODO: Problem is that when clicking Apply should not update the chartMetrics, except when I add cost per custo
    useEffect(() => {

        const chartMetricsSet = (data: any) => {
            if (isGraph && data) {
                if (newQuery === "NEW" || newQuery === "APPLY") { //TODO maybe these two conditions should be separated and "BACK" should be handled as in blocked comment below
                    // if (chartMetrics.length === 0) {
                    const mostkeys = data.sort(
                        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
                    )[0];
                    const arr = []

                    if (defaultSelected) {
                        const mostKeys = data.sort(
                            (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
                        )[0];
                        const metricKey = Object.keys(mostKeys).find((el: any) => typeof mostKeys[el] !== "string")
                        arr.push({
                            chartType: "line",
                            metric: metricKey,
                            axis: 0
                        } as MetricsResponse)

                    } else {
                        for (const [index, [key, value]] of Object.entries(mostkeys).entries()) {

                            if (typeof value === 'number' || value === "Infinity" || value === "NaN" || key === "Start date" || key === "End date" || value === null) {

                                arr.push({
                                    chartType: "column",
                                    metric: key,
                                    axis: index - 1
                                } as MetricsResponse)

                            }
                        }
                    }




                    // console.log("ARR", arr)

                    setChartMetrics(arr)
                } else if (newQuery === "BACK") {
                    const mostkeys = data.sort(
                        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
                    )[0];

                    for (const [index, [key, value]] of Object.entries(mostkeys).entries()) {
                        if (typeof value === 'number' || value === "Infinity" || value === "NaN" || key === "Start date" || key === "End date" || value === null) {

                            const existingMetric = chartMetrics.find((metric: any) => metric.metric === key);
                            if (!existingMetric) {
                                const nextAxis = chartMetrics.length;
                                setChartMetrics((ps: any) => [...ps, {
                                    chartType: "column",
                                    metric: key,
                                    axis: nextAxis
                                }])
                            }

                        }
                    }
                }
                // else if (newQuery === "APPLY") {
                //     console.count("APPLY")
                //     const mostkeys = data.sort(
                //         (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
                //     )[0];
                //     const arr = chartMetrics
                //     for (const [index, [key, value]] of Object.entries(mostkeys).entries()) {
                //         if (key.includes("Cost per")) {
                //             console.count("Cost per")
                //             const nextAxis = chartMetrics.length;
                //             arr.push({
                //                 chartType: "column",
                //                 metric: key,
                //                 axis: nextAxis
                //             } as MetricsResponse)
                //         }
                //     }


                //     setChartMetrics(arr)

                // }



            }
            // console.log(componentID, "JOINED DATA", data, mainData, mainTotalData, ccData, ccTotalData, mainQueryStatus, mainTotalQueryStatus)
            setData(data)
        }
        setData(undefined)


        if (![mainQueryStatus, mainTotalQueryStatus, ccQueryStatus, ccTotalQueryStatus].includes("RUNNING" || "FAILED")) {
            if (!isGraph) {
                // console.log(mainData, ccData)
                if (ccQueryStatus === "NOT STARTED") {
                    if (mainData && mainTotalData) {
                        const allProperties = { ...mainData[0], ...mainTotalData[0] };
                        const totalRow = Object.keys(allProperties).reduce((obj, key) => {
                            return { ...obj, [key]: mainTotalData[0][key] };
                        }, {});
                        const newData = [...mainData, totalRow];
                        chartMetricsSet(newData)
                    }



                } else if (ccQueryStatus === "DONE" && ccTotalQueryStatus === "DONE") {
                    if (mainData && mainTotalData && ccData && ccTotalData) {


                        let costPerCC = "Cost per"
                        // ccData is a list of objects, and I need to find the one that non of the values are null, undefined or NaN
                        const costPerCC2 = ccData && (ccData as any[]).find((cc: any) =>
                            Object.values(cc).every((v: any) =>
                                (typeof v === 'string' && v !== 'Infinity' && v !== 'NaN') ||
                                (typeof v === 'number')
                            ) &&
                            cc !== null &&
                            cc !== undefined
                        );

                        if (costPerCC2) {
                            const key = Object.entries(costPerCC2).find(([k, v]) =>
                                typeof v === 'number' && k !== 'custom_conversion'

                            )?.[0];
                            costPerCC = key ?? "Cost per"
                        }



                        // console.log("Main data", mainData)
                        // console.log("Main total data", mainTotalData)
                        // console.log("cc data", ccData)
                        // console.log("cc total data", ccTotalData)
                        // TODO ADD THE OTHER 2 AS WELL
                        const conversionName = ccData.length > 0 ? ccData[0].hasOwnProperty("conversion_name") ? "conversion_name"
                            : ccData[0].hasOwnProperty("filter_name") ? "filter_name"
                                : ccData[0].hasOwnProperty("activity") ? "activity"
                                    : ccData[0].hasOwnProperty("floodlight_activity_name") ? "floodlight_activity_name"
                                        : "CC_name" : "";


                        const conversionValue = ccData.length > 0 ? ccData[0].hasOwnProperty("conversion_name") ? "custom_conversions_value"
                            : ccData[0].hasOwnProperty("filter_name") ? "custom_conversions"
                                : ccData[0].hasOwnProperty("activity") ? "total_conversions"
                                    : ccData[0].hasOwnProperty("floodlight_activity_name") ? "total_conversions"
                                        : "custom_conversion" : "";
                        const transformedMainData = mainData.map((mainItem) => {
                            const matchingCCDataItems = ccData.filter((ccItem) => {
                                const { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper, ...rest } = ccItem;
                                return Object.entries(rest).every(([key, value]) => mainItem[key] === value);
                            });

                            if (matchingCCDataItems.length > 0) {
                                return matchingCCDataItems.reduce((acc, { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper }) => {
                                    if (costper) {
                                        return { ...acc, [CC_name]: custom_conversion, [`${costPerCC + CC_name}`]: costper };
                                    } else {
                                        return { ...acc, [CC_name]: custom_conversion };
                                    }
                                }, mainItem);
                            } else {
                                return { ...mainItem };
                            }
                        });


                        const transformedTotalData = mainTotalData.map((mainItem) => {
                            const matchingCCTotalDataItems = ccTotalData.filter((ccItem) => {
                                const { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper, ...rest } = ccItem;
                                return Object.entries(rest).every(([key, value]) => mainItem[key] === value);
                            });


                            if (matchingCCTotalDataItems.length > 0) {
                                return matchingCCTotalDataItems.reduce((acc, { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper }) => {
                                    if (costper) {
                                        return { ...acc, [CC_name]: custom_conversion, [` ${costPerCC + CC_name}`]: costper };
                                    } else {
                                        return { ...acc, [CC_name]: custom_conversion };
                                    }
                                }, mainItem);
                            } else {
                                return { ...mainItem };
                            }
                        });

                        const allProperties = { ...transformedMainData[0], ...transformedTotalData[0] };
                        // console.log("All properties", allProperties)
                        const totalRow = Object.keys(allProperties).reduce((obj, key) => {
                            // console.log("Key", key)
                            // console.log("obj", obj)
                            return { ...obj, [key]: transformedTotalData[0][key] };
                        }, {});

                        const newData = [...transformedMainData, totalRow];
                        chartMetricsSet(newData)



                    }
                }
            } else {
                if (ccQueryStatus === "NOT STARTED") {
                    if (mainData) {

                        chartMetricsSet(mainData)

                    }
                } else if (ccQueryStatus === "DONE") {
                    if (mainData && ccData) {
                        let costPerCC = "Cost per"
                        // ccData is a list of objects, and I need to find the one that non of the values are null, undefined or NaN
                        const costPerCC2 = ccData && (ccData as any[]).find((cc: any) =>
                            Object.values(cc).every((v: any) =>
                                (typeof v === 'string' && v !== 'Infinity' && v !== 'NaN') ||
                                (typeof v === 'number')
                            ) &&
                            cc !== null &&
                            cc !== undefined
                        );

                        if (costPerCC2) {
                            const key = Object.entries(costPerCC2).find(([k, v]) =>
                                typeof v === 'number' && k !== 'custom_conversion'

                            )?.[0];
                            costPerCC = key ?? "Cost per"
                        }




                        const conversionName = ccData.length > 0 ? ccData[0].hasOwnProperty("conversion_name") ? "conversion_name"
                            : ccData[0].hasOwnProperty("filter_name") ? "filter_name"
                                : ccData[0].hasOwnProperty("activity") ? "activity"
                                    : ccData[0].hasOwnProperty("floodlight_activity_name") ? "floodlight_activity_name"
                                        : "CC_name" : "";
                        const conversionValue = ccData.length > 0 ? ccData[0].hasOwnProperty("conversion_name") ? "custom_conversions_value"
                            : ccData[0].hasOwnProperty("filter_name") ? "custom_conversions"
                                : ccData[0].hasOwnProperty("activity") ? "total_conversions"
                                    : ccData[0].hasOwnProperty("floodlight_activity_name") ? "total_conversions"
                                        : "custom_conversion" : "";
                        const transformedMainData = mainData.map((mainItem) => {
                            const matchingCCDataItems = ccData.filter((ccItem) => {
                                const { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper, ...rest } = ccItem;
                                return Object.entries(rest).every(([key, value]) => mainItem[key] === value);
                            });

                            if (matchingCCDataItems.length > 0) {
                                return matchingCCDataItems.reduce((acc, { [conversionName]: CC_name, [conversionValue]: custom_conversion, [costPerCC]: costper }) => {
                                    if (costper) {
                                        return { ...acc, [CC_name]: custom_conversion, [` ${costPerCC + CC_name}`]: costper };
                                    } else {
                                        return { ...acc, [CC_name]: custom_conversion };
                                    }
                                }, mainItem);
                            } else {
                                return { ...mainItem };
                            }
                        });
                        chartMetricsSet(transformedMainData)
                    }
                }
            }
        }
    }, [ccData, ccQueryStatus, ccTotalData, ccTotalQueryStatus, isGraph, mainData, mainQueryStatus, mainTotalData, mainTotalQueryStatus])

    return [data] as const;
};



