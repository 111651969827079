import Insights from 'components/Portal/Insights';
import { useClientContext } from 'context/ClientContext';
import styled, { useTheme } from 'styled-components';

import { ReactComponent as ADD_NEW } from '../../../assets/newAssets/add_new.svg';
import React, { useContext, useEffect, useState } from 'react';

import { ReactComponent as EDIT_LINK } from '../../../assets/newAssets/editLink.svg';
import { ReactComponent as DELETE_LINK } from '../../../assets/newAssets/deleteLink.svg';
import { ReactComponent as OPEN_LINK } from '../../../assets/newAssets/openLink.svg';
import ManageLinkModal from './manageLinkModal';
import DeleteConfirmationModal from 'components/DeleteConfirmationModall';
import { API } from 'aws-amplify';
import { getAllLinksByOrg } from 'graphql/queries';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

import APIContext from 'context/APIContext';
import {
  ClientCodes,
  CreateLinkMutation,
  DeleteLinkMutation,
  GetAllLinksByOrgQuery,
  RemoveClientCodeMutation,
  UpdateLinkMutation,
  UpdateOrderListMutation,
  linkListsResponse,
  linkResponse,
} from 'API';
import {
  addClientCode,
  createLink,
  deleteLink,
  removeClientCode,
  updateLink,
  updateOrderList,
} from 'graphql/mutations';
import { usePortalContext } from 'context/PortalContext';

import ReorderModal from './reOrderModal';

interface IisClient {
  isClient?: boolean;
}

export const LinksContainerStyled = styled.div<IisClient>`
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.border};
  padding: 10px;
  height: fit-content;
  min-height: 160px;
  max-height: 160px;
  display: grid;
  //grid that is 3 columns wide and 2 rows tall
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.placeholderBackground};
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.scrollThumb};
  }
  //gap between the grid items
  gap: 10px;
  .noAccess:hover {
    background-color: ${({ theme }) => theme.c50};
    display: ${(props) => (props.isClient ? '' : 'flex')};
    justify-content: space-around;
    .svg {
      fill: ${({ theme }) => theme.c500};
    }
    .name {
      display: ${(props) => (props.isClient ? '' : 'none')};
    }
    .seeMore {
      display: ${(props) => (props.isClient ? 'none' : 'flex')};
      justify-content: space-around;
      gap: 1vw;
      color: ${({ theme }) => theme.c500};
    }
    .open:hover,
    .delete:hover,
    .edit:hover {
      fill: ${({ theme }) => theme.c300};
      cursor: pointer;
    }
  }
`;
interface IInsight {
  isClient?: boolean;
}
const SingleInsight = styled.a<IInsight>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 1px solid ${({ theme }) => theme.border};
  font-family: 'FontRegular', sans-serif;
  font-weight: 200;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  align-items: center;
  padding: 10px;
  color: ${({ theme }) => theme.primaryGray};
  /* color: ${(props) => (props.isClient ? props.theme.primaryGray : props.theme.secondaryGray)}; */
  min-height: 3vw;
  &:hover {
    background-color: ${({ theme }) => theme.c50};
    .svg {
      fill: ${({ theme }) => theme.c500};
    }
    .ikon {
      display: ${(props) => (props.isClient ? 'initial' : 'none')};
      color: ${({ theme }) => theme.c500};
    }
    .name {
      color: ${({ theme }) => theme.c500};
    }
  }
  .ikon {
    font-size: 1.2vw;
  }

  .svg {
    /* height: 1.2vw; */
    /* fill: ${(props) => (props.isClient ? props.theme.primaryGray : props.theme.secondaryGray)}; */

    stroke-width: 5px;
    width: 100%;

    path {
      stroke: ${({ theme }) => theme.primaryGray};
      stroke-width: 0.1;
    }
  }
  .name {
    grid-column: 2 / span 3;
    font-size: 0.6vw;
  }
  .seeMore {
    /* grid-column: 2 / span 3; */

    font-size: 0.6vw;
    display: none;
  }
`;

const AddLink = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 1px dashed ${({ theme }) => theme.border};
  font-family: 'FontRegular', sans-serif;
  font-weight: 200;
  border-radius: 7px;
  cursor: pointer;
  /* background-color: ${({ theme }) => theme.placeholderBackground}; */
  align-items: center;
  padding: 10px;
  color: ${({ theme }) => theme.primaryGray};
  &:hover {
    background-color: ${({ theme }) => theme.c50};
    .svg {
      fill: ${({ theme }) => theme.c500};
      color: ${({ theme }) => theme.c500};
    }
    .name {
      color: ${({ theme }) => theme.c500};
    }
  }

  .name {
    grid-column: 2 / span 3;
    font-size: 0.6vw;
  }
  .seeMore {
    grid-column: 2 / span 3;
    font-size: 0.6vw;
    display: none;
  }
`;

export type selectedLink =
  | {
    title: string;
    icon_name: string;
    url: string;
    id: string;
  }
  | undefined;

export type IconObject = {
  value: string;
  icon: JSX.Element;
};

export type IconRepository = {
  [key: string]: IconObject;
};

type Props = {
  agency: string;
};
// export type ClientCodesExtended = ClientCodes & { selected: boolean }
const LinksContainer = (props: Props) => {
  const { activeOrganization, loggedInUserAccessType } = useClientContext();
  const { getEnvironment } = useContext(APIContext);
  const { linksResponse, fetchLinks } = usePortalContext();
  const theme = useTheme();

  const availableClientCodes = loggedInUserAccessType?.Access?.filter((e) => e?.Level?.includes('FULL'))[0]?.Clients?.map(
    (a) => {
      return {
        __typename: 'ClientCodes',
        ClientCode: a?.ClientCode,
        CLientCountry: activeOrganization?.OrganizationId.split('#')[1],
        // selected: false
      } as ClientCodes;
    },
  );
  // const handleClientCodeClick = (clientCode: string) => {
  // const newClientCodes = availableClientCodes.map(code => code.ClientCode === clientCode ? { ...code, selected: !code.selected } : code)
  // console.log(newClientCodes)
  // }

  async function createNewLink(link: linkResponse) {
    try {
      const response = (await API.graphql({
        query: createLink,
        variables: {
          input: {
            title: link.title,
            linkValue: link?.linkValue,
            icon: link.icon,
            image: link.image,
          },
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
          clientCodes: link.clientCodes?.map((a) => a?.ClientCode) as string[],
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as CreateLinkMutation;
      fetchLinks();
    } catch (error) {
      console.error('Error creating link', error);
    }
  }
  const checkFullAccess = loggedInUserAccessType?.Access?.find((a) => a?.Level === "FULL_ACCESS")?.Clients ?? []

  const isAgency = checkFullAccess.length > 0 ? true : false

  const [manageLinkModal, setManageLinkModal] = useState(false);

  const [selectedLink, setSelectedLink] = useState<linkResponse | undefined>(undefined);

  const handleManageLinkModal = (a: linkResponse | undefined) => {
    manageLinkModal ? setSelectedLink(undefined) : setSelectedLink(a);
    setManageLinkModal(!manageLinkModal);
  };

  const saveLink = (link: linkResponse) => {
    // if (link) {
    //   if (selectedLink) {
    //     const newLinks = fakeLinksResponse.links.map((l) => l.id === link.id ? link : l)
    //     setFakeLinksResponse({ ...fakeLinksResponse, links: newLinks })
    //   } else {
    //     setFakeLinksResponse({ ...fakeLinksResponse, links: [...fakeLinksResponse.links, link], order: [...fakeLinksResponse.order, link.id] })
    //   }
    // }
    createNewLink(link);
    handleManageLinkModal(undefined);
  };

  const orderUpdate = async (order: string[]) => {
    try {
      const response = (await API.graphql({
        query: updateOrderList,
        variables: {
          orderList: order,
          // orderList: [],
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as UpdateOrderListMutation;
      fetchLinks();
    } catch (error) {
      console.error('Error updating order', error);
    }
  };

  const handleUpdateLink = async (link: linkResponse) => {
    // UPDATE CLIENT CODES
    const removedClientCodes =
      selectedLink?.clientCodes?.filter(
        (cc1) => !link.clientCodes?.some((cc2) => cc1?.ClientCode === cc2?.ClientCode),
      ) ?? [];
    const addedClientCodes =
      link.clientCodes?.filter(
        (cc1) => !selectedLink?.clientCodes?.some((cc2) => cc1?.ClientCode === cc2?.ClientCode),
      ) ?? [];
    if (removedClientCodes?.length > 0) {
      try {
        const response = (await API.graphql({
          query: removeClientCode,
          variables: {
            Organization_id: activeOrganization?.OrganizationId,
            id: selectedLink?.Id,
            clientCodes: removedClientCodes?.map((a) => a?.ClientCode) as string[],
            Environment: getEnvironment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as RemoveClientCodeMutation;
        // TODO: UPDATE ORDER
        // console.log('Remove Client Code', response);
        // fetchLinks()
      } catch (error) {
        console.error('Error updating link', error);
      }
    }
    if (addedClientCodes?.length > 0) {
      try {
        const response = (await API.graphql({
          query: addClientCode,
          variables: {
            Organization_id: activeOrganization?.OrganizationId,
            id: selectedLink?.Id,
            clientCodes: addedClientCodes?.map((a) => a?.ClientCode) as string[],
            Environment: getEnvironment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as RemoveClientCodeMutation;
        // console.log('Add Client Code', response);
        // fetchLinks()
      } catch (error) {
        console.error('Error updating link', error);
      }
    }

    // compare title, linkValue, icon, image in link and selectedLink
    // if they are different add them to the update object
    // if they are the same do nothing

    const updateObject = {
      ...(link.title !== selectedLink?.title && { title: link.title }),
      ...(link.linkValue !== selectedLink?.linkValue && { linkValue: link.linkValue }),
      ...(link.icon !== selectedLink?.icon && { icon: link.icon }),
      ...(link.image !== selectedLink?.image && { image: link.image }),
    };
    if (Object.keys(updateObject).length > 0) {
      try {
        const response = (await API.graphql({
          query: updateLink,
          variables: {
            input: updateObject,
            Organization_id: activeOrganization?.OrganizationId,
            Environment: getEnvironment,
            Id: selectedLink?.Id,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as UpdateLinkMutation;
        // TODO: UPDATE ORDER
        // fetchLinks()
      } catch (error) {
        console.error('Error updating link', error);
      }
    }
    fetchLinks();
    setManageLinkModal(false);
  };

  /*---------------------------------DELERTE LINK MODAL---------------------------------*/
  async function handleDeleteLink(id: string) {
    try {
      const response = (await API.graphql({
        query: deleteLink,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
          Id: id,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as DeleteLinkMutation;
      fetchLinks();
      const newOrder = linksResponse?.order?.filter(a => a !== id) as string[]

      orderUpdate(newOrder)
      return true;
    } catch (error) {
      console.error('Error deleting link', error);
      return false;
    }
  }

  const [deleteLinkModal, setDeleteLinkModal] = useState(false);
  const [deleteID, setDeleteID] = useState<string>();
  const handleDeleteConfirmationModal = async (a: boolean) => {
    if (a) {
      handleDeleteLink(deleteID as string);
      setDeleteLinkModal(false);
    } else {
      setDeleteLinkModal(a);
    }
  };

  const [reorderModal, setReorderModal] = useState(false);

  const areLinksAndOrderEqual = () => {
    const checkLengthLinksAndOrder = linksResponse?.links?.length === linksResponse?.order?.length
    if (checkLengthLinksAndOrder) {
      return true
    } else {
      return false
    }
  }


  // useEffect(()=> {
  //   if(areLinksAndOrderEqual()) {
  //     // console.log("links and order are equal")
  //   } else {
  //     const onlyDisplayExistingLinks = linksResponse?.order?.filter((id) => linksResponse?.links?.find((link) => link?.Id === id))
  //     orderUpdate(onlyDisplayExistingLinks as string[])
  //     console.log("links and order are not equal")
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[linksResponse])
  // console.log("LINKSRESPONSE", linksResponse)

  return (
    <>
      <>
        {linksResponse && linksResponse !== undefined ? (
          <>
            <LinksContainerStyled
              isClient={!isAgency}
              style={{ border: props.agency === 'annalect' || props.agency === 'hs' ? '0px solid gray' : '' }}
            >
              {linksResponse !== null &&
                linksResponse !== undefined &&
                linksResponse.links
                  ?.sort(
                    (a, b) =>
                      (linksResponse.order as string[]).indexOf(a?.Id as string) -
                      (linksResponse.order as string[]).indexOf(b?.Id as string),
                  )
                  .map((link) => (
                    /*    && linksResponse.links?.map((link) => ( */
                    <SingleInsight
                      isClient={!isAgency}
                      href={!isAgency ? (link?.linkValue as string).startsWith('http://') ||
                        (link?.linkValue as string).startsWith('https://')
                        ? (link?.linkValue as string)
                        : `http://${link?.linkValue}` : void 0}
                      target="_blank"
                      className="noAccess"
                      theme={theme}
                      key={link?.Id}
                    >
                      {/* {getIcon(link?.icon as string)} */}
                      <div className="material-symbols-outlined svg ikon">{link?.icon}</div>
                      <span className="name">{link?.title}</span>
                      <span className="seeMore">
                        <OPEN_LINK
                          onClick={() =>
                            window.open(
                              (link?.linkValue as string).startsWith('http://') ||
                                (link?.linkValue as string).startsWith('https://')
                                ? (link?.linkValue as string)
                                : `http://${link?.linkValue}`,
                              '_blank',
                            )
                          }
                          className="svg open"
                        />
                        <EDIT_LINK onClick={() => handleManageLinkModal(link as linkResponse)} className="svg edit" />
                        <DELETE_LINK
                          onClick={() => {
                            setDeleteLinkModal(true);
                            setDeleteID(link?.Id as string);
                          }}
                          className="svg delete"
                        />
                      </span>
                    </SingleInsight>
                  ))}

              {isAgency && (
                <AddLink onClick={() => handleManageLinkModal(undefined)}>
                  <ADD_NEW className="svg" />
                  <span className="name">Add new link</span>
                </AddLink>
              )}
              {isAgency && linksResponse.links && linksResponse.links?.length > 0 && (
                <AddLink onClick={() => setReorderModal(true)}>
                  <div className="material-symbols-outlined svg ikon">reorder</div>
                  <span className="name">Reorder links</span>
                </AddLink>
              )}

              {manageLinkModal && (
                <ManageLinkModal
                  handleManageLinkModal={handleManageLinkModal}
                  selectedLink={selectedLink as linkResponse}
                  saveLink={saveLink}
                  availableClientCodes={availableClientCodes}
                  handleUpdateLink={handleUpdateLink}
                // handleClientCodeClick={handleClientCodeClick}
                ></ManageLinkModal>
              )}

              {deleteLinkModal && (
                <DeleteConfirmationModal
                  handleDeleteConfirmationModal={handleDeleteConfirmationModal}
                  modalObject={{
                    title: 'Delete Link',
                    message: 'Are you sure you want to delete this link?',
                    mainButton: 'Delete',
                    secondaryButton: 'Cancel',
                  }}
                ></DeleteConfirmationModal>
              )}

              {reorderModal && (
                <ReorderModal
                  reorderModal={reorderModal}
                  // orderList={linksResponse.order as string[]}
                  setReorderModal={setReorderModal}
                  linksResponse={linksResponse}
                  orderUpdate={orderUpdate}
                ></ReorderModal>
              )}
            </LinksContainerStyled>
          </>
        ) : (
          <>
            <div></div>
          </>
        )}
      </>
    </>
  );
};

export default LinksContainer;
