import styled from "styled-components";

export const BriefContainer = styled.div`

margin-top: 30px;

.subColumnBulletin {
  display: flex;
  flex-direction: row;
}
.headerSection {
    margin-bottom: 30px;


    h1 {
        font-size: 22px;
        font-family: 'FontRegular',sans-serif;
        color: #807F83;
        font-weight: normal;
        margin-bottom: 10px;
    }

    h2{
        font-size: 16px;
        font-family: 'FontRegular',sans-serif;
        color: #807F83;
        font-weight: normal;
        margin-bottom: 10px;
    }
}

  .loadingDot {
    animation: loadingDots 1s infinite;
    margin-left: 5px;
  }

  .loadingDot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loadingDot:nth-child(3) {
    animation-delay: 0.4s;
  } 

  @keyframes loadingDots {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  } 

`

export const FormInputContainer = styled.div`
display: flex;
flex-direction: column;
/* width: 100%; */
margin-bottom:1.3vw;

.test {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 100px;
  
}

input, select, textarea {

  display: flex;
  flex-direction: column;
font-size: 12px;
font-family: "fontRegular";
padding: 8px;
border-radius: 7px;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
background-color:${({ theme }) => theme.background};

  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
}
textarea {
    resize: none;
    min-height: 100px;
}

option {
    font-size: 12px;
  }

  label {
    font-size: 12px;
  }

  span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none
  }
  input:invalid[focused-name = true] {
    outline: 1px solid red;
  }
  input:invalid[focused-name = true] ~ span {
    display: block;
  }

  textarea:invalid[focused-name = true] {
    outline: 1px solid red;
  }
  textarea:invalid[focused-name = true] ~ span {
    display: block;
  }

  .labelContianer {
    display: flex;
    flex-direction: row;
  }

/*   .errorMessage {
    color: red;
  }

  .errorMessageInput {
    border: 1px solid red;
  } */
/* .react-datepicker-popper {
align-self:flex-start  !important;;
} */
.react-datepicker-wrapper{
  max-width: 200px!important;
}

.react-datepicker__navigation {
span {
  display: block;
}
}

`

export const Select = styled.select`
  font-size: 12px;
  font-family: 'fontRegular';
  padding: 8px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.primaryGray};
  background-color: ${({ theme }) => theme.background};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 10vw;
  margin-bottom:1.3vw;
`

export const UploadFileContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;

#file-upload::-webkit-file-upload-button {
  visibility: hidden;
}

#file-upload::before {
  content: 'Choose file';
  display: inline-block;
  background: #007bff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}

#file-upload:hover::before {
  background: #0069d9;
}

.uploadedFilesLeftContianer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px dashed #C9CACC;
  border-radius: 9px;

  label {
    font-size: 15px;
    margin: 10px;
  }

  input {
    width: 100%;
    height: auto;
  }

}

.uploadedFilesRightContianer {
  width: 100%;
  margin-left: 20px;

  label {
    font-size: 15px;
    margin: 10px;
    margin-left: 0px;
  }

  ul {
    margin-top: 10px;
  }

  .titleRightContainer {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
  
    .button {
      font-size: 13px;
      justify-content: center;
      border: 1px solid #807F83;
      border-radius: 4px;
      padding: 5px;
      color: #807F83;
      font-family: 'FontMedium',sans-serif;
      cursor: pointer;
    }

    .button:hover {
      border: 1px solid #e03d3d;
      background-color: #e03d3d;
      color: white;
    }
  
  }

  
}

li {
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 5px;

  a {
  text-decoration: none;
  color: black;
  }

  .fileAndTitleContainer {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .buttonRemove {
    display: flex;
    align-items: center;
    font-size: 11px;
    justify-content: center;
    width: 20px;
    height: 19px;
    border: 1px solid #807F83;
    border-radius: 4px;
    padding: 5px;
    color: #807F83;
    font-family: 'FontMedium',sans-serif;
    cursor: pointer;
  }


  .buttonRemove:hover {
    border: 1px solid #e03d3d;
   background-color: #e03d3d;
   color: white;
  }

}

li:hover {
  background-color: #eaeaea;
}

`


export const InfoIcon = styled.div`
  .questionMark{

    font-size: 10px;
    color: ${({ theme }) => theme.primaryGray};;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};;
    border-radius: 50%;
    font-weight: normal;
    padding: 0 3px;
    top: -7px;
    right: -7px;
    margin-left: 5px; 
    white-space: pre-wrap;
  }

    .tooltip .tooltiptext {
    visibility: hidden;
    /* visibility: visible; */
    width: fit-content;
    max-width: 350px;
    font-size: 13px;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: left;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};;
    border-radius: 7px;
    padding: 3px 8px;
      font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }


  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltipListContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      margin-top: 3px;
    }

    ul {
      margin-top: 4px;
      text-align: initial;
      margin-left: 13px;
    }
  }

`