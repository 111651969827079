import styled from 'styled-components';

export const OverwriteMainContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  width: 99%;
  height: calc(100vh - 105px);
  padding-bottom: 1vh;
  padding: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
`;

export const SingleColumn = styled.div`
  width: 15.63vw;
  padding: 1.04vw;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.border};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;

  .header {
    font-size: 1.04vw;
    font-family: 'FontMedium';
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray};
    padding: 0.89vw 0.73vw;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    margin-bottom: 1.04vw;
  }
  .elementsContainer {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.placeholderBackground};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: ${({ theme }) => theme.scrollThumb};
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  background: transparent;
  /*     border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px; */
  /* width: fit-content; */
  /* margin-bottom: 8px; */
  /* margin-top: 21px; */
  font-family: 'FontMedium', sans-serif;
  color: ${({ theme }) => theme.primaryGray};
  display: flex;
  margin-bottom: 0.24vw;
  max-width: 14vw;

  input {
    display: none;
  }
  .checked {
    padding: 0.47vw 0.63vw;
    max-width: 14vw;
    span {
      font-size: 0.73vw;
      font-family: 'FontMedium';
      font-weight: normal;
      color: ${({ theme }) => theme.primaryGray};
      word-wrap: break-word;
    }
  }

  input:checked ~ .checked {
    /* border: 1px solid ${({ theme }) => theme.primaryGray}; */
    background-color: ${({ theme }) => theme.border};
    border-radius: 7px;
    width: 100%;
  }
`;

export const DetailsPane = styled.div`
  width: calc(99% - (15.63vw * 3));
  padding: 6vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  height: 100%;

  .header {
    font-size: 1.25vw;
    font-family: 'FontMedium';
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray};
    padding: 0.89vw 0.73vw;
    margin-bottom: 1.04vw;
  }

  .iconHolder {
    display: flex;
    width: 10.42vw;
    height: 10.42vw;
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 7%;
    justify-content: center;
    align-items: center;
    svg {
      font-size: 10vw !important;
      width: 10.42vw !important;
      height: 10.42vw !important;
    }
  }
  .details {
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* border: 1px solid ${({ theme }) => theme.border}; */
    .p {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      /* padding: 0.89vw 0.73vw; */
      margin-bottom: 1.04vw;
    }
    h1 {
      font-size: 1.04vw;
      font-family: 'FontMedium';
      font-weight: normal;
      color: ${({ theme }) => theme.primaryGray};
      margin-right: 1.04vw;
    }
  }

  button {
    position: absolute;
    bottom: 4vh;
    right: 4vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.04vw;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  margin-bottom: 1.04vw;
  .table {
    height: 50vh;
    width: 100%;
    padding: 2vw;

    table {
      border-collapse: collapse;
    }

    tr {
      font-family: 'FontRegular', sans-serif;
      white-space: nowrap;
      color: ${({ theme }) => theme.primaryGray};
      border-bottom: 1px solid ${({ theme }) => theme.border};
    }

   tbody > tr > td {
      padding: 0.5vw 0.7vw;
      font-size: 0.7vw;
      color: ${({ theme }) => theme.primaryGray};
      font-family: 'FontRegular', sans-serif;
      border-bottom: 1px solid ${({ theme }) => theme.border};
      border-collapse: collapse;
    }
    th {
      color: ${({ theme }) => theme.primaryGray};
      padding:  0.5vw 0.7vw;
      font-size: 0.9vw;
      font-family: 'FontBold', sans-serif;
      border-bottom: none;
      text-align: left;
    }
    tbody > tr:nth-last-child(1) > td {
      border-bottom: none;
    }
    th {
      /* padding: 12px; */
      font-size: 0.9vw;
    }
    tr:hover > td {
      background-color: ${({ theme }) => theme.background};
    }
    thead > tr > th {
      border-bottom: 1px solid ${({ theme }) => theme.border};
      border-collapse: collapse;
      padding: 0.5vw 0.7vw;
    }
    tfoot > tr > td  {
      padding: 1vw 0.7vw;
      font-size: 0.9vw;
      font-family: 'FontBold', sans-serif;
      font-weight: bold;
    }
  }
  .comment {
    width: 100%;
    padding: 1.04vw;
    height: 30vh;
    textarea  {
      width: 100%;
    min-height: 200px;
    border-radius: 7px;
    resize: none;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color: ${({ theme }) => theme.background};
    font-size: 16px;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
    font-style: normal;
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
}
  }
  .backButton {
    position: absolute;
    bottom: 2vw;
    right: 2vw;
  }

 
`;
