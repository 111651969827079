import React from 'react';
import { useTheme } from 'styled-components'




const TVLogo = () => {
  const theme = useTheme()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26" height="28" viewBox="0 0 26 28">
      <defs>
        <linearGradient id="linear-gradient" x1="0.005" y1="0.5" x2="0.998" y2="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#595bd4" />
          <stop offset="1" stopColor="#fe9526" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="-0.11" x2="1.408" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-3" x1="-4.969" x2="1.485" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-9" x1="-1.488" x2="8.426" xlinkHref="#linear-gradient" />
      </defs>
      <g id="television" transform="translate(-11 -10)">
        <path id="Path_1" data-name="Path 1" d="M34,19H22.81l-.586-1.171a1.49,1.49,0,0,0-1-.782L22.453,14c.016,0,.031.005.047.005a1.518,1.518,0,1,0-.978-.372L20.162,17h-1.3l-1.441-4.323a1.495,1.495,0,1,0-.947.32l1.348,4.044a1.475,1.475,0,0,0-1.043.789L16.192,19H14a3,3,0,0,0-3,3V34a3,3,0,0,0,3,3h1v.5a.5.5,0,0,0,1,0V37H32v.5a.5.5,0,0,0,1,0V37h1a3,3,0,0,0,3-3V22A3,3,0,0,0,34,19ZM22.5,12a.5.5,0,1,1-.5.5A.5.5,0,0,1,22.5,12ZM16,11.5a.5.5,0,1,1,.5.5A.5.5,0,0,1,16,11.5Zm1.671,6.776A.5.5,0,0,1,18.118,18h2.764a.5.5,0,0,1,.447.276l.362.724H17.309ZM36,34a2,2,0,0,1-2,2H14a2,2,0,0,1-2-2V22a2,2,0,0,1,2-2H34a2,2,0,0,1,2,2Z" fill="url(#linear-gradient)" />
        <path id="Path_2" data-name="Path 2" d="M28.5,21h-14A1.5,1.5,0,0,0,13,22.5v11A1.5,1.5,0,0,0,14.5,35h14A1.5,1.5,0,0,0,30,33.5v-11A1.5,1.5,0,0,0,28.5,21ZM29,33.5a.5.5,0,0,1-.5.5h-14a.5.5,0,0,1-.5-.5v-11a.5.5,0,0,1,.5-.5h4.293l-3.147,3.147a.5.5,0,1,0,.707.707L20.207,22h1.586l-3.418,3.418a.5.5,0,1,0,.707.707L23.207,22H28.5a.5.5,0,0,1,.5.5Z" fill="url(#linear-gradient-2)" />
        <path id="Path_3" data-name="Path 3" d="M33,31a2,2,0,1,0,2,2A2,2,0,0,0,33,31Zm-1,2a.987.987,0,0,1,.5-.846v1.692A.987.987,0,0,1,32,33Zm1.5.846V32.154a.966.966,0,0,1,0,1.692Z" fill="url(#linear-gradient-3)" />
        <path id="Path_4" data-name="Path 4" d="M34.5,23h-3a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" fill="url(#linear-gradient-3)" />
        <path id="Path_5" data-name="Path 5" d="M34.5,21h-3a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" fill="url(#linear-gradient-3)" />
        <path id="Path_6" data-name="Path 6" d="M34.5,25h-3a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" fill="url(#linear-gradient-3)" />
        <path id="Path_7" data-name="Path 7" d="M34.5,27h-3a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" fill="url(#linear-gradient-3)" />
        <path id="Path_8" data-name="Path 8" d="M34.5,29h-3a.5.5,0,0,0,0,1h3a.5.5,0,0,0,0-1Z" fill="url(#linear-gradient-3)" />
        <path id="Path_9" data-name="Path 9" d="M16.75,27.043l-1.6,1.6a.5.5,0,1,0,.707.707l1.6-1.6a.5.5,0,1,0-.707-.707Z" fill="url(#linear-gradient-9)" />
      </g>
    </svg>

  );
};

export default TVLogo;
