import styled, { useTheme } from 'styled-components';
import { MainContainer, Graphs, Tables, AudienceReport } from 'components/Main/Main.styles';



import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { Add, TxtContainer2 } from 'components/Main/table/TableStyles';
import { StyledGraphContainer } from 'components/Main/graf/GraphStyles';
import TVKPIs from 'components/newSetup/TV/TVKPIs';
import { useDataSources } from 'customHooks/useDataSources';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';
import { useTemplateContext } from 'context/TemplateContext';
import PlaceholderContainer from '../PlaceholderContainer';
import { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import ClientPlaceholderContainer from '../CLIENTCOMPONENTS/ClientPlaceholderContainer';
import { PublishContext } from 'context/PublishContext';
import NoCampaignSelected from '../NoCampaignSelected';
import InputContext from 'context/InputContext';
import { useClientContext } from 'context/ClientContext';
import ARContainer from 'components/Main/Gallery/ARContainer';
import { IImageList, useImageList } from 'customHooks/useImageList';



export const Alert = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
  text-align: center;
  .text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: white;
  }
`;



const TVmain = () => {
    const { activeSubCampaign, activeCampaign } = useCampaignContext()
    const { fetchedPlaceholders } = useTemplateContext()
    const newPlaceholder = undefined
    const theme = useTheme()
    const [dataSources] = useDataSources(activeCampaign)
    const { publishedData } = useContext(PublishContext)



    // useEffect(() => {
    //     setUploadComponents(undefined)
    // }, [activeSubCampaign?.subcampaignId])


    const [storedQueries, , , , , , publishedQueries] = useGetStoredQueriesDetails(activeCampaign, newPlaceholder, fetchedPlaceholders, publishedData)

    const { setCapture } = useContext(InputContext);
    const { anyCreate } = useClientContext();
    const isAgency = anyCreate;



    const componentIDs = [
        ['TV4.1', 'TV4.2', 'TV4.3', 'TV4.4', 'TV4.5'],
        ['TV5.1', 'TV5.2', 'TV5.3', 'TV5.4', 'TV5.5'],
        ['TV6.1', 'TV6.2', 'TV6.3', 'TV6.4', 'TV6.5'],
    ];

    const [uploadComponents, setUploadComponents] = useState<IImageList[][] | undefined>(undefined)
    const [list] = useImageList({ storedQueries: storedQueries, source: "TV" })
    useEffect(() => {
        if (dataSources.length > 0) {
            setUploadComponents(list)
        } else {
            setUploadComponents(undefined)
        }
    }, [dataSources, list, activeSubCampaign])


    return (
        <>
            {activeCampaign?.authorized && <button className='PDFbutton' onClick={() => setCapture(true)}>Download PPT</button>}
            {activeCampaign ?
                <>
                    {activeCampaign.authorized ?
                        <MainContainer key={activeSubCampaign?.subcampaignId} >
                            {activeSubCampaign?.subcampaignId !== -1 && storedQueries.length > 0 && dataSources.length > 0 ?
                                <>
                                    <TVKPIs></TVKPIs>

                                    <Graphs
                                        dashboard={"TV"}
                                        columnsNumber={4}
                                    >


                                        {storedQueries.filter(el => el?.position?.startsWith("TV1"))
                                            .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                            .map(e => (
                                                <PlaceholderContainer
                                                    key={e.position}
                                                    componentID={e.position as string}
                                                    placeholderId={e.position as string}
                                                    dataSources={dataSources}
                                                    storedQueryDetails={e}
                                                    isGraph={true}
                                                ></PlaceholderContainer>
                                            ))}


                                    </Graphs>

                                    <Graphs dashboard={"TV"}
                                        columnsNumber={2}>

                                        {storedQueries.filter(el => el?.position?.startsWith("TV2"))
                                            .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                            .map(e => (
                                                <PlaceholderContainer
                                                    key={e.position}
                                                    componentID={e.position as string}
                                                    placeholderId={e.position as string}
                                                    dataSources={dataSources}
                                                    storedQueryDetails={e}
                                                    isGraph={true}
                                                ></PlaceholderContainer>
                                            ))}
                                    </Graphs>

                                    <Tables>
                                        {storedQueries.filter(el => el?.position?.startsWith("TV3")).map(e => (
                                            <PlaceholderContainer
                                                key={e.position}
                                                componentID={e.position as string}
                                                placeholderId={e.position as string}
                                                dataSources={dataSources}
                                                storedQueryDetails={e}
                                                isGraph={false}
                                            ></PlaceholderContainer>
                                        ))}
                                    </Tables>


                                    {uploadComponents?.map((ids, index) => (
                                        <AudienceReport key={index}>
                                            {ids.map((id: any) => (
                                                <ARContainer
                                                    key={id.id}
                                                    componentID={id.id}
                                                    oldComponentID={
                                                        null}
                                                    visible={id.visible}
                                                    setUploadComponents={setUploadComponents}
                                                    uploadComponents={uploadComponents}
                                                />
                                            ))}
                                        </AudienceReport>
                                    ))}


                                </> :
                                <StyledGraphContainer expand={0}>

                                    <TxtContainer2 isGraph={false} displayTxt={0}>
                                        <Add isGraph={true} >
                                            <div style={{ paddingTop: "50%" }} className='text'>

                                                <ReactLoading className="loaderCentered" type="spinningBubbles" color={theme.publishButton} />
                                            </div>
                                        </Add>
                                    </TxtContainer2>
                                </StyledGraphContainer>
                            }




                        </MainContainer>
                        :
                        <MainContainer>
                            <>
                                <TVKPIs></TVKPIs>
                                <Graphs
                                    dashboard={"TV"}
                                    columnsNumber={4}
                                >
                                    {publishedQueries.filter(el => el?.position?.startsWith("TV1"))
                                        .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                        .map(e => (
                                            <ClientPlaceholderContainer
                                                key={e.position}
                                                publishedQueriesDetails={e}
                                                componentID={e.position as string}
                                                isGraph={true}
                                            ></ClientPlaceholderContainer>
                                        ))}
                                </Graphs>


                                <Graphs dashboard={"TV"}
                                    columnsNumber={2}>

                                    {publishedQueries.filter(el => el?.position?.startsWith("TV2"))
                                        .sort((a, b) => (Number(a.position) > Number(b.position) ? 1 : -1))
                                        .map(e => (
                                            <ClientPlaceholderContainer
                                                key={e.position}
                                                publishedQueriesDetails={e}
                                                componentID={e.position as string}
                                                isGraph={true}
                                            ></ClientPlaceholderContainer>
                                        ))}
                                </Graphs>

                                <Tables>
                                    {publishedQueries.filter(el => el?.position?.startsWith("TV3")).map(e => (
                                        <ClientPlaceholderContainer
                                            key={e.position}
                                            publishedQueriesDetails={e}
                                            componentID={e.position as string}
                                            isGraph={false}
                                        ></ClientPlaceholderContainer>
                                    ))}
                                </Tables>




                            </>

                            {componentIDs.map((ids, index) => (
                                <AudienceReport key={index}>
                                    {ids.map((id) => (
                                        <ARContainer
                                            key={id}
                                            componentID={id}
                                            oldComponentID={
                                                null}
                                        />
                                    ))}
                                </AudienceReport>
                            ))}

                        </MainContainer>


                    }

                </>
                : <NoCampaignSelected page={"TV"}></NoCampaignSelected>
            }


        </>


    );
};

export default TVmain;
