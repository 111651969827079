import React, { useContext, useEffect, useState } from 'react'
import { Modal } from './AddCampaignModal'
import { HeadModul, ModulContainer, Selections } from 'components/Main/table/TableStyles'
import { IoMdClose } from 'react-icons/io'
import styled, { useTheme } from 'styled-components'
import { FormInputContainer } from './FormInput'
import { /* ClientCodesExtended, */ IconRepository, selectedLink } from './LinksContainer'
import { DarkButton } from 'components/style/Global'
import { ClientCodes, GetPreSignedUrlQuery, S3Method, linkListsResponse, linkResponse } from 'API'
import { FileUploader } from 'react-drag-drop-files'
import { API } from 'aws-amplify'
import { getPreSignedUrl } from 'graphql/queries'
import { useCampaignContext } from 'context/CampaignContext'
import APIContext from 'context/APIContext'
import { v4 as uuidv4 } from 'uuid';
import { useClientContext } from 'context/ClientContext'
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';


export const SingleInsight = styled.a`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 1px solid ${({ theme }) => theme.border};
  font-family: 'FontRegular', sans-serif;
  font-weight: 200;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.placeholderBackground};
  align-items: center;
  padding: 10px;
  color: ${({ theme }) => theme.primaryGray};
  margin-bottom: 5px;


  .ikon {
    font-size: 1.2vw;
  }

  .svg {

    fill: ${({ theme }) => theme.primaryGray};
    stroke-width: 5px;
    width: 100%;

    path {
      stroke: ${({ theme }) => theme.primaryGray};
      stroke-width: 0.1;
    }
  }
  .name {
    grid-column: 2 / span 3;
    font-size: 0.6vw;
  }
  .seeMore {
    /* grid-column: 2 / span 3; */
    font-size: 0.6vw;
    display: none;
  }
`;

type Props = {
    reorderModal: boolean,
    setReorderModal: (arg: boolean) => void,
    linksResponse: linkListsResponse | undefined,
    orderUpdate: (arg: string[]) => void,


}




const ReorderModal = (props: Props) => {

    const { linksResponse, orderUpdate, reorderModal, setReorderModal } = props
    const theme = useTheme();
    const [orderList, setOrderList] = useState<any>([])

    useEffect(() => {
        const order = linksResponse?.order?.map(id => {
            return {
                id: id,
                content: linksResponse?.links?.find(link => link?.Id === id)?.title
            }
        })
        setOrderList(order)
    }, [linksResponse])


    const onDragEnd = (result: any) => {
        const newOrder = Array.from(orderList);
        const [removed] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, removed);
        setOrderList(newOrder)
    };


    const noOrder = () => {
        const order = linksResponse?.order?.map(id => {
            return {
                id: id,
                content: linksResponse?.links?.find(link => link?.Id === id)?.title
            }
        })
        setOrderList(order)
        setReorderModal(false)
    }

    return (
        <Modal >
            <HeadModul style={{ height: "fit-content", maxHeight: "80vh" }}>
                <button type='button' className="x" onClick={() => setReorderModal(false)}> <IoMdClose className='closeIcon' /></button>
                <ModulContainer style={{ padding: "0 100px" }}>
                    <h1>Reorder Links</h1>

                    {linksResponse?.links &&
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {orderList?.map((link: any, index: number) => (
                                            <div style={{ display: link.content === undefined ? "none" : "block" }}>
                                                {/* <span>{index}</span> */}
                                                <Draggable key={link?.id} draggableId={link?.id as string} index={index}  >
                                                    {(provided, snapshot) => (
                                                        <SingleInsight
                                                            onClick={() => console.log("KITA")}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}

                                                            // className="noAccess"
                                                            theme={theme}
                                                            key={link?.id}
                                                        >
                                                            {/* <div className='material-icons svg ikon'>{link?.icon}</div> */}
                                                            <span className="name">{link?.content}</span>
                                                        </SingleInsight>
                                                    )}
                                                </Draggable>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }

                    <div style={{ alignSelf: "flex-end", marginTop: "50px" }} className="btnz">
                        <button
                            // onClick={() => { orderUpdate(orderList.map((e: any) => e.id)); setReorderModal(false) }} 
                            onClick={() => noOrder()}
                            style={{ marginRight: ".3vw" }} type='submit'>Cancel</button>
                        <DarkButton onClick={() => { orderUpdate(orderList.map((e: any) => e.id)); setReorderModal(false) }} >Save</DarkButton>
                    </div>
                </ModulContainer>

            </HeadModul>
        </Modal>
    )
}

export default ReorderModal