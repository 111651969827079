export interface Props {
  className: string;
  height: string;
}

const CampaignManagerLogo = ({ className, height }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 86 82">
    <path fill="#c7e3cf" stroke="none;" d="M52.9 4.1c.151.068.232.083.4.1a1.062 1.062 0 0 0-.4-.1z" strokeWidth={0.1} />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M53.3 4.1c.233.098.446.098.7.1-.233-.098-.446-.098-.7-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#56aa6e"
      stroke="none;"
      d="M54 4.1c.447.188 1.018.1 1.5.1-.447-.188-1.018-.1-1.5-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M55.5 4.1c.205.087.375.095.6.1-.205-.087-.375-.095-.6-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#c7e3cf"
      stroke="none;"
      d="M56.1 4.1c.151.068.232.083.4.1a1.067 1.067 0 0 0-.4-.1m-4.3.1a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M52.167 4.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#56aa6e" stroke="none;" d="M52.3 4.2a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#1e8e3e"
      stroke="none;"
      d="M29.6 27.6c.771-.016 1.524-.13 2.3-.096 4.031.177 8.06 1.283 11.6 3.227 1.636.899 2.983 2.113 4.5 3.169-.186-.491-.505-.916-.713-1.4-.49-1.142-.737-2.358-.683-3.6.2-4.557 4.502-8.164 8.996-7.683 1.341.144 2.718.708 3.8 1.499.549.401.982.918 1.5 1.352.814.681 1.788 1.25 2.8 1.58 3.689 1.204 8.049-.284 10.055-3.648 1.154-1.937 1.429-4.209 1.028-6.4-.474-2.588-2.413-4.429-4.383-6-4.552-3.629-10.123-5.4-15.9-5.4-9.684 0-18.625 5.791-22.669 14.6a23.895 23.895 0 0 0-1.77 5.5c-.206 1.094-.259 2.21-.461 3.3z"
      strokeWidth={0.1}
    />
    <path fill="#56aa6e" stroke="none;" d="M56.8 4.2a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M57.167 4.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#c7e3cf" stroke="none;" d="M57.3 4.2a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="M51.1 4.3a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#56aa6e"
      stroke="none;"
      d="M51.467 4.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M57.867 4.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#9ccdaa"
      stroke="none;"
      d="M58.067 4.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M58.267 4.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#b8dcc2"
      stroke="none;"
      d="M50.467 4.433c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M50.6 4.4a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1m7.9 0a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M58.867 4.433c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#9ccdaa"
      stroke="none;"
      d="M49.967 4.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#56aa6e"
      stroke="none;"
      d="M50.167 4.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M59.267 4.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#c7e3cf" stroke="none;" d="m49.4 4.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M49.567 4.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M59.767 4.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#c7e3cf" stroke="none;" d="m59.9 4.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#80bf92"
      stroke="none;"
      d="M49.067 4.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#72b886" stroke="none;" d="m60.2 4.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m60.3 4.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#72b886"
      stroke="none;"
      d="M48.667 4.833c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#64b17a" stroke="none;" d="m60.6 4.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m60.7 4.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m48.2 4.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m48.3 4.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m61 4.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m61.1 4.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m47.8 5 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m47.9 5 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m61.4 5 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m61.5 5 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m47.5 5.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m47.6 5.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m61.7 5.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m61.8 5.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m47.2 5.2.1.1-.1-.1m14.9 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m46.8 5.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m46.9 5.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m62.4 5.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m62.5 5.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m46.5 5.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m46.6 5.4.1.1-.1-.1m16.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m62.8 5.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m46.3 5.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m63 5.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m46 5.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m63.3 5.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m45.7 5.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m45.8 5.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m63.6 5.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m45.5 5.8.1.1-.1-.1m18.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m63.9 5.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m45.2 5.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m64.1 5.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m45 6 .1.1L45 6m19.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m44.7 6.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m44.8 6.1.1.1-.1-.1m19.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m64.6 6.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m44.5 6.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m64.8 6.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m44.3 6.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m65 6.3.1.1-.1-.1m-20.9.1.1.1-.1-.1m21.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m65.3 6.4.1.1-.1-.1m-21.5.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m43.9 6.5.1.1-.1-.1m21.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m65.5 6.5.1.1-.1-.1m-21.9.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m43.7 6.6.1.1-.1-.1m21.9 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m65.7 6.6.1.1-.1-.1m-22.3.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m43.5 6.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m65.9 6.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m43.2 6.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m43.3 6.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m66.1 6.8.1.1-.1-.1M43 6.9l.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m43.1 6.9.1.1-.1-.1m23.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m66.3 6.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m42.9 7 .1.1-.1-.1m23.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m66.5 7 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m42.7 7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m66.6 7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m42.5 7.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m66.8 7.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m42.3 7.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m67 7.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m42.1 7.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m67.2 7.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m42 7.5.1.1-.1-.1m25.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m41.8 7.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m67.5 7.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m41.6 7.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m67.7 7.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m41.5 7.8.1.1-.1-.1m26.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m41.3 7.9.1.1-.1-.1m26.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m41.1 8 .1.1-.1-.1m27.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m41 8.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m68.3 8.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m40.8 8.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m68.5 8.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m40.7 8.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m68.6 8.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m40.5 8.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m68.8 8.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m40.4 8.5.1.1-.1-.1m28.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m69.1 8.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m40.1 8.7.1.1-.1-.1m29.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m40 8.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m39.8 8.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m69.5 8.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m39.7 9 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m69.6 9 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m39.4 9.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m69.9 9.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m39.3 9.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m70 9.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m39.2 9.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m70.3 9.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m38.9 9.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m70.4 9.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m38.8 9.7.1.1-.1-.1m31.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m38.7 9.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m38.4 10 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m70.9 10 .1.1-.1-.1m-32.6.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m71 10.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m38.2 10.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m71.1 10.2.1.1-.1-.1m-33 .1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m71.2 10.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m71.7 10.7.1.1-.1-.1m-34.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#aad4b6"
      stroke="none;"
      d="m71.8 10.8.1.1-.1-.1m-34.4.1.1.1-.1-.1m34.5 0 .1.1-.1-.1m-34.6.1.1.1-.1-.1M72 11l.1.1-.1-.1m-34.8.1.1.1-.1-.1m34.9 0 .1.1-.1-.1m-35 .1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#80bf92" stroke="none;" d="m72.2 11.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m37 11.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m72.3 11.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.9 11.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m72.4 11.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.8 11.5.1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#5ab272" stroke="none;" d="m72.6 11.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.6 11.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m72.7 11.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.5 11.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m72.8 11.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.4 11.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m72.9 11.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m36.3 12 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m73 12 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m36.2 12.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m73.1 12.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m73.4 12.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#79c68e" stroke="none;" d="m73.5 12.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m35.7 12.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m73.6 12.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m35.6 12.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m73.7 12.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m35.5 12.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#5bb974"
      stroke="none;"
      d="M73.7 12.9c.493 1.354 1.231 2.507 1.296 4 .201 4.588-3.373 8.982-8.096 9.196a8.363 8.363 0 0 1-4-.822c-.654-.316-1.229-.816-1.9-1.074 1.121 1.816 1.942 3.503 1.791 5.7-.28 4.072-4.311 7.887-8.491 7.483-1.44-.139-2.929-.588-4.1-1.459-1.045-.777-1.887-1.834-2.9-2.663-2.021-1.653-4.336-3.081-6.8-3.975-2.301-.834-4.75-1.505-7.2-1.677-10.036-.703-19.896 4.633-24.425 13.691-1.004 2.008-1.857 4.173-2.231 6.4-1.182 7.029.109 14.208 4.419 20 .659.886 1.394 1.677 2.124 2.5.249.28.427.625.718.865 4.046 3.327 10.482 2.679 13.523-1.665a7.137 7.137 0 0 0 1.143-2.6c.389-1.846.037-3.729-.798-5.4-.348-.696-1.074-1.368-1.273-2.1-.8-.564-1.461-1.388-2-2.2-.828-1.249-1.382-2.888-1.382-4.4 0-5.885 6.866-10.014 11.982-7.053.999.578 1.839 1.412 2.5 2.353.836.191 1.703 1.22 2.4 1.737 1.359 1.009 2.863 1.828 4.4 2.532 5.884 2.695 12.94 2.848 19 .626 2.238-.821 4.39-1.954 6.3-3.384 8.447-6.326 11.997-17.317 9.1-27.411a24.348 24.348 0 0 0-3.167-6.8c-.549-.824-1.128-1.807-1.933-2.4z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m73.8 12.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m35.4 13 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m35.2 13.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m35.1 13.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m74.2 13.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m35 13.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m74.3 13.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m34.8 13.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m34.7 13.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m74.6 13.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m34.6 14 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m34.5 14.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m34.4 14.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m74.9 14.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m34.3 14.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m34.2 14.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m34.1 14.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m75.2 14.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m33.9 15 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#39a156"
      stroke="none;"
      d="M74.633 15.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m75.4 15 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m33.7 15.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m75.6 15.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m33.5 15.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m75.8 15.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m33.4 15.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#3da45a" stroke="none;" d="M74.8 15.8v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m33.3 15.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m76 15.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m33.2 16.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m33.1 16.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m33 16.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#2d994c" stroke="none;" d="M74.9 16.4v.9a1.119 1.119 0 0 0 0-.9z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m76.3 16.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m32.9 16.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m32.8 16.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m32.8 16.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m32.7 16.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m76.6 16.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m32.6 17.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m76.7 17.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m32.5 17.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m76.8 17.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m32.4 17.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m76.9 17.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#72b886"
      stroke="none;"
      d="m32.3 17.7.1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m77.4 18.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m31.8 18.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m77.5 18.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m31.7 18.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m77.6 18.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m31.6 19.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m77.7 19.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m31.6 19.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m31.5 19.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m31.4 19.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m77.9 19.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m31.3 19.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#3da45a"
      stroke="none;"
      d="M74.633 19.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m78 19.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m31.3 19.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m31.2 20.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m31.1 20.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m31.1 20.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m78.2 20.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m31 20.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m78.3 20.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m31 20.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m30.9 20.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m78.4 20.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m30.8 21.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M53.567 21.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#9ccdaa"
      stroke="none;"
      d="M53.767 21.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#c7e3cf" stroke="none;" d="M53.9 21.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#fff"
      stroke="none;"
      d="M54.2 21.242c-2.929.385-5.451 1.781-6.768 4.558-1.031 2.176-1.029 4.945.049 7.1 1.463 2.926 4.764 4.761 8.019 4.371 3.328-.398 6.198-2.642 7.01-5.971 1.071-4.385-1.608-8.987-6.11-9.9-.703-.142-1.487-.251-2.2-.158z"
      strokeWidth={0.1}
    />
    <path fill="#c7e3cf" stroke="none;" d="M55.2 21.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M55.567 21.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#47a362"
      stroke="none;"
      d="M55.767 21.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M53.067 21.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034m3.1 0c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#72b886" stroke="none;" d="m52.6 21.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m52.7 21.4.1.1-.1-.1m3.9 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m56.7 21.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m30.7 21.5.1.1-.1-.1m21.6 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m57 21.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m78.6 21.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m30.7 21.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m52 21.6.1.1-.1-.1m5.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m51.7 21.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m57.6 21.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#aad4b6"
      stroke="none;"
      d="m30.6 21.8.1.1-.1-.1m20.9 0 .1.1-.1-.1m6.3 0 .1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M78.733 21.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#64b17a" stroke="none;" d="m30.6 21.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m51.2 21.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m51.3 21.9.1.1-.1-.1m6.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m58.1 21.9.1.1-.1-.1M51 22l.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m51.1 22 .1.1-.1-.1m7.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m58.3 22 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m30.5 22.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m50.8 22.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m50.9 22.1.1.1-.1-.1m7.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m58.5 22.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M78.833 22.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#80bf92" stroke="none;" d="m30.5 22.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m50.7 22.2.1.1-.1-.1m7.9 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m50.5 22.3.1.1-.1-.1m8.3 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m50.3 22.4.1.1-.1-.1m8.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m30.4 22.5.1.1-.1-.1m19.8 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m59.1 22.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M78.933 22.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#64b17a" stroke="none;" d="m30.4 22.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m59.3 22.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m49.9 22.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m59.4 22.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m30.3 22.8.1.1-.1-.1m19.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#a2d7b1"
      stroke="none;"
      d="M79.033 22.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#8ec69e" stroke="none;" d="m30.3 22.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m59.7 22.9.1.1-.1-.1m-29.4.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m49.5 23 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m59.8 23 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m49.4 23.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m59.9 23.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m30.2 23.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m49.3 23.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m60 23.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#a2d7b1"
      stroke="none;"
      d="M79.133 23.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#8ec69e" stroke="none;" d="m30.2 23.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m49.2 23.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m30.2 23.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m60.4 23.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="M79.2 23.6v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#72b886"
      stroke="none;"
      d="M30.133 23.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#aad4b6" stroke="none;" d="m48.8 23.7.1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m48.6 23.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m48.5 24 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m60.8 24 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m30 24.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m60.9 24.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M79.333 24.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M30.033 24.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#9ccdaa" stroke="none;" d="m48.2 24.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m48.1 24.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m61.2 24.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m29.9 24.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="M79.4 24.6v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="M29.9 24.7v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m48 24.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#64b17a" stroke="none;" d="m47.9 24.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="m47.8 25 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#c7e3cf"
      stroke="none;"
      d="M29.833 25.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#56aa6e" stroke="none;" d="m47.7 25.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m61.6 25.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="M79.5 25.1v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m47.7 25.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="M29.8 25.3v.3c.07-.155.07-.145 0-.3m17.8 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M79.533 25.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#80bf92" stroke="none;" d="m47.5 25.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m47.4 25.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#b8dcc2"
      stroke="none;"
      d="M29.733 25.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#3da45a"
      stroke="none;"
      d="M64.467 25.833c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#39a156" stroke="none;" d="M68.2 25.8a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="M79.6 25.8v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m47.3 25.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#80bf92"
      stroke="none;"
      d="M29.733 26.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#3da45a"
      stroke="none;"
      d="M65.5 26c.261.11.516.099.8.1-.261-.11-.516-.099-.8-.1m1.1 0c.261.11.516.099.8.1-.261-.11-.516-.099-.8-.1z"
      strokeWidth={0.1}
    />
    <path fill="#56aa6e" stroke="none;" d="m47.2 26.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m62.1 26.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M79.633 26.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#47a362"
      stroke="none;"
      d="M29.733 26.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#b8dcc2" stroke="none;" d="m47.2 26.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m62.2 26.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m47.1 26.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="M29.6 26.6v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M79.733 26.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#9ccdaa" stroke="none;" d="m47 26.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="M79.7 26.8v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="M29.6 26.9v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M62.433 26.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#80bf92" stroke="none;" d="m46.9 27 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m46.9 27.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M79.7 27.1v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#56aa6e"
      stroke="none;"
      d="M29.633 27.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M62.533 27.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#56aa6e" stroke="none;" d="m46.8 27.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m46.8 27.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#2d994c" stroke="none;" d="M30.2 27.5c.62.26 1.53.1 2.2.1-.62-.26-1.53-.1-2.2-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m46.8 27.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M28.8 27.6a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M29.167 27.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#84ca96" stroke="none;" d="M29.3 27.6a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M27.967 27.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M28.167 27.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M28.367 27.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#39a156"
      stroke="none;"
      d="M34.1 27.7c.151.068.231.083.4.1a1.062 1.062 0 0 0-.4-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M62.633 27.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="M27.3 27.8a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#8ec69e"
      stroke="none;"
      d="M46.733 27.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#d6ecdc" stroke="none;" d="M79.8 27.8v.5c.083-.203.083-.297 0-.5z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M26.767 27.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M26.967 27.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M26.267 28.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M46.733 28.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="M25.7 28.1a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#39a156"
      stroke="none;"
      d="M36.667 28.133c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="M25.3 28.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#3da45a"
      stroke="none;"
      d="M37.067 28.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="M62.7 28.2v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path
      fill="#a2d7b1"
      stroke="none;"
      d="M24.967 28.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#3da45a"
      stroke="none;"
      d="M37.467 28.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M79.8 28.3v2c.209-.497.209-1.503 0-2m-55.233.133c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#64b17a"
      stroke="none;"
      d="M46.633 28.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M24.267 28.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M23.967 28.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#8ec69e" stroke="none;" d="M46.6 28.6v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M62.7 28.6v.6c.095-.229.095-.371 0-.6z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m23 28.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="M46.6 28.9v.8a.963.963 0 0 0 0-.8z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m22.4 29.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m22.2 29.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m21.9 29.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M62.7 29.3v.6c.095-.229.095-.371 0-.6z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m21.4 29.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m21.2 29.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="M46.6 29.7v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m20.7 29.8.1.1-.1-.1m-.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M62.733 29.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066M20.3 30l.1.1-.1-.1m-.2.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#56aa6e"
      stroke="none;"
      d="M46.633 30.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M62.733 30.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m19.9 30.2.1.1-.1-.1m-.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M79.8 30.3v.5c.083-.203.083-.297 0-.5z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m19.5 30.4.1.1-.1-.1m-.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#aad4b6" stroke="none;" d="M46.7 30.5v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M62.633 30.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M62.633 30.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m18.8 30.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m46.7 30.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m18.6 30.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m18.3 31.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#72b886"
      stroke="none;"
      d="M46.833 31.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M62.533 31.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#b7e0c2" stroke="none;" d="m18.1 31.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M79.7 31.2v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m17.8 31.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m46.9 31.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M62.433 31.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M79.733 31.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#d6ecdc" stroke="none;" d="M79.7 31.7v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#9ccdaa" stroke="none;" d="m47 31.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m62.3 31.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m16.9 32 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ec69e" stroke="none;" d="m47.1 32.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m16.6 32.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M79.633 32.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#b8dcc2" stroke="none;" d="m47.2 32.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m47.2 32.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M79.633 32.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m16.2 32.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m47.3 32.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m62 32.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M79.633 32.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m15.8 32.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m47.4 32.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m61.9 32.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#72b886" stroke="none;" d="m47.5 33 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m61.8 33 .1.1-.1-.1m17.7 0v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m15.3 33.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#56aa6e" stroke="none;" d="m47.6 33.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m15.2 33.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b8dcc2" stroke="none;" d="m47.7 33.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m47.8 33.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m61.5 33.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M79.433 33.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m14.7 33.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m14.6 33.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80bf92" stroke="none;" d="m48 33.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m61.3 33.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M79.433 33.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m14.5 33.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c7e3cf" stroke="none;" d="m48.1 33.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m61.1 34.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m61 34.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M79.333 34.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m60.6 34.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M79.233 34.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="m60.5 34.8.1.1-.1-.1m-.1.1.1.1-.1-.1m-.1.1.1.1-.1-.1m-.1.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M79.133 35.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m60.1 35.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M79.033 35.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m59.6 35.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m12.5 35.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m59.2 35.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M78.933 35.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m12.4 36 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m12.3 36.1.1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m50.6 36.2.1.1-.1-.1m8.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m50.8 36.3.1.1-.1-.1m7.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m78.8 36.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m58.1 36.5.1.1-.1-.1m-6.7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m57.9 36.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M78.733 36.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m11.8 36.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m51.6 36.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m11.7 36.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m57.4 36.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M57.167 36.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M52.567 37.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M56.867 37.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M52.8 37.1a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1m3.567.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m11.4 37.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="M53.4 37.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M55.667 37.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="M55.8 37.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M54 37.3c.151.068.232.083.4.1a1.067 1.067 0 0 0-.4-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M54.4 37.3c.177.078.304.091.5.1-.177-.078-.304-.091-.5-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M54.9 37.3c.177.078.304.091.5.1-.177-.078-.304-.091-.5-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m11.1 37.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m78.4 37.6.1.1-.1-.1m-67.4.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M78.333 37.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m10.8 38 .1.1-.1-.1m67.4.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m78 38.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m10.3 38.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m77.9 38.9.1.1-.1-.1m-67.8.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m77.8 39.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m77.7 39.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m9.8 39.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m77.6 39.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="m9.5 40 .1.1-.1-.1m67.9 0 .1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m9.2 40.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="m77.1 40.6.1.1-.1-.1m-68 .1.1.1-.1-.1m67.9.1.1.1-.1-.1m-68 .1.1.1-.1-.1m67.9.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m8.9 41.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m76.8 41.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="m8.8 41.3.1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1m67.9 0 .1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m8.5 41.9.1.1-.1-.1m67.9 0 .1.1-.1-.1m-68 .2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m8.3 42.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m76.1 42.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m75.9 42.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m8 43 .1.1L8 43z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m7.9 43.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m7.8 43.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m75.3 43.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m75.1 43.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m7.5 44.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m74.8 44.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m7.4 44.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M30.6 44.6a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M30.9 44.6c.261.11.516.099.8.1-.261-.11-.516-.099-.8-.1z"
      strokeWidth={0.1}
    />
    <path fill="#84ca96" stroke="none;" d="M31.7 44.6a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M30.067 44.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#fff"
      stroke="none;"
      d="M30.2 44.744c-1.284.201-2.53.649-3.6 1.4-2.9 2.036-4.097 5.803-2.991 9.156.436 1.321 1.222 2.41 2.182 3.396A6.935 6.935 0 0 0 29 60.571a8.573 8.573 0 0 0 3.3.186c1.229-.176 2.361-.582 3.4-1.265 4.819-3.168 4.555-10.689-.3-13.686a7.55 7.55 0 0 0-2.6-.98c-.83-.155-1.761-.213-2.6-.082z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M32.367 44.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#8ecf9f" stroke="none;" d="M32.5 44.7a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m74.5 44.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M29.567 44.833c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="M32.9 44.8a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M7.333 44.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M29.167 44.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M33.367 44.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#b7e0c2" stroke="none;" d="m33.7 45 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m34 45.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M7.233 45.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m28.2 45.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m74.1 45.2.1.1-.1-.1m-46.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m74 45.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m27.8 45.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m34.7 45.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#a2d7b1"
      stroke="none;"
      d="M7.133 45.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m27.6 45.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m34.9 45.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m27.4 45.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m35.1 45.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m27.2 45.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m35.4 45.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m73.6 45.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M7.033 45.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m73.5 45.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m35.7 46 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m73.4 46 .1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.933 46.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066M36 46.2l.1.1-.1-.1m-9.7.1.1.1-.1-.1m-19.467.367c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m25.8 46.7.1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m25.6 46.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M6.733 47.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m25.5 47 .1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m25.3 47.2.1.1-.1-.1m-.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.633 47.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m24.8 47.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m37.5 47.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m24.7 47.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#34a853"
      stroke="none;"
      d="M37.7 47.9c.19.502.538.916.768 1.4.575 1.211.887 2.559.828 3.9-.194 4.397-4.348 7.996-8.696 7.691-1.605-.112-2.76-.946-4.2-1.491 1.002 1.516 2.095 3.02 2.283 4.9.474 4.736-3.627 8.579-8.183 8.891-1.59.109-3.177-.26-4.6-.972-.593-.296-1.1-.752-1.7-1.019.54.939 1.827 1.669 2.7 2.286 2.917 2.059 6.383 3.439 9.9 4.022 8.239 1.365 16.927-1.22 22.691-7.408a26.382 26.382 0 0 0 4.678-7c1.253-2.736 1.895-5.714 2.131-8.7-1.154 0-2.351.092-3.5-.017-3.64-.346-7.079-1.367-10.3-3.114-1.13-.613-2.183-1.35-3.2-2.134-.525-.405-1-.955-1.6-1.235z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m71.5 47.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.533 48.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m71.4 48 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m71.3 48.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M6.533 48.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m24.5 48.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m37.8 48.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m71.2 48.2.1.1-.1-.1m-64.8.3v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m24.3 48.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m38 48.5.1.1-.1-.1m32.7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m70.6 48.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#79c68e"
      stroke="none;"
      d="M6.433 48.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#73c388" stroke="none;" d="m38.2 48.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m24 49 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m38.3 49 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m70.2 49 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m70.1 49.1.1.1-.1-.1m-63.8.1v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m23.9 49.2.1.1-.1-.1m14.5 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m38.5 49.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m69.7 49.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M6.333 49.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#bfe4c9" stroke="none;" d="m38.6 49.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m69.3 49.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M23.633 49.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m38.7 49.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m69 49.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M6.233 50.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m68.7 50.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.233 50.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M23.533 50.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a6d9b3" stroke="none;" d="m38.8 50.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m68.4 50.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M6.2 50.4v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M23.433 50.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m38.9 50.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m68.1 50.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m67.8 50.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="M39 50.8v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m67.6 50.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M23.333 50.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m67.3 51 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M6.1 51.1v.5c.083-.203.083-.297 0-.5z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m67.1 51.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="m66.9 51.2.1.1-.1-.1m-27.767.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M23.233 51.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#d9efdf"
      stroke="none;"
      d="M39.133 51.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m66.4 51.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="M6.1 51.6v.9a1.119 1.119 0 0 0 0-.9z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m66.2 51.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M23.233 51.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#b7e0c2" stroke="none;" d="m66 51.7.1.1-.1-.1m-.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M39.233 51.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m65.6 51.9.1.1-.1-.1m-.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="M39.2 52.1v.5c.083-.203.083-.297 0-.5m25.7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M23.1 52.3v.8a.963.963 0 0 0 0-.8z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m64.7 52.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#84ca96" stroke="none;" d="M6.1 52.5v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m64.2 52.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#cce9d4" stroke="none;" d="M39.2 52.6v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m64 52.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m63.7 52.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="M6.1 52.9v1a1.285 1.285 0 0 0 0-1m57.1 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="M39.2 53v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m62.9 53 .1.1-.1-.1m-.3.1.1.1-.1-.1m-.3.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M62.067 53.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#d6ecdc" stroke="none;" d="M23.2 53.4v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#65bd7c" stroke="none;" d="M39.2 53.4v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M61.667 53.433c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M61.367 53.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M60.967 53.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="M23.2 53.7v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M60.567 53.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#b7e0c2" stroke="none;" d="M60 53.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#d6ecdc" stroke="none;" d="M6.1 53.9v.4c.071-.176.071-.224 0-.4z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="M39.1 53.9v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M59.567 53.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="M58.9 54a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="M58.3 54.1a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M23.333 54.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M57.567 54.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#b7e0c2"
      stroke="none;"
      d="M57.767 54.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M57.967 54.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M56.3 54.3c.151.068.232.083.4.1a1.067 1.067 0 0 0-.4-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M56.767 54.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#d6ecdc" stroke="none;" d="M56.9 54.3a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#80c893"
      stroke="none;"
      d="M39.033 54.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M23.433 54.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#84ca96" stroke="none;" d="M6.2 54.7v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#65bd7c" stroke="none;" d="M56.2 54.8v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m38.9 54.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.233 55.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M56.233 55.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#d6ecdc" stroke="none;" d="M6.2 55.2v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m38.8 55.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m38.8 55.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#cce9d4" stroke="none;" d="M56.2 55.3v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m23.6 55.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m38.7 55.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m23.7 55.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m38.7 55.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#84ca96"
      stroke="none;"
      d="M6.333 55.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m38.6 55.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M56.133 55.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#addcb9"
      stroke="none;"
      d="M6.333 55.967c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m23.8 55.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m38.5 56 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M56.133 56.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M6.333 56.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m23.9 56.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d9efdf"
      stroke="none;"
      d="M56.133 56.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#addcb9" stroke="none;" d="m24 56.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="M6.4 56.5v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m24.1 56.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M56.033 56.567c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m24.2 56.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="M56 56.7v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m38.1 56.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m24.4 57 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m38 57 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="M6.5 57.1v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m24.6 57.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m37.8 57.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#bfe4c9"
      stroke="none;"
      d="M55.933 57.367c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="M6.6 57.6v.3c.07-.155.07-.145 0-.3z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m24.8 57.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m37.6 57.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m24.9 57.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m37.5 57.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#bfe4c9"
      stroke="none;"
      d="M55.833 57.867c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M6.733 58.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m25.3 58.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m37.1 58.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M55.733 58.267c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066M25.4 58.3l.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#80c893" stroke="none;" d="m37 58.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m25.5 58.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m36.9 58.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m25.6 58.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m36.8 58.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="M6.833 58.667c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m25.7 58.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m36.7 58.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m25.8 58.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m36.6 58.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#bfe4c9"
      stroke="none;"
      d="M55.633 58.767c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m25.9 58.8.1.1-.1-.1m.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M6.933 59.067c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#c1e5cb" stroke="none;" d="m26.2 59.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m36.1 59.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M55.533 59.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#a1d7af" stroke="none;" d="m26.3 59.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m36 59.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#d6ecdc"
      stroke="none;"
      d="M7.033 59.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m35.7 59.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#a6d9b3"
      stroke="none;"
      d="M55.433 59.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#8ecf9f" stroke="none;" d="m26.7 59.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m35.4 59.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m27 59.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m35.2 59.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m27.2 59.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m55.3 59.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m27.4 59.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m34.9 59.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m55.3 59.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m27.6 60 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m34.7 60 .1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#e1f2e6"
      stroke="none;"
      d="M7.233 60.167c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m27.8 60.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m34.5 60.1.1.1-.1-.1m20.7 0 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m28 60.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m34.2 60.2.1.1-.1-.1m21 0 .1.1-.1-.1m-26.9.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m34 60.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m7.3 60.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m28.5 60.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m28.6 60.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m33.7 60.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m28.8 60.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#8ecf9f"
      stroke="none;"
      d="M33.367 60.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a6d9b3" stroke="none;" d="m55.1 60.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#b3debe"
      stroke="none;"
      d="M29.267 60.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M32.967 60.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m7.4 60.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="M29.7 60.7a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#bfe4c9"
      stroke="none;"
      d="M32.367 60.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#73c388"
      stroke="none;"
      d="M32.567 60.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M30.267 60.833c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M30.4 60.8c.151.068.232.083.4.1a1.062 1.062 0 0 0-.4-.1z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M30.8 60.8c.261.11.517.099.8.1-.261-.11-.516-.099-.8-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="M31.6 60.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M31.967 60.833c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a6d9b3" stroke="none;" d="m55 60.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m7.5 61 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m54.9 61.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m54.8 61.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m54.8 61.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m7.7 61.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m54.7 61.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m7.8 61.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m54.6 61.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m54.5 62.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m8 62.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m54.4 62.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m54.3 62.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m8.2 62.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m8.3 63 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m54.1 63.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m8.4 63.2.1.1-.1-.1m45.6.1.1.1-.1-.1m-.1.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m53.8 63.7.1.1-.1-.1m-.1.2.1.1-.1-.1m-.1.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m53.5 64.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="m53.4 64.5.1.1-.1-.1m-44.3.1.1.1-.1-.1m44.2.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#a2d7b1" stroke="none;" d="m9.2 64.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m53.2 64.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m9.3 65 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m53 65.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m52.9 65.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m9.6 65.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m52.8 65.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m9.7 65.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m52.6 65.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m9.9 66 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m52.4 66.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a2d7b1" stroke="none;" d="m10.1 66.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m52.3 66.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m52.2 66.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m52.1 66.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m52 66.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m10.5 66.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m51.9 67 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m51.8 67.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m10.7 67.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m51.6 67.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m10.9 67.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m11 67.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m51.4 67.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m51.3 67.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m11.2 67.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m11.3 68 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m51.1 68.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m51 68.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m50.9 68.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b7e0c2" stroke="none;" d="m11.6 68.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m11.7 68.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m50.7 68.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m50.6 68.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m50.5 68.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#c1e5cb" stroke="none;" d="m12 68.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#addcb9" stroke="none;" d="m12.1 69 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m12.2 69.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m50.2 69.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m50.1 69.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m50 69.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m49.9 69.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m49.8 69.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#c1e5cb"
      stroke="none;"
      d="m12.7 69.7.1.1-.1-.1m.1.1.1.1-.1-.1m.1.1.1.1-.1-.1m.1.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m13.1 70.1.1.1-.1-.1m.1.1.1.1-.1-.1m.1.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#80c893"
      stroke="none;"
      d="M13.433 70.467c-.005.005-.011.111.034.066.005-.005.011-.111-.034-.066z"
      strokeWidth={0.1}
    />
    <path fill="#b3debe" stroke="none;" d="m14.3 71.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m48 71.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m14.4 71.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m47.9 71.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m47.8 71.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m47.7 71.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m14.9 71.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m15 72 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m15.1 72.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m47.2 72.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m47.1 72.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m15.5 72.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m15.6 72.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m46.7 72.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m46.6 72.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m16 72.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m16.1 72.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m46.2 72.9.1.1-.1-.1m-29.8.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m45.9 73.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m45.8 73.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m16.7 73.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m16.8 73.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m45.5 73.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m17.1 73.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m45.2 73.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m17.4 73.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m44.9 73.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m17.6 73.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m17.7 74 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m44.6 74 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m17.9 74.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m44.3 74.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m18.2 74.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m44.1 74.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m18.4 74.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m43.8 74.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m18.7 74.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m43.6 74.6.1.1-.1-.1m-24.7.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m43.4 74.7.1.1-.1-.1m-24.3.1.1.1-.1-.1m.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#b3debe"
      stroke="none;"
      d="m42.9 75 .1.1-.1-.1m-.2.1.1.1-.1-.1m-.2.1.1.1-.1-.1m-.2.1.1.1-.1-.1m-.2.1.1.1-.1-.1z"
      strokeWidth={0.1}
    />
    <path fill="#80c893" stroke="none;" d="m20.5 75.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m41.9 75.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m20.7 75.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m20.9 75.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m41.4 75.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m21.1 75.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m41.2 75.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m21.4 75.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m41 75.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m21.6 76 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m40.7 76 .1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m21.8 76.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m21.9 76.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m40.5 76.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m22.1 76.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m40.2 76.2.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m22.4 76.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m39.9 76.3.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m40 76.3.1.1-.1-.1m-17.4.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m22.7 76.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m39.7 76.4.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m22.9 76.5.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m39.4 76.5.1.1-.1-.1m-16.2.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#8ecf9f" stroke="none;" d="m39.1 76.6.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#b3debe" stroke="none;" d="m23.5 76.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m23.6 76.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="m38.8 76.7.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#bfe4c9" stroke="none;" d="m23.8 76.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#80c893" stroke="none;" d="m23.9 76.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="m38.4 76.8.1.1-.1-.1z" strokeWidth={0.1} />
    <path fill="#98d3a8" stroke="none;" d="m24.2 76.9.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#bfe4c9"
      stroke="none;"
      d="M38.167 76.933c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#80c893"
      stroke="none;"
      d="M24.567 77.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#a6d9b3"
      stroke="none;"
      d="M37.767 77.033c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#73c388"
      stroke="none;"
      d="M24.967 77.133c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="m37.4 77.1.1.1-.1-.1z" strokeWidth={0.1} />
    <path
      fill="#80c893"
      stroke="none;"
      d="M25.367 77.233c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#a6d9b3" stroke="none;" d="M36.9 77.2a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M25.767 77.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#b3debe"
      stroke="none;"
      d="M36.567 77.333c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#8ecf9f" stroke="none;" d="M26.2 77.4a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#a6d9b3" stroke="none;" d="M36 77.4a.702.702 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M26.767 77.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#59b873"
      stroke="none;"
      d="M26.967 77.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#b3debe"
      stroke="none;"
      d="M35.567 77.533c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M27.267 77.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path fill="#80c893" stroke="none;" d="M27.4 77.6a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path fill="#73c388" stroke="none;" d="M34.7 77.6a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M35.067 77.633c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034m-7.1.1c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M28.167 77.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M28.367 77.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034m5.6 0c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#98d3a8"
      stroke="none;"
      d="M34.167 77.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034z"
      strokeWidth={0.1}
    />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M34.367 77.733c-.045.045.061.039.066.034.045-.045-.061-.039-.066-.034M28.8 77.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="M29.1 77.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#65bd7c"
      stroke="none;"
      d="M29.4 77.8c.151.068.232.083.4.1a1.062 1.062 0 0 0-.4-.1m3.2 0c.151.068.231.083.4.1a1.062 1.062 0 0 0-.4-.1z"
      strokeWidth={0.1}
    />
    <path fill="#98d3a8" stroke="none;" d="M33 77.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1z" strokeWidth={0.1} />
    <path
      fill="#cce9d4"
      stroke="none;"
      d="M33.3 77.8a.716.716 0 0 0 .3.1.716.716 0 0 0-.3-.1m-2.9.1c.472.198 1.091.1 1.6.1-.472-.198-1.091-.1-1.6-.1z"
      strokeWidth={0.1}
    />
  </svg>
);

export default CampaignManagerLogo;
