import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from "highcharts/highcharts-3d";
import { queryInput } from 'API';

import styled, { useTheme } from 'styled-components';
import { useCampaignContext } from 'context/CampaignContext';
import RangeSlider from 'react-range-slider-input';
import "react-range-slider-input/dist/style.css";
import CategoriesPlugin from "highcharts-grouped-categories"
import { PRINT11 } from 'components/Main/graf/PRINT1.1';
import { OOH11 } from 'components/Main/graf/OOH1.1';
import PreviewModal from 'components/Main/table/PreviewModal';
import { useHTML2Canvas } from 'customHooks/useHTML2Canvas';
import InputContext from 'context/InputContext';
CategoriesPlugin(Highcharts);






Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x: any, y: any, w: any, h: any) {
    return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
};

highcharts3d(Highcharts);

type Props = {
    data: any;
    componentID: any,
    page: any;
    title: any;
    setTitle(title: any): any
    currentQuery: any;
    currentConversionsQuery: any;
    conversionsData: any;
    chartMetrics: any;
    setChartMetrics(chartMetric: any): any;


};





const GraphsContainer = styled.div`
  input {
font-size: 12px;
padding: 8px;
border-radius: 7px;
width: 20vw;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.primaryGray};
}

select {
    border-radius: 7px;
    padding: 3px;
        font-family: 'FontRegular', sans-serif;
}
option {
    font-size: 14px;
    padding: 3px;
    font-family: 'FontRegular', sans-serif;
}
label {
    color: ${({ theme }) => theme.primaryGray};
}
h1 {
    font-family: "FontRegular";
    font-size: 1.4vw;
}

.sliderContainer{
    width: 10vw;
    .valuesDisplay{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: .5vw;
    }
    h1 {
font-size:10px;
color: ${({ theme }) => theme.secondaryGray};
    }
    .sliderStyle {
    background: ${({ theme }) => theme.secondaryGray};
}
.sliderStyle .range-slider__range  {
    background: ${({ theme }) => theme.c400};
}
.sliderStyle .range-slider__thumb {
  background: ${({ theme }) => theme.c400};
  width: 15px;
  height: 15px;
}
}
`

function OOHChartsContainer2(props: Props) {


    const theme = useTheme()
    const tempDataCopy = JSON.parse(JSON.stringify(props.data))
    const mostkeys = tempDataCopy.sort(
        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
    )[0];
    const [TV11dropdown, setTV11dropdown] = useState("price_per_TRP")
    const { TVStationSelector } = useCampaignContext();
    const [options, setOptions] = useState<any>()


    const { capture, updatePPT } = useContext(InputContext)

    const date1 = useMemo(() => new Date(props.data[0].date), [props.data]);
    const date2 = new Date(props.data[props.data.length - 1].date);
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // go through props.data and create an array of all the dates between oldest and newest date
    const dateArray = useMemo(() => {
        const dates = [];
        if (diffDays > 0) {
            for (let i = 0; i < diffDays; i++) {
                const date = new Date(props.data[0].date);
                date.setDate(date.getDate() + i);
                dates.push(date.toISOString().slice(0, 10));
            }
        } else {
            dates.push(date1.toISOString().slice(0, 10));
        }
        return dates;
    }, [diffDays, props.data, date1]); // Add dependencies here


    const [value, setValue] = useState(diffDays > 30 ?
        [diffDays - 30, diffDays - 1] : [0, diffDays === 0 ? 0 : diffDays - 1])



    useEffect(() => {
        if (props.componentID === "OOH1.1") {
            const sorted = props.data.sort((a: any, b: any) => {
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            });
            setOptions(OOH11(sorted, theme, value, dateArray))
        } else if (props.componentID === 'Print1.1') {
            const sorted = props.data.sort((a: any, b: any) => {
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            });
            setOptions(PRINT11(sorted, theme, value, dateArray))
        } else if (props.componentID === "Cinema1.1") {

            const sorted = props.data.sort((a: any, b: any) => {
                if (a.date < b.date) {
                    return -1;
                }
                if (a.date > b.date) {
                    return 1;
                }
                return 0;
            });
            setOptions(OOH11(sorted, theme, value, dateArray))
        }

    }, [props.chartMetrics, TV11dropdown, TVStationSelector, props.componentID, props.data, value, theme, dateArray])









    const [edit, setEdit] = useState(false)

    const handleSave = () => {
        setEdit(!edit)
    }



    const addDays = (date: any, days: any) => {
        const result = new Date(date);
        result.setDate(result.getDate() - days);
        return result.toISOString().slice(0, 10);
    }

    const removeDays = (date: any, days: any) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result.toISOString().slice(0, 10);
    }
    const chartRef: any = useRef();
    const returnObject = useHTML2Canvas(capture, chartRef.current, props.componentID as string, props.title, "")
    useEffect(() => {
        if (returnObject) {
            // updatePDFelements(returnObject);

            const tobeReturned = {
                ...returnObject,
                theData: options.series,
                categories: options.xAxis.categories,

            }

            updatePPT(tobeReturned);
        }
    }, [returnObject])


    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    return (
        <GraphsContainer style={{ width: "90vw" }} className='graphsContainer'>
            {diffDays > 30 && <div className='sliderContainer'>
                <div className='valuesDisplay'>
                    <h1>{addDays(dateArray[value[0]], 4)} </h1>
                    <h1> {removeDays(dateArray[value[1]], 4)}</h1>
                </div>

                <RangeSlider className="sliderStyle" min={0} max={dateArray.length - 1} value={value} onInput={setValue} />

            </div>}



            <div ref={chartRef} style={{ width: "100%" }}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} {...props} allowChartUpdate={true} />
            </div>






        </GraphsContainer >
    );
}

export default OOHChartsContainer2;