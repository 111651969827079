import React from 'react'
import { HeadModul, ModulContainer } from 'components/Main/table/TableStyles'
import { Modal } from './NewPortal/Header/AddCampaignModal'
import { IoMdClose } from 'react-icons/io'
import { DarkButton } from './style/Global'

export type modalObject = {
    title: string
    message: string
    mainButton: string | undefined
    secondaryButton: string | undefined
}

type Props = {
    handleDeleteConfirmationModal: (a: boolean) => void
    modalObject: modalObject
}

const DeleteConfirmationModal = (props: Props) => {
    const { handleDeleteConfirmationModal, modalObject } = props
    return (
        <Modal >
            <HeadModul style={{ height: "fit-content" }}>
                <button type='button' className="x" onClick={() => handleDeleteConfirmationModal(false)}> <IoMdClose className='closeIcon' /></button>
                <ModulContainer>
                    <h1>{modalObject.title}</h1>
                    <ModulContainer>
                        <p>{modalObject.message}</p>
                    </ModulContainer>

                    <div className="buttonsContainer" style={{ alignSelf: "center" }}>
                        {props.modalObject.secondaryButton && <button style={{ marginRight: ".4vw" }} type="button" onClick={() => handleDeleteConfirmationModal(false)}>{props.modalObject.secondaryButton}</button>}
                        {props.modalObject.mainButton && <DarkButton type="button" onClick={() => handleDeleteConfirmationModal(true)}>{props.modalObject.mainButton}</DarkButton>}
                    </div>
                </ModulContainer>
            </HeadModul>
        </Modal>
    )
}

export default DeleteConfirmationModal