import React, { useEffect, useState } from 'react';
import { EditedList, FooterTotal } from '../PlanItem';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { Environment } from 'API';
import { IActiveOrganization } from 'context/ClientContext';
import { useUpdateRow } from '../useFetchPlanData';
// import { Portal } from "react-overlays";

type InputProps = {
  value: string;
  column: any;
  setValue: (value: React.SetStateAction<string>) => void;
  setEditedList: (value: React.SetStateAction<EditedList>) => void;
  setFooterTotalList?: React.Dispatch<React.SetStateAction<any[]>>;
  row: any;
  type: string;
  data?: any[];
  calculateFooterTotal?: (data: any) => FooterTotal[] | undefined;
  makeTotal?: (updatedData: any[], test: any[]) => void;
  originalInput?: (originalText: any, key: any, rowId: string, original: any) => any[];
  getEnvironment?: Environment;
  activeCampaign?: ACdetails;
  activeOrganization?: IActiveOrganization;
  setIsRowAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<any[]>>;
  updateData?: (rowId: string, editedColumn: string, newValue: string) => void;
  setError?: React.Dispatch<React.SetStateAction<string | null>>;
  error?: string | null;
};

const InputComponent: React.FC<InputProps> = (props) => {
  const { activeCampaign } = useCampaignContext();
  const {
    value,
    column,
    setValue,
    setEditedList,
    row,
    type,
    data,
    makeTotal,
    originalInput,
    getEnvironment,
    activeOrganization,
    setData,
    setIsRowAdded,
    updateData,
    setError,
    error,
  } = props;
  const getEnv = getEnvironment ? getEnvironment : Environment.PRODUCTION;
  const actCamp: ACdetails | any = activeCampaign ? activeCampaign : {};
  const actOrg: IActiveOrganization | any = activeOrganization ? activeOrganization : { OrganizationId: '' };
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const [callUpdateApi] = useUpdateRow(getEnv, actCamp, actOrg);

  const formatValue = (value: string, type: string) => {
    if (type === 'date') {
      console.log('This is the date for the date input', value);

      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return '01-01-2000';
      } else {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        console.log(`${year}-${month}-${day}`);
        return `${year}-${month}-${day}`;
      }
    } else {
      return value;
    }
  };

  const [localValue, setLocalValue] = useState(formatValue(value, type));
  const [prevLocalValue, setPrevLocalValue] = useState(props.value);

  const updateRowAndCalculateTotal = (event: any, test: any[] | undefined) => {
    if (type === 'number' && data !== undefined) {
      const updatedRow = {
        ...row,
        [column.accessorKey]: event.target.value,
      };

      if (test !== undefined) {
        makeTotal !== undefined && makeTotal(updatedRow, test);
      }
    }
  };
  console.log('row', row);
  const updateTheRow = async (value: string, editedColumn: string, rowId: string) => {
    setIsRowAdded !== undefined && setIsRowAdded(true);

    let newColumn = [];

    if (value !== '') {
      if (type === 'number') {
        const newValue = {
          RowId: rowId,
          ColumnId: editedColumn,
          Value: {
            StringValue: value,
          },
        };

        newColumn.push(newValue);
      } else if (type === 'date') {
        const newValue = {
          RowId: rowId,
          ColumnId: editedColumn,
          Value: {
            StringValue: new Date(value),
          },
        };
        newColumn.push(newValue);
      }
    }

    const updateResult = await callUpdateApi(newColumn);
    if (updateResult === null) {
      setError !== undefined && setError('There has been an error on the server, please try again later');
    } else if (updateResult === 'updated') {
      // setError(null);
      // setEditButton(!EditButton);
    } else {
      // setError(updateResult);
    }

    setPrevLocalValue(localValue);
  };

  return (
    <div>
      {type === 'date' ? (
        <DatePicker
          // portalId="calendar-portal"

          className="datepicker"
          popperPlacement="top"
          popperProps={{
            strategy: 'fixed',
          }}
          dateFormat="dd-MM-yyyy"
          selected={localValue === column.header ? new Date() : new Date(localValue)}
          calendarStartDay={1}
          onChange={(e: any) => {
            const newValue = new Date(e).toISOString();
            setValue(newValue);
            const test =
              originalInput !== undefined ? originalInput(newValue, column.accessorKey, row.rowId, data) : undefined;
            console.log('This is the test', newValue);
            setLocalValue(newValue);
            const rowId = row['rowId'];
            const editedColumn = column.accessorKey as string;
            setData !== undefined &&
              setData((prevData) => {
                const updatedData = prevData.map((item) => {
                  if (item.rowId === rowId) {
                    return {
                      ...item,
                      [editedColumn]: newValue,
                    };
                  }
                  return item;
                });

                return updatedData;
              });
            updateRowAndCalculateTotal(e, test);

            if (timeoutId) {
              clearTimeout(timeoutId);
            }

            // Set a new timeout
            const newTimeoutId = setTimeout(() => {
              const editedColumn = column.accessorKey as string;
              const rowId = row['rowId'];

              if (newValue !== prevLocalValue) {
                updateTheRow(newValue, editedColumn, rowId);
              }
            }, 0);

            setTimeoutId(newTimeoutId);
          }}
          minDate={new Date(activeCampaign?.startDate as string)}
          maxDate={new Date(activeCampaign?.endDate as string)}
          onBlur={() => {
            const editedColumn = column.accessorKey as string;
            const rowId = row['rowId'];

            setEditedList((prev) => ({
              ...prev,
              [rowId]: {
                ...prev[rowId],
                [editedColumn]: localValue,
                rowId: rowId,
              },
            }));

            if (localValue !== prevLocalValue) {
              updateTheRow(localValue, editedColumn, rowId);
            }
          }}
        />
      ) : (
        <input
          type={type}
          value={localValue}
          style={{
            marginLeft: '6px',
            width: '110px',
          }}
          onClick={(event: any) => {
            const target = event.target as HTMLInputElement;
            if (localValue === column.header) {
              target.select();
            }
          }}
          onInput={(event: any) => {
            const newValue = event.target.value;
            const editedColumn = column.accessorKey as string;
            const rowId = row['rowId'];

            updateData !== undefined && updateData(rowId, editedColumn, newValue);
            if (newValue !== localValue) {
              setValue(newValue);
              setLocalValue(newValue);

              if (timeoutId) {
                clearTimeout(timeoutId);
              }

              const newTimeoutId = setTimeout(() => {
                updateTheRow(newValue, editedColumn, rowId);
              }, 3000);

              setTimeoutId(newTimeoutId);
            }

            if (localValue === column.header) {
              setValue('');
            }
          }}
          onChange={(event: any) => {
            const newValue = event.target.value;
            const editedColumn = column.accessorKey as string;
            const rowId = row['rowId'];

            updateData !== undefined && updateData(rowId, editedColumn, newValue);
          }}
          // onChange={(event: any) => {
          //   const newValue = event.target.value;
          //   setValue(newValue);
          //   const editedColumn = column.accessorKey as string;
          //   const rowId = row['rowId'];
          //   const test =
          //     originalInput !== undefined ? originalInput(newValue, column.accessorKey, row.rowId, data) : undefined;
          //   setLocalValue(newValue);
          //   setData !== undefined && setData((prevData) => {
          //     const updatedData = prevData.map((item) => {
          //       if (item.rowId === rowId) {
          //         return {
          //           ...item,
          //           [editedColumn]: newValue,
          //         };
          //       }
          //       return item;
          //     });

          //     return updatedData;
          //   });
          // }}
          onBlur={(event: any) => {
            const newValue = event.target.value;
            const editedColumn = column.accessorKey as string;
            const rowId = row['rowId'];

            if (newValue.trim() !== '') {
              setEditedList((prev) => ({
                ...prev,
                [rowId]: {
                  ...prev[rowId],
                  [editedColumn]: newValue,
                  rowId: rowId,
                },
              }));
            }

            if (newValue !== prevLocalValue) {
              updateTheRow(newValue, editedColumn, rowId);
            }
          }}
        />
      )}
    </div>
  );
};

export default InputComponent;
