import styled from 'styled-components';

interface ACProps {
  showModal: Boolean;
  showUploadModal: Boolean;
}

export const AudienceReportContainer = styled.div<ACProps>`
 // width: 20%;
  width: 100%;
  height: 20vh;
  background-color: ${({ theme }) => theme.placeholderBackground};
  margin: 2px 10px 10px 10px;
  border-radius: 7px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .drop_area {
    background-color: pink;
  }
  .drop_zone {
    background-color: pink;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    
  }

  img {
    cursor: zoom-in;
  }

  .imageThumbnail {
    width: 100% !important;
    height: 20vh;
    object-fit: cover;
    border-radius: 7px;
    opacity: 0.5;

  }
  .buttonsContainer {
    display: flex;
    position: absolute;
    width: 100% !important;
    height: 20%;
    bottom: 0;
    flex-direction: row;
    gap: 0.3vw;
    justify-content: center;
    //   background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;

  }
/*   .btn {
    pointer-events: all;
  } */
  .editBtn{
    background-color: ${({ theme }) => theme.placeholderBackground};
  }
  .imageThumbnail:hover {
    opacity: 1;
  }
  .buttonsContainer:hover  {
    opacity: 1;
  }
  .imageThumbnail:hover + .buttonsContainer {
    opacity: 1;
  }

  .addCaption {
    width: 550px;
    border-radius: 20px;
    margin: 0.5vh 0;
    padding: 0.3vh;
    font-size: 0.8vw;
  }


  .modal {
    display: ${(props) => (props.showModal ? 'block' : 'none')}; /* Hidden by default */
  }

  .modalUpload {
    display: ${(props) => (props.showUploadModal ? 'block' : 'none')}; /* Hidden by default */
  }
  .myModal {
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
  }

  .headModal {
    position: absolute;
  z-index: 100;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }
  .modalOverride {
    position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }

  /* Modal Content (image) */
  .modal-content {
    margin: auto;
    display: block;
    max-height: 90vh;
    max-width: 90vw;
    min-width: 400px;
    max-width: 90vw;
    border-radius:  7px 7px 0 0;

  }
  .modal-content-upload {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    height: 500px;
    border: 1px dashed ${({ theme }) => theme.secondaryGray};;
    border-radius: 9px;
  }
  .modalPreview {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 500px;
    max-width: 800px;
    height: fit-content;

  }
  .imageUploader {
    display: block;
    max-width: 700px;
    text-align: center;
    border-radius: 5px;

    img, video {
      width: 100%;
      border-radius: 5px;
    }
  }

  textarea {
    min-height: 10vh;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color:${({ theme }) => theme.background};
    width: 80%;
    resize: none;
    padding: 8px;
    margin: 0.5vh 0 2vh 0;
    font-size: 14px;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
    display: block;
    width: 100%;
    max-width: 700px;
  }

  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.primaryGray};
}

  /* Caption of Modal Image */
  .caption {
    margin: auto;
    display: block;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.primaryGray};
    padding: 10px 0;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 7px 7px;
    background-color: ${({ theme }) => theme.border};

  }

  /* Add Animation */
  .modal {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }
    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  /* The Close Button */
  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: ${({ theme }) => theme.primaryGray};
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

`;
