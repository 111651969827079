import { DashboardTypes, agencyEnum, countryEnum, datatype, detailedDimensionsDetail, filterDetail, filtertype, localQuery, metric, metricFunction, queryInput } from 'API';
import { Kpis, TxtContainer } from 'components/Main/Main.styles';
import { useClientContext } from 'context/ClientContext';
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { KPIholder } from './TV.styles';

import { useCampaignContext } from 'context/CampaignContext';
import { TVJSON, useDataQuery } from 'customHooks/useDataQuery';
import { useTheme } from 'styled-components';
// import { useActiveCampaign } from 'customHooks/useActiveCampaign';
import AuthContext from 'context/AuthContext';
import { PublishContext } from 'context/PublishContext';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import { useTemplateContext } from 'context/TemplateContext';

type Props = {}

const TVKPIs = (props: Props) => {
    const { REACT_APP_AGENCY } = process.env;
    const { user } = useContext(AuthContext);
    const { publishedData, updateOLArray } = useContext(PublishContext);
    const { updateTemplatePlaceholderList } = useTemplateContext()
    const { activeOrganization, organizationList } = useClientContext();
    const { campaignList, activeSubCampaign, activeCampaign } = useCampaignContext()
    const theme = useTheme()
    const [query, setQuery] = useState<queryInput>()

    // const [activeCamp] = useActiveCampaign({ campaignList: campaignList, orgList: organizationList })
    const activeCamp = activeCampaign
    const activeSubCamp = activeSubCampaign
    const username = user?.username;
    const Organization = activeOrganization?.Organization;
    const ClientCountry = activeCamp?.ClientCountry;


    const publishedKPI = publishedData?.find((element: any) => element.position === "TV0")
    const publishedOutputLocation = publishedKPI?.outputlocation


    useEffect(() => {
        if (activeSubCamp && Object.keys(activeSubCamp).length > 0 && activeCamp?.authorized === true) {
            //METRICS DATA
            const metrics: metric[] = [
                {
                    column: "budget_incl_fee_per_spot",
                    name: "budget_incl_fee_per_spot",
                    aggregation: metricFunction.SUM,
                    id: "1"
                },
                {
                    column: "planned_budget_incl_fee_per_spot",
                    name: "planned_budget_incl_fee_per_spot",
                    aggregation: metricFunction.SUM,
                    id: "2"
                },
                {
                    column: "planned_primary_trps",
                    name: "planned_primary_trps",
                    aggregation: metricFunction.SUM,
                    id: "3"
                },
                {
                    column: "trp_primary_target",
                    name: "trp_primary_target",
                    aggregation: metricFunction.SUM,
                    id: "4"
                },
                {
                    column: "sub_rf_1_primary_target",
                    name: "rf_1_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "5"
                },
                {
                    column: "planned_reach",
                    name: "planned_reach",
                    aggregation: metricFunction.MAX,
                    id: "6"
                },
                {
                    column: "effective_frequency",
                    name: "effective_frequency",
                    aggregation: metricFunction.MAX,
                    id: "7"
                },
                {
                    column: "planned_effective_reach",
                    name: "planned_effective_reach",
                    aggregation: metricFunction.MAX,
                    id: "8"
                },
                {
                    column: "sub_rf_2_primary_target",
                    name: "rf_2_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "9"
                }, {
                    column: "sub_rf_3_primary_target",
                    name: "rf_3_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "10"
                }, {
                    column: "sub_rf_4_primary_target",
                    name: "rf_4_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "11"
                }, {
                    column: "sub_rf_5_primary_target",
                    name: "rf_5_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "12"
                }, {
                    column: "sub_rf_6_primary_target",
                    name: "rf_6_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "13"
                }, {
                    column: "sub_rf_7_primary_target",
                    name: "rf_7_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "14"
                }, {
                    column: "sub_rf_8_primary_target",
                    name: "rf_8_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "15"
                }, {
                    column: "sub_rf_9_primary_target",
                    name: "rf_9_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "16"
                }, {
                    column: "sub_rf_10_primary_target",
                    name: "rf_10_primary_target",
                    aggregation: metricFunction.MAX,
                    id: "17"
                }, {
                    column: "date",
                    name: "Startdate",
                    aggregation: metricFunction.MIN,
                    decimals: -1,
                    id: "18"
                }, {
                    column: "date",
                    name: "Enddate",
                    aggregation: metricFunction.MAX,
                    decimals: -1,
                    id: "19"
                }, {
                    column: "universe_primary_target",
                    name: "universe_primary_target",
                    aggregation: metricFunction.AVG,
                    id: "20"
                },
            ];

            // FILTER(CAMPAIGN DATA)

            const filter: filterDetail = {
                column: 'subcampaign_id',
                filter: {
                    filtertype: filtertype.EQUAL,
                    datatype: datatype.NUMBER,
                    value: activeSubCamp?.subcampaignId?.toString(),
                },

            };


            // DATE DATA


            const localQuery: localQuery = {
                filters: [filter],
                metrics: metrics,
                // dimensions: ['subcampaign_id', "primary_target_group"],
                detailedDimensions: [{
                    column: "primary_target_group",
                    name: "primary_target_group",
                    hidden: false,
                    id: "21"
                }, {
                    column: "subcampaign_id",
                    name: "subcampaign_id",
                    hidden: false,
                    id: "22"
                }],
                preview: 100,
                orderBy: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22"],
            };
            const newQuery: queryInput = {
                query: localQuery,
                views: [
                    {
                        viewName:
                            Organization === 'DEMO-Organization' ? 'fozzie_callisto_tv_demo' : `fozzie_callisto_tv_${REACT_APP_AGENCY}`,
                        database: ClientCountry === "DK" ? "data_lake_denmark" : ClientCountry === "SE" ? "data_lake_sweden" :
                            ClientCountry === "FI" ? "data_lake_finland" : "data_lake_norway",
                    },
                    {
                        viewName:
                            Organization === 'DEMO-Organization' ? 'fozzie_callisto_planned_tv_demo' : `fozzie_callisto_planned_tv_${REACT_APP_AGENCY}`,
                        database: ClientCountry === "DK" ? "data_lake_denmark" : ClientCountry === "SE" ? "data_lake_sweden" :
                            ClientCountry === "FI" ? "data_lake_finland" : "data_lake_norway",
                    }

                ],
                jobnumber: 'ANNA0027',
                clients: [{
                    country: countryEnum[activeCamp?.ClientCountry as keyof typeof countryEnum],
                    client: activeCamp?.ClientCode as string
                }],
                date: { from: getStartDate(), to: getEndDate() },
                user: {
                    name: username,
                    agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
                    country: countryEnum.DK,
                },
            };
            setQuery(newQuery)
        }
    }, [ClientCountry, REACT_APP_AGENCY, activeCamp, Organization, username, activeSubCamp])

    const [data, queryStatus, error, queryId, outputLocation] = useDataQuery({ query: query, querySource: 'TV KPI', publishedOutputLocation: publishedOutputLocation })

    const jsonDataObject = data as TVJSON
    const trp_primary_target = jsonDataObject ? jsonDataObject.trp_primary_target : NaN
    const budget_incl_fee_per_spot = jsonDataObject ? jsonDataObject.budget_incl_fee_per_spot : NaN
    const effective_frequency = jsonDataObject ? jsonDataObject.effective_frequency : NaN
    const planned_budget_incl_fee_per_spot = jsonDataObject ? jsonDataObject.planned_budget_incl_fee_per_spot : NaN
    const planned_effective_reach = jsonDataObject ? jsonDataObject.planned_effective_reach : NaN
    const planned_primary_trps = jsonDataObject ? jsonDataObject.planned_primary_trps : NaN
    const planned_reach = jsonDataObject ? jsonDataObject.planned_reach : NaN
    const rf_1_primary_target = jsonDataObject ? jsonDataObject.rf_1_primary_target : NaN
    const effective_frequencyN = jsonDataObject ? jsonDataObject[`rf_${jsonDataObject.effective_frequency}_primary_target` as keyof typeof jsonDataObject] as number : NaN
    /*     const primary_target_group = jsonDataObject ? jsonDataObject.primary_target_group : NaN
        const universe_primary_target = jsonDataObject ? jsonDataObject.universe_primary_target : NaN
        const Enddate = jsonDataObject ? jsonDataObject.Enddate : NaN
        const Startdate = jsonDataObject ? jsonDataObject.Startdate : NaN */

    /*     useEffect(() => {
            if (queryId) {
                const a = {
                    Placeholder: 'TV0',
                    Location: queryId,
                    Placeholder_titel: ""
                }
                updateTemplatePlaceholderList(a)
            }
        }, [queryId]) */



    useEffect(() => {
        if (outputLocation) {
            const placeholdersObject = {
                position: 'TV0',
                outputlocation: outputLocation,
            };
            //  console.log("placeholdersObject", placeholdersObject)
            updateOLArray(placeholdersObject);
        }
    }, [outputLocation, updateOLArray])




    return (
        <>
            {queryStatus === "FAILED" ? <Kpis>
                <KPIholder style={{ padding: "1vw" }}>
                    <p>Something went wrong, please reload the page and try again </p>
                </KPIholder>
            </Kpis> :
                <Kpis>
                    <KPIholder>
                        {queryStatus === "RUNNING" ?
                            (<TxtContainer className='loader'>
                                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                            </TxtContainer>) :
                            (<TxtContainer>
                                <h1>Budget</h1>
                                <h2>Realized</h2>
                                <div className='valueFlex'>
                                    <h4 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(budget_incl_fee_per_spot)}
                                        <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(budget_incl_fee_per_spot)}</span>
                                    </h4>
                                    <div className='plan'>
                                        <h2>Planned</h2>
                                        <h2 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_budget_incl_fee_per_spot)}
                                            <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(planned_budget_incl_fee_per_spot)}</span>
                                        </h2>
                                    </div>
                                </div>
                            </TxtContainer>)}
                    </KPIholder>

                    <KPIholder>
                        {queryStatus === "RUNNING" ?
                            <TxtContainer className='loader'>
                                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                            </TxtContainer> :
                            <TxtContainer>
                                <h1>TRP</h1>
                                <h2>Realized</h2>
                                <div className='valueFlex'>
                                    <h4 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(trp_primary_target)}
                                        <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(trp_primary_target)}</span>
                                    </h4>
                                    <div className='plan'>
                                        <h2>Planned</h2>
                                        <h2 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_primary_trps)}
                                            <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_primary_trps)}</span>
                                        </h2>
                                    </div>
                                </div>

                            </TxtContainer>}
                    </KPIholder>

                    <KPIholder>
                        {queryStatus === "RUNNING" ?
                            <TxtContainer className='loader'>
                                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                            </TxtContainer> :
                            <TxtContainer>
                                <h1>Reach</h1>
                                <h2>1+</h2>
                                <div className='valueFlex'>
                                    <h4 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(rf_1_primary_target) + "%"}
                                        <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(rf_1_primary_target) + "%"}</span>
                                    </h4>
                                    <div className='plan'>
                                        <h2>Planned</h2>
                                        <h2 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_reach) + "%"}
                                            <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_reach) + "%"}</span>
                                        </h2>
                                    </div>
                                </div>

                            </TxtContainer>}
                    </KPIholder>

                    <KPIholder>
                        {queryStatus === "RUNNING" ?
                            <TxtContainer className='loader'>
                                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                            </TxtContainer> :
                            <TxtContainer>
                                <h1>Effective Reach</h1>
                                <h2>{effective_frequency}+</h2>
                                <div className='valueFlex'>
                                    <h4 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(effective_frequencyN) + "%"}
                                        <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(effective_frequencyN) + "%"}</span>
                                    </h4>
                                    <div className='plan'>
                                        <h2>Planned</h2>
                                        <h2 className='tooltip'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_effective_reach) + "%"}
                                            <span className='tooltiptext'>{Intl.NumberFormat('en-DK', { notation: 'compact' }).format(planned_effective_reach) + "%"}</span>
                                        </h2>
                                    </div>
                                </div>
                            </TxtContainer>}
                    </KPIholder>

                </Kpis>}
        </>

    )
}

export default TVKPIs