import { useEffect, useState } from "react";
import omdlogo from "../assets/lightomd.png"
import phdlogo from "../assets/ppt_assets/PHD_pptlogo.png"
import hslogo from "../assets/lightheartScienceNoBckg.png"
import omdpptbackground from "../assets/ppt_assets/templateImage.png"
import phdpptbackground from "../assets/ppt_assets/PHD_PPTbackground2.png"
import hscpptbackground from "../assets/ppt_assets/HSC_background.png"






export const useSlideDefinition = (agency: string): readonly [any] => {
    const { REACT_APP_AGENCY } = process.env;
    const logo = REACT_APP_AGENCY === "hs" ? hslogo : REACT_APP_AGENCY === "phd" ? phdlogo : omdlogo
    const [backgroundImage, setbackgroundImage] = useState<string>('');

    const convertToBase64 = async (imageUrl: string, setBase64String: (base64: string) => void) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    const base64 = reader.result.replace('data:', '').replace(/^.+,/, '');
                    setBase64String(base64);
                } else {
                    console.error('Error: reader.result is not a string');
                }
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error converting image to base64:', error);
        }
    };
    useEffect(() => {
        const img = REACT_APP_AGENCY === "omd" ? omdpptbackground : REACT_APP_AGENCY === "phd" ? phdpptbackground : hscpptbackground
        convertToBase64(img, setbackgroundImage);
    }, [])

    const masterSlides = [
        {
            agency: "omd",
            titleSlide: {
                title: "MASTER_SLIDE",
                objects: [
                    { image: { x: 0, y: 0, w: "100%", h: "100%", data: `image/png;base64,${backgroundImage}` } },
                    {
                        placeholder: {
                            options: {
                                name: "date", type: "title", x: 0.2,
                                y: 0,
                                w: "50%",
                                h: 0.75,
                                fontFace: "Arial",
                                color: '#ffffff',
                                fontSize: 12,
                                align: "left",
                                valign: "middle",
                                margin: 0,
                            },
                            text: "Date goes here"
                        }
                    },
                    {
                        placeholder: {
                            options: {
                                type: "pic",
                                name: "logo",
                                x: 0.2, y: 4, w: 1, h: 1,
                            },


                        }
                    },

                    {
                        placeholder: {
                            text: "Title goes here",
                            options: {
                                name: "title",
                                type: "title",
                                x: 0.2,
                                y: 6,
                                w: "50%",
                                h: 0.75,
                                fontFace: "Arial",
                                color: "ffffff",
                                bold: true,
                                fontSize: 54,
                                align: "left",
                                valign: "bottom",
                                margin: 0,
                            },
                        },
                    },
                    {
                        placeholder: {
                            text: "Subtitle goes here",
                            options: {
                                name: "subtitle",
                                type: "title",
                                x: 0.2,
                                y: 7,
                                w: "50%",
                                h: 0.25,
                                fontFace: "Arial",
                                color: "ffffff",
                                // bold: true,
                                fontSize: 14,
                                align: "left",
                                valign: "bottom",
                                margin: 0,
                            },
                        },
                    },
                ],
            },
            tableSlide: {
                title: 'MASTER_SLIDE_TABLE',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [
                    { rect: { x: 0, y: "100%", w: '100%', h: 0.1, fill: { color: '#bc2424' } } },
                    { image: { x: 12.7, y: 0, w: 0.4, h: 0.4, data: logo } },
                    { placeholder: { options: { name: 'title', type: 'title', x: 0.5, y: 0.2, w: 12, h: 0.6, color: '#bc2424', fontSize: 28, bold: true, fontFace: "Arial", align: "left" }, text: '' } },
                    { placeholder: { options: { name: 'comment', type: "body", x: 0.5, y: 6, w: 12, h: 0.5, fontSize: 12, color: '#8b8b8b', }, text: '' } },
                ],
            },
            chartSlide: {
                title: 'MASTER_SLIDE_CHART',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [
                    { rect: { x: 0, y: "100%", w: '100%', h: 0.1, fill: { color: '#bc2424' } } },
                    { image: { x: 12.7, y: 0, w: 0.4, h: 0.4, data: logo } },
                    { placeholder: { options: { name: 'title', type: 'title', x: 0.5, y: 0.2, w: 12, h: 0.6, color: '#bc2424', fontSize: 28, bold: true, fontFace: "Arial", align: "left" }, text: '' } },
                    { placeholder: { options: { name: 'comment', type: "body", x: 9, y: 1.5, w: 3, h: 4.25, fontSize: 12, color: '#8b8b8b', }, text: '' } },
                ],
            }
        },
        {
            agency: "phd",
            titleSlide: {
                title: "MASTER_SLIDE",
                objects: [
                    { image: { x: 0, y: 0, w: "100%", h: "100%", data: `image/png;base64,${backgroundImage}` } },

                    {
                        placeholder: {
                            text: "Title goes here",
                            options: {
                                name: "title",
                                type: "title",
                                x: "10%",
                                y: "40%",
                                w: "80%",
                                h: "19%",
                                fontFace: "Arial",
                                color: "ffffff",
                                bold: true,
                                fontSize: 50,
                                align: "center",
                                valign: "middle",
                                margin: 0,
                            },
                        },
                    },
                    {
                        placeholder: {
                            options: {
                                name: "date", type: "title",
                                x: "47%",
                                y: "62%",
                                w: "5%",
                                h: "5%",
                                fontFace: "Arial",
                                color: '#ffffff',
                                fontSize: 9,
                                align: "center",
                                valign: "middle",
                                margin: 0,
                            },
                            text: "2024"
                        }
                    },



                ],
            },
            tableSlide: {
                title: 'MASTER_SLIDE_TABLE',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [


                    { line: { x: 0, y: 0.3, w: '100%', h: 0, line: { color: "#CCCCCC", line_size: 0.5 } } },
                    { line: { x: 0, y: "96%", w: '100%', h: 0, line: { color: "#CCCCCC", line_size: 0.5 } } },
                    { image: { x: 12.7, y: 0.05, w: 0.4, h: 0.15, data: logo } },
                    { placeholder: { options: { name: 'title', type: 'title', x: "2%", y: "9%", w: "26%", h: "8%", color: '#3A008C', fontSize: 22, bold: false, fontFace: "Arial", align: "left" }, text: '' } },
                    // { placeholder: { options: { name: 'comment', type: "body", x: 9, y: 1.5, w: 3, h: 4.25, fontSize: 12, color: '#8b8b8b', }, text: '' } },

                    { text: { options: { x: "2%", y: "96%", w: "16%", h: "3%", color: '#BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: '2024 PHD' } },
                    { text: { options: { x: "32%", y: "96%", w: "46%", h: "3%", color: '#BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: 'Confidential - Not for Public Consumption or Distribution' } },
                ],
            },
            chartSlide: {
                title: 'MASTER_SLIDE_CHART',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [
                    { rect: { x: 0, y: 0, w: '30%', h: "100%", fill: { color: '#ffffff' } } },
                    { rect: { x: "30%", y: 0, w: '70%', h: "100%", fill: { color: '#F7F7F6' } } },

                    { line: { x: 0, y: 0.3, w: '100%', h: 0, line: { color: "#CCCCCC", line_size: 0.5 } } },
                    { line: { x: 0, y: "96%", w: '100%', h: 0, line: { color: "#CCCCCC", line_size: 0.5 } } },

                    { image: { x: 12.7, y: 0.05, w: 0.4, h: 0.15, data: logo } },
                    { placeholder: { options: { name: 'title', type: 'title', x: "2%", y: "9%", w: "26%", h: "8%", color: '#3A008C', fontSize: 22, bold: false, fontFace: "Arial", align: "left" }, text: '' } },
                    { placeholder: { options: { name: 'comment', type: "body", x: "2%", y: "20%", w: "26%", h: "70%", fontSize: 12, color: '#8b8b8b', fill: { color: "#ffffff" } }, text: '' } },


                    { text: { options: { x: "2%", y: "96%", w: "16%", h: "3%", color: '##BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: '2024 PHD' } },
                    { text: { options: { x: "32%", y: "96%", w: "46%", h: "3%", color: '#BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: 'Confidential - Not for Public Consumption or Distribution' } },
                ],
            }
        },
        {
            agency: "hs",
            titleSlide: {
                title: "MASTER_SLIDE",
                objects: [
                    { image: { x: 0, y: 0, w: "100%", h: "100%", data: `image/png;base64,${backgroundImage}` } },
                    { rect: { x: "5%", y: "50%", w: '95%', h: 0.2, fill: { color: '#ffffff' } } },

                    {
                        placeholder: {
                            text: "Title goes here",
                            options: {
                                name: "title",
                                type: "title",
                                x: "5%",
                                y: "60%",
                                w: "75%",
                                h: 1.5,
                                fontFace: "Arial",
                                color: "ffffff",
                                bold: true,
                                fontSize: 54,
                                align: "left",
                                valign: "top",
                                margin: 0,
                            },
                        },
                    },
                    {
                        placeholder: {
                            text: "Subtitle goes here",
                            options: {
                                name: "subtitle",
                                type: "title",
                                x: "5%",
                                y: "85%",
                                w: "75%",
                                h: 0.25,
                                fontFace: "Arial",
                                color: "ffffff",
                                // bold: true,
                                fontSize: 14,
                                align: "left",
                                valign: "bottom",
                                margin: 0,
                            },
                        },
                    },
                ],
            },
            tableSlide: {
                title: 'MASTER_SLIDE_TABLE',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [
                    // { rect: { x: 0, y: 0, w: '5%', h: "100%", fill: { color: '#1A2E33' } } },
                    { rect: { x: "5%", y: 0, w: '95%', h: "1%", fill: { color: '#497F93' } } },
                    { image: { x: 12.7, y: 0.05, w: 0.4, h: 0.15, data: logo } },
                    { placeholder: { options: { name: 'title', type: 'title', x: "7%", y: "9%", w: "26%", h: "8%", color: '#1A2E33', fontSize: 22, bold: false, fontFace: "Arial", align: "left" }, text: '' } },
                    { text: { options: { x: "32%", y: "96%", w: "46%", h: "3%", color: '#BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: 'Confidential - Not for Public Consumption or Distribution' } },
                ],
            },
            chartSlide: {
                title: 'MASTER_SLIDE_CHART',
                margin: [0.5, 0.25, 1.00, 0.25],
                bkgd: 'FFFFFF',
                objects: [
                    { rect: { x: 0, y: 0, w: '5%', h: "100%", fill: { color: '#1A2E33' } } },
                    { rect: { x: "5%", y: 0, w: '95%', h: "1%", fill: { color: '#497F93' } } },
                    { image: { x: 12.7, y: 0.05, w: 0.4, h: 0.15, data: logo } },

                    { placeholder: { options: { name: 'title', type: 'title', x: "7%", y: "9%", w: "26%", h: "8%", color: '#1A2E33', fontSize: 22, bold: false, fontFace: "Arial", align: "left" }, text: '' } },
                    { placeholder: { options: { name: 'comment', type: "body", x: "7%", y: "20%", w: "26%", h: "70%", fontSize: 12, color: '#8b8b8b', fill: { color: "#ffffff" } }, text: '' } },
                    { text: { options: { x: "32%", y: "96%", w: "46%", h: "3%", color: '#BFBFBF', fontSize: 6, bold: false, fontFace: "Arial", align: "left" }, text: 'Confidential - Not for Public Consumption or Distribution' } },
                ],
            }

        },
    ]


    const findMasterSlide = (agency: string) => {
        const masterSlide = masterSlides.find((slide) => slide.agency === agency)
        if (masterSlide) {
            return masterSlide
        }
        return masterSlides[0]
    }

    return [findMasterSlide(agency)]
}
