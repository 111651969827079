// BUILD A CUSTOM HOOK THAT
// 1. checks if the campaign has a plan
// 2. checks if the campaign has a brief
// returns an object with the following properties:
// - hasPlan: boolean
// - hasBrief: boolean

import { GetBrief_v2Query, GetDetailedPlanningQuery, ValueResponse, briefTemplateSection, detailedPlanningResponse } from "API";
import { API } from "aws-amplify";
import APIContext from "context/APIContext";
import { ACdetails } from "context/CampaignContext";
import { useClientContext } from "context/ClientContext";
import { getBrief_v2, getDetailedPlanning } from "graphql/queries";
import { useContext, useEffect, useState } from "react";

export type rollbackStatus = {
    hasPlan: boolean;
    hasBrief: boolean;
}


export const useCheckRollbackStatus = (
    activeCampaign: ACdetails | undefined,
): readonly [rollbackStatus | undefined] => {
    const { activeOrganization } = useClientContext()
    const { getEnvironment } = useContext(APIContext);
    const [rollbackStatus, setRollbackStatus] = useState<rollbackStatus>()

    const callPlanApi = async () => {
        const response = (await API.graphql({
            query: getDetailedPlanning,
            variables: {
                // Environment: getEnvironment ,
                Environment: 'PRODUCTION',
                CampaignId: activeCampaign?.campaignId,
                OrganizationId: activeOrganization?.OrganizationId,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetDetailedPlanningQuery };


        const rows = !response.data.getDetailedPlanning?.rows || response.data.getDetailedPlanning?.rows.length === 0 ? false : true;
        getBriefNEW(rows)
    };

    const getBriefNEW = async (rows: any) => {
        try {
            const response = (await API.graphql({
                query: getBrief_v2,
                variables: {
                    Environment: getEnvironment,
                    CampaignId: activeCampaign?.campaignId,
                    OrganizationId: activeOrganization?.OrganizationId,
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetBrief_v2Query };

            const brief = response.data.getBrief_v2?.sections as briefTemplateSection[];
            const a = brief
                .map((section) => {
                    const c = section.columns
                        ?.map((column) => {
                            if (column?.subColumns && column?.subColumns?.length > 0) {
                                const s = column.subColumns
                                    ?.map((subColumn) => {
                                        // if any property inside the value object is different from null return true
                                        const value = subColumn?.value as ValueResponse;
                                        const { __typename, ...rest } = value;
                                        const valueIsNotNull = Object.values(rest).some((value) => value !== null);
                                        return valueIsNotNull;
                                    })
                                    .some((value) => value === true);
                                return s;
                            } else {
                                const value = column?.value as ValueResponse;
                                const { __typename, ...rest } = value;
                                const valueIsNotNull = Object.values(rest).some((value) => value !== null);
                                return valueIsNotNull;
                            }
                        })
                        .some((value) => value === true);
                    return c;
                })
                .some((value) => value === true);
            const res = {
                hasPlan: rows,
                hasBrief: a
            }

            setRollbackStatus(res)


        } catch (e) {
            console.log(`ERROR FETCHING THE NEW BRIEF WAS THIS:`, e);
        }
    };



    useEffect(() => {
        if (activeCampaign) {
            callPlanApi()
        }
    }, [activeCampaign])



    return [rollbackStatus] as const;
}