const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


export function TV11(data: any, TV11dropdown: any, theme: any) {

    const theData = [] as any;
    const theAxis = [] as any;
    const categ = [] as any;
    const tempDataCopy = JSON.parse(JSON.stringify(data))
    const mostkeys = tempDataCopy.sort(
        (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
    )[0];


    const lookupTable = ["Bauer Media National", "DRR", "TV 2", "TV 2 News", "Viaplay Group", "Discovery", "Discovery Networks", 'Warner Bros Discovery']
    data.sort((a: any, b: any) => lookupTable.indexOf(a.network) - lookupTable.indexOf(b.network))


    theData.push({
        data: data.map((e: any) => TV11dropdown === "price_per_TRP" ? e.actual_price_per_TRP : TV11dropdown === "budgets" ? e.budget_incl_fee_per_spot : e.trp_primary_target),
        color: "#698296",
        name: "Actual",
        type: "column",


    }, {
        marker: {
            symbol: 'c-rect',
            lineWidth: 3,
            lineColor: theme.brand,
            radius: 0.9 * (100 / (data.length)),
        },
        enableMouseTracking: false,
        type: "scatter",
        data: data.map((e: any) => TV11dropdown === "price_per_TRP" ? e.planned_price_per_TRP : TV11dropdown === "budgets" ? e.planned_budget_incl_fee_per_spot : e.planned_primary_trps),
        name: "Planned",
    })
    categ.push(...data.map((e: any) => e.network))


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, [key, value]] of Object.entries(mostkeys).entries()) {
        if (key === "network") {
            const yaxis = {
                min: 0,
                labels: {
                    format: '{value}',
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false
                },
                title: {
                    text: key,
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false,
                },
                gridLineWidth: 0,

            };
            theAxis.push(yaxis);
        }
    }




    return {
        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            style: {
                fontFamily: "fontRegular"
            },

        },
        title: {
            text: "Station Split",
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw",

            }
        },
        /*  subtitle: {
             useHTML: true,
             text: '<select>Default Option</select>',
             style: {
                 color: theme.secondaryGray,
                 fontFamily: "fontRegular",
                 fontSize: "12px"
             }
         }, */
        yAxis:
            theAxis,
        xAxis: {
            categories: categ,
            //  crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },

        },
        tooltip: {
            //followPointer: true,
            shared: true,
            formatter: function (this: any) {

                var ret = this.points[0].series.name + ": " + Intl.NumberFormat('en-DK').format(Math.round(this.y)) + '<br/>',
                    x = this.x,
                    name = this.points[0].series.chart.series[1].name,
                    points = this.points[0].series.chart.series[1].points;

                points.forEach((v: any, i: any) => {
                    if (v.category === x) {
                        ret += name + ': ' + Intl.NumberFormat('en-DK').format(Math.round(v.y)) + '<br/>';
                    }
                });

                return ret;
            }

        },

        plotOptions: {
            column: {
                pointPadding: 0.1,
                distance: -60,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: Math.trunc(this.y).toString().length > 4 ? 'compact' : "standard" }).format(Math.round(this.y))
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent'
                    }
                }
            },
            /*           series: {
                          stacking: "normal"
                      } */
        },
        series: theData,
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {

                return this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },

    }
}