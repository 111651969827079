import styled from 'styled-components';

const StyledSVG = styled.svg`
  .cls-1 {
    fill: #fcf5f9;
  }
  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12,
  .cls-13,
  .cls-14,
  .cls-15 {
    stroke-width: 0px;
  }
  .cls-2 {
    fill: #a05893;
  }
  .cls-3 {
    fill: #f6dfec;
  }
  .cls-4 {
    fill: #c065ae;
  }
  .cls-5 {
    fill: #000;
    opacity: 0.15;
  }
  .cls-6 {
    fill: #f7f4f9;
    opacity: 0.8;
  }
  .cls-7,
  .cls-13 {
    fill: #d39fcb;
  }
  .cls-8 {
    fill: #844b7b;
  }
  .cls-16,
  .cls-13 {
    opacity: 0.3;
  }
  .cls-9 {
    fill: #6350a4;
  }
  .cls-10 {
    fill: #432c49;
  }
  .cls-11 {
    fill: #6d3d65;
  }
  .cls-12 {
    fill: #fff;
  }
  .cls-14 {
    fill: #ebbdd8;
  }
  .cls-15 {
    fill: #7d4372;
  }
`;

const DIGITAL_HS_3D = () => {
  return (
    <StyledSVG id="Newspaper" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 751.83 797.4">
      <defs />
      <path
        className="cls-8"
        d="M420.04,724.81l73.61-42.56c7.59-4.38,11.38-10.12,11.38-15.86,0-5.74.03-10.24.03-10.24l-69.27-39.31c-4.05-2.34-7.33-8.02-7.33-12.69v-90.75l-128.57,74.29v90.75c0,4.67,3.28,10.36,7.32,12.69l57.86,33.69c15.18,8.76,39.78,8.76,54.96,0Z"
      />
      <path
        className="cls-11"
        d="M330.77,694.5v10.19l-23.56-13.58c-4.02-2.33-7.32-8-7.32-12.69v-90.74l24.49-14.13c.51,6.22,1.06,12.65,1.56,18.91l-17.18,9.94v70.9c0,4.7,3.26,10.36,7.32,12.69l14.68,8.5Z"
      />
      <path
        className="cls-11"
        d="M308.77,673.33c0,4.67,3.28,10.36,7.33,12.69l48.98,28.56c15.18,8.76,39.78,8.76,54.96,0l73.65-42.56c15.18-8.76,15.18-22.96,0-31.72l-49.02-28.58c-4.05-2.34-7.33-8.02-7.33-12.69v-70.91s-128.57,74.29-128.57,74.29v70.92Z"
      />
      <path
        className="cls-5"
        d="M459.59,620.35h0s-14.92-8.63-14.92-8.63c-4.05-2.34-7.33-8.02-7.33-12.69v-70.91l-128.57,74.29v70.92c0,4.67,3.28,10.36,7.33,12.69l14.67,8.47,128.83-74.14Z"
      />
      <path
        className="cls-15"
        d="M505.05,656.15v10.24c0,5.75-3.81,11.46-11.38,15.86l-73.62,42.54c-7.28,4.23-16.71,6.35-26.23,6.52v-10.2c9.52-.13,18.95-2.33,26.23-6.51l73.62-42.58c7.57-4.36,11.38-10.11,11.38-15.86Z"
      />
      <path
        className="cls-11"
        d="M102.33,771.23c4.75,2.74,12.69,2.59,17.75-.33,63.21-36.49,434.02-250.58,497.22-287.07,5.06-2.92,9.16-10.02,9.16-15.86V32.76c0-5.84-3.85-12.8-8.59-15.54-4.75-2.74-12.69-2.59-17.75.33L102.9,304.62c-5.06,2.92-9.16,10.02-9.16,15.86v435.21c0,5.84,3.84,12.8,8.59,15.54Z"
      />
      <path
        className="cls-15"
        d="M120.08,770.89l497.22-287.07c5.06-2.92,9.16-10.02,9.16-15.86V32.75c0-5.84-4.1-8.21-9.16-5.29L120.09,314.53c-5.06,2.92-9.16,10.02-9.16,15.86v435.21c0,5.84,4.09,8.21,9.15,5.29Z"
      />
      <path
        className="cls-4"
        d="M367.75,609.75c-1,0-1.93-.24-2.76-.72-1.96-1.13-3.04-3.43-3.04-6.46,0-5.9,4-12.84,9.11-15.79h0c2.63-1.52,5.16-1.73,7.12-.6,1.96,1.13,3.04,3.43,3.04,6.47,0,5.9-4,12.84-9.11,15.79-1.52.88-3,1.32-4.36,1.32ZM375.43,587.55c-.99,0-2.12.35-3.31,1.04h0c-4.45,2.57-8.07,8.84-8.07,13.98,0,2.26.71,3.92,2,4.66,1.29.74,3.08.53,5.04-.6,4.45-2.57,8.07-8.84,8.07-13.98,0-2.26-.71-3.92-2-4.66-.5-.29-1.08-.43-1.72-.43Z"
      />
      <path
        className="cls-3"
        d="M611.87,75.19L127.63,354.44v356.9c0,4.75,5.15,7.74,9.26,5.37l8.63-4.98,457.32-263.69c4.97-2.87,9.03-9.86,9.03-15.63V75.19Z"
      />
      <path
        className="cls-14"
        d="M609.79,78.79v353.61c0,5-3.66,11.33-7.99,13.83l-457.33,263.69-8.63,4.98c-.64.37-1.32.55-2.02.55-1.05,0-2.09-.43-2.87-1.17-.8-.77-1.25-1.82-1.24-2.95v-355.7S609.79,78.79,609.79,78.79M611.87,75.19L127.63,354.44v356.9c0,3.61,2.97,6.2,6.19,6.2,1.02,0,2.07-.26,3.06-.83l8.63-4.98,457.32-263.69c4.97-2.87,9.03-9.86,9.03-15.63V75.19h0Z"
      />
      <path
        className="cls-9"
        d="M127.63,330.51c0-2.87,2.01-6.38,4.49-7.83L607.38,48.68c2.48-1.45,4.49-.3,4.49,2.58v24.12S127.63,354.59,127.63,354.59v-24.08Z"
      />
      <path
        className="cls-11"
        d="M127.63,330.51c0-2.87,2.01-6.38,4.49-7.83L607.38,48.68c2.48-1.45,4.49-.3,4.49,2.58v24.12S127.63,354.59,127.63,354.59v-24.08Z"
      />
      <path
        className="cls-1"
        d="M590.74,74.28c0,2.7,1.89,3.79,4.23,2.44s4.23-4.62,4.23-7.32-1.89-3.79-4.23-2.44-4.23,4.62-4.23,7.32Z"
      />
      <path
        className="cls-1"
        d="M573.84,84.04c0,2.7,1.89,3.79,4.23,2.44s4.23-4.62,4.23-7.32-1.89-3.79-4.23-2.44-4.23,4.62-4.23,7.32Z"
      />
      <path
        className="cls-1"
        d="M556.93,93.8c0,2.7,1.89,3.79,4.23,2.44s4.23-4.62,4.23-7.32-1.89-3.79-4.23-2.44-4.23,4.62-4.23,7.32Z"
      />
      <path
        className="cls-12"
        d="M127.63,354.6v356.74c0,4.75,5.14,7.74,9.26,5.37l8.63-4.98,105.82-61.02v-367.44l-123.71,71.33h0Z"
      />
      <polygon className="cls-12" points="259.8 289.28 259.8 635.77 601.72 438.36 601.72 91.87 259.8 289.28" />
      <path
        className="cls-6"
        d="M223.02,325.12l-78.92,45.6c-1.46.84-2.64,2.89-2.64,4.58v8.22c0,1.69,1.18,2.37,2.64,1.53l78.92-45.6c1.46-.84,2.64-2.89,2.64-4.58v-8.21c0-1.69-1.18-2.37-2.64-1.53Z"
      />
      <path
        className="cls-10"
        d="M240.6,328.38l-2.33-2.24c.46-1.18.74-2.46.74-3.72,0-1.9-.63-3.36-1.74-3.99-.84-.49-1.85-.44-2.83.12-2.05,1.18-3.65,4.5-3.65,7.56,0,1.9.63,3.36,1.74,3.99.39.22.81.34,1.25.34.52,0,1.05-.15,1.58-.46.77-.45,1.48-1.19,2.06-2.12l1.91,1.84c.18.17.41.26.63.26.24,0,.48-.09.66-.28.35-.36.34-.94-.03-1.3ZM234.44,328.4c-.4.23-.74.27-1,.13-.38-.22-.82-.98-.82-2.41,0-2.38,1.25-5.11,2.74-5.97.25-.14.47-.21.66-.21.12,0,.24.03.34.09.38.22.82.98.82,2.41,0,2.38-1.25,5.11-2.74,5.97Z"
      />
      <path
        className="cls-10"
        d="M143.38,409.16l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-10"
        d="M146.03,407.63l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-10"
        d="M148.69,406.1l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-10"
        d="M143.38,415.34l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-10"
        d="M146.03,413.81l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-10"
        d="M148.69,412.28l-1.77,1.02c-.1.06-.19,0-.19-.14v-4.79c0-.14.08-.3.19-.36l1.77-1.02c.1-.06.19,0,.19.14v4.79c0,.14-.08.3-.19.36Z"
      />
      <path
        className="cls-11"
        d="M143.66,431.67l-2.18-1.33c-.08-.05-.08-.24,0-.39l2.18-3.84c.11-.19.26-.16.26.05v5.17c0,.2-.15.41-.26.34Z"
      />
      <path className="cls-11" d="M142.75,428.73l2.87-1.66v1.36l-2.87,1.66c-.24.14-.44-.05-.44-.43s.2-.8.44-.94Z" />
      <polygon className="cls-11" points="146.15 426.77 147.13 426.2 147.13 427.57 146.15 428.13 146.15 426.77" />
      <path
        className="cls-11"
        d="M149.77,425.36v5.02c0,.38-.2.8-.44.94-.24.14-.44-.04-.44-.43v-4.34l-1.21.7v-1.36l1.66-.96c.24-.14.44.05.44.43Z"
      />
      <path
        className="cls-11"
        d="M144.51,442.91c-2.03,1.4-3.41,5.26-3.02,8.11.36,2.61,1.91,2.51,2.08,3.72.02.13.1.18.2.13l1.94-1.1c.09-.05.18-.2.2-.35.2-1.6,2.18-3.53,2.2-7.42.01-2.98-1.62-4.44-3.59-3.09Z"
      />
      <path
        className="cls-11"
        d="M145.67,456.9l-1.87,1.08c-.13.07-.23,0-.23-.18v-1.72c0-.17.1-.37.23-.44l1.87-1.08c.13-.07.23,0,.23.18v1.72c0,.17-.1.37-.23.44Z"
      />
      <path
        className="cls-11"
        d="M144.96,402.43c-1.73,1.42-3.11,4.36-3.39,7.18-.16,1.64.04,2.98.5,3.93l-.63,3.27c-.05.27.1.41.25.24l1.89-2.06c.79.25,1.74.03,2.75-.81,1.73-1.43,3.12-4.37,3.4-7.19.45-4.58-1.94-6.88-4.75-4.57ZM145.64,413.16c-1.74,1-3.15-.36-3.15-3.03s1.41-5.66,3.15-6.67c1.74-1,3.15.36,3.15,3.03s-1.41,5.66-3.15,6.67Z"
      />
      <path
        className="cls-11"
        d="M144.96,465.58c-1.73,1.42-3.11,4.36-3.39,7.18-.16,1.64.04,2.98.5,3.93l-.63,3.27c-.05.27.1.41.25.24l1.89-2.06c.79.25,1.74.03,2.75-.81,1.73-1.43,3.12-4.37,3.4-7.19.45-4.58-1.94-6.88-4.75-4.57ZM145.64,476.31c-1.74,1-3.15-.36-3.15-3.03s1.41-5.66,3.15-6.66,3.15.36,3.15,3.03-1.41,5.66-3.15,6.66Z"
      />
      <path
        className="cls-10"
        d="M147.62,488.72c0,1.41-.74,2.99-1.66,3.52-.92.53-1.66-.19-1.66-1.6s.74-2.99,1.66-3.52c.92-.53,1.66.19,1.66,1.6Z"
      />
      <path
        className="cls-10"
        d="M150.48,489.23c0,.94-.5,1.99-1.11,2.34-.61.35-1.11-.12-1.11-1.06s.5-1.99,1.11-2.34c.61-.35,1.11.12,1.11,1.06Z"
      />
      <path
        className="cls-11"
        d="M147.92,498.8l-3.92,2.27v-4.97c0-1.05.64-2.26,1.42-2.71l1.08-.62c.78-.45,1.42.03,1.42,1.07v4.97Z"
      />
      <path className="cls-10" d="M149.55,492.14l-1.08.62v5.72l2.04-1.18v-4.45c0-.71-.43-1.03-.96-.72Z" />
      <path
        className="cls-10"
        d="M141.45,494.44c0,.94.5,1.42,1.11,1.06.61-.35,1.11-1.4,1.11-2.34s-.5-1.42-1.11-1.06c-.61.35-1.11,1.4-1.11,2.34Z"
      />
      <path
        className="cls-7"
        d="M160.62,397.93l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M160.62,419.68l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path className="cls-10" d="M142.38,496.28l1.08-.62v5.72l-2.04,1.18v-4.45c0-.71.43-1.52.96-1.83Z" />
      <path
        className="cls-7"
        d="M160.62,441.43l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M160.62,463.18l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M160.62,484.94l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-11"
        d="M169.07,502.7l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M169.07,517.39l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M169.07,532.08l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M178.06,541.42l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,554.06l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,556.06l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,568.71l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,570.71l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,583.35l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,585.35l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,597.99l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,599.99l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,612.63l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,614.63l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,627.27l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,629.28l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,641.92l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-7"
        d="M178.06,643.92l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14,0,1.35-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-10"
        d="M167.26,656.56l3.75-6.62c.14-.25.14-.59,0-.67l-3.75-2.29c-.18-.11-.44.24-.44.59v8.91c0,.35.26.4.44.08Z"
      />
      <path
        className="cls-6"
        d="M270.5,336.8l151.88-87.69c1.24-.72,2.25-2.47,2.25-3.9v-38.72c0-1.44-1.01-2.02-2.25-1.3l-151.88,87.67c-1.24.72-2.25,2.46-2.25,3.9v38.74c0,1.44,1.01,2.02,2.25,1.3Z"
      />
      <path
        className="cls-10"
        d="M283.75,309.47s0-.05.01-.09l1-4.16-1.38.79s-.06.02-.09,0c-.02-.02-.04-.06-.04-.11v-.75c0-.05.01-.1.04-.16.02-.05.05-.09.09-.11l2.21-1.27s.06-.02.09,0c.02.02.04.06.04.11v.66c0,.08-.02.21-.08.4l-1,4.17s-.03.1-.05.15c-.02.05-.05.08-.08.1l-.65.37s-.06.02-.08,0c-.02-.02-.03-.06-.03-.1Z"
      />
      <path
        className="cls-10"
        d="M287.43,302.47c.23-.13.43-.2.6-.19.17,0,.3.07.41.18.11.11.19.27.25.47.05.2.08.43.09.68,0,.2.01.41.01.64s0,.43-.01.63c0,.26-.04.53-.09.79-.05.27-.14.52-.25.76-.11.24-.25.46-.41.66-.17.2-.37.37-.6.5-.23.13-.43.2-.6.19-.16,0-.3-.07-.41-.18-.11-.11-.19-.27-.24-.47-.05-.2-.09-.43-.1-.68,0-.19,0-.4,0-.62s0-.44,0-.65c.01-.26.04-.52.1-.79.05-.27.13-.52.24-.75.11-.24.25-.46.41-.66.17-.2.37-.37.6-.5ZM287.92,305.34c.01-.41.01-.81,0-1.19,0-.12-.02-.22-.03-.31-.02-.09-.04-.16-.08-.21s-.09-.08-.15-.09c-.06,0-.14.02-.23.07-.09.05-.17.12-.23.2-.06.08-.11.17-.15.26-.04.09-.06.2-.08.31-.02.11-.03.22-.03.35-.02.4-.02.8,0,1.19,0,.12.01.22.03.31.02.09.04.16.08.21.04.05.09.08.15.09.06,0,.14-.02.23-.07.09-.05.17-.12.23-.2.06-.08.11-.17.15-.26.04-.1.07-.2.08-.31.02-.11.03-.22.03-.34Z"
      />
      <path
        className="cls-10"
        d="M290.62,301.67c0,.19,0,.36,0,.54,0,.15-.02.29-.05.42-.03.14-.07.27-.13.4-.06.13-.14.24-.22.35-.09.1-.2.19-.32.26s-.23.1-.31.1c-.09,0-.16-.03-.22-.09-.06-.06-.1-.14-.13-.24-.03-.1-.05-.22-.06-.36,0-.09,0-.18,0-.26s0-.18,0-.28c0-.15.02-.29.06-.43.03-.14.08-.27.13-.4.06-.13.13-.24.22-.35.09-.1.19-.19.31-.26s.23-.11.32-.1c.09,0,.16.03.22.09.06.06.1.14.14.24.03.1.05.22.05.36ZM291.78,300.17c.02-.05.04-.1.07-.14.02-.04.06-.07.1-.09l.38-.22s.06-.02.08,0c.02.02.03.04.03.09,0,0,0,.02,0,.04,0,.02,0,.03-.01.05l-2.28,5.94c-.02.05-.04.1-.07.14-.02.04-.05.07-.1.09l-.38.22s-.06.02-.08,0c-.02-.02-.03-.04-.03-.09,0,0,0-.02,0-.04,0-.02,0-.03.01-.05l2.28-5.94ZM289.71,302.7c0,.12.02.2.05.25.03.05.07.05.14.01.07-.04.11-.1.14-.17.03-.08.04-.18.05-.3,0-.16,0-.32,0-.47,0-.12-.02-.2-.05-.25-.03-.05-.07-.05-.14-.01-.07.04-.11.1-.14.17-.03.08-.04.18-.05.31-.01.16-.01.32,0,.47ZM292.77,302.92c0,.18,0,.36,0,.54,0,.15-.02.29-.05.43-.03.14-.08.27-.13.4-.06.13-.13.24-.22.35-.09.1-.19.19-.31.26-.12.07-.23.11-.32.1s-.16-.03-.22-.09c-.06-.06-.1-.14-.13-.24-.03-.1-.05-.22-.05-.36,0-.09,0-.18,0-.26s0-.18,0-.28c0-.15.02-.29.05-.43.03-.14.08-.27.13-.4.06-.12.13-.24.22-.34.09-.1.19-.19.32-.26.12-.07.22-.1.31-.1.09,0,.16.03.22.09.06.06.1.14.13.24.03.1.05.22.06.36ZM291.85,303.95c0,.12.02.2.05.25.02.05.07.05.14.01.07-.04.11-.1.14-.17.02-.08.04-.18.05-.31,0-.16,0-.32,0-.47,0-.12-.02-.2-.05-.25-.03-.05-.07-.05-.14-.01-.07.04-.12.1-.14.17-.03.08-.04.18-.05.3,0,.16,0,.32,0,.47Z"
      />
      <path
        className="cls-11"
        d="M283.72,297.54l-1.53-2.35c-1.67,2.84-2.95,6.24-3.6,9.71l2.46-.19c.48-2.57,1.43-5.07,2.66-7.17Z"
      />
      <path
        className="cls-11"
        d="M288.02,292.9v-3.99c-2.18,1.26-4.19,3.5-5.83,6.28l1.53,2.35c1.21-2.05,2.69-3.72,4.3-4.65Z"
      />
      <path
        className="cls-11"
        d="M280.7,308.41c0-1.22.13-2.46.36-3.69l-2.46.19c-.31,1.67-.48,3.35-.48,5s.17,3.13.48,4.44l2.46-2.66c-.23-.96-.36-2.06-.36-3.28Z"
      />
      <path
        className="cls-2"
        d="M297.46,294.01c-.65-2.72-1.93-4.64-3.6-5.55-1.64-.89-3.65-.81-5.83.45v3.99c1.61-.93,3.09-.98,4.3-.32,1.24.67,2.18,2.09,2.66,4.09.23.96.36,2.06.36,3.28s-.13,2.46-.36,3.69c-.48,2.57-1.43,5.07-2.66,7.17-1.21,2.05-2.69,3.72-4.3,4.65-1.61.93-3.09.98-4.3.32-1.24-.67-2.18-2.09-2.66-4.09l-2.46,2.66c.65,2.72,1.93,4.64,3.6,5.55,1.64.89,3.65.81,5.83-.45,2.18-1.26,4.19-3.5,5.83-6.28,1.67-2.84,2.95-6.24,3.6-9.71.31-1.67.49-3.35.49-5s-.17-3.13-.49-4.44Z"
      />
      <path
        className="cls-11"
        d="M312.77,283.1l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14s-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14,0-1.35,1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M312.77,292.94l80.02-46.19c1.24-.72,2.25-.21,2.25,1.14s-1.01,3.02-2.25,3.74l-80.02,46.19c-1.24.72-2.25.21-2.25-1.14,0-1.35,1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-6"
        d="M435.31,241.63l151.88-87.69c1.24-.72,2.25-2.47,2.25-3.9v-38.72c0-1.44-1.01-2.02-2.25-1.3l-151.88,87.67c-1.24.72-2.25,2.47-2.25,3.9v38.74c0,1.44,1.01,2.02,2.25,1.3Z"
      />
      <path
        className="cls-10"
        d="M449.3,210.75c0-.27.04-.54.11-.81.08-.27.17-.51.3-.73.13-.23.27-.42.43-.6.16-.18.33-.31.5-.41.17-.1.34-.16.5-.17.16-.02.31.02.43.1.13.08.23.21.3.38.08.18.11.4.11.68,0,.14-.01.27-.03.4-.02.13-.05.25-.08.37-.03.12-.07.24-.11.36-.04.11-.08.23-.12.34l-.88,2.38s-.03.07-.05.12c-.02.05-.05.08-.09.11l-.74.42s-.05.01-.06,0c-.02-.02-.03-.05-.03-.1,0-.02,0-.04,0-.06,0-.02,0-.03.02-.04l.59-1.62c-.15.05-.3.08-.43.06-.14-.01-.26-.07-.36-.15-.1-.09-.19-.22-.25-.38s-.09-.37-.09-.62ZM451.15,209.68c0-.09-.01-.17-.04-.23-.03-.06-.06-.11-.11-.14-.05-.03-.1-.04-.16-.04-.06,0-.12.02-.19.06-.06.04-.13.09-.19.15-.06.07-.11.14-.16.22-.05.08-.08.17-.11.26-.03.09-.04.19-.04.28s.01.17.04.24c.03.06.06.11.11.14.04.03.1.04.16.04.06,0,.12-.02.19-.06.07-.04.13-.09.19-.15.06-.07.11-.14.16-.22.04-.08.08-.17.11-.26.03-.09.04-.19.04-.28Z"
      />
      <path
        className="cls-10"
        d="M453.75,206.42c.23-.13.43-.19.6-.19.17,0,.3.07.41.18.11.11.19.27.25.47.05.2.08.43.09.68,0,.2.01.41.01.63s0,.43-.01.63c-.01.26-.04.53-.09.79-.05.27-.14.52-.25.76-.11.24-.25.46-.41.66-.17.2-.37.37-.6.5-.23.13-.43.19-.6.19-.16,0-.3-.07-.41-.18-.11-.11-.19-.27-.25-.47-.05-.2-.08-.43-.09-.68,0-.19-.01-.4-.01-.62s0-.44.01-.65c.01-.26.04-.52.09-.79.05-.27.14-.52.25-.75.11-.24.25-.46.41-.66.17-.2.37-.37.6-.5ZM454.24,209.29c.01-.41.01-.81,0-1.19,0-.12-.02-.22-.03-.31-.02-.09-.04-.16-.08-.21s-.09-.08-.15-.09c-.06,0-.14.02-.23.07-.09.05-.17.12-.23.2-.06.08-.11.17-.15.26-.04.09-.06.2-.08.31-.02.11-.03.22-.03.35-.02.4-.02.8,0,1.19,0,.12.01.22.03.31.02.09.04.16.08.21.04.05.09.08.15.09.06,0,.14-.02.23-.07.09-.05.17-.12.23-.2.06-.08.11-.17.15-.26.04-.1.07-.2.08-.31.02-.11.03-.22.03-.34Z"
      />
      <path
        className="cls-10"
        d="M456.94,205.62c0,.19,0,.36,0,.54,0,.15-.02.29-.05.42-.03.14-.08.27-.13.4-.06.13-.14.24-.22.35-.09.1-.19.19-.31.26s-.23.1-.31.1c-.09,0-.16-.03-.22-.09-.06-.06-.1-.14-.13-.24-.03-.1-.05-.22-.06-.36,0-.09,0-.18,0-.26s0-.18,0-.28c0-.15.02-.29.06-.43.03-.14.08-.27.13-.4.06-.13.13-.24.22-.35.09-.1.19-.19.31-.26s.23-.11.31-.1c.09,0,.16.03.22.09.06.06.1.14.14.24.03.1.05.22.05.36ZM458.09,204.11c.02-.05.04-.1.07-.14.02-.04.06-.07.1-.09l.38-.22s.06-.02.08,0c.02.02.03.04.03.09,0,.01,0,.02,0,.04,0,.02,0,.03-.01.05l-2.28,5.94c-.02.05-.04.1-.07.14-.02.04-.06.07-.1.09l-.38.22s-.06.02-.08,0c-.02-.02-.03-.04-.03-.09,0-.01,0-.02,0-.04,0-.02,0-.03.01-.05l2.28-5.94ZM456.03,206.65c0,.12.02.2.05.25.03.05.07.05.14.01.07-.04.11-.1.14-.17.03-.08.04-.18.05-.3,0-.16,0-.32,0-.47,0-.12-.02-.2-.05-.25-.03-.05-.07-.05-.14-.01-.07.04-.11.1-.14.17-.03.08-.04.18-.05.31-.01.16-.01.32,0,.47ZM459.08,206.87c0,.18,0,.36,0,.54,0,.15-.03.29-.05.43-.03.14-.08.27-.13.4-.06.13-.13.24-.22.35-.09.1-.19.19-.31.26-.12.07-.23.11-.32.1s-.16-.03-.22-.09c-.06-.06-.1-.14-.13-.24-.03-.1-.05-.22-.05-.36,0-.09-.01-.18-.01-.26s0-.18.01-.28c0-.15.02-.29.05-.43.03-.14.08-.27.13-.4.06-.12.13-.24.22-.34.09-.1.19-.19.32-.26.12-.07.22-.1.31-.1.09,0,.16.03.22.09.06.06.1.14.13.24.03.1.05.22.06.36ZM458.17,207.9c0,.12.02.2.05.25.02.05.07.05.14.01.07-.04.11-.1.14-.17.02-.08.04-.18.05-.31.01-.16.01-.32,0-.47,0-.12-.02-.2-.05-.25-.03-.05-.07-.05-.14-.01-.07.04-.12.1-.14.17-.03.08-.04.18-.05.3,0,.16,0,.32,0,.47Z"
      />
      <path
        className="cls-10"
        d="M454.21,196.92v-3.99c-2.18,1.26-4.19,3.5-5.83,6.28l1.53,2.35c1.21-2.05,2.69-3.72,4.3-4.65Z"
      />
      <path
        className="cls-11"
        d="M463.64,198.04c-.65-2.72-1.93-4.64-3.6-5.55-1.64-.89-3.65-.81-5.83.45v3.99c1.61-.93,3.09-.98,4.3-.32,1.24.67,2.18,2.09,2.66,4.09.23.96.36,2.06.36,3.28s-.13,2.46-.36,3.69c-.48,2.56-1.43,5.06-2.66,7.16-1.21,2.05-2.7,3.73-4.31,4.66-1.61.93-3.09.98-4.3.32-1.24-.67-2.18-2.09-2.66-4.09-.23-.96-.36-2.06-.36-3.28s.13-2.46.36-3.69c.48-2.57,1.43-5.07,2.66-7.17l-1.53-2.35c-1.67,2.84-2.95,6.24-3.6,9.71-.31,1.67-.49,3.35-.49,5s.17,3.13.49,4.44c.65,2.72,1.93,4.64,3.6,5.55,1.64.89,3.65.81,5.83-.45,2.18-1.26,4.19-3.5,5.83-6.28h0s0,0,0,0c1.67-2.84,2.95-6.24,3.6-9.71.31-1.67.48-3.35.48-5s-.17-3.13-.48-4.44Z"
      />
      <path
        className="cls-11"
        d="M478.91,187.13l46.21-26.68c1.24-.72,2.25-.21,2.25,1.14s-1.01,3.02-2.25,3.74l-46.21,26.68c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M478.91,196.96l80.02-46.19c1.24-.72,2.25-.21,2.25,1.14s-1.01,3.02-2.25,3.74l-80.02,46.19c-1.24.72-2.25.21-2.25-1.14,0-1.35,1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-11"
        d="M285.98,347.42l32.41-18.77c1.24-.72,2.25-.21,2.25,1.14s-1.01,3.02-2.25,3.74l-32.41,18.77c-1.24.72-2.25.21-2.25-1.14s1.01-3.02,2.25-3.74Z"
      />
      <path
        className="cls-7"
        d="M588.32,184.98h.01-.01M587.37,185.53v247.55c0,.69-.61,1.75-1.21,2.1l-315.83,182.33v-247.55c0-.69.61-1.75,1.21-2.1l3.95-2.28c1.88-1.08,3.29-3.54,3.29-5.7v-14.32c0-.69.61-1.75,1.21-2.1l45.34-26.17v13.31c0,2.42,1.67,3.69,3.33,3.69.68,0,1.37-.2,2.05-.59l256.66-148.17M326.29,316.74h.01-.01M588.2,182.91c-.3,0-.64.1-1.01.31l-257.53,148.68c-.36.21-.7.31-1.01.31-.74,0-1.25-.59-1.25-1.61v-14.32c0-1.02-.51-1.61-1.25-1.61-.3,0-.64.1-1.01.31l-46.21,26.68c-1.24.72-2.25,2.47-2.25,3.9v14.32c0,1.44-1.01,3.18-2.25,3.9l-3.95,2.28c-1.24.72-2.25,2.47-2.25,3.9v248.56c0,1.02.51,1.61,1.25,1.61.3,0,.64-.1,1.01-.31l316.69-182.84c1.24-.72,2.25-2.47,2.25-3.9v-248.56c0-1.02-.51-1.61-1.25-1.61h0Z"
      />
      <path
        className="cls-7"
        d="M288.61,380.77c-.4,0-.77-.1-1.1-.29-.75-.43-1.18-1.29-1.18-2.36v-9.44c0-1.79,1.22-3.9,2.77-4.8l25.11-14.48c.93-.53,1.88-.59,2.63-.16.75.43,1.18,1.29,1.18,2.36v9.44c0,1.8-1.22,3.91-2.77,4.8l-25.11,14.48c-.51.3-1.04.44-1.53.44ZM290.14,365.69c-.92.53-1.73,1.93-1.73,3v9.44c0,.36.1.53.14.56.04.02.24.02.55-.16l25.11-14.48c.92-.53,1.73-1.93,1.73-3v-9.44c0-.36-.1-.53-.14-.56-.04-.03-.24-.02-.55.16l-25.11,14.48Z"
      />
      <path
        className="cls-7"
        d="M291.64,369.57l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-10"
        d="M311.81,358.52l1.36,1.93c.05.07.15.01.2-.12l1.36-3.49c.07-.17,0-.32-.1-.26l-2.71,1.57c-.11.06-.17.29-.1.38Z"
      />
      <path
        className="cls-9"
        d="M285.16,472.22c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M302.06,452.7c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M318.97,442.94c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M335.87,437.46c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M352.8,419.89c1.2-.69,2.17-.13,2.17,1.25,0,1.39-.97,3.07-2.17,3.76s-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M369.68,413.66c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M386.58,394.14c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M403.49,389.26c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M420.4,335.57c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M437.3,364.86c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M454.21,364.86c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M471.11,364.86c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M488.02,335.58c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M504.92,329.97c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M521.89,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M538.73,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M555.64,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-9"
        d="M572.54,296.53c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-8"
        d="M285.11,475.27c-.12,0-.24-.04-.34-.13-.22-.19-.24-.52-.05-.73l17.07-19.62s.08-.08.13-.11l16.78-9.69,16.88-5.48,16.81-17.48c.05-.06.12-.1.19-.13l16.78-6.18,16.82-19.42c.07-.08.15-.13.25-.16l16.63-4.8,16.82-53.41c.06-.2.23-.34.44-.36.19-.02.4.08.51.26l16.75,29.02,16.65-.02,16.56.02,16.75-29.02c.06-.11.17-.19.29-.23l16.74-5.56,16.81-23.53c.1-.14.25-.22.42-.22h33.67s16.79-9.69,16.79-9.69c.24-.15.56-.06.71.19.14.25.06.57-.19.71l-16.91,9.76c-.08.05-.17.07-.26.07h-33.54s-16.75,23.45-16.75,23.45c-.06.09-.15.16-.26.19l-16.72,5.55-16.81,29.11c-.09.16-.27.26-.45.26h0l-16.86-.02-16.95.02h0c-.18,0-.36-.1-.45-.26l-16.31-28.26-16.55,52.55c-.05.17-.18.29-.35.34l-16.75,4.84-16.81,19.41c-.06.07-.13.12-.21.15l-16.79,6.19-16.82,17.49c-.06.06-.13.11-.22.13l-16.91,5.47-16.61,9.6-17.01,19.55c-.1.12-.25.18-.39.18Z"
      />
      <path
        className="cls-7"
        d="M285.16,485.53c-.36,0-.71-.19-.9-.52-.29-.5-.12-1.13.38-1.42l287.39-165.92c.5-.29,1.13-.12,1.42.38.29.5.12,1.13-.38,1.42l-287.39,165.92c-.16.09-.34.14-.52.14Z"
      />
      <path
        className="cls-11"
        d="M285.16,462.46c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M302.06,433.18c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M318.97,429.52c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M335.87,394.14c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M352.8,403.9c1.2-.69,2.17-.13,2.17,1.25,0,1.39-.97,3.07-2.17,3.76-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M369.68,399.02c1.2-.69,2.17-.13,2.17,1.25,0,1.39-.97,3.07-2.17,3.76-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M386.58,384.38c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M403.49,364.86c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M420.4,359.98c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M437.3,345.34c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M454.21,345.34c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M471.11,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M488.02,325.68c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M504.92,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M521.83,296.53c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M538.73,296.53c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M555.64,286.77c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M572.54,286.77c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M285.16,465.49c-.09,0-.18-.02-.26-.07-.25-.14-.33-.46-.19-.71l17.02-29.35c.07-.13.2-.22.34-.25l16.53-3.53,16.79-35.16c.06-.13.18-.23.32-.27.14-.04.29-.02.41.05l16.73,9.66,16.56-4.8,16.8-14.55,16.87-19.48c.07-.08.15-.13.25-.16l16.8-4.85,16.82-14.57c.1-.08.22-.13.34-.13h16.56l16.77-38.73c.07-.16.22-.28.4-.31.17-.03.35.04.47.17l16.51,19.07,16.51-19.07s.08-.08.13-.11l16.96-9.79c.08-.05.17-.07.26-.07h16.77l16.73-9.66c.08-.05.17-.07.26-.07h16.91c.29,0,.52.23.52.52s-.23.52-.52.52h-16.77l-16.73,9.66c-.08.05-.17.07-.26.07h-16.77l-16.77,9.68-16.85,19.45c-.2.23-.59.23-.79,0l-16.36-18.89-16.58,38.28c-.08.19-.27.31-.48.31h-16.71l-16.76,14.51c-.06.05-.12.09-.2.11l-16.76,4.84-16.83,19.42-16.93,14.68c-.06.05-.12.09-.2.11l-16.89,4.89c-.14.04-.28.02-.4-.05l-16.44-9.49-16.66,34.88c-.07.15-.2.25-.36.28l-16.56,3.54-16.91,29.15c-.1.17-.27.26-.45.26Z"
      />
      <g className="cls-16">
        <path
          className="cls-11"
          d="M285.16,462.46c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M302.06,433.18c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M318.97,429.52c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M335.87,394.14c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M352.8,403.9c1.2-.69,2.17-.13,2.17,1.25,0,1.39-.97,3.07-2.17,3.76-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M369.68,399.02c1.2-.69,2.17-.13,2.17,1.25,0,1.39-.97,3.07-2.17,3.76-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M386.58,384.38c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M403.49,364.86c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M420.4,359.98c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M437.3,345.34c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M454.21,345.34c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M471.11,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M488.02,325.68c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M504.92,306.29c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M521.83,296.53c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M538.73,296.53c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76c-1.2.69-2.17.13-2.17-1.25s.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M555.64,286.77c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M572.54,286.77c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
        />
        <path
          className="cls-11"
          d="M285.16,465.49c-.09,0-.18-.02-.26-.07-.25-.14-.33-.46-.19-.71l17.02-29.35c.07-.13.2-.22.34-.25l16.53-3.53,16.79-35.16c.06-.13.18-.23.32-.27.14-.04.29-.02.41.05l16.73,9.66,16.56-4.8,16.8-14.55,16.87-19.48c.07-.08.15-.13.25-.16l16.8-4.85,16.82-14.57c.1-.08.22-.13.34-.13h16.56l16.77-38.73c.07-.16.22-.28.4-.31.17-.03.35.04.47.17l16.51,19.07,16.51-19.07s.08-.08.13-.11l16.96-9.79c.08-.05.17-.07.26-.07h16.77l16.73-9.66c.08-.05.17-.07.26-.07h16.91c.29,0,.52.23.52.52s-.23.52-.52.52h-16.77l-16.73,9.66c-.08.05-.17.07-.26.07h-16.77l-16.77,9.68-16.85,19.45c-.2.23-.59.23-.79,0l-16.36-18.89-16.58,38.28c-.08.19-.27.31-.48.31h-16.71l-16.76,14.51c-.06.05-.12.09-.2.11l-16.76,4.84-16.83,19.42-16.93,14.68c-.06.05-.12.09-.2.11l-16.89,4.89c-.14.04-.28.02-.4-.05l-16.44-9.49-16.66,34.88c-.07.15-.2.25-.36.28l-16.56,3.54-16.91,29.15c-.1.17-.27.26-.45.26Z"
        />
      </g>
      <path
        className="cls-7"
        d="M294.01,390.03l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M285.16,395.14c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-13"
        d="M285.16,395.14c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-7"
        d="M294.01,399.77l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M285.16,403.9c1.2-.69,2.17-.13,2.17,1.25s-.97,3.07-2.17,3.76-2.17.13-2.17-1.25.97-3.07,2.17-3.76Z"
      />
      <path
        className="cls-11"
        d="M285.16,533.95c-.13,0-.26-.05-.36-.15-.21-.2-.21-.53-.01-.74l8.58-8.82,8.42-1.07,8.5-10.99,8.51,1.17,8.27-7.47,8.44-5.06,8.61-11.93,8.55,2.68,8.21-7.34c.21-.19.54-.17.74.04.19.22.17.54-.04.74l-8.66,7.73-8.39-2.63-8.39,11.55-8.45,5.06-8.55,7.73-8.39-1.15-8.4,10.85-8.49,1.07-8.32,8.56c-.1.11-.24.16-.37.16Z"
      />
      <path
        className="cls-10"
        d="M288.5,505.4c.05-.06.13-.12.22-.19.09-.07.16-.12.23-.16.46-.27.89-.4,1.28-.39.39,0,.73.14,1.02.38.28.25.51.61.67,1.07.16.47.24,1.02.24,1.67s-.08,1.3-.24,1.95c-.16.65-.38,1.27-.67,1.84-.28.58-.62,1.1-1.02,1.56-.39.46-.82.83-1.28,1.09-.46.27-.89.4-1.28.39-.39,0-.73-.14-1.01-.39-.28-.25-.51-.61-.67-1.07-.16-.46-.24-1.02-.24-1.67,0-.37.03-.73.08-1.07.05-.35.12-.69.21-1.02.09-.33.18-.65.29-.97.11-.32.21-.62.32-.91l2.25-6.41c.04-.09.09-.19.14-.32.05-.12.13-.22.25-.28l.71-.41c.07-.04.13-.04.18.02.05.06.07.14.07.26,0,.06,0,.12-.02.16-.01.04-.03.09-.04.12l-1.67,4.73ZM286.91,510.81c0,.38.05.72.14,1,.1.29.23.52.4.68.17.17.38.26.64.27.25.01.54-.07.85-.25s.59-.42.85-.73c.25-.3.47-.64.64-1.01.17-.37.31-.75.4-1.15.09-.39.14-.78.14-1.17s-.05-.72-.14-1c-.1-.29-.23-.52-.4-.68s-.39-.26-.64-.27c-.25-.01-.53.07-.85.25s-.6.42-.85.73-.46.64-.64,1.01c-.17.37-.31.75-.4,1.15-.09.4-.14.79-.14,1.17Z"
      />
      <path
        className="cls-10"
        d="M294.25,507.76c.04.18.1.35.17.51.07.16.18.28.33.35.15.07.35.09.59.05.24-.04.56-.17.95-.39.26-.15.5-.34.73-.56.23-.23.43-.49.61-.8.17-.3.31-.63.41-.99.1-.36.15-.75.15-1.17s-.05-.72-.15-.95c-.1-.23-.24-.38-.4-.45-.17-.07-.37-.08-.6-.03-.23.06-.48.17-.75.32l-1.03.59c-.09.05-.17.05-.24-.02-.06-.06-.1-.16-.1-.3v-.68c0-.12.02-.23.07-.36.04-.12.09-.23.13-.33l2.23-4.98-3.56,2.06c-.09.05-.17.05-.24-.02-.06-.06-.1-.16-.1-.3v-.68c0-.14.03-.28.1-.42.07-.14.14-.24.24-.29l4.87-2.81c.09-.05.17-.05.24.02.06.06.1.16.1.3v.68c0,.12-.02.24-.06.37-.04.13-.1.27-.16.41l-2.19,4.9c.41-.23.79-.34,1.13-.36.34-.01.63.08.88.27.24.2.43.5.57.89.14.4.21.89.21,1.49,0,.65-.08,1.28-.25,1.89-.17.61-.39,1.17-.66,1.68-.28.51-.6.97-.98,1.37-.38.41-.77.73-1.19.98-.55.32-1.03.49-1.43.53-.4.04-.73-.03-.99-.21-.26-.17-.45-.42-.58-.75-.13-.33-.2-.7-.21-1.11,0-.12.03-.23.08-.34.05-.11.12-.19.19-.23l.57-.33c.09-.05.16-.05.22,0,.05.05.09.12.11.22Z"
      />
      <path
        className="cls-10"
        d="M301.61,503.51c.04.18.1.35.17.51.08.16.19.28.34.35.15.07.35.09.59.05.24-.04.56-.17.95-.39.26-.15.5-.33.73-.56.23-.23.43-.5.61-.8s.31-.64.41-.99c.1-.36.15-.75.15-1.17s-.05-.72-.15-.96c-.1-.23-.23-.38-.4-.45-.17-.07-.37-.08-.6-.02-.23.06-.48.16-.75.32l-1.03.59c-.09.05-.17.04-.23-.02-.07-.06-.1-.16-.1-.3v-.69c0-.11.02-.23.07-.35.04-.12.09-.23.13-.33l2.23-4.98-3.56,2.06c-.09.05-.17.04-.24-.02-.06-.07-.1-.17-.1-.3v-.69c0-.14.03-.28.1-.42.07-.14.14-.24.24-.29l4.87-2.81c.09-.05.17-.05.24.02.07.06.1.16.1.3v.68c0,.12-.02.24-.06.37-.04.13-.1.27-.16.41l-2.19,4.9c.41-.23.79-.35,1.13-.36.34-.01.64.08.88.27.24.19.43.49.57.89.14.4.21.89.21,1.49,0,.65-.08,1.28-.25,1.89-.17.61-.39,1.17-.66,1.68s-.6.97-.98,1.37c-.37.41-.77.73-1.19.98-.55.32-1.03.5-1.43.53-.4.04-.73-.03-.99-.21-.26-.17-.45-.42-.58-.75-.13-.33-.19-.7-.2-1.11,0-.12.03-.23.08-.34.05-.11.12-.19.19-.24l.57-.33c.09-.05.16-.05.22,0,.05.05.09.13.1.22Z"
      />
      <path
        className="cls-7"
        d="M286.69,540.67l73.04-42.33c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-73.04,42.33c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M286.69,491.84l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-9"
        d="M285.16,592.46c-.13,0-.26-.05-.36-.15-.21-.2-.21-.53-.01-.74l8.58-8.82,8.48-1.07,8.54-7.46,8.23-2.24,8.58-17.08,8.6,4.21,8.3-7.31.14-.03,8.24-1.56,8.32-14.38c.14-.25.47-.33.71-.19.25.14.33.46.19.71l-8.56,14.8-8.57,1.62-8.61,7.58-8.3-4.07-8.32,16.58-8.56,2.33-8.48,7.48-8.43,1.07-8.32,8.56c-.1.11-.24.16-.37.16Z"
      />
      <path
        className="cls-11"
        d="M285.16,592.46c-.13,0-.26-.05-.36-.15-.21-.2-.21-.53-.01-.74l8.58-8.82,8.48-1.07,8.54-7.46,8.23-2.24,8.58-17.08,8.6,4.21,8.3-7.31.14-.03,8.24-1.56,8.32-14.38c.14-.25.47-.33.71-.19.25.14.33.46.19.71l-8.56,14.8-8.57,1.62-8.61,7.58-8.3-4.07-8.32,16.58-8.56,2.33-8.48,7.48-8.43,1.07-8.32,8.56c-.1.11-.24.16-.37.16Z"
      />
      <path
        className="cls-10"
        d="M289.47,571.73c0,.14-.03.28-.1.42-.07.14-.14.24-.24.29l-.51.29c-.09.05-.17.05-.24-.02-.06-.06-.1-.16-.1-.3v-10.49l-2,3.5c-.08.14-.16.23-.25.26-.09.03-.16,0-.22-.06l-.29-.42c-.06-.08-.08-.19-.07-.34.01-.14.06-.28.13-.41l2.65-4.65c.07-.11.13-.2.2-.25.06-.05.13-.09.2-.13l.51-.29c.09-.05.17-.05.24.02.07.06.1.16.1.3v12.3Z"
      />
      <path
        className="cls-10"
        d="M294.88,562.12c.25-.49.43-.94.56-1.37.12-.43.18-.93.18-1.5,0-.67-.13-1.16-.38-1.47-.25-.3-.66-.29-1.21.03-.29.17-.53.37-.74.61-.2.25-.37.52-.51.82-.13.3-.24.62-.32.95-.08.33-.14.66-.19,1-.02.15-.07.29-.13.4-.06.11-.13.19-.19.22l-.57.33c-.07.04-.14.04-.19-.01-.05-.05-.08-.14-.08-.25,0-.47.08-.99.21-1.55.13-.56.32-1.11.57-1.64.25-.54.55-1.03.91-1.5.36-.46.77-.83,1.23-1.09.45-.26.85-.38,1.2-.37.35.02.64.14.87.38.23.23.41.56.53.99.12.43.18.92.18,1.48,0,.41-.02.78-.06,1.14-.04.36-.11.71-.2,1.06s-.22.71-.37,1.08c-.15.36-.33.75-.54,1.17l-2.76,5.56,3.78-2.18c.09-.05.17-.05.24.02.07.06.1.16.1.3v.68c0,.14-.03.28-.1.42-.07.14-.14.24-.24.29l-5.33,3.08c-.09.05-.17.05-.23-.02-.07-.06-.1-.16-.1-.3v-.68c0-.14.02-.27.07-.4.04-.13.11-.29.21-.49l3.62-7.18Z"
      />
      <path
        className="cls-10"
        d="M304.66,555.21c0,.56.01,1.1.01,1.6s0,1.04-.01,1.62c0,.74-.08,1.47-.21,2.18-.13.72-.32,1.38-.57,2-.26.62-.58,1.17-.97,1.67-.39.49-.86.9-1.4,1.21-.54.32-1.01.45-1.4.41-.39-.04-.72-.22-.97-.54-.26-.32-.45-.76-.57-1.33-.13-.57-.2-1.22-.21-1.95,0-.57-.01-1.1-.01-1.6s0-1.04.01-1.62c0-.74.08-1.47.21-2.19.13-.72.32-1.38.57-2,.25-.62.58-1.18.97-1.67.39-.5.86-.91,1.4-1.22.54-.31,1.01-.45,1.4-.4.39.04.72.23.97.55.26.32.45.76.57,1.33.13.57.2,1.22.21,1.95ZM299.52,561.4c.02,1.19.19,1.98.53,2.36.33.38.82.38,1.47,0,.64-.37,1.13-.94,1.47-1.7.34-.77.51-1.75.53-2.97,0-.58.01-1.12.01-1.62s0-1.04-.01-1.6c-.02-1.2-.19-1.99-.53-2.37-.33-.38-.82-.39-1.47-.02-.64.37-1.13.94-1.47,1.71-.34.77-.51,1.76-.53,2.98,0,.57-.01,1.11-.01,1.62s0,1.03.01,1.6Z"
      />
      <path
        className="cls-7"
        d="M286.69,599.18l73.04-42.33c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-73.04,42.33c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M286.69,550.35l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-11"
        d="M386.59,475.27c-.15,0-.3-.07-.41-.19-.18-.23-.14-.55.08-.73l8.45-6.74,8.51-9.73,8.64-1.25,8.24-1.86,8.53-10.08,8.4,1.49,8.42-13.34,8.56.14,8.28-7.4c.21-.19.54-.17.74.04.19.21.17.54-.04.73l-8.59,7.67-8.38-.14-8.48,13.44-8.5-1.5-8.37,9.89-8.62,1.94-8.3,1.21-8.32,9.5-8.52,6.81c-.1.08-.21.11-.33.11Z"
      />
      <path
        className="cls-10"
        d="M389.93,446.72c.05-.06.13-.12.22-.19.09-.07.16-.12.23-.16.46-.27.89-.4,1.28-.39.39,0,.73.14,1.02.38.28.25.51.61.67,1.07.16.47.24,1.02.24,1.67s-.08,1.3-.24,1.95c-.16.65-.38,1.27-.67,1.84-.28.58-.62,1.1-1.02,1.56-.39.46-.82.83-1.28,1.09-.46.27-.89.4-1.28.39-.39,0-.73-.14-1.01-.39-.28-.25-.51-.61-.67-1.07-.16-.46-.24-1.02-.24-1.67,0-.37.03-.73.08-1.07.05-.35.12-.69.21-1.02.09-.33.18-.65.29-.97.11-.32.21-.62.32-.91l2.25-6.41c.04-.09.09-.19.14-.32.05-.12.13-.22.25-.28l.71-.41c.07-.04.13-.04.18.02.05.06.07.14.07.26,0,.06,0,.12-.02.16-.01.04-.03.09-.04.12l-1.67,4.73ZM388.34,452.13c0,.38.05.72.14,1,.1.29.23.52.4.68.17.17.38.26.64.27.25.01.54-.07.85-.25.31-.18.59-.42.85-.73.25-.3.47-.64.64-1.01.17-.37.31-.75.4-1.15.09-.39.14-.78.14-1.17s-.05-.72-.14-1c-.1-.29-.23-.52-.4-.68-.17-.17-.39-.26-.64-.27-.25-.01-.53.07-.85.25-.31.18-.6.42-.85.73-.25.3-.46.64-.64,1.01-.17.37-.31.75-.4,1.15-.09.4-.14.79-.14,1.17Z"
      />
      <path
        className="cls-10"
        d="M398.5,443.66c.25-.49.43-.94.56-1.37.12-.43.18-.93.18-1.5,0-.67-.13-1.16-.38-1.47-.26-.3-.66-.29-1.21.03-.29.17-.53.37-.74.61-.2.25-.37.52-.51.82-.14.3-.24.62-.32.95-.08.33-.14.66-.19,1-.02.15-.07.29-.13.4-.06.11-.13.19-.19.22l-.57.33c-.08.04-.14.04-.19-.01-.05-.05-.08-.14-.08-.25,0-.47.08-.99.21-1.55.13-.56.32-1.11.57-1.64.25-.54.55-1.03.91-1.5.36-.46.77-.83,1.23-1.09.46-.26.85-.38,1.2-.37.35.02.63.14.87.38.23.23.41.56.53.99.12.43.18.92.18,1.48,0,.41-.02.78-.06,1.14-.04.36-.11.71-.21,1.06-.09.35-.22.71-.37,1.08-.15.36-.33.75-.54,1.17l-2.76,5.56,3.78-2.18c.09-.05.17-.05.24.02.07.06.1.16.1.3v.68c0,.14-.03.28-.1.42-.07.14-.14.24-.24.29l-5.33,3.08c-.09.05-.17.05-.23-.02-.07-.06-.1-.16-.1-.3v-.68c0-.14.02-.27.06-.4.04-.13.11-.29.21-.49l3.62-7.18Z"
      />
      <path
        className="cls-10"
        d="M406.91,432.48c.09-.05.17-.04.24.02.06.06.1.17.1.3v.69c0,.11-.01.24-.04.38-.02.14-.06.29-.1.46l-2.75,12.5c-.03.13-.08.26-.13.39-.05.13-.13.22-.23.27l-.57.33c-.08.05-.15.04-.21-.01-.06-.05-.09-.15-.09-.27,0-.02,0-.06,0-.09,0-.04.01-.09.03-.16l2.76-12.52-4.06,2.34c-.09.05-.17.05-.24-.02-.07-.07-.1-.17-.1-.3v-.69c0-.14.03-.28.1-.42.06-.14.14-.24.24-.29l5.03-2.91Z"
      />
      <path
        className="cls-7"
        d="M388.12,481.99l73.04-42.33c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-73.04,42.33c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M388.12,433.16l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76s.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-9"
        d="M386.59,533.78c-.09,0-.19-.02-.27-.08-.25-.15-.32-.47-.17-.72l8.64-14.17,8.69,1.61,8.18-2.24,8.46-10.32,8.4-2.12,8.54-15.22,8.73,2.54,8.21-1.56,8.33-7.41c.21-.19.54-.17.73.04.19.21.17.54-.04.73l-8.54,7.6-.14.03-8.6,1.63-8.18-2.37-8.36,14.89-8.5,2.15-8.45,10.3-.16.04-8.57,2.34-.12-.02-8.1-1.5-8.27,13.56c-.1.16-.27.25-.45.25Z"
      />
      <path
        className="cls-11"
        d="M386.59,533.78c-.09,0-.19-.02-.27-.08-.25-.15-.32-.47-.17-.72l8.64-14.17,8.69,1.61,8.18-2.24,8.46-10.32,8.4-2.12,8.54-15.22,8.73,2.54,8.21-1.56,8.33-7.41c.21-.19.54-.17.73.04.19.21.17.54-.04.73l-8.54,7.6-.14.03-8.6,1.63-8.18-2.37-8.36,14.89-8.5,2.15-8.45,10.3-.16.04-8.57,2.34-.12-.02-8.1-1.5-8.27,13.56c-.1.16-.27.25-.45.25Z"
      />
      <path
        className="cls-10"
        d="M390.5,509.23s-.1.08-.17.13c-.08.05-.13.09-.16.1-.4.23-.78.35-1.15.37-.37.02-.7-.08-.99-.29-.29-.22-.52-.55-.7-1.01-.18-.46-.27-1.05-.27-1.77,0-.65.08-1.29.25-1.93s.39-1.24.67-1.81c.28-.56.61-1.07.99-1.51.37-.45.77-.79,1.19-1.03.48-.28.91-.41,1.29-.4.38,0,.71.13.98.38s.47.6.62,1.06c.14.46.22,1.02.22,1.66,0,.32-.03.66-.1,1.02-.06.36-.15.72-.25,1.09-.1.36-.21.72-.32,1.06-.12.35-.22.66-.32.95l-2.25,6.4c-.04.09-.09.19-.14.32-.05.13-.13.22-.25.28l-.71.41c-.07.04-.13.04-.18-.02-.04-.05-.07-.14-.07-.26,0-.06,0-.12.02-.16.01-.05.03-.09.04-.12l1.74-4.91ZM392.09,503.85c0-.39-.05-.73-.14-1.02-.1-.29-.23-.51-.4-.66-.17-.15-.37-.22-.61-.23-.24,0-.5.08-.78.24-.29.17-.55.39-.78.66-.24.28-.44.59-.61.93-.17.34-.3.72-.39,1.11-.1.4-.14.79-.14,1.19s.05.75.14,1.03c.09.28.23.5.39.65.17.15.37.23.61.23.23,0,.5-.08.78-.24.28-.16.54-.38.78-.66.24-.28.45-.59.61-.93.17-.35.3-.71.4-1.1.09-.39.14-.79.14-1.2Z"
      />
      <path
        className="cls-10"
        d="M395.31,507.8c.04.18.1.35.17.51.07.16.19.28.33.35.15.07.35.09.59.05.24-.04.56-.17.95-.39.25-.15.5-.34.73-.56.23-.23.43-.49.61-.8.17-.3.31-.64.41-.99.11-.36.16-.75.16-1.17s-.05-.72-.15-.95c-.1-.23-.23-.38-.4-.45-.17-.07-.37-.08-.6-.02-.23.06-.48.16-.75.32l-1.03.59c-.09.05-.17.05-.23-.02-.07-.06-.1-.16-.1-.3v-.68c0-.12.02-.23.07-.36.05-.12.09-.23.13-.33l2.23-4.98-3.56,2.06c-.09.05-.17.05-.24-.02-.06-.07-.1-.16-.1-.3v-.68c0-.14.03-.28.1-.42.07-.14.14-.24.24-.29l4.87-2.81c.09-.05.17-.04.24.02.06.06.1.17.1.3v.69c0,.11-.02.24-.06.37-.04.13-.1.27-.16.41l-2.19,4.9c.41-.23.79-.35,1.13-.36.34-.01.64.08.88.27.24.19.43.49.57.89.13.4.2.89.2,1.49,0,.65-.08,1.28-.25,1.89-.16.61-.39,1.17-.66,1.68-.28.51-.6.96-.98,1.37-.38.41-.77.73-1.19.98-.55.32-1.03.49-1.43.53-.4.04-.73-.03-.99-.21-.26-.17-.45-.42-.58-.75-.13-.33-.19-.7-.2-1.11,0-.12.03-.23.08-.35.05-.11.12-.19.19-.23l.57-.33c.09-.05.16-.05.22,0,.05.05.09.12.11.22Z"
      />
      <path
        className="cls-7"
        d="M388.12,540.5l73.04-42.33c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-73.04,42.33c-.83.48-1.5.14-1.5-.76s.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M388.12,491.67l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path className="cls-2" d="M496.47,380.28c3.6-2.08,6.93-2.19,9.63-.72l-9.63,25.98v-25.26Z" />
      <path className="cls-9" d="M486.84,390.69c2.71-4.6,6.03-8.33,9.63-10.41v25.26l-9.63-14.85Z" />
      <path className="cls-9" d="M480.87,406.75c1.08-5.75,3.2-11.36,5.97-16.06l9.63,14.85-15.6,1.2Z" />
      <path
        className="cls-11"
        d="M486.84,390.69c-2.77,4.7-4.89,10.31-5.97,16.06l15.6-1.2v-25.26c-3.6,2.08-6.93,5.81-9.63,10.41Z"
      />
      <path className="cls-2" d="M506.11,379.57c2.77,1.5,4.89,4.67,5.97,9.17l-15.6,16.81,9.63-25.98Z" />
      <path className="cls-2" d="M480.87,422.36c-.52-2.16-.81-4.61-.81-7.34s.29-5.51.81-8.27l15.6-1.2-15.6,16.81Z" />
      <path className="cls-2" d="M512.07,404.34c-1.08,5.75-3.2,11.36-5.97,16.06l-9.63-14.85,15.6-1.2Z" />
      <path className="cls-2" d="M496.47,430.81c-3.6,2.08-6.93,2.19-9.63.72l9.63-25.98v25.26Z" />
      <path className="cls-2" d="M480.87,422.36l15.6-16.81-9.63,25.98c-2.77-1.5-4.89-4.67-5.97-9.17Z" />
      <path className="cls-2" d="M512.07,388.73c.52,2.16.81,4.61.81,7.34s-.29,5.51-.81,8.27l-15.6,1.2,15.6-16.81Z" />
      <path className="cls-2" d="M506.11,420.4c-2.71,4.6-6.03,8.33-9.63,10.41v-25.26l9.63,14.85Z" />
      <path
        className="cls-11"
        d="M488.29,443.69l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76s.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M484.68,455.56l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M484.68,465.35l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M484.68,475.13l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path className="cls-2" d="M555.64,346c3.6-2.08,6.93-2.19,9.63-.72l-9.63,25.98v-25.26Z" />
      <path className="cls-9" d="M546.01,356.4c2.71-4.6,6.03-8.33,9.63-10.41v25.26l-9.63-14.85Z" />
      <path className="cls-9" d="M540.04,372.46c1.08-5.74,3.2-11.36,5.97-16.06l9.63,14.85-15.6,1.2Z" />
      <path className="cls-2" d="M565.27,345.28c2.77,1.5,4.89,4.67,5.97,9.17l-15.6,16.81,9.63-25.98Z" />
      <path className="cls-9" d="M540.04,388.07c-.52-2.16-.81-4.61-.81-7.34s.29-5.51.81-8.27l15.6-1.2-15.6,16.81Z" />
      <path className="cls-2" d="M571.24,370.05c-1.08,5.75-3.2,11.36-5.97,16.06l-9.63-14.85,15.6-1.2Z" />
      <path className="cls-2" d="M555.64,396.52c-3.6,2.08-6.93,2.19-9.63.72l9.63-25.98v25.26Z" />
      <path className="cls-9" d="M540.04,388.07l15.6-16.81-9.63,25.98c-2.77-1.5-4.89-4.67-5.97-9.17Z" />
      <path
        className="cls-11"
        d="M546.01,356.4c-2.77,4.7-4.89,10.31-5.97,16.06-.52,2.76-.81,5.54-.81,8.27s.29,5.18.81,7.34c1.08,4.5,3.2,7.66,5.97,9.17l9.63-25.98v-25.26c-3.6,2.08-6.93,5.81-9.63,10.41Z"
      />
      <path className="cls-2" d="M571.24,354.45c.52,2.16.81,4.61.81,7.34s-.29,5.51-.81,8.27l-15.6,1.2,15.6-16.81Z" />
      <path className="cls-2" d="M565.27,386.11c-2.71,4.6-6.03,8.33-9.63,10.41v-25.26l9.63,14.85Z" />
      <path
        className="cls-11"
        d="M547.46,409.53l16.37-9.5c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-16.37,9.5c-.83.48-1.5.14-1.5-.76s.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M543.85,421.4l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M543.85,431.19l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
      <path
        className="cls-7"
        d="M543.85,440.97l23.59-13.69c.83-.48,1.5-.14,1.5.76s-.67,2.01-1.5,2.49l-23.59,13.69c-.83.48-1.5.14-1.5-.76,0-.9.67-2.01,1.5-2.49Z"
      />
    </StyledSVG>
  );
};

export default DIGITAL_HS_3D;
