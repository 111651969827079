import PreviewTV from 'assets/Preview/PreviewTV';
import { StyledGraphContainer } from 'components/Main/graf/GraphStyles';
import { Add, TxtContainer2 } from 'components/Main/table/TableStyles';

interface Props {
  page: string;
}

const NoCampaignSelected = (props: Props) => {
  const { page } = props;

  return (
    <StyledGraphContainer expand={0} style={{ background: 'transparent' }}>
      <TxtContainer2 isGraph={false} displayTxt={0}>
        <Add isGraph={true}>
          <div
            style={{ paddingTop: '3vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            className="text"
          >
            <div style={{ width: '40%' }}>
              <PreviewTV></PreviewTV>
            </div>

            <h1>No campaign selected, {page}</h1>
            <p>Please select a campaign in the top left corner to continue</p>
          </div>
        </Add>
      </TxtContainer2>
    </StyledGraphContainer>
  );
};

export default NoCampaignSelected;
