import styled from 'styled-components';
import KPIContainer from '../Header/KPIContainer';
import LinksContainer from '../Header/LinksContainer';
import GanttContainer, { GanttContainerStyled, Skeleton } from './GanttContainer';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { memo, useEffect, useState } from 'react';
import { ReactComponent as SVG_CRICLE } from '../../../assets/newAssets/circle.svg';
import ReactLoading from 'react-loading';
import KPIContainerSkeleton from '../Header/KPIContianerSkeleton';
import { FaChevronDown } from 'react-icons/fa';
import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { Selections } from 'components/Main/table/TableStyles';
import { RiCloseFill } from 'react-icons/ri';
import { useClientContext } from 'context/ClientContext';
import { start } from 'repl';
import { IoIosSearch } from 'react-icons/io';
import { europinzedDate } from 'methods/dateMethods';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ClientsContainer = styled.div`
  /* width: 30%; */
  padding-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 5px;
  margin-bottom: 4px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;

  padding: 4px 8px;
  border: 1px solid ${({ theme }) => theme.secondaryGray};
  position: absolute;
  width: 100%;
  top: 2.2rem;
  /* left: 0; */
  background-color: ${({ theme }) => theme.placeholderBackground};
  max-width: 180px;

  .icon {
    padding: 0;
    align-self: end;
  }

  input {
    display: none;
  }

  label {
    padding: 0 !important;
    font-size: 1vw;
    margin-bottom: 2px;
  }
  label:hover {
    background-color: black;
    color: white;
  }

  label:has(input:checked) {
    background-color: ${({ theme }) => theme.primaryGray};
    color: white;
  }

  .checked {
    padding: 10px;
    padding: 10px 20px;
    display: block;
    border-radius: 50px;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    left: 3vw;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;
interface isClientFilterOpened {
  isClientFilterOpened: boolean;
  isDateFilterOpened: boolean;
}
const MainContainer = styled.div<isClientFilterOpened>`
  position: relative;
  /* position: absolute; */
  /*   top: 100px;
  left: 0; */
  width: 100%;
  min-height: calc(100vh - 100px);
  padding-bottom: 1vh;
  margin-top: 1vh;
  padding: 0;
  //margin: 0;
  /* display: grid;
  grid-template-rows: 1fr fr; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-columns: 1fr; */
  display: flex;
  flex-direction: column;
  /* gap: 1vw; */ //USED TO BE 1VW
  //  overflow: scroll;

  .dateAccordion {
    height: fit-content;
    /* flex-wrap: wrap; */
    border-radius: 5px;
    margin-bottom: 4px;
    padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.secondaryGray};
    position: absolute;
    width: 100%;
    top: 2.2rem;
    background-color: ${({ theme }) => theme.placeholderBackground};
    max-width: 200px;
  }

  .GanttKPIs {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .GanttKPIs > * {
    /* flex: 1; */
    margin-right: 10px;
    width: 30vw;
  }

  .GanttContainerMain {
    width: 100%;
    position: absolute; //USED TO BE RELATIVE WITHOUT TOP
    top: 180px;
  }
  .selectedStuff {
    margin-top: 0.5rem;
    font-size: 0.6rem;
    font-family: 'fontRegular';
    font-weight: normal;
    font-style: italic;
    color: ${({ theme }) => theme.primaryGray};
  }

  .ribbon {
    position: absolute;
    top: 12px;
    left: 24px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 600px; */
    /* background-color: red; */
    width: 95%;
    padding: 5px;
    .filters {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
    }
  }
  .CCFilterSelected {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .CCFilter,
  .dateFilter,
  .sortFilter {
    padding: 4px 8px;
    border: 1px solid ${({ theme }) => theme.secondaryGray};
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.placeholderBackground};
    height: 1.7rem;

    svg {
      height: 0.8rem;
      width: 0.8rem;
      display: inline-flex;
      align-self: center;
      top: 0.125rem;
      position: relative;
      margin-left: 1rem;
    }

    h6 {
      font-size: 0.8rem;
      font-family: 'fontRegular';
      font-weight: normal;
      color: ${({ theme }) => theme.primaryGray};
    }
  }
  .searchIcon {
    height: 1.5rem;
    width: 1.5rem;
    color: ${({ theme }) => theme.primaryGray};
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 50%;

    transform: translateY(-50%);
  }
  input[type='date'] {
    background-color: transparent;
  }

  .title {
    color: ${({ theme }) => theme.primaryGray};
    margin-bottom: 0.1vw;
    font-size: 0.9rem;
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
  }
  .sectionContent {
    border-radius: 7px;
    padding: 10px;
    color: ${({ theme }) => theme.primaryGray};
    height: fit-content;
    border: 1px solid ${({ theme }) => theme.border};
    margin-bottom: 1vw;
    display: flex;
  }
  h5 {
    font-family: 'FontRegular', sans-serif;
    font-weight: normal;
  }

  .CCFilter {
    height: ${({ isClientFilterOpened }) => isClientFilterOpened && 'fit-content'};
  }
  .dateFilter {
    height: ${({ isDateFilterOpened }) => isDateFilterOpened && 'fit-content'};
  }

  .legend {
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    padding: 0px 8px;
    /* right: 44px; */
    display: flex;
    flex-direction: row;
    height: 1.7rem;

    .legendItem {
      display: flex;
      flex-direction: row;
      height: 1.5rem;
      background-color: ${({ theme }) => theme.placeholderBackground};
    }

    h6 {
      font-size: 0.8rem;
      font-family: 'fontRegular';
      font-weight: normal;
      color: ${({ theme }) => theme.primaryGray};
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
      margin-left: -3px;
    }
  }
`;

const MainStartPage = () => {
  const {
    campaignList,
    fetchedPortalData,
    setOrder,
    order,
    reducedClientList,
    clientFilterList,
    filterCampaignsPerClientCode,
    setClientFilterList,
    endDateFilter,
    permanentCampaignList,
    startDateFilter,
    setStartDateFilter,
    setEndDateFilter,
    setClientSearchTerm,
    excludeAlwaysOn,
    setExcludeAlwaysOn,
  } = useCampaignContext();
  const { activeOrganization } = useClientContext();
  const [theme, setTheme] = useState(JSON.parse(window.localStorage.getItem('myTheme')!));
  const [isClientFilterOpened, setIsClientFilterOpened] = useState(false);
  const [isDateFilterOpened, setIsDateFilterOpened] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Add 1 to the month to get a 1-based index
  const currentDay = currentDate.getDate();
  const currentYearStartDate = new Date(currentYear, 0, 1).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const currentYearEndDate = new Date(currentYear, 11, 31).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const threeMonthsAgo = new Date(currentYear, currentMonth - 3, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const sixMonthsAgo = new Date(currentYear, currentMonth - 6, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const eighteenMonthsAgo = new Date(currentYear, currentMonth - 18, currentDay).toISOString().substring(0, 10); // Format the date as "yyyy-mm-dd"
  const getendDate = () => {
    if (permanentCampaignList) {
      const endDateArray = permanentCampaignList?.map((el) => el?.endDate as string);
      const maxEndDate =
        endDateArray &&
        (endDateArray?.reduce((a, b) => {
          return a > b ? a : b;
        }) as string);
      // add 5 days to the maxEndDate
      const newDate = new Date(maxEndDate);
      newDate.setDate(newDate.getDate() + 5);
      const newDateTransformed = newDate.toISOString().substring(0, 10);
      return newDateTransformed;
    }
  };

  const arrayWithStatus = [
    {
      title: 'Not Briefed',
      color: '#BFBFBF',
    },
    {
      title: 'Briefed',
      color: '#EBC57D',
    },
    {
      title: 'Planned',
      color: '#E2FFE8',
    },
    {
      title: 'Running',
      color: '#A9C8C0',
    },
    {
      title: 'Completed',
      color: '#7D98AB',
    },
    // {
    //   title: 'Partly Published',
    //   color: '#8BAEDF',
    // },
    {
      title: 'Evaluated',
      color: '#AFA2C3',
    },
  ];

  interface IFilter {
    code: string;
    country: string;
  }
  const [a, setA] = useState<Array<IFilter>>([]);
  const handleClientCodes = (event: any) => {
    const obj: IFilter = {
      code: event.target.value,
      country: event.target.name,
    };
    event.target.checked
      ? setA((a) => [...a, obj])
      : setA((a) => [...a.filter((el) => !(el?.code === event.target.value && el?.country === event.target.name))]);
  };
  useEffect(() => {
    filterCampaignsPerClientCode(a);
  }, [a]);

  useEffect(() => {
    setClientFilterList([]);
  }, [activeOrganization]);
  //Meta performance - kvote 2
  const openedClientFilter = () => {
    return (
      <ClientsContainer>
        {reducedClientList?.map((e, index) => (
          <Selections key={e?.campaignId}>
            <input
              type="checkbox"
              checked={clientFilterList.some((el) => el?.code === e?.ClientCode && el?.country === e?.ClientCountry)}
              value={e?.ClientCode as string}
              name={e?.ClientCountry as string}
              onChange={handleClientCodes}
            />
            <span className="tooltip">
              {e?.ClientCode} {e?.ClientCountry}
              <span className="tooltiptext">{e?.ClientName}</span>
            </span>
          </Selections>
        ))}
      </ClientsContainer>
    );
  };

  const openedDateFilter = () => {
    return (
      <section className="dateAccordion" /* style={{ paddingTop: '7px' }} */>
        <div className="sectionContent" style={{ flexDirection: 'column' }}>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h5>Start Date</h5>
              {/* <input value={startDateFilter} onChange={(e) => setStartDateFilter(e.target.value)} type="date" /> */}
              <DatePicker
                className="datepicker"
                dateFormat="dd-MM-yyyy"
                selected={startDateFilter}
                onChange={(e: any) => setStartDateFilter(e.toISOString().slice(0, 10))}
                calendarStartDay={1}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h5>End Date</h5>
              {/* <input value={endDateFilter} onChange={(e) => setEndDateFilter(e.target.value)} type="date" /> */}
              <DatePicker
                className="datepicker"
                dateFormat="dd-MM-yyyy"
                selected={endDateFilter}
                onChange={(e: any) => setEndDateFilter(e.toISOString().slice(0, 10))}
                calendarStartDay={1}
              />
            </div>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', marginTop: '5px' }}>
            <button
              type="button"
              onClick={() => {
                setStartDateFilter(currentYearStartDate);
                setEndDateFilter(currentYearEndDate);
              }}
              style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
            >
              Current year
            </button>
            <button
              type="button"
              onClick={() => {
                setStartDateFilter(eighteenMonthsAgo);
                setEndDateFilter(getendDate() as string);
              }}
              style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
            >
              Max
            </button>
            <button
              type="button"
              onClick={() => {
                setStartDateFilter(threeMonthsAgo);
                setEndDateFilter(currentDate.toISOString().substring(0, 10));
              }}
              style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
            >
              Last 3 months
            </button>
            <button
              type="button"
              onClick={() => {
                setStartDateFilter(sixMonthsAgo);
                setEndDateFilter(currentDate.toISOString().substring(0, 10));
              }}
              style={{ fontSize: '12px', padding: '3px 6px', borderRadius: '5px' }}
            >
              Last 6 months
            </button>
          </div>
          <div></div>
        </div>
      </section>
    );
  };
  return (
    <MainContainer isClientFilterOpened={isClientFilterOpened} isDateFilterOpened={isDateFilterOpened}>
      <div className="GanttKPIs">
        {fetchedPortalData ? (
          <>
            {campaignList ? (
              /* campaignList?.filter(
                (el) => el?.authorized === true || (el?.authorized === false && el.status === 'PUBLISHED'),
              ).length > 0 && */ <KPIContainer></KPIContainer>
            ) : (
              <KPIContainerSkeleton></KPIContainerSkeleton>
            )}
            {/* <KPIContainerSkeleton></KPIContainerSkeleton> */}
            {/* <LinksContainer agency="annalect"></LinksContainer>
            <LinksContainer agency="hs"></LinksContainer> */}
            <LinksContainer agency="customLinks"></LinksContainer>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="GanttContainerMain">
        {campaignList ? (
          /* campaignList?.filter(
            (el) => el?.authorized === true || (el?.authorized === false && el.status === 'PUBLISHED'),
          ).length > 0 && */ <div>
            <GanttContainer></GanttContainer>
            <div className="ribbon">
              <div className="filters">
                <div className="dateFilter">
                  <h6 onClick={() => setIsDateFilterOpened(!isDateFilterOpened)}>
                    Filter by Date {isDateFilterOpened ? <RiCloseFill /> : <FaChevronDown />}
                  </h6>
                  {isDateFilterOpened && openedDateFilter()}
                  {!isDateFilterOpened && (
                    <h4 className="selectedStuff">
                      {' '}
                      {europinzedDate(startDateFilter)} - {europinzedDate(endDateFilter)}
                    </h4>
                  )}
                </div>
                <div className="CCFilter">
                  <h6 onClick={() => setIsClientFilterOpened(!isClientFilterOpened)}>
                    Filter by Client Code {isClientFilterOpened ? <RiCloseFill /> : <FaChevronDown />}
                  </h6>
                  {isClientFilterOpened && openedClientFilter()}
                  <div className="CCFilterSelected">
                    {a.map((el) => (
                      <h4 className="selectedStuff" key={el.code}>
                        {el.code} - {el.country} |
                      </h4>
                    ))}
                  </div>
                </div>
                <div className="sortFilter" onClick={() => (order === 'desc' ? setOrder('asc') : setOrder('desc'))}>
                  <h6>Sort {order === 'desc' ? <FaSortAmountDown /> : <FaSortAmountDownAlt />}</h6>
                </div>
                <div className="sortFilter" onClick={() => setExcludeAlwaysOn(!excludeAlwaysOn)}>
                  <h6>{excludeAlwaysOn ? 'Include alwaysOn' : 'Exclude alwaysOn'}</h6>
                </div>
                <div>
                  <IoIosSearch className="searchIcon" />
                  <input
                    onChange={(e) => setClientSearchTerm(e.target.value)}
                    style={{ fontSize: '0.8rem', padding: '5px', paddingLeft: '1.5rem', width: '150px' }}
                    type="text"
                  />
                </div>
              </div>
              <div className="legend">
                {arrayWithStatus.map((item) => {
                  return (
                    <div key={item.title} className="legendItem">
                      <SVG_CRICLE fill={item.color} />
                      <h6>{item.title}</h6>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <>
            <></>
            <GanttContainerStyled>
              <div>
                {Array(20)
                  .fill(0)
                  .map((_, index) => (
                    <Skeleton key={index} style={{ height: '35px', marginTop: index > 0 ? '10px' : '0' }} />
                  ))}
              </div>
            </GanttContainerStyled>
            {/* <div className="loaders">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </div> */}
          </>
        )}
      </div>
    </MainContainer>
  );
};

export default MainStartPage;
