/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const subscribeToUpdatedStatus = /* GraphQL */ `subscription SubscribeToUpdatedStatus($id: String) {
  subscribeToUpdatedStatus(id: $id) {
    id
    status
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToUpdatedStatusSubscriptionVariables,
  APITypes.SubscribeToUpdatedStatusSubscription
>;
export const overviewStatus = /* GraphQL */ `subscription OverviewStatus(
  $source: String
  $client: String
  $campaignId: String
) {
  overviewStatus(source: $source, client: $client, campaignId: $campaignId) {
    source
    severity
    status
    message
    client
    campaignId
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OverviewStatusSubscriptionVariables,
  APITypes.OverviewStatusSubscription
>;
