import { commentState, commentType, GetCampaignCommentsQuery, mediaSource } from 'API';
import { API } from 'aws-amplify';
import { getCampaignComments } from 'graphql/queries';
import { createContext, useContext, useEffect, useState } from 'react';
import { CampaignContext, useCampaignContext } from './CampaignContext';

export const CommentsContext = createContext({
  commentsList: [] as CommentDetails[],
  fetchCampaignComments: (a: any) => {},
  KPIValues: [] as CommentDetails[],
  overallComment: undefined as string | undefined,
});

type CommentDetails = {
  campaign: number;
  sub_campaign: number;
  placeholder: string;
  media_source: mediaSource;
  comment_type: commentType;
  comment: string;
  created_at: string;
  last_edited_at: string;
  created_by: string;
  last_edited_by: string;
  state: commentState;
};

export const CommentsContextProvider = (props: any) => {
  const { activeCampaign, dashType, activeSubCampaign } = useCampaignContext();
  const [commentsList, setCommentsList] = useState<any>();

  useEffect(() => {
    if (activeCampaign) {
      setCommentsList(undefined);

      if (
        dashType === 'CINEMA' ||
        dashType === 'PRINT' ||
        dashType === 'OOH' ||
        dashType === 'DIGITAL' ||
        dashType === 'FLOWCHART' ||
        dashType === 'HOME' ||
        dashType === 'INSOURCE_DATA'
      ) {
        fetchCampaignComments({
          campaign: activeCampaign?.campaignId,
          comment_type: commentType.COMMENT,
          media_source:
            dashType === 'FLOWCHART' || dashType === 'HOME'
              ? mediaSource.OVERALL
              : dashType === 'INSOURCE_DATA'
              ? mediaSource.DIGITAL
              : mediaSource[dashType as keyof typeof mediaSource],
        });
      } else if (dashType === 'RADIO' || dashType === 'TV') {
        if (activeSubCampaign && activeSubCampaign.subcampaignId !== -1) {
          fetchCampaignComments({
            campaign: -1,
            comment_type: commentType.COMMENT,
            media_source: mediaSource[dashType as keyof typeof mediaSource],
            sub_campaign: activeSubCampaign.subcampaignId,
          });
        }
      }
    } else {
      setCommentsList(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampaign, activeSubCampaign, dashType]);

  const [KPIValues, setKPIValues] = useState<any>([]);
  const [overallComment, setOverallComment] = useState<string | undefined>();

  async function fetchCampaignComments(v: any) {
    try {
      const response = (await API.graphql({
        query: getCampaignComments,
        variables: v,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetCampaignCommentsQuery };
      //    console.log('RETURN OF THE COMMENT', response.data.getCampaignComments);
      const responseList = response.data.getCampaignComments;

      if (
        dashType === 'CINEMA' ||
        dashType === 'PRINT' ||
        dashType === 'OOH' ||
        dashType === 'RADIO' ||
        dashType === 'TV'
      ) {
        const filteredComments = responseList?.filter((comment) => comment?.comment_type === 'COMMENT');
        filteredComments?.forEach((comment) => {
          if (comment && comment.placeholder === '-1') {
            // transform dashType so that first letter is uppercase and the rest is lowercase
            const dashTypeTransformed = dashType?.charAt(0).toUpperCase() + dashType?.slice(1).toLowerCase();
            comment.placeholder = `${dashTypeTransformed}0`;
          }
        });

        // go through filteredComments and find the one with the latest last_edited_at date
        if (filteredComments && filteredComments.length > 0) {
          let latestDate = new Date(0);
          let latestComment = filteredComments[0];
          filteredComments?.forEach((e) => {
            if (new Date(e?.last_edited_at as string) > latestDate) {
              latestDate = new Date(e?.last_edited_at as string);
              latestComment = e;
            }
          });
          setCommentsList([latestComment]);
        } else {
          setCommentsList(filteredComments);
        }

        /*    const filteredRest = responseList?.filter(comment => comment?.comment_type !== "COMMENT")
                   filteredRest?.forEach(comment => {
                       if (comment && dashType === "PRINT") {
                           // transform dashType so that first letter is uppercase and the rest is lowercase
                           const dashTypeTransformed = dashType?.charAt(0).toUpperCase() + dashType?.slice(1).toLowerCase()
                           comment.placeholder = comment?.comment_type === "COVERAGE" ? `${dashTypeTransformed}KPI2` : comment?.comment_type === "READERS" ? `${dashTypeTransformed}KPI3` : `${dashTypeTransformed}KPI4`
                       } else if (comment && dashType === "OOH") {
                           comment.placeholder = comment?.comment_type === "COVERAGE" ? `${dashType}KPI2` : comment?.comment_type === "READERS" ? `${dashType}KPI3` : `${dashType}KPI4`
                       }
   
                   })
                   setKPIValues(filteredRest) */
      } else {
        setCommentsList(responseList);
      }
    } catch (e) {
      console.log(`error in campaign comments ${e}`);
    }
  }

  return (
    <CommentsContext.Provider
      value={{
        commentsList,
        fetchCampaignComments,
        KPIValues,
        overallComment,
      }}
    >
      {props.children}
    </CommentsContext.Provider>
  );
};
