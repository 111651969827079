import { DashboardTypes, SwitchUserModeMutation, UserMode } from 'API';
import { API } from 'aws-amplify';
import { LoginComponent } from 'components/Aside/LoginComponent';
import { lightTheme } from 'components/style/Theme';

import APIContext from 'context/APIContext';
import AuthContext from 'context/AuthContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { switchUserMode } from 'graphql/mutations';
import { useContext, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as SVG_SETTINGS } from '../../assets/SVG/settings.svg';
import { ReactComponent as SVG_DARK } from '../../assets/newAssets/Property 1=dark.svg';
import { ReactComponent as SVG_DIGITAL } from '../../assets/newAssets/Property 1=digital.svg';
import { ReactComponent as SVG_HOME } from '../../assets/newAssets/Property 1=home.svg';
import { ReactComponent as SVG_LIGHT } from '../../assets/newAssets/Property 1=light.svg';
import { ReactComponent as SVG_OOH_KAMPAGNE } from '../../assets/newAssets/Property 1=ooh.svg';
import { ReactComponent as SVG_PRINT } from '../../assets/newAssets/Property 1=print.svg';
import { ReactComponent as SVG_RADIO } from '../../assets/newAssets/Property 1=radio.svg';
import { ReactComponent as SVG_TV } from '../../assets/newAssets/Property 1=video.svg';
import { ReactComponent as SVG_CREATE } from '../../assets/newAssets/create.svg';
import { ReactComponent as SVG_PREVIEW } from '../../assets/newAssets/preview.svg';
import { AsideContainer, NavItem, Navigation } from './Aside.styles';
import { ReactComponent as SVG_FLOWCHART } from '../../assets/newAssets/Property 1=flowChart.svg';
import { ReactComponent as SVG_CINEMA } from '../../assets/SVG/cinema.svg';
import { useUserAccess } from 'customHooks/useUserAccess';
import { useCampaignId } from 'customHooks/useParameters';
import { useDataSources } from 'customHooks/useDataSources';
import { useEnrichedContext } from 'context/EnrichedContext';

import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';
import InputContext from 'context/InputContext';

const { REACT_APP_LOGO } = process.env;

export const NavLink = styled(Link)`
  width: 3vw;
  height: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center; /* vertically aligns items */
  gap: 2px;
  text-decoration: none;
  font-size: 1vw;

  color: ${({ theme }) => theme.primaryGray};
  /*   .svg {
    height: 1.5vw;
    width:100% ;
    fill: ${({ theme }) => theme.primaryGray};
    path {
      stroke: ${({ theme }) => theme.primaryGray};
      stroke-width: 1;
    }
  } */
`;

export const NavDiv = styled.div`
  width: 3vw;
  height: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center; /* vertically aligns items */
  gap: 2px;
  text-decoration: none;
  font-size: 1vw;

  color: ${({ theme }) => theme.primaryGray};
  &:hover {
    cursor: pointer;
  }
  /*   .svg {
    height: 1.5vw;
    width:100% ;
    fill: ${({ theme }) => theme.primaryGray};
    path {
      stroke: ${({ theme }) => theme.primaryGray};
      stroke-width: 1;
    }
  } */
`;

const { REACT_APP_AGENCY_URL } = process.env;

export const http = {
  DEMO: 'https://nordics-demo.omd.com',
  STAGING: 'https://nordics-staging.annalect.com',
  PRODUCTION: `https://nordics.${REACT_APP_AGENCY_URL}.com`,
};

type Props = {
  // dashType?: string;
  themeToggler: any;
  theme: any;
};

const Aside = (props: Props) => {
  const {
    checkPaddington,
    activeOrganization,
    anyPublish,
    anyCreate,
    isOnlyDanish,
    hasMoreThanOneCountry,
    isThereAnyReadPlus,
    setActiveOrganization,
  } = useClientContext();
  const { activeCampaign, campaignList, setDashType, dashType, navigationCampaignList } = useCampaignContext();
  const memoizedCampaignList = useMemo(() => campaignList, [campaignList]);
  const memoizedActiveCampaign = useMemo(() => activeCampaign, [activeCampaign]);
  const { getEnvironment } = useContext(APIContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userCanDoInsource } = useClientContext();

  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { fullSources } = useEnrichedContext();
  // const [fullSources] = useDataSources(activeCampaign);

  async function changeMode(mode: string) {
    try {
      const response = (await API.graphql({
        query: switchUserMode,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          UserMode: UserMode[mode as keyof typeof UserMode],
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: SwitchUserModeMutation };

      //  response.data.switchUserMode?.StatusCode === 200 ? window.location.reload() : console.log("error")
      response.data.switchUserMode?.some((e) => e?.Status?.StatusCode === 200)
        ? window.location.reload()
        : console.log('error');
    } catch (e) {
      console.log(e);
    }
  }

  const redirectHome = () => {
    setDashType(DashboardTypes.HOME);
    setActiveOrganization(undefined);
  };

  /*  const tempIsOnlyDanish = true;
  const tempHasMoreThanOneCountry = true;

  // I need a boolean that show true if isOnlyDanish is true and hasMoreThanOneCountry is false in all other combinations it should be false

  // const isDanishAndOnlyOneCountry = tempIsOnlyDanish && !tempHasMoreThanOneCountry;
  const isDanishAndOnlyOneCountry = isOnlyDanish && !hasMoreThanOneCountry; */

  const dashboardTypeMap: { [key: string]: DashboardTypes } = {
    DIGITAL: DashboardTypes.DIGITAL,
    TV: DashboardTypes.TV,
    RADIO: DashboardTypes.RADIO,
    PRINT: DashboardTypes.PRINT,
    OOH: DashboardTypes.OOH,
    HOME: DashboardTypes.HOME,
    CINEMA: DashboardTypes.CINEMA,
    FLOWCHART: DashboardTypes.FLOWCHART,
    INSOURCE_DATA: DashboardTypes.INSOURCE_DATA,
    OVERWRITE: DashboardTypes.OVERWRITE,
  };

  const handleClick = (link: string, dashboardType: string, subCampaigns: boolean) => {
    const search = searchParams.toString();
    // slice search string from first letter to first ampersand
    const paramsClient = search.slice(0, search.indexOf('&') !== -1 ? search.indexOf('&') : search.length);

    if (dashboardType in dashboardTypeMap) {
      setDashType(dashboardTypeMap[dashboardType]);
    }

    const searchParam = 'subcampaignID';
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (subCampaigns === false) {
      newSearchParams.delete(searchParam);
      const finalSearch = newSearchParams.toString();

      if (dashboardType !== dashType) {
        navigate(link + '?' + (link === '/reporting/portal' ? paramsClient : finalSearch), {
          state: { client: paramsClient },
        });
      }
      // else {
      //   navigate(link + '?' + finalSearch);
      // }
    } else {
      newSearchParams.delete(searchParam);
      let finalSearch = newSearchParams.toString();

      if (dashboardType !== dashType) {
        navigate(link + '?' + finalSearch);
      }
    }

    // if (campaignID === "none") {
    //   navigate(link + '?' + paramsClient, {
    //     state: { client: paramsClient },
    //   });
    // }
  };
  //TODO NEW SOURCE
  const hasDigital = fullSources?.some(
    (source) =>
      source.name === 'Adform' ||
      source.name === 'DV360' ||
      source.name === 'CM' ||
      source.name === 'Facebook' ||
      source.name === 'Youtube' ||
      source.name === 'TikTok' ||
      source.name === 'Snapchat' ||
      source.name === 'LinkedIn' ||
      source.name === 'Readpeak' ||
      source.name === 'Pinterest' ||
      source.name === 'Taboola' ||
      source.name === 'Google Ads' ||
      source.name === 'Marathon Digital Tracking',
  );

  const activeCampaignHasTVInternal = fullSources?.some((source) => source.name === 'TV');
  const activeCampaignHasRadioInternal = fullSources?.some((source) => source.name === 'RADIO');
  const activeCampaignHasPrintInternal = fullSources?.some((source) => source.name === 'PRINT');
  const activeCampaignHasOOHInternal = fullSources?.some((source) => source.name === 'OOH');
  const activeCampaignHasCinemaInternal = fullSources?.some((source) => source.name === 'CINEMA');

  const campaignListHasDigitalInternal = navigationCampaignList?.some((e) =>
    e?.dashboardType.some((e) => e === 'DIGITAL'),
  );
  const campaignListHasTVInternal = navigationCampaignList?.some((e) => e?.dashboardType.some((e) => e === 'TV'));
  const campaignListHasRadioInternal = navigationCampaignList?.some((e) => e?.dashboardType.some((e) => e === 'RADIO'));
  const campaignListHasPrintInternal = navigationCampaignList?.some((e) => e?.dashboardType.some((e) => e === 'PRINT'));
  const campaignListHasOOHInternal = navigationCampaignList?.some((e) => e?.dashboardType.some((e) => e === 'OOH'));
  const campaignListHasCinemaInternal = navigationCampaignList?.some((e) =>
    e?.dashboardType.some((e) => e === 'CINEMA'),
  );

  const creatorIcon = iconList.find((icon) => icon === 'screen_share');
  const handymanIcon = iconList.find((icon) => icon === 'handyman');
  const MediaLink = (dashboard: DashboardTypes) => {
    const lookup = {
      DIGITAL: {
        link: '/reporting/onlinePortal',
        icon: () => {
          return <SVG_DIGITAL className="svg" />;
        },
        title: 'Digital',
        subCampaigns: false,
      },
      TV: {
        link: '/reporting/TVPortal',
        icon: () => {
          return <SVG_TV className="svg" />;
        },
        title: 'Video',
        subCampaigns: true,
      },
      RADIO: {
        link: '/reporting/radioPortal',
        icon: () => {
          return <SVG_RADIO className="svg" />;
        },
        title: 'Radio',
        subCampaigns: true,
      },
      PRINT: {
        link: '/reporting/printPortal',
        icon: () => {
          return <SVG_PRINT className="svg" />;
        },
        title: 'Print',
        subCampaigns: false,
      },
      OOH: {
        link: '/reporting/OOHPortal',
        icon: () => {
          return <SVG_OOH_KAMPAGNE className="svg" />;
        },
        title: 'Out of Home',
        subCampaigns: false,
      },
      HOME: {
        link: '/',
        icon: () => {
          return <SVG_HOME className="svg" />;
        },
        title: 'Home',
        subCampaigns: false,
      },
      FLOWCHART: {
        link: '/reporting/portal',
        icon: () => {
          return <SVG_FLOWCHART className="svg" />;
        },
        title: 'Overview',
        subCampaigns: false,
      },
      CINEMA: {
        link: '/reporting/cinemaPortal',
        icon: () => {
          return <SVG_CINEMA className="svg" />;
        },
        title: 'Cinema',
        subCampaigns: false,
      },
      BVOD: {
        link: '/reporting/BVODPortal',
        icon: () => {
          return <SVG_CINEMA className="svg" />;
        },
        title: 'BVOD',
        subCampaigns: false,
      },
      INSOURCE_DATA: {
        link: '/reporting/insourceDataPortal',
        title: 'Insource data',
        icon: () => {
          return (
            <div style={{ fontSize: '1vw' }} className="svg material-symbols-outlined  ">
              {creatorIcon}
            </div>
          );
        },

        subCampaigns: false,
      },
      OVERWRITE: {
        link: '/reporting/dataOverwrite',
        icon: () => {
          return (
            <div style={{ fontSize: '1vw' }} className="svg material-symbols-outlined">
              {handymanIcon}
            </div>
          );
        },
        title: 'Overwrite Data',
        subCampaigns: true,
      },

      // empty: {
      //   link: '/reporting/BVODPortal',
      //   icon: () => {
      //     return <SVG_CINEMA className="svg" />;
      //   },
      //   title: 'BVOD',
      //   subCampaigns: false,
      // },
    };

    const { link, icon, title, subCampaigns } = lookup[dashboard];

    return (
      <NavDiv
        onClick={() => handleClick(link, dashboard, subCampaigns)}
        className={dashType === dashboard ? 'selected' : 'null'}
      >
        {icon()}
        <h4 className="navigationTitle">{title}</h4>
      </NavDiv>
    );
  };

  const LogoImage = ({ theme }: { theme: string }) => (
    <img
      style={{ width: '70%' }}
      className="bar__clientLogo"
      src={require(`../../assets/${theme}${REACT_APP_LOGO}`)}
      alt="Logo"
    />
  );

  return (
    <>
      {dashType !== null && (
        <>
          <AsideContainer dashType={dashType}>
            <div className="logodiv" onClick={() => redirectHome()}>
              <NavLink to="/">
                <div className="logo">
                  {dashType !== DashboardTypes.HOME &&
                  window.localStorage.getItem('myTheme') === JSON.stringify(lightTheme) ? (
                    <LogoImage theme="light" />
                  ) : (
                    <LogoImage theme="dark" />
                  )}
                </div>
              </NavLink>
            </div>

            <Navigation dashType={dashType}>
              {dashType !== DashboardTypes.HOME && (
                <div>
                  {/* <NavLink className={location.pathname === '/reporting' ? 'selected' : 'null'} to="/">
              <div onClick={() => redirectHome()}>
                <SVG_HOME className="svg" />
                <h4 className="navigationTitle">Home</h4>
              </div>      
            </NavLink> */}

                  {memoizedCampaignList && (
                    <>
                      {memoizedActiveCampaign ? (
                        <>
                          {(anyPublish || isThereAnyReadPlus) && MediaLink(DashboardTypes.FLOWCHART)}
                          {/* {userAccess && MediaLink(DashboardTypes.FLOWCHART)} */}
                          {hasDigital && MediaLink(DashboardTypes.DIGITAL)}
                          {activeCampaignHasTVInternal && MediaLink(DashboardTypes.TV)}
                          {activeCampaignHasRadioInternal && MediaLink(DashboardTypes.RADIO)}
                          {activeCampaignHasPrintInternal && MediaLink(DashboardTypes.PRINT)}
                          {activeCampaignHasOOHInternal && MediaLink(DashboardTypes.OOH)}
                          {activeCampaignHasCinemaInternal && MediaLink(DashboardTypes.CINEMA)}
                          {hasDigital && userCanDoInsource && MediaLink(DashboardTypes.INSOURCE_DATA)}
                        </>
                      ) : (
                        <>
                          {(anyPublish || isThereAnyReadPlus) && MediaLink(DashboardTypes.FLOWCHART)}
                          {/* {userAccess && MediaLink(DashboardTypes.FLOWCHART)} */}
                          {campaignListHasDigitalInternal && MediaLink(DashboardTypes.DIGITAL)}
                          {campaignListHasTVInternal && MediaLink(DashboardTypes.TV)}
                          {campaignListHasRadioInternal && MediaLink(DashboardTypes.RADIO)}
                          {campaignListHasPrintInternal && MediaLink(DashboardTypes.PRINT)}
                          {campaignListHasOOHInternal && MediaLink(DashboardTypes.OOH)}
                          {campaignListHasCinemaInternal && MediaLink(DashboardTypes.CINEMA)}
                          {campaignListHasDigitalInternal &&
                            userCanDoInsource &&
                            MediaLink(DashboardTypes.INSOURCE_DATA)}
                        </>
                      )}
                    </>
                  )}
                  {checkPaddington && (
                    <>
                      <NavLink
                        to="/reporting/userAdmin"
                        onClick={() => setDashType(DashboardTypes.HOME)}
                        className={location.pathname === '/reporting/userAdmin' ? 'selected' : 'null'}
                      >
                        <SVG_SETTINGS className="svg" />
                        <h4 className="navigationTitle">User Admin</h4>
                      </NavLink>

                      {/* {((memoizedActiveCampaign && hasDigital) ||
                        (!memoizedActiveCampaign && campaignListHasDigitalInternal)) &&
                        MediaLink(DashboardTypes.OVERWRITE)} */}
                    </>
                  )}
                </div>
              )}

              <div className="bottomNav">
                {activeOrganization && anyPublish && (
                  <>
                    {anyCreate ? (
                      <NavItem onClick={() => changeMode('PRESENTATION')}>
                        <SVG_CREATE className="svg" />
                        <h4 className="navigationTitle">Preview Off</h4>
                      </NavItem>
                    ) : (
                      <NavItem onClick={() => changeMode('DEVELOP')}>
                        <SVG_PREVIEW className="svg green" />
                        <h4 className="navigationTitle green">Preview On</h4>
                      </NavItem>
                    )}
                  </>
                )}

                {/* {dashType !== DashboardTypes.HOME && (
            <NavItem className="tog" onClick={() => props.themeToggler()}>
              {window.localStorage.getItem('myTheme') === JSON.stringify(lightTheme) ? (
                <SVG_LIGHT className="svg" />
              ) : (
                <SVG_DARK className="svg" />
              )}
              <h4 className="navigationTitle">Theme</h4>
            </NavItem>
          )} */}

                <LoginComponent wrapper={'noWrapper'}></LoginComponent>
                <section className="user">
                  <p>
                    {user?.signInUserSession.idToken.payload.name.at(0)}
                    {user?.signInUserSession.idToken.payload.family_name.at(0)}
                  </p>
                </section>
              </div>
            </Navigation>
          </AsideContainer>
        </>
      )}
    </>
  );
};

export default Aside;
