import { DashboardTypes, GetSubCampaignsQuery } from "API";
import { API } from "aws-amplify";
import { getSubCampaigns } from "graphql/queries";

interface SubCampaignResponse {
  __typename: "subCampaignResponse";
  subcampaignId?: number | null;
  name?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  mediaGroup?: string | null;
}

export type FetchSubCampaignsResponse = Promise<(SubCampaignResponse | null)[] | null | undefined>;

export const fetchSubCampaignsFunction = async (campaignID: string, dashType: DashboardTypes | null): FetchSubCampaignsResponse => {
  try {
    const response = (await API.graphql({
      query: getSubCampaigns,
      variables: {
        campaignId: campaignID,
        media: dashType,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as { data: GetSubCampaignsQuery };
    const result = response.data.getSubCampaigns;
    return result;
  } catch (e) {
    console.error('Fetching subCampaigns failed:', e);
    return [];
  }
}