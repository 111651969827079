import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { placeholderFullSources } from './PlaceholderContainer';
import {
  BottomRow,
  Breadcrumbs,
  Conversions,
  Line,
  MidRow,
  NavigationButton,
  RightPane,
  Selections,
  TopRow,
} from 'components/Main/table/TableStyles';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import {
  agencyEnum,
  calculatedMetricsDetail,
  countryEnum,
  datatype,
  detailedDimensionsDetail,
  filterDetail,
  filtertype,
  localQuery,
  metric,
  metricFunction,
  queryInput,
  view,
} from 'API';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import AuthContext from 'context/AuthContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { PublishContext } from 'context/PublishContext';
import { set } from 'lodash';
import { CCData, useDataQuery } from 'customHooks/useDataQuery';

type Props = {
  isGraph: Boolean;
  placeHolderDataSource: placeholderFullSources[];
  setPage(page: any): any;
  nextPage(): any;
  previousPage(): any;
  page: number;
  selectCustomConversion: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ConversionsContainer = (props: Props) => {
  const { isGraph, placeHolderDataSource, setPage, nextPage, previousPage, page, selectCustomConversion } = props;
  const selectedPlatform = placeHolderDataSource.find((e) => e.isSelected);
  const { user } = useContext(AuthContext);
  const { activeCampaign } = useCampaignContext();
  const [serchTerm, setSearchTerm] = useState<string>('');

  return (
    <Conversions displayConversions={page}>
      <TopRow isGraph={isGraph}>
        <Breadcrumbs>
          <label style={{ cursor: 'pointer' }} onClick={() => setPage(1)}>
            {selectedPlatform?.name}
          </label>
          <Line>|</Line>
          <label style={{ cursor: 'pointer' }} onClick={() => setPage(2)}>
            Dimensions:
          </label>
          {selectedPlatform?.dimensions
            ?.filter((e) => e.isSelected)
            .map((dimension: any, key: number) => (
              <span key={key}>{dimension.dimension}</span>
            ))}
          <Line>|</Line>
          <label style={{ cursor: 'pointer' }} onClick={() => setPage(3)}>
            Metrics:
          </label>
          {selectedPlatform?.metrics
            ?.filter((e) => e.isSelected)
            .map((metric: any, key: number) => (
              <span key={key}>{metric.metric}</span>
            ))}
          {selectedPlatform?.calcMetrics
            ?.filter((e) => e.isSelected)
            .map((metric: any, key: number) => (
              <span key={key}>{metric.name}</span>
            ))}
        </Breadcrumbs>
      </TopRow>

      <MidRow>
        <h1>Custom Conversions</h1>
        <br />
        <h2>
          Chosen Conversions :
          <span>
            <div style={{ width: isGraph === true ? '40vw' : 'auto', display: 'flex', flexWrap: 'wrap' }}>
              {selectedPlatform?.customConversions
                ?.filter((e) => e.isSelected)
                .map((c) => (
                  <Selections key={c.CCname}>
                    <input
                      type="checkbox"
                      checked={c.isSelected}
                      name={c.CCname}
                      value={c.CCname}
                      onChange={(e) => selectCustomConversion(e)}
                    />
                    <span>{c.CCname}</span>
                  </Selections>
                ))}
            </div>
          </span>
        </h2>

        <RightPane>
          <input
            className="searchBar"
            type="text"
            placeholder="Search.."
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          ></input>
          <div className="listContainer">
            {selectedPlatform?.customConversions &&
              selectedPlatform?.customConversions.length > 0 &&
              selectedPlatform?.customConversions
                ?.filter((val) => val?.CCname.toLowerCase().includes(serchTerm.toLowerCase()))
                .map((c) => (
                  <Selections key={c.CCname}>
                    <input
                      type="checkbox"
                      checked={c.isSelected}
                      name={c.CCname}
                      value={c.CCname}
                      onChange={(e) => selectCustomConversion(e)}
                    />
                    <span>{c.CCname}</span>
                  </Selections>
                ))}
          </div>

          {/*    {queryStatus === 'RUNNING' && (
                        <>
                            <h1>Please wait</h1>
                            <h2>Fetching the list of custom conversion</h2>
                        </>
                    )} */}
          {selectedPlatform?.customConversions?.length === 0 && (
            <>
              <h1>No custom conversions found</h1>
              <h2>Press next to continue</h2>
            </>
          )}
        </RightPane>
      </MidRow>

      <BottomRow isGraph={isGraph}>
        <NavigationButton type="button" style={{ backgroundColor: 'transparent' }} onClick={previousPage}>
          <GoChevronLeft /> Back
        </NavigationButton>
        <NavigationButton style={{ backgroundColor: 'transparent' }} type="button" onClick={nextPage}>
          Continue <GoChevronRight />
        </NavigationButton>
      </BottomRow>
    </Conversions>
  );
};

export default ConversionsContainer;
