import { API } from 'aws-amplify';
import { useClientCode } from 'customHooks/useParameters';
import { startQuery } from 'graphql/mutations';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ActionEnum,
  Clients,
  Environment,
  GetBriefTemplateQuery,
  GetClientListQuery,
  GetConcernsQuery,
  GetInsightsDashboardsQuery,
  GetPortalDashboardsQuery,
  GetProjectLevelQuery,
  GetUserQuery,
  GetUserV2Query,
  PortalDashboardItem,
  StartQueryMutation,
  StatementType,
  UserConcerns,
  UserIsAuthorizedMultiQuery,
  UserIsAuthorizedQuery,
  briefTemplateSection,
  country,
  countryEnum,
  getUserTypeV2,
  queryInput,
} from '../API';
import { getBriefTemplate, getClientList, getConcerns, getInsightsDashboards, getPortalDashboards, getProjectLevel, getUser, getUserV2, userIsAuthorized, userIsAuthorizedMulti } from '../graphql/queries';
import APIContext from './APIContext';
import AuthContext from './AuthContext';
import { set } from 'lodash';

export interface OrganizationWithCountry {
  country: {
    country: string;
    countryEnum: string;
  };
  Organization?: string | undefined;
  OrganizationId?: string | undefined;
};


// interface IPaddingtonUser {
//   Username: String;
//   Name: String;
//   Family_name: String;
//   Email: String;
//   HasFullAccess: any[];
//   HasReadAccess: any[];
//   HasNoAccess: any[];
// }

interface IInsightsDashboards {
  ClientCode: String;
  ClientCountry: String;
  Resources: [{
    Dashboard: String;
    Access: Boolean
  }]
}

export type IActiveOrganization = {
  Organization: string,
  OrganizationId: string
} | undefined

type ClientContextProps = {
  setActiveOrganization: (args: IActiveOrganization) => void,
  setCheckParams: (args: any) => void,
  activeOrganization: IActiveOrganization,
  unpublishedStatement: any[],
  insights: PortalDashboardItem[] | undefined,
  activities: PortalDashboardItem[] | undefined,
  checkPaddington: getUserTypeV2 | undefined,
  organizationList: IActiveOrganization[],
  insightsPaddington: PortalDashboardItem[] | undefined,
  insightsDashboards: IInsightsDashboards[] | undefined,
  handleCountrySelection: (ctry: country) => void,
  setOrganizationList: (args: IActiveOrganization[]) => void,
  anyPublish: boolean,
  anyCreate: boolean,
  briefTemplate: briefTemplateSection[] | undefined,
  briefTemplateID: string,
  fetchBriefTemplate: () => void,
  setOrganizationListCountry: (args: country | undefined) => void
  organizationListWithCountry: OrganizationWithCountry[]
  isOnlyDanish: boolean,
  hasMoreThanOneCountry: boolean,
  fetchMultipleAuthorizations: (action: string) => void,
  userAccess: UserAccess | null,
  typeOfReadAccess: ReadAccessType[],
  getLevel: (fullClients: any[]) => void,
  isThereAnyReadPlus: boolean,
  userCanDoInsource: boolean,
  loggedInUserAccessType: getUserTypeV2 | undefined
}

export interface UserAccess {
  fullAccess: boolean,
  readAccess: boolean,
  noAccess: boolean
}

export type ReadAccessType = {
  ClientCode: string,
  ClientCountry: string,
  Level: string
} | []


const ClientContext = createContext<ClientContextProps | undefined>(undefined)

export const ClientContextProvider = (props: any) => {

  const [activeOrganization, setActiveOrganization] = useState<IActiveOrganization>();
  const [checkParams, setCheckParams] = useState();
  const { isAuthorized, user } = useContext(AuthContext);
  // const isTryg = activeOrganization?.OrganizationId === "TRY9#DK" && !user?.username.includes("annalect")
  const [unpublishedStatement, setUnpublishedStatement] = useState<any>();
  const [activities, setActivities] = useState<Array<PortalDashboardItem>>();
  const [insights, setInsights] = useState<Array<PortalDashboardItem>>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activitiesPaddington, setActivitiesPaddington] = useState<Array<PortalDashboardItem>>();
  const [insightsPaddington, setInsightsPaddington] = useState<Array<PortalDashboardItem>>();
  const [checkPaddington, setCheckPaddington] = useState<getUserTypeV2>();
  const [organizationList, setOrganizationList] = useState<Array<IActiveOrganization>>([]);
  const { getEnvironment } = useContext(APIContext);
  const [insightsDashboards, setInsightsDashboards] = useState<Array<IInsightsDashboards>>();
  const [anyPublish, setAnyPublish] = useState<boolean>(false)
  const [anyCreate, setAnyCreate] = useState<boolean>(false)
  const [userAccess, setUserAccess] = useState(null as UserAccess | null)

  const [org] = useClientCode()

  // based on the organisationID in organisationList, determine the country
  // the country is what comes after # in the organisationID
  // we only need the distinct countries
  // this is the list of countries that
  // [
  //   "NO",
  //   "DK",
  //   "FI",
  //   "SE",
  //   "NC",
  //   "GL",
  //   null
  // ]
  // they should be transformed to this
  // [
  //  "NORWAY"
  //  "DENMARK"
  //  "FINLAND"
  //  "SWEDEN"
  //  "NORDICS"
  //  "GLOBAL"

  const translateCountryList = useMemo(() => [
    { country: "NO", countryEnum: 'NORWAY' },
    { country: "DK", countryEnum: 'DENMARK' },
    { country: "FI", countryEnum: 'FINLAND' },
    { country: "SE", countryEnum: 'SWEDEN' },
    { country: "NC", countryEnum: 'NORDICS' },
    { country: "GL", countryEnum: 'GLOBAL' },
    { country: null, countryEnum: 'NA' }
  ], []);

  const distinctCountries = organizationList.map((e) => {
    const country = e?.OrganizationId.split("#")[1]
    const foundCountry = translateCountryList.find((e) => e.country === country)
    return foundCountry
  }).filter((e, i, a) => a.indexOf(e) === i)


  const isOnlyDanish = distinctCountries.length === 1 && distinctCountries[0]?.country === "DK"

  const hasMoreThanOneCountry = distinctCountries.filter(c => c?.country !== 'NC' && c?.country !== 'GL' && c?.country !== null).length > 1



  const organizationListWithCountry: OrganizationWithCountry[] = organizationList.map((e) => {
    const country = e?.OrganizationId.split("#")[1]
    const foundCountry = translateCountryList.find((e) => e.country === country)
    if (foundCountry) {
      if (foundCountry.country === null) {
        return { ...e, country: { country: "NA", countryEnum: 'NA' } }
      }
      return { ...e, country: foundCountry }
    }
    return { ...e, country: { country: "NA", countryEnum: 'NA' } }

  }
  )


  // srediti subcampaigns da se citaju iz search param
  // napraviti da se u client dropdownu ne prikazuje drzava vec samo ime klijenta ako imas jednu drzavu
  // promjena dashboarda treba zadrzati kampanju i klijenta
  // aside treba postati div umjesto NavLink
  // na home page promjeniti da se prikazuju samo dostupni dashboardi
  // hs staging
  //provjeriti komentare na offline portalima, nesto povzano sa subcampaigns




  const handleCountrySelection = (country: country) => {
    setOrganizationListCountry(country)
    // setOrganizationList([])
    // fetchConcerns({ Environment: getEnvironment, Country: ctry });
  };

  // const org = "ska1#dk"

  const countryFromOrg = org.toLowerCase() === 'none' ? 'none' : org.split("#")[1]
  const isAnnalectUser = user?.username.includes("annalect");


  const [organizationListCountry, setOrganizationListCountry] = useState<country | undefined>();
  //IF AUTHORIZED; FETCH CONCERNS
  useEffect(() => {

    // FETCH CONCERNS, SET ORGANIZATION LIST THAT CREATES DROPDOWN MENU
    const fetchConcerns = async (vars: { Environment: Environment, Country: country | undefined }) => {

      try {
        const response = (await API.graphql({
          query: getConcerns,
          variables: vars,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetConcernsQuery };


        // const test = normalFunction(response.data.getConcerns as Array<IActiveOrganization>)
        return response.data.getConcerns as Array<IActiveOrganization>
      } catch (e) {
        console.log('ERROR GET CONCERNS', e);
      }
    };

    // call fetchConcerns in parallel with the translateCountryList and await all results
    // then return all results in an array with the country property and the result of the fetchConcerns

    const fetchConcernsParallel = async () => {
      const all = await Promise.all(translateCountryList.filter(c => c.country !== null).map(async (e) => {
        const res = await fetchConcerns({ Environment: getEnvironment, Country: e.country as country })
        return { country: e.country, list: res }
      }))
      return all
    }




    if (isAuthorized) {
      fetchConcerns({ Environment: getEnvironment, Country: organizationListCountry }).then((res) => {
        if (res) {
          // first we need to check if there are more than one country in the list
          // if there is only one country, we setOrganizationList to the res
          // if there is more than one country, we need to map through the translateCountryList and add the combined result to setOrganizationList

          const distinctCountriesInRes = res.map((e) => {
            const country = e?.OrganizationId.split("#")[1]
            const foundCountry = translateCountryList.find((e) => e.country === country)
            return foundCountry
          }).filter((e, i, a) => a.indexOf(e) === i)

          const moreThanOneCountryInRes = distinctCountriesInRes.filter(c => c?.country !== 'NC' && c?.country !== 'GL' && c?.country !== null).length > 1

          if (moreThanOneCountryInRes) {
            const orgList: IActiveOrganization[] = []
            fetchConcernsParallel().then((res) => {

              res.forEach((e) => {
                // add e to orgList using spread operator
                if (e.list) {
                  orgList.push(...e.list)
                }
              })
              setOrganizationList(orgList)
              // setOrganizationList([orgList[0]])
            })




            // setOrganizationList(orgList)
          } else {
            setOrganizationList(res)
          }
        }
      }
      ).catch((e) => console.error("ERROR IN FETCH CONCERNS", e))
    }
  }, [isAuthorized, organizationListCountry, getEnvironment, translateCountryList]);



  // GET (DASHBOARDS)
  const fetchDashboards = useCallback(async () => {

    // const activeDetails = clients.find((e: any) => e.Organization === activeOrganization);
    setActivities([]);
    setInsights([]);

    try {
      const response = (await API.graphql({
        query: getPortalDashboards,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment, // Environment.DEMO,
          StatementType: StatementType.Access
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetPortalDashboardsQuery };
      const data = response.data.getPortalDashboards;
      //   console.log(data, "GET PORTAL DASBOARDS ACCESS");

      setActivities(data?.activities as PortalDashboardItem[]);
      setInsights(data?.insights as PortalDashboardItem[]);
    } catch (e) {
      console.log('ERROR IN FETCH DASHBOARDS', e);
    }
  }, [activeOrganization, getEnvironment]);


  // GET PADDINGTON ACCESS TYPE (DASHBOARDS)
  const fetchDashboardsPaddington = useCallback(async () => {
    // const activeDetails = clients.find((e: any) => e.Organization === activeOrganization);
    setActivitiesPaddington([]);
    setInsightsPaddington([]);
    try {
      const response = (await API.graphql({
        query: getPortalDashboards,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment, // Environment.DEMO,
          StatementType: StatementType.Paddington
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetPortalDashboardsQuery };
      const data = response.data.getPortalDashboards;
      //   console.log(data, "GET PORTAL DASBOARDS PADDINGTON");

      setActivitiesPaddington(data?.activities as PortalDashboardItem[]);
      setInsightsPaddington(data?.insights as PortalDashboardItem[]);
    } catch (e) {
      console.log('ERROR IN FETCH DASHBOARDS PADDINGTON', e);
    }
  }, [activeOrganization, getEnvironment]);



  const [typeOfReadAccess, setTypeOfReadAccess] = useState<ReadAccessType[]>([])

  const getLevel = async (fullClients: any[]) => {
    fullClients.forEach((e) => {
      delete e?.__typename
    })
    try {
      const response = (await API.graphql({
        query: getProjectLevel,
        variables: {
          Clients: fullClients,
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetProjectLevelQuery };
      // console.log(response.data.getProjectLevel, "GET LEVEL")
      const responseArray = response.data.getProjectLevel
      // console.log("typeOfReadAccess", responseArray)
      const enrichedResponse = fullClients.map((e) => {
        const found = responseArray?.find((r) => r?.ClientCode === e.ClientCode)
        if (found) {
          return { ...e, Level: "READ_PLUS_ACCESS" }
        }
        return { ...e, Level: "READ_ACCESS" }
      })
      setTypeOfReadAccess(enrichedResponse as ReadAccessType[])


    } catch (error) {
      console.error("Error in getLevel", error)
    }
  }


  // GET USER TYPE
  const fetchUserPaddington = useCallback(async () => {
    setCheckPaddington(undefined)
    try {
      const response = (await API.graphql({
        query: getUserV2,
        variables: {
          Environment: getEnvironment, // Environment.DEMO,
          Organization_id: activeOrganization?.OrganizationId,
          StatementType: StatementType.Paddington,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUserV2Query };

      const fullAccess = response.data.getUserV2?.Access?.filter(e => e?.Level?.includes("FULL"))[0]?.Clients
      const readAccess = response.data.getUserV2?.Access?.filter(e => e?.Level?.includes("READ"))[0]?.Clients
      const noAccess = response.data.getUserV2?.Access?.filter(e => e?.Level?.includes("NO"))[0]?.Clients

      if (fullAccess) {
        getLevel(fullAccess as Clients[])
      }
      const user = {
        fullAccess: fullAccess ? fullAccess.length > 0 : false,
        readAccess: readAccess ? readAccess.length > 0 : false,
        noAccess: noAccess ? noAccess.length > 0 : false
      }
      setUserAccess(user)
      if ((fullAccess && fullAccess.length > 0) || (readAccess && readAccess.length > 0)) {
        setCheckPaddington(response.data.getUserV2 as getUserTypeV2)
      }

    } catch (e) {
      console.log('ERROR IN FETCH DASHBOARDS', e);
    }







  }, [activeOrganization, getEnvironment]);



  const [isThereAnyReadPlus, setIsThereAnyReadPlus] = useState<boolean>(false)
  // GET INSIGHTS DASHBOARDS
  const fetchInsightsDashboards = useCallback(async () => {
    try {
      const response = (await API.graphql({
        query: getInsightsDashboards,
        variables: {
          Environment: getEnvironment, // Environment.DEMO,
          Organization_id: activeOrganization?.OrganizationId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetInsightsDashboardsQuery };

      setInsightsDashboards(response.data.getInsightsDashboards as unknown as IInsightsDashboards[])


    } catch (e) {
      console.log('ERROR IN FETCH INSIGHTS DASHBOARDS', e);
    }
  }, [activeOrganization, getEnvironment]);


  const fetchMultipleAuthorizations = useCallback(async (action: string) => {

    try {
      const response = (await API.graphql({
        query: userIsAuthorizedMulti,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
          Resource: "Queries", // Write publish instead of queries,
          Service: "OMD Portal",
          StatementType: StatementType.Access,
          Action: ActionEnum[action as keyof typeof ActionEnum],
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UserIsAuthorizedMultiQuery };
      const data = response.data.userIsAuthorizedMulti;
      if (data && data.length > 0 && action === "PUBLISH") {
        // console.log("DATA", data)
        setAnyPublish(data.some(e => e?.Authorized === true))
        const isAllUnauthorized = data.every(e => e?.Authorized === false)
        if (isAllUnauthorized) {
          try {
            const response = (await API.graphql({
              query: getUserV2,
              variables: {
                Environment: getEnvironment, // Environment.DEMO,
                Organization_id: activeOrganization?.OrganizationId,
                StatementType: StatementType.Access,
              },
              authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: GetUserV2Query };
            const anyReadPlusClients = response.data.getUserV2?.Access?.find(e => e?.Level === "READ_PLUS_ACCESS");
            if (anyReadPlusClients?.Clients) {
              if (anyReadPlusClients.Clients.length > 0) {
                setIsThereAnyReadPlus(true)
              }

            }



          } catch (e) {
            console.log('ERROR IN FETCH DASHBOARDS', e);
          }

        }
      } else if (data && data.length > 0 && action === "CREATE") {

        setAnyCreate(data.some(e => e?.Authorized === true))
      }

      return data;
    } catch (e) {
      console.log('ERROR IN FETCH USER IS AUTHORIZED MULTI', e);
    }
  }, [activeOrganization, getEnvironment]);

  const [briefTemplate, setBriefTemplate] = useState<briefTemplateSection[] | undefined>([])
  const [briefTemplateID, setBriefTemplateID] = useState<string>("")

  const fetchBriefTemplate = async () => {
    try {

      const response = (await API.graphql({
        query: getBriefTemplate,
        variables: {
          Environment: getEnvironment,
          OrganizationId: activeOrganization?.OrganizationId
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as { data: GetBriefTemplateQuery };

      const res = response.data.getBriefTemplate?.sections as briefTemplateSection[]
      // inn all items in the res array sorty columns by placeholder property
      res.forEach((e) => {
        e.columns?.sort((a, b) => {
          if (parseInt(a?.placeholder as string, 10) > parseInt(b?.placeholder as string, 10)) {
            return 1
          } else {
            return -1
          }

        })
      })
      setBriefTemplate(response.data.getBriefTemplate?.sections as briefTemplateSection[])
      setBriefTemplateID(response.data.getBriefTemplate?.templateId as string)

    } catch (error) {
      console.log("Error fetching the brief template", error)
    }
  }


  const [loggedInUserAccessType, setLoggedInUserAccessType] = useState<getUserTypeV2>()
  const fetchAccess = async () => {
    try {
      const response = (await API.graphql({
        query: getUserV2,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
          StatementType: StatementType.Access,

        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUserV2Query };
      // console.log("LOGGED USER HAS THIS ACCESS", response)
      const data = response.data.getUserV2
      if (data) {
        setLoggedInUserAccessType(data)
      } else {
        setLoggedInUserAccessType(undefined)
      }

    } catch (error) {
      console.error("Error fetching userIsAuthorized", error);
    }
  }


  const [userCanDoInsource, setUserCanDoInsource] = useState<boolean>(false)
  const fetchIsInsource = async () => {
    try {
      const response = (await API.graphql({
        query: userIsAuthorizedMulti,
        variables: {
          Organization_id: activeOrganization?.OrganizationId,
          Environment: getEnvironment,
          Resource: "INSOURCE", // Write publish instead of queries,
          Service: "OMDPortal",
          StatementType: StatementType.Access,
          Action: ActionEnum.WRITE
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UserIsAuthorizedMultiQuery };
      const data = response.data.userIsAuthorizedMulti;
      if (data) {
        const isIt = data.some(e => e?.Authorized === true)
        setUserCanDoInsource(isIt)
      } else {
        setUserCanDoInsource(false)
      }

      // console.log("INSOURCE", data)
    } catch (e) {
      console.log('ERROR IN FETCH USER IS AUTHORIZED MULTI', e);
    }

  }


  useEffect(() => {
    if (activeOrganization !== undefined) {
      fetchDashboards();
      fetchIsInsource()
      fetchDashboardsPaddington();
      fetchUserPaddington();
      fetchInsightsDashboards()
      fetchMultipleAuthorizations("PUBLISH")
      fetchMultipleAuthorizations("CREATE")
      fetchBriefTemplate()
      fetchAccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization, fetchDashboards, fetchUserPaddington]);

  return (
    <ClientContext.Provider
      value={{
        setActiveOrganization,
        setCheckParams,
        activeOrganization,
        unpublishedStatement,
        insights,
        activities,
        checkPaddington,
        organizationList,
        insightsPaddington,
        insightsDashboards,
        handleCountrySelection,
        setOrganizationList,
        anyPublish,
        anyCreate,
        briefTemplate,
        briefTemplateID,
        fetchBriefTemplate,
        setOrganizationListCountry,
        organizationListWithCountry,
        isOnlyDanish,
        hasMoreThanOneCountry,
        fetchMultipleAuthorizations,
        userAccess,
        typeOfReadAccess,
        getLevel,
        isThereAnyReadPlus,
        userCanDoInsource,
        loggedInUserAccessType
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
//http://localhost:3000/onlinePortal?client=Telenor&campaignID=23188

export const useClientContext = () => {
  const context = useContext(ClientContext)
  if (context === undefined) {
    throw new Error("context needs to be provided in client context")
  }
  return context

}

