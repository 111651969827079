import { useContext, useState } from 'react';
import styled from 'styled-components';
import { GetUserV2Query, getUserV3Response, RemoveUserMutation, StatementType, userAccessType } from 'API';
import { removeUser } from 'graphql/mutations';
import { API } from 'aws-amplify';
import APIContext from 'context/APIContext';
import { VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';
import { getUserV2 } from 'graphql/queries';
import { useClientContext } from 'context/ClientContext';
import { useGetSingleUserAccess } from 'customHooks/useGetSingleUserAccess';
import DeleteConfirmationModal from 'components/DeleteConfirmationModall';
import { theme } from 'highcharts';
import ReactLoading from 'react-loading';
import { lightTheme } from 'components/style/Theme';
import { set } from 'lodash';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

const Card = styled.div`
  height: 10vh;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 3.6vh;
&:hover {
    background: ${({ theme }) => theme.c50};
  }
  .details {
    width: 75%;
    padding: 1.38vh 1.34vw;
    h1{
      color: ${({ theme }) => theme.primaryGray};
      font-weight: normal;
      font-size: 1.2vw;
    }
    h2{
      /* color: ${({ theme }) => theme.primaryGray}; */
      font-weight: normal;
      font-size: 1vw;
    }

 
  }
  .action {
    position: absolute;
    bottom: 3.38vh;
    left: 2.34vw;
    display: flex;
    flex-direction: column;
   // gap: 0.6vh;
  }
  .onoff {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    width: 26vw;
    overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.border};
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollThumb};
      border-radius: 10px;
    }
    
    button {
      all: initial;
      font-family: 'FontMedium', sans-serif;

      font-size: .8vw;
      margin-bottom: 0.6vh;
      //    color: red;
      line-height: .8;
    }
  }

  h4 {
    font-weight: 800;
    font-size: 1vw;
    color:#20C933;
  }
  .editing {
    width: 25%;
    height: fit-content;
    justify-content: flex-end;
    padding: 1.38vh 1.34vw;
    display: flex;
    gap: 5px;
    color: ${({ theme }) => theme.primaryGray};
    .icons {
      display: flex;
      flex-direction: row;
      /* gap: 1.05vw; */
      flex-direction: row;
      color: ${({ theme }) => theme.primaryGray};

      .icon {
        cursor: pointer;
        /* justify-content: center;
        align-items: center; */
        padding: 4px 7px;
        /* size: 0.729vw; */
        font-size: 1vw;
        &:hover {
          background: #f1f5fa;
          border-radius: 4px;
        }
      }
    
    }
  }
  .premium {
    position: absolute;
    background: rgb(218,165,32);
    background: linear-gradient(149deg, rgba(218,165,32,1) 0%, rgba(255,255,255,1) 24%);
    /* border-radius: 7px 50px 50% 200px; */
    width: 100%;
    height:100%;
    top: 10px;
    left:0;
    filter: blur(10px);
  }
`;

type Props = {
  data: getUserV3Response;
  typeOfReadAccess: any[];
  userType: any;
  callFetchUsers(): any;
  modalModify(user: any, usrTyp: any, insightsData: any): any;
  setExternalUsers(props: any): any;
  setInternalUsers(props: any): any;
  setUserType(props: any): any;
  insightsData: any
};


const UserAdminCard2 = (props: Props) => {
  const { getEnvironment } = useContext(APIContext);
  const [getAccess] = useGetSingleUserAccess()

  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [wait, setWait] = useState(false);
  // HANDLE REMOVE USER ENTIRELY
  const handleDeleteUser = (response: boolean) => {

    if (response) {
      props.setExternalUsers(undefined)
      props.setInternalUsers(undefined)
      removeTheUser();
      setWait(true);
    } else {
      setDeleteUserModal(false);
    }




  };


  // MUTATION REMOVE USER
  async function removeTheUser() {

    const remove = async (variables: any) => {

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = (await API.graphql({
          query: removeUser,
          variables: variables,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: RemoveUserMutation };
        props.callFetchUsers();
        setDeleteUserModal(false);

      } catch (e) {
        console.error('ERROR IN REMOVING USER', e);
      }
    }
    getAccess(props.data).then((e) => {
      const fullAccess = e.filter(e => e?.Level === 'FULL_ACCESS').map(e => e?.Clients).flat();
      const readAccess = e?.filter(e => e?.Level === 'READ_ACCESS').map(e => e?.Clients).flat();
      const joined = fullAccess?.concat(readAccess)
      const removedTypename = joined?.map(e => {
        const { __typename, ...rest } = e || {};
        return rest;
      });
      const vars = {
        Username: props.data.Username,
        //    UserType: UserType[props.userType as keyof typeof UserType],
        // Clients: fullAccess?.concat(readAccess),
        Clients: removedTypename,
        Environment: getEnvironment, // Environment.DEMO,
      };
      remove(vars);
    }
    )





  }

  const isItReadPlus = (code: string) => {
    const getLevel = props.typeOfReadAccess.find(e => e.ClientCode === code)
    if (getLevel?.Level === 'READ_PLUS_ACCESS') return '#DAA520'
    else return '#FFEE00'
  }



  return (
    <Card >

      <div className="details">
        <h1>{props.data.Name + ' ' + props.data.Family_name} </h1>
        <h2>{props.data.Email}</h2>
      </div>

      <div className="editing">

        <div className="icons">

          <div onClick={() => { props.modalModify(props.data, props.userType, props.insightsData) }} className="icon material-symbols-outlined">{iconList.find((e) => e === 'edit')}</div>
          <div onClick={() => setDeleteUserModal(true)} className="icon material-symbols-outlined">
            {iconList.find((e) => e === 'delete')}
          </div>

        </div>




        {/* <VscEdit style={{ cursor: 'pointer' }} onClick={() => { props.modalModify(props.data, props.userType, props.insightsData) }} />
        <AiOutlineDelete style={{ cursor: 'pointer' }} onClick={() => setDeleteUserModal(true)} /> */}
      </div>

      {deleteUserModal && (
        <DeleteConfirmationModal
          handleDeleteConfirmationModal={handleDeleteUser}
          modalObject={{
            title: "Delete User",
            message: wait ? "Please wait while we delete the user" : "Are you sure you want to delete this user?",
            mainButton: wait ? undefined : "Delete",
            secondaryButton: wait ? undefined : "Cancel"
          }}
        ></DeleteConfirmationModal>
      )

      }


    </Card>
  );
};

export default UserAdminCard2;
