import { FlowchartCampaignStatus, UpdateCampaignStatusMutation } from 'API';
import { API } from 'aws-amplify';
import { updateCampaignStatus } from 'graphql/mutations';

export const useUpdateCampaignStatus = (): readonly [(activeCampaign: any, type: string) => Promise<unknown>] => {

    const UpdateCampaign = async (activeCampaign: any, type: string) => {
        try {
            const response = (await API.graphql({
                query: updateCampaignStatus,
                variables: {
                    CampaignId: activeCampaign?.campaignId,
                    Status: type,
                    // Environment: "PRODUCTION",
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            })) as { data: UpdateCampaignStatusMutation };


            return response;
        } catch (e) {
            console.log('error saving brief status', e);
            return e;
        }
    };

    return [UpdateCampaign] as const;
};


