
import { Container, HeadModul, ModulContainer } from 'components/Main/table/TableStyles';
import { DarkButton } from 'components/style/Global';
import { useTemplateContext } from 'context/TemplateContext';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 30px;
  }

  .btnContainer {
    align-self: flex-end;
  }
  .box {
    margin: 30px 0;
    width: 500px;
}

`;

type Props = {
    setSaveTemplateDisplay(arg: any): any;
};

function SaveTemplate(props: Props) {
    const { templateSave, saveTemplateMessage, templateName, setTemplateName, fetchedTemplateData, templateUpdate } = useTemplateContext()
    const [nameAlert, setNameAlert] = useState(false)
    const handleSave = () => {
        templateName.length > 0 ? templateSave(templateName) : setNameAlert(true)
    }

    useEffect(() => {
        if (templateName.length > 0) {
            setNameAlert(false)
        }
    }, [templateName])


    return (
        <Modal onClick={() => props.setSaveTemplateDisplay(false)}>
            <HeadModul onClick={e => e.stopPropagation()} style={{ minHeight: "0", height: "fit-content" }} >
                <button type='button' className="x" onClick={() => props.setSaveTemplateDisplay(false)}> <IoMdClose className='closeIcon' /></button>
                <ModulContainer onClick={e => e.stopPropagation()} >
                    <h1>Save your template</h1>
                    <Container className='containerBox box' style={{ display: "flex", flexDirection: "column", gap: "2vw", paddingTop: "1vw" }}>
                        <input type="text" defaultValue={templateName.length > 0 ? templateName : ''} placeholder={templateName.length > 0 ? templateName : 'Enter template name here'} onInput={e => setTemplateName((e.target as HTMLInputElement).value)} />
                        <div className='btnContainer'>
                            {fetchedTemplateData?.Name === templateName && <button type='button' onClick={templateUpdate} >
                                SAVE
                            </button>}

                            {fetchedTemplateData?.Name !== templateName && <button type='button' onClick={handleSave} >
                                SAVE AS
                            </button>}
                        </div>

                        {nameAlert && <div>
                            <p>Please enter a template name</p>
                        </div>}

                        {saveTemplateMessage && <div>
                            <p>{saveTemplateMessage}</p>
                        </div>}

                    </Container>


                    <DarkButton onClick={() => props.setSaveTemplateDisplay(false)} style={{ alignSelf: "flex-end" }} type="button">
                        OK
                    </DarkButton>

                </ModulContainer>
            </HeadModul>
        </Modal >
    );
}

export default SaveTemplate;
