import { DashboardTypes } from 'API';
import styled from 'styled-components';

type AsideContianerType = {
  dashType?: string;
}

export const AsideContainer = styled.div<AsideContianerType>`
  min-height: 100vh;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 4.5vw;
  background-color: ${({ theme, dashType }) => dashType !== DashboardTypes.HOME ? theme.background: "transparent"};

  .logodiv {
    text-align: center;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  }
  .logo{

    display: flex;
    flex-direction: column;
    align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  }
  .user {
  /* border:1px solid ${({ theme }) => theme.primaryGray};;
   background-color: ${({ theme }) => theme.secondaryGray};
  display:inline-block;
  font-size:.7vw;
  font-family: 'FontMedium', sans-serif; 
    font-weight: normal;
  width:1.7vw;
  height:1.7vw;
  line-height:1.7vw;
  text-align:center;
  border-radius:50%;
  vertical-align:middle;
  color: ${({ theme }) => theme.primaryGray}; */
 
}


/*   .log {
    position: absolute;
    bottom: 2vw;
  }
  .tog {
    position: absolute;
    bottom: 5vw;

  } */
  h4{
    font-family: 'FontMedium', sans-serif; 
    font-weight: normal;
}
`;

export const Navigation = styled.nav<AsideContianerType>`
  display: flex;
  flex-direction: column;
  align-items: ${({ dashType }) => dashType !== DashboardTypes.HOME ? "center": ""};
  justify-content: ${({ dashType }) => dashType !== DashboardTypes.HOME ? "space-between": "flex-end"};
  gap: 5px;
  padding-top: 100px;
  height: 90%;
  text-align: center;


  .navigationTitle {
    font-size: 9px;
  }

  .svg {
    height: 1vw;
    fill: ${({ theme }) => theme.primaryGray};
    stroke-width: 5px;
    width: 100%;
    
    path {
      stroke: ${({ theme }) => theme.primaryGray};
      stroke-width: .1;
    }
  }
  .green {
      fill: #20C933;
      color: #20C933;
    }
  &:link {
    color: ${({ theme }) => theme.primaryGray};
  }

  /* visited link */
  &:visited {
    color: ${({ theme }) => theme.primaryGray};
  }

  .selected {
    background-color: ${({ theme }) => theme.c100};
    border-radius: 7px;
    color: ${({ theme }) => theme.c600};

    .svg {
      fill: ${({ theme }) => theme.c600};
    }
    path {
      stroke: ${({ theme }) => theme.c600};
    }
  }
.bottomNav {
  display: flex;
  flex-direction: column;
  justify-content:${({ dashType }) => dashType !== DashboardTypes.HOME ? "flex-end": ""};
  align-items: center;

  .user {
 /*  border:1px solid ${({ theme }) => theme.c300};; */
     background-color: ${({ theme }) => theme.c400};
  display:inline-block;
  font-size:.8vw;
  width:2vw;
  height:2vw;
  line-height:2vw;
  text-align:center;
  border-radius:50%;
  vertical-align:middle;
  p{

    color: ${({ theme }) => theme.c50};
  }
}
 
  
}


`;

export const NavItem = styled.a`
  color: ${({ theme }) => theme.primaryGray};
  font-size: 1.25vw;
  width: 3vw;
  height: 3vw;
  justify-content:center ;
  text-decoration: none ;
  display: flex;
  flex-direction: column;
  justify-content: center; /* vertically aligns items */
  gap: 2px;
`;
