
import styled from 'styled-components';
import { LoginComponent } from './Aside/LoginComponent';
import { HeadModul } from './Main/table/TableStyles';

export const WrapperContainer = styled.div`
    position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */


h1 {
  font-weight: normal;
}
h2 {
  font-weight: normal;
  font-size: 1vw;
  font-family: "fontMedium";
  
}
 .head {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }

  section {
    display: flex;
    flex-direction: column;
/*     justify-content: center;
  align-items: center; */
  }
  .log {
    margin-left: auto;
    margin-right: auto
  }

  p {
    color: ${({ theme }) => theme.primaryGray};  
    font-family: 'FontMedium', sans-serif; 
    font-size: 1.8vw;
    margin-bottom: 0.4vw;
  }


  .loadingDot {
    animation: loadingDots 1s infinite;
    margin-left: 5px;
  }

  .loadingDot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loadingDot:nth-child(3) {
    animation-delay: 0.4s;
  } 

 

  @keyframes loadingDots {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  } 
`;
export interface Props {
  changeHidden(): any;
  hidden: Boolean;
}

function Wrapper(props: Props) {
  return (
    <>
      {props.hidden && (
        <WrapperContainer>
          <HeadModul className='head'>
            <section>
              <h1>Looks like you're not logged in right now.</h1>
              <h2>Please log in to continue.</h2>
            </section>
            <LoginComponent wrapper={'wrapper'}></LoginComponent>
          </HeadModul>
        </WrapperContainer>
      )}
    </>
  );
}

export default Wrapper;
