const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


export function Radio21(data: any, RadioStationSelector: string, theme: any) {
    const theData = [] as any;
    const theAxis = [] as any;
    const categ = [] as any;


    const dataPerSpotName = data.reduce(
        (result: any, item: any) => ({
            ...result,
            [item["spot_name"]]: [
                ...(result[item["spot_name"]] || []),
                item,
            ]
        }),
        {}
    )



    const pero = [] as any[]
    function updateArrays(mainArray: any) {
        // Find the longest array
        let longestArray = mainArray.reduce((prev: any, curr: any) => (curr.length > prev.length ? curr : prev));

        // Get the unique "year_week" values from the longest array
        const uniqueYwValues = new Set(longestArray.map((obj: any) => obj.year_week));

        // Update the other arrays
        mainArray.forEach((array: any) => {
            // Add missing objects to match the length of the longest array
            while (array.length < longestArray.length) {
                const missingYwValue = [...uniqueYwValues].find(
                    (ywValue) => !array.some((obj: any) => obj.year_week === ywValue)
                );

                if (missingYwValue) {
                    array.push({
                        network: "Discovery",
                        year_week: missingYwValue,
                        spot_name: array[0].spot_name, // Modify spot_name accordingly
                        trp_primary_target: null, // Set trp_primary_target to null
                    });
                }
            }

            // Sort the array based on the order of the longest array
            array.sort((a: any, b: any) => {
                const indexA = longestArray.findIndex((obj: any) => obj.year_week === a.year_week);
                const indexB = longestArray.findIndex((obj: any) => obj.year_week === b.year_week);
                return indexA - indexB;
            });
        });

        return mainArray;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, [key, value]] of Object.entries(dataPerSpotName).entries()) {
        const prepData = (dataSet: any) => {
            const yaxis = {
                min: 0,
                labels: {
                    format: '{key}', // bilo je value
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false
                },
                title: {
                    text: key,
                    style: {
                        color: colorArray[theData.length],
                    },
                    enabled: false,
                },
                gridLineWidth: 0,

            };

            const dataSeries = dataSet.map((e: any) => e.trp_primary_target)


            const ser = {
                name: dataSet[0].spot_name,
                type: "column",
                data: dataSeries,
                color: colorArray[theData.length],
                yAxis: 0,
                percentage: 22,
                center: [
                    "50%",
                    null
                ],
                size: "100%",
            };

            theData.push(ser);
            theAxis.push(yaxis);

            if (theAxis[1]) {
                theAxis[1].opposite = true;
            }

        }



        if (RadioStationSelector === "All stations") {
            const sum = (value as Array<any>).reduce((a: any, c: any) => {
                let x = a.find((e: any) => e.year_week === c.year_week)
                if (!x) a.push(Object.assign({}, c))
                else x.trp_primary_target += c.trp_primary_target
                return a
            }, [])
            pero.push(sum)
            //   prepData(sum)
        } else {
            //   const dataPerStation = (value as Array<any>).filter((e: any) => e.network === TVStationSelector)
            const filteredData = (value as Array<any>).filter((e: any) => e.network === RadioStationSelector)
            pero.push(filteredData)
        }
        if (pero.length === Object.keys(dataPerSpotName).length) {
            categ.push(...pero[0].map((e: any) => e.year_week));

            const updatedArray = updateArrays(pero);
            updatedArray.forEach((element: any) => {
                prepData(element)

            });
        }

    }



    return {

        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            type: "column",
            style: {
                fontFamily: "fontRegular"
            }
        },
        title: {
            text: 'Spot Variation',
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: "TRP",
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },

        yAxis:
            theAxis,
        xAxis: {
            categories: categ,
            crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },
        },
        tooltip: {
            useHTML: true,
            shared: true,
            followPointer: true,
            valueDecimals: 1,

            // Highcharts.TooltipFormatterContextObject
            /* formatter: function (this: any) {
                let cat = categ;
                const x = this.point.x;
                const a = this.y;
                const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0)
 
                return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "<br> (" + (a / b * 100).toFixed(5) + "%)"
            }, */
        },

        plotOptions: {

            column: {
                pointPadding: 0.1,
                distance: -60,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent'
                    }
                }
            },
            series: {
                stacking: theData.length > 1 ? "normal" : null
            }


        },
        series: theData.map((el: any) => {
            el.yAxis = 0;
            return el
        }),
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {
                return this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },


    }



}

