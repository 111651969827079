import { HeadModul, ModulContainer } from "components/Main/table/TableStyles"
import { IoMdClose } from "react-icons/io";
import styled from "styled-components"
import FormInput from "./FormInput";
import { useState } from "react";
import { DarkButton } from "components/style/Global";
import TagInput from "./TagInput";
import { useCampaignContext } from "context/CampaignContext";
import { API } from 'aws-amplify';
import { createCampaign } from "graphql/mutations";
import { CreateCampaignMutation } from "API";
import { useSearchParams } from 'react-router-dom';
import { useClientCode } from "customHooks/useParameters";
import { get } from "lodash";
import { useClientContext } from "context/ClientContext";


export const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  padding: 50px 0; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;

  h1 {
    text-align: center;
   font-size: 20px;
   font-weight: normal;
   margin-bottom: 50px;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1vw;
  }

  .tagsContainer{
    display: grid;
    grid-column: span 2; 
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vw;
  }
  `

type Props = {
    seeModal: any;
    setSeeModal(seeModal: any): any;

};

const AddCampaignModal = (props: Props) => {
    const { reducedClientList, } = useCampaignContext()
    const [responseMessage, setResponseMessage] = useState<any>()
    const [searchParams, setSearchParams] = useSearchParams();
    const { activeOrganization } = useClientContext()


    const [getID, setID,] = useClientCode();




    const [values, setValues] = useState({
        name: "",
        startDate: "",
        endDate: "",
        clientCode: "",
        // budget: "",
        comments: "",
        additionalInfo1: "",
        additionalInfo2: "",
        tags: {}
    })

    const inputs = [
        {
            id: 1,
            name: "name",
            type: "text",
            placeholder: "Campaign Name",
            label: "Campaign Name",
            errorMessage: "Campaign Name is a mandatory field",
            required: true
        },
        {
            id: 2,
            name: "clientCode",
            type: "dropdown",
            placeholder: "Client Code",
            label: "Client Code",
            errorMessage: "Client Code is a mandatory field",
            required: true
        },

        {
            id: 3,
            name: "startDate",
            type: "date",
            placeholder: "Start Date",
            label: "Start Date",
            required: true,
            errorMessage: "Start Date is a mandatory field"
        },
        {
            id: 4,
            name: "endDate",
            type: "date",
            placeholder: "End Date",
            label: "End Date",
            required: true,
            errorMessage: "End Date is a mandatory field"
        },
        // {
        //     id: 5,
        //     name: "budget",
        //     type: "number",
        //     placeholder: "Briefed Budget",
        //     label: "Briefed Budget",
        //     required: true,
        //     errorMessage: "Campaign Name is a mandatory field"
        // },
        /*     {
                id: 6,
                name: "comments",
                type: "textarea",
                placeholder: "Comments",
                label: "Comments",
                required: false,
                errorMessage: ""
            },
            {
                id: 7,
                name: "additionalInfo1",
                type: "textarea",
                placeholder: "Additional Info 1",
                label: "Additional Info 1",
                required: false,
                errorMessage: ""
            },
            {
                id: 8,
                name: "additionalInfo2",
                type: "textarea",
                placeholder: "Additional Info 2",
                label: "Additional Info 2",
                required: false,
                errorMessage: ""
            }, */
    ]

    const tags = [
        {
            id: 1,
            name: "tagGroup1",
            label: "Tag Group 1",
            options: ["option1", "option2", "option3"],
        }, {
            id: 2,
            name: "tagGroup2",
            label: "Tag Group 2",
            options: ["option1", "option2", "option3"],
        }, {
            id: 3,
            name: "tagGroup3",
            label: "Tag Group 3",
            options: ["option1", "option2", "option3"],
        }, {
            id: 4,
            name: "tagGroup4",
            label: "Tag Group 4",
            options: ["option1", "option2", "option3"],
        }, {
            id: 5,
            name: "tagGroup5",
            label: "Tag Group 5",
            options: ["option1", "option2", "option3"],
        }, {
            id: 6,
            name: "tagGroup6",
            label: "Tag Group 6",
            options: ["option1", "option2", "option3"],
        }, {
            id: 7,
            name: "tagGroup7",
            label: "Tag Group 7",
            options: ["option1", "option2", "option3"],
        }, {
            id: 8,
            name: "tagGroup8",
            label: "Tag Group 8",
            options: ["option1", "option2", "option3"],
        }, {
            id: 9,
            name: "tagGroup9",
            label: "Tag Group 9",
            options: ["option1", "option2", "option3"],
        },
    ]

    const onChange = (e: any) => {


        e.preventDefault()
        if (e.target.name.length === 0) {
            setValues({ ...values, clientCode: e.target.value })
        } else {

            setValues({ ...values, [e.target.name]: /* e.target.name === "budget" ? Number(e.target.value) : */ e.target.value })
        }
    }

    const onChangeTag = (e: any, name: string) => {
        const newTags = { ...values.tags, [name]: e.target.value }
        const newValues = { ...values, tags: newTags }
        setValues(newValues)
    }
    async function handleAddCampaign(variablesParams: any) {
        const clientCountry = reducedClientList?.filter(e => e?.ClientCode === variablesParams.clientCode)[0]?.ClientCountry ?? activeOrganization?.OrganizationId.split('#')[1]
        try {
            const response = (await API.graphql({
                query: createCampaign,
                variables: { ...variablesParams, clientCountry: clientCountry as string },
                authMode: "AMAZON_COGNITO_USER_POOLS"
            })) as { data: CreateCampaignMutation }
            setValues({
                name: "",
                startDate: "",
                endDate: "",
                clientCode: "",
                // budget: "",
                comments: "",
                additionalInfo1: "",
                additionalInfo2: "",
                tags: {}
            })
            const message = response.data.createCampaign?.message
            setResponseMessage(message)
            searchParams.delete("campaignID")
            setSearchParams(searchParams)
            setID("none")
            // fetchCampaigns()
            setTimeout(() => {
                setID(getID)
                // setResponseMessage(undefined)
                // props.setSeeModal(false)
            }, 1500);

        } catch (error) {
            console.log("error adding new campaign", error);

        }
    }

    return (
        <Modal>
            <HeadModul>
                {!responseMessage ? <>   <button type='button' className="x" onClick={() => props.setSeeModal(false)}> <IoMdClose className='closeIcon' /></button>
                    <ModulContainer>
                        <h1>Create Campaign</h1>

                        <form>
                            {inputs.map(input => (
                                <FormInput key={input.id} {...input} value={values[input.name as keyof typeof values]} onChange={onChange}></FormInput>
                            ))}

                            {/*   <div className="tagsContainer">
                            {tags.map(tag => (
                                <TagInput onChangeTag={onChangeTag} key={tag.id} {...tag}></TagInput>
                            ))}
                        </div> */}


                        </form>
                        <DarkButton type="button" onClick={() => handleAddCampaign(values)}>Submit</DarkButton>
                    </ModulContainer> </> : <>
                    <button type='button' className="x" onClick={() => { props.setSeeModal(false); setResponseMessage(undefined); }}> <IoMdClose className='closeIcon' /></button>
                    <ModulContainer>
                        <p>{responseMessage}</p>
                    </ModulContainer></>}
            </HeadModul>
        </Modal>
    )
}

export default AddCampaignModal