import {
  DashboardTypes,
  Dimensions,
  GetDataSourcesQuery,
  Metrics,
  agencyEnum,
  calculatedMetricsDetail,
  countryEnum,
  datatype,
  detailedDimensionsDetail,
  filtertype,
  localQuery,
  metricFunction,
  queryInput,
  view,
} from 'API';
import { API } from 'aws-amplify';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { getDataSources } from 'graphql/queries';
import { useContext, useEffect, useState } from 'react';

import { FcGoogle } from 'react-icons/fc';
import { SiFacebook, SiTiktok, SiYoutube } from 'react-icons/si';
import TVLogo from 'assets/tv';
import LinkedinLogo from 'assets/linkedin';
import RadioLogo from 'assets/radio';
import OOHLogo from 'assets/OOH';
import PrintLogo from 'assets/PRINT';
import { FaSnapchatSquare } from 'react-icons/fa';
import AdFormLogo from 'assets/adform';
import CampaignManagerLogo from 'assets/cmLogo';
import { getStartDate, getEndDate } from 'methods/dateMethods';
import AuthContext from 'context/AuthContext';
import { set, update } from 'lodash';
import { useDataQuery } from './useDataQuery';
import { useClientContext } from 'context/ClientContext';
export type fullSources = {
  source_name?: string;
  metrics?: Metrics[];
  dimensions?: Dimensions[];
  name: string;
  metaName: string;
  calcMetrics: Array<calculatedMetricsDetail>;
  customConversions?: any;
};

export const useDataSources = (activeCampaign: ACdetails | undefined): readonly [fullSources[]] => {
  const [fullSources, setFullSources] = useState<fullSources[]>([]);
  const [fullSourcesPrev, setFullSourcesPrev] = useState<fullSources[]>([]);
  const { user } = useContext(AuthContext);
  const { userCanDoInsource } = useClientContext();
  const { dashType } = useCampaignContext();

  const [adformQ, setAdformQ] = useState<queryInput>();
  const [facebookQ, setFacebookQ] = useState<queryInput>();
  const [cmQ, setCmQ] = useState<queryInput>();
  const [dv360Q, setDv360Q] = useState<queryInput>();

  const [Adformdata, AdformqueryStatus, Adformerror, AdformqueryId, AdformoutputLocation] = useDataQuery({
    query: adformQ,
    querySource: 'CC QUERY',
  });
  const [Facebookdata, FacebookqueryStatus, Facebookerror, FacebookqueryId, FacebookoutputLocation] = useDataQuery({
    query: facebookQ,
    querySource: 'CC QUERY',
  });
  const [CMdata, CMqueryStatus, CMerror, CMqueryId, CMoutputLocation] = useDataQuery({
    query: cmQ,
    querySource: 'CC QUERY',
  });
  const [DV360data, DV360queryStatus, DV360error, DV360queryId, DV360outputLocation] = useDataQuery({
    query: dv360Q,
    querySource: 'CC QUERY',
  });
  useEffect(() => {
    if (fullSourcesPrev.length > 0) {
      const sourceNameToData = {
        Facebook: Facebookdata,
        Adform: Adformdata,
        CM: CMdata,
        DV360: DV360data,
      };

      Object.entries(sourceNameToData).forEach(([sourceName, data]) => {
        if (fullSourcesPrev.some((e) => e.name === sourceName) && data !== undefined) {
          fullSourcesPrev.forEach((source) => {
            if (source.name === sourceName) {
              source.customConversions = data;
            }
          });
        }
      });

      const test = fullSourcesPrev
        .filter((e) => e.name === 'Facebook' || e.name === 'Adform' || e.name === 'CM' || e.name === 'DV360')
        .map((e) => e.name);

      const sourceNames = test;

      // Generate all combinations of source names
      const combinations = [];
      for (let i = 1; i < Math.pow(2, sourceNames.length); i++) {
        const combination = sourceNames.filter((_, j) => (i & Math.pow(2, j)) !== 0);
        // if (combination.length >= 2) {
        combinations.push(combination);
        // }
      }

      // Sort combinations in descending order based on length
      combinations.sort((a, b) => b.length - a.length);

      // Check if fullSourcesPrev contains certain sources
      const checkSources = (sources: string[]) => {
        return sources.every((source) =>
          fullSourcesPrev.some((e) => e.name === source && e.customConversions !== undefined),
        );
      };

      // Use the checkSources function
      const validCombination = combinations.find(checkSources);
      if (validCombination) {
        setFullSources(fullSourcesPrev);
        return;
      }

      // Use the checkSources function
      // combinations.forEach((combination) => {
      //   if (checkSources(combination)) {
      //     setFullSources(fullSourcesPrev);
      //   }
      // });

      // console.log("jjjjajjjjjajjjja", combinations)

      // if (fullSourcesPrev.some((e) => e.name === 'Facebook') && fullSourcesPrev.some((e) => e.name === 'Adform')) {
      //   if (
      //     fullSourcesPrev.find((e) => e.name === 'Facebook')?.customConversions !== undefined &&
      //     fullSourcesPrev.find((e) => e.name === 'Adform')?.customConversions !== undefined
      //   ) {
      //     setFullSources(fullSourcesPrev);
      //   }
      // } else if (
      //   fullSourcesPrev.some((e) => e.name === 'Facebook') &&
      //   !fullSourcesPrev.some((e) => e.name === 'Adform')
      // ) {
      //   if (fullSourcesPrev.find((e) => e.name === 'Facebook')?.customConversions !== undefined) {
      //     setFullSources(fullSourcesPrev);
      //   }
      // } else if (
      //   fullSourcesPrev.some((e) => e.name === 'Adform') &&
      //   !fullSourcesPrev.some((e) => e.name === 'Facebook')
      // ) {
      //   if (fullSourcesPrev.find((e) => e.name === 'Adform')?.customConversions !== undefined) {
      //     setFullSources(fullSourcesPrev);
      //   }
      // }
    }
  }, [Adformdata, CMdata, DV360data, Facebookdata, fullSourcesPrev]);

  useEffect(() => {
    const { REACT_APP_AGENCY } = process.env;
    const getCustomConversions = (sources: fullSources[]) => {
      if (
        // sources.some((e) => e.name === 'Facebook') ||
        // sources.some((e) => e.name === 'Adform') ||
        // (sources.some((e) => e.name === 'Facebook') && sources.some((e) => e.name === 'Adform'))
        sources.some((e) => e.name === 'Facebook' || e.name === 'Adform' || e.name === 'CM' || e.name === 'DV360')
      ) {
        sources.forEach((source) => {
          const sourceNameToValues = {
            Facebook: {
              metricsColumn: 'custom_conversions_value',
              detailedDimensionsColumn: 'conversion_name',
              viewName: `fozzie_facebook_custom_conversions_${REACT_APP_AGENCY}`,
              setQuery: setFacebookQ,
            },
            Adform: {
              metricsColumn: 'custom_conversions',
              detailedDimensionsColumn: 'filter_name',
              viewName: `fozzie_adform_custom_cost_conversions_${REACT_APP_AGENCY}`,
              setQuery: setAdformQ,
            },
            CM: {
              metricsColumn: 'total_conversions',
              detailedDimensionsColumn: 'activity',
              viewName: `fozzie_campaign_manager_conversions_${REACT_APP_AGENCY}`,
              setQuery: setCmQ,
            },
            DV360: {
              metricsColumn: 'total_conversions',
              detailedDimensionsColumn: 'floodlight_activity_name',
              viewName: `fozzie_google_dv360_conversions_${REACT_APP_AGENCY}`,
              setQuery: setDv360Q,
            },
          };

          if (sourceNameToValues[source.name as keyof typeof sourceNameToValues]) {
            const { metricsColumn, detailedDimensionsColumn, viewName, setQuery } =
              sourceNameToValues[source.name as keyof typeof sourceNameToValues];
            const CCQuery: queryInput = {
              query: {
                preview: 100,
                filters: [
                  {
                    column: 'campaign_id',
                    filter: {
                      filtertype: filtertype.EQUAL,
                      datatype: datatype.NUMBER,
                      value: activeCampaign?.campaignId?.toString(),
                    },
                  },
                ],
                metrics: [
                  {
                    column: metricsColumn,
                    name: 'CCvalue',
                    aggregation: metricFunction.SUM,
                    id: 'm',
                  },
                ],
                detailedDimensions: [
                  {
                    name: 'CCname',
                    column: detailedDimensionsColumn,
                    hidden: false,
                    id: 'd',
                  },
                ],

                orderBy: ['m', 'd'],
              } as localQuery,
              views: [
                {
                  viewName,
                  database:
                    activeCampaign?.ClientCountry === 'DK'
                      ? 'data_lake_denmark'
                      : activeCampaign?.ClientCountry === 'SE'
                      ? 'data_lake_sweden'
                      : activeCampaign?.ClientCountry === 'FI'
                      ? 'data_lake_finland'
                      : 'data_lake_norway',
                },
              ],
              jobnumber: 'ANNA0027',
              clients: [
                {
                  country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
                  client: activeCampaign?.ClientCode as string,
                },
              ],
              date: { from: getStartDate(), to: getEndDate() },
              user: {
                name: user?.username,
                agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
                country: countryEnum.DK,
              },
            };
            setQuery(CCQuery);
          }
        });
      } else {
        sources.forEach((source) => {
          source.customConversions = [];
        });

        setFullSources(sources);
      }
    };
    async function fetchSources() {
      try {
        const responseList = (await API.graphql({
          query: getDataSources,
          variables: {
            campaignId: activeCampaign?.campaignId?.toString(),
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetDataSourcesQuery };
        const response = responseList.data.getDataSources;

        console.log('SOURCES', response);

        response?.forEach((sourceObject) => {
          if (sourceObject) {
            const foundStartDate = structuredClone(sourceObject)?.metrics?.filter((e) => e?.metric === 'date')[0];
            const foundEndDate = structuredClone(sourceObject)?.metrics?.filter((e) => e?.metric === 'date')[0];
            if (foundStartDate && foundEndDate) {
              foundStartDate.metric = 'Start date';
              foundEndDate.metric = 'End date';
            }
            sourceObject?.metrics?.push(foundEndDate as any);
            sourceObject?.metrics?.push(foundStartDate as any);
            sourceObject.metrics = sourceObject?.metrics?.filter((e) => e?.metric !== 'date');
            sourceObject.metrics = sourceObject?.metrics?.filter(
              (e) =>
                !e?.metric?.includes('ad_frequency') &&
                !e?.metric?.includes('adset_frequency') &&
                !e?.metric?.includes('campaign_frequency') &&
                !e?.metric?.includes('campaign_mapping_frequency'),
            );
          }
        });

        const digitalResponse = response?.filter(
          (source) =>
            source?.source_name !== 'DATASOURCE#ADE' &&
            source?.source_name !== 'DATASOURCE#ADR' &&
            source?.source_name !== 'DATASOURCE#OOH' &&
            source?.source_name !== 'DATASOURCE#PRINT' &&
            source?.source_name !== 'DATASOURCE#CINEMA' &&
            source?.source_name !== 'DATASOURCE#BVOD',
        );
        if (response && digitalResponse && digitalResponse.length > 0) {
          let commonMetrics = digitalResponse[0]?.metrics;
          let commonDimensions = digitalResponse[0]?.dimensions;

          commonMetrics = commonMetrics?.map((metric) => ({
            ...metric,
            view: Array.from(metric?.view ?? []),
            __typename: 'Metrics',
          }));

          commonDimensions = commonDimensions?.map((dimension) => ({
            ...dimension,
            view: Array.from(dimension?.view ?? []),
            __typename: 'Dimensions',
          }));

          digitalResponse.slice(1).forEach((source) => {
            commonMetrics = commonMetrics
              ?.filter((commonMetric) => source?.metrics?.some((m) => m?.metric === commonMetric?.metric))
              .map((commonMetric) => {
                const matchingMetric = source?.metrics?.find((m) => m?.metric === commonMetric?.metric);
                if (matchingMetric && commonMetric) {
                  commonMetric.view = Array.from(
                    new Set([...(commonMetric.view ?? []), ...(matchingMetric.view ?? [])]),
                  );
                }
                return commonMetric;
              });

            commonDimensions = commonDimensions
              ?.filter((commonDimension) =>
                source?.dimensions?.some((d) => d?.dimension === commonDimension?.dimension),
              )
              .map((commonDimension) => {
                const matchingDimension = source?.dimensions?.find((d) => d?.dimension === commonDimension?.dimension);
                if (matchingDimension && commonDimension) {
                  commonDimension.view = Array.from(
                    new Set([...(commonDimension.view ?? []), ...(matchingDimension.view ?? [])]),
                  );
                }
                return commonDimension;
              });
          });

          const newDataSource = {
            __typename: 'DataSourcesInfo' as const,
            source_name: 'DATASOURCE#CUSTOMSOURCE',
            metrics: commonMetrics?.map((metric) => ({
              ...metric,
              view: Array.from(metric?.view ?? []),
              __typename: 'Metrics' as const,
            })),
            dimensions: commonDimensions?.map((dimension) => ({
              ...dimension,
              view: Array.from(dimension?.view ?? []),
              __typename: 'Dimensions' as const,
            })),
          };
          // console.log("sssssssssss", newDataSource);
          response.push(newDataSource);
        }

        const arra: any = [];

        const sourceNameToVariable = {
          'DATASOURCE#FB': facebook,
          'DATASOURCE#DV360': dv360,
          'DATASOURCE#CM': cm,
          'DATASOURCE#ADF': adform,
          'DATASOURCE#GAD': youtube,
          'DATASOURCE#TIK': tiktok,
          'DATASOURCE#SNAP': snapchat,
          'DATASOURCE#ADE': TV,
          'DATASOURCE#ADR': Radio,
          'DATASOURCE#LI': linkedIn,
          'DATASOURCE#OOH': OOH,
          'DATASOURCE#PRINT': PRINT,
          'DATASOURCE#RP': readpeak,
          'DATASOURCE#PI': pinterest,
          'DATASOURCE#CINEMA': CINEMA,
          'DATASOURCE#CUSTOMSOURCE': customSource,
          'DATASOURCE#DV360_YOUTUBE': dv360YT,
          'DATASOURCE#TAB': taboola,
          'DATASOURCE#OLV': olv,
          'DATASOURCE#GAD_DISPLAY': googleAds,
          'DATASOURCE#MAR_DIGITAL_TRACKING': marathon,
        };

        response?.forEach((platform, index: number) => {
          if (platform && platform.source_name) {
            const variable = sourceNameToVariable[platform.source_name as keyof typeof sourceNameToVariable];
            if (variable) {
              updateLookup(variable, index);
            } else {
              console.log('there is no platforms available');
            }
          }

          function updateLookup(source: { name: string; metaName: string; calcMetrics: Array<any> }, i: number) {
            const newObject = { ...platform, ...source };

            arra.push(newObject);
            if (response && response.length > 0) {
              if (i === response.length - 1) {
                getCustomConversions(arra);

                setFullSourcesPrev(arra as fullSources[]);
              }
            }
            //setFullSources((pS: any) => [...pS, newObject]);
          }
        });
      } catch (e) {
        console.log(`There was an error ${e}`, e);
      }
    }

    const calcMetricFormulas = {
      CPM: { name: 'CPM', query: '1000*SUM(#cost)/SUM(#impressions)', userDefined: false },
      CTR: { name: 'CTR%', query: '100*SUM(#clicks)/SUM(#impressions)', userDefined: false },
      CPC: { name: 'CPC', query: 'SUM(#cost)/SUM(#clicks)', userDefined: false },
      CPCfb: { name: 'CPC', query: 'SUM(#cost)/SUM(#actions_link_click)', userDefined: false },
      COV: { name: 'COV', query: 'SUM(#conversion_post_click)/SUM(#clicks)', userDefined: false },
      eCPMV: { name: 'eCPMV', query: '1000*SUM(#cost)/SUM(#viewimpressions)', userDefined: false },
      CPLPview: { name: 'CPLPview', query: 'SUM(#cost)/SUM(#actions_landing_page_view)', userDefined: false },
      VTR_3_sec: {
        name: 'VTR_3_sec%',
        query: '100*SUM(#video_3_sec_watched_actions)/SUM(#impressions)',
        userDefined: false,
      },
      VTR_thruplay: {
        name: 'VTR_thruplay%',
        query: '100*SUM(#video_thruplay_watched_actions)/SUM(#impressions)',
        userDefined: false,
      },
      VTR_completed_view: {
        name: 'VTR_completed_view%',
        query: '100*SUM(#video_p100_watched_actions)/SUM(#impressions)',
        userDefined: false,
      },
      CPV_3_sec: { name: 'CPV_3_sec', query: 'SUM(#cost)/SUM(#video_3_sec_watched_actions)', userDefined: false },
      CPV_Thruplay: {
        name: 'CPV_Thruplay',
        query: 'SUM(#cost)/SUM(#video_thruplay_watched_actions)',
        userDefined: false,
      },
      CPV_completed_view: {
        name: 'CPV_completed_view',
        query: 'SUM(#cost)/SUM(#video_p100_watched_actions)',
        userDefined: false,
      },
      avgWatchTimeFB: {
        name: 'avgWatchTimeFB',
        query: 'SUM(#total_seconds_watched)/SUM(#video_play_actions)',
        userDefined: false,
      },
      viewimpressionspercent: {
        name: 'viewimpressions%',
        query: '100*SUM(#active_view_viewable_impressions)/SUM(#impressions)',
        userDefined: false,
      },
      viewimpressionspercentAdform: {
        name: 'viewimpressions%',
        query: '100*SUM(#viewimpressions)/SUM(#impressions)',
        userDefined: false,
      },
      // Frequency: { name: 'frequency', query: 'SUM(#impressions)/MAX(#reach)', userDefined: false },
      ad_frequency: { name: 'ad_frequency', query: 'SUM(#impressions)/MAX(#ad_reach)', userDefined: false },
      adset_frequency: { name: 'adset_frequency', query: 'SUM(#impressions)/MAX(#adset_reach)', userDefined: false },
      campaign_frequency: {
        name: 'campaign_frequency',
        query: 'SUM(#impressions)/MAX(#campaign_reach)',
        userDefined: false,
      },
      campaign_mapping_frequency: {
        name: 'campaign_mapping_frequency',
        query: 'SUM(#impressions)/MAX(#campaign_mapping_reach)',
        userDefined: false,
      },
      Instant_Experience_Avg_Watch_Time: {
        name: 'Instant_Experience_Avg_Watch_Time',
        query: 'sum(#canvas_total_view_time)/sum(#canvas_action_link_click)',
        userDefined: false,
      },
      Instant_Experience_Percentage: {
        name: 'Instant_Experience_Percentage',
        query: 'sum(#canvas_total_view_percent)/sum(#canvas_action_link_click)',
        userDefined: false,
      },
      // YT CALC METRICS( TO BE REVISED)
      ViewRate: { name: 'ViewRate', query: '100*SUM(#views)/SUM(#impressions)', userDefined: false },
      VideoPlayed25: { name: 'VideoPlayed25', query: 'SUM(#video_p0_p25_views)/SUM(#impressions)', userDefined: false },
      VideoPlayed50: {
        name: 'VideoPlayed50',
        query: '(SUM(#video_p0_p25_views)+SUM(#video_p25_p50_views))/SUM(#impressions)',
        userDefined: false,
      },
      VideoPlayed75: {
        name: 'VideoPlayed75',
        query: '(SUM(#video_p0_p25_views)+SUM(#video_p25_p50_views)+SUM(#video_p50_p75_views))/SUM(#impressions)',
        userDefined: false,
      },
      VideoPlayed100: {
        name: 'VideoPlayed100',
        query:
          '(SUM(#video_p0_p25_views)+SUM(#video_p25_p50_views)+SUM(#video_p50_p75_views)+SUM(#video_p75_p100_views)+SUM(#video_p100_views))/SUM(#impressions)',
        userDefined: false,
      },

      VideoPlayedAtLeast25: {
        name: 'VideoPlayedAtLeast25',
        query:
          '(SUM(#video_p25_p50_views)+SUM(#video_p50_p75_views)+SUM(#video_p75_p100_views)+SUM(#video_p100_views))/SUM(#impressions)',
        userDefined: false,
      },
      VideoPlayedAtLeast50: {
        name: 'VideoPlayedAtLeast50',
        query: '(SUM(#video_p50_p75_views)+SUM(#video_p75_p100_views)+SUM(#video_p100_views))/SUM(#impressions)',
        userDefined: false,
      },
      VideoPlayedAtLeast75: {
        name: 'VideoPlayedAtLeast75',
        query: '(SUM(#video_p75_p100_views)+SUM(#video_p100_views))/SUM(#impressions)',
        userDefined: false,
      },
      VideoPlayedAtLeast100: {
        name: 'VideoPlayedAtLeast100',
        query: '(SUM(#video_p100_views))/SUM(#impressions)',
        userDefined: false,
      },

      CPV_view: { name: 'CPV_view', query: 'SUM(#cost)/SUM(#views)', userDefined: false },
      CPVtrueview: { name: 'CPVtrueview', query: 'SUM(#cost)/SUM(#video_views)', userDefined: false },
      CPVbumper_no_skip: { name: 'CPVbumper_no_skip', query: 'SUM(#cost)/SUM(#video_p100_views)', userDefined: false },
      avgWatchTime: { name: 'avgWatchTime', query: 'SUM(#total_secs_watched)/SUM(#impressions)', userDefined: false },
      //TIKTOK
      VTR_2_sec: { name: 'VTR_2_sec%', query: '100*SUM(#video_watched_2s)/SUM(#impressions)', userDefined: false },
      VTR_6_sec: { name: 'VTR_6_sec%', query: '100*SUM(#video_watched_6s)/SUM(#impressions)', userDefined: false },
      VTR_completed_view_Tik: {
        name: 'VTR_completed_view%',
        query: '100*SUM(#video_views_p100)/SUM(#impressions)',
        userDefined: false,
      },
      CPV_2_sec: { name: 'CPV_2_sec', query: 'SUM(#cost)/SUM(#video_watched_2s)', userDefined: false },
      CPV_6_sec: { name: 'CPV_6_sec', query: 'SUM(#cost)/SUM(#video_watched_6s)', userDefined: false },
      CPV_completed_view_Tik: {
        name: 'CPV_completed_view',
        query: 'SUM(#cost)/SUM(#video_views_p100)',
        userDefined: false,
      },
      avgWatchTimeTIKTOK: {
        name: 'avgWatchTime',
        query: 'SUM(#total_secs_watched)/SUM(#video_play_actions)',
        userDefined: false,
      },

      //SNAPCHAT
      CPV_swipes: { name: 'CPV_swipes', query: 'SUM(#cost)/SUM(#swipes)', userDefined: false },
      VTR_swipes: { name: 'VTR_swipes', query: '100*SUM(#swipes)/SUM(#impressions)', userDefined: false },
      CPV_video_views: { name: 'CPV_video_views', query: 'SUM(#cost)/SUM(#video_views)', userDefined: false },
      CPV_video_views_15s: {
        name: 'CPV_video_views_15s',
        query: 'SUM(#cost)/SUM(#video_views_15s)',
        userDefined: false,
      },
      VTR_video_views_15s: {
        name: 'VTR_video_views_15s',
        query: '100*SUM(#video_views_15s)/SUM(#impressions)',
        userDefined: false,
      },
      CPV_shares: { name: 'CPV_shares', query: 'SUM(#cost)/SUM(#shares)', userDefined: false },
      avg_screen_time: {
        name: 'avg_screen_time',
        query: 'SUM(#screen_time_millis)/(1000 * SUM(#impressions))',
        userDefined: false,
      },
      avg_screen_time_millis: {
        name: 'avg_screen_time_millis',
        query: 'SUM(#screen_time_millis)/sum(#impressions)',
        userDefined: false,
      },
      VTR_Q1: { name: 'VTR_Q1', query: '100*SUM(#quartile_1)/SUM(#impressions)', userDefined: false },
      VTR_Q2: { name: 'VTR_Q2', query: '100*SUM(#quartile_2)/SUM(#impressions)', userDefined: false },
      VTR_Q3: { name: 'VTR_Q3', query: '100*SUM(#quartile_3)/SUM(#impressions)', userDefined: false },
      VTR_view_completion: {
        name: 'VTR_view_completion',
        query: '100*SUM(#view_completion)/SUM(#impressions)',
        userDefined: false,
      },

      //LINKEDIN
      video_ViewRate: { name: 'video_ViewRate', query: '100*SUM(#video_views)/SUM(#impressions)', userDefined: false },
      completions_ViewRate: {
        name: 'completions_ViewRate',
        query: '100*SUM(#video_completions)/SUM(#impressions)',
        userDefined: false,
      },
      views_eCPV: { name: 'eCPV', query: '100*SUM(#cost)/SUM(#video_views)', userDefined: false },
      completions_eCPV: { name: 'eCPV', query: '100*SUM(#cost)/SUM(#video_completions)', userDefined: false },
      landing_page_CTR: {
        name: 'landing_page_CTR%',
        query: '100*SUM(#landing_page_clicks)/SUM(#impressions)',
        userDefined: false,
      },
      linkedIn_CTR: { name: 'linkedIn_CTR%', query: '100*SUM(#linkedin_clicks)/SUM(#impressions)', userDefined: false },
      landing_page_CPC: { name: 'landing_page_CPC', query: 'SUM(#cost)/SUM(#landing_page_clicks)', userDefined: false },
      linkedIn_CPC: { name: 'linkedIn_CPC', query: 'SUM(#cost)/SUM(#linkedin_clicks)', userDefined: false },

      //TV
      planned_price_per_TRP: {
        name: 'planned_price_per_TRP',
        query: 'SUM(#planned_budget_incl_fee_per_spot)/SUM(#planned_primary_trps)',
        userDefined: false,
      },
      actual_price_per_TRP: {
        name: 'actual_price_per_TRP',
        query: 'SUM(#budget_incl_fee_per_spot)/SUM(#trp_primary_target)',
        userDefined: false,
      },
      CPMTV: {
        name: 'CPM',
        query: '100000*(SUM(#campaign_value_per_spot)/SUM(#trp_primary_target))/AVG(#universe_primary_target)',
        userDefined: false,
      },
      CPMTV30: {
        name: 'CPM(30 sec)',
        query: '100000*(SUM(#campaign_value_per_spot)/SUM(#trp30_primary_target))/AVG(#universe_primary_target)',
        userDefined: false,
      },
      PRICEperTRP: {
        name: 'Price/TRP',
        query: 'SUM(#campaign_value_per_spot)/SUM(#trp_primary_target)',
        userDefined: false,
      },
      PRICEperTRP30: {
        name: 'Price/TRP(30 sec)',
        query: 'SUM(#campaign_value_per_spot)/SUM(#trp30_primary_target)',
        userDefined: false,
      },

      //DV360
      VTR360: {
        name: 'VTR(complete_views)',
        query: '100*SUM(#complete_views_video)/SUM(#impressions)',
        userDefined: false,
      },
      VTR360_TRUEVIEWS: {
        name: 'VTR(trueviews)',
        query: '100*SUM(#trueview_views)/SUM(#impressions)',
        userDefined: false,
      },
      CP_completedviews: {
        name: 'cost_per_complete_views',
        query: 'SUM(#cost)/SUM(#complete_views_video)',
        userDefined: false,
      },
      CP_trueviews: { name: 'cost_per_trueviews', query: 'SUM(#cost)/SUM(#trueview_views)', userDefined: false },
    };

    const adform = {
      name: 'Adform',
      metaName: 'DATASOURCE#ADF',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.CPC,
        calcMetricFormulas.COV,
        calcMetricFormulas.eCPMV,
        calcMetricFormulas.viewimpressionspercentAdform,
      ],
    };
    const dv360 = {
      name: 'DV360',
      metaName: 'DATASOURCE#DV360',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.CPC,
        calcMetricFormulas.COV,
        calcMetricFormulas.eCPMV,
        calcMetricFormulas.viewimpressionspercent,
        calcMetricFormulas.VTR360,
        calcMetricFormulas.VTR360_TRUEVIEWS,
        calcMetricFormulas.CP_completedviews,
        calcMetricFormulas.CP_trueviews,
      ],
    };

    const cm = {
      name: 'CM',
      metaName: 'DATASOURCE#CM',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.CPC,
        calcMetricFormulas.COV,
        calcMetricFormulas.eCPMV,
        calcMetricFormulas.viewimpressionspercent,
      ],
    };
    const facebook = {
      name: 'Facebook',
      metaName: 'DATASOURCE#FB',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.CPCfb,
        calcMetricFormulas.CPLPview,
        calcMetricFormulas.VTR_3_sec,
        calcMetricFormulas.VTR_thruplay,
        calcMetricFormulas.VTR_completed_view,
        calcMetricFormulas.CPV_3_sec,
        calcMetricFormulas.CPV_Thruplay,
        calcMetricFormulas.CPV_completed_view,
        calcMetricFormulas.avgWatchTimeFB,
        calcMetricFormulas.ad_frequency,
        calcMetricFormulas.adset_frequency,
        calcMetricFormulas.campaign_frequency,
        calcMetricFormulas.campaign_mapping_frequency,
        calcMetricFormulas.Instant_Experience_Avg_Watch_Time,
        calcMetricFormulas.Instant_Experience_Percentage,
      ],
    };
    const youtube = {
      name: 'Youtube',
      metaName: 'DATASOURCE#GAD',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CPC,
        calcMetricFormulas.CTR,
        calcMetricFormulas.ViewRate,
        calcMetricFormulas.VideoPlayed25,
        calcMetricFormulas.VideoPlayed50,
        calcMetricFormulas.VideoPlayed75,
        calcMetricFormulas.VideoPlayed100,
        calcMetricFormulas.CPVtrueview,
        calcMetricFormulas.CPVbumper_no_skip,
        calcMetricFormulas.avgWatchTime,
        calcMetricFormulas.VideoPlayedAtLeast25,
        calcMetricFormulas.VideoPlayedAtLeast50,
        calcMetricFormulas.VideoPlayedAtLeast75,
        calcMetricFormulas.VideoPlayedAtLeast100,
      ],
    };

    const tiktok = {
      name: 'TikTok',
      //  icon: <SiTiktok style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px` }} />,
      metaName: 'DATASOURCE#TIK',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.CPC,
        calcMetricFormulas.VTR_2_sec,
        calcMetricFormulas.VTR_6_sec,
        calcMetricFormulas.VTR_completed_view_Tik,
        calcMetricFormulas.CPV_2_sec,
        calcMetricFormulas.CPV_6_sec,
        calcMetricFormulas.CPV_completed_view_Tik,
        calcMetricFormulas.avgWatchTimeTIKTOK,
      ],
    };

    const snapchat = {
      name: 'Snapchat',
      // icon: (
      //     <FaSnapchatSquare style={{ fontSize: '1.5rem', marginBottom: '-5px', marginRight: `8px`, color: `	#FFFC00` }} />
      // ),
      metaName: 'DATASOURCE#SNAP',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CPC,
        calcMetricFormulas.CPV_swipes,
        calcMetricFormulas.VTR_swipes,
        calcMetricFormulas.CPV_video_views,
        calcMetricFormulas.CPV_video_views_15s,
        calcMetricFormulas.VTR_video_views_15s,
        calcMetricFormulas.CPV_shares,
        calcMetricFormulas.avg_screen_time,
        calcMetricFormulas.avg_screen_time_millis,
        calcMetricFormulas.VTR_Q1,
        calcMetricFormulas.VTR_Q2,
        calcMetricFormulas.VTR_Q3,
        calcMetricFormulas.VTR_view_completion,
      ],
    };

    const linkedIn = {
      name: 'LinkedIn',
      metaName: 'DATASOURCE#LI',
      calcMetrics: [
        calcMetricFormulas.CPM,
        calcMetricFormulas.CTR,
        calcMetricFormulas.landing_page_CTR,
        calcMetricFormulas.linkedIn_CTR,
        calcMetricFormulas.video_ViewRate,
        calcMetricFormulas.completions_ViewRate,
        calcMetricFormulas.CPC,
        calcMetricFormulas.landing_page_CPC,
        calcMetricFormulas.linkedIn_CPC,
        calcMetricFormulas.views_eCPV,
        calcMetricFormulas.completions_eCPV,
      ],
    };

    const TV = {
      name: 'TV',
      metaName: 'DATASOURCE#ADE',
      calcMetrics: [
        calcMetricFormulas.planned_price_per_TRP,
        calcMetricFormulas.actual_price_per_TRP,
        calcMetricFormulas.CPMTV,
        calcMetricFormulas.CPMTV30,
        calcMetricFormulas.PRICEperTRP,
        calcMetricFormulas.PRICEperTRP30,
      ],
    };
    const Radio = {
      name: 'RADIO',
      metaName: 'DATASOURCE#ADR',
      calcMetrics: [
        calcMetricFormulas.planned_price_per_TRP,
        calcMetricFormulas.actual_price_per_TRP,
        calcMetricFormulas.CPMTV,
        calcMetricFormulas.CPMTV30,
        calcMetricFormulas.PRICEperTRP,
        calcMetricFormulas.PRICEperTRP30,
      ],
    };
    const OOH = {
      name: 'OOH',
      metaName: 'DATASOURCE#OOH',
      calcMetrics: [calcMetricFormulas.planned_price_per_TRP, calcMetricFormulas.actual_price_per_TRP],
    };
    const PRINT = {
      name: 'PRINT',
      metaName: 'DATASOURCE#PRINT',
      calcMetrics: [calcMetricFormulas.planned_price_per_TRP, calcMetricFormulas.actual_price_per_TRP],
    };
    const CINEMA = {
      name: 'CINEMA',
      metaName: 'DATASOURCE#CINEMA',
      calcMetrics: [],
    };

    const readpeak = {
      name: 'readpeak',
      metaName: 'DATASOURCE#RP',
      calcMetrics: [calcMetricFormulas.CPM, calcMetricFormulas.CPC, calcMetricFormulas.CTR],
    };

    const pinterest = {
      name: 'Pinterest',
      metaName: 'DATASOURCE#PI',
      calcMetrics: [],
    };

    const dv360YT = {
      name: 'DV360(YouTube)',
      metaName: 'DATASOURCE#DV360_YOUTUBE',
      calcMetrics: [
        // calcMetricFormulas.CPM,
        // calcMetricFormulas.CTR,
        // calcMetricFormulas.CPC,
        // calcMetricFormulas.COV,
        // calcMetricFormulas.eCPMV,
        // calcMetricFormulas.viewimpressionspercent,
        // calcMetricFormulas.VTR360,
        // calcMetricFormulas.VTR360_TRUEVIEWS,
        // calcMetricFormulas.CP_completedviews,
        // calcMetricFormulas.CP_trueviews,
      ],
    };

    const taboola = {
      name: 'Taboola',
      metaName: 'DATASOURCE#TAB',
      calcMetrics: [],
    };
    const marathon = {
      name: 'Marathon',
      metaName: 'DATASOURCE#MAR_DIGITAL_TRACKING',
      calcMetrics: [],
    };
    const olv = {
      name: 'Online Video',
      metaName: 'DATASOURCE#OLV',
      calcMetrics: [],
    };

    const customSource = {
      name: 'Custom',
      metaName: 'DATASOURCE#CUSTOMSOURCE',
      calcMetrics: [calcMetricFormulas.CPM, calcMetricFormulas.CPC],
    };

    const googleAds = {
      name: 'Google Ads',
      metaName: 'DATASOURCE#GAD_DISPLAY',
      calcMetrics: [
        // calcMetricFormulas.CPM,
        // calcMetricFormulas.CPC,
        // calcMetricFormulas.CTR,
        // calcMetricFormulas.ViewRate,
        // calcMetricFormulas.VideoPlayed25,
        // calcMetricFormulas.VideoPlayed50,
        // calcMetricFormulas.VideoPlayed75,
        // calcMetricFormulas.VideoPlayed100,
        // calcMetricFormulas.CPVtrueview,
        // calcMetricFormulas.CPVbumper_no_skip,
        // calcMetricFormulas.avgWatchTime,
        // calcMetricFormulas.VideoPlayedAtLeast25,
        // calcMetricFormulas.VideoPlayedAtLeast50,
        // calcMetricFormulas.VideoPlayedAtLeast75,
        // calcMetricFormulas.VideoPlayedAtLeast100,
      ],
    };

    if (activeCampaign) {
      if (activeCampaign.authorized || (!activeCampaign.authorized && userCanDoInsource)) {
        setFullSources([]);
        setFullSourcesPrev([]);
        setAdformQ(undefined);
        setFacebookQ(undefined);
        fetchSources();
      }
    }
  }, [activeCampaign, user?.username]);

  return [fullSources] as const;
};
