import { PDFelementType } from "context/InputContext"
import html2canvas from "html2canvas"
import { useEffect, useState } from "react"


export const useHTML2Canvas = (
    capture: boolean,
    tableRef: any,
    placeholder: string,
    title: string,
    comment: string,
    columns?: any,
    data?: any
): PDFelementType | undefined => {

    const [returnObject, setReturnObject] = useState<PDFelementType>()

    useEffect(() => {
        setTimeout(() => {
            if (capture) {

                const getCanvas = async () => {
                    const element = tableRef
                    const canvas = await html2canvas(element)
                    const imgData = canvas.toDataURL('image/png')
                    const dimensions = { width: canvas.width, height: canvas.height };
                    const ratioMultiplier: number = (12 / dimensions.width) * 400

                    const object = {
                        placeholder: placeholder,
                        image: imgData,
                        title: title === "Add title here" ? "" : title,
                        comment: comment,
                        captured: true,
                        columns: columns ? columns : undefined,
                        data: data ? data : undefined,
                        ratioMultiplier: ratioMultiplier

                    }
                    setReturnObject(object)
                    return object
                }

                getCanvas()
            } else {
                return undefined
            }
        }, 2000);

    }, [capture])




    return returnObject
}