import styled from 'styled-components';

const StyledSVG = styled.svg`
  background: ${(props) => props.theme.background};

  .cls-1,
  .cls-2,
  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6,
  .cls-7,
  .cls-8,
  .cls-9,
  .cls-10,
  .cls-11,
  .cls-12 {
    stroke-width: 0px;
  }

  .cls-1 {
    fill: ${(props) => props.theme.c50};
  }
  .cls-2 {
    fill: ${(props) => props.theme.c100};
  }
  .cls-3 {
    fill: ${(props) => props.theme.c200};
  }
  .cls-4 {
    fill: ${(props) => props.theme.c300};
  }
  .cls-5 {
    fill: ${(props) => props.theme.c400};
  }
  .cls-6 {
    fill: ${(props) => props.theme.c500};
  }
  .cls-7 {
    fill: ${(props) => props.theme.c100};
  }
  .cls-8 {
    fill: ${(props) => props.theme.c200};
  }
  .cls-9 {
    fill: ${(props) => props.theme.c600};
  }
  .cls-10 {
    fill: ${(props) => props.theme.c300};
  }
  .cls-11 {
    fill: ${(props) => props.theme.c400};
  }
  .cls-12 {
    fill: ${(props) => props.theme.c500};
  }
  .cls-13 {
    fill: none;
    stroke: ${(props) => props.theme.border};
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }
`;

const PreviewTV = () => {
  return (
    <StyledSVG id="Layer_1" data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1778.9 906.25">
      <defs>

      </defs>
      <path
        className="cls-12"
        d="M1558.2,404.99c25.38,9.72,41.4,26.67,46.87,51.31,6.6,29.76-8.96,59.89-38.31,74.27-8.86,4.34-18.42,7.56-28.67,7.82-5.54.14-4.82,3.6-5.15,6.77-1.16-5.91-1.15-6.02-8.45-6.56-19.88-1.49-36.43-9.12-50.2-22.09-29.31-27.61-25.88-75.9,7.07-99.9,5.22-3.8,10.67-7.21,16.67-9.88,1.93.25,3.28.97,4.19,2.77,7.51,15.01,16.26,29.49,22.44,45.05,1.08,2.72,2.5,5.36,4.6,7.55,0-.07.34.02.32-.04.48-.41.67-.98,1.11-1.38,2.64-1.74,3.49-4.27,4.36-7.02,3.49-11.07,8.92-21.49,13.42-32.21,1.82-4.34,3.59-8.69,5.54-12.98.83-1.82,1.86-3.22,4.17-3.46Z"
      />
      <path
        className="cls-5"
        d="M226.93,558.71c-.02-55.45-.03-110.9-.13-166.35,0-3.22.98-4.45,4.71-4.41,15.15.16,30.3.16,45.45,0,3.73-.04,4.72,1.19,4.71,4.41-.1,55.45-.11,110.9-.13,166.35-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43Z"
      />
      <path
        className="cls-5"
        d="M1244.31,388.77c.05,13.49.14,26.99.13,40.48,0,43.15-.04,86.31-.07,129.46-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43-.03-49.71-.05-99.41-.08-149.12,0-6.9-.04-13.8-.06-20.7,1.3-1.78,3.53-1.45,5.23-1.41,14.84.34,29.68-.19,44.51.19,1.79.05,3.54-.19,4.94,1.1Z"
      />
      <path
        className="cls-5"
        d="M1077.42,558.71c-.02-44.46-.02-88.93-.13-133.39,0-3.23.99-4.44,4.72-4.4,15.15.16,30.3.16,45.45,0,3.73-.04,4.72,1.17,4.72,4.4-.11,44.46-.11,88.93-.13,133.39-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43Z"
      />
      <path
        className="cls-5"
        d="M114.41,446.24c.03-4.64.18-9.28.06-13.91-.07-2.63.85-3.8,3.95-3.78,15.6.09,31.2.14,46.79-.02,4.39-.05,4.07,2.31,4.07,4.94-.02,27.97,0,55.95-.01,83.92,0,13.78-.05,27.55-.07,41.33-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43-.03-34.33-.05-68.66-.08-102.99,0-2.25-.08-4.5-.12-6.75,1.31-1.92,3.4-1.27,5.25-1.29,13.1-.17,26.19-.03,35.75-.09-9.55-.04-22.63.08-35.7-.08-1.86-.02-3.94.61-5.28-1.26Z"
      />
      <path
        className="cls-5"
        d="M965.07,558.71c-.02-37.14-.02-74.28-.13-111.43-.01-3.24,1-4.44,4.72-4.4,15.15.16,30.29.16,45.44,0,3.72-.04,4.73,1.16,4.72,4.4-.12,37.14-.11,74.28-.13,111.43-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43Z"
      />
      <path
        className="cls-5"
        d="M393.83,493.57c.02,21.71.04,43.43.06,65.14-1.11.71-2.38.43-3.58.43-15.82.02-31.64.02-47.45,0-1.2,0-2.47.28-3.58-.43-.05-21.67-.1-43.34-.15-65.01,1.98-2.18,4.8-1.3,7.14-1.3,14.36-.04,28.72-.05,43.07-.02,1.57,0,3.28-.1,4.48,1.19Z"
      />
      <path
        className="cls-8"
        d="M518.45,558.72c-.02-60.69,0-121.38-.13-182.07,0-3.8,1.46-4.85,5.41-4.75,13.39.34,11.32-1.54,11.33,10.24.06,58.86,0,117.72-.03,176.57-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M552.81,558.72c-.02-47.73,0-95.46-.13-143.19-.01-3.8,1.46-4.85,5.41-4.75,13.39.34,11.31-1.54,11.33,10.24.07,45.9,0,91.8-.02,137.7-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M586.23,558.72c-.02-40.12,0-80.25-.13-120.37-.01-3.8,1.46-4.85,5.41-4.75,13.39.34,11.31-1.54,11.33,10.24.08,38.29,0,76.59-.02,114.88-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M620.59,558.72c-.02-34.49.02-68.98-.14-103.47-.02-3.8,1.46-4.85,5.41-4.75,13.39.34,11.3-1.54,11.33,10.24.08,32.66,0,65.32-.02,97.98-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M654.01,558.72c-.02-28.01.03-56.02-.14-84.03-.02-3.8,1.46-4.85,5.41-4.75,13.38.34,11.29-1.54,11.33,10.24.09,26.18,0,52.36-.02,78.54-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M687.44,558.72c-.02-24.07.04-48.13-.14-72.2-.03-3.8,1.47-4.85,5.41-4.75,13.38.34,11.27-1.54,11.33,10.23.1,22.24,0,44.47-.03,66.71-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M721.79,558.72c-.02-20.69.05-41.37-.14-62.06-.03-3.8,1.47-4.84,5.41-4.75,13.38.33,11.26-1.54,11.33,10.23.11,18.86,0,37.71-.03,56.57-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M755.22,558.72c-.02-18.15.06-36.3-.14-54.46-.04-3.8,1.47-4.84,5.41-4.74,13.38.33,11.25-1.54,11.33,10.23.11,16.32,0,32.65-.03,48.97-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M789.57,558.72c-.02-15.62.07-31.24-.14-46.85-.05-3.81,1.47-4.84,5.42-4.74,13.38.33,11.23-1.54,11.33,10.23.12,13.79,0,27.58-.03,41.36-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-8"
        d="M823,558.72c-.02-13.65.09-27.29-.14-40.94-.06-3.81,1.48-4.84,5.42-4.74,13.38.33,11.22-1.54,11.33,10.23.12,11.82-.01,23.63-.03,35.45-5.53.7-11.05.7-16.58,0Z"
      />
      <path
        className="cls-5"
        d="M1530.51,465.81c-.11.5-.34.87-1.01.79-7.98-18.52-12.8-37.88-18.06-57.11-.64-2.32-2.12-4.86.44-7.09,1.6-1.01,3.54-.84,5.32-1.21,1.81-.38,3.2-1.01,3.12-2.98.12-.2.25-.4.37-.6.11.21.21.42.31.63.05,1.65,1.05,2.38,2.8,2.37,1.16-.55,2.42-.22,3.63-.36,3-.33,4.3.96,4.24,3.58-.1,5.33.93,10.61,1,15.93.18,12.75.26,25.51-.88,38.24-.23,2.62-.93,5.2-1.28,7.81Z"
      />
      <path
        className="cls-5"
        d="M1189.51,386.15q.62-9.97,11.52-9.97c12.62,0,25.24.17,37.85-.09,4.52-.09,6.18,1.25,5.6,5.25-.24,1.66-.1,3.36-.13,5.03-3.87,1.99-8.04.9-12.11,1.04-12.55.42-25.11,0-37.66.16-1.7.02-3.88.49-5.07-1.42Z"
      />
      <path
        className="cls-2"
        d="M1511.88,402.4c5.45,19.34,10.92,38.67,16.34,58.01.57,2.03.86,4.13,1.28,6.2-9.78-18.72-19.56-37.44-29.35-56.16-.66-1.26-1.4-2.49-2.11-3.73,1.98-1.3,5.38-1.09,5.52-4.33.28-.3.55-.28.79.05,2.53,2.62,5.02-.56,7.53-.03Z"
      />
      <path
        className="cls-11"
        d="M1558.2,404.99c-7.91,18.14-15.79,36.28-23.78,54.39-.47,1.06-.09,3.71-3.03,2.22-.06-10.7,2.14-21.15,4.41-31.58,1.74-7.97,3.25-15.98,5.1-23.92.47-2.01,1.13-3.79,3.45-4.59,2.16.31,4.33.62,6.49.93.57.41,1.45.14,1.94.73,1.8.61,3.6,1.22,5.41,1.83Z"
      />
      <path
        className="cls-8"
        d="M1544.37,401.5c-3.99,18.81-7.98,37.63-11.98,56.44-.26,1.23-.65,2.44-.98,3.66-.04,1.45.03,2.92-.89,4.21-.03-12.93.03-25.86-.13-38.79-.1-7.86-.6-15.71-.79-23.56-.1-4.26-3.72-2.15-5.79-2.85,6.94-1.39,13.78-.76,20.57.89Z"
      />
      <path
        className="cls-10"
        d="M965.07,558.71c18.2,0,36.41,0,54.61,0,19.24,0,38.49,0,57.73,0,18.2,0,36.41,0,54.61,0,19.24,0,38.49,0,57.73,0,18.2,0,36.41,0,54.61,0,8.93,0,17.86,0,26.79,0,0,.21,0,.43,0,.64h-333.8c0-.21,0-.43,0-.64h27.72Z"
      />
      <path
        className="cls-10"
        d="M339.27,558.71c18.2,0,36.41,0,54.61,0h26.79v.64H86.87c0-.21,0-.43,0-.64,9.24,0,18.48,0,27.72,0,18.2,0,36.41,0,54.61,0,19.24,0,38.49,0,57.73,0,18.2,0,36.41,0,54.61,0h57.73Z"
      />
      <path
        className="cls-10"
        d="M823,558.72c5.53,0,11.05,0,16.58,0,2.12,0,4.24,0,6.36,0,0,.22-.02.43-.03.65h-333.8c0-.22,0-.43,0-.65,2.12,0,4.23,0,6.35,0,5.53,0,11.05,0,16.58,0,5.92,0,11.85,0,17.77,0,5.53,0,11.05,0,16.58,0,5.61,0,11.23,0,16.85,0,5.53,0,11.05,0,16.58,0h17.77c5.53,0,11.05,0,16.58,0,5.61,0,11.23,0,16.85,0,5.53,0,11.05,0,16.58,0,5.61,0,11.23,0,16.85,0,5.53,0,11.05,0,16.58,0,5.92,0,11.85,0,17.77,0h16.58c5.61,0,11.23,0,16.85,0h50.93c5.62,0,11.23,0,16.85,0Z"
      />
      <path
        className="cls-9"
        d="M990.64,429.42c-7.88,0-15.77.05-23.65-.05-1.29-.02-3.62.9-3.6-1.31.02-1.84,2.15-1.13,3.35-1.13,16.23-.05,32.46-.05,48.69,0,1.18,0,3.27-.63,3.15,1.32-.12,1.86-2.17,1.12-3.37,1.13-8.19.07-16.38.04-24.58.04Z"
      />
      <path
        className="cls-6"
        d="M253.71,360.97c8.19,0,16.38-.03,24.57.04,1.16.01,3.23-.73,3.09,1.38-.11,1.64-1.9,1.08-2.98,1.08-16.38.04-32.77.05-49.15,0-1.15,0-3.23.74-3.09-1.37.1-1.64,1.89-1.08,2.98-1.09,8.19-.05,16.38-.03,24.57-.03Z"
      />
      <path
        className="cls-1"
        d="M1111.77,414.93c5.85.02,11.71,0,17.56.07.96.01,2.45-.37,2.56,1.06.14,1.77-1.55,1.43-2.66,1.43-16.64.03-33.28.03-49.91,0-1.11,0-2.86.34-2.67-1.41.18-1.63,1.91-1.06,2.99-1.07,6.62-.09,13.25-.09,19.87-.11,4.09-.69,8.18-.55,12.26.04Z"
      />
      <path
        className="cls-6"
        d="M393.83,493.57c-18.23.04-36.47.09-54.7.13-1.96-2.73.21-2.64,2.19-2.64,8.66,0,17.32,0,25.98,0,7.73,0,15.46,0,23.2,0,1.89,0,3.97-.24,3.33,2.51Z"
      />
      <path
        className="cls-9"
        d="M114.41,446.24c15.75.03,31.5.05,47.25.08,1.08,0,2.17-.04,3.24.04,1.02.07,2.56-.2,2.68,1.05.17,1.71-1.62,1.4-2.7,1.41-9.73.06-19.46.04-29.18.06-7.1.01-14.2.06-21.31.09-.89-.12-1.7-.26-1.68-1.36.02-1.08.83-1.23,1.71-1.37Z"
      />
      <path
        className="cls-6"
        d="M1189.51,386.15c18.28.08,36.56.15,54.84.23-.02.8-.03,1.59-.05,2.39-18.23.04-36.46.09-54.69.13-1.08-.88-1.2-1.79-.11-2.75Z"
      />
      <rect className="cls-4" x="1497.89" y="44.32" width="83.79" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1397.63" y="45.36" width="83.79" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1287.78" y="44.32" width="92.76" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1158.78" y="44.17" width="107.98" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="58.44" y="82.12" width="69.32" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="138.84" y="82.24" width="220.03" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="366.22" y="82.12" width="220.03" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1603.61" y="85.42" width="127.71" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1425.24" y="74.53" width="149.45" height="10.77" rx="3" ry="3" />
      <rect className="cls-4" x="1425.24" y="91.85" width="149.45" height="10.77" rx="3" ry="3" />
      <rect className="cls-7" x="56.95" y="49.73" width="107.98" height="14.26" rx="3" ry="3" />
      <rect className="cls-7" x="791.09" y="657.37" width="63.97" height="14.26" rx="3" ry="3" />
      <rect className="cls-7" x="757.44" y="680.06" width="97.62" height="24.57" rx="3" ry="3" />
      <rect className="cls-7" x="1620.67" y="658.6" width="63.97" height="14.26" rx="3" ry="3" />
      <rect className="cls-7" x="1587.02" y="681.28" width="97.62" height="24.57" rx="3" ry="3" />
      <g>
        <g>
          <rect className="cls-3" x="73.58" y="156.8" width="69.32" height="10.77" rx="3" ry="3" />
          <g>
            <rect className="cls-3" x="190.7" y="181.76" width="69.32" height="13.95" rx="3" ry="3" />
            <rect className="cls-3" x="190.7" y="198.19" width="44.03" height="11.78" rx="3" ry="3" />
            <rect className="cls-6" x="73.82" y="181.45" width="107.98" height="28.52" rx="3" ry="3" />
          </g>
          <rect className="cls-7" x="72.78" y="139.09" width="80.16" height="14.26" rx="3" ry="3" />
        </g>
        <rect className="cls-13" x="56.28" y="115.83" width="395.51" height="117.4" rx="3" ry="3" />
      </g>
      <g>
        <g>
          <rect className="cls-3" x="490.26" y="156.8" width="69.32" height="10.77" rx="3" ry="3" />
          <g>
            <rect className="cls-3" x="607.38" y="181.76" width="69.32" height="13.95" rx="3" ry="3" />
            <rect className="cls-3" x="607.38" y="198.19" width="44.03" height="11.78" rx="3" ry="3" />
            <rect className="cls-6" x="490.5" y="181.45" width="107.98" height="28.52" rx="3" ry="3" />
          </g>
          <rect className="cls-7" x="489.46" y="139.09" width="80.16" height="14.26" rx="3" ry="3" />
        </g>
        <rect className="cls-13" x="472.96" y="115.83" width="395.51" height="117.4" rx="3" ry="3" />
      </g>
      <g>
        <g>
          <rect className="cls-3" x="906.93" y="156.8" width="69.32" height="10.77" rx="3" ry="3" />
          <g>
            <rect className="cls-3" x="1024.05" y="181.76" width="69.32" height="13.95" rx="3" ry="3" />
            <rect className="cls-3" x="1024.05" y="198.19" width="44.03" height="11.78" rx="3" ry="3" />
            <rect className="cls-6" x="907.17" y="181.45" width="107.98" height="28.52" rx="3" ry="3" />
          </g>
          <rect className="cls-7" x="906.13" y="139.09" width="80.16" height="14.26" rx="3" ry="3" />
        </g>
        <rect className="cls-13" x="889.63" y="115.83" width="395.51" height="117.4" rx="3" ry="3" />
      </g>
      <g>
        <g>
          <rect className="cls-3" x="1323.61" y="156.8" width="69.32" height="10.77" rx="3" ry="3" />
          <g>
            <rect className="cls-3" x="1440.73" y="181.76" width="69.32" height="13.95" rx="3" ry="3" />
            <rect className="cls-3" x="1440.73" y="198.19" width="44.03" height="11.78" rx="3" ry="3" />
            <rect className="cls-6" x="1323.85" y="181.45" width="107.98" height="28.52" rx="3" ry="3" />
          </g>
          <rect className="cls-7" x="1322.81" y="139.09" width="80.16" height="14.26" rx="3" ry="3" />
        </g>
        <rect className="cls-13" x="1306.31" y="115.83" width="395.51" height="117.4" rx="3" ry="3" />
      </g>
      <rect className="cls-13" x="56.28" y="248.18" width="395.51" height="378.56" rx="3" ry="3" />
      <rect className="cls-13" x="56.02" y="641.7" width="812.45" height="225.96" rx="3" ry="3" />
      <rect className="cls-13" x="888.5" y="641.7" width="812.45" height="225.96" rx="3" ry="3" />
      <rect className="cls-13" x="6.88" y="16.51" width="1766.52" height="877.76" rx="22" ry="22" />
      <rect className="cls-13" x="472.96" y="248.18" width="395.51" height="378.56" rx="3" ry="3" />
      <rect className="cls-13" x="889.02" y="248.18" width="395.51" height="378.56" rx="3" ry="3" />
      <rect className="cls-13" x="1306.87" y="248.18" width="395.51" height="378.56" rx="3" ry="3" />
      <rect className="cls-3" x="588.34" y="591.37" width="146.11" height="12.89" rx="3" ry="3" />
      <rect className="cls-7" x="91.53" y="724.77" width="107.35" height="14.26" rx="3" ry="3" />
      <rect className="cls-3" x="91.98" y="742.21" width="69.32" height="10.77" rx="3" ry="3" />
      <rect className="cls-7" x="923.3" y="724.77" width="107.35" height="14.26" rx="3" ry="3" />
      <rect className="cls-3" x="923.75" y="742.21" width="69.32" height="10.77" rx="3" ry="3" />
      <g>
        <rect className="cls-7" x="363.26" y="259.63" width="63.97" height="14.26" rx="3" ry="3" />
        <rect className="cls-7" x="329.61" y="282.31" width="97.62" height="24.57" rx="3" ry="3" />
        <rect className="cls-7" x="80.31" y="289.44" width="107.35" height="14.26" rx="3" ry="3" />
        <rect className="cls-3" x="80.85" y="306.88" width="69.32" height="10.77" rx="3" ry="3" />
      </g>
      <rect className="cls-9" x="1603.68" y="38.59" width="127.57" height="27.19" />
      <g>
        <rect className="cls-7" x="776.31" y="267.97" width="63.97" height="14.26" rx="3" ry="3" />
        <rect className="cls-7" x="742.66" y="290.66" width="97.62" height="24.57" rx="3" ry="3" />
        <rect className="cls-7" x="493.37" y="297.78" width="107.35" height="14.26" rx="3" ry="3" />
        <rect className="cls-3" x="493.9" y="315.22" width="69.32" height="10.77" rx="3" ry="3" />
      </g>
      <g>
        <rect className="cls-7" x="1192.91" y="268.77" width="63.97" height="14.26" rx="3" ry="3" />
        <rect className="cls-7" x="1159.26" y="291.46" width="97.62" height="24.57" rx="3" ry="3" />
        <rect className="cls-7" x="909.97" y="298.59" width="107.35" height="14.26" rx="3" ry="3" />
        <rect className="cls-3" x="910.5" y="316.03" width="69.32" height="10.77" rx="3" ry="3" />
      </g>
      <g>
        <rect className="cls-7" x="1616.2" y="269.58" width="63.97" height="14.26" rx="3" ry="3" />
        <rect className="cls-7" x="1582.55" y="292.26" width="97.62" height="24.57" rx="3" ry="3" />
        <rect className="cls-7" x="1333.26" y="299.39" width="107.35" height="14.26" rx="3" ry="3" />
        <rect className="cls-3" x="1333.79" y="316.83" width="69.32" height="10.77" rx="3" ry="3" />
      </g>
      <rect className="cls-3" x="180.72" y="594.08" width="146.11" height="12.89" rx="3" ry="3" />
      <rect className="cls-3" x="1031.67" y="591.37" width="146.11" height="12.89" rx="3" ry="3" />
      <rect className="cls-3" x="1456.45" y="591.37" width="146.11" height="12.89" rx="3" ry="3" />
    </StyledSVG>
  );
};

export default PreviewTV;
