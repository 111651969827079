import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import React, { useState } from 'react';
import styled from 'styled-components';

export const FormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  margin-bottom: 1.3vw;

  .error-input {
    border: 1px solid red;
  }

  .error {
      color: red;
  }
  .imageExplanation {
    font-size: 0.5vw;
    color: ${({ theme }) => theme.primaryGray};
    margin-top: 5px;
    font-style: italic;
  }


  input,
  select,
  textarea {
    font-size: 12px;
    font-family: 'fontRegular';
    padding: 8px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color: ${({ theme }) => theme.background};
  }
  textarea:focus,
  input:focus {
    outline: 1px solid ${({ theme }) => theme.border};
  }
  textarea {
    resize: none;
    min-height: 100px;
  }

  option {
    font-size: 12px;
  }

  label {
    font-size: 12px;
  }

  span {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
  }
  input:invalid[focused-name='true'] {
    outline: 1px solid red;
  }
  input:invalid[focused-name='true'] ~ span {
    display: block;
  }
  .iconContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    padding: 20px 10px;
    /* height: 300px;
    overflow: scroll; */

    label {
      cursor: pointer;
      background: transparent;
      /* width: fit-content; */
      /* margin-bottom: 8px; */
      /* margin-top: 21px; */
      font-family: 'FontMedium', sans-serif;
      color: ${({ theme }) => theme.primaryGray};
      display: flex;
      justify-content: center;
      align-items: center;
      
      .svg {
        height: 2.2vw;
        fill: ${({ theme }) => theme.secondaryGray};
        stroke-width: 5px;
        width: 100%;
      }
    }

    .checkboxLabel:hover {
    background-color: black;
    color: white;
  }

  .checkboxLabel:has(input:checked) {
    background-color: ${({ theme }) => theme.primaryGray};
    color: white;

  }

    input {
      display: none;
    }

    .checked {
      padding: 9px 12px;
    }

    input:checked ~ .checked {
      border: 1px solid ${({ theme }) => theme.primaryGray};
      border-radius: 7px;
      
    }
  }
`;

const Select = styled.select`
  font-size: 12px;
  font-family: 'fontRegular';
  padding: 8px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.primaryGray};
  background-color: ${({ theme }) => theme.background};
`;

type Props = {
  value: any;
  onChange: any;
  id: any;
  name: any;
  type: any;
  placeholder: any;
  label: any;
  errorMessage: any;
  required: any;
};

const FormInput = (props: Props) => {
  const { label, onChange, id, errorMessage, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const { reducedClientList } = useCampaignContext();
  const { activeOrganization } = useClientContext()


  const parentClientCode = activeOrganization?.OrganizationId.split('#')[0]
  const parentClientCountry = activeOrganization?.OrganizationId.split('#')[1]

  const anyCampaignWithParentClient = reducedClientList?.some((client) => client?.ClientCode === parentClientCode)


  return (
    <FormInputContainer>
      <label htmlFor="">{label}</label>
      {props.type === 'textarea' ? (
        <textarea {...inputProps} onChange={onChange}></textarea>
      ) : props.type === 'dropdown' ? (
        <Select defaultValue={''} onChange={onChange}>
          <option disabled value="">
            {props.label}
          </option>
          {!anyCampaignWithParentClient && (
            <option value={parentClientCode}>
              {parentClientCode} {parentClientCountry} ()
            </option>
          )}
          {reducedClientList?.map((option) => (
            <option value={option?.ClientCode?.toString()} key={option?.ClientCode}>
              {' '}
              {option?.ClientCode} {option?.ClientCountry} ({option?.ClientName})
            </option>
          ))}
        </Select>
      ) : (
        <input {...inputProps} onChange={onChange} onBlur={() => setFocused(true)} focused-name={focused.toString()} />
      )}
      {/*   {props.type !== "textarea" ? <input {...inputProps} onChange={onChange} onBlur={() => setFocused(true)} focused-name={focused.toString()} /> : <textarea {...inputProps} onChange={onChange}  ></textarea>} */}
      <span>{errorMessage}</span>
    </FormInputContainer>
  );
};

export default FormInput;
