import {
  commentState,
  commentType,
  country,
  CreateCommentMutation,
  DashboardTypes,
  EditCommentMutation,
  mediaSource,
  RemoveCommentMutation,
} from 'API';
import { API } from 'aws-amplify';
import { Content } from 'components/Header/Header.styled';
import { useCampaignContext } from 'context/CampaignContext';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { CommentsContext } from 'context/CommentsContext';
import { createComment, editComment, removeComment } from 'graphql/mutations';
import { useContext, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Action, Bulletin, CommentsContainer, Input } from './Comments.styled';

export interface Props {
  toggleComment: boolean;
  componentID: any;
  header: boolean;
  setToggleComment(toggleComment: boolean): any;
}

const Comments = (props: Props) => {
  const { activeCampaign, dashType, activeSubCampaign } = useCampaignContext();

  const { statements } = useContext(ClientStatementsContext);
  const { commentsList, fetchCampaignComments } = useContext(CommentsContext);
  const [commentInfo, setCommentInfo] = useState<any>();

  const [textArea, setTextArea] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setCommentInfo(null);
  }, [activeCampaign]);

  useEffect(() => {
    commentsList?.forEach((comment) => {
      if (comment.placeholder === props.componentID && comment.state !== commentState.REMOVED) {
        setCommentInfo(comment);
      }
    });
  }, [commentsList]);

  const handleEdit = () => {
    setTextArea(commentInfo.comment);
    setCreatedAt(commentInfo.created_at);
    setEditing(true);
  };

  // CREATE COMMENT
  async function SaveComment() {
    try {
      const saveComment = (await API.graphql({
        query: createComment,
        variables: {
          comment: {
            campaign: dashType === 'RADIO' || dashType === 'TV' ? -1 : activeCampaign?.campaignId,
            sub_campaign: activeSubCampaign?.subcampaignId,
            client: {
              code: activeCampaign?.ClientCode,
              country: country[activeCampaign?.ClientCountry as keyof typeof country],
            },
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
            comment_type: commentType.COMMENT,
            comment: textArea,
            placeholder: props.componentID,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: CreateCommentMutation };
      if (activeCampaign) {
        if (
          dashType === 'CINEMA' ||
          dashType === 'PRINT' ||
          dashType === 'OOH' ||
          dashType === 'DIGITAL' ||
          dashType === DashboardTypes.HOME ||
          dashType === DashboardTypes.FLOWCHART ||
          dashType === DashboardTypes.INSOURCE_DATA
        ) {
          fetchCampaignComments({
            campaign: activeCampaign?.campaignId,
            comment_type: commentType.COMMENT,
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
          });
        } else if (dashType === 'RADIO' || dashType === 'TV') {
          if (activeSubCampaign && activeSubCampaign.subcampaignId !== -1) {
            fetchCampaignComments({
              campaign: -1,
              comment_type: commentType.COMMENT,
              media_source: mediaSource[dashType as keyof typeof mediaSource],
              sub_campaign: activeSubCampaign.subcampaignId,
            });
          }
        }
      }
    } catch (e) {
      console.log(`create comment error ${e}`, e);
    }
  }

  // EDIT COMMENT
  async function EditComment() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const commentEdit = (await API.graphql({
        query: editComment,
        variables: {
          comment: {
            campaign: dashType === 'RADIO' || dashType === 'TV' ? -1 : activeCampaign?.campaignId,
            sub_campaign: activeSubCampaign?.subcampaignId,
            client: {
              code: activeCampaign?.ClientCode,
              country: country[activeCampaign?.ClientCountry as keyof typeof country],
            },
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
            comment_type: commentType.COMMENT,
            created_at: createdAt,
            comment: textArea,
            placeholder: props.componentID,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: EditCommentMutation };

      setEditing(false);
      if (activeCampaign) {
        if (
          dashType === 'CINEMA' ||
          dashType === 'PRINT' ||
          dashType === 'OOH' ||
          dashType === 'DIGITAL' ||
          dashType === DashboardTypes.HOME ||
          dashType === DashboardTypes.FLOWCHART ||
          dashType === DashboardTypes.INSOURCE_DATA
        ) {
          fetchCampaignComments({
            campaign: activeCampaign?.campaignId,
            comment_type: commentType.COMMENT,
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
          });
        } else if (dashType === 'RADIO' || dashType === 'TV') {
          if (activeSubCampaign && activeSubCampaign.subcampaignId !== -1) {
            fetchCampaignComments({
              campaign: -1,
              comment_type: commentType.COMMENT,
              media_source: mediaSource[dashType as keyof typeof mediaSource],
              sub_campaign: activeSubCampaign.subcampaignId,
            });
          }
        }
      }
    } catch (e) {
      console.log(`edit comment error ${e}`, e);
    }
  }

  // REMOVE COMMENT
  async function RemoveComment() {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const commentRemove = (await API.graphql({
        query: removeComment,
        variables: {
          comment: {
            campaign: dashType === 'RADIO' || dashType === 'TV' ? -1 : activeCampaign?.campaignId,
            sub_campaign: activeSubCampaign?.subcampaignId,
            client: {
              code: activeCampaign?.ClientCode,
              country: country[activeCampaign?.ClientCountry as keyof typeof country],
            },
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
            comment_type: commentType.COMMENT,
            created_at: commentInfo.created_at,
            placeholder: props.componentID,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: RemoveCommentMutation };
      setCommentInfo(null);
      if (activeCampaign) {
        if (
          dashType === 'CINEMA' ||
          dashType === 'PRINT' ||
          dashType === 'OOH' ||
          dashType === 'DIGITAL' ||
          dashType === DashboardTypes.HOME ||
          dashType === DashboardTypes.FLOWCHART ||
          dashType === DashboardTypes.INSOURCE_DATA
        ) {
          fetchCampaignComments({
            campaign: activeCampaign?.campaignId,
            comment_type: commentType.COMMENT,
            media_source:
              dashType === DashboardTypes.HOME || dashType === DashboardTypes.FLOWCHART
                ? mediaSource.OVERALL
                : dashType === DashboardTypes.INSOURCE_DATA
                ? mediaSource.DIGITAL
                : mediaSource[dashType as keyof typeof mediaSource],
          });
        } else if (dashType === 'RADIO' || dashType === 'TV') {
          if (activeSubCampaign && activeSubCampaign.subcampaignId !== -1) {
            fetchCampaignComments({
              campaign: -1,
              comment_type: commentType.COMMENT,
              media_source: mediaSource[dashType as keyof typeof mediaSource],
              sub_campaign: activeSubCampaign.subcampaignId,
            });
          }
        }
      }
    } catch (e) {
      console.log(`remove comment error ${e}`, e);
    }
  }
  const content = (
    <>
      {props.componentID !== 'overall' && props.componentID !== 'plan' && (
        <button
          type="button"
          style={{ marginLeft: 'auto', padding: '0', boxShadow: 'initial' }}
          className="x"
          onClick={() => props.setToggleComment(false)}
        >
          {' '}
          <IoMdClose className="closeIcon" />
        </button>
      )}
      {commentInfo !== null && editing === false && statements?.Comment?.READ && (
        <Bulletin>
          {/*   {props.componentID !== "overall" && <Head>{firstName} {lastName} at {date}</Head>} */}
          <Content style={{ whiteSpace: 'pre-wrap' }}>{commentInfo?.comment}</Content>
          {statements?.Comment?.WRITE && (
            <Action>
              <button type="button" onClick={handleEdit}>
                EDIT
              </button>
              <button type="button" onClick={RemoveComment}>
                DELETE
              </button>
            </Action>
          )}
        </Bulletin>
      )}

      {commentInfo === null && editing === false && statements?.Comment?.WRITE && (
        <Input>
          <textarea
            placeholder="Enter your comment here..."
            onInput={(e) => setTextArea((e.target as HTMLInputElement).value)}
          ></textarea>
          <Action>
            <button disabled={!statements?.Comment?.WRITE} type="button" onClick={SaveComment}>
              SAVE
            </button>
            {/*  <button type="button">CANCEL</button> */}
          </Action>
        </Input>
      )}

      {commentInfo !== null && editing === true && statements?.Comment?.WRITE && (
        <Input>
          <textarea
            className="editComment"
            defaultValue={commentInfo?.comment}
            onInput={(e) => setTextArea((e.target as HTMLInputElement).value)}
          ></textarea>
          <Action>
            <button disabled={!statements?.Comment?.WRITE} type="button" onClick={EditComment}>
              SAVE
            </button>
            <button type="button" onClick={() => setEditing(false)}>
              CANCEL
            </button>
          </Action>
        </Input>
      )}
    </>
  );

  return (
    <>
      {props.componentID === 'overall' || props.componentID === 'plan' ? (
        <div>{content}</div>
      ) : (
        <CommentsContainer header={props.header} style={{ display: props.toggleComment ? 'flex' : 'none' }}>
          {content}
        </CommentsContainer>
      )}
    </>
  );
};

export default Comments;
