import { BriefValue, briefTemplateSubColumn } from 'API'
import BriefInput2 from '../2.1.BriefInput'
import { Inputs, selectType } from '../APICall/BRIEFTEMPLATE'
import { enrichedBriefTemplateSectionSubColumns, enrichedbriefTemplateSectionColumns } from '../CustomHook/useBriefDataFromClient'


type Props = {
  optionInputs: briefTemplateSubColumn[]
  type: string,
  onChange: (e: any) => void,
  name: string,

  value: any,
  briefValues: BriefValue & { Id: string }[]

}




const OptionInputs3 = (props: Props) => {
  const { optionInputs, onChange, briefValues } = props


  const getValueProps = (id: string) => {
    const foundElement = briefValues?.find(brief => brief.Id === id) as BriefValue & { Id: string }
    if (foundElement) {
      return foundElement.Value[Object.keys(foundElement.Value)[0] as keyof typeof foundElement.Value]
    } else {
      return ""
    }
  }

  return (
    <div>
      <div className='test'>
        {optionInputs?.map(subColumns => (
          <BriefInput2 briefValues={briefValues} savedValue={getValueProps(subColumns.id as string)} inputProps={subColumns} onChange={onChange} key={subColumns.name}  ></BriefInput2>
        ))}
      </div>
    </div>
  )


}

export default OptionInputs3