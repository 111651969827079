const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]



export function TV13(data: any, TVStationSelector: any, theme: any) {
    const theData = [] as any;
    const theAxis = [] as any;
    const categ = [] as any;

    let result

    if (TVStationSelector === "All stations") {
        result = data.reduce((r: any, { year_week, planned_primary_trps, trp_primary_target }: any) => {
            var temp = r.find((o: any) => o.year_week === year_week);
            if (!temp) {
                r.push({ year_week, planned_primary_trps, trp_primary_target });
            } else {
                temp.planned_primary_trps += planned_primary_trps;
                temp.trp_primary_target += trp_primary_target;
            }
            return r;
        }, []);
    } else {
        result = data.filter((el: any) => el.network === TVStationSelector)

    }


    const actual = result.map((e: any) => e.trp_primary_target)
    const planned = result.map((e: any) => e.planned_primary_trps)


    theData.push({
        data: actual,
        color: "#698296",
        name: "Actual",
        type: "column",

    }, {
        marker: {
            symbol: 'c-rect',
            lineWidth: 3,
            lineColor: theme.brand,
            radius: 0.9 * (100 / (planned.length)),

        },
        enableMouseTracking: false,
        type: "scatter",
        data: planned,
        name: "Planned"
    })

    categ.push(...result.map((e: any) => e.year_week))


    result.forEach((element: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [index, [key, value]] of Object.entries(element).entries()) {
            if (key === "year_week") {
                const yaxis = {
                    min: 0,
                    labels: {
                        format: '{value}',
                        style: {
                            color: colorArray[theData.length],
                        },
                        enabled: false
                    },
                    title: {
                        text: key,
                        style: {
                            color: colorArray[theData.length],
                        },
                        enabled: false,
                    },
                    gridLineWidth: 0,

                };
                theAxis.push(yaxis);
            }
        }
    });








    return {
        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            // type: "column",
            style: {
                fontFamily: "fontRegular"
            }
        },
        title: {
            text: 'TRP Per Week',
            align: 'left',

            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: TVStationSelector,
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },
        yAxis:
            theAxis,
        xAxis: {
            categories: categ,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },

        },
        tooltip: {

            shared: true,
            // Highcharts.TooltipFormatterContextObject
            formatter: function (this: any) {

                var ret = this.points[0].series.name + ": " + new Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(this.y) + '<br/>',
                    x = this.x,
                    name = this.points[0].series.chart.series[1].name,
                    points = this.points[0].series.chart.series[1].points;

                points.forEach((v: any, i: any) => {
                    if (v.category === x) {
                        ret += name + ': ' + new Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(v.y) + '<br/>';
                    }
                });

                return ret;
            }
        },

        plotOptions: {
            column: {
                pointPadding: 0.1,
                distance: -60,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(this.y)
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent'
                    }
                }
            },
            /*           series: {
                          stacking: "normal"
                      } */
        },
        series: theData,
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {

                return this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },

    }
}