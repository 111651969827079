import { LiteralUnion, MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import { useState } from 'react';
import PlanModalInput, { oneInput } from '../PlanComponents/PlanModalInput';
import { Modal } from 'components/Main/table/PreviewModal';
import { IoMdClose } from 'react-icons/io';
import { HeadModul, ModulContainer } from '../../../../Main/table/TableStyles';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { usePortalContext } from 'context/PortalContext';

interface YourCustomModalProps {
  table?: any;
  setModalTable?: (value: any) => void;
  newColumns: MRT_ColumnDef<any>[];
  showModal: boolean;
  setShowModal?: (value: boolean) => void;
  data: any[];
  newRow: any[];
  handleCreateUser: (props: {
    exitCreatingMode: () => void;
    row: MRT_Row<any>;
    table: MRT_TableInstance<any>;
    values: Record<LiteralUnion<string, string>, any>;
  }) => void;
  columnTypes: {
    [x: string]: string;
  }[];
  columnInputTypes: {
    [x: string]: string;
  }[];
  CommunicationTaskKey: string;
  selectedRow?: MRT_Row<any>;
  setSelectedRow?: (value: MRT_Row<any>) => void;
}

export interface Column {
  accessorKey: string;
  enableResizing: boolean;
  header: string;
  maxSize: number;
  minSize: number;
  type: string;
}

export interface ExistingColumn {
  accessorKey: string;
  enableResizing: boolean;
  header: string;
  initialValue: string | undefined;
  maxSize: number;
  minSize: number;
  type: string;
}

const PlanModal = (props: YourCustomModalProps) => {
  const {
    table,
    newColumns,
    showModal,
    data,
    setShowModal,
    newRow,
    handleCreateUser,
    columnTypes,
    columnInputTypes,
    CommunicationTaskKey,
    selectedRow,
  } = props;
  const newSetOfColumns = newColumns as Column[];

  const { section, rowId, valueType, ...rest } = selectedRow?.original || {};
  const updatedColumns = newSetOfColumns.map((column) => ({
    ...column,
    initialValue: rest[column.accessorKey],
  }));


  const [globalValues, setGlobalValues] = useState([] as any);
  const [globalValues2, setGlobalValues2] = useState([selectedRow?.original] as any);
  const [error, setError] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState(null as null | string);
  const { budgetCTC, budgetApproved } = usePortalContext();
  const { campaignList, setPermanentCampaignList, activeCampaign } = useCampaignContext();
  

  const handleGlobalChange = (localObject: oneInput) => {
    selectedRow === undefined && setGlobalValues((prev: any) => ({ ...prev, ...localObject }));
    setError(false);
  };

  const closeModal = () => {
    setShowModal!(!showModal);
  };

  const saveTable = async () => {
    if (
      globalValues[CommunicationTaskKey] === undefined ||
      globalValues[CommunicationTaskKey] === null ||
      globalValues[CommunicationTaskKey].length === 0
    ) {
      // setError(true);
      setErrorMessage("Communication Task is required, it can't be empty");
      return;
    }

    let newCampaign = { ...activeCampaign } as ACdetails;

    if (newCampaign !== undefined) {
      if (
        globalValues['bdb786ee-1b4b-41da-ba45-0e30daf7f988'] !== undefined &&
        globalValues['bdb786ee-1b4b-41da-ba45-0e30daf7f988'] !== null &&
        globalValues['bdb786ee-1b4b-41da-ba45-0e30daf7f988'].length > 0 &&
        activeCampaign?.recommended_budget_ctc !== budgetCTC?.budget
      ) {
        newCampaign.recommended_budget_ctc = budgetCTC?.budget;
      }

      if (
        globalValues['09b438ee-226c-43b4-a90c-49e62a0541e1-jfsd'] !== undefined &&
        globalValues['09b438ee-226c-43b4-a90c-49e62a0541e1-jfsd'] !== null &&
        globalValues['09b438ee-226c-43b4-a90c-49e62a0541e1-jfsd'].length > 0 &&
        activeCampaign?.approved_budget !== budgetApproved?.budget
      ) {
        newCampaign.approved_budget = budgetApproved?.budget;
      }

      const newCampaignList = campaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];

      setPermanentCampaignList(newCampaignList);
    }

    if (
      globalValues === undefined ||
      globalValues === null ||
      globalValues.length === 0 ||
      (typeof globalValues === 'object' && Object.keys(globalValues).length === 0)
    ) {
      setError(true);
      return;
    }

    handleCreateUser({
      values: globalValues,
      table,
      exitCreatingMode: () => {},
      row: {} as MRT_Row<any>,
    });

    setShowModal!(!showModal);
  };

  const handleGlobalChange2 = (localObject: oneInput) => {
    setGlobalValues2((prev: any) => ({ ...prev, ...localObject }));
    setError(false);
  };

  const updateTable = () => {
  }

 

  return (
    <div>
      <Modal>
        <div className="containerModal">
          <HeadModul>
            <button type="button" className="x" onClick={() => closeModal()}>
              <IoMdClose className="closeIcon" />
            </button>
            <ModulContainer>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="grid">
                  {selectedRow?.original === undefined
                    ? newSetOfColumns.map((column) => (
                        <PlanModalInput
                          column={column}
                          handleGlobalChange={handleGlobalChange}
                          newSetOfColumns={newSetOfColumns}
                          newRow={newRow}
                          data={data}
                          columnTypes={columnTypes}
                          columnInputTypes={columnInputTypes}
                          key={column.accessorKey}
                          errorMessage={errorMessage}
                          setErrorMessage={setErrorMessage}
                          CommunicationTaskKey={CommunicationTaskKey}
                        />
                      ))
                    : updatedColumns.map((column) => (
                        <PlanModalInput
                          column={column}
                          handleGlobalChange={handleGlobalChange2}
                          newSetOfColumns={newSetOfColumns}
                          newRow={newRow}
                          data={data}
                          columnTypes={columnTypes}
                          columnInputTypes={columnInputTypes}
                          key={column.accessorKey}
                          errorMessage={errorMessage}
                          setErrorMessage={setErrorMessage}
                          CommunicationTaskKey={CommunicationTaskKey}
                        />
                      ))}
                </div>
              </form>
            </ModulContainer>
            <div>
              <button
                onClick={() => 
                  selectedRow === undefined ? saveTable() : updateTable()}
                className="saveButton"
                style={error ? { border: '1px solid red' } : {}}
              >
                Save
              </button>
              <button onClick={() => closeModal()} className="buttonCancel">
                Cancel
              </button>
            </div>
            <div>
              {error && <div className="errorInputs"> The fields cant be empty, please complete at least one </div>}
            </div>
          </HeadModul>
        </div>
      </Modal>
    </div>
  );
};

export default PlanModal;
