
import { InfoIcon } from './style/BriefInputStyle'
import Markdown from "react-markdown";
import { createRoot } from 'react-dom/client'
import remarkGfm from 'remark-gfm'

type Props = {
  info: string,
}


const Info3 = (props: Props) => {
  const { info } = props

  return (
    <InfoIcon >
      <div className='tooltip'>
        <div className='questionMark'>?</div>
        <div className='tooltiptext'>
          {info}
        </div>
      </div>
    </InfoIcon>
  )
}

export default Info3