import { useFetchPlanData } from './useFetchPlanData';
import { IActiveOrganization } from 'context/ClientContext';
import { ACdetails } from 'context/CampaignContext';
import { Environment, GetQueriesQuery, GetStoredQueryQuery, UserQuery } from 'API';
import Comments from 'components/Comments/Comments';
import { useEffect, useState } from 'react';

import UploadFile3 from '../Brief/UploadFile/2.2.UploadFile';
import { QueryInfo } from '../Brief/Brief';
import { getQueries, getStoredQuery } from 'graphql/queries';
import { API } from 'aws-amplify';
import { getFilesFromS3 } from '../Brief/UploadFile/getFilesfromS3';
import { useSearchParams } from 'react-router-dom';
import PlanItem from './PlanItem';
import NewPlanItem from './NewPlanItem';

export type Person = {
  [key: string]: {
    header: string;
    accessorKey: string;
    size: number;
    type: string;
  };
};

interface Props {
  getEnvironment: Environment;
  activeCampaign: ACdetails;
  activeOrganization: IActiveOrganization;
  tab: string;
  setApprovedBudget: (arg: number | null) => void;
}

const Plan = (props: Props) => {
  const { getEnvironment, activeCampaign, activeOrganization, tab, setApprovedBudget } = props;
  // const [toggleComment, setToggleComment] = useState(false);

  const [searchParams] = useSearchParams();

  const { newColumns, newRow2, isLoading, isError, columnTypes, columnInputTypes, tabIsLoading } = useFetchPlanData(
    getEnvironment,
    activeCampaign,
    activeOrganization,
    tab,
  );

  const [uploadedFiles, setUploadedFiles] = useState<QueryInfo[]>([]);
  const [editBrief, setEditBrief] = useState(false);

  useEffect(() => {
    async function fetchUploadedFiles(): Promise<QueryInfo[]> {
      try {
        const response = (await API.graphql({
          query: getQueries,
          variables: {
            campaign_id: activeCampaign?.campaignId?.toString(10),
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetQueriesQuery };

        if (response.data.getQueries) {
          const res = response.data.getQueries;

          const queries = res.filter((query) => query?.position?.includes('PlanUploadFile'));
          return queries.map((q) => {
            return { id: q?.query_id ?? '', position: q?.position ?? '', name: q?.query_id?.split('_')[1] ?? '' };
          });
        }
        return [] as QueryInfo[];
      } catch (e) {
        console.error('ERROR FETCHING QUARIES', e);
        return [] as QueryInfo[];
      }
    }

    const addToFileList = async () => {
      const queryInfos = await fetchUploadedFiles();

      setUploadedFiles(queryInfos);
    };

    // HERE IS CALLED THE FIRST TIME ADDTOFILELIST AND THAT WILL TRIGGER ANTHER FUNCTION THAT WILL UPDATE setUploadedFiles
    if (activeCampaign && searchParams.get('tab') === 'plan') {
      addToFileList();
    }
  }, [activeCampaign, activeOrganization, getEnvironment, searchParams]);

  const uploadNewFile = (id: string, position: string, file: File) => {
    setUploadedFiles((prev) => [...prev, { id: id, position: position, name: id.split('_')[1], file: file }]);
  };

  async function fetchStoredQueriesID(position: string): Promise<File> {
    try {
      const response = (await API.graphql({
        query: getStoredQuery,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          position: position,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetStoredQueryQuery };
      const responseID = response.data.getStoredQuery;

      const file = await getFilesFromS3(
        responseID as UserQuery,
        activeCampaign?.ClientCode as string,
        activeCampaign?.ClientCountry as string,
        getEnvironment,
      );

      if (file) {
        setUploadedFiles((prev) =>
          prev.map((e) => {
            if (e.id === responseID?.query_id) {
              return { ...e, file: file };
            } else {
              return e;
            }
          }),
        );
      }

      return file as File;
    } catch (e) {
      console.log(`ERROR WAS THIS: ${e}`);
      return {} as File;
    }
  }

  const openFile = async (queryInfo: QueryInfo) => {
    const file = queryInfo.file ?? (await fetchStoredQueriesID(queryInfo.position));
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };




  if (!activeCampaign?.authorized && (
    activeCampaign?.status !== 'PLANNING_DONE' &&
    activeCampaign?.status !== 'PLANNED' &&
    activeCampaign?.status !== 'CAMPAIGN_PARTLY_PUBLISHED' &&
    activeCampaign?.status !== 'CAMPAIGN_PUBLISHED'
  )) {
    return (
      <div className='planItemContainer'>

        <p>The plan is currently under development</p>
      </div>
    )
  }

  return (
    <>
      <>
        {isLoading === true || tabIsLoading ? (
          tab === 'plan' ? (
            <p>
              The data is loading
              <span className="loadingDot">.</span>
              <span className="loadingDot">.</span>
              <span className="loadingDot">.</span>
            </p>
          ) : (
            <></>
          )
        ) : (
          <div className="planItems">
            <div className="planItemContainer">
              <h1>COMMENTS</h1>
              <Comments
                header={false}
                toggleComment={true}
                componentID={'plan'}
                setToggleComment={(toggleComment: boolean) => console.log('toggle')}
              ></Comments>
            </div>

            <div className="planItemContainer">
              <h1>TOUCHPOINT ACTION PLAN</h1>
              {tab === 'plan' && (
                <>
                  {/* <PlanItem
                    newColumns={newColumns}
                    newRow={newRow2}
                    isError={isError}
                    getEnvironment={getEnvironment}
                    activeCampaign={activeCampaign}
                    activeOrganization={activeOrganization}
                    columnTypes={columnTypes}
                    columnInputTypes={columnInputTypes}
                    setApprovedBudget={setApprovedBudget}
                  /> */}

                  <div>
                    <NewPlanItem
                      newColumns={newColumns}
                      newRow={newRow2}
                      columnInputTypes={columnInputTypes}
                      columnTypes={columnTypes}
                      activeCampaign={activeCampaign}
                      getEnvironment={getEnvironment}
                      activeOrganization={activeOrganization}
                    />

                  </div>
                </>
              )}
            </div>
            <div className="planItemContainer">
              <h1>UPLOAD ADDITIONAL FILES</h1>
              <UploadFile3
                openFile={openFile}
                setUploadedFiles={setUploadedFiles}
                editBrief={editBrief}
                uploadedFiles={uploadedFiles}
                uploadNewFile={uploadNewFile}
                position="Plan"
              ></UploadFile3>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default Plan;
