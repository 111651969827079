import { useState } from 'react';
import PlanModal from './Modals/PlanModal';

interface Props {
  openToEdit: boolean;
  newRow: any[];
  newColumns: any[];
  data: any[];
  handleCreateUser: ({ values }: { values: any }) => Promise<void>;
  columnTypes: {
    [x: string]: string;
  }[];
  columnInputTypes: {
    [x: string]: string;
  }[];
  CommunicationTaskKey: string;
}

const CreateRow = (props: Props) => {
  const {
    openToEdit,
    newRow,
    newColumns,
    data,
    handleCreateUser,
    columnTypes,
    columnInputTypes,
    CommunicationTaskKey,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const testFuntion = () => {
    // table.setCreatingRow(createRow(table, {}));
    setShowModal(true);
    if (newRow.length > 0) {
      //   table.setCreatingRow(true);
    }
  };

  return (
    <div>
      {!openToEdit ? (
        <> </>
      ) : (
        <button className="buttonCallToAction" onClick={testFuntion}>
          Create New Row
        </button>
      )}

      {showModal && (
        <PlanModal
          newColumns={newColumns}
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
          newRow={newRow}
          handleCreateUser={handleCreateUser}
          columnTypes={columnTypes}
          columnInputTypes={columnInputTypes}
          CommunicationTaskKey={CommunicationTaskKey}
        />
      )}
    </div>
  );
};

export default CreateRow;
