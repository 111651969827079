import styled from 'styled-components';
import { HeadModul, ModulContainer } from './TableStyles';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { DarkButton } from 'components/style/Global';
import { IoMdClose } from 'react-icons/io';

const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex; 
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.4vw;
  }

  .filterTitle {
    margin: 0;
    padding: 0.4vw 0;
  }
`;

const DragItem = styled.div`
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  margin: 0 0 8px 0;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

type Props = {
    displayOrderModal: any,
    setDisplayOrderModal(toggle: any): any,
    currentQueryCOPY: any,
    data: any,
}

const OrderModal = (props: Props) => {
    const [items, setItems] = useState<any>();




    useEffect(() => {
        const QcalcMetrics = props.currentQueryCOPY.query.calculatedMetrics
        const QMetrics = props.currentQueryCOPY.query.metrics
        const QDimensions = props.currentQueryCOPY.query.detailedDimensions
        const combinedArray = QcalcMetrics.concat(...QMetrics, ...QDimensions)
        // eslint-disable-next-line array-callback-return
        const elements = props.currentQueryCOPY.query.orderBy.map((orderID: string) => {
            if (combinedArray.find((x: any) => x.id === orderID) !== undefined) {
                const pero = combinedArray.find((x: any) => x.id === orderID)
                return { id: orderID, content: pero.name }
            }
        })
        setItems(elements)
    }, [props.currentQueryCOPY])



    const onDragEnd = (result: any) => {
        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItems(newItems);
    };




    const setapply = () => {
        props.currentQueryCOPY.query.orderBy = items.map((element: any) => element.id);
        props.setDisplayOrderModal(!props.displayOrderModal)
    }



    return (
        <Modal onClick={() => props.setDisplayOrderModal(!props.displayOrderModal)}>
            <HeadModul onClick={e => e.stopPropagation()}  >
                <button type='button' className="x" onClick={() => props.setDisplayOrderModal(!props.displayOrderModal)}>
                    <IoMdClose className='closeIcon' />
                </button>
                <ModulContainer onClick={e => e.stopPropagation()} >
                    {items &&
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {items.map((item: any, index: any) => (
                                            <Draggable key={item.id} draggableId={item.id.toString()} index={index} >
                                                {(provided, snapshot) => (
                                                    <DragItem
                                                        onClick={() => console.log("KITA")}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}>{item.content}</DragItem>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>}


                </ModulContainer>


                <DarkButton className="scaleHover" type="button" onClick={setapply}>
                    OK
                </DarkButton>
            </HeadModul>
        </Modal>
    );
};

export default OrderModal;
