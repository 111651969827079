
import { useClientContext } from 'context/ClientContext'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import reactSelect from 'react-select';
import { useCampaignContext } from 'context/CampaignContext';
import { DashboardTypes } from 'API';
import { NavLink } from 'components/Aside/Aside';

interface BarProps {
  location: string
}

const BarContainer = styled.div<BarProps>`
   // position: relative;
    display: flex;
    border-radius: 20px 20px 0 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${(props) => (props.location === "home" ? '0' : '1.5rem 2.5rem')}; ;
    background-color: ${({ theme }) => theme.placeholderBackground};
   // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 8px;
    .log {
        font-size: 35px;
    }
    Select {
        color: ${({ theme }) => theme.primaryGray};
        option {
            color: ${({ theme }) => theme.primaryGray};
            background-color: ${({ theme }) => theme.placeholderBackground};
        }
    }
    .test {
        width: 2.5vw;
        height: 2.5vw;
    }
`
export const CustomSelect = styled(reactSelect)`
  position: relative;
  display: block;
  width: 300px;
  margin-bottom: 4px;       
  font-size: 1rem;
 // padding: 4px;
  font-family: 'FontRegular', sans-serif;
  font-weight: normal;
  color: ${({ theme }) => theme.primaryGray};
  background: ${({ theme }) => theme.placeholderBackground};
  .react-select__menu {
    background-color: ${({ theme }) => theme.placeholderBackground} !important;
    font-size: 12px;
  }
        /* width */
        .react-select__menu-list::-webkit-scrollbar {
      width: 10px;
    }
        /* Track */
        .react-select__menu-list::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.border};
      border-radius: 10px;
    }
    /* Handle */
    .react-select__menu-list::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.scrollThumb};
      border-radius: 10px;
    }
  .react-select__control {
    background-color: ${({ theme }) => theme.placeholderBackground} !important;
    color: ${({ theme }) => theme.secondaryGray} !important; 
  }
   .react-select__single-value  {
    color: ${({ theme }) => theme.secondaryGray} !important; 
  }
  .react-select__value-container {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .react-select__indicator-separator {
    width: 0 ;
  }
  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    border-color: ${({ theme }) => theme.secondaryGray};
  }
  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    border-color: ${({ theme }) => theme.secondaryGray};
  }
  .react-select__control:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    border-color: ${({ theme }) => theme.secondaryGray}
  }
  .react-select__option--is-selected {
  background-color:${({ theme }) => theme.primaryGray} ;
  }
  .react-select__input-container {
    color: ${({ theme }) => theme.secondaryGray} !important; 
  }
`;

type Props = {
  setSearchParams(params: any): any,
  searchParams: any
  location: string

}

const Bar = (props: Props) => {

  const { setActiveOrganization, activeOrganization, organizationList } = useClientContext();
  const { setDashType, dashType } = useCampaignContext()
  useEffect(() => {
    if (activeOrganization) {
      props.setSearchParams({ client: activeOrganization.OrganizationId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrganization])


  const clientsDropdown = organizationList?.map(e => {
    return {
      label: e?.Organization,
      value: e?.OrganizationId,
    };
  });

  const LogoImage = ({ theme }: { theme: string }) => (
    <img
      style={{ width: '70%' }}
      className="bar__clientLogo"
      src={require(`../../assets/${theme}${REACT_APP_LOGO}`)}
      alt="Logo"
    />
  );

  const handleSelection = (value: any) => {
    if (activeOrganization)
      props.setSearchParams({ client: activeOrganization.OrganizationId })
    const findOrg = organizationList.find(element => element?.OrganizationId === value)
    setActiveOrganization(findOrg)
  }
  const redirectHome = () => {
    setDashType(DashboardTypes.HOME);
    setActiveOrganization(undefined);
  };
  const { REACT_APP_LOGO } = process.env;


  return (
    <BarContainer location={props.location}>
      <div className="logodiv" onClick={() => redirectHome()}>
        <NavLink to="/">
          <div className="logo">

            <LogoImage theme="light" />

          </div>
        </NavLink>
      </div>

      <CustomSelect
        onChange={(sel: any) => handleSelection(sel.value)}
        options={clientsDropdown}
        defaultValue={clientsDropdown.find((e: any) => e.value === activeOrganization?.OrganizationId)}
        value={clientsDropdown.find((e: any) => e.value === activeOrganization?.OrganizationId)}
        className="react-select-container"
        classNamePrefix="react-select"
      ></CustomSelect>
    </BarContainer>
  )
}

export default Bar