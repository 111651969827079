import { ModulContainer } from 'components/Main/table/TableStyles';
import { FileUploader } from 'react-drag-drop-files';
import { fileTypes, smallDragAndDropContainer } from 'components/Main/Gallery/ARContainer';
import { QueryInfo } from '../Brief';
import FileList from './3.3.FilesList';

interface Props {
  selectedFiles: QueryInfo[];
  removeAllFiles: () => void;
  colorsForIcon: { [key: string]: string };
  openFile: (file: QueryInfo) => void;
  removeFile: (queryInfo: QueryInfo) => void;
  addFile: (files: FileList) => void;
  editBrief: boolean;
}

const SelectedFilesList3 = (props: Props) => {
  const { selectedFiles, removeAllFiles, colorsForIcon, openFile, removeFile, addFile, editBrief } = props;

  return (
    <>
      <div className="uploadedFilesRightContianer">
        <div className="titleRightContainer">
          {/* <label>Pending files</label> */}
          {/* {selectedFiles.length > 0 && <div className="button" onClick={removeAllFiles}>Remove all</div>} */}
        </div>



        <>
          <ModulContainer onClick={(e) => e.stopPropagation()} className="modal-content-upload">
            <FileUploader
              classes="drop_area"
              handleChange={(files: FileList) => addFile(files)}
              name="file"
              multiple
              types={fileTypes}
              children={smallDragAndDropContainer}
            />
          </ModulContainer>
        </>
        {selectedFiles.length > 0 && (
          <>
            <FileList
              files={selectedFiles}
              onFileOpen={openFile}
              onFileRemove={removeFile}
              colorsForIcon={colorsForIcon}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SelectedFilesList3;
