import { VscEdit } from 'react-icons/vsc';
import { KPIholder } from './KPIContainer';
import { TxtContainer } from 'components/Main/Main.styles';
import { IconButton } from 'components/style/Global';
import { useCampaignContext } from 'context/CampaignContext';

type Props = {
  fee: boolean;
  setFee: (props: any) => void;
  feeValue: string | number | undefined;
  setFeeValue: (props: any) => void;
  handleSave: (type: string) => void;
  title: string;
  error: string;
  handleCancel: (type: string) => void;
};

const KPIFee = (props: Props) => {
  const { fee, setFee, feeValue, setFeeValue, handleSave, title, error, handleCancel } = props;
  const { activeCampaign } = useCampaignContext()
  return (
    <KPIholder className="KPIBox">
      <TxtContainer>
        <div className="headerEdit">
          <h1 style={{ fontSize: '0.6vw' }}>{title}</h1>
          {/* {fee ? (
            <div onClick={() => handleSave(title)}> save</div>
          ) : (
            <div onClick={() => setFee(!fee)}>
              <VscEdit />
              Edit
            </div>
          )} */}
        </div>
        <div className="valueFlex">
          {fee ? (
            <input
              type="number"
              /*  style={{ width: '110px', height: '25px' }} */
              value={feeValue || ''}
              onChange={(e: any) => setFeeValue(e.target.value)}
            />
          ) : (
            <div onClick={() => activeCampaign?.authorized && setFee(!fee)}>
              <h4 className="tooltip" style={{ cursor: "pointer" }}>
                {Intl.NumberFormat('en-DK', { notation: 'compact', maximumFractionDigits: 1 }).format(Number(feeValue))}
                <span className="tooltiptext">
                  {Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(Number(feeValue))}
                </span>
              </h4>
              <div>{error.length > 0 ? error : ''}</div>
            </div>
          )}
        </div>
        <div>
          {fee && (
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <IconButton style={{ margin: '0px', padding: '0px' }} type="button" onClick={() => handleSave(title)}>
                Save
              </IconButton>
              <IconButton style={{ margin: '0px', padding: '0px' }} type="button" onClick={() => handleCancel(title)}>
                Cancel
              </IconButton>
            </div>
          )}
        </div>
      </TxtContainer>
    </KPIholder >
  );
};

export default KPIFee;
