import styled from 'styled-components';
import omd from '../../assets/newAssets/omd.jpg';
import phd from '../../assets/newAssets/phd.jpg';
import hs from '../../assets/newAssets/hs.jpg';
import { Link } from 'react-router-dom';

type DashboardProps = {
  h: string;
};

const { REACT_APP_AGENCY } = process.env;

export const Dashboard = styled.div<DashboardProps>`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .wrapper24 {
    display: flex;
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
  }
`;

export const ActivitiesContainer = styled.div`
  background: url(${REACT_APP_AGENCY === 'omd' ? omd : REACT_APP_AGENCY === 'phd' ? phd : hs});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 26px;
  padding: 5em 5.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  .sectionHeader {
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontMedium';
    font-weight: inherit;
    font-size: 1.3vw;
    margin-bottom: 2vw;
  }

  .container {
    background-color: ${({ theme }) => theme.placeholderBackground};
    border-radius: 26px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2vw;

  }
`;

interface ContainerProps {
  accessible: any;
}

export const ActivityContainer = styled.div<ContainerProps>`
  /*  opacity: ${(props) => (props.accessible ? '1' : '0.3')}; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3vw;
  pointer-events: ${(props) => (props.accessible ? 'all' : 'none')};
  .svg {
    height: 2.7vw;
    padding: 0.1vw;
    fill: ${({ theme }) => theme.c400};
  }

  .textAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .textContainer {
    margin-left: 1.5vw;
    margin-right: 2vw;

    h1 {
      text-align: left;
      font-family: 'FontBold';
      font-size: 0.9vw;
      font-weight: inherit;
      margin-bottom: 0.2vw;
      
      
    }
    h2 {
      text-align: left;
      font-family: 'FontRegular';
      font-size: 0.7vw;
      font-weight: inherit;
    }
  }
  .arrowContainer {
    align-self: flex-end;
  }
  .arrowBackground {
    width: fit-content;
    height: fit-content;
    padding: 0.4vw;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.c50};
    fill: ${({ theme }) => theme.c500};
    height: 1.7vw;
  }
`;

export const NavLink = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:visited,
  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
    color: #000000;
  }
  h3 {
    font-size: 0.75vw;
    font-weight: 500;
    text-align: center;
  }
`;

export const NavAnchor = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:visited,
  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
    color: #000000;
  }
  h3 {
    font-size: 0.75vw;
    font-weight: 500;
    text-align: center;
  }
`;
interface Props {
  prop: boolean;
}

export const InsightsContainer = styled.div`
  padding: 5em 5.5rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;

  .sectionHeader {
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontMedium';
    font-weight: inherit;
    font-size: 1.3vw;
    margin-bottom: 2vw;
  }
  position: relative;
  .learnMore {
    cursor: pointer;
    background-color: ${({ theme }) => theme.c500};
    font-family: 'FontMedium';
    padding: 0.6vw 1vw;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      font-weight: inherit;
      font-size: 1vw;
      color: ${({ theme }) => theme.placeholderBackground};
    }
    .arrowBackground {
      width: fit-content;
      height: fit-content;
      margin-left: 1vw;
      fill: ${({ theme }) => theme.placeholderBackground};
    }
  }

  .container {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2vw;
  }
`;

export const InsightContainer = styled.a<Props>`
  width: 100%;
  margin-top: 1vw;
  padding: 1vw;
  border-radius: ${(props) => (props.prop ? '8px' : '8px 8px 0 0')};
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-decoration: none;
  background-color: ${({ theme }) => theme.placeholderBackground};

  h1 {
    text-align: left;
    font-family: 'FontBold';
    font-size: 0.9vw;
    font-weight: inherit;
    margin-bottom: 0.2vw;
  }
  h2 {
    text-align: left;
    font-family: 'FontRegular';
    font-size: 0.7vw;
    font-weight: inherit;
  }

  .arrowContainer {
    align-self: flex-end;
  }
  .arrowBackground {
    width: fit-content;
    height: fit-content;
    padding: 0.4vw;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.c50};
    fill: ${({ theme }) => theme.c500};
    height: 1.7vw;
  }
`;
