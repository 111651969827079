import APIContext from 'context/APIContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { useContext, useEffect, useState } from 'react';
import Plan from './Plan';
import { PlanContainer } from './PlanStyle';
import { usePortalContext } from 'context/PortalContext';

import Comments from 'components/Comments/Comments';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { VscComment } from 'react-icons/vsc';
import { IconButton } from 'components/style/Global';

interface Props {
  tab: string;
  setApprovedBudget: (arg: number | null) => void;
}

const PlanComponent = (props: Props) => {
  const { tab, setApprovedBudget } = props;
  const { getEnvironment } = useContext(APIContext);
  const { activeCampaign } = useCampaignContext();
  const { activeOrganization } = useClientContext();
  const [toggleComment, setToggleComment] = useState(false);
  const { statements } = useContext(ClientStatementsContext)

  const { setBudgetCTC, setBudgetApproved } = usePortalContext()


  useEffect(() => {

    if (activeCampaign?.recommended_budget_ctc) {
      setBudgetCTC({ budget: activeCampaign?.recommended_budget_ctc })
    }

    if (activeCampaign?.approved_budget) {
      setBudgetApproved({ budget: activeCampaign?.approved_budget })
    } else {
      setBudgetApproved(null)
    }
  }, [activeCampaign])



  return (
    <PlanContainer tab={tab}>
      {getEnvironment === undefined && activeCampaign === undefined && activeOrganization === undefined ? (
        tab === "plan" ? <div>loading...</div> : <></>
      ) : (
        <div>
          {getEnvironment !== undefined && activeCampaign !== undefined && activeOrganization !== undefined && (
            <Plan
              key={activeCampaign?.campaignId}
              getEnvironment={getEnvironment}
              activeCampaign={activeCampaign}
              activeOrganization={activeOrganization}
              tab={tab}
              setApprovedBudget={setApprovedBudget}
            ></Plan>
          )}
        </div>
      )}
    </PlanContainer>
  );
};

export default PlanComponent;
