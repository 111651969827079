import React from 'react';



const UploadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="197.291" height="156.074" viewBox="0 0 197.291 156.074">
      <g id="upload-image-ui_1_" data-name="upload-image-ui (1)" transform="translate(-187 -278.045)">
        <path id="Path_777" data-name="Path 777" d="M219.874,426.839s-10.388-22.057,6.9-35.7-14.422-27.49-2.977-44.433,50.346-3.209,49.366-34.6,31.711-39.884,42.5-30.4,12.271,55.692,43.243,50.731,60.063,60.524,35.215,94.312Z" transform="translate(-21.653)" fill="#ffbe55" opacity="0.1" />
        <path id="Path_778" data-name="Path 778" d="M291.53,353H437.487v88.808H291.53Z" transform="translate(-76.006 -54.501)" fill="#2a94f4" opacity="0.33" />
        <path id="Path_779" data-name="Path 779" d="M318.66,377.37H449.808v75.505H318.66Z" transform="translate(-95.733 -72.221)" fill="#f7f7f7" />
        <path id="Path_780" data-name="Path 780" d="M318.66,377.37H449.808V378.6H318.66Z" transform="translate(-95.733 -72.221)" fill="#2a94f4" opacity="0.61" />
        <path id="Path_781" data-name="Path 781" d="M251.1,762.91s-3.354,3.861-3.97,4.475a4.2,4.2,0,0,1-1.921.887,2.764,2.764,0,0,0-.753.48.774.774,0,0,0-.281.641c.079,4.344,3.861,7.392,4.063,7.136.363-.458.087-1.733-.636-2.571a3.288,3.288,0,0,1-.579-3.1,5.181,5.181,0,0,1,1.373-2.229l2.917-2.729-.213-2.988Zm34.282,5.49,2.669,4.459a6.644,6.644,0,0,1,.846,4.541,1.451,1.451,0,0,0,1.394,1.7c2.873.071,8.429.175,8.59-.087.816-1.332-2.7-1.929-5.46-2.865-1.861-.63-4.153-5.671-5.6-9.638l-2.178,1.378A.587.587,0,0,0,285.387,768.4Z" transform="translate(-41.576 -352.556)" fill="#fa968c" />
        <path id="Path_782" data-name="Path 782" d="M310.969,657.927a18.27,18.27,0,0,0,3.5-.491.727.727,0,0,1,.382.027c1.288.456,2.068,1.086,1.635,1.793-.175.284-6.713.136-9.264.068a.87.87,0,0,1-.832-1.026l.166-.958a17.866,17.866,0,0,0,4.418.587Zm-17.53-59.632c-2.95-.589-6.473,1.826-7.231,5.621a117.4,117.4,0,0,0-.988,16.94,7.232,7.232,0,0,1-3.362,6.317,6.212,6.212,0,0,0-.961.709c-1.454,1.329-8.225,9.813-12.468,15.57a3.9,3.9,0,0,0,.5,2.737c5.43-4.254,14.637-12.266,16.187-13.679a33.083,33.083,0,0,0,2.6-2.609c3.335-2.235,8.751-19.3,9.455-22.826.756-3.8-.791-8.189-3.741-8.781Z" transform="translate(-59.174 -232.796)" fill="#0b4870" />
        <ellipse id="Ellipse_107" data-name="Ellipse 107" cx="4.587" cy="5.919" rx="4.587" ry="5.919" transform="translate(227.836 334.966)" fill="#0b4870" />
        <path id="Path_783" data-name="Path 783" d="M295.474,484.159a6.371,6.371,0,0,0-12.345-2.066,2.254,2.254,0,0,1-2.15,1.454,5.636,5.636,0,0,0-1.212.115,5.666,5.666,0,0,0-2.06,10.241,3.019,3.019,0,0,1,1.223,2.118,7.415,7.415,0,1,0,12.885-5.957,6.368,6.368,0,0,0,3.659-5.905Zm1.1-8.47s2.581,1.624,2.268,3.812c0,0,1.378-.977,1.692-4.339C300.749,472.832,296.574,475.689,296.574,475.689Z" transform="translate(-64.151 -142.663)" fill="#0b4870" />
        <path id="Path_784" data-name="Path 784" d="M362.591,512.438a17.1,17.1,0,0,0,.216,3.275,12.23,12.23,0,0,0,.865,3.056,5.679,5.679,0,0,0,.7,1.2,1.834,1.834,0,0,0,.69.584.605.605,0,0,0,.276.046,1.135,1.135,0,0,0,.4-.115,3.764,3.764,0,0,0,1.04-.824,12.861,12.861,0,0,0,1.853-2.751,34.849,34.849,0,0,0,1.471-3.217c.448-1.108.849-2.24,1.233-3.384s.745-2.3,1.083-3.466v0a.548.548,0,0,1,1.067.243,65.339,65.339,0,0,1-1.536,7.237,31.453,31.453,0,0,1-1.2,3.575,15.5,15.5,0,0,1-1.9,3.493,6.406,6.406,0,0,1-1.681,1.585,4.168,4.168,0,0,1-1.3.527,3.632,3.632,0,0,1-1.534-.005A4.129,4.129,0,0,1,363,522.93a5.5,5.5,0,0,1-.988-.816,8.49,8.49,0,0,1-1.318-1.831,15.6,15.6,0,0,1-1.424-3.856,20.379,20.379,0,0,1-.5-3.984v-.008a1.911,1.911,0,1,1,3.82-.109A.508.508,0,0,1,362.591,512.438Z" transform="translate(-124.896 -166.077)" fill="#fab9af" />
        <path id="Path_785" data-name="Path 785" d="M326.411,531.866c1.034-3.114,3.111-9.7,2.933-11.859a2.656,2.656,0,0,0-2.652-2.437h-8.986a2.748,2.748,0,0,0-2.751,2.672c-.076,2.6,1.124,8.525,1.8,11.625Zm-1.771,14.053c1.034,3.73,9.433,30.254,6.535,31.056S312.5,551.882,312.3,548.015c-.191-3.667,1.935-7.641,4.833-8.443S323.609,542.189,324.641,545.919Z" transform="translate(-91.098 -174.164)" fill="#fab9af" />
        <path id="Path_786" data-name="Path 786" d="M337.639,647.507a.47.47,0,0,0,.076-.458c-2.246-6.219-5.261-14.094-5.905-15.581-.074-.169-.15-.336-.232-.5-1.1-7.864-6.142-23.882-6.928-26.72-1.034-3.73-4.609-7.147-7.51-6.344s-5.024,4.775-4.833,8.443c.126,2.42,6.353,13.469,11.706,21.2A13.689,13.689,0,0,1,326.192,633a17.309,17.309,0,0,0,1.045,3.676c.633,1.465,4.344,7.054,7.662,11.794a.466.466,0,0,0,.423.2A3.451,3.451,0,0,0,337.639,647.507Z" transform="translate(-91.106 -232.492)" fill="#0b4870" />
        <path id="Path_787" data-name="Path 787" d="M318.222,535.812a8.543,8.543,0,0,0,.409-4.074,101.048,101.048,0,0,1-1.173-11.728,2.512,2.512,0,0,1,2.516-2.44h1.332a3.582,3.582,0,0,0,6.508,0h1.25a8.068,8.068,0,0,0,1.468,8.991c-.434,1.536-.8,3.083-1.072,4.336a8.473,8.473,0,0,0,.461,5.07c1.149,2.756,2.057,7.177,1.787,8.926a19.466,19.466,0,0,1-15.988-2.063l2.5-7.018Z" transform="translate(-93.595 -174.164)" fill="#ffbe55" />
        <path id="Path_788" data-name="Path 788" d="M342.6,506.817l-4.074-.428,1.288-4.459,2.636.325Z" transform="translate(-110.181 -162.791)" fill="#fab9af" />
        <path id="Path_789" data-name="Path 789" d="M344.81,502.247l-2.636-.327-.4,1.392c.589.666,1.348,2.1,2.309,2.1a1.53,1.53,0,0,0,.832-.243Z" transform="translate(-112.537 -162.784)" fill="#fa968c" />
        <path id="Path_790" data-name="Path 790" d="M341.172,488.123a5.058,5.058,0,0,1-3.2-1.613,4.041,4.041,0,0,1-1.334-2.622V479.53h6.71a12.374,12.374,0,0,1,.014,4.612C343,485.863,341.876,488.126,341.172,488.123Z" transform="translate(-108.807 -146.504)" fill="#fab9af" />
        <path id="Path_791" data-name="Path 791" d="M335.585,488.379c0,.851-.407,1.539-.909,1.539s-1.056-1.113-1.056-1.965a1,1,0,0,1,1.056-1.113C335.175,486.837,335.585,487.528,335.585,488.379Z" transform="translate(-106.611 -151.819)" fill="#fab9af" />
        <path id="Path_792" data-name="Path 792" d="M333.62,472.925a2.463,2.463,0,0,1,2.464-2.464h2.265a3.7,3.7,0,0,0,1.828-.483,1.8,1.8,0,0,1,2.355.437c.589.8.843,1.869-.649,2.86-.742.494-1.828-.052-2.551.183-.044,0-.085-.008-.131-.008h-2a1.263,1.263,0,0,0-1.244,1.261.815.815,0,0,1-.813.791h-.09l-.319-1.023-1.113,1.5v-3.056Z" transform="translate(-106.611 -139.372)" fill="#0b4870" />
        <path id="Path_793" data-name="Path 793" d="M406.652,488.211s.469,2.317-.191,2.693-1.171.51-1.757-.428a8,8,0,0,1-.966-3.49,6.166,6.166,0,0,1,.816-2.781c.682-.48.54,1.3.516,2.172s.03,1.85.45,1.746.347-1.771.789-2.052S406.652,488.211,406.652,488.211Z" transform="translate(-157.595 -149.845)" fill="#fab9af" />
        <path id="Path_794" data-name="Path 794" d="M336.833,474.342c-.224,1,.106,1.539-3.157,3.094s-4.3.89-5.316,2.562c0,0,.445-2.772,2.249-3.722s4.928-1.572,5.932-3.777A4.289,4.289,0,0,1,336.833,474.342Z" transform="translate(-102.786 -141.392)" fill="#0b4870" />
        <path id="Path_795" data-name="Path 795" d="M357.077,478.061a6.3,6.3,0,0,0,3.053,4.964,10.981,10.981,0,0,1-1.2-4.191C359.014,477.174,356.883,476.915,357.077,478.061Z" transform="translate(-123.658 -144.926)" fill="#0b4870" />
        <path id="Path_796" data-name="Path 796" d="M508.036,413h-.546v-1.364h-1.364v-.546h1.91Zm0,37.45h-.546v-3.122h.546Zm0-6.241h-.546v-3.122h.546Zm0-6.241h-.546v-3.122h.546Zm0-6.243h-.546V428.6h.546Zm0-6.241h-.546V422.36h.546Zm0-6.243h-.546v-3.122h.546Zm0,36.241h-1.91v-.546h1.364v-1.364h.546Zm-5.185,0h-3.274v-.546h3.274Zm-6.549,0h-3.275v-.546H496.3Zm-6.549,0h-3.275v-.546h3.275Zm-6.549,0H479.93v-.546H483.2Zm-6.549,0h-3.275v-.546h3.275Zm-6.549,0h-3.275v-.546h3.275Zm-6.549,0h-3.275v-.546h3.275Zm-6.549,0h-3.274v-.546h3.274Zm-6.549,0h-3.274v-.546h3.274Zm-6.549,0H442v-1.91h.546v1.364h1.364Zm-1.364-5.037H442v-3.127h.546Zm0-6.254H442V441.06h.546Zm0-6.257H442V434.8h.546Zm0-6.254H442v-3.127h.546Zm0-6.254H442v-3.127h.546Zm0-6.257H442v-3.127h.546Zm0-6.254H442V411l1.91,0v.546l-1.364,0Zm60.306-1.28-3.274-.005v-.546l3.274.005Zm-6.549-.008-3.275-.005v-.546l3.275.005Zm-6.549-.008-3.275-.005v-.546l3.275.005ZM483.2,411.6l-3.275-.005v-.546l3.275.005Zm-6.549-.008-3.275-.005v-.546l3.275.005Zm-6.549-.011-3.275-.005v-.546l3.275.005Zm-6.549-.008-3.275-.005v-.546l3.275.005Zm-6.549-.008-3.274-.005v-.546l3.274.005Zm-6.549-.011-3.274-.005v-.546l3.274.005Z" transform="translate(-185.416 -96.674)" fill="#d3d3d3" />
        <path id="Path_797" data-name="Path 797" d="M238.935,785.4a.6.6,0,0,1,.248-.737c.207-.123.764-.467,1.182-.723a.286.286,0,0,1,.434.218,6.874,6.874,0,0,0,3.4,5.613,2.435,2.435,0,0,1,.322,2.289,2.965,2.965,0,0,1-1.826-1.149C239.134,786.826,238.935,785.4,238.935,785.4Z" transform="translate(-37.732 -367.813)" fill="#0b4870" />
        <path id="Path_798" data-name="Path 798" d="M408,394h69.038v45.3H408Z" transform="translate(-160.694 -84.313)" fill="#ffbe55" />
        <path id="Path_799" data-name="Path 799" d="M416,404h64.4v32.745H416Z" transform="translate(-166.511 -91.584)" fill="#f7f7f7" opacity="0.78" />
        <path id="Path_800" data-name="Path 800" d="M508.739,468.369c0,5.728-5.187,4.912-10.915,4.912S488,474.1,488,468.369a10.369,10.369,0,1,1,20.739,0Z" transform="translate(-218.864 -130.849)" fill="#ffbe55" />
        <path id="Path_801" data-name="Path 801" d="M416,486.963,432.373,473l14.463,13.917Z" transform="translate(-166.511 -141.756)" fill="#ffbe55" />
        <path id="Path_802" data-name="Path 802" d="M486.74,468.785,510.685,448l21.151,20.717Z" transform="translate(-217.948 -123.578)" fill="#ffbe55" />
        <path id="Path_803" data-name="Path 803" d="M416,524h64.342v6.276H416Z" transform="translate(-166.511 -178.839)" fill="#0e538c" />
        <path id="Path_804" data-name="Path 804" d="M443.274,555.553s-2.333-.382-2.685.292-.467,1.19.5,1.738a8.014,8.014,0,0,0,3.526.832,6.131,6.131,0,0,0,2.748-.922c.456-.7-1.323-.491-2.191-.431s-1.85.041-1.76-.385,1.757-.415,2.019-.868S443.274,555.553,443.274,555.553Z" transform="translate(-184.204 -201.703)" fill="#fab9af" />
        <path id="Path_805" data-name="Path 805" d="M325.312,521.972l-.005-.033s0,.005,0,.008l.005.019.019.071.049.166c.041.12.085.243.139.371a5.831,5.831,0,0,0,.374.767,8.734,8.734,0,0,0,1.062,1.457,12.651,12.651,0,0,0,3,2.4,20.655,20.655,0,0,0,3.714,1.684,34.984,34.984,0,0,0,4.079,1.086,55.325,55.325,0,0,0,8.609.914l1.094.038,1.1.005,1.1,0c.366,0,.734-.016,1.1-.019.734-.019,1.471-.011,2.208-.055s1.474-.065,2.21-.093h.014a.548.548,0,0,1,.123,1.086c-1.465.276-2.939.532-4.423.734-.739.12-1.487.186-2.235.265l-1.122.115-1.127.082-1.127.082-1.132.041c-.379.011-.756.027-1.135.03l-1.138-.008a43.814,43.814,0,0,1-4.576-.276,33.626,33.626,0,0,1-4.584-.865,22.607,22.607,0,0,1-4.478-1.722,15.5,15.5,0,0,1-4.03-2.972,12.173,12.173,0,0,1-1.591-2.1,9.637,9.637,0,0,1-.619-1.22c-.09-.213-.172-.439-.248-.669-.035-.123-.074-.237-.106-.366a4.552,4.552,0,0,1-.1-.475,1.909,1.909,0,0,1,3.777-.557Z" transform="translate(-97.809 -176.178)" fill="#fab9af" />
        <path id="Path_806" data-name="Path 806" d="M502.442,603.188H447.594a5.594,5.594,0,0,1,0-11.188h54.848a5.594,5.594,0,0,1,0,11.188Z" transform="translate(-185.416 -228.283)" fill="#ffbe55" />
        <path id="Path_807" data-name="Path 807" d="M717.881,351.412c-2.581,0-5.163.065-7.742-.022a7.939,7.939,0,0,1-6.8-4.069,7.684,7.684,0,0,1-1.072-5.193,8.182,8.182,0,0,1,6.514-6.964,5.268,5.268,0,0,1,1.558-.125c.469.038.622-.123.6-.6a8.145,8.145,0,0,1,7.3-8.361,7.95,7.95,0,0,1,7.774,3.7c.2.286.308.308.617.1a7.79,7.79,0,0,1,11.51,3.6c.068.177.156.347.21.524.322,1.018.322,1.018,1.345,1.031a8.238,8.238,0,0,1,6.977,4.906c.1.251.177.51.265.767a.575.575,0,0,0-.491.418A7.918,7.918,0,0,1,744.9,343.3a18.273,18.273,0,0,1-6.664,4.322,27.554,27.554,0,0,1-3.509,1.105q-2.329.6-4.683,1.059-2.456-2.435-4.909-4.874c-.216-.216-.355-.355-.644-.06-2.118,2.153-4.251,4.287-6.383,6.426Zm4.145-23.629c-.246-.246-.445-.153-.622-.183a7.636,7.636,0,0,0-7.567,2.865,7.22,7.22,0,0,0-1.233,2.153,6.647,6.647,0,0,0-.409,2.955c.041.426-.136.609-.578.6a7.493,7.493,0,0,0-5.378,2.115,7.787,7.787,0,0,0-2.082,3.157,7.356,7.356,0,0,0-.265,4.071,4.037,4.037,0,0,0,.72,1.94c.2-.109.145-.273.145-.409a19.707,19.707,0,0,1,.12-2.5,7.3,7.3,0,0,1,3.206-5.482,8.011,8.011,0,0,1,4.759-1.247c.554.027.761-.109.75-.718a9.431,9.431,0,0,1,2-6.3,7.128,7.128,0,0,1,3.755-2.45C720.2,328.116,721.068,327.947,722.026,327.784Z" transform="translate(-374.604 -34.892)" fill="#2a94f4" />
        <path id="Path_808" data-name="Path 808" d="M805.928,390.512l-.234-.12c-.472-.5-.942-1-1.414-1.5q2.358-.454,4.682-1.059a27.548,27.548,0,0,0,3.509-1.105,18.229,18.229,0,0,0,6.664-4.322,7.96,7.96,0,0,0,1.553-2.178c.1-.226.226-.4.491-.418a7.96,7.96,0,0,1-1.476,7.717,7.726,7.726,0,0,1-4.505,2.767,8.837,8.837,0,0,1-1.793.21C810.916,390.526,808.422,390.512,805.928,390.512Z" transform="translate(-448.838 -73.995)" fill="#2a94f4" />
        <path id="Path_809" data-name="Path 809" d="M802.19,425.6c0-.082.033-.112.112-.082C802.264,425.545,802.228,425.573,802.19,425.6Zm3.869-.142a.109.109,0,0,1,.044.022c.008.005.005.022.008.033-.016-.019-.03-.041-.046-.06C806.065,425.453,806.059,425.455,806.059,425.455Z" transform="translate(-447.318 -107.181)" fill="#f7f7f7" />
        <path id="Path_810" data-name="Path 810" d="M755.228,418.7c.076-.044.153-.087.232-.128.164.191.387.12.589.12q6.083.008,12.168,0c.2,0,.423.068.592-.112l.235.12,1.651,1.757.005-.005-3.763.065c-.079-.033-.115,0-.112.082-.016.546-.036,1.092-.052,1.635-.4.387-.328.941-.453,1.411a12.045,12.045,0,0,1-3.146,5.586c-.477.472-1.119.718-1.525,1.255-1.258.011-2.516.011-3.771.038-.5.011-.39-.319-.39-.595,0-2.914-.016-5.831.011-8.746.005-.554-.134-.72-.693-.7-1.042.046-2.088.014-3.255.014C754.161,419.839,754.693,419.268,755.228,418.7Z" transform="translate(-411.951 -102.179)" fill="#ffbe55" />
        <path id="Path_811" data-name="Path 811" d="M783.21,440.242c.406-.538,1.048-.783,1.525-1.255a12.036,12.036,0,0,0,3.146-5.586c.125-.469.057-1.023.453-1.411v7.927c-.134.349-.513.158-.729.327Z" transform="translate(-433.518 -111.937)" fill="#ffbe55" />
        <path id="Path_812" data-name="Path 812" d="M771.128,412.907c.216-.169.595.022.729-.327C771.873,413.27,771.392,412.847,771.128,412.907Zm1.348-13.412c.472.5.941,1,1.414,1.5-.169.18-.393.112-.592.112q-6.082,0-12.168,0c-.2,0-.423.071-.589-.12,2.128-2.139,4.265-4.273,6.383-6.426.289-.295.428-.156.644.06Q770.018,397.065,772.476,399.5Z" transform="translate(-417.034 -84.599)" fill="#ffbe55" />
        <path id="Path_813" data-name="Path 813" d="M726.108,331.573c-.958.164-1.823.333-2.682.565a7.144,7.144,0,0,0-3.755,2.45,9.446,9.446,0,0,0-2,6.3c.011.608-.2.742-.75.718a8.011,8.011,0,0,0-4.759,1.247,7.3,7.3,0,0,0-3.206,5.482,19.7,19.7,0,0,0-.12,2.5c0,.134.06.3-.145.409a4.026,4.026,0,0,1-.72-1.94,7.357,7.357,0,0,1,.265-4.071,7.806,7.806,0,0,1,2.082-3.157,7.493,7.493,0,0,1,5.378-2.115c.442.008.62-.175.578-.6a6.685,6.685,0,0,1,.409-2.955,7.219,7.219,0,0,1,1.233-2.153,7.628,7.628,0,0,1,7.567-2.865C725.663,331.42,725.862,331.327,726.108,331.573Z" transform="translate(-378.686 -38.682)" fill="#2a94f4" />
        <path id="Path_814" data-name="Path 814" d="M314.98,610.275H265.043a1.637,1.637,0,0,1,0-3.275H314.98a1.637,1.637,0,1,1,0,3.275Zm65.627,63.035H190.684a3.684,3.684,0,0,1,0-7.368H380.607a3.684,3.684,0,0,1,0,7.368Z" transform="translate(0 -239.19)" fill="#f7f7f7" />
        <circle id="Ellipse_108" data-name="Ellipse 108" cx="5.714" cy="5.714" r="5.714" transform="translate(343.561 404.217)" fill="#ffbe55" />
        <path id="Path_815" data-name="Path 815" d="M0,0H10.661V10.661H0Z" transform="matrix(0.828, 0.56, -0.56, 0.828, 362.19, 393.437)" fill="#2a94f4" opacity="0.4" />
        <path id="Path_816" data-name="Path 816" d="M852.68,678.83l10.6,1.094-1.094,10.607" transform="translate(-484.031 -291.419)" fill="#0b4870" />
      </g>
    </svg>


  );
};

export default UploadIcon;
