import React, { useContext, useState } from 'react';
import { OverwriteMainContainer, ResultsContainer } from './OverwriteMain.Styles';
import Comments from 'components/Comments/Comments';
import { DarkButton } from 'components/style/Global';
import { API, input } from 'aws-amplify';
import { createOverwriteData } from 'graphql/mutations';
import { CreateOverwriteDataMutation, Environment, overwriteItem } from 'API';
import { useCampaignContext } from 'context/CampaignContext';
import APIContext from 'context/APIContext';
import { placeholderFullSources } from '../PlaceholderContainer';
// import { OverwriteMainContainer, ResultsContainer } from './StyledComponents'; // Adjust the import paths as necessary
// import Comments from './Comments'; // Adjust the import paths as necessary

interface Props {
    setUserMainQuery: any
    mainData: any;
    selectedDataSource: placeholderFullSources | undefined;
    // setUserMainQuery: () => void;
    // mainData: { [key: string]: any }[];
}

const ResultPage: React.FC<Props> = (props) => {
    const [toggleComment, setToggleComment] = useState(true);
    const { setUserMainQuery, mainData, selectedDataSource } = props;
    const { activeCampaign } = useCampaignContext();
    const { getEnvironment } = useContext(APIContext);

    // map mainData and in each object add a new key value pair which is a copy of the pair that has a numerical value and name it new + key
    const [dataWithNew, setDataWithNew] = useState<any[]>(() =>
        mainData.map((item: { [key: string]: any }) => {
            const newItem = { ...item };
            Object.keys(item).forEach((key) => {
                if (typeof item[key] === 'number') {
                    newItem[`New_${key}`] = item[key];
                }
            });
            newItem.reason = '';
            return newItem;
        })
    );

    const headers = dataWithNew.length > 0 ? Object.keys(dataWithNew[0]) : [];

    // Calculate sums for numeric columns
    const sums = headers.reduce((acc, header) => {
        if (header !== 'dimensionProperty' && header !== 'New_dimensionProperty') {
            acc[header] = dataWithNew.reduce((sum: number, item: any) => sum + (item[header] || 0), 0);
        }
        return acc;
    }, {} as { [key: string]: number });



    let reason = '';

    const handleSaveButton = async () => {
        const overwriteItemsArray: overwriteItem[] = dataWithNew.map((item) => {
            const overwriteItem: overwriteItem = {
                ItemId: item.lineitemid,
                ItemName: item.lineitem,
                NewValue: item.New_impressions,
                Reason: reason,
                Metric: "impressions",
                SourceName: selectedDataSource?.metaName,


            };
            return overwriteItem;
        }
        );


        try {
            const response = (await API.graphql({
                query: createOverwriteData,
                variables: {
                    Datasource_id: "DATASOURCE#ADF",
                    CampaignId: activeCampaign?.campaignId,
                    Environment: getEnvironment,
                    OverwriteItems: overwriteItemsArray
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })) as { data: CreateOverwriteDataMutation }
            console.log(response);
        } catch (e) {
            console.error(e);
        }

    }

    return (
        <OverwriteMainContainer>
            <ResultsContainer>
                <div className="table">
                    {mainData && (
                        <table>
                            <thead>
                                <tr>
                                    {headers.map((header) => (
                                        <th key={header}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataWithNew.map((item: { [key: string]: any }, index: number) => (
                                    <tr key={index}>
                                        {headers.map((header) => (
                                            <td key={header}>
                                                {header.startsWith('New') ? (
                                                    <input
                                                        type="number"
                                                        value={item[header]}
                                                        onChange={(e) => {
                                                            const value = Number(e.target.value);
                                                            const newItem = { ...item, [header]: value };
                                                            const newData = [...dataWithNew];
                                                            newData[index] = newItem;
                                                            setDataWithNew(newData);
                                                        }}
                                                    />
                                                ) :
                                                    header === "reason" ? (
                                                        <input
                                                            type="text"
                                                            value={item[header]}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const newItem = { ...item, [header]: value };
                                                                const newData = [...dataWithNew];
                                                                newData[index] = newItem;
                                                                setDataWithNew(newData);
                                                            }}
                                                        />
                                                    )
                                                        : (
                                                            item[header]
                                                        )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    {headers.map((header) => (
                                        <td key={header}>{header === 'lineitem' || header === 'reason' || header === "lineitemid" ? '' : sums[header]}</td>
                                    ))}
                                </tr>
                            </tfoot>
                        </table>
                    )}
                </div>
                <div className="comment">
                    <textarea defaultValue={reason} onInput={(e) => { reason = (e.target as HTMLInputElement).value }} placeholder='Eneter your reasoning here...'></textarea>
                </div>
                <button className="backButton" type="button" onClick={() => setUserMainQuery()}>
                    GO BACK
                </button>
                <DarkButton onClick={() => handleSaveButton()}>SAVE</DarkButton>
            </ResultsContainer>
        </OverwriteMainContainer>
    );
};

export default ResultPage;