import { createGlobalStyle } from 'styled-components';
import FontRegular from '../../assets/GTWalsheimProRegular.ttf';
import FontMedium from '../../assets/GTWalsheimProMedium.ttf';
import FontBold from '../../assets/GTWalsheimProBold.ttf';

const GlobalPortal = createGlobalStyle`
 @font-face {
    font-family: 'FontRegular';
    src: url(${FontRegular}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
 @font-face {
    font-family: 'FontMedium';
    src: url(${FontMedium}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
 @font-face {
    font-family: 'FontBold';
    src: url(${FontBold}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
body, html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background:${({ theme }) => theme.background};
    background-size: cover;
    background-position:center;
    font-family: 'FontRegular', sans-serif;
}

h1 {
    color: ${({ theme }) => theme.primaryGray};  
    font-family: 'FontMedium', sans-serif; 
}

h2 {
    font-family: 'FontRegular', sans-serif; 
    color: ${({ theme }) => theme.secondaryGray};
}
h4{
    font-family: 'FontRegular', sans-serif; 
}

button {     
cursor: pointer;
background: transparent;
border: 1px solid ${({ theme }) => theme.primaryGray};
border-radius: 7px;
width: fit-content;
margin-bottom: 8px;
font-family: 'FontMedium', sans-serif;
color: ${({ theme }) => theme.primaryGray};
padding: 9px 12px;
height: fit-content;
}

`;

export default GlobalPortal;
