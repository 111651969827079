import {
  agencyEnum,
  countryEnum,
  datatype,
  filterDetail,
  filtertype,
  localQuery,
  metric,
  metricFunction,
  queryInput,
} from 'API';
import AuthContext from 'context/AuthContext';
import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';
import { PublishContext } from 'context/PublishContext';
import { JsonObject, useDataQuery } from 'customHooks/useDataQuery';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { KPIContainer, Kpis, TxtContainer } from './Main.styles';

type Props = {};

const DigitalKPIs = (props: Props) => {
  const { user } = useContext(AuthContext);
  const { campaignList, activeCampaign, canDoInsourceAndOnInsourcePortal } = useCampaignContext();
  const { activeOrganization } = useClientContext();
  const { publishedData, updateOLArray } = useContext(PublishContext);
  const { REACT_APP_AGENCY } = process.env;

  const username = user?.username;
  const organization = activeOrganization?.Organization;
  const ClientCountry = activeCampaign?.ClientCountry;
  const isDemo = organization === 'DEMO-Organization';
  const theme = useTheme();
  const [query, setQuery] = useState<queryInput>();

  // in publishedData find the object with position "0"

  const publishedKPI = publishedData?.find((element: any) => element.position === '0');
  const publishedOutputLocation = publishedKPI?.outputlocation;

  useEffect(() => {
    if (
      activeCampaign &&
      Object.keys(activeCampaign).length > 0 &&
      (activeCampaign?.authorized === true ||
        (canDoInsourceAndOnInsourcePortal && activeCampaign?.authorized === false))
    ) {
      //METRICS DATA
      const metrics: metric[] = [
        {
          column: 'cost',
          name: 'TotalCost',
          aggregation: metricFunction.SUM,
        },
        {
          column: 'clicks',
          name: 'TotalClicks',
          aggregation: metricFunction.SUM,
        },
        {
          column: 'impressions',
          name: 'TotalImpression',
          aggregation: metricFunction.SUM,
        },
      ];

      // FILTER(CAMPAIGN DATA)

      const filter: filterDetail = {
        column: 'campaign_id',
        filter: {
          filtertype: filtertype.EQUAL,
          datatype: datatype.NUMBER,
          value: activeCampaign?.campaignId.toString(), //TODO get from search params hook
        },
      };

      // DATE DATA
      const localQuery: localQuery = {
        filters: [filter],
        metrics: metrics,
        dimensions: ['campaign_id'],
        preview: 100,
      };

      const getDatabase = (country: string | undefined) => {
        switch (country) {
          case 'DK':
            return 'data_lake_denmark';
          case 'SE':
            return 'data_lake_sweden';
          case 'FI':
            return 'data_lake_finland';
          default:
            return 'data_lake_norway';
        }
      };

      const getViewName = (platform: string) => {
        return isDemo ? `fozzie_${platform}_demo` : `fozzie_${platform}_${REACT_APP_AGENCY}`;
      };
      //TODO new digital source
      const platforms = [
        'adform_bvod_excluded',
        'facebook',
        'campaign_manager_bvod_excluded',
        'google_dv360',
        'google_ads_video',
        'google_dv360_youtube',
        'tiktok',
        'snapchat',
        'linkedin',
        'readpeak',
        'taboola',
        'pinterest',
        'marathon_digital_tracking',
        'campaign_manager_olv',
        'adform_olv',
        'google_ads_display',
      ];

      const views = platforms.map((platform) => ({
        viewName: getViewName(platform),
        database: getDatabase(ClientCountry),
      }));

      const newQuery: queryInput = {
        query: localQuery,
        views: views,
        jobnumber: 'ANNA0027',
        clients: [
          {
            country: countryEnum[activeCampaign?.ClientCountry as keyof typeof countryEnum],
            client: activeCampaign?.ClientCode as string,
          },
        ],
        date: { from: getStartDate(), to: getEndDate() },
        user: {
          name: username,
          agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
          country: countryEnum.DK,
        },
      };

      setQuery(newQuery);
    }
  }, [activeCampaign, REACT_APP_AGENCY, isDemo, ClientCountry, username]);

  const [data, queryStatus, error, queryId, outputLocation] = useDataQuery({
    query: query,
    querySource: 'KPI',
    publishedOutputLocation: publishedOutputLocation,
  });

  const jsonDataObject = data as JsonObject;
  const totalClicks = jsonDataObject ? Math.trunc(Number(jsonDataObject.TotalClicks)) : NaN;
  const totalCost = jsonDataObject ? Math.trunc(Number(jsonDataObject.TotalCost)) : NaN;
  const totalImpression = jsonDataObject ? Number(jsonDataObject.TotalImpression) : NaN;
  const CPC = totalCost / totalClicks;
  const CPM = (totalCost / totalImpression) * 1000;

  useEffect(() => {
    if (outputLocation) {
      const placeholdersObject = {
        position: '0',
        outputlocation: outputLocation,
      };
      updateOLArray(placeholdersObject);
    }
  }, [outputLocation, updateOLArray]);

  return (
    <>
      {queryStatus === 'FAILED' ? (
        <Kpis>
          <KPIContainer style={{ padding: '1vw' }}>
            <p>Something went wrong, please reload the page and try again </p>
          </KPIContainer>
        </Kpis>
      ) : (
        <Kpis>
          <KPIContainer>
            {queryStatus === 'RUNNING' ? (
              <TxtContainer className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </TxtContainer>
            ) : (
              <TxtContainer>
                <h3>Cost</h3>
                <h4 className="tooltip">
                  {Intl.NumberFormat('en-DK', { notation: 'compact' }).format(totalCost)}
                  <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(totalCost)}</span>
                </h4>
              </TxtContainer>
            )}
          </KPIContainer>

          <KPIContainer>
            {queryStatus === 'RUNNING' ? (
              <TxtContainer className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </TxtContainer>
            ) : (
              <TxtContainer>
                <h3>Impressions</h3>
                <h4 className="tooltip">
                  {Intl.NumberFormat('en-DK', { notation: 'compact' }).format(totalImpression)}
                  <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(totalImpression)}</span>
                </h4>
              </TxtContainer>
            )}
          </KPIContainer>

          <KPIContainer>
            {' '}
            {queryStatus === 'RUNNING' ? (
              <TxtContainer className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </TxtContainer>
            ) : (
              <TxtContainer>
                <h3>CPM</h3>
                <h4 className="tooltip">
                  {new Intl.NumberFormat('en-DK', { maximumFractionDigits: 2 }).format(CPM)}
                  <span className="tooltiptext">{new Intl.NumberFormat('en-DK').format(CPM)}</span>
                </h4>
              </TxtContainer>
            )}
          </KPIContainer>

          <KPIContainer>
            {queryStatus === 'RUNNING' ? (
              <TxtContainer className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </TxtContainer>
            ) : (
              <TxtContainer>
                <h3>Clicks</h3>
                <h4 className="tooltip">
                  {new Intl.NumberFormat('en-DK', { maximumFractionDigits: 2 }).format(totalClicks)}
                  <span className="tooltiptext">{totalClicks.toLocaleString('en-DK')}</span>
                </h4>
              </TxtContainer>
            )}
          </KPIContainer>

          <KPIContainer>
            {queryStatus === 'RUNNING' ? (
              <TxtContainer className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </TxtContainer>
            ) : (
              <TxtContainer>
                <h3>CPC</h3>
                <h4 className="tooltip">
                  {new Intl.NumberFormat('en-DK', { maximumFractionDigits: 2 }).format(CPC)}
                  <span className="tooltiptext">{new Intl.NumberFormat('en-DK').format(CPC)}</span>
                </h4>
              </TxtContainer>
            )}
          </KPIContainer>
        </Kpis>
      )}
    </>
  );
};

export default DigitalKPIs;
