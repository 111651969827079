import { useClientContext } from 'context/ClientContext';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
// import Highcharts from 'highcharts/highcharts-gantt';

// import CustomEvents from 'highcharts-custom-events';

// CustomEvents(Highcharts);

interface Chart {
  border: string;
  height: number;
  scrollablePlotArea: {
    minHeight: number;
    opacity: number;
  };
  marginRight: number;
  marginTop: number;
  backgroundColor: string;
  style: {
    fontFamily: string;
    fontWeight: string;
    color: string;
  };
}

interface XAxisEvent {
  afterSetExtremes: (this: any) => void;
}

interface XAxis {
  events?: XAxisEvent;
  grid: {
    cellHeight: number;
  };
  currentDateIndicator?: {
    width: number;
    color: string;
    dashStyle: string;
    label: {
      format: string;
    };
  };
}

interface YAxisLabelEvent {
  click: () => void;
}

interface YAxis {
  staticScale: number;
  forceRedraw: boolean;
  label: {
    events?: YAxisLabelEvent;
  };
}

interface Series {
  name: string | undefined;
  data: any[];
}

interface NavigatorSeries {
  type: string;
  pointPlacement: number;
  pointPadding: number;
  accessibility: {
    enabled: boolean;
  };
}

interface NavigatorYAxis {
  min: number;
  max: number;
  reversed: boolean;
  categories: any[];
}

interface Navigator {
  enabled: boolean;
  liveRedraw: boolean;
  series: NavigatorSeries;
  yAxis: NavigatorYAxis;
}

export interface TestType {
  chart: Chart;
  credits: {
    enabled: boolean;
  };
  xAxis: XAxis[];
  yAxis: YAxis[];
  scrollbar: {
    enabled: boolean;
  };
  rangeSelector: {
    enabled: boolean;
  };
  series: Series[];
  navigator: Navigator;
}


export const useGetGanttOptions = (
  seriesArray: any[] | null,
  // height: number,
  // startDate: number | undefined,
  // endDate: number | undefined,
): readonly [TestType | null] => {
  const { activeOrganization } = useClientContext();
  const theme = useTheme();
  const [updateSeries, setUpdateSeries] = useState(0);
  const [options, setOptions] = useState<any | null>(null);



  useEffect(() => {
    if (seriesArray !== null) {
      const test: TestType = {
        chart: {
          border: theme.border,
          height: seriesArray.length * 30 < 800 ? seriesArray.length * 31 : 800,
          scrollablePlotArea: {
            // minHeight: (seriesArray.length + 3) * 31,
            minHeight: seriesArray.length * 30 < 800 ? seriesArray.length * 31 : (seriesArray.length + 3) * 31,
            opacity: 1,
          },
          marginRight: 30,
          marginTop: 90,
          backgroundColor: theme.placeholderBackground,
          style: {
            fontFamily: 'fontRegular',
            fontWeight: 'normal',
            color: theme.primaryGray,
          },
        },
        credits: {
          enabled: false,
        },
        xAxis: [
          {
            events: {
              afterSetExtremes: function (this: any) {
                // if interval is less than 200 daqys
                if (this.chart.xAxis[1].max - this.chart.xAxis[1].min < 17280000000) {
                  this.update({
                    tickInterval: 36e7,
                  });
                } else {
                  this.update({
                    tickInterval: 2592000000, //TODO bring this back and check for different orgs, maybe needs to bechanged a bit
                  });
                  this.chart.xAxis[1].update({
                    tickInterval: undefined,
                  });
                }
              },
            },
            grid: {
              cellHeight: 30,
            },
            // min: startDate,
            // max: endDate,
            currentDateIndicator: {
              width: 4,
              color: theme.brand,
              dashStyle: 'dot',
              label: {
                format: '%A, %e %B',
              },
            },
          },
          {
            grid: {
              cellHeight: 30,

            },
          },
        ],
        yAxis: [
          {
            staticScale: 30,
            forceRedraw: true,
            label: {
              events: {
                click: function () {
                  alert('WOOO');
                },
              },
            },
          },
        ],

        scrollbar: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        series: [
          {
            name: activeOrganization?.Organization,
            data: seriesArray,
          },
        ],

        navigator: {
          enabled: false,
          liveRedraw: true,
          series: {
            type: 'gantt',
            pointPlacement: 0.5,
            pointPadding: 0.25,
            accessibility: {
              enabled: false,
            },
          },
          yAxis: {
            min: 0,
            max: 3,
            reversed: true,
            categories: [],
          },
        },
      }
      setOptions(test);
    } else {
      return;
    }
  }, [activeOrganization?.Organization, seriesArray, theme.border, theme.brand, theme.placeholderBackground, theme.primaryGray]);

  // useEffect(() => {
  //   if (activeOrganization?.Organization !== undefined && seriesArray !== null) {
  //     setOptions({
  //       chart: {
  //         height: seriesArray.length * 30 < 800 ? seriesArray.length * 31 : 800,
  //         scrollablePlotArea: {
  //           minHeight: seriesArray.length * 31,
  //           opacity: 1,
  //         },
  //         marginRight: 30,
  //         marginTop: 90,
  //         // backgroundColor: theme.background,
  //         style: {
  //           fontFamily: 'fontRegular',
  //           fontWeight: 'normal',
  //         },

  //         events: {
  //           load(chart: any) {},
  //         },
  //       },
  //       series: [
  //         {
  //           name: activeOrganization?.Organization,
  //           data: seriesArray,
  //         },
  //       ],
  //     });
  //   }
  // }, [activeOrganization?.Organization, seriesArray]);

  return [options];
};
