import { HeadModul, ModulContainer } from 'components/Main/table/TableStyles';
import { DarkButton } from 'components/style/Global';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TiArrowUnsorted, TiFilter } from "react-icons/ti"
import { useTemplateContext } from 'context/TemplateContext';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { useCampaignContext } from 'context/CampaignContext';
import { useGetStoredQueriesDetails } from 'customHooks/useGetStoredQueriesDetails';


const Modal = styled.div`
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    display: block;
    margin-bottom: 1vh;
    margin-top: 0.3vh;
    font-weight: normal;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontRegular', sans-serif;
    font-size: 14px;
  }

  h1 {
    text-align: center;
    font-size: 30px;
  }

  .tableContainer {
    border: 1px solid ${({ theme }) => theme.border};
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    //  padding: 0.5vw 0.9vw;
    min-height: 200px;
    max-height: 520px;
margin: 30px 0;
    thead {
    position: sticky;
    top: 0;
    }

    tbody {
    }
    overflow-y: scroll;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    tr th {
      text-align: left;
      border-bottom: 2px solid ${({ theme }) => theme.border};
      font-size: 14px;
      padding: 12px;
      color: ${({ theme }) => theme.primaryGray};
    
    }
    th {
      position: sticky;

    }
    tr td {
        font-size: 12px;
      padding: 12px;
    }

    tbody tr {
        color: ${({ theme }) => theme.secondaryGray};
    }

    

  }

  .messageContainer {
    padding: 1vw;
  }

  .filterDropdown {
        height: fit-content;
        width: calc(100% + 2vw);        
        border: 2px solid ${({ theme }) => theme.border};
        position: absolute;
        padding: 0.9vw 0.9vw;
        background:${({ theme }) => theme.placeholderBackground}; ;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        
        
    }
`;

type Props = {
    setPickTemplateDisplay(arg: any): any;
};



function PickTemplate(props: Props) {
    const { templatesList, fetchTemplateById, templateDelete, saveTemplateMessage, fetchedPlaceholders } = useTemplateContext()
    const { activeCampaign } = useCampaignContext()
    // const { templatesList, fetchTemplateById, templateDelete, saveTemplateMessage } = useTemplateContext()
    const [selectedTemplate, setSelectedTemplate] = useState<any>()
    const [showFilter, setShowFilter] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ownerList, setOwnerList] = useState([...new Set(templatesList.map((el: any) => el.CreatedBy))])
    const [displayList, setDisplayList] = useState<any>(templatesList)
    const [filterList, setFilterList] = useState<any>([])
    const [nameOrder, setNameOrder] = useState(true)
    const [dateOrder, setDateOrder] = useState(true)
    const [
        storedQueries,
        ,
        ,
        ,
        ,
        ,
        ,
    ] = useGetStoredQueriesDetails(activeCampaign, undefined, fetchedPlaceholders);
    const filteredStoredQueries = storedQueries?.filter((el) => el.position?.startsWith("1") || el.position?.startsWith("2") || el.position?.startsWith("3")) ?? [];


    useEffect(() => {
        setDisplayList(templatesList)
    }, [templatesList])


    const handleFilter = (e: any) => {
        e.target.checked ? setFilterList((ps: any) => [...ps, e.target.value]) : setFilterList((ps: any) => ps.filter((el: any) => el !== e.target.value))
    }

    useEffect(() => {
        if (filterList.length > 0) {
            setDisplayList(templatesList.filter((item: any) => filterList.includes(item.CreatedBy)));
        } else {
            setDisplayList(templatesList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterList])


    const handleOK = () => {
        if (selectedTemplate) {
            fetchTemplateById(selectedTemplate?.Id)
            props.setPickTemplateDisplay(false)
        } else {
            props.setPickTemplateDisplay(false)

        }
    }


    return (
        <Modal onClick={() => props.setPickTemplateDisplay(false)}>
            {filteredStoredQueries && filteredStoredQueries.length > 0 ?
                <HeadModul>
                    <button type='button' className="x" onClick={() => props.setPickTemplateDisplay(false)}> <IoMdClose className='closeIcon' /></button>
                    <ModulContainer onClick={e => e.stopPropagation()} >
                        <h1>Stored queries detected</h1>
                        <div className="tableContainer messageContainer">
                            <h3>
                                Selecting a template is not possible when stored queries are detected.
                                <br />
                                Please delete the stored queries to proceed.
                            </h3>

                            <DarkButton onClick={handleOK} style={{ alignSelf: "flex-end" }} className="scaleHover" type="button">
                                OK
                            </DarkButton>


                        </div>

                    </ModulContainer>
                </HeadModul>
                :
                <HeadModul onClick={e => e.stopPropagation()} >
                    <button type='button' className="x" onClick={() => props.setPickTemplateDisplay(false)}> <IoMdClose className='closeIcon' /></button>
                    <ModulContainer onClick={e => e.stopPropagation()} >
                        <h1>Select template</h1>
                        <div className="tableContainer">
                            <table>
                                <thead style={{ zIndex: "1" }}>
                                    <tr style={{ zIndex: "1" }}>
                                        <th style={{ zIndex: "1" }}>Name <TiArrowUnsorted onClick={() => {
                                            const newArr = displayList.sort((a: any, b: any) => nameOrder ? a.Name.localeCompare(b.Name) : b.Name.localeCompare(a.Name))
                                            setDisplayList(newArr)
                                            setNameOrder(!nameOrder)
                                        }} /></th>

                                        <th style={{ zIndex: "1" }}>Owner <TiFilter onClick={() => setShowFilter(!showFilter)} />
                                            <div style={{ zIndex: "10", visibility: showFilter ? "visible" : "hidden" }} className='filterDropdown'>
                                                {ownerList.map((el) => (
                                                    <label key={el} style={{ fontWeight: "400", fontSize: "0.7vw" }} >{el}
                                                        <input value={el} onClick={(e) => {
                                                            handleFilter(e)
                                                        }} style={{ float: "right" }} type="checkbox" />
                                                    </label>
                                                ))}
                                            </div>
                                        </th>

                                        <th >Date Created <TiArrowUnsorted onClick={() => {
                                            const newArr = displayList.sort((a: any, b: any) => dateOrder ? a.CreatedAt.localeCompare(b.CreatedAt) : b.CreatedAt.localeCompare(a.CreatedAt))
                                            setDisplayList(newArr)
                                            setDateOrder(!dateOrder)
                                        }} style={{ fontSize: ".7vw" }} /></th>

                                        <th >Delete</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {displayList.map((element: any, index: any) => (
                                        <tr key={index} style={{ cursor: "pointer", color: selectedTemplate?.Id === element.Id ? '#20C933' : "" }}>
                                            <td onClick={() => { setSelectedTemplate(element) }} >{element.Name}</td>
                                            <td onClick={() => { setSelectedTemplate(element) }}>{element.CreatedBy}</td>
                                            <td onClick={() => { setSelectedTemplate(element) }}>{element.CreatedAt.split('T')[0]}</td>
                                            <td style={{ textAlign: "center" }} onClick={() => templateDelete(element.Id)}><AiOutlineDelete /></td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                        {saveTemplateMessage && <div>
                            <p>{saveTemplateMessage}</p>
                        </div>}
                        <DarkButton onClick={handleOK} style={{ alignSelf: "flex-end" }} className="scaleHover" type="button">
                            OK
                        </DarkButton>
                    </ModulContainer>

                </HeadModul>


            }
        </Modal >
    );
}

export default PickTemplate;
