import {
  BottomRow,
  Breadcrumbs,
  Line,
  Metrics,
  MidRow,
  NavigationButton,
  RightPane,
  Selections,
  TopRow,
} from 'components/Main/table/TableStyles';
import { placeholderFullSources } from './PlaceholderContainer';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { ChangeEvent, useState } from 'react';

interface Props {
  isGraph: Boolean;
  placeHolderDataSource: placeholderFullSources[];
  setPage(page: any): any;
  nextPage(): any;
  previousPage(): any;
  selectMetric: (e: ChangeEvent<HTMLInputElement>) => void;
  selectCalcMetric: (e: ChangeEvent<HTMLInputElement>) => void;
}

const MetricsContainer = (props: Props) => {
  const { isGraph, placeHolderDataSource, setPage, nextPage, previousPage, selectMetric, selectCalcMetric } = props;
  const selectedPlatform = placeHolderDataSource.find((e) => e.isSelected);
  const [serchTerm, setSearchTerm] = useState<string>('');

  return (
    <Metrics displayMetrics={3}>
      <TopRow isGraph={props.isGraph}>
        <Breadcrumbs>
          <label style={{ cursor: 'pointer' }} onClick={() => setPage(1)}>
            {selectedPlatform?.name}
          </label>
          <Line>|</Line>
          <label>Dimensions:</label>
          {selectedPlatform?.dimensions
            ?.filter((e) => e.isSelected)
            .map((dimension: any, key: number) => (
              <span key={key}>{dimension.dimension}</span>
            ))}
          <Line>|</Line>
          <label>Metrics:</label>
          {selectedPlatform?.metrics
            ?.filter((e) => e.isSelected)
            .map((metric: any, key: number) => (
              <span key={key}>{metric.metric}</span>
            ))}
          {selectedPlatform?.calcMetrics
            ?.filter((e) => e.isSelected)
            .map((metric: any, key: number) => (
              <span key={key}>{metric.name}</span>
            ))}
        </Breadcrumbs>
      </TopRow>

      <MidRow>
        <h1>Metrics</h1>
        <br />
        <h2>
          Chosen Metrics :
          <span>
            <div style={{ width: isGraph === true ? '40vw' : 'auto', display: "flex", flexWrap: "wrap" }}>
              {selectedPlatform?.metrics
                ?.filter((e) => e.isSelected)
                .map((sM) => (
                  <Selections key={sM.metric} chosen={true}>
                    <input
                      type="checkbox"
                      checked={sM.isSelected}
                      name={sM.metric}
                      value={sM.metric}
                      onChange={(e) => selectMetric(e)}
                    />
                    <span>{sM.metric}</span>
                  </Selections>
                ))}
            </div>

            {selectedPlatform?.calcMetrics
              ?.filter((e) => e.isSelected)
              .map((sCM) => (
                <Selections key={sCM.name} chosen={true}>
                  <input
                    type="checkbox"
                    checked={sCM.isSelected}
                    name={sCM.name}
                    value={sCM.query}
                    onChange={(e) => selectCalcMetric(e)}
                  />
                  <span>{sCM.name}</span>
                </Selections>
              ))}
          </span>
        </h2>

        <RightPane>
          <input
            className="searchBar"
            type="text"
            placeholder="Search.."
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          ></input>
          <div className="listContainer">
            {selectedPlatform?.metrics
              ?.filter((val) => val.metric?.toLowerCase().includes(serchTerm.toLowerCase()))
              .map((metrics) => (
                <Selections key={metrics.metric}>
                  <input
                    type="checkbox"
                    checked={metrics.isSelected}
                    name={metrics.metric}
                    value={metrics.metric}
                    onChange={(e) => selectMetric(e)}
                  />
                  <span>{metrics.metric}</span>
                  {metrics.description && (
                    <>
                      <span className="questionMark tooltip">
                        ?<span className="tooltiptext">{metrics.description}</span>
                      </span>
                    </>
                  )}
                </Selections>
              ))}
            {selectedPlatform?.calcMetrics
              ?.filter((val) => val.name.toLowerCase().includes(serchTerm.toLowerCase()))
              .map((metrics) => (
                <Selections key={metrics.name}>
                  <input
                    type="checkbox"
                    checked={metrics.isSelected}
                    name={metrics.name}
                    value={metrics.query}
                    onChange={(e) => selectCalcMetric(e)}
                  />
                  <span>{metrics.name}</span>
                  <span className="questionMark tooltip">
                    ?<span className="tooltiptext">{metrics.query.replace(/SUM/g, '')}</span>
                  </span>
                </Selections>
              ))}
          </div>
        </RightPane>
      </MidRow>

      <BottomRow isGraph={isGraph}>
        <NavigationButton type="button" style={{ backgroundColor: 'transparent' }} onClick={previousPage}>
          <GoChevronLeft /> Back
        </NavigationButton>
        {selectedPlatform?.metrics?.some((e) => e.isSelected) ||
          selectedPlatform?.calcMetrics.some((e) => e.isSelected) ? (
          <NavigationButton style={{ backgroundColor: 'transparent' }} type="button" onClick={nextPage}>
            Continue <GoChevronRight />
          </NavigationButton>
        ) : (
          <NavigationButton className="notAllowed" type="button" style={{ cursor: 'not-allowed' }}>
            Continue <GoChevronRight />
          </NavigationButton>
        )}
      </BottomRow>
    </Metrics>
  );
};

export default MetricsContainer;
