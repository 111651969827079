/* eslint-disable react-hooks/exhaustive-deps */
import { lightTheme } from 'components/style/Theme';
import AuthContext from 'context/AuthContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useClientContext } from 'context/ClientContext';
import {
  UserType,
  StatementType,
  Clients,
  userAccessType,
  AccessType,
  UpgradeClientAccessMutation,
  GetProjectLevelQuery,
  DowngradeClientAccessMutation,
  GetUsersV3Query,
  getUserV3Response,
} from 'API';
import { API } from 'aws-amplify';
import { getInsightsAccess, getProjectLevel, getUsersV3 } from 'graphql/queries';
import Bar from 'components/Portal/Bar';
import { Dashboard } from 'components/Portal/Dashboard.styled';
import Wrapper from 'components/Wrapper';
import GlobalPortal from 'components/style/GlobalPortal';
import APIContext from 'context/APIContext';
import { useSearchParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import UserAdminCard2 from './UserAdminCard2';
import UserAdminModal2 from './UserAdminModal2';
import { downgradeClientAccess, upgradeClientAccess } from 'graphql/mutations';
import ReadPlusAdminModal from './ReadPlusAdminModal';
import { useGetSingleUserAccess } from 'customHooks/useGetSingleUserAccess';
import { get } from 'lodash';
import DeleteConfirmationModal from 'components/DeleteConfirmationModall';
import { iconList } from 'assets/newAssets/SVG/additionalIconsForLinks/iconList';

interface IGetInsightAccessResponse {
  getInsightsAccess?: {
    __typename: 'UserStatementType';
    Users?: Array<{
      __typename: 'UserWithActionListStatement';
      Username?: string | null;
      Statements?: Array<{
        __typename: 'ActionListStatement';
        Resource?: string | null;
        ClientCode?: string | null;
        ClientCountry?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
}

export type readPlusSave = {
  newLevel: string;
  clientCodes: Clients;
};

export const Wrapper2 = styled.div`
  height: 100%;
  margin: auto;
  width: 70vw; // used to be 60
  display: flex;
  flex-direction: row;
  gap: 1.37vh;

  .column {
    width: 35vw; // used to be 30
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 50px 50px 20px 20px;
    border: 1px solid ${({ theme }) => theme.border};
    border-top: none;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  }

  .fixedHeader {
    height: fit-content;
    width: 100%;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border-radius: 20px 20px 0 0;
    display: flex;
    align-items: center;
    padding: 1.38vh 1.34vw;
    justify-content: space-between;

    h1 {
      font-weight: normal;
      font-size: 1.2vw;
    }

    h3 {
      font-weight: 100;
      color: #20c933;
      text-decoration: underline;
    }

    .icons {
      display: flex;
      flex-direction: row;
      /* gap: 1.05vw; */
      flex-direction: row;
      color: ${({ theme }) => theme.primaryGray};

      .icon {
        cursor: pointer;
        /* justify-content: center;
        align-items: center; */
        padding: 7px 10px;
        /* size: 0.729vw; */
        font-size: 1.2vw;
        &:hover {
          background: #f1f5fa;
          border-radius: 4px;
        }
      }
      .searchBar {
      display: flex;
      align-items: center;
      padding-right: .7vw;

      .input {
        font-size: 12px;
        padding: 8px;
        border-radius: 7px;
        border: 1px solid ${({ theme }) => theme.border};
        color: ${({ theme }) => theme.primaryGray};
        background-color: ${({ theme }) => theme.background};

        ::placeholder {
          color: ${({ theme }) => theme.primaryGray};
          font-family: 'FontMedium', sans-serif;
        }
      }
    }
    }
  }

  .cards {
    width: 100%;
    height: 72vh;
    margin-top: 0.73vh;
    margin-bottom: 0.18vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .test {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.46vh;
    margin-bottom: 0.6vh;
    padding: 0 1vw;
  }

  .loaderDiv {
    position: absolute;
  }
  .headerSubtitle {
    font-size: 14px;
    font-weight: 100;
    color: ${({ theme }) => theme.secondaryGray};
    text-decoration: underline;
    cursor: pointer;
  }
`;

type Props = {};

const UserAdmin2 = (props: Props) => {
  const { activeOrganization, organizationList, setActiveOrganization, checkPaddington, typeOfReadAccess, getLevel } =
    useClientContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getEnvironment } = useContext(APIContext);
  const [internalUsers, setInternalUsers] = useState<Array<getUserV3Response>>();
  const [externalUsers, setExternalUsers] = useState<Array<getUserV3Response>>();
  const [searchTermEXT, setSearchTermEXT] = useState<string>('');
  const [searchTermINT, setSearchTermINT] = useState<string>('');
  const { isAuthorized } = useContext(AuthContext);
  const [seeModal, setSeeModal] = useState(false);
  const [hidden, setHidden] = useState<Boolean>(false);
  const [internalInsightsData, setInternalInsightsData] = useState<any>();
  const [externalInsightsData, setExternalInsightsData] = useState<any>();
  const { REACT_APP_ANNALECT_DATA_USER } = process.env;
  const canSeeAnnalectData = REACT_APP_ANNALECT_DATA_USER === 'true';

  useEffect(() => {
    const client = searchParams.get('client');
    if (client && organizationList.length > 0) {
      const clientDetails = organizationList.find((e: any) => e.OrganizationId === client);
      if (clientDetails === undefined) {
        alert('You are not authorized to see this organization');
      } else {
        setActiveOrganization(clientDetails);
      }
    }
  }, [/* searchParams, */ organizationList]);

  const [theme, setTheme] = useState(lightTheme);

  const fetchInternalUsersV2 = useCallback(async () => {
    try {
      const r = (await API.graphql({
        query: getUsersV3,
        variables: {
          Environment: getEnvironment,
          Organization_id: activeOrganization?.OrganizationId,
          UserType: UserType.INTERNAL,
          StatementType: StatementType.Access,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUsersV3Query };

      const newResponse = r.data.getUsersV3 as getUserV3Response[];
      setInternalUsers(newResponse);
    } catch {
      console.error('errorFetchingInternalUsers');
    }
    try {
      const response = (await API.graphql({
        query: getInsightsAccess,
        variables: {
          Environment: getEnvironment,
          Organization_id: activeOrganization?.OrganizationId,
          UserType: UserType.INTERNAL,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: IGetInsightAccessResponse };
      setInternalInsightsData(response.data.getInsightsAccess?.Users);
    } catch (e) {
      console.error('ERROR IN INTERNAL USERS', e);
    }
  }, [activeOrganization, getEnvironment]);

  const fetchExternalUsersV2 = useCallback(async () => {
    try {
      const r = (await API.graphql({
        query: getUsersV3,
        variables: {
          Environment: getEnvironment,
          Organization_id: activeOrganization?.OrganizationId,
          UserType: UserType.EXTERNAL,
          StatementType: StatementType.Access,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetUsersV3Query };

      const newResponse = r.data.getUsersV3 as getUserV3Response[];

      setExternalUsers(newResponse);
    } catch {
      console.error('errorFetchingEXternalUsers');
    }

    try {
      const response = (await API.graphql({
        query: getInsightsAccess,
        variables: {
          Environment: getEnvironment,
          Organization_id: activeOrganization?.OrganizationId,
          UserType: UserType.EXTERNAL,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: IGetInsightAccessResponse };
      setExternalInsightsData(response.data.getInsightsAccess?.Users);
    } catch (e) {
      console.log('ERROR IN EXTERNAL USERS', e);
    }
  }, [activeOrganization, getEnvironment]);

  useEffect(() => {
    if (activeOrganization) {
      // setBufferExternalUsers(undefined);
      // setBufferInternalUsers(undefined);
      setSearchTermEXT('');
      setSearchTermINT('');
      setInternalUsers(undefined);
      setExternalUsers(undefined);
      fetchInternalUsersV2();
      fetchExternalUsersV2();
    }
  }, [activeOrganization]);

  const callBothUsers = () => {
    setSearchTermEXT('');
    setSearchTermINT('');
    fetchInternalUsersV2();
    fetchExternalUsersV2();
  };

  useEffect(() => {
    isAuthorized ? setHidden(false) : setHidden(true);
  }, [isAuthorized]);

  const changeHidden = () => {
    setHidden(!hidden);
  };

  const [getAccess] = useGetSingleUserAccess();
  const [modifyUser, setModifyUser] = useState<getUserV3Response>();
  const [modifyUserAccessLevels, setModifyUserAccessLevels] = useState<userAccessType[]>();
  const [modifyUserInsights, setModifyUserInsights] = useState([]);
  const modalModify = (user: getUserV3Response, usrTyp: string, insightsData: any) => {
    getAccess(user).then((e) => {
      setModifyUserAccessLevels(e);
    });
    setModifyUserInsights(insightsData);
    setUserType(usrTyp);
    setModifyUser(user);
    setSeeModal(!seeModal);
  };
  const [userType, setUserType] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const themes = useTheme();

  // const isReadPlus = checkPaddington?.Access?.some(e => e?.Level?.includes("READ")) ?? false
  // const typeOfReadAccess = checkPaddington?.Access?.find(e => e?.Level === "FULL_ACCESS")?.Clients?.map((e: Clients | null, index: number) => {
  //   return { ...e, Level: index % 2 === 0 ? "READ_ACCESS" : "READ_PLUS_ACCESS" }
  // }) ?? []
  const [readPlusModal, setReadPlusModal] = useState(false);
  const [readPlusLoader, setReadPlusLoader] = useState(false);

  const getLevelCheck = async (fullClients: any[]) => {
    fullClients.forEach((e) => {
      delete e?.__typename;
    });
    try {
      const response = (await API.graphql({
        query: getProjectLevel,
        variables: {
          Clients: fullClients,
          Environment: getEnvironment,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetProjectLevelQuery };
      const responseArray = response.data.getProjectLevel;
      const isPending = responseArray?.some((e) => e?.Status === 'PENDING');
      if (isPending || responseArray?.length === 0) {
        setTimeout(() => {
          getLevelCheck(fullClients);
        }, 5000);
      } else {
        getLevel(fullClients);
        callBothUsers();
        setReadPlusModal(false);
        setReadPlusLoader(false);
        return true;
      }
    } catch (error) {
      console.error('Error in getLevel', error);
    }
  };

  const HandleReadPlusModalResponse = async (arg: readPlusSave) => {
    if (arg.newLevel === 'READ_PLUS_ACCESS') {
      // CALL UPGRADE API

      try {
        const response = (await API.graphql({
          query: upgradeClientAccess,
          variables: {
            Clients: arg.clientCodes,
            Current_Level: AccessType.READ,
            Upgraded_Level: AccessType.READ_PLUS,
            Environment: getEnvironment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: UpgradeClientAccessMutation };
        const isItSuccess = await getLevelCheck(
          checkPaddington?.Access?.find((e) => e?.Level === 'FULL_ACCESS')?.Clients ?? [],
        );
      } catch (e) {
        console.error('ERROR IN UPGRADE CLIENT ACCESS', e);
      }
    } else {
      try {
        const response = (await API.graphql({
          query: downgradeClientAccess,
          variables: {
            Clients: arg.clientCodes,
            Environment: getEnvironment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: DowngradeClientAccessMutation };
        const isItSuccess = await getLevelCheck(
          checkPaddington?.Access?.find((e) => e?.Level === 'FULL_ACCESS')?.Clients ?? [],
        );
      } catch (e) {
        console.error('ERROR IN UPGRADE CLIENT ACCESS', e);
      }

      setReadPlusModal(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper changeHidden={changeHidden} hidden={hidden}></Wrapper>
      <GlobalPortal />
      {/* <Aside themeToggler={themeToggler} theme={theme}></Aside> */}
      <Dashboard h="95vh">
        <Bar
          location={'userAdmin'}
          setSearchParams={(params: any) => setSearchParams(params)}
          searchParams={searchParams}
        ></Bar>
        <div className="wrapper24">
          {externalUsers && internalUsers ? (
            <Wrapper2 hidden={false}>
              <div className="column">
                <div className="fixedHeader">
                  <h1>
                    Internal users <span>({internalUsers?.length})</span>
                  </h1>
                  <div className="icons">
                    <div className="searchBar">
                      <input
                        className="input"
                        type="text"
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchTermINT(event.target.value);
                        }}
                      />
                    </div>
                    <div onClick={() => {
                      setSeeModal(!seeModal);
                      setUserType('INTERNAL');
                    }} className="icon material-symbols-outlined">{iconList.find((e) => e === 'person_add')}</div>

                  </div>
                </div>
                <div className="cards">
                  <div className="test">
                    {internalUsers !== undefined &&
                      internalUsers
                        .filter((val) => val.Name?.toLowerCase().includes(searchTermINT.toLowerCase()))
                        .map((e) => (
                          <UserAdminCard2
                            typeOfReadAccess={typeOfReadAccess}
                            setExternalUsers={(props: any) => setExternalUsers}
                            setInternalUsers={(props: any) => setInternalUsers}
                            setUserType={(props: any) => setUserType}
                            callFetchUsers={fetchInternalUsersV2}
                            userType={'INTERNAL'}
                            key={e.Username}
                            data={e}
                            modalModify={(user, usrTyp, insightsData) => modalModify(user, usrTyp, insightsData)}
                            insightsData={internalInsightsData?.filter(
                              (element: any) => element.Username === e.Username,
                            )}
                          ></UserAdminCard2>
                        ))}
                  </div>
                </div>

              </div>
              <div className="column">
                <div className="fixedHeader">
                  <div>
                    <h1>
                      External users <span>({externalUsers?.length})</span>
                    </h1>

                  </div>
                  <div className="icons">
                    <div className="searchBar">
                      <input
                        className="input"
                        type="text"
                        placeholder="Search..."
                        onChange={(event) => {
                          setSearchTermEXT(event.target.value);
                        }}
                      />
                    </div>
                    <div onClick={() => {
                      setSeeModal(!seeModal);
                      setUserType('EXTERNAL');
                    }} className="icon material-symbols-outlined">{iconList.find((e) => e === 'person_add')}</div>
                    <div onClick={() => { setReadPlusModal(!readPlusModal) }} className="icon material-symbols-outlined">
                      {iconList.find((e) => e === 'switch_access_2')}
                    </div>

                  </div>


                </div>
                <div className="cards">
                  <div className="test">
                    {externalUsers !== undefined &&
                      externalUsers
                        ?.filter((val: any) => {
                          if (searchTermEXT === '') {
                            return val;
                          } else if (val.Name.toLowerCase().includes(searchTermEXT.toLowerCase())) {
                            return val;
                          }
                          return null;
                        })
                        .map((e) => (
                          <UserAdminCard2
                            typeOfReadAccess={typeOfReadAccess}
                            setExternalUsers={(props: any) => setExternalUsers}
                            setInternalUsers={(props: any) => setInternalUsers}
                            setUserType={(props: any) => setUserType}
                            callFetchUsers={fetchExternalUsersV2}
                            userType={'EXTERNAL'}
                            key={e.Username}
                            data={e}
                            modalModify={(user, usrTyp, insightsData) => modalModify(user, usrTyp, insightsData)}
                            insightsData={externalInsightsData?.filter(
                              (element: any) => element.Username === e.Username,
                            )}
                          ></UserAdminCard2>
                        ))}
                  </div>
                </div>

              </div>
            </Wrapper2>
          ) : (
            <div className="loaderDiv">
              <ReactLoading type="spinningBubbles" color={theme.publishButton}></ReactLoading>
            </div>
          )}
        </div>
        {seeModal && (
          <UserAdminModal2
            setSeeModal={(seeModal: boolean) => setSeeModal(seeModal)}
            seeModal={seeModal}
            setExternalUsers={(props) => setExternalUsers}
            setInternalUsers={(props) => setInternalUsers}
            modifyUser={modifyUser as getUserV3Response}
            modifyUserAccessLevels={modifyUserAccessLevels}
            setModifyUserAccessLevels={(par: any) => setModifyUserAccessLevels(par)}
            setModifyUser={(modifyUser: any) => setModifyUser(modifyUser)}
            callBothUsers={callBothUsers}
            userType={userType}
            modifyUserInsights={modifyUserInsights}
            setModifyUserInsights={(par: any) => setModifyUserInsights(par)}
            typeOfReadAccess={typeOfReadAccess}
          ></UserAdminModal2>
        )}

        {readPlusModal && (
          <ReadPlusAdminModal
            setReadPlusModal={(readPlusModal: boolean) => setReadPlusModal(readPlusModal)}
            readPlusModal={readPlusModal}
            typeOfReadAccess={typeOfReadAccess}
            HandleReadPlusModalResponse={HandleReadPlusModalResponse}
            readPlusLoader={readPlusLoader}
            setReadPlusLoader={(readPlusLoader: boolean) => setReadPlusLoader(readPlusLoader)}
          ></ReadPlusAdminModal>
        )}
      </Dashboard>
    </ThemeProvider>
  );
};

export default UserAdmin2;
