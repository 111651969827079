import { GetQueriesQuery, StatementsFromClient, UserQuery } from 'API';
import PlaceholderContainer, { PlaceholderType } from './PlaceholderContainer';
import { fullSources } from 'customHooks/useDataSources';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTemplateContext } from 'context/TemplateContext';
import { Graphs } from 'components/Main/Main.styles';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { useDragAndDrop } from 'customHooks/useDragAndDrop';
import { API } from 'aws-amplify';
import { getQueries } from 'graphql/queries';
import InputContext from 'context/InputContext';
interface Props {
  tablePlaceholderList: PlaceholderType[];
  setTablePlaceholderList: React.Dispatch<React.SetStateAction<PlaceholderType[]>>;
  findStoredQueryProp: (id: string) => UserQuery | undefined;
  findStoriedQuery: (id: string) => UserQuery | undefined;
  dataSources: fullSources[];
  clickedAddNewElement: (
    newElement: string,
    type?: string,
    componentQueryId?: string,
    componentParentId?: string,
  ) => Promise<PlaceholderType | undefined>;
  isGraph: boolean;
  componentIDPrefix: string;
  setStoredQueries: React.Dispatch<React.SetStateAction<UserQuery[]>>;
  storedQueries: UserQuery[];
  findOriginalElement: (id: string) => PlaceholderType | undefined;
  statements?: StatementsFromClient | null | undefined;
  addingNewTable?: boolean;
  setAddingNewTable?: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  newPlaceholderItem?: PlaceholderType | undefined;
  setNewPLaceholderItem?: React.Dispatch<React.SetStateAction<PlaceholderType | undefined>>;
}
export interface orderList2 {
  Order: number;
  QueryId: string | undefined;
  Saved?: boolean;
}

const SortingPlaceHolderList = (props: Props) => {
  const {
    type,
    tablePlaceholderList,
    setTablePlaceholderList,
    findStoredQueryProp,
    dataSources,
    clickedAddNewElement,
    isGraph,
    componentIDPrefix,
    setStoredQueries,
    findOriginalElement,
    setAddingNewTable,
    statements,
    newPlaceholderItem,
    setNewPLaceholderItem,
  } = props;

  const { activeCampaign } = useCampaignContext();
  const { isDragEnabled } = useTemplateContext();
  const [newStoredQueries, setNewStoredQueries] = useState<UserQuery[] | undefined>();
  const { order2Table, order2Chart, setOrder2Table, setOrder2Chart } = useContext(InputContext);
  const { updateOrderList } = useDragAndDrop({ tablePlaceholderList });
  const order2 = type === 'Table' ? order2Table : order2Chart;
  const setOrder2 = type === 'Table' ? setOrder2Table : setOrder2Chart;

  useEffect(() => {
  }, [tablePlaceholderList, order2]);

  const addUnsavedItemsToOrder2 = (newPlaceholder?: PlaceholderType | undefined) => {
    // Find items with saved as false
    let unsavedItems = tablePlaceholderList.filter((item: any) => item.saved === false);

    // If newPlaceholder is not null or undefined, add it to unsavedItems
    if (newPlaceholder) {
      unsavedItems = [...unsavedItems, newPlaceholder];
    }
    // Filter out items that are already in order2
    const newItems = unsavedItems.filter((item) => !order2.some((orderItem) => orderItem.QueryId === item.query_id));
    // Find the last element
    const lastElement = newItems[newItems.length - 1];

    const newOrder2 = [...order2];

    if (lastElement) {
      newOrder2.push({
        QueryId: lastElement.id,
        Order: order2.length + 1,
        Saved: false,
      });
    }

    setAddingNewTable !== undefined && setAddingNewTable(false);
    setOrder2(newOrder2);
    type === 'Table' && setNewPLaceholderItem !== undefined && setNewPLaceholderItem(undefined);
  };

  useEffect(() => {
    if (newPlaceholderItem !== undefined) {
      if (newPlaceholderItem) {
        addUnsavedItemsToOrder2(newPlaceholderItem);
      }
    }
  }, [newPlaceholderItem]);

  const savePlaceholderToOrder2 = (componentId: string) => {
    const newOrder2 = order2.map((item) => {
      if (item.QueryId === componentId) {
        return {
          ...item,
          Saved: true,
        };
      }
      return item;
    });

    // console.log("ORDER2##########", newOrder2)
    setOrder2(newOrder2);
    saveOrderList(newOrder2);
  };

  const saveOrderList = (newOrder2: orderList2[]) => {
    const onlySavedOrder = newOrder2.filter((item) => /* item.Saved === undefined || */ item.Saved === true);
    const newOrderedList = newOrderForBackend(onlySavedOrder);
    // console.log("ORDER2##########", newOrderedList)
    updateOrder(type, newOrderedList);
  };

  const addPlaceholder = async () => {
    // clickedAddNewElement('Chart');
    const thisIsNewItem = await clickedAddNewElement('Chart');
    addUnsavedItemsToOrder2(thisIsNewItem);
  };

  const moveElement = (id: string, direction: 'UP' | 'DOWN') => {
    setOrder2((currentOrder: orderList2[]) => {
      const index = currentOrder.findIndex((element) => {
        return String(element.QueryId) === id;
      });
      if (index < 0) return currentOrder;

      const newIndex = direction === 'UP' ? index - 1 : index + 1;
      if (newIndex < 0 || newIndex >= currentOrder.length) return currentOrder;
      const newOrder = [...currentOrder];
      [newOrder[index], newOrder[newIndex]] = [newOrder[newIndex], newOrder[index]];

      const newOrderedList = newOrderForBackend(newOrder);
      updateOrder(type, newOrderedList);

      return newOrder;
    });
  };

  const removeItemFromList = (id: string) => {
    const newList = tablePlaceholderList.filter((el) => el.id !== id);
    const newOrder = order2.filter((el) => el.QueryId !== id);

    const newOrderedList = newOrderForBackend(newOrder);

    setOrder2(newOrder);
    setTablePlaceholderList(newList);
    // setStoredQueries((prev) => prev.filter((el) => el.position !== id));
    updateOrder(type, newOrderedList);
  };

  const newOrderForBackend = (newOrder: orderList2[]) => {
    const newOrderedList = newOrder.map((item, index) => ({
      Order: index + 1,
      QueryId: item.QueryId,
    })) as orderList2[];

    return newOrderedList;
  };

  const updateTablePlaceholderDuplicate = async (
    queryId: string,
    id: string,
    isDupicated: boolean | undefined,
    newOriginalId?: string,
  ) => {
    const iselementDuplicated = isDupicated === undefined ? false : isDupicated;
    const newTablePlaceholderList = tablePlaceholderList.map((item) => {
      if (item.id === id) {
        const newOrginalId = newOriginalId ? newOriginalId : item.original_id;
        return {
          ...item,
          // query_id: queryId,
          saved: true,
          duplicated: iselementDuplicated,
          newQueryId: queryId,
          original_id: newOrginalId,
        };
      }
      return item;
    });
    const queriesForDuplication = await newQueryID();
    setNewStoredQueries(queriesForDuplication as UserQuery[]);
    await setTablePlaceholderList(newTablePlaceholderList);
  };

  const newQueryID = async () => {
    try {
      const response = (await API.graphql({
        query: getQueries,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetQueriesQuery };
      const storedQueries = response.data.getQueries;
      setNewStoredQueries(storedQueries as UserQuery[]);
      return storedQueries;
      // sortPlaceholders(storedQueries as UserQuery[]);
    } catch (e) {
      console.log('ERROR FETCHING QUARIES', e);
    }
  };

  const handleDuplicate = async (componentID: string) => {
    const queriesForDuplication = await newQueryID();

    const findQueryObject = queriesForDuplication?.find((el) => el?.position === componentID);

    const componentQueryId = findQueryObject?.query_id as string;
    const componentId = findQueryObject?.position as string;

    const newPlaceholder = await clickedAddNewElement(type, 'duplicated', componentQueryId, componentId);
    addUnsavedItemsToOrder2(newPlaceholder);
    if (newPlaceholder) {
      return true;
      // setNewPlaceholder(newPlaceholder);
      // addDuplicate(newPlaceholder);
    }

    return false;
  };

  const parentRef = useRef<HTMLElement>(null);
  let totalHeight = 0;

  useEffect(() => {
    if (parentRef.current) {
      const calculateHeight = () => {
        if (parentRef.current) {
          const draggableDivs = parentRef.current.querySelectorAll('.draggable-div') as NodeListOf<HTMLElement>;
          let totalHeight2 = 0;
          draggableDivs.forEach((div) => {
            const style = window.getComputedStyle(div);
            const marginTop = parseInt(style.marginTop);
            const marginBottom = parseInt(style.marginBottom);
            totalHeight2 += div.offsetHeight + marginTop + marginBottom;
          });
          totalHeight = totalHeight2;
          parentRef.current.style.minHeight = `${totalHeight}px`;
        }
      };

      // Call calculateHeight initially
      calculateHeight();

      // Set up a MutationObserver to call calculateHeight whenever a child div's height changes
      const observer = new MutationObserver(calculateHeight);
      const draggableDivs = Array.from(parentRef.current.querySelectorAll('.draggable-div'));
      draggableDivs.forEach((div) => observer.observe(div, { attributes: true, attributeFilter: ['style'] }));

      // Stop observing when the component unmounts
      return () => observer.disconnect();
    }
  }, [parentRef, tablePlaceholderList]);

  const [dragSrcIndex, setDragSrcIndex] = useState<any>(null);

  const handleDragStart = (e: any, index: number) => {
    const target = e.target as HTMLDivElement;
    target.style.opacity = '1';
    e.target.classList.add('dragging');
    setDragSrcIndex(index);
  };

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: { target: { classList: { add: (arg0: string) => void } } }) => {
    const target = e.target as HTMLDivElement;
    e.target.classList.add('over');
    target.style.visibility = 'visible';
  };

  const handleDragLeave = (e: { target: { classList: { remove: (arg0: string) => void } } }) => {
    e.target.classList.remove('over');
  };

  const handleDrop = (e: any, index: number) => {
    e.preventDefault();
    const newItems = [...order2];
    const [removed] = newItems.splice(dragSrcIndex, 1);
    newItems.splice(index, 0, removed);
    setOrder2(newItems);
  };

  const handleDragEnd2 = async (
    e: {
      target: { style: { opacity: string }; classList: { remove: (arg0: string) => void } };
    },
    id: number,
  ) => {
    e.target.style.opacity = '1';
    e.target.classList.remove('over');
    e.target.classList.remove('dragging');

    const findElement = order2.find((item) => item.Order === id);

    if (findElement?.Order === findElement?.QueryId) {
    } else {
      const orderSaved = order2.filter((item) => item.Saved === true);
      const newOrderedList = newOrderForBackend(orderSaved);
      updateOrder(type, newOrderedList);
    }
  };

  const updateOrder = async (type: string, newList: orderList2[]) => {
    await updateOrderList(type, newList);
  };

  return (
    <>
      {isGraph ? (
        <Graphs dashboard={'online'} columnsNumber={2}>
          {order2.map((id: orderList2, index: number) => (
            <div
              key={id.Order}
              draggable={isDragEnabled}
              onDragStart={isDragEnabled ? (e) => handleDragStart(e, index) : undefined}
              onDragEnter={isDragEnabled ? (e) => handleDragEnter : undefined}
              onDragOver={isDragEnabled ? handleDragOver : undefined}
              onDragLeave={isDragEnabled ? (e) => handleDragLeave : undefined}
              onDrop={isDragEnabled ? (e) => handleDrop(e, index) : undefined}
              onDragEnd={isDragEnabled ? (e: any) => handleDragEnd2(e, id.Order) : undefined}
              className={isDragEnabled ? 'item' : ''}
            >
              <>
                <PlaceholderWrapper
                  id={id.QueryId as string}
                  index={index}
                  tablePlaceholderList={tablePlaceholderList}
                  moveElement={moveElement}
                  orderLength={order2.length}
                  findStoredQueryProp={findStoredQueryProp}
                  dataSources={dataSources}
                  isDragEnabled={isDragEnabled}
                  handleDuplicate={handleDuplicate}
                  // newPlaceholder={newPlaceholder}
                  isGraph={isGraph}
                  componentIDPrefix={componentIDPrefix}
                  setTablePlaceholderList={setTablePlaceholderList}
                  setStoredQueries={setStoredQueries}
                  removeItemFromList={removeItemFromList}
                  findOriginalElement={findOriginalElement}
                  otherElement={undefined}
                  activeCampaign={activeCampaign}
                  statements={statements}
                  clickedAddNewElement={clickedAddNewElement}
                  savePlaceholderToOrder2={savePlaceholderToOrder2}
                  updateTablePlaceholderDuplicate={updateTablePlaceholderDuplicate}
                  newStoredQueries={newStoredQueries}
                />
              </>
            </div>
          ))}
          {activeCampaign && statements?.Queries?.CREATE && (
            // <div className="addMoreButton" onClick={() => clickedAddNewElement('Chart')}>
            <>
              <div className="addMoreButton" onClick={() => addPlaceholder()}>
                + Add Placeholder
              </div>
            </>

          )}
        </Graphs>
      ) : (
        <>
          {order2.map((id: orderList2, index: number) => (
            <div
              key={id.Order}
              draggable={isDragEnabled}
              onDragStart={isDragEnabled ? (e) => handleDragStart(e, index) : undefined}
              onDragEnter={isDragEnabled ? (e) => handleDragEnter : undefined}
              onDragOver={isDragEnabled ? handleDragOver : undefined}
              onDragLeave={isDragEnabled ? (e) => handleDragLeave : undefined}
              onDrop={isDragEnabled ? (e) => handleDrop(e, index) : undefined}
              onDragEnd={isDragEnabled ? (e: any) => handleDragEnd2(e, id.Order) : undefined}
              className={isDragEnabled ? 'item' : ''}
            >
              <>
                <PlaceholderWrapper
                  id={id.QueryId as string}
                  index={index}
                  tablePlaceholderList={tablePlaceholderList}
                  moveElement={moveElement}
                  orderLength={order2.length}
                  findStoredQueryProp={findStoredQueryProp}
                  dataSources={dataSources}
                  isDragEnabled={isDragEnabled}
                  handleDuplicate={handleDuplicate}
                  // newPlaceholder={newPlaceholder}
                  isGraph={isGraph}
                  componentIDPrefix={componentIDPrefix}
                  setTablePlaceholderList={setTablePlaceholderList}
                  setStoredQueries={setStoredQueries}
                  removeItemFromList={removeItemFromList}
                  findOriginalElement={findOriginalElement}
                  otherElement={undefined}
                  activeCampaign={activeCampaign}
                  statements={statements}
                  clickedAddNewElement={clickedAddNewElement}
                  savePlaceholderToOrder2={savePlaceholderToOrder2}
                  updateTablePlaceholderDuplicate={updateTablePlaceholderDuplicate}
                  newStoredQueries={newStoredQueries}
                />
              </>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default SortingPlaceHolderList;

interface PlaceholderWrapperProps {
  id: string;
  index: number;
  tablePlaceholderList: PlaceholderType[];
  moveElement: (id: string, direction: 'UP' | 'DOWN') => void;
  orderLength: number;
  findStoredQueryProp: (id: string) => UserQuery | undefined;
  dataSources: fullSources[];
  isDragEnabled: boolean;
  handleDuplicate: (componentID: string, mainQueryID: string) => Promise<boolean>;
  // newPlaceholder: PlaceholderType | null;
  isGraph: boolean;
  componentIDPrefix: string;
  setTablePlaceholderList: React.Dispatch<React.SetStateAction<PlaceholderType[]>>;
  setStoredQueries: React.Dispatch<React.SetStateAction<UserQuery[]>>;
  removeItemFromList: (id: string) => void;
  findOriginalElement: (id: string) => PlaceholderType | undefined;
  otherElement: PlaceholderType | undefined;
  activeCampaign?: ACdetails;
  statements?: StatementsFromClient | null | undefined;
  clickedAddNewElement?: (
    newElement: string,
    type?: string,
    componentQueryId?: string,
  ) => Promise<PlaceholderType | undefined>;
  updateTablePlaceholderDuplicate: (
    queryId: string,
    id: string,
    isDupicated: boolean | undefined,
    newOriginalId?: string | undefined,
  ) => void;
  savePlaceholderToOrder2: (componentId: string) => void;
  newStoredQueries: UserQuery[] | undefined;
}

export const PlaceholderWrapper = ({
  id,
  index,
  tablePlaceholderList,
  moveElement,
  orderLength,
  findStoredQueryProp,
  dataSources,
  isDragEnabled,
  handleDuplicate,
  // newPlaceholder,
  isGraph,
  componentIDPrefix,
  setTablePlaceholderList,
  setStoredQueries,
  removeItemFromList,
  savePlaceholderToOrder2,
  updateTablePlaceholderDuplicate,
  activeCampaign,
  newStoredQueries,
}: PlaceholderWrapperProps) => {
  const element2 = tablePlaceholderList.find((el) => el.id === id);
  /**
  * This block of code is a `useMemo` hook that calculates the details of a stored query based on the current state of `element2`.
  * If `element2` is undefined or its `original_id` property is undefined, it returns undefined.
  *
  * It then finds the `originalElement` in `tablePlaceholderList` that matches the `original_id` of `element2`.
  * It calculates `test` based on the `original_id` of `element2`.
  * If `original_id` is a number greater than 99, it uses `findStoredQueryProp` with `original_id` as a string.
  * If `original_id` is not greater than 99, it uses `findStoredQueryProp` with a string composed of `componentIDPrefix` and `element2.minor`.
  *
  * It checks if `originalElement` is a placeholder by comparing its `id` and `query_id`.
  *
  * If `element2` is saved and duplicated, it finds the stored query with a position matching `element2.id`.
  * If `element2` is not saved and is duplicated, it finds the stored query with a position matching `element2.original_id`.
  *
  * If `originalElement` is a placeholder and `element2` is not saved, it finds the stored query with a position matching `element2.id`.
  * If `test` is undefined and `element2` is not saved, it finds the stored query with a position matching `element2.original_id`.
  *
  * If none of the above conditions are met, it returns `test`.
  * This hook is recalculated whenever `element2`, `findStoredQueryProp`, `tablePlaceholderList`, `componentIDPrefix`, or `newStoredQueries` changes.
  * If `element2` is null or undefined, it returns null.
  */
  const storedQueryDetails = useMemo(() => {
    if (element2 === undefined || element2?.original_id === undefined) return undefined;

    const storedQueries = newStoredQueries;
    const originalId = element2?.original_id?.toString();
    const originalElement = tablePlaceholderList.find((el) => el.id === originalId);

    const test =
      Number(element2?.original_id) > 99
        ? findStoredQueryProp(element2?.original_id?.toString())
        : findStoredQueryProp(`${componentIDPrefix}.${element2.minor}`);

    const isPlaceholder = originalElement?.id === originalElement?.query_id;
    if (element2.saved === true) {
      if (element2.duplicated === true) {
        return storedQueries?.find((el) => el?.position === element2.id);
      }
    } else {
      if (element2.duplicated) {
        return storedQueries?.find((el) => el?.position === element2.original_id);
      }
    }

    if (isPlaceholder) {
      if (!element2.saved) {
        return storedQueries?.find((el) => el?.position === element2.id);
      }
    }

    if (test === undefined) {
      if (!element2.saved) {
        return storedQueries?.find((el) => el?.position === element2.original_id);
      }
    }

    return test;
    // I have removed the id from here so this will not rerender on every change
  }, [element2, findStoredQueryProp, tablePlaceholderList, componentIDPrefix, newStoredQueries]);


  // console.log("This is the most important part", storedQueryDetails)

  if (!element2) return null;

  return (
    <div>
      {/* <div>{element2.id}</div> */}
      <PlaceholderContainer
        // key={`${element2.id + element2.query_id}`}
        key={`${element2.id}`}
        storedQueryDetails={storedQueryDetails}
        dataSources={dataSources}
        componentID={`${componentIDPrefix}.${element2.minor}`}
        isGraph={isGraph}
        // placeholderId={ element2.id}
        placeholderId={element2.original_id ?? element2.id}
        placeholderList={tablePlaceholderList}
        handleDuplicate={handleDuplicate}
        element={element2}
        parentIndex={index}
        orderLength={orderLength}
        moveElement={moveElement}
        setTablePlaceholderList={setTablePlaceholderList}
        isDragEnabled={isDragEnabled}
        setStoredQueries={setStoredQueries}
        removeItemFromList={removeItemFromList}
        savePlaceholderToOrder2={savePlaceholderToOrder2}
        updateTablePlaceholderDuplicate={updateTablePlaceholderDuplicate}
      />
    </div>
  );
};
