import { useCampaignContext } from 'context/CampaignContext';
import { useClientContext } from 'context/ClientContext';

import { DashboardTypes, country } from 'API';
import AuthContext from 'context/AuthContext';
import { useCampaignId, useClientCode, useSubCampaignId } from 'customHooks/useParameters';
import { useContext, useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';

import reactSelect from 'react-select';
import styled from 'styled-components';
import { Container } from './Header.styled';

interface spendProps {
  clientFilterList: Array<{ code: string; country: string } | undefined>;
  dashType?: DashboardTypes;
  setCurrentPage?: (e: 'welcome' | 'dashboardSelector') => void;
}

type CustomSelectType = {
  dashType?: DashboardTypes;
};

export const CustomSelect = styled(reactSelect) <CustomSelectType>`
  position: relative;
  display: block;
  width: 100%; /* ${({ dashType }) => (dashType !== DashboardTypes.HOME ? '' : ' !important')}; */
  margin-bottom: 4px;
  margin-right: 4px;
  font-size: 0.9rem;
  font-family: 'FontRegular', sans-serif;
  font-weight: normal;
  color: ${({ theme }) => theme.primaryGray} !important;
  background: ${({ theme }) => theme.placeholderBackground} !important;
  border-radius: 10px;
  z-index: 1;

  .react-select__menu {
    /* background-color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.placeholderBackground : '#1a1b1e'} !important; */
    background-color: ${({ theme }) => theme.placeholderBackground};
    font-size: 12px;
  }
  /* width */
  .react-select__menu-list::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  .react-select__menu-list::-webkit-scrollbar-track {
    /* background: ${({ dashType, theme }) => (dashType !== DashboardTypes.HOME ? theme.border : '#27292a')} !important; */
    background: ${({ theme }) => theme.border};
    border-radius: 10px;
  }
  /* Handle */
  .react-select__menu-list::-webkit-scrollbar-thumb {
    /* background: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.scrollThumb : '#c9cacc'} !important; */
    background: ${({ theme }) => theme.scrollThumb};
    border-radius: 10px;
  }

  .react-select__control {
    background: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.background : 'white'} !important;
    /* background: ${({ theme }) => theme.background}; */
    border: none;
    /* color: ${({ dashType, theme }) => (dashType !== DashboardTypes.HOME ? theme.secondaryGray : '#1a1b1e')} !important; */
    color: ${({ theme }) => theme.secondaryGray} !important;
  }
  .react-select__indicator {
    visibility: hidden;
  }

  .react-select__single-value {
    /* color: ${({ dashType, theme }) => (dashType !== DashboardTypes.HOME ? theme.primaryGray : '#c9cacc')} !important; */
    color: ${({ theme }) => theme.primaryGray} !important;
  }

  .react-select__value-container {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .react-select__indicator-separator {
    width: 0;
  }
  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    border-color:  ${({ theme }) => theme.secondaryGray};;
    /* border-color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.secondaryGray : theme.primaryGray} !important; */
  }
  .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    /* border-color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.secondaryGray : theme.primaryGray} !important; */
    border-color: ${({ theme }) => theme.secondaryGray};;
  }
  .react-select__control:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondaryGray};
    /* border-color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.secondaryGray : theme.primaryGray} !important; */
    border-color: ${({ theme }) => theme.secondaryGray};;
  }
  .react-select__control:hover .react-select__indicator {
    visibility: visible;
  }
  .react-select__option--is-selected {
    /* background-color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.secondaryGray : theme.primaryGray} !important; */
    background-color: ${({ theme }) => theme.secondaryGray};
  }
  .react-select__input-container {
    /* color: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME ? theme.secondaryGray : theme.primaryGray} !important; */
    color: ${({ theme }) => theme.secondaryGray} !important;
  }
`;

export const CampaignSelectComponent = (props: spendProps) => {
  const {
    setActiveOrganization,
    activeOrganization,
    organizationList,
    handleCountrySelection,
    setOrganizationList,
    organizationListWithCountry,
    hasMoreThanOneCountry,
    isOnlyDanish,
  } = useClientContext();
  const { campaignList, activeCampaign, dashType, subCampaignList } = useCampaignContext();
  // const [searchParams, setSearchParams] = useSearchParams();
  const [campaignID, setCampaignId, deleteCampID] = useCampaignId();
  const [subCamp, setSubCamp, deletesubCamID] = useSubCampaignId();
  const { user } = useContext(AuthContext);
  const [org, setOrg, deleteOrgID] = useClientCode();
  const { setCurrentPage, clientFilterList } = props;

  //CLIENT SELECt
  const handleClientSelection = (e: string) => {
    const findOrg = organizationList.find((element) => element?.OrganizationId === e);
    setOrg(e);
    setActiveOrganization(findOrg);
    setCurrentPage !== undefined && setCurrentPage('dashboardSelector');
  };

  const handleCampaignSelection = (campaignName: any) => {
    const selectedCampaign = campaignList?.find((e) => e?.campaignId === campaignName);
    if (selectedCampaign) {
      setCampaignId(selectedCampaign.campaignId?.toString());

      if (subCampaignList && subCampaignList.length > 0) {
        if (subCamp === 'none') {
          const firsItem = subCampaignList[0];
          const subcampaignId = firsItem.subcampaignId;
          if (subcampaignId) {
            setSubCamp(subcampaignId?.toString());
          }
        }
      }
    }
  };

  const handleSubcampaignSelection = (val: string) => {
    const selectedSubcampaign = subCampaignList?.find((e) => e.name === val);

    setSubCamp(val);
  };

  useEffect(() => {
    if (org !== 'none') {
      if (organizationList.length > 0) {
        const clientDetails = organizationList.find((e) => e?.OrganizationId === org);
        if (clientDetails === undefined) {
          alert(`You are not authorized to see this organization ${org}`);
          return;
        } else {
          setActiveOrganization(clientDetails);
          setCurrentPage !== undefined && setCurrentPage("dashboardSelector")
        }
      }
    }

  }, [org, organizationList, setActiveOrganization]);

  type ACdetails = { subcampaign: { [key: number]: any } };

  function checkPublishedSubcampaigns(el: ACdetails) {
    let res: any = [];
    dashType !== null && Object.entries(el?.subcampaign).forEach(([key, value]) => {
      if (key !== '-1') {
        if (value.published[dashType] === true) {
          res.push(true);
        }
      }
    });
    return res.some((e: any) => e === true);
  }

  const clientsDropdown =
    organizationListWithCountry &&
    organizationListWithCountry?.map((e) => {
      return {
        label: hasMoreThanOneCountry ? e?.Organization + ' (' + e?.country.countryEnum + ')' : e?.Organization,
        value: e?.OrganizationId,
      };
    });

  const campaignsDropdown =
    campaignList &&
    campaignList.map(e => {
      return {
        label: e?.campaign,
        value: e?.campaignId,
      };
    });

  const subCampaignDropdown =
    subCampaignList &&
    subCampaignList
      .filter((e) =>
        activeCampaign?.authorized === true
          ? e
          : e.subcampaignId !== null &&
          e.subcampaignId !== undefined &&
          activeCampaign?.subcampaign.hasOwnProperty(e.subcampaignId),
      )
      .map((e) => {
        return {
          label: e.name,
          value: e.subcampaignId,
        };
      });



  return (
    // <Container dashType={dashType}>
    <Container dashType={''}>
      <>
        {clientsDropdown.length <= 4 && dashType === DashboardTypes.HOME ? (
          <div className="buttonsListClients">
            {/* THIS IS ONLY IF THE LIST IS LESS THAN 4 AND IT WILL BE DISPLAYED AS BUTTONS */}
            {clientsDropdown.map((client) => {
              return (
                <button onClick={() => client.value !== undefined && handleClientSelection(client.value)}>
                  {client.label}
                </button>
              );
            })}
          </div>
        ) : (
          <>
            {(dashType === DashboardTypes.HOME /* || dashType === DashboardTypes.FLOWCHART */) && (
              <CustomSelect
                dashType={dashType}
                onChange={(sel: any) => handleClientSelection(sel.value)}
                options={clientsDropdown}
                value={clientsDropdown?.find((e) => e.value === org)}
                className="react-select-container"
                id="sel1"
                classNamePrefix="react-select"
              ></CustomSelect>
            )}
          </>
        )}
      </>

      {dashType !== DashboardTypes.HOME && dashType !== DashboardTypes.FLOWCHART && (
        <>
          {
            <CustomSelect
              options={campaignsDropdown}
              onChange={(sel: any) => handleCampaignSelection(sel.value)}
              value={campaignsDropdown?.find((e) => e.value === Number(campaignID))}
              id="sel2"
              className="react-select-container"
              classNamePrefix="react-select"
            ></CustomSelect>
          }

          {subCampaignList.length > 0 && (
            <CustomSelect
              options={subCampaignDropdown}
              onChange={(sel: any) => handleSubcampaignSelection(sel.value)}
              value={subCampaignDropdown?.find((e) => e.value === Number(subCamp))}
              className="react-select-container"
              id="sel3"
              classNamePrefix="react-select"
            ></CustomSelect>
          )}
        </>
      )}
    </Container>
  );
};
