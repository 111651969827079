import styled from 'styled-components';

interface Props {
  rowHeights: Record<string, number>;
}

interface PlanContainerProp {
  tab: string;
}

export const PlanContainer = styled.div<PlanContainerProp>`
  display: ${({ tab }) => (tab === 'plan' ? 'block' : 'none')};
  margin-top: 30px;
  max-width: calc(95vw - 80px);

  .planItems {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-between;
    gap: 5vw;
    .planItemContainer{
      border: 1px solid ${({ theme }) => theme.border};
      border-radius: 7px;
padding: 30px 40px;
    }
  }


  table {
    border-collapse: collapse;
    /* min-width: 80vw; */
  max-width: 90vw;


    .number {
      text-align: right;
    }
    .string {
      text-align: left;
    }
  }

  tr {
    font-family: 'FontRegular', sans-serif;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryGray};
    /* border-bottom: 1px solid ${({ theme }) => theme.border}; */
  }

  tbody > tr > td {
    padding: 8px 0;
    font-size: 12px;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontRegular', sans-serif;
  }
  th,
  th {
    /* padding: 12px 0; */
    padding:  0;
    font-size: 14px;
    text-align: left;
  }
  tr:hover > td {
    background-color: ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
  }

  td[data-value='awareness'] {
    background-color: red;
    border: red;
  }

    .loadingDot {
    animation: loadingDots 1s infinite;
    margin-left: 5px;
  }

  .loadingDot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loadingDot:nth-child(3) {
    animation-delay: 0.4s;
  } 

 

  @keyframes loadingDots {
  0%, 80%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  } 
`;

export const ReactTableNew = styled.div<Props>`
  

.saveButton{
  background-color: red;
  color: white;
  margin-right: 5px;
  /* width: 140px; */
  /* margin-right: 10px; */

  &:hover {
    background-color: #ac0101;
    /* border: 1px solid #ac0101; */
  }
}

.buttonCancel {
  border: 1px solid #dddddd;

  &:hover {
    background-color: #acacac;
    color: white;
  }
}

.buttonsFlex {
  display: flex;
  flex-direction: column;
}

.buttonCallToAction{
  background-color: #dddddd;
  /* width: 140px; */

  &:hover {
    background-color: #acacac;
    color: white;
  }
}

.css-10062bc-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 15px;
}

.css-rqrfwl-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 15px;
}

.headerColumn{
  text-wrap: pretty;
  margin: 5px;
  line-height: 1.5;
  margin-top: 0.5rem;
}

.my-table-class{
  word-wrap: break-word !important;
  max-width: 400px;
}

  .inputContainer {
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }
 
 .containerButtons {
    display: flex;
    /* width: 100% !important; */
    width: 200px;
    align-items: center;
    justify-content: space-between;
  }

  .MuiTableContainer-root {
    min-height: 150px;
  }

  .css-10gei56 {
    z-index: 0;
  }


  .MuiTableRow-root {
    line-height: ${({ rowHeights }) => rowHeights} !important; /* Adjust as needed */
    /* word-wrap: break-word; */
    word-break: break-all;
    /* overflow: auto; */
     z-index: 0; 
    position: relative;
    
  }

  tbody > tr > td {
    min-width: 55px;
    display: flex;
    align-items: flex-start;
    /* justify-content: center;  */
    /* width: 60px!important; */
    overflow: auto;
    text-wrap: balance;
  }

  .MuiBox-root {
width:fit-content
    /* background-color: red; */
  }

  .Mui-TableHeadCell-ResizeHandle-Wrapper{
    background-color: white;
   
  }

  .css-1cu920a-MuiPaper-root-MuiAlert-root {
    background-color: grey;
  }

  .css-di3982 {
    display: none;
  }

  .MuiPaper-elevation2 {
    box-shadow: none;
  }

  button {
    box-shadow: none;
  }

  .css-1jo1f9u-MuiButtonBase-root-MuiIconButton-root {
    border: 0px solid red !important;

    .css-i4bv87-MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
    }
  }

  .MuiInputBase-input {
    width: 100px;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
    overflow: auto;
    text-wrap: balance;
  }

  .MuiCollapse-vertical {
    display: none;
  }

  .css-pwcg7p-MuiCollapse-root {
    display: none;
  }

  .css-epvm6 {
    display: none;
  }

  .MuiTableCell-sizeMedium {
    min-height: 48px;
    border: 1px solid #f8f8f8;

    /* border-radius: 7px; */
  }

  /* .css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background-color: #d6d6d6;
} */

.datepicker{
    width: 100%;
    
   
}
.react-datepicker-popper {
  z-index: 99999!important;
  background-color: red;
  color: purple

}

`;
