import { ModulContainer } from 'components/Main/table/TableStyles';
import { usePortalContext } from 'context/PortalContext';
import React, { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useTheme } from 'styled-components';
import { HeadModul, IconButton, Modal, ModulHeader } from './DrawerModal Styled';
import { useSearchParams } from 'react-router-dom';
import Brief from './Main/Brief/Brief';
import Summary from './Main/Summary/Summary';
import { useCampaignContext } from 'context/CampaignContext';
import { VscEdit } from 'react-icons/vsc';
import { useTab } from 'customHooks/useParameters';
import PlanComponent from './Main/Plan/PlanContainer';
import { europinzedDate } from 'methods/dateMethods';

type Props = {};

const DrawerModal = (props: Props) => {
  const { drawerIsOpen, setDrawerIsOpen, setEditCampaign } = usePortalContext();
  const { activeCampaign, fetchedPortalData } = useCampaignContext();

  // const links = ["brief", /* "plan", */ "summary"]

  const links = ['brief', 'plan', 'summary'];
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const [tab] = useTab();
  const [approvedBudget, setApprovedBudget] = React.useState(null as null | number);
  const { budgeBrief } = usePortalContext();

  const ll = [
    {
      available: true,
      name: 'brief',
      title: 'Brief',
    },
    {
      available: activeCampaign?.authorized
        ? true
        : !activeCampaign?.authorized &&
          activeCampaign?.status !== 'CREATED' &&
          activeCampaign?.status !== 'NOT_BRIEFED'
          ? true
          : false,
      name: 'plan',
      title: 'Plan',
    },
    {
      available: activeCampaign?.authorized
        ? true
        : !activeCampaign?.authorized &&
          (activeCampaign?.status === 'CAMPAIGN_PUBLISHED' ||
            activeCampaign?.status === 'CAMPAIGN_PARTLY_PUBLISHED' ||
            (Date.now() > new Date(activeCampaign?.endDate as string).getTime()
              || (Date.now() > new Date(activeCampaign?.startDate as string).getTime() && Date.now() < new Date(activeCampaign?.endDate as string).getTime()))



          )
          ? true
          : false,
      name: 'summary',
      title: 'Summary',
    },
  ];




  return (
    <>
      {/* {fetchedPortalData.length > 1 && ( */}
      <Modal>
        <HeadModul>
          <ModulHeader>
            <div className="first">
              <div className="links">

                {ll.map((link) => (
                  <IconButton
                    style={{
                      color: tab === link.name ? theme.brand : !link.available ? theme.border : '',
                      borderBottom: tab === link.name ? `1px solid ${theme.brand}` : '',
                      borderRadius: '0',
                    }}
                    key={link.name}
                    type="button"
                    disabled={!link.available}
                    onClick={() => {
                      searchParams.set('tab', link.name);
                      setSearchParams(searchParams);
                    }}
                  >
                    {link.name.charAt(0).toUpperCase() + link.name.slice(1)}
                  </IconButton>
                ))}
              </div>
              <h1 style={{ display: 'flex', flexDirection: 'row' }}>
                <div>       {activeCampaign?.campaign}
                  <span className='campaignID'>{`(${activeCampaign?.campaignId})`}</span></div>
                <span style={{ alignSelf: 'self-end' }}>
                  <IconButton
                    /* className='someButton' */ style={{
                      fontSize: '12px',
                      marginLeft: '15px',
                      padding: '5px 6px 3px 6px',
                    }}
                    onClick={() => {
                      setDrawerIsOpen(false);
                      setEditCampaign(true) /* ; searchParams.delete("tab") */;
                      setSearchParams(searchParams);
                    }}
                  >
                    <VscEdit />
                    Edit
                  </IconButton>
                </span>
              </h1>
            </div>

            <div className="second">
              <button
                type="button"
                className="secondClose"
                onClick={() => {
                  setDrawerIsOpen(false);
                  searchParams.delete('tab');
                  searchParams.delete('campaignID');
                  setSearchParams(searchParams);
                }}
              >
                <IoMdClose className="closeIcon" />
              </button>

              {/*         <IconButton className='editCampaign' onClick={() => {
                            setDrawerIsOpen(false); setEditCampaign(true); searchParams.delete("tab"); setSearchParams(searchParams)
                        }}><VscEdit />Edit campaign</IconButton> */}
            </div>
            <div className="third">
              <h2>
                Date: <span>{europinzedDate(activeCampaign?.startDate as string)}</span> -{' '}
                <span>{europinzedDate(activeCampaign?.endDate as string)}</span>
              </h2>
              {/*           <div className='links'>
                            <IconButton>Comments</IconButton>
                            <IconButton>Additional Info 1</IconButton>
                            <IconButton>Additional Info 2</IconButton>
                        </div> */}

              {tab !== 'brief' && (
                <div className="third">
                  <h2>
                    <span>
                      {
                        // budgeBrief === null ?

                        <>
                          {activeCampaign?.briefed_amount !== null && 'Briefed Amount:' + ' '}{' '}
                          <span>
                            <b>
                              {isNaN(Number(activeCampaign?.briefed_amount)) ||
                                Number(activeCampaign?.briefed_amount) === 0
                                ? activeCampaign?.briefed_amount
                                : Number(activeCampaign?.briefed_amount).toLocaleString()}
                            </b>
                          </span>{' '}
                          <span>
                            <b>{activeCampaign?.briefed_currency}</b>
                          </span>
                        </>
                        // : (
                        //   <>
                        //     {budgeBrief?.budget !== null && 'Briefed Amount:' + ' '}
                        //     <span>
                        //       <b>{budgeBrief?.budget}</b>
                        //     </span>{' '}
                        //     <span>
                        //       <b>{budgeBrief?.budget !== null && budgeBrief?.currency}</b>
                        //     </span>
                        //   </>
                        // )
                      }
                    </span>
                  </h2>
                </div>
              )}
            </div>

            {/*    <div className='fourth'>
                        <h2>TAGS</h2>
                        <div className='tagsContainer'>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>
                            <Selections>Tag1</Selections>

                        </div>
                    </div> */}
          </ModulHeader>

          {tab === 'brief' && (
            <ModulContainer>
              <Brief></Brief>
            </ModulContainer>
          )}

          <ModulContainer>
            <PlanComponent tab={tab} setApprovedBudget={setApprovedBudget} />
          </ModulContainer>

          {tab === 'summary' && (
            <ModulContainer>
              <Summary approvedBudget={approvedBudget}></Summary>
            </ModulContainer>
          )}
        </HeadModul>
      </Modal>
      {/* )} */}
    </>
  );
};

export default DrawerModal;
