import { queryInput } from "API"
import OrderModal from "components/Main/table/OrderModal"
import PreviewModal from "components/Main/table/PreviewModal"
import { useCampaignContext } from "context/CampaignContext"
import html2canvas from "html2canvas"
import { set, values } from "lodash"
import React, { useContext } from "react"
import { useEffect, useState } from "react"
import { useTable } from "react-table"
import styled from "styled-components"
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { CommentsContext } from "context/CommentsContext"
import InputContext from "context/InputContext"
import Placeholder from "react-select/dist/declarations/src/components/Placeholder"
import { useHTML2Canvas } from "customHooks/useHTML2Canvas"
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import pptxgen from "pptxgenjs";
export const Styles = styled.div`
    max-width: 100%;
    overflow-x: scroll;
 //   border: 1px solid blue;
    &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.placeholderBackground};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollThumb};
    
  }
  input {
font-size: 12px;
padding: 8px;
border-radius: 7px;
width: 20vw;
border: 1px solid ${({ theme }) => theme.border};
color: ${({ theme }) => theme.primaryGray};
  }
  textarea:focus,input:focus {
    outline: 1px solid ${({ theme }) => theme.primaryGray};
}

.title {
    font-family: "FontRegular";
    font-size: 1.2vw;
    margin: 1vw 0;
    margin-left: 1vw;
}
`

export const TableExport = styled.table`
display: none;
 table {
    border-collapse: collapse;
    /* min-width: 92vw; */

    .number {
      text-align: right;
    }
    .string {
      text-align: left;
    }
  }

  tr {
    font-family: 'FontRegular', sans-serif;
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryGray};
    border-bottom: 1px solid ${({ theme }) => theme.border};
  }

  tbody > tr > td {
    padding: 3px;
    font-size: 8px;
    color: ${({ theme }) => theme.primaryGray};
    font-family: 'FontRegular', sans-serif;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    border-collapse: collapse;
  }
  th,
  tbody > tr:nth-last-child(1) > td {
    color: ${({ theme }) => theme.primaryGray};
    font-size: 12px;
    font-family: 'FontBold', sans-serif;
    border-bottom: none;
  }
  th {
    /* padding: 12px; */
    font-size: 12px;
  }
  tr:hover > td {
    background-color: ${({ theme }) => theme.background};
  }
  thead > tr > th {
    border-bottom: 1px solid ${({ theme }) => theme.border};
    border-collapse: collapse;
    padding: 5px 3px;
    }
`

type Props = {
    data: any,
    page: any;
    title: any
    setTitle(title: any): any
    conversionsData: any;
    userMainQuery: any;
    userCCQuery: any;
    componentID?: string;
    handleChanges?(secondQuery: any, currentConversionsQueryCOPY: any): any
}



const DataTable = (props: Props) => {
    const columnData: any[] = [];
    let temData: any = []
    const { dashType } = useCampaignContext()
    const [displayOrderModal, setDisplayOrderModal] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [clickedHeader, setClickedHeader] = useState()
    const [activeObject, setActiveObject] = useState<any>()
    const [currentQueryCOPY, setCurrentQueryCOPY] = useState<queryInput | undefined>(undefined)
    const [mainReference, setMainReference] = useState<queryInput | undefined>(undefined)
    const [testObject, setTestObject] = useState<any>(undefined)
    const [currentConversionsQueryCOPY, setCurrentConversionsQueryCOPY] = useState<queryInput | undefined>(undefined)
    const [displayOrderConversionsModal, setDisplayOrderConversionsrModal] = useState(false)
    const { commentsList } = useContext(CommentsContext);
    const { capture, updatePPT } = useContext(InputContext)
    props.data?.forEach((element: any) => {

    /*     element &&  */Object.keys(element).forEach(function (k) {
        if (element[k] === "Infinity" || element[k] === "NaN") {
            element[k] = "-";
        }
    });
    }
    )


    const headers = [] as any[]

    temData = [...props.data]
    if (dashType === "TV") {
        const lookupTable = ["Bauer Media National", "DRR", "TV 2", "TV 2 News", "Viaplay Group", "Discovery", "Discovery Networks", 'Warner Bros Discovery', undefined]
        temData.sort((a: any, b: any) => lookupTable.indexOf(a.Station) - lookupTable.indexOf(b.Station))

    }
    // dodao sam ovaj every i gore jos jedan element &&

    if (temData.length > 0/*  && temData.every((el: any) => el !== undefined) */) {

        const tempDataCopy = JSON.parse(JSON.stringify(temData))
        const mostkeys = tempDataCopy.sort(
            (a: any, b: any) => Object.keys(b).length - Object.keys(a).length
        )[0];

        const alignArrays = Object.entries(mostkeys).map((el: any) => el.map((element: any) => element === null ? 0 : element))
        const alignObject = Object.fromEntries(alignArrays)


        Object.keys(mostkeys).forEach((key: any, i) => {
            headers.push(key)
            const object: any = {
                Header: key,
                accessor: (data: any) => data[key],
                Footer: (info: any) => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum: any, row: { values: { visits: any } }) => row.values[key as keyof typeof values] + sum, 0),
                        [info.rows]
                    )
                    return <> {isNaN(total) ? "-" : new Intl.NumberFormat('en-DK', { maximumFractionDigits: 2 }).format(total)}</>
                },
                //kita: "number"
                kita: alignObject[key] === "-" ? "number" : typeof (alignObject[key])
            }
            columnData.push(object)
        })

    }


    const data = React.useMemo(
        () => temData,
        [props.data]
    )
    const columns = React.useMemo(
        () => columnData,
        [props.data]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })




    // CREATE QUERY COPY
    useEffect(() => {
        if (props.userMainQuery) {
            setCurrentQueryCOPY(JSON.parse(JSON.stringify(props.userMainQuery)))
            const kopije = JSON.parse(JSON.stringify(props.userMainQuery))
            kopije.query.calculatedMetrics?.map((el: any) => Object.assign(el, { column: el.name }))
            setMainReference(kopije)

            const testingStuff = JSON.parse(JSON.stringify(props.userMainQuery))?.query.metrics
            testingStuff?.map((metric: any) => Object.assign(metric, { active: true }))
            setTestObject(testingStuff)
        }
    }, [props.userMainQuery])

    // CREATE CONVERSIONS QUERY COPY
    useEffect(() => {
        if (props.userCCQuery) {
            setCurrentConversionsQueryCOPY(JSON.parse(JSON.stringify(props.userCCQuery)))
        }
    }, [props.userCCQuery])



    const openClose = (value: any) => {
        setActiveObject({})
        let QcalcMetrics = mainReference?.query.calculatedMetrics
        let QMetrics = testObject
        let QDimensions = currentQueryCOPY?.query.detailedDimensions


        if (QcalcMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QcalcMetrics.find((x: any) => x.name === value))
        } else if (QcalcMetrics?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QcalcMetrics.find((x: any) => x.column === value))
        }
        else if (QMetrics?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.name === value))
        } else if (QMetrics?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QMetrics.find((x: any) => x.column === value))
        }

        else if (QDimensions?.find((x: any) => x.name === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.name === value))
        } else if (QDimensions?.find((x: any) => x.column === value) !== undefined) {
            setActiveObject(QDimensions.find((x: any) => x.column === value))
        }
        setHidden(!hidden)
        setClickedHeader(value)
    }


    useEffect(() => {
        if (props.userCCQuery === undefined && currentQueryCOPY !== undefined) {
            if (JSON.stringify(props.userMainQuery) !== JSON.stringify(currentQueryCOPY)) {
                props.handleChanges && props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        } else if (currentConversionsQueryCOPY !== undefined && currentQueryCOPY !== undefined) {
            if ((JSON.stringify(props.userMainQuery) !== JSON.stringify(currentQueryCOPY)) || (JSON.stringify(props.userCCQuery) !== JSON.stringify(currentConversionsQueryCOPY))) {


                props.handleChanges && props.handleChanges(currentQueryCOPY, currentConversionsQueryCOPY)
            }
        }
    }, [hidden, displayOrderModal, displayOrderConversionsModal])

    const [edit, setEdit] = useState(false)


    const handleSave = () => {
        setEdit(!edit)
    }




    const tableRef: any = React.useRef()
    const returnObject = useHTML2Canvas(capture, tableRef.current, props.componentID as string, props.title, commentsList?.find(el => el.placeholder === props.componentID)?.comment ?? "", columns, data)
    useEffect(() => {
        if (returnObject) {
            // updatePDFelements(returnObject)
            updatePPT(returnObject)
            // updatePPT({ placeholder: props.componentID, table: tableRef.current })
        }
    }, [returnObject])

    const pero = props.componentID
    const nekiid = `id${props.componentID?.replace(/\./g, '0')}`

    // const handlePPT = () => {
    //     let pptx = new pptxgen();
    //     pptx.tableToSlides(nekiid as string, { autoPage: true });
    //     pptx.writeFile({ fileName: "html2pptx-demo.pptx" });
    // }




    const TableforExport = <TableExport id={nekiid} ref={tableRef}  {...getTableProps()}  >
        <thead >
            {headerGroups.map(headerGroup => {

                return (


                    <tr {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                className={column.kita}
                            >
                                {column.render(
                                    "Header"
                                )}
                            </th>
                        ))}
                    </tr>
                )
            })}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row)
                return (
                    <tr className="pero" {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return (
                                <td
                                    className={(typeof (cell.value) !== "string") || cell.value === "-" ? "number" : "string"}
                                    {...cell.getCellProps(

                                    )}

                                >
                                    { // @ts-ignore

                                        typeof (cell.value) !== "number" ? cell.render('Cell') : new Intl.NumberFormat('en-DK').format(cell.value)}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
        </tbody>

    </TableExport>


    return (
        props.data.length > 0 ? <Styles>
            <div style={{ marginBottom: "1vw", textAlign: "left" }}>
                {!edit && <h1 className="title" onClick={() => props.page === 6 ? setEdit(!edit) : null} >{props.title === "Add title here" && props.page === 7 ? "" : props.title} </h1>}

                {edit && <h1 className="title"><input value={props.title} type="text" onChange={(e) => props.setTitle && props.setTitle(e.target.value)} /> <button style={{ width: "fit-content" }} type='button' onClick={handleSave}>SAVE</button></h1>}
            </div>
            {
                props.page === 6 && <>

                    <table {...getTableProps()}  >
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className={column.kita}
                                            style={{

                                                fontWeight: 'bold',
                                                cursor: "pointer"

                                            }}

                                            onClick={() => openClose(column.render(
                                                "Header"
                                            ))}
                                        >
                                            {column.render(
                                                "Header"
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr  {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    {...cell.getCellProps(

                                                    )}
                                                    className={(typeof (cell.value) !== "string") || cell.value === "-" ? "number" : "string"}
                                                    style={{
                                                        padding: '10px',

                                                    }}
                                                >
                                                    { // @ts-ignore
                                                        typeof (cell.value) !== "number" ? cell.render('Cell') : new Intl.NumberFormat('en-DK').format(cell.value)}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                    <button type="button" style={{ width: "fit-content", fontSize: ".7vw", margin: "10px 5px" }} onClick={() => { setDisplayOrderModal(!displayOrderModal) }}>CHANGE COLUMN ORDER</button>
                </>
            }


            {
                props.page === 7 &&
                <>


                    <table ref={tableRef}  {...getTableProps()}  >
                        <thead >
                            {headerGroups.map(headerGroup => {

                                return (


                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps()}
                                                className={column.kita}
                                            >
                                                {column.render(
                                                    "Header"
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                )
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr className="pero" {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    className={(typeof (cell.value) !== "string") || cell.value === "-" ? "number" : "string"}
                                                    {...cell.getCellProps(

                                                    )}

                                                >
                                                    { // @ts-ignore

                                                        typeof (cell.value) !== "number" ? cell.render('Cell') : new Intl.NumberFormat('en-DK').format(cell.value)}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                    {TableforExport}
                </>
            }
            {
                hidden &&
                <PreviewModal
                    hidden={hidden}
                    openClose={openClose}
                    activeObject={activeObject}
                    data={props.data}
                    clickedHeader={clickedHeader}
                    mainReference={mainReference}
                    currentQueryCOPY={currentQueryCOPY}
                    currentQuery={props.userMainQuery}
                    setCurrentQueryCOPY={(data: any) => setCurrentQueryCOPY(data)}
                    testObject={testObject}
                    currentConversionsQueryCOPY={currentConversionsQueryCOPY}
                    currentConversionsQuery={props.userCCQuery}
                    // setChartMetrics={props.setChartMetrics}
                    conversionsData={props.conversionsData}></PreviewModal>
            }
            {
                displayOrderModal &&
                <OrderModal
                    displayOrderModal={displayOrderModal}
                    setDisplayOrderModal={(toggle: any) => setDisplayOrderModal(toggle)}
                    currentQueryCOPY={currentQueryCOPY}
                    data={props.data}
                ></OrderModal>
            }

            {/*  {displayOrderConversionsModal &&
                <OrderModalConversions
                    displayOrderConversionsModal={displayOrderConversionsModal}
                    setDisplayOrderConversionsrModal={(toggle: any) => setDisplayOrderConversionsrModal(toggle)}
                    currentConversionsQueryCOPY={currentConversionsQueryCOPY}
                    data={props.data}
                ></OrderModalConversions>
            } */}
        </Styles > : <p>THERE IS NO DATA TO DISPLAY</p>



    )
}

export default DataTable

