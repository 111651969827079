import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
//import { Auth } from 'aws-amplify';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { DarkButton } from 'components/style/Global';
import { ReactComponent as SVG_LOGIN } from '../../assets/SVG/login.svg';
import { ReactComponent as SVG_LOGOUT } from '../../assets/SVG/logout.svg';
import { NavItem } from './Aside.styles';

interface Props {
  wrapper: any
}

export const LoginComponent = (props: Props) => {
  const { user } = useContext(AuthContext);




  const login = async (e: any) => {
    e.preventDefault();

    // Get the current URL
    const currentUrl = window.location.href;

    console.log(e);
    //Auth.federatedSignIn()
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito, customState: currentUrl })
      .then((data) => {
        console.log(data);
      })
    // history.replace(from);
    // setRedirectToPreviousRoute(true);
    // fakeAuth.authenticate(() => {
    //   history.replace(from);
    // });
  };

  const logout = (e: any) => {
    e.preventDefault();
    Auth.signOut();
  };

  return (
    // eslint-disable-next-line no-script-url
    <NavItem className="log" /* href="#" */ onClick={(e: any) => (!user ? login(e) : logout(e))}>

      {props.wrapper === "noWrapper" ?
        !user ? <><SVG_LOGIN className="svg" /> <h4 className='navigationTitle'>Log in</h4></> : <> <SVG_LOGOUT className="svg" /><h4 className='navigationTitle'>Log out</h4></> : !user ? <DarkButton style={{ alignSelf: "center" }}>Log in</DarkButton> : <DarkButton style={{ alignSelf: "center" }}>Log out</DarkButton>}
    </NavItem>
  );
};
