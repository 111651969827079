
import { InputContainer, Select } from '../style/BriefInputStyle'
import { enrichedBriefTemplateSectionSubColumns, enrichedbriefTemplateSectionColumns } from '../CustomHook/useBriefDataFromClient'
import { briefTemplateSectionColumns, briefTemplateSubColumn } from 'API'
import { useEffect } from 'react'

type Props = {
  inputProps: briefTemplateSectionColumns | briefTemplateSubColumn,
  onChange: (e: any) => void,
  name?: string,
  value: any

}


const SelectInput = (props: Props) => {
  const { inputProps, onChange } = props
  let { value } = props
  const { id, name } = inputProps

  // const optionsCurrency = ["DKK", "SEK", "NOK", "EUR", "USD"]
  // const optionsType = ["CTC", "Media net"]


  const options = {
    optionsCurrency: ["DKK", "SEK", "NOK", "EUR", "USD"],
    optionsType: ["CTC", "Media net"],
    optionsVat: ["Excluded", "Included"],
  }



  /*   if (!value || value === null) {
      value = options[`options${name}` as keyof typeof options][0];
      onChange({ e: value, id: id });
    } */


  return (
    <InputContainer>
      <Select value={value} onChange={(e) => onChange({ e: e.target.value, id: id })}>
        <option value=""></option>
        {options[`options${name}` as keyof typeof options]?.map(option => (
          <option key={option} value={option}>{option} </option>
        ))}
      </Select>
    </InputContainer>
  )
}

export default SelectInput