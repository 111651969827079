import { useUpdateRow } from '../useFetchPlanData';
import { Environment, FlowchartCampaignStatus } from 'API';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import { IActiveOrganization } from 'context/ClientContext';
import { EditedList } from '../PlanItem';
import { useState } from 'react';
import ErrorModal from '../Modals/ErrorModal';
import { usePortalContext } from 'context/PortalContext';
import { useUpdateCampaignStatus } from '../../Brief/CustomHook/useUpdateCampaignStatus';
import Switch from 'react-switch';
import { updateCampaign } from 'graphql/mutations';
import { DarkButton } from 'components/style/Global';
interface Props {
  EditButton: boolean;
  validationErrors: Record<string, string | undefined>;
  setEditButton: (value: boolean) => void;
  editedUsers: Record<string, any>;
  getEnvironment: Environment;
  activeCampaign: ACdetails;
  activeOrganization: IActiveOrganization;
  editedList: EditedList;
  setIsRowAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  data?: any[];
  setData?: React.Dispatch<React.SetStateAction<any[]>>;
}

const SaveButton = (props: Props) => {
  const {
    EditButton,
    setEditButton,
    getEnvironment,
    activeCampaign,
    activeOrganization,
    editedList,
    setIsRowAdded,
    data,
    setData,
  } = props;
  const [callUpdateApi] = useUpdateRow(getEnvironment, activeCampaign, activeOrganization);
  const [error, setError] = useState<string | null>(null);
  const { budgetCTC, budgetApproved } = usePortalContext();
  const [UpdateCampaign] = useUpdateCampaignStatus();
  const { campaignList, setPermanentCampaignList, permanentCampaignList } = useCampaignContext();

  const handleSaveUsers = async () => {
    if (
      activeCampaign?.recommended_budget_ctc !== budgetCTC?.budget ||
      activeCampaign?.approved_budget !== budgetApproved?.budget
    ) {
      const newCampaign = {
        ...activeCampaign,
        recommended_budget_ctc: budgetCTC?.budget,
        approved_budget: budgetApproved?.budget,
      } as ACdetails;

      const newCampaignList = campaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];
      setPermanentCampaignList(newCampaignList);
    }

    let newColumn = [];


    for (const [key, value] of Object.entries(editedList)) {
      for (const [key2, value2] of Object.entries(value)) {
        if (value2 !== '' && key2 !== 'section' && key2 !== 'rowId') {
          const newValue = {
            RowId: value.rowId,
            ColumnId: key2,
            Value: {
              StringValue: value2,
            },
          };

          newColumn.push(newValue);
        }
      }
    }

    // data !== undefined && console.log('this is amazing to see how it works', editedList, data);

    // const updateData = data?.map((row) => {
    //   console.log("this is amazing to see how it works",   editedList[row.rowId] )
    //   editedList[row.rowId] &&
    //     Object.keys(editedList[row.rowId]).map((key) => {
    //       if (key !== 'section' && key !== 'rowId') {
    //         row[key] = editedList[row.rowId][key];
    //       }
    //     });

    //     console.log("this is amazing to see how it works",   editedList[row.rowId] )

    //   return row;
    // });
    // console.log("this is amazing to see how it works", updateData)

    const updateResult = await callUpdateApi(newColumn);
    setIsRowAdded && setIsRowAdded(true);

    if (updateResult === 'updated') {
      setError(null);
      setEditButton(!EditButton);
    } else {
      setError(updateResult);
    }
  };

  const handleSwitchButton = () => {
    if (activeCampaign?.status === 'PLANNING_DONE' || activeCampaign?.status === 'PLANNED') {
      UpdateCampaign(activeCampaign, FlowchartCampaignStatus.PLANNING_STARTED);
      const campaign = permanentCampaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
      const newCampaign = {
        ...campaign,
        status: 'PLANNING_STARTED',
      } as ACdetails;

      const newCampaignList = permanentCampaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];
      setPermanentCampaignList(newCampaignList);
    } else if (activeCampaign?.status === 'PLANNING_STARTED') {
      UpdateCampaign(activeCampaign, FlowchartCampaignStatus.PLANNING_DONE);
      const campaign = permanentCampaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
      const newCampaign = {
        ...campaign,
        status: 'PLANNING_DONE',
      } as ACdetails;

      const newCampaignList = permanentCampaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];
      setPermanentCampaignList(newCampaignList);
    }
  };

  return (
    <>
      {!EditButton ? (
        <div className="buttonsFlex">
          {activeCampaign?.authorized && <button style={{ marginTop: "20px " }} className="buttonCallToAction" onClick={() => setEditButton(!EditButton)}>
            Edit Plan
          </button>}
          {activeCampaign?.authorized && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Switch
                disabled={
                  !(
                    activeCampaign?.status === 'PLANNING_DONE' ||
                    activeCampaign?.status === 'PLANNED' ||
                    activeCampaign?.status === 'PLANNING_STARTED'
                  )
                }
                onChange={() => {
                  handleSwitchButton();
                }}
                checked={
                  activeCampaign?.status === 'PLANNING_DONE' ||
                  activeCampaign?.status === 'PLANNED' ||
                  activeCampaign?.status === 'CAMPAIGN_PARTLY_PUBLISHED' ||
                  activeCampaign?.status === 'CAMPAIGN_PUBLISHED'
                }
              ></Switch>
              <h1 style={{ marginBottom: '0' }}>
                {activeCampaign?.status === 'PLANNING_DONE' ||
                  activeCampaign?.status === 'PLANNED' ||
                  activeCampaign?.status === 'CAMPAIGN_PARTLY_PUBLISHED' ||
                  activeCampaign?.status === 'CAMPAIGN_PUBLISHED'
                  ? 'Visible to client'
                  : 'Not visible to client'}
              </h1>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            <DarkButton
              style={{ marginTop: "30px ", float: 'right' }}
              disabled={error !== null && true}
              // onClick={() => handleSaveUsers()}
              onClick={() => setEditButton(!EditButton)}
            >
              {/* Save */}
              Exit Edit Mode
            </DarkButton>
            <ErrorModal error={error} setError={setError} type={'update'}></ErrorModal>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveButton;
