import { DashboardTypes, subCampaignResponse } from 'API';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCampaignId, useSubCampaignId } from './useParameters';
import { fetchSubCampaignsFunction } from 'methods/fetchSubCampaigns';

export const useActiveSubCampaign = (
  dashType: DashboardTypes | null,
): readonly [subCampaignResponse | undefined, subCampaignResponse[], Error | undefined] => {
  const [campaignID] = useCampaignId();

  const [activeSubCampaign, setActiveSubCampaign] = useState<subCampaignResponse | undefined>({} as subCampaignResponse);
  const [subCampaignList, setSubCampaignList] = useState<subCampaignResponse[]>([] as subCampaignResponse[]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ getSubCampaignId ] = useSubCampaignId()
  const navigate = useNavigate();

  const searchParam = 'subcampaignID';

  const setId2 = (value: string, link: string): void => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set(searchParam, value);
    const finalSearch = newSearchParams.toString();
    navigate(link + '?' + finalSearch);
  };

  let activeSubCampaignId = searchParams.get(searchParam) ?? undefined;

  useEffect(() => {
    const fetchSubCampaignsList = async () => {
      // if the campaignID is none, set the subCampaignList to an empty array
      if (campaignID === 'none' || (dashType !== DashboardTypes.TV && dashType !== DashboardTypes.RADIO)) {
        setSubCampaignList([]);
        return;
      }

      // fetch the subCampaigns
      const result = await fetchSubCampaignsFunction(campaignID, dashType);
      activeSubCampaignId = undefined;

      if (result && result.length > 0) {
        const filteredResult = result.filter((item): item is subCampaignResponse => item !== null);
        setSubCampaignList(filteredResult);

        if(result !== null) {
            testCall(filteredResult);
        }
      } else {
        setSubCampaignList([]);
      }
    };

    fetchSubCampaignsList();
  }, [campaignID, dashType]);

  const testCall = (subCampaignList: Array<subCampaignResponse>) => {
    if (activeSubCampaignId !== undefined || getSubCampaignId !== "none") {
      return;
    }
  
    const firstSubCampaign = subCampaignList[0];
    if (!firstSubCampaign) {
      return;
    }
  
    const firstSubCampaignId = firstSubCampaign.subcampaignId;
    if (!firstSubCampaignId) {
      return;
    }
  
    const portalPath = dashType === DashboardTypes.TV ? '/reporting/TVPortal' :
                       dashType === DashboardTypes.RADIO ? '/reporting/radioPortal' : null;
  
    if (portalPath) {
      setId2(String(firstSubCampaignId), portalPath);
    }
  };

  // if the activeSubCampaignId is 'none' and the subCampaignList is not empty, set the activeSubCampaign to the first subCampaign in the list
  // else if the activeSubCampaignId is not 'none' and the subCampaignList is not empty, set the activeSubCampaign to the subCampaign with the id of activeSubCampaignId

  useEffect(() => {

    const noSubCampaign: subCampaignResponse = {
      __typename: 'subCampaignResponse',
      subcampaignId: -1,
      name: undefined,
      startDate: undefined,
      endDate: undefined,
      mediaGroup: undefined,
     }

    if ( subCampaignList.length === 0 && dashType !== DashboardTypes.HOME ) {
      setActiveSubCampaign(noSubCampaign)
      return;
    }

    if ( subCampaignList.length === 0 && dashType !== DashboardTypes.HOME ) {
        setActiveSubCampaign(undefined)
        return;
      }

    if (subCampaignList.length === 0) {
      setActiveSubCampaign(undefined)
      return;
    }

    const firstSubCampaign = subCampaignList[0];
    const firstSubCampaignId = firstSubCampaign.subcampaignId;

    if (!firstSubCampaignId) {
      return;
    }

    if (activeSubCampaignId === 'none' && (dashType === DashboardTypes.TV || dashType === DashboardTypes.RADIO)) {
      setActiveSubCampaign(firstSubCampaign);
      if (dashType === DashboardTypes.TV) {
        setId2(String(firstSubCampaignId), '/reporting/TVPortal');
      } else if (dashType === DashboardTypes.RADIO) {
        setId2(String(firstSubCampaignId), '/reporting/radioPortal');
      }
      return;
    }

    if (activeSubCampaignId !== 'none' && (dashType === DashboardTypes.TV || dashType === DashboardTypes.RADIO)) {
      const activeSubCamapign = subCampaignList.find(
        (subCampaign) => subCampaign.subcampaignId === Number(activeSubCampaignId),
      );
      if (activeSubCamapign) {
        setActiveSubCampaign(activeSubCamapign);
      }
    }
  }, [activeSubCampaignId, subCampaignList, dashType]);

  return [activeSubCampaign, subCampaignList, error];
};
