const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


export function TV22(data: any, TVStationSelector: string, theme: any) {
    const theData = [] as any;
    const categ = [] as any;
    const lookupTable = ["Bauer Media National", "DRR", "TV 2", "TV 2 News", "Viaplay Group", "Discovery", "Discovery Networks", 'Warner Bros Discovery']



    // create timeslot property 
    const sorted = data.sort((a: any, b: any) => (a.timeslot_start > b.timeslot_start) ? 1 : ((b.timeslot_start > a.timeslot_start) ? -1 : 0))
    const dataArray = sorted.map((obj: any) => ({
        ...obj,
        timeslot: `${obj.timeslot} (${obj.timeslot_start}-${obj.timeslot_end})`
    }));

    if (TVStationSelector === "All stations") {
        const trpSum = dataArray.map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
        const dataArraySUM = dataArray.map((e: any) => {
            return { ...e, trp_primary_target: ((e.trp_primary_target / trpSum) * 100) }
        })
        let result = dataArraySUM.reduce(
            (result: any, item: any) => ({
                ...result,
                [item["network"]]: [
                    ...(result[item["network"]] || []),
                    item,
                ]
            }),
            {}
        )
        //sort objeect by station names from lookup table
        result = Object.fromEntries(Object.entries(result).sort((a: any, b: any) => lookupTable.indexOf(a[0]) - lookupTable.indexOf(b[0])))

        for (const [key, value] of Object.entries(result)) {
            const dataSeries = (value as Array<any>).map((e: any) => e.trp_primary_target)
            const ser = {
                name: key,
                type: "column",
                data: dataSeries,
                color: colorArray[theData.length],
                yAxis: 0,
            };
            theData.push(ser);



        }

        categ.push(...(Object.values(result)[0] as any).map((e: any) => e.timeslot))

    } else {
        const resultFiltered = dataArray.filter((el: any) => el.network === TVStationSelector)
        if (resultFiltered.length > 0) {
            const trpSum = resultFiltered.map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
            const result = resultFiltered.map((e: any) => {
                return { ...e, trp_primary_target: Math.round((e.trp_primary_target / trpSum) * 100) }
            })

            categ.push(...result.map((e: any) => e.timeslot))

            //   prime = result.filter((el: any) => el.timeslot.includes("PT")).map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)



            const dataSeries = result.map((e: any) => e.trp_primary_target)
            const ser = {
                name: result[0].network,
                type: "column",
                data: dataSeries,
                color: colorArray[theData.length],
                yAxis: 0,
            };
            theData.push(ser);
        }


    }


    return {

        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            type: "column",
            style: {
                fontFamily: "fontRegular"
            }
        },
        title: {
            text: 'Timeslots',
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: "% of TRP",
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },

        yAxis: {
            min: 0,
            labels: {
                format: '{value}', // bilo je value
                style: {
                    color: colorArray[theData.length],
                },
                enabled: false
            },
            title: {
                text: "TRP",
                style: {
                    color: colorArray[theData.length],
                },
                enabled: false,
            },
            stackLabels: {
                enabled: true,
                align: 'center',
                formatter: function (this: any) {
                    return this.hideStackLabel ? '' : this.totalToDisplay || Math.round(this.total) + "%";
                },
                style: {
                    color: theme.primaryGray,
                    textOutline: "0px"
                }

            },
            gridLineWidth: 0,


        },
        xAxis: {
            categories: categ,
            crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },
        },
        tooltip: {
            useHTML: true,
            shared: true,
            followPointer: true,
            valueDecimals: 0,
            valueSuffix: '%'
        },

        plotOptions: {

            column: {
                pointPadding: 0.1,
                distance: -60,
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    crop: false,
                    overflow: 'none',
                    formatter: function (this: any) {
                        return Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Math.round(this.y))
                    },
                    style: {
                        color: theme.primaryGray,
                        textOutline: "0px"
                    }
                }
            },
            series: {
                stacking: theData.length > 1 ? "normal" : "none"
            }


        },
        series: theData.map((el: any) => {
            el.yAxis = 0;
            return el
        }),
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: true,
            labelFormatter: function (this: any) {
                return this.name
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },


    }






}

