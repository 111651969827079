import {
  GetStatementsFromClientQuery,
  StatementsFromClient,
} from 'API';
import { API } from 'aws-amplify';
import { getStatementsFromClient } from 'graphql/queries';
import { createContext, useContext, useEffect, useState } from 'react';
import { CampaignContext, useCampaignContext } from './CampaignContext';
import ClientContext, { useClientContext } from './ClientContext';
import { useCampaignId, useClientCode } from 'customHooks/useParameters';


const { REACT_APP_ENVIRONMENT } = process.env;

const ClientStatementsContext = createContext({
  statements: {} as StatementsFromClient | null | undefined,
});

export const ClientStatementsContextProvider = (props: any) => {

  const { activeCampaign } = useCampaignContext();
  const { activeOrganization } = useClientContext()
  const [statements, setStatements] = useState<StatementsFromClient | null | undefined>();



  useEffect(() => {
    if (activeCampaign) {
      async function fetchStatements() {

        try {
          const response = (await API.graphql({
            query: getStatementsFromClient,
            variables: {
              ClientCountry: activeCampaign?.ClientCountry,
              ClientCode: activeCampaign?.ClientCode,
              //  Environment: Environment[REACT_APP_ENVIRONMENT as keyof typeof Environment],
              Environment: REACT_APP_ENVIRONMENT, // Environment.DEMO,
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
          })) as { data: GetStatementsFromClientQuery };
          const pero = response.data.getStatementsFromClient;
          // console.log("statements", pero)
          setStatements(pero as StatementsFromClient);
        } catch (e) {
          console.log(`statements error ${e}`);
        }
      }

      //  setStatements(null)
      fetchStatements();
    }

  }, [activeCampaign]);



  return (
    <ClientStatementsContext.Provider
      value={{
        statements,
      }}
    >
      {props.children}
    </ClientStatementsContext.Provider>
  );
};

export default ClientStatementsContext;
