import { useContext, useEffect, useState } from 'react';
import { API, Storage } from 'aws-amplify';
import { usePapaParse } from 'react-papaparse';
import { type GraphQLSubscription } from '@aws-amplify/api';
import {
  GetQueryQuery,
  Placeholder,
  queryInput,
  StartQueryMutation,
  statusEnum,
  SubscribeToUpdatedStatusSubscription,
} from 'API';
import { startQuery } from 'graphql/mutations';
import { getQuery } from 'graphql/queries';
import { subscribeToUpdatedStatus } from 'graphql/subscriptions';
import InputContext from 'context/InputContext';
import { set } from 'lodash';

export type IData = {
  actual_cost: number;
  campaign_id: number;
  client_code: string;
  country: string;
  media_source: string;
  year_week: string;
  end_Date: string;
  start_Date: string;
};

export type JsonObject = {
  TotalClicks: number;
  TotalCost: number;
  TotalImpression: number;
  campaign_id: number;
};

export type TVJSON = {
  subcampaign_id: string;
  budget_incl_fee_per_spot: number;
  planned_budget_incl_fee_per_spot: number;
  planned_reach: number;
  effective_frequency: number;
  planned_effective_reach: number;
  planned_primary_trps: number;
  trp_primary_target: number;
  rf_1_primary_target: number;
  rf_2_primary_target: number;
  rf_3_primary_target: number;
  rf_4_primary_target: number;
  rf_5_primary_target: number;
  rf_6_primary_target: number;
  rf_7_primary_target: number;
  rf_8_primary_target: number;
  rf_9_primary_target: number;
  rf_10_primary_target: number;
  primary_target_group: string;
  universe_primary_target: number;
  Enddate: string;
  Startdate: string;
};

export type CCData = {
  isSelected?: boolean;
  CCname: string;
  CCvalue: number | null;
};

export type resultType = {
  data: Array<JsonObject | TVJSON | OOHPRINT | IData | CCData | any>;
};

export type OOHPRINT = {
  ctc: number;
  campaign_id: number;
  Enddate: string;
  Startdate: string;
};

interface FetchingData {
  query: queryInput | undefined;
  querySource:
    | 'KPI'
    | 'TV KPI'
    | 'RADIO KPI'
    | 'OOH KPI'
    | 'CINEMA KPI'
    | 'PRINT KPI'
    | 'OVERFLOW KPI'
    | 'CAMPAIGN OVERFLOW KPI'
    | 'QUERY'
    | 'QUERY TOTAL'
    | 'CC QUERY';
  publishedOutputLocation?: string | undefined | null;
  publishedQueriesDetails?: Placeholder;
}

export const useDataQuery = (
  Props: FetchingData,
): readonly [
  JsonObject | TVJSON | OOHPRINT | Array<IData> | Array<CCData> | Array<any> | undefined,
  'DONE' | 'RUNNING' | 'FAILED' | 'NOT STARTED',
  Error | undefined,
  string,
  string,
] => {
  const { query, querySource, publishedOutputLocation, publishedQueriesDetails } = Props;
  const [data, setData] = useState<
    JsonObject | TVJSON | OOHPRINT | Array<IData> | Array<CCData> | Array<any> | undefined
  >();
  const [status, setStatus] = useState<'DONE' | 'RUNNING' | 'FAILED' | 'NOT STARTED'>('NOT STARTED');
  const [error, setError] = useState<Error>();
  const [queryId, setQueryId] = useState<string>('');
  const [outputLocation, setOutputLocation] = useState<string>('');
  const { readRemoteFile } = usePapaParse();
  const { setTVKPI, setRADIOKPI, setOOHKPI, setPRINTKPI, setCINEMAKPI } = useContext(InputContext);

  // everytime the query changes , start a new query with the fetchStartQuery function
  useEffect(() => {
    // console.log("This is what in the end I might ask ??", query)
    setData(undefined);
    if (query && querySource !== 'CC QUERY' && querySource !== 'QUERY TOTAL') {
      const q = query.query;
      const orderByIds = q.orderBy;
      if (orderByIds) {
        console.log('orderByIds - fetch', orderByIds, querySource);
        console.log('orderByIds - query', q);
      } else {
        console.log('orderByIds - fetch', 'not found', querySource);
      }
    }
    async function fetchStartQuery() {
      try {
        const responseID = (await API.graphql({
          query: startQuery,
          variables: { query: query },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: StartQueryMutation };

        setQueryId(responseID.data.startQuery?.id ?? '');
      } catch (e) {
        console.error(`something went wrong: ${e}`, e);
        setError(e as Error);
      }
    }
    if (query) {
      fetchStartQuery().catch((e) => {
        console.error(e);
        setError(e as Error);
      });
    } else {
      // setQueryId("")
    }
  }, [query]);

  useEffect(() => {
    async function getDownloadLink(location: any) {
      const [, , bucket, , ..._key] = location.split('/');
      // setTheBucket(bucket);
      const keyJoin = _key.join('/');
      const [outputPre, workGroup, keyId] = keyJoin.split('/');
      const link = await Storage.get(keyId, {
        level: 'protected',
        identityId: [outputPre, workGroup].join('/'),
        region: 'eu-west-1',
        bucket: bucket,
      });
      // setLink(link);
      previewFile(link);
    }

    function previewFile(link: any) {
      readRemoteFile(link, {
        download: true,
        header: true,
        dynamicTyping: true,

        skipEmptyLines: true,
        complete: (results: resultType) => {
          // console.log("This is the result of the query", results)
          if (querySource === 'KPI') {
            setData(results.data[0] as JsonObject);
          } else if (querySource === 'TV KPI') {
            setData(results.data[0] as TVJSON);
            //  setTVKPI(results.data[0] as TVJSON);
          } else if (querySource === 'RADIO KPI') {
            setData(results.data[0] as TVJSON);
            // setRADIOKPI(results.data[0] as TVJSON);
          } else if (querySource === 'OOH KPI') {
            setData(results.data[0] as OOHPRINT);
          } else if (querySource === 'CINEMA KPI') {
            setData(results.data[0] as OOHPRINT);
          } else if (querySource === 'PRINT KPI') {
            setData(results.data[0] as OOHPRINT);
          } else if (querySource === 'OVERFLOW KPI' || querySource === 'CAMPAIGN OVERFLOW KPI') {
            setData(results.data as Array<IData>);
          } else if (querySource === 'CC QUERY') {
            const data = results.data.map((e: any) => {
              return { ...e, isSelected: false };
            });
            setData(data as Array<CCData>);
          } else if (querySource === 'QUERY' || querySource === 'QUERY TOTAL') {
            const stringifyYW = results.data.map((e: any) => {
              const pero = Object.fromEntries(
                Object.entries(e).map(([k, v]) => {
                  return (k === 'yw' && typeof v === 'number') ||
                    (k === 'month' &&
                      typeof v === 'number' &&
                      query &&
                      query?.query.detailedDimensions?.some((e) => e?.column === 'month')) ||
                    (k === 'month' &&
                      typeof v === 'number' &&
                      publishedQueriesDetails &&
                      publishedQueriesDetails?.metrics?.some((e: any) => e.metric === 'month') === false) ||
                    (k === 'year' &&
                      typeof v === 'number' &&
                      query &&
                      query?.query.detailedDimensions?.some((e) => e?.column === 'year')) ||
                    (k === 'year' &&
                      typeof v === 'number' &&
                      publishedQueriesDetails &&
                      publishedQueriesDetails?.metrics?.some((e: any) => e.metric === 'year') === false)
                    ? [k, `${v}`]
                    : k.endsWith('id') && !k.endsWith('tid') && typeof v === 'number'
                    ? [k, `${v}`]
                    : [k, v];
                }),
              );
              return pero;
            });

            setData(stringifyYW as Array<any>);
          } else {
            setError(new Error('querySource not found'));
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }

    async function fetchGetQuery() {
      try {
        const responselist = (await API.graphql({
          query: getQuery,
          variables: { id: queryId },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetQueryQuery };

        const location = responselist.data.getQuery?.outputlocation;
        setOutputLocation(location ?? 'Output location not found');
        if (location) {
          getDownloadLink(location);
        } else {
          setError(new Error('Output location not found'));
        }
      } catch (e) {
        console.log(`what is wrong here: ${e}`, e);
      }
    }
    if (queryId) {
      try {
        const subscription = API.graphql<GraphQLSubscription<SubscribeToUpdatedStatusSubscription>>({
          query: subscribeToUpdatedStatus,
          variables: { id: queryId },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        }).subscribe({
          next: (eventData) => {
            const data = eventData?.value?.data ?? null;
            const subscribeToUpdatedStatus = data != null ? data.subscribeToUpdatedStatus : null;

            if (subscribeToUpdatedStatus != null) {
              if (subscribeToUpdatedStatus.status === statusEnum.SUCCEEDED) {
                setStatus('DONE');
                fetchGetQuery().catch((e) => {
                  console.error(e);
                  setError(e as Error);
                });
                subscription.unsubscribe();
              } else if (subscribeToUpdatedStatus.status === statusEnum.RUNNING) {
                setStatus('RUNNING');
              } else if (subscribeToUpdatedStatus.status === statusEnum.FAILED) {
                setStatus('FAILED');
                setError(new Error('Query failed')); //TODO get more info from the error
                console.error('Sub', subscribeToUpdatedStatus);
                subscription.unsubscribe();
              }
            }
          },
          error: (error) => {
            console.error(error);
            subscription.unsubscribe();
          },
        });
      } catch (e) {
        console.error(`error${e}`);
      }
    }
    if (publishedOutputLocation) {
      setStatus('RUNNING');
      setTimeout(() => {
        getDownloadLink(publishedOutputLocation);
        setStatus('DONE');
      }, 1000);
    } else {
      setStatus('NOT STARTED');
      setData(undefined);
    }
  }, [publishedOutputLocation, publishedQueriesDetails, query, queryId, querySource, readRemoteFile]);

  useEffect(() => {
    if (querySource === 'TV KPI') {
      setTVKPI(data);
    } else if (querySource === 'RADIO KPI') {
      setRADIOKPI(data);
    } else if (querySource === 'OOH KPI') {
      setOOHKPI(data);
    } else if (querySource === 'PRINT KPI') {
      setPRINTKPI(data);
    } else if (querySource === 'CINEMA KPI') {
      setCINEMAKPI(data);
    }
  }, [data, querySource, setOOHKPI, setPRINTKPI, setRADIOKPI, setTVKPI, setCINEMAKPI]);

  return [data, status, error, queryId, outputLocation] as const;
};
