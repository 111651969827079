import { agencyEnum, client, countryEnum, datatype, filtertype, localQuery, metricFunction, queryInput } from 'API';
import AuthContext from 'context/AuthContext';
import { useClientContext } from 'context/ClientContext';
import { getEndDate, getStartDate } from 'methods/dateMethods';
import { useContext, useEffect, useState } from 'react';

interface Props {
  clientCodes: client[];
}
export const usePrepKPIQuery = (props: Props): readonly [queryInput | undefined] => {
  const [query, setQuery] = useState<queryInput | undefined>(undefined);
  const { clientCodes } = props;
  const { activeOrganization } = useClientContext();
  const { user } = useContext(AuthContext);

  // date15MonthsAgo
  useEffect(() => {
    const { REACT_APP_AGENCY } = process.env;
    const prepQuery = () => {
      const checkFilters = () => {
        if (clientCodes.length > 1) {
          return {
            column: 'client_code',
            filter: {
              filtertype: filtertype.IN,
              datatype: datatype.STRING,
              list: clientCodes.map((el: any) => el.client),
            },
          };
        } else {
          return {
            column: 'client_code',
            filter: {
              filtertype: filtertype.EQUAL,
              datatype: datatype.STRING,
              value: clientCodes[0].client,
            },
          };
        }
      };

      const query: localQuery = {
        filters: [
          checkFilters(),
          {
            column: 'cost',
            filter: {
              filtertype: filtertype.GT,
              datatype: datatype.NUMBER,
              value: '0',
            },
          },
        ],
        metrics: [
          {
            column: 'cost',
            name: 'actual_cost',
            aggregation: metricFunction.SUM,
          },
          {
            column: 'date',
            name: 'start_Date',
            aggregation: metricFunction.MIN,
            decimals: -1,
          },
          {
            column: 'date',
            name: 'end_Date',
            aggregation: metricFunction.MAX,
            decimals: -1,
          },
        ],
        dimensions: ['campaign_id', 'client_code', 'year_week', 'country', 'media_source'],
        preview: -1,
      };

      const organization = activeOrganization?.Organization;
      const ClientCountry = clientCodes[0].country?.toUpperCase();
      const isDemo = organization === 'DEMO-Organization';

      const getDatabase = (country: string | undefined) => {
        switch (country) {
          case 'DK':
            return 'data_lake_denmark';
          case 'SE':
            return 'data_lake_sweden';
          case 'FI':
            return 'data_lake_finland';
          default:
            return 'data_lake_norway';
        }
      };

      const getViewName = (platform: string) => {
        return isDemo ? `fozzie_${platform}_demo` : `fozzie_${platform}_${REACT_APP_AGENCY}`;
      };

      const platforms = [
        'adform_bvod_excluded',
        'adform_bvod',
        'cinema',
        'facebook',
        'campaign_manager_bvod',
        'campaign_manager_bvod_excluded',
        'google_dv360',
        'google_dv360_youtube',
        'google_ads_video',
        'tiktok',
        'snapchat',
        'outdoor',
        'callisto_tv',
        'print',
        'callisto_radio',
        'readpeak',
        'linkedin',
        'pinterest',
        'taboola',
        'marathon_digital_tracking',
        'campaign_manager_olv',
        'adform_olv',
        'google_ads_display',
      ];

      const views = platforms.map((platform) => ({
        viewName: getViewName(platform),
        database: getDatabase(ClientCountry),
      }));

      const finalquery: queryInput = {
        user: {
          name: user?.username,
          agency: agencyEnum[`${REACT_APP_AGENCY?.toUpperCase()}` as keyof typeof agencyEnum],
          country: countryEnum[`${clientCodes[0].country.toUpperCase()}` as keyof typeof countryEnum],
        },
        date: { from: getStartDate(), to: getEndDate() },
        jobnumber: 'ANNA0027',
        clients: clientCodes as client[],
        query: query,
        views: views,
      };
      setQuery(finalquery);
    };

    if (clientCodes.length > 0 && user?.username && activeOrganization?.Organization) {
      prepQuery();
    }
  }, [activeOrganization?.Organization, clientCodes, user?.username]);

  return [query];
};
