const colorArray = ["#7D98AB", "#A9C8C0", "#DBBC8E", "#C6ADAE", "#698296", "#A3B6C4", "#E5D0AF", "#C2D8D3", "#95A7B5", "#AE8A8C"]


export function TV14(data: any, TVStationSelector: string, theme: any) {
    const theData = [] as any;
    const categ = [] as any;


    // create timeslot property 


    if (TVStationSelector === "All stations") {
        const result = data.reduce((r: any, { pip_rel, trp_primary_target }: any) => {
            var temp = r.find((o: any) => o.pip_rel === pip_rel);
            if (!temp) {
                r.push({ pip_rel, trp_primary_target });
            } else {
                temp.trp_primary_target += trp_primary_target;

            }
            return r;
        }, []);
        const trpSum = result.map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
        const dataArraySUM = result.map((e: any) => {
            return { ...e, trp_primary_target: Math.round((e.trp_primary_target / trpSum) * 100) }
        })/* .sort((a: any, b: any) => parseFloat(a.trp_primary_target) - parseFloat(b.trp_primary_target)) */

        categ.push(...result.map((e: any) => e.pip_rel))
        theData.push({
            name: "TRP",
            type: "pie",
            data: dataArraySUM.map((el: any) => el.trp_primary_target),
            color: colorArray[theData.length],
            yAxis: 1,
            size: "50%",

        })


    } else {
        const result = data.filter((el: any) => el.network === TVStationSelector)
        const trpSum = result.map((e: any) => e.trp_primary_target).reduce((a: number, b: number) => a + b, 0)
        const dataArraySUM = result.map((e: any) => {
            return { ...e, trp_primary_target: Math.round((e.trp_primary_target / trpSum) * 100) }
        })/* .sort((a: any, b: any) => parseFloat(a.trp_primary_target) - parseFloat(b.trp_primary_target)) */
        categ.push(...result.map((e: any) => e.pip_rel))
        theData.push({
            name: "TRP",
            type: "pie",
            data: dataArraySUM.map((el: any) => el.trp_primary_target),
            color: colorArray[theData.length],
            size: "40%",
            center: ["50%", null]
        })


    }

    return {
        chart: {
            backgroundColor: 'transparent',
            zoomType: 'xy',
            type: "pie",
            style: {
                fontFamily: "fontRegular"
            },

        },
        title: {
            text: 'Position in Block',
            align: 'left',
            style: {
                color: theme.primaryGray,
                fontFamily: "fontMedium",
                fontSize: "1.2vw"
            }
        },
        subtitle: {
            text: "% of TRP",
            align: 'left',
            style: {
                color: theme.secondaryGray,
                fontFamily: "fontRegular",
                fontSize: ".8vw"
            }
        },

        xAxis: {
            categories: categ,
            crosshair: true,
            gridLineWidth: 0,
            labels: {
                style: {
                    color: theme.primaryGray,
                }
            },
        },
        tooltip: {
            useHTML: true,
            followPointer: true,
            shared: false,
            // Highcharts.TooltipFormatterContextObject
            formatter: function (this: any) {
                let cat = categ;
                const x = this.point.x;
                /*          const a = this.y;
                         const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0) */

                return '<b>' + cat[x] + '</b><br>' + this.series.name + " : " + this.y + "%"
            },
        },

        plotOptions: {

            pie: {


                dataLabels: {
                    allowPointSelect: true,
                    // distance: "-30%",
                    enabled: true,
                    allowOverlap: false,
                    align: "center",
                    style: {
                        color: theme.primaryGray,
                        textOutline: 'transparent',
                        border: "1px solid red"
                    },
                    // Highcharts.PointLabelObject
                    formatter: function (this: any) {
                        // let cat = categ;
                        /*                      const a = this.y;
                                             const b = this.series.processedYData.reduce((a: any, b: any) => a + b, 0) */
                        let cat = categ;
                        const x = this.point.x;
                        //  const x = this.point.x;
                        const test = this?.y;
                        if (test as number > 0) {
                            return '<b>' + cat[x] + '</b><br>' + this.y + "%"
                        }
                    },
                },
                showInLegend: {
                    enable: false,
                    formatter: function (this: any) {
                        let cat = categ;
                        const x = this.point.x;
                        const test = this?.y;
                        if (test as number > 0) {
                            return cat[x] + ":<br>" + this.y;
                        }
                    },
                },
                // depth: 35,
                colors: colorArray,
                borderWidth: 0,

            },

        },
        series: theData,
        credits: { enabled: false },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
        legend: {
            enabled: false,
            labelFormatter: function (this: any) {
                var legendIndex = this.index;
                var legendNamePie = this.series?.chart?.axes[0].categories[legendIndex];
                return legendNamePie
            },
            itemStyle: {
                color: theme.primaryGray,
                fontWeight: "normal",
                font: "fontMedium"
            }
        },


    }






}

